import Type from '../../functions/Type';

export interface AnyReference {
  fullPath: string;
  root: AnyReference;
}

export default class Reference<Child extends AnyReference | void = any, Parent extends AnyReference | void = any>
  implements AnyReference
{
  constructor(public path: string, public parent: Parent) {}

  get fullPath(): string {
    if (this.parent && this.path) {
      return this.parent.fullPath + '/' + this.path;
    } else if (this.path) {
      return this.path;
    } else {
      return '';
    }
  }

  get root(): AnyReference {
    return this.parent?.root ?? this;
  }

  child(path: string): Child {
    if (!path) throw new Error(`Cannot get child of reference with falsy path "${path}"`);
    return new this.childReferenceType!(path, this);
  }

  get childReferenceType(): Type<Child> | undefined {
    return undefined;
  }
}
