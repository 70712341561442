import firebase from 'firebase/app';
import isInteger from 'lodash/isInteger';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import unwrap from '../../functions/unwrap';
import useUserGroups from '../../hooks/useUserGroups';
import SelectOption from '../../models/SelectOption';
import DropdownSelect from '../DropdownSelect';
import FilterMetadata from '../FirestoreTable/FilterMetadata';
import FontAwesome from '../FontAwesome';
import EditableInput from '../Forms/EditableInput';
import ColumnMetadata from '../Table/ColumnMetadata';
import FilterComponent from './FilterComponent';

const pathifyName = name => name.toLowerCase().replaceAll(/[^a-zA-Z0-9]|[\s_]/g, '-');

export default function FiltersComponent({queryId, updateQuery, onClickDownloadCsv}) {
  const history = useHistory();
  const {groups} = useUserGroups();

  const [queryEntry, setQueryEntry] = useState();
  const [error, setError] = useState();
  const [filters, setFilters] = useState();
  const [limitWarning, setLimitWarning] = useState(null);

  const availableColumns = useMemo(() => ColumnMetadata.all(), []);

  // EFFECT: get data

  useEffect(() => {
    return firebase
      .firestore()
      .collection('queries')
      .doc(queryId)
      .onSnapshot(
        snapshot => {
          setError(null);
          if (!snapshot.exists) return;
          setQueryEntry([snapshot.id, snapshot.data()]);
        },
        error => {
          console.error('Error getting firestore query object', error);
          setError(error);
        },
      );
  }, [queryId, history]);

  // EFFECT: apply values to filters

  useEffect(() => {
    let filters = [];
    if (queryEntry) {
      filters = FilterMetadata.forTrips(groups).map(filter => {
        const copy = {...filter};
        unwrap(queryEntry[1].filterValues[copy.field], value => {
          copy.value = value;
        });
        return copy;
      });
    }
    setFilters(filters);
  }, [queryEntry, groups]);

  const onChangeLimit = useCallback(
    limit => {
      updateQuery({limit});
    },
    [updateQuery],
  );

  const onChangeName = useCallback(
    name => {
      const path = pathifyName(name);
      return updateQuery({name, path}).then(() => history.replace(`/admin/jobs/${path}`));
    },
    [history, updateQuery],
  );

  const onChangeVisibleColumns = useCallback(
    visibleColumns => {
      updateQuery({visibleColumns});
    },
    [updateQuery],
  );

  const onChangeFilter = useCallback(
    (filter, value) => {
      let filterValues = queryEntry[1].filterValues || {};
      if (value) {
        filterValues[filter.field] = value;
      } else {
        delete filterValues[filter.field];
      }
      updateQuery({filterValues});
    },
    [queryEntry, updateQuery],
  );

  const onChangeLimitInternal = useCallback(
    event => {
      const value = event.target.value.trim();
      const number = Number(value);
      if (!value) {
        setLimitWarning(null);
        onChangeLimit(null);
      } else if (!isInteger(number)) {
        setLimitWarning('Invalid input');
      } else if (number <= 0) {
        setLimitWarning('Number must be greater than zero');
      } else {
        setLimitWarning(null);
        onChangeLimit(number);
      }
    },
    [onChangeLimit],
  );

  const filterCount = useMemo(
    () => (!filters ? null : filters.filter(filter => unwrap(filter.value, () => true, false)).length),
    [filters],
  );

  if (error) {
    return 'Error';
  }

  if (!queryEntry) {
    return null;
  }

  const query = queryEntry[1];
  const maxFilters = filterCount >= 3;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group">
            <h5>Name</h5>
            <div>
              <EditableInput
                name="queryName"
                value={query.name}
                onChange={({name, value, done}) =>
                  onChangeName(value).then(
                    () => done(true),
                    e => done(false),
                  )
                }
                className="show-border form-control"
                placeholder="Type name here"
                noInline
              />
            </div>
          </div>

          <div className="form-group">
            <h5>Columns</h5>
            <DropdownSelect
              options={availableColumns.map(column => new SelectOption(column.path, column.name))}
              selectedValues={query.visibleColumns}
              onChange={onChangeVisibleColumns}
              buttonClassName="btn w-100 text-left"
              className="d-block mb-2 mb-md-3"
            />
          </div>

          <div className="form-group">
            <h5>Limit</h5>
            <input
              type="number"
              name="limitInput"
              id="Limit"
              value={query.limit || ''}
              onChange={onChangeLimitInternal}
              placeholder="500 (default)"
              className={'form-control' + (limitWarning ? ' is-invalid' : '')}
            />
            <small className="invalid-feedback">Number must be greater than zero</small>
          </div>

          <div className="form-group">
            <h5>CSV Download</h5>
            <button onClick={onClickDownloadCsv} className="btn btn-primary">
              <i className="fas fa-download" /> Download
            </button>
          </div>
        </div>

        <div className="col-xl-6">
          <h5>Filters{filterCount ? ` (${filterCount})` : ''}</h5>

          {maxFilters && (
            <div className="alert alert-info">
              <FontAwesome.InfoCircle className="mr-2" />
              Maximum three filters at a time
            </div>
          )}

          {filters &&
            filters.map((filter, i) => (
              <div key={filter.field + i} className="form-group">
                <label
                  htmlFor={`${filter.field}Select`}
                  className={unwrap(filter.value, () => true) ? 'font-weight-bold' : ''}>
                  {filter.name}
                </label>
                <FilterComponent
                  filter={filter}
                  onChangeFilter={onChangeFilter}
                  disabled={maxFilters && !filter.value}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
