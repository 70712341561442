import unwrap from '../../functions/unwrap';
import RealtimeDatabaseModel from '../base/RealtimeDatabaseModel';

export default class Driver extends RealtimeDatabaseModel {
  static path = 'drivers';

  id;
  types;

  constructor(pojo = undefined) {
    super();

    if (pojo) {
      Object.assign(this, pojo);
      this.addressCoordinatesArray = pojo.addressCoordinatesArray;
      this.address = pojo.address;
      this.allowPayout = pojo.allowPayout;
      this.bearing = pojo.bearing;
      this.cellphone = pojo.cellphone;
      this.coordinate = pojo.coordinate;
      this.email = pojo.email;
      this.firstName = pojo.firstName;
      this.isTestingAccount = pojo.isTestingAccount;
      this.lastName = pojo.lastName;
      this.phone = pojo.phone;
      this.status = pojo.status;
      this.submissionTime = pojo.submissionTime;
    }
  }

  static Key = {
    cellphone: 'cellphone',
    currentTrip: 'currentTrip',
    inPickupModeEnabled: 'inPickupModeEnabled',
    phone: 'phone',
  };

  addressString(altValue) {
    return unwrap(altValue || this.address, address => {
      switch (typeof address) {
        case 'string':
          return address;
        case 'object':
          return [
            address.line1,
            address.line2,
            address.city,
            [address.region, address.postalCode].filter(Boolean).join(' '),
            address.country,
          ]
            .filter(Boolean)
            .join(', ');
        default:
          return null;
      }
    });
  }

  /**
   * Returns a Google Maps `LatLng` object for the driver `coordinate`, or `null`
   */
  coordinateLatLng() {
    return unwrap(this.coordinate, coordinate => {
      return unwrap(coordinate[0], latitude => {
        return unwrap(coordinate[1], longitude => {
          return new window.google.maps.LatLng(latitude, longitude);
        });
      });
    });
  }

  /**
   * Returns the driver's full name, first name, or `null`
   */
  fullName() {
    return unwrap(this.firstName, firstName => {
      return unwrap(
        this.lastName,
        lastName => {
          return `${firstName} ${lastName}`;
        },
        () => firstName,
      );
    });
  }

  fullNameWithNickname() {
    return unwrap(this.firstName, firstName => {
      return unwrap(
        this.lastName,
        lastName => {
          return unwrap(
            this.nickName,
            nickName => {
              return `${firstName} "${nickName}" ${lastName}`;
            },
            () => {
              return `${firstName} ${lastName}`;
            },
          );
        },
        () => firstName,
      );
    });
  }

  nickNameWithLastName() {
    return [this.nickName || this.firstName, this.lastName].filter(Boolean).join(' ');
  }

  casualName() {
    return unwrap(this.nickName || this.firstName, fn => unwrap(this.lastName, ln => `${fn} ${ln[0]}.`, fn));
  }

  /**
   * Returns the first and last initials, one, or niether (null safe)
   */
  get initials() {
    const firstInitial = (this.firstName || '').substring(0, 1).toUpperCase();
    const lastInitial = (this.lastName || '').substring(0, 1).toUpperCase();
    return firstInitial + lastInitial;
  }

  /**
   * Returns `cellphone`, `phone`, or `null`
   */
  safePhoneNumber() {
    return this[Driver.Key.cellphone] || this[Driver.Key.phone] || null;
  }

  quickQuery() {
    return [this.firstName, this.nickName, this.lastName, this.phone, this.cellphone]
      .filter(Boolean)
      .join(' ')
      .toLowerCase();
  }
}
