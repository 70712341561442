"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tripTypeDetailRead = void 0;
const TripTypeDetail_1 = require("../models/TripTypeDetail");
const PATH = 'trip-type-details';
async function tripTypeDetailRead(type, database) {
    const result = await database.read(PATH, type);
    return (0, TripTypeDetail_1.tripTypeDetailDeserialize)(result.value);
}
exports.tripTypeDetailRead = tripTypeDetailRead;
