import firebase from 'firebase/app';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import ReactScroll from 'react-scroll';
import {Chat as WaveCommonChat} from 'wave-common';
import Delay from '../../../components/Delay';
import Spinner from '../../../components/Spinner';
import unwrap from '../../../functions/unwrap';
import usePrevious from '../../../hooks/usePrevious';
import useRealtimeDatabase, {Result} from '../../../hooks/useRealtimeDatabase';
import ChatModel from '../../../models/scoopm/Chat';
import ChatType from '../../../models/scoopm/ChatType';
import Message from '../../../models/scoopm/Message';
import Trip from '../../../models/scoopm/Trip';
import UserType from '../../../models/scoopm/UserType';
import MessageComponent from './Message';

const messagesDivId = 'messagesDiv';

export default function Chat({trip, chatType}: {trip: Result<Trip | null>; chatType: ChatType}) {
  // refs

  const messageInput = useRef<HTMLInputElement>();
  const scrollIsProgrammatic = useRef(false);
  const shouldScrollToBottom = useRef(true);

  const scrollToBottom = useRef((duration = 250) => {
    scrollIsProgrammatic.current = true;
    ReactScroll.animateScroll.scrollToBottom({
      containerId: messagesDivId,
      duration,
    });
  });

  /// state

  const [messageString, setMessageString] = useState('');
  const [hasUnseenMessages, setHasUnseenMessages] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [recipientId, recipientType, senderType] = useMemo(() => {
    switch (chatType) {
      case ChatType.riderDriver:
        return [trip.data?.driverId, UserType.driver, UserType.customer];
      case ChatType.adminDriver:
        return [trip.data?.driverId, UserType.driver, UserType.admin];
      default:
        return [undefined, undefined];
    }
  }, [chatType, trip.data?.driverId]);

  // hooks

  const chat = useRealtimeDatabase<WaveCommonChat>(
    useMemo(
      () => ({
        path: trip.key
          ? `/chats/${chatType === ChatType.adminDriver ? ChatType.adminDriver.rawValue : trip.data?.customerId}/${
              trip.key
            }`
          : null,
      }),
      [trip.key, trip.data?.customerId, chatType],
    ),
  );
  const previousChat = usePrevious(chat);

  // chat data --> scroll

  useEffect(() => {
    ReactScroll.Events.scrollEvent.register('end', function (to, element) {
      scrollIsProgrammatic.current = false;
    });

    return () => {
      ReactScroll.Events.scrollEvent.remove('end');
    };
  }, []);

  useEffect(() => {
    if (!chat.data || !chat.data.messages || !Object.keys(chat.data.messages).length) {
      // no messages

      console.log('Not scrolling');
    } else if (!previousChat.data || !previousChat.data.messages) {
      // first load of messages

      console.log('Scrolling immediately');

      if (shouldScrollToBottom.current) {
        scrollToBottom.current(0);
      } else {
        setHasUnseenMessages(true);
      }
    } else {
      // later loads of messages

      console.log('Animated scroll');

      if (shouldScrollToBottom.current) {
        scrollToBottom.current();
      } else {
        setHasUnseenMessages(true);
      }
    }
  }, [chat.data, previousChat.data]);

  // view methods

  const onScrollMessagesDiv = (event: any) => {
    if (scrollIsProgrammatic.current) {
      return;
    }

    const messagesDiv = event.target;

    const didScrollToBottom = messagesDiv.scrollTop === messagesDiv.scrollHeight - messagesDiv.offsetHeight;

    shouldScrollToBottom.current = didScrollToBottom;

    if (didScrollToBottom) {
      setHasUnseenMessages(false);
    }
  };

  const onClickNewMessages = (event: any) => {
    event.preventDefault();

    scrollToBottom.current();
    shouldScrollToBottom.current = true;
    setHasUnseenMessages(false);
  };

  const onChangeMessageInput = (event: any) => {
    setMessageString(event.target.value);
  };

  const onSubmitForm = (event: any) => {
    event.preventDefault();

    addMessage();
  };

  const onClickTryAgain = (event: any) => {
    addMessage();
  };

  const addMessage = () => {
    const validatedString = messageString.trim();

    if (!validatedString) {
      return;
    }

    // check if Chat exists

    chat.reference?.once('value').then(async snapshot => {
      if (!snapshot.exists()) {
        await chat.set(new ChatModel(chatType, []));
      }

      const message = new Message(
        validatedString,
        recipientId,
        recipientType,
        [firebase.auth().currentUser!.uid],
        firebase.auth().currentUser!.uid,
        senderType,
      );

      console.log('Adding chat message...');
      shouldScrollToBottom.current = true;
      setHasError(false);
      setIsSending(true);

      chat.reference
        ?.child('messages')
        .push()
        .set(message)
        .then(
          () => {
            console.log('Added chat message');
            setMessageString('');
          },
          error => {
            console.error('Error adding chat message', error);
            setHasError(true);
          },
        )
        .finally(() => {
          setIsSending(false);
          messageInput.current?.focus();
        });
    });
  };

  // render

  return (
    <div className="h-100 rounded bg-white d-flex flex-column position-relative">
      <div
        id={messagesDivId}
        onScroll={onScrollMessagesDiv}
        className="flex-grow-1 border rounded px-2 pt-2 mb-2 d-flex flex-column overflow-scroll"
        style={{minHeight: '12rem'}}>
        {chat.isLoading && (
          <Delay>
            <Spinner />
          </Delay>
        )}

        {(!chat.data || !chat.data.messages || Object.keys(chat.data.messages).length === 0) && 'No messages'}

        {chat.data && chat.data.messages && (
          <>
            {/* <small className="text-muted text-center">Start of conversation</small> */}

            {Object.values(chat.data.messages).map((message, i) => (
              <MessageComponent key={i} message={message} />
            ))}
          </>
        )}
      </div>

      {hasUnseenMessages && (
        <button
          onClick={onClickNewMessages}
          className="btn btn-sm btn-light align-self-center shadow position-absolute"
          style={{bottom: '4rem'}}>
          New messages
        </button>
      )}

      <form onSubmit={onSubmitForm}>
        <div className="input-group">
          <input
            ref={messageInput as never}
            type="text"
            onChange={onChangeMessageInput}
            value={isSending ? 'Sending...' : messageString}
            placeholder={
              unwrap(trip.data, data => data.isDone())
                ? 'Trip is over'
                : chatType === ChatType.adminDriver
                ? 'Message the driver'
                : 'Type message...'
            }
            className="form-control"
            disabled={!recipientId || chat.isLoading || isSending || trip.data?.isDone()}
          />
          <div className="input-group-append">
            <button type="submit" className="btn btn-primary" disabled={!messageString || isSending}>
              Send
            </button>
          </div>
        </div>
      </form>

      {hasError && (
        <small className="text-danger mt-1">
          Couldn't sent message.
          <span onClick={onClickTryAgain} style={{textDecoration: 'underline'}} className="ml-1 cursor-pointer">
            Try again
          </span>
        </small>
      )}
    </div>
  );
}
