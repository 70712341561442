import formatIntl from './formatIntl';
import {unwrap} from './unwrap';

/**
 * Formats an amount in a given currency
 * @param {number | undefined} amount The currency amount
 * @param {?string} currency The currency of the amount
 */
const formatCurrency = (amount, currency = 'USD') =>
  unwrap(amount, amount => formatIntl(amount, {style: 'currency', currency}));

export default formatCurrency;
