"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectPick = void 0;
function objectPick(obj, ...keys) {
    const picked = {};
    keys.forEach(key => {
        picked[key] = obj[key];
    });
    return picked;
}
exports.objectPick = objectPick;
