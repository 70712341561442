import firebase from 'firebase/app';
import React, {useEffect, useMemo} from 'react';
import {FailureStatus, IdleStatus, PendingStatus, SuccessStatus, Type, unwrap, useStatus} from 'wave-common';
import FirestoreModel from '../models/base/FirestoreModel';
import {createModel} from './useFirestoreGet';

export default function useFirestoreQueryListener<Model extends FirestoreModel>(
  reference: firebase.firestore.Query | undefined | null,
  modelType?: {new (): Model} | Type<Model> | undefined | null,
): ReturnType<Model> {
  const {isPending, error, value, setStatus} = useStatus<Status<Model> | undefined>(React as any);

  useEffect(() => {
    if (reference) {
      setStatus(new PendingStatus(undefined));
      return reference.onSnapshot(
        snapshot => {
          const models = unwrap(modelType, type => snapshot.docs.map(doc => createModel(type, doc)));
          setStatus(new SuccessStatus({snapshot, models}));
        },
        error => {
          setStatus(new FailureStatus(error, undefined));
        },
      );
    } else {
      setStatus(new IdleStatus(undefined));
    }
  }, [reference, modelType, setStatus]);

  return useMemo(
    () => ({
      reference,
      isLoading: isPending,
      error,
      ...value,
    }),
    [reference, isPending, error, value],
  );
}

interface Status<Model> {
  snapshot: firebase.firestore.QuerySnapshot;
  models?: (Model | undefined)[];
}

interface ReturnType<Model> {
  reference?: firebase.firestore.Query | undefined | null;
  isLoading: boolean;
  error?: Error;
  snapshot?: firebase.firestore.QuerySnapshot;
  models?: (Model | undefined)[];
}
