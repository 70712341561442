import {PhoneNumberUtil, PhoneNumberFormat} from 'google-libphonenumber';

const instance = PhoneNumberUtil.getInstance();

/**
 *
 * @param {string} string
 * @param {string} format
 * @param {boolean} ignoreExceptions
 * @param {string} region
 * @returns {string}
 */
const format = (string, format, ignoreExceptions, region) => {
  if (!string) {
    return string;
  }
  // console.log(`Formatting ${string} to national format`);
  try {
    const number = instance.parse(string, region);
    return instance.format(number, format);
  } catch (error) {
    if (ignoreExceptions) {
      console.warn(`Error formatting \`${string}\`; ignoring.`, error.message);
      return string;
    } else {
      throw error;
    }
  }
};

const formatPhone = (string, ignoreExceptions = true, region = 'US') =>
  format(string, PhoneNumberFormat.NATIONAL, ignoreExceptions, region);

const formatPhoneE164 = (string, ignoreExceptions = true, region = 'US') =>
  format(string, PhoneNumberFormat.E164, ignoreExceptions, region);

export {formatPhone, formatPhoneE164};
