import ScoopMApi from '.';
import VendorApplicationModel from '../../models/scoopm/VendorApplication';
import HttpReference from '../platform/HttpReference';
import VendorApplication from './VendorApplication';

export default class VendorApplications extends HttpReference<VendorApplicationModel, VendorApplication, ScoopMApi> {
  get childReferenceType() {
    return VendorApplication;
  }

  create(application: VendorApplicationModel) {
    return this.request({
      method: 'POST',
      data: application as any,
    });
  }
}
