import {AxiosResponse} from 'axios';
import firebase from 'firebase/app';
import {useEffect, useState} from 'react';
import User from '../models/scoopm/User';
import ScoopMApi from '../references/scoopm-api';
import useIsMountedRef from './useIsMountedRef';

interface State<U = User> {
  isLoading: boolean;
  error: Error | undefined;
  users: U[] | undefined;
}

export default function useUsers(uids: string[] | undefined) {
  const isMountedRef = useIsMountedRef();
  const [state, setState] = useState<State>({
    isLoading: true,
    error: undefined,
    users: undefined,
  });

  useEffect(() => {
    if (uids) {
      const ref = firebase.database().ref('users');
      const promises = uids?.map(uid => ref.child(uid).once('value')) ?? [];
      setState(state => ({
        isLoading: true,
        error: state.error,
        users: state.users,
      }));
      Promise.all(promises).then(
        snapshots => {
          if (isMountedRef.current) {
            const users = snapshots.map(snapshot => User.fromSnapshot(User, snapshot));
            setState({isLoading: false, error: undefined, users});
          }
        },
        error => {
          if (isMountedRef.current) {
            setState({isLoading: false, error, users: undefined});
          }
        },
      );
    } else {
      setState({isLoading: true, error: undefined, users: undefined});
    }
  }, [isMountedRef, uids]);

  return state;
}

export type UserWithAuthData = User & {claims?: Record<string, boolean>};
type StateWithAuthData = State<UserWithAuthData>;

export function useUsersWithAuthData(uids: string[] | undefined): StateWithAuthData {
  const isMountedRef = useIsMountedRef();

  const {isLoading, error, users} = useUsers(uids);

  const [state, setState] = useState<StateWithAuthData>({
    isLoading: true,
    error: undefined,
    users: undefined,
  });

  useEffect(() => {
    if (users) {
      setState(oldState => ({
        isLoading: true,
        error: oldState.error,
        users: oldState.users,
      }));
      Promise.all(
        users.map(user =>
          (ScoopMApi.instance.users.child(user.id).claims.get() as Promise<any>).then((response: AxiosResponse) =>
            Object.assign(User.fromPojo(User, user), {claims: response.data}),
          ),
        ),
      ).then(
        users => {
          if (!isMountedRef.current) return;
          setState({isLoading: false, error: undefined, users});
        },
        error => {
          if (!isMountedRef.current) return;
          setState({isLoading: false, error, users: undefined});
        },
      );
    } else {
      setState({isLoading: true, error: undefined, users: undefined});
    }
  }, [isLoading, error, users, isMountedRef]);

  return state;
}
