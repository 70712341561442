import isEqual from 'lodash/isEqual';
import {useCallback, useEffect, useMemo, useRef} from 'react';

function useDeepComparisonMemoize(value?: object) {
  const ref = useRef<object | undefined>();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export default function useDeepComparisonEffect(callback: React.EffectCallback, dependencies: React.DependencyList) {
  useEffect(callback, dependencies.map(useDeepComparisonMemoize));
}

export function useDeepComparisonMemo<T>(factory: () => T, deps: React.DependencyList | undefined) {
  return useMemo<T>(factory, deps?.map(useDeepComparisonMemoize));
}

export function useDeepComparisonCallback<T extends (...args: any[]) => any>(
  callback: T,
  deps: React.DependencyList,
): T {
  return useCallback(callback, deps.map(useDeepComparisonMemoize));
}
