import React from 'react';
import {Link} from 'react-router-dom';
import FirebaseTable from '../../../components/FirebaseTable';
import {formatPhone} from '../../../functions/formatPhone';
import unwrap from '../../../functions/unwrap';
import Customer from '../../../models/scoopm/Customer';

function phone(user, fallback = 'Empty') {
  return unwrap(
    user,
    user => unwrap(Customer.prototype.safePhoneNumber.apply(user), safe => formatPhone(safe, true), fallback),
    fallback,
  );
}

const columns = [
  {
    heading: 'First name',
    value: user => user.firstName || 'Empty',
    valueComponent: user => user.firstName || <span className="text-gray-300">Empty</span>,
  },
  {
    heading: 'Last name',
    value: user => user.lastName || 'Empty',
    valueComponent: user => user.lastName || <span className="text-gray-300">Empty</span>,
  },
  {
    heading: 'Phone',
    value: phone,
    valueComponent: user => phone(user, <span className="text-gray-300">Empty</span>),
  },
  {
    heading: 'UID',
    value: user => user.key,
  },
];

export default function All() {
  return (
    <FirebaseTable
      title="Users"
      path="users"
      columns={columns}
      link={user => `/admin/users/${user.key}`}
      searchTerm={user => Object.values(user).filter(Boolean).join('')}
      additionalBarComponents={
        <Link to="/admin/users/create" className="btn bg-white">
          <i className="fa fa-plus" /> Add
        </Link>
      }
    />
  );
}
