import firebase from 'firebase/app';
import React, {ChangeEvent, MouseEvent, useCallback, useState} from 'react';
import {RouteChildrenProps} from 'react-router-dom';
import {baseUserFullName, useStatus} from 'wave-common';
import Alert from '../../../components/Alert/Alert';
import Card from '../../../components/Card';
import FirebaseStorageSquareImage from '../../../components/FirebaseStorageSquareImage';
import {FontAwesomeV5} from '../../../components/FontAwesome';
import {useAlert} from '../../../contexts/AlertContext';
import {useTitle} from '../../../contexts/TitleContext';
import useDriver from '../../../hooks/useDriver';
import FirebaseImageStore from '../../../services/FirebaseImageStore';

export default function Files({match}: RouteChildrenProps<{uid: string}>) {
  // basic vars

  const driverId = match?.params.uid;

  // title

  const {data: driver} = useDriver(driverId);
  useTitle(`${driver ? baseUserFullName(driver) : ''} / Files`);

  // render

  if (!driverId) {
    return null;
  }

  return (
    <Card white={false}>
      <h2>Files</h2>

      <div className="row">
        <File title="Driver's License" bucketPath="licenses" driverId={driverId} />
        <File title="Registration" bucketPath="registrations" driverId={driverId} />
        <File title="Insurance card" bucketPath="insurance-cards" driverId={driverId} />
        <File title="W-9" bucketPath="w9s" driverId={driverId} />
        <File title="Fingerprints" bucketPath="figngerprints" driverId={driverId} />
      </div>
    </Card>
  );
}

function File({title, bucketPath, driverId}: {title: string; bucketPath: string; driverId: string}) {
  // basic vars

  const elementId = bucketPath + driverId;
  const imagePath = bucketPath + '/' + driverId;

  // alert

  const {setAlert} = useAlert();

  // status

  const {error, isPending, handlePromise} = useStatus<unknown>(React as never);

  // This is used to hide, and then re-show, the image after it has been uploaded. This forces the image preview to be refreshed.

  const [showImage, setShowImage] = useState(true);

  // callbacks

  const refreshImage = useCallback(() => {
    setShowImage(false);
    delete (FirebaseImageStore.instance.urls as Record<string, string | null>)[imagePath];
    setTimeout(() => setShowImage(true), 100);
  }, [imagePath]);

  const onChangeFileInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) {
        setAlert(new Alert('No file selected', 'Make sure to select a file first'));
        return;
      }
      handlePromise(
        new Promise((resolve, reject) =>
          firebase
            .storage()
            .ref(imagePath)

            .put(file)
            .then(result => {
              refreshImage();
              resolve(result);
            }, reject),
        ),
      );
    },
    [imagePath, handlePromise, setAlert, refreshImage],
  );

  const onClickDelete = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      setAlert(
        new Alert(
          'WARNING!',
          'Do you want to delete this file? This change cannot be undone',
          'Delete forever',
          () => handlePromise(firebase.storage().ref(imagePath).delete().then(refreshImage)),
          'Cancel',
        ),
      );
    },
    [imagePath, handlePromise, setAlert, refreshImage],
  );

  return (
    <div className="col-12 col-md-6 mb-2 mb-md-3">
      <div className="rounded border p-2 p-md-3 bg-white">
        <div className="row">
          <div className="col-auto">{showImage && <FirebaseStorageSquareImage path={imagePath} />}</div>
          <div className="col d-flex flex-column align-items-start justify-content-between">
            <h5>{title}</h5>
            <div className="row">
              <div className="col">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id={elementId}
                    onChange={onChangeFileInput}
                    accept=".jpg,.jpeg,.png"
                    disabled={isPending}
                  />
                  <label className="custom-file-label" htmlFor={elementId}>
                    {error ? error.message : isPending ? 'Uploading...' : 'Change file'}
                  </label>
                </div>
              </div>
              <div className="col-auto">
                <button className="btn btn-outline-danger" onClick={onClickDelete}>
                  <FontAwesomeV5 name="trash" /> Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
