import entries from 'lodash/entries';
import sortBy from 'lodash/sortBy';
import React from 'react';
import {Link} from 'react-router-dom';
import Card from '../../components/Card';
import {DelaySpinner} from '../../components/Spinner';
import unwrap from '../../functions/unwrap';
import useRealtimeDatabase from '../../hooks/useRealtimeDatabase';
import Driver from '../../models/scoopm/Driver';
import Property from '../types/Property';
import PropertyType from '../types/PropertyType';
import Schema from '../types/Schema';

const payoutFormulaSchema = new Schema(
  'payout-formula',
  [
    new Property('name', PropertyType.string),
    new Property('items', PropertyType.pricingFormulaItem, {
      isArray: true,
      userFacingName: 'Pricing items',
    }),
  ],
  AdditionalContent,
);

function AdditionalContent({schema, id, model}) {
  const users = useRealtimeDatabase({
    path: 'users',
    orderByChild: 'payoutFormulaId',
    equalTo: id,
  });
  const sortedUserEntries = unwrap(users.data, data => sortBy(Object.entries(data), ([id, user]) => user.firstName));

  return (
    <Card padded>
      <h6 className="mb-0">Users with this formula</h6>
      <div className="mb-3">
        <small>
          If the user doesn't have a specific formula, the system will fall back to the default formula for the job
          (based on job type, vendor, source, etc.)
        </small>
      </div>
      {users.isLoading && <DelaySpinner />}
      {sortedUserEntries &&
        sortedUserEntries.map(([userId, user]) => (
          <div key={userId}>
            <Link to={`/admin/users/${userId}`}>{Driver.prototype.fullName.apply(user)}</Link>
          </div>
        ))}
    </Card>
  );
}

export default payoutFormulaSchema;
