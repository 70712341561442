import React from 'react';
import FilterMetadata from '../../components/FirestoreTable/FilterMetadata';
import TripStatus from '../../models/scoopm/TripStatus';
import Table from './Table';

export default function History() {
  return (
    <Table
      id="DISPATCH_PORTAL_HISTORY"
      filters={[
        Object.assign(FilterMetadata.status, {
          value: TripStatus.done().map(s => s.rawValue),
        }),
      ]}
    />
  );
}
