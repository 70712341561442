import React from 'react';
import {exists, formatCurrency} from 'wave-common';
import {
  pricingFormulaInputAmountFormatted,
  pricingFormulaInputValueFormatted,
  pricingFormulaInputVerb,
  pricingFormulatInputTitle,
} from 'wave-common/lib/models/PricingFormulaInput';
import PricingFormulaItemCondition from 'wave-common/lib/models/PricingFormulaItemCondition';
import unwrap from '../../functions/unwrap';
import Property from '../types/Property';
import Schema from '../types/Schema';

export default function PricingFormulaItemDisplay<Model>({
  schema,
  property,
  value,
}: {
  schema: Schema<Model>;
  property: Property;
  value: any;
}) {
  return (
    <span>
      {value.input ? `Add ${pricingFormulatInputTitle(value.input).toLowerCase()} ×` : 'Add'}{' '}
      {value.input ? pricingFormulaInputAmountFormatted(value.input, value.amount) : formatCurrency(value.amount)}
      {unwrap(value.conditions, conditionsString)}
      {unwrap(value.name, name => (
        <em> ({name})</em>
      ))}
    </span>
  );
}

function conditionsString(conditions: PricingFormulaItemCondition[]) {
  return `, ${conditions.map(conditionString).join(', and ')}`;
}

function conditionString(condition: PricingFormulaItemCondition) {
  const minimumString = unwrap(condition.minimumInclusive, number =>
    pricingFormulaInputValueFormatted(condition.input, number),
  );
  const maximumString = unwrap(condition.maximumExclusive, number =>
    pricingFormulaInputValueFormatted(condition.input, number),
  );

  if (condition.applyRangeToInputValue) {
    return exists(condition.minimumInclusive) && exists(condition.maximumExclusive)
      ? `between ${minimumString} and under ${maximumString}`
      : exists(condition.minimumInclusive)
      ? `above ${minimumString}`
      : exists(condition.maximumExclusive)
      ? `below ${maximumString}`
      : false;
  } else {
    return (
      `when ${condition.input.toLowerCase()} ` +
      [
        exists(condition.minimumInclusive)
          ? `${pricingFormulaInputVerb(condition.input)} at or above ${minimumString}`
          : undefined,
        exists(condition.maximumExclusive)
          ? `${pricingFormulaInputVerb(condition.input)} under ${maximumString}`
          : undefined,
      ]
        .filter(Boolean)
        .join(', and ')
    );
  }
}

// [${condition.minimumInclusive || '0'}, ${condition.maximumExclusive || '∞'})
