import firebase from 'firebase/app';
import find from 'lodash/find';
import React, {useMemo} from 'react';
import joinClassNames from '../functions/joinClassNames';
import unwrap from '../functions/unwrap';
import useFirestoreQueryGet from '../hooks/useFirestoreQueryGet';

export default function FirestoreSelect(props: {
  collection: string;
  getTextForDoc: (doc: firebase.firestore.DocumentSnapshot) => string;

  selectedId?: string;
  onChange: (id: string | null, data: object | null) => any;

  className?: string;
}) {
  const reference = useMemo(() => firebase.firestore().collection(props.collection), [props.collection]);
  const {isLoading, snapshot, error} = useFirestoreQueryGet(reference);
  const typedSnapshot = snapshot as unknown as firebase.firestore.QuerySnapshot;

  return (
    <select
      value={props.selectedId ?? ''}
      onChange={event => {
        const id = event.target.value || null;
        const data = unwrap(typedSnapshot, snapshot =>
          unwrap(
            find(snapshot.docs, doc => doc.id === id),
            doc => doc.data(),
          ),
        );
        props.onChange(id, data);
      }}
      className={joinClassNames('mw-100', props.className)}
      disabled={isLoading || Boolean(error)}>
      {isLoading && <option value="">Loading...</option>}
      {error && <option value="">Error loading data</option>}
      <option value="">-</option>
      {unwrap(typedSnapshot, snapshot =>
        snapshot.docs.map(doc => (
          <option key={doc.id} value={doc.id}>
            {props.getTextForDoc(doc)}
          </option>
        )),
      )}
    </select>
  );
}
