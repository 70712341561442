import ScoopMApi from '..';
import HttpReference from '../../platform/HttpReference';
import Applicants from './Applicants';
import Funnels from './Funnels';

export default class Fountain extends HttpReference<void, Funnels, ScoopMApi> {
  funnels: Funnels;
  applicants: Applicants;

  constructor(path: string, parent: ScoopMApi) {
    super(path, parent);

    this.funnels = new Funnels('funnels', this);
    this.applicants = new Applicants('applicants', this);
  }
}
