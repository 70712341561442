import React, {ReactNode, useMemo} from 'react';
import {DelaySpinner} from '../../components/Spinner';
import {unwrap} from '../../functions/unwrap';
import useResult from '../../hooks/useResult';
import HttpReference from '../../references/platform/HttpReference';
import {None, PropertyDisplayProps} from './PropertyDisplay';

export default function StripeObjectDisplay<StripeModel extends object>(
  props: PropertyDisplayProps<string> & {
    referenceForId: (id: string) => HttpReference<StripeModel>;
    format: (stripeModel: StripeModel) => ReactNode;
  },
) {
  const {referenceForId} = props;
  const reference = useMemo(() => unwrap(props.value, value => referenceForId(value)), [referenceForId, props.value]);
  const promise = useMemo(() => unwrap(reference, reference => reference.request()), [reference]);
  const result = useResult(promise);

  const url = useMemo(
    () =>
      unwrap(
        reference,
        reference => `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}/${reference.parent.path}/${props.value}`,
      ),
    [reference, props.value],
  );

  return (
    <div>
      {result === undefined && <None />}
      {result === null && <DelaySpinner small />}
      {result?.error && result.error.message}
      {result?.value && (
        <a href={url} className="text-decoration-underline">
          {props.format(result.value!.data)}
        </a>
      )}
    </div>
  );
}
