import round from 'lodash/round';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Circle, GoogleMap, InfoWindow, Marker, Polyline, Rectangle, withGoogleMap} from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import {LatLng} from 'wave-common';
import Constants from '../../Constants';
import unwrap from '../../functions/unwrap';
import useLocalStorage from '../../hooks/useLocalStorage';
import mapStyles from '../../mapStyles';
import './Map.css';
import MapType from './MapType';
import {GoogleLatLng, MarkerInterface} from './Marker';

const defaultCenter = {lat: 40.7409914, lng: -98.3618973};
const defaultZoom = 4;

type Clusters = Record<string, MarkerInterface[]>;

function Map({
  // map options

  zoomControl = true,
  mapTypeControl = true,
  scaleControl = true,
  streetViewControl = true,
  rotateControl = false,
  fullscreenControl = true,
  gestureHandling = 'cooperative',
  options,

  // contents

  center,
  zoom,
  markers,
  circles,
  paths,
  rectangles,

  // direct parent listeners

  onMap,
  onClick,
  onZoomChanged,
  onDrag,
  onClickMarkerClusterer,

  // public listeners

  onBoundsChange,
  onCenterChanged,

  //

  useDefaultMapStyles,

  children,

  useDefaultZoomAndCenter = true,

  ...props
}: {
  zoomControl?: boolean;
  mapTypeControl?: boolean;
  scaleControl?: boolean;
  streetViewControl?: boolean;
  rotateControl?: boolean;
  fullscreenControl?: boolean;
  gestureHandling?: string;
  options?: object;

  // contents

  center?: LatLng;
  zoom?: number;
  markers?: MarkerInterface[];
  circles?: any[];
  paths?: any[];
  rectangles?: any[];

  // direct parent listeners

  onMap?: (map: any) => void;
  onClick?: (event: {latLng: GoogleLatLng}) => void;
  onZoomChanged?: () => void;
  onDrag?: () => void;
  onClickMarkerClusterer?: (event: any) => void;

  // public listeners

  onBoundsChange?: (bounds: any) => void;
  onCenterChanged?: (center: any) => void;

  //

  useDefaultMapStyles?: boolean;
} & Record<string, any>) {
  const map = useRef<any>();

  const [selectedMarkerId, setSelectedMarkerId] = useState<string>();
  const [clusters, setClusters] = useState<Clusters | undefined>();
  const [unClusteredMarkers, setUnClusteredMarkers] = useState<MarkerInterface[] | undefined>();
  const [internalZoom, setInternalZoom] = useState<number | undefined>(defaultZoom);
  const [internalCenter, setInternalCenter] = useState<LatLng | undefined>(defaultCenter);
  const [mapTypeId, setMapTypeId] = useLocalStorage('MAP_TYPE_ID', MapType.roadmap.rawValue);
  const [styles, setStyles] = useState<google.maps.MapTypeStyle[] | undefined>(
    useDefaultMapStyles ? undefined : mapStyles,
  ); // gets removed if map type is hybrid or other than roadmap

  useEffect(() => {
    let clusters: Clusters | undefined = undefined;
    let unClusteredMarkers = undefined;

    unwrap(markers, markers => {
      clusters = {};
      unClusteredMarkers = [];

      markers.forEach(marker => {
        if (marker.clusterName) {
          if (!clusters![marker.clusterName]) {
            clusters![marker.clusterName] = [];
          }
          clusters![marker.clusterName].push(marker);
        } else {
          unClusteredMarkers.push(marker);
        }
      });
    });

    setClusters(clusters);
    setUnClusteredMarkers(unClusteredMarkers);
  }, [markers]);

  useEffect(() => {
    setInternalZoom(zoom || (useDefaultZoomAndCenter ? defaultZoom : undefined));
  }, [zoom, useDefaultZoomAndCenter]);

  useEffect(() => {
    setInternalCenter(center || (useDefaultZoomAndCenter ? defaultCenter : undefined));
  }, [center, useDefaultZoomAndCenter]);

  useEffect(() => {
    setStyles(mapTypeId === MapType.roadmap.rawValue && !useDefaultMapStyles ? mapStyles : undefined);
  }, [mapTypeId, useDefaultMapStyles]);

  const onMapBoundsChangedInternal = useCallback(() => {
    unwrap(onBoundsChange, callback => {
      unwrap(map.current?.getBounds(), bounds => {
        callback(bounds);
      });
    });
  }, [onBoundsChange]);

  const onMapCenterChangedInternal = useCallback(() => {
    onCenterChanged && onCenterChanged(map.current?.getCenter());
  }, [onCenterChanged]);

  const onDragMapInternal = useCallback(() => {
    onDrag && onDrag();
  }, [onDrag]);

  const onCircleRadiusChangedInternal = useCallback((circleComponent, onRadiusChanged) => {
    circleComponent && onRadiusChanged && onRadiusChanged(round(circleComponent.getRadius(), 2));
  }, []);

  const onCircleCenterChangedInternal = useCallback((circleComponent, onCenterChanged) => {
    circleComponent && onCenterChanged && onCenterChanged(circleComponent.getCenter());
  }, []);

  const onMapTypeIdChanged = () => {
    setMapTypeId(map.current?.getMapTypeId());
  };

  const ref = useCallback(
    it => {
      map.current = it;
      onMap && onMap(it);
    },
    [onMap],
  );

  // RENDER

  const renderMarker = useCallback(
    ({
      id,
      position,
      title,
      label,
      animate,
      icon,
      zIndex,
      infoWindowContent,
      infoWindowComponent,
      onClick,
      ...marker
    }: MarkerInterface) => (
      <Marker
        {...marker}
        key={id}
        position={position}
        title={title}
        label={label}
        animation={animate ? google.maps.Animation.DROP : undefined}
        icon={
          icon === false
            ? undefined
            : icon || {
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: '#fffffff0',
                strokeColor: Constants.theme.primaryColorHexString,
                strokeWeight: 2,
                fillOpacity: 1,
                scale: 14,
              }
        }
        // icon={{
        //     path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
        //     fillColor: "blue",
        //     fillOpacity: 0.6,
        //     strokeWeight: 0,
        //     rotation: rotation,
        //     scale: 2,
        //     anchor: new window.google.maps.Point(15, 30),
        // }
        // }
        onClick={() => {
          setSelectedMarkerId(id);
          if (onClick) {
            onClick();
          }
        }}
        zIndex={zIndex}>
        {id === selectedMarkerId &&
          (infoWindowComponent ? (
            <InfoWindow>{infoWindowComponent}</InfoWindow>
          ) : (
            infoWindowContent && (
              <InfoWindow>
                <div dangerouslySetInnerHTML={{__html: infoWindowContent}} />
              </InfoWindow>
            )
          ))}
      </Marker>
    ),
    [selectedMarkerId],
  );

  const renderCluster = useCallback(
    ([clusterName, cluster]) => (
      <MarkerClusterer
        key={clusterName}
        imagePath={'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'}
        onClick={onClickMarkerClusterer}
        averageCenter
        enableRetinaIcons
        gridSize={12}>
        {cluster.map(renderMarker)}
      </MarkerClusterer>
    ),
    [onClickMarkerClusterer, renderMarker],
  );

  const clustersContent = useMemo(
    () => clusters && Object.entries(clusters).map(renderCluster),
    [clusters, renderCluster],
  );

  const individualMarkersContent = useMemo(
    () => unClusteredMarkers && unClusteredMarkers.map(renderMarker),
    [unClusteredMarkers, renderMarker],
  );

  const renderPolyline = useCallback(
    (path, i) => (
      <Polyline
        key={i}
        path={path.googlePath}
        options={{
          geodesic: true,
          strokeOpacity: 1.0,
          strokeColor:
            path.color || (i % 2 ? Constants.theme.primaryDarkColorHexString : Constants.theme.primaryColorHexString),
          strokeWeight: 3,
        }}
      />
    ),
    [],
  );

  const polylinesContent = useMemo(() => Array.isArray(paths) && paths.map(renderPolyline), [paths, renderPolyline]);

  const renderCircle = useCallback(
    (circle, i) => {
      let circleComponent: any = undefined;
      return (
        <Circle
          key={i}
          ref={it => {
            circleComponent = it;
          }}
          center={circle.center}
          radius={circle.radiusM}
          editable={circle.editable}
          options={{
            strokeColor: circle.strokeColor || Constants.theme.primaryColorHexString,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: circle.fillColor || Constants.theme.primaryColorHexString,
            fillOpacity: unwrap(circle.fillOpacity, undefined, 0.3),
          }}
          onRadiusChanged={() => onCircleRadiusChangedInternal(circleComponent, circle.onRadiusChanged)}
          onCenterChanged={() => onCircleCenterChangedInternal(circleComponent, circle.onCenterChanged)}
          onClick={circle.onClick}
        />
      );
    },
    [onCircleCenterChangedInternal, onCircleRadiusChangedInternal],
  );

  const circlesContent = useMemo(() => Array.isArray(circles) && circles.map(renderCircle), [circles, renderCircle]);

  const rectanglesContent = useMemo(
    () =>
      rectangles && Array.isArray(rectangles) && rectangles.map((rectangle, i) => <Rectangle key={i} {...rectangle} />),
    [rectangles],
  );

  const optionsMemo: google.maps.MapOptions = useMemo(
    () => ({
      // if not memoized, flickering can occur
      styles,
      zoomControl,
      mapTypeControl,
      scaleControl,
      streetViewControl,
      rotateControl,
      fullscreenControl,
      gestureHandling,
      ...(options || {}),
    }),
    [
      styles,
      zoomControl,
      mapTypeControl,
      scaleControl,
      streetViewControl,
      rotateControl,
      fullscreenControl,
      gestureHandling,
      options,
    ],
  );

  return (
    <GoogleMap
      ref={ref}
      options={optionsMemo}
      zoom={internalZoom}
      center={internalCenter}
      mapTypeId={mapTypeId}
      onMapTypeIdChanged={onMapTypeIdChanged}
      onBoundsChanged={onMapBoundsChangedInternal}
      onCenterChanged={onMapCenterChangedInternal}
      onClick={onClick}
      onZoomChanged={onZoomChanged}
      onDrag={onDragMapInternal}
      {...props}>
      {clustersContent}
      {individualMarkersContent}
      {polylinesContent}
      {circlesContent}
      {rectanglesContent}
      {children}
    </GoogleMap>
  );
}

export default withGoogleMap(Map);
