import {ReactNode} from 'react';

export default class Alert<ConfirmResult = void, CancelResult = void> {
  constructor(
    public title: string,
    public body?: ReactNode,
    public confirmText?: string,
    public confirmAction?: (text?: string) => Promise<ConfirmResult>,
    public cancelText?: string,
    public cancelAction?: () => Promise<CancelResult>,
    public onDismiss?: (error?: Error) => void,
    public prompt?: string,
    public dontAskAgainKey?: string,
  ) {}
}
