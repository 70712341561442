import React from 'react';
import joinClassNames from '../../functions/joinClassNames';
import DataRow from './DataRow';
import SelectionAction from './SelectionAction';

interface SelectionActionButtonProps {
  action: SelectionAction;
  selection: DataRow[];
  className?: string;
  disabled: boolean;
}

export default function SelectionActionButton(props: SelectionActionButtonProps) {
  return (
    <button
      onClick={event => props.action.onClick(props.selection)}
      className={joinClassNames(
        'd-block bg-white border-0 p-2 text-left',
        !props.disabled && 'bg-hover-light text-dark',
        props.className,
      )}
      disabled={props.disabled}>
      {props.action.text}
    </button>
  );
}

// export function withSelectionActionButton(action: SelectionAction) {
//     return function (props: SelectionActionButtonProps) {
//         const propsWithAction = Object.assign(props, { action })
//         return <SelectionActionButton {...propsWithAction} />
//     }
// }
