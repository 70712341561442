import capitalize from 'lodash/capitalize';
import round from 'lodash/round';
import times from 'lodash/times';
import moment from 'moment';
import React from 'react';
import ContactCard from '../../../components/ContactCard';
import FirebaseStorageSquareImage from '../../../components/FirebaseStorageSquareImage';
import FontAwesome from '../../../components/FontAwesome';
import EditableTextarea from '../../../components/Forms/EditableTextarea';
import SquareImage from '../../../components/SquareImage';
import joinClassNames from '../../../functions/joinClassNames';
import MomentHelper from '../../../functions/MomentHelper';
import unwrap from '../../../functions/unwrap';
import useLocalStorage from '../../../hooks/useLocalStorage';
import TripLocation from '../../../models/scoopm/TripLocation';
import TripStatus from '../../../models/scoopm/TripStatus';
import RealtimeDatabase from '../../../references/database/RealtimeDatabase';

export default function LocationDetails({trip, distancesMi, minimal}) {
  const locations = trip.data && trip.data.locationsWithDefault();

  const contacts = trip.data && trip.data.contactsWithDefault();

  const [alwaysShowTimestampInfo, setAlwaysShowTimestampInfo] = useLocalStorage('ALWAYS_SHOW_TIMESTAMP_INFO', false);

  return (
    <div className="row m-0">
      {locations &&
        locations.map((location, i) => {
          const isOverdue = TripLocation.prototype.isOverdue.apply(location);
          const isCurrent = trip.data.currentLocationWithDefault() === i;
          const wasLate =
            location.completionTimestampMs && location.scheduledTimestampMs
              ? moment(location.completionTimestampMs).isAfter(moment(location.scheduledTimestampMs))
              : null;

          return (
            <React.Fragment key={i}>
              <div
                className={
                  'col-md d-flex flex-column justify-content-between bg-white rounded p-2 p-md-3' +
                  (isCurrent ? (isOverdue ? ' border border-danger' : ' border-success-pulse') : ' border border-light')
                }>
                <div>
                  {/* contact card */}

                  <ContactCard
                    contact={contacts && contacts.find(contact => contact.userType === location.contactUserType)}
                    location={location}
                    distanceMi={unwrap(distancesMi, d => d[i])}
                  />

                  {/* distance */}

                  {unwrap(distancesMi, distances =>
                    unwrap(distances[i], distance => (
                      <div className="mt-2">
                        <FontAwesome.RouteSolid /> {round(distance, 1)} mi to next stop
                      </div>
                    )),
                  )}

                  {/* travel time */}

                  {i === 0 &&
                    unwrap(trip.data.estimatedMinutes, minutes => (
                      <div>
                        <FontAwesome.Clock /> {round(minutes)} min travel time
                        {unwrap(trip.data.ezCaterPaddingM, min => ` (plus ${round(min - minutes)} min for padding)`)}
                      </div>
                    ))}

                  {/* wait time */}

                  <WaitTime seconds={location.waitTimeS} />

                  {/* instructions */}

                  <div className="mt-3">
                    <Heading>Instructions</Heading>
                    <EditableTextarea
                      value={location.instructions || ''}
                      onSave={value =>
                        RealtimeDatabase.instance
                          .child(`/trips/${trip.data.customerId}/${trip.key}/locations/${i}/instructions`)
                          .set(value || null)
                      }
                      className="form-control mx-n2"
                      rows={4}
                    />
                  </div>

                  {/* contents */}

                  {location.contents && (
                    <div className="mt-2">
                      <Heading>Contents</Heading>
                      {location.contents.map((content, j) => (
                        <TripContent key={j} content={content} />
                      ))}
                    </div>
                  )}
                </div>

                {/* images */}

                {i === locations.length - 1 &&
                  unwrap(location.imageCount, imageCount => (
                    <div className="row mt-3">
                      {times(imageCount, imageIndex => (
                        <div className="col-auto mb-2 mb-md-3">
                          <FirebaseStorageSquareImage
                            path={`/trip-images/${trip.key}-${i}-${imageIndex}`}
                            thumbnailPath={`/trip-images/thumbnails/${trip.key}-${i}-${imageIndex}_200x200`}
                            isImage
                          />
                        </div>
                      ))}
                    </div>
                  ))}

                {/* files */}

                {location.files &&
                  location.files.map((file, i) => (
                    <div key={file.url + i} className="row">
                      <div className="col-auto mt-2 mt-md-3">
                        <Heading>{file.name}</Heading>
                        <SquareImage url={file.url} isImage={false} />
                      </div>
                    </div>
                  ))}

                {/* times */}

                {(location.scheduledTimestampMs ||
                  location.completionTimestampMs ||
                  location.requestedTimestampMs ||
                  location.estimatedArrivalTimestampMs ||
                  location.waitTimeS) && (
                  <div
                    className={joinClassNames(
                      'alert mt-3 mb-0 show-on-hover-container',
                      isOverdue ? 'alert-danger' : 'bg-light',
                    )}
                    onClick={event => setAlwaysShowTimestampInfo(!alwaysShowTimestampInfo)}>
                    {/* {moment(location.scheduledTimestampMs || location.estimatedArrivalTimestampMs).format('L')} */}

                    {unwrap(location.scheduledTimestampMs || location.estimatedArrivalTimestampMs, timestamp => (
                      <div className="row mb-2 mb-lg-0">
                        <div className="col-auto">
                          <FontAwesome.CalendarAlt marginRight />
                        </div>
                        <div className="col">
                          {location.scheduledTimestampMs ? 'Scheduled' : 'Estimated arrival'}
                          {moment(location.scheduledTimestampMs || location.estimatedArrivalTimestampMs).format(' L')}
                        </div>
                        <div className="col col-lg-auto d-flex justify-content-end">
                          {formatTime(timestamp, location.timeZoneIdentifier, alwaysShowTimestampInfo, true)}
                        </div>
                      </div>
                    ))}

                    {trip.data.status === TripStatus.scheduled.rawValue && location.requestedTimestampMs && (
                      <div className="row mb-2 mb-lg-0">
                        <div className="col-auto">
                          <FontAwesome.Empty square marginRight />
                        </div>
                        <div className="col text-gray-500">
                          {location.requestedTimestampMs === location.scheduledTimestampMs ? (
                            'Same as original request'
                          ) : (
                            <>
                              Original request:{' '}
                              {formatTime(
                                location.requestedTimestampMs,
                                location.timeZoneIdentifier,
                                alwaysShowTimestampInfo,
                                true,
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    {location.arrivalTimestampMs && (
                      <div className="row mb-2 mb-lg-0">
                        <div className="col-auto">
                          <FontAwesome.LongArrowAltDown className="text-gray-500" square marginRight />
                        </div>
                        <div className="col">Arrived</div>
                        <div className="col col-lg-auto d-flex justify-content-end">
                          {formatTime(
                            location.arrivalTimestampMs,
                            location.timeZoneIdentifier,
                            alwaysShowTimestampInfo,
                            false,
                          )}
                        </div>
                      </div>
                    )}

                    {location.completionTimestampMs && location.arrivalTimestampMs && (
                      <div className="row mb-2 mb-lg-0">
                        <div className="col-auto">
                          <FontAwesome.LongArrowAltDown className="text-gray-500" square marginRight />
                        </div>
                        <div className="col">Stayed</div>
                        <div className="col col-lg-auto d-flex justify-content-end">
                          {capitalize(
                            moment
                              .duration(location.completionTimestampMs - location.arrivalTimestampMs, 'milliseconds')
                              .humanize(false, {m: 60, h: 24}),
                          )}
                        </div>
                      </div>
                    )}

                    {location.completionTimestampMs && (
                      <div className={joinClassNames('row mb-2 mb-lg-0', wasLate ? 'text-danger' : 'text-success')}>
                        <div className="col-auto">
                          {wasLate ? (
                            <FontAwesome.ExclamationCircleSolid square marginRight />
                          ) : (
                            <FontAwesome.CheckCircleSolid marginRight />
                          )}
                        </div>
                        <div className="col">Completed</div>
                        <div className="col col-lg-auto d-flex justify-content-end">
                          {formatTime(
                            location.completionTimestampMs,
                            location.timeZoneIdentifier,
                            alwaysShowTimestampInfo,
                            false,
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* arrow */}

              {i < locations.length - 1 && (
                <div className="col-md-auto d-flex align-items-center justify-content-center p-2">
                  <FontAwesome.ArrowDownSolid className="d-md-none mx-2 mx-md-3" />
                  <FontAwesome.ArrowRightSolid className="d-none d-md-inline mx-2 mx-md-3" />
                </div>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
}

function Heading({children}) {
  return (
    <p className="mb-2">
      <strong>{children}</strong>
    </p>
  );
}

function WaitTime({seconds}) {
  return unwrap(seconds, seconds => (
    <div className="mt-3">
      <Heading>Task time</Heading>
      <div>{MomentHelper.daysHoursMinutesFromSeconds(seconds)}</div>
    </div>
  ));
}

function TripContent({content}) {
  const titleContainsAsterisk = /^\(.+\)$/.test(content.title);
  const parsedTitle = content.title; // titleContainsAsterisk ? content.title.slice(1, content.title.length - 1) : content.title
  return (
    <div className={joinClassNames(titleContainsAsterisk && 'pl-4 text-gray-600')}>
      {content.quantity} {parsedTitle}
    </div>
  );
}

function formatTime(timestamp, timeZoneIdentifier, alwaysShowTimestampInfo, includeDate) {
  const guessed = moment.tz.guess();
  return (
    <>
      {timeZoneIdentifier && guessed && timeZoneIdentifier !== guessed && (
        <span className={joinClassNames('text-gray-600 mr-3 nowrap', !alwaysShowTimestampInfo && 'show-on-hover')}>
          {format(timestamp, guessed, 'LT z')}
        </span>
      )}

      <span className="nowrap">
        {format(
          timestamp,
          timeZoneIdentifier || guessed,
          'LT z',
          // includeDate
          //     ? 'M/D · LT z'
          //     : 'LT z',
        )}
      </span>
    </>
  );
}

function format(timestamp, timeZoneIdentifier, formatString) {
  return timeZoneIdentifier
    ? moment(timestamp).tz(timeZoneIdentifier).format(formatString)
    : moment(timestamp).format(formatString);
}
