import firebase from 'firebase/app';
import {useMemo} from 'react';
import {useAuthContext} from '../contexts/AuthContext';
import unwrap from '../functions/unwrap';
import Trip from '../models/scoopm/Trip';
import TripStatus from '../models/scoopm/TripStatus';
import useFirestoreQueryListener from './useFirestoreQueryListener';

export default function useActiveTrips() {
  const {user} = useAuthContext();
  const query = useMemo(
    () =>
      unwrap(user, user =>
        firebase
          .firestore()
          .collection('trips')
          .where('customerId', '==', user.uid)
          .where(
            'status',
            'in',
            [TripStatus.created, TripStatus.accepted, TripStatus.started].map(s => s.rawValue),
          ),
      ),
    [user],
  );
  return useFirestoreQueryListener(query, Trip);
}
