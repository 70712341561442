import firebase from 'firebase/app';
import {ReadResult} from 'wave-common/lib/data-sources/DataSource';
import Interface, {Options} from 'wave-common/lib/data-sources/FirestoreDataSource';
import logPromise from '../functions/logPromise';

const FirestoreDataSource: Interface = {
  FieldValue: firebase.firestore.FieldValue,

  GeoPoint: firebase.firestore.GeoPoint,

  serverTimestamp: firebase.firestore.FieldValue.serverTimestamp,

  create: async function (path: string, data: firebase.firestore.DocumentData): Promise<{id: string}> {
    const snapshot = await logPromise(`create object at "${path}"`, firebase.firestore().collection(path).add(data));
    return {id: snapshot.id};
  },

  read: async function (
    path: string,
    id: string,
  ): Promise<{
    value: firebase.firestore.DocumentData;
    id?: string | undefined;
  }> {
    const snapshot = await logPromise(`read "${path}/${id}"`, firebase.firestore().collection(path).doc(id).get());
    if (!snapshot.exists) {
      throw new Error(`No value at "${path}/${id}"`);
    }
    return {value: snapshot.data()!, id: snapshot.id};
  },

  update: async function (path: string, id: string, values: any): Promise<void> {
    await logPromise(`update "${path}/${id}"`, firebase.firestore().collection(path).doc(id).update(values));
  },

  delete: async function (path: string, id: string): Promise<void> {
    await logPromise(`delete "${path}/${id}"`, firebase.firestore().collection(path).doc(id).delete());
  },

  list: async function (path: string, options?: Options): Promise<ReadResult<firebase.firestore.DocumentData>[]> {
    let query = firebase.firestore().collection(path) as firebase.firestore.Query;
    if (options) {
      if (options.orderBy) query = query.orderBy(options.orderBy, options.orderByDirection);
      if (options.startAt) query = query.startAt(options.startAt);
      if (options.endAt) query = query.endAt(options.endAt);
      if (options.where) {
        for (const where of options.where) {
          query = query.where(where[0], where[1], where[2]);
        }
      }
    }
    const snapshot = await logPromise(`query "${path}"`, query.get());

    return snapshot.docs.map(doc => ({value: doc.data(), id: doc.id}));
  },
};

export default FirestoreDataSource;
