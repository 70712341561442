import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import UserGuard from '../../guards/UserGuard';
import {VerticalPaddedContainerFluid} from '../../layouts/main/VerticalPaddedContainer';
import Guard from './Guard';

export default function Dispatch({children}) {
  return (
    <VerticalPaddedContainerFluid>
      <UserGuard>
        <Guard>
          <div className="row flex-grow-1 overflow-scroll">
            <div style={{minWidth: '15rem'}} className="col-md-auto mb-3 mb-md-0">
              <MenuLink to="/dispatch" fa="fas fa-truck">
                Active jobs
              </MenuLink>
              <MenuLink to="/dispatch/history" fa="fas fa-history">
                History
              </MenuLink>
              <hr className="my-2 d-none d-md-block" />
              <MenuLink to="/dispatch/create" fa="fas fa-plus-circle">
                Create order
              </MenuLink>
            </div>
            <div className="col-md overflow-scroll">{children}</div>
          </div>
        </Guard>
      </UserGuard>
    </VerticalPaddedContainerFluid>
  );
}

function MenuLink({to, fa, children}) {
  const location = useLocation();

  return (
    <Link
      to={to}
      className={'d-block p-2 m-1' + (location.pathname === to ? ' te xt-primary font-weight-bold text-primary' : '')}>
      <i className={fa + ' mr-2'} />
      {children}
    </Link>
  );
}
