import firebase from 'firebase/app';
import chunk from 'lodash/chunk';
import isEqual from 'lodash/isEqual';
import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from '../../../components/Spinner';
import unwrap from '../../../functions/unwrap';
import TransportType from '../../../models/scoopm/TransportType';
import ScoopMApi from '../../../references/scoopm-api';
// import vehicles from '../../../fuel-vehicles.json';

export default function UpdateVehiclesDatabaseButton() {
  const [isUpdating, setIsUpdating] = useState(false);

  const update = event => {
    if (!window.confirm('DANGER: ARE YOU SURE YOU WANT TO DO THIS?')) {
      return;
    }

    const run = async () => {
      // fetch FUEL vehicles

      const fuelResponse = await ScoopMApi.instance.vehicles.fuel.get(); // while in development, use the JSON file instead of actually fetcing from FUEL
      const vehicles = fuelResponse.data;
      console.log(vehicles);

      // years

      const years = [];
      for (const vehicle of vehicles) {
        const year = Number(vehicle.year);
        if (!years.includes(year) && year > 1900) {
          years.push(year);
        }
      }
      console.log(years);

      // makes

      const makes = [];
      for (const vehicle of vehicles) {
        const year = Number(vehicle.year);
        if (!makes.find(make => make.name === vehicle.make_name)) {
          makes.push({
            name: vehicle.make_name,
            years: [year],
          });
        } else {
          const index = makes.findIndex(make => make.name === vehicle.make_name);
          if (!makes[index].years.includes(year)) {
            makes[index].years.push(year);
          }
        }
      }
      console.log(makes);

      // models

      const models = [];
      for (const vehicle of vehicles) {
        const year = Number(vehicle.year);
        if (
          !models.find(
            model => model.year === year && model.make === vehicle.make_name && model.name === vehicle.model_name,
          )
        ) {
          models.push({
            year: year,
            make: vehicle.make_name,
            name: vehicle.model_name,
          });
        }
      }
      console.log(models);

      // trims

      const trims = [];
      for (const vehicle of vehicles) {
        const year = Number(vehicle.year);
        if (
          !trims.find(
            trim =>
              trim.year === year &&
              trim.make === vehicle.make_name &&
              trim.model === vehicle.model_name &&
              trim.name === vehicle.trim,
          )
        ) {
          trims.push({
            year: Number(vehicle.year),
            make: vehicle.make_name,
            model: vehicle.model_name,
            name: vehicle.trim || null,
            fuelVehicleId: vehicle.id,
            transportType: unwrap(TransportType.forFuelBodyType(vehicle.bodytype), type => type.rawValue),
          });
        }
      }
      console.log(trims);

      // update DB

      const firestore = firebase.firestore();
      const yearsReference = firestore.collection('vehicle-years');
      const makesReference = firestore.collection('vehicle-makes');
      const modelsReference = firestore.collection('vehicle-models');
      const trimsReference = firestore.collection('vehicle-trims');

      // find unique years

      const oldYearsSnapshot = await yearsReference.get();
      const oldYears = oldYearsSnapshot.docs.map(doc => doc.get('value'));
      const newYears = years.filter(year => !oldYears.includes(year));

      // update years

      console.log(`Setting ${newYears.length} years`);
      const yearsBatch = firestore.batch();
      for (const year of newYears) {
        const yearReference = yearsReference.doc();
        yearsBatch.set(yearReference, {value: year});
      }
      await yearsBatch.commit();

      // find unique makes

      const oldMakesSnapshot = await makesReference.get();
      const oldMakes = oldMakesSnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
      // const newMakes = makes.filter(make => !oldMakes.find(oldMake => isEqual(oldMake, make)));

      // update makes

      console.log(`Setting/updating makes`);
      const makesBatch = firestore.batch();
      for (const make of makes) {
        const oldMake = oldMakes.find(oldMake => oldMake.name === make.name);
        if (oldMake) {
          makesBatch.update(makesReference.doc(oldMake.id), {years: make.years});
        } else {
          const makeReference = makesReference.doc();
          makesBatch.set(makeReference, make);
        }
      }
      await makesBatch.commit();

      // find unique models

      const oldModelsSnapshot = await modelsReference.get();
      const oldModels = oldModelsSnapshot.docs.map(doc => doc.data());
      const newModels = models.filter(model => !oldModels.find(oldModel => isEqual(oldModel, model)));

      // update models

      console.log(`Setting ${newModels.length} models`);
      const newModelsChunks = chunk(newModels, 500);
      for (const chunk of newModelsChunks) {
        console.log('Chunk', chunk.length);
        const modelsBatch = firestore.batch();
        for (const model of chunk) {
          const modelReference = modelsReference.doc();
          modelsBatch.set(modelReference, model);
        }
        await modelsBatch.commit();
      }

      // find unique trims

      const oldTrimsSnapshot = await trimsReference.get();
      const oldTrims = oldTrimsSnapshot.docs.map(doc => doc.data());
      const newTrims = trims.filter(trim => !oldTrims.find(oldTrim => isEqual(oldTrim, trim)));

      // update trims

      console.log(`Setting ${newTrims.length} trims`);
      const newTrimsChunks = chunk(newTrims, 500);
      for (const chunk of newTrimsChunks) {
        console.log('Chunk', chunk.length);
        const trimsBatch = firestore.batch();
        for (const trim of chunk) {
          const trimReference = trimsReference.doc();
          trimsBatch.set(trimReference, trim);
        }
        await trimsBatch.commit();
      }

      console.log('DONE');
    };

    setIsUpdating(true);
    run()
      .catch(console.error)
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <Button variant="warning" onClick={update} disabled={isUpdating}>
      {isUpdating && <Spinner small />} Update Vehicles Database
    </Button>
  );
}
