import {Driver} from 'wave-common';
import ScoopMApi from '.';
import HttpReference from '../platform/HttpReference';
import Applicant from './Applicant';

export default class Drivers extends HttpReference<void, Applicant, ScoopMApi> {
  applicant: Applicant;

  constructor(path: string, parent: ScoopMApi) {
    super(path, parent);

    this.applicant = new Applicant('applicant', this);
  }

  read({email}: {email?: string}) {
    if (email) {
      return this.request<Driver>({
        params: {
          email,
        },
      });
    }
  }
}
