import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import joinClassNames from '../functions/joinClassNames';
import useIsMountedRef from '../hooks/useIsMountedRef';
import UserType from '../models/scoopm/UserType';
import BootstrapModalContext from '../routes/BootstrapModalContext';
import FirebaseImageStore from '../services/FirebaseImageStore';
import {ModalContent} from './SquareImage';

export default function FirebaseStorageCircleImage({
  path,
  fallbackUrl = UserType.customer.imgSrc,
  link,
  diameterRem = 4,
  className = undefined,
  containerClassName = undefined,
  style = undefined,
  modal = false,
  ...props
}: {
  path?: string | null;
  fallbackUrl?: string;
  link?: string;
  diameterRem?: number;
  className?: string;
  containerClassName?: string;
  style?: any;
  modal?: boolean;
}) {
  const isMountedRef = useIsMountedRef();

  const {setModalContent} = useContext(BootstrapModalContext);

  const [url, setUrl] = useState(FirebaseImageStore.instance.urlNullOrFallback(path, fallbackUrl));

  useEffect(() => {
    if (path) {
      FirebaseImageStore.instance.fetchUrl(path).then(
        url => {
          if (isMountedRef.current) setUrl(url);
        },
        error => {
          if (isMountedRef.current) setUrl(fallbackUrl);
        },
      );
    } else {
      setUrl(fallbackUrl);
    }
  }, [path, fallbackUrl, isMountedRef]);

  const combinedStyle = useMemo(
    () => ({
      width: `${diameterRem}rem`,
      height: `${diameterRem}rem`,
      borderRadius: `${diameterRem / 2}rem`,
      objectFit: 'cover',
      ...(style || {}),
    }),
    [diameterRem, style],
  );

  const canShowModal = Boolean(modal && path && url && url !== fallbackUrl);

  return link ? (
    <Link to={link} className={joinClassNames(containerClassName, canShowModal && 'cursor-pointer')} {...props}>
      <img src={url} alt="" style={combinedStyle} className={joinClassNames('profile-card-image', className)} />
    </Link>
  ) : (
    <div
      onClick={
        canShowModal
          ? () => setModalContent(<ModalContent url={url} onDismiss={() => setModalContent(null)} />)
          : undefined
      }
      className={joinClassNames(containerClassName, canShowModal && 'cursor-pointer')}
      {...props}>
      <img src={url} alt="" style={combinedStyle} className={joinClassNames('profile-card-image', className)} />
    </div>
  );
}
