"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fountainApplicantTokenListForApplicantId = exports.fountainApplicantTokenCreate = void 0;
const FountainApplicantToken_1 = require("../models/FountainApplicantToken");
const PATH = 'fountain-applicant-tokens';
async function fountainApplicantTokenCreate(token, firestore) {
    return firestore.create(PATH, token);
}
exports.fountainApplicantTokenCreate = fountainApplicantTokenCreate;
async function fountainApplicantTokenListForApplicantId(applicantId, firestore) {
    const results = await firestore.list(PATH, { where: [['applicantId', '==', applicantId]] });
    return results.map(result => (0, FountainApplicantToken_1.fountainApplicantTokenDeserialize)(result.value, result.id));
}
exports.fountainApplicantTokenListForApplicantId = fountainApplicantTokenListForApplicantId;
