import Constants from './Constants';

const createPalette = prefersDarkMode => ({
  type: prefersDarkMode ? 'dark' : 'light',
  primary: {
    main: Constants.theme.primaryColorHexString,
  },
  secondary: {
    main: Constants.theme.secondaryColorHexString,
  },
  // contrastThreshold: 1,
});

export default createPalette;
