import HttpReference from '../platform/HttpReference';
import firebase from 'firebase/app';
import CreateTokenRequest from '../../models/scoopm/CreateTokenRequest';
import StreamMemberType from '../../models/scoopm/StreamMemberType';
import StreamType from '../../models/scoopm/StreamType';

class Token extends HttpReference {
  async create(tripIdOrDriverId) {
    let idToken = null;
    try {
      idToken = await firebase.auth().currentUser.getIdToken();
    } catch (error) {
      console.error('Error getting ID token', error);
      throw error;
    }

    return await this.request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      data: new CreateTokenRequest(StreamType.driverHQ, StreamMemberType.admin, tripIdOrDriverId),
    });
  }
}

export default Token;
