import React, {ReactChild, StyleHTMLAttributes, useState} from 'react';
import useStatus, {StatusButton} from '../../hooks/useStatus';
import AdjustingTextArea from '../AdjustingTextArea';

export default function EditableTextarea(props: {
  value?: string;
  onSave: (value: string) => Promise<boolean>;
  placeholder?: string;
  rows?: number;
  className?: string;
  style?: StyleHTMLAttributes<HTMLTextAreaElement>;
  children?: ReactChild;
}) {
  const [savedValue, setSavedValue] = useState(props.value ?? String(props.children) ?? '');
  const [value, setValue] = useState(savedValue);
  const {status, handlePromise} = useStatus();

  return (
    <>
      <AdjustingTextArea
        defaultValue={props.value}
        onChange={event => setValue(event.target.value)}
        placeholder={props.placeholder || 'Type here...'}
        rows={props.rows || 6}
        className={props.className}
        style={props.style}
      />

      <div className="d-flex flex-column align-items-end mt-2">
        <StatusButton
          onClick={() => {
            const valueToSave = value;
            handlePromise(
              props.onSave(valueToSave).then(() => {
                setSavedValue(valueToSave);
              }),
            );
          }}
          status={status}
          className={
            value !== savedValue ? 'btn btn-sm btn-success' : status === 'IDLE' ? 'd-none' : 'btn btn-sm btn-light'
          }
          disabled={value === savedValue}
        >
          {status === 'PENDING' ? 'Saving' : status === 'SUCCESS' ? 'Saved' : 'Save'}
        </StatusButton>
      </div>
    </>
  );
}
