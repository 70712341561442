import React, {useCallback, useMemo} from 'react';
import ContactCard from '../../../../components/ContactCard';
import unwrap from '../../../../functions/unwrap';
import Trip from '../../../../models/scoopm/Trip';
import TripContact from '../../../../models/scoopm/TripContact';
import TripDriver from '../../../../models/scoopm/TripDriver';
import UserType from '../../../../models/scoopm/UserType';
import RealtimeDatabase from '../../../../references/database/RealtimeDatabase';
import ConfirmButton from './ConfirmButton';
import ZoomButton from './ZoomButton';

export default function DriverCard(props: {
  trip: Trip;
  selectedDriverId?: string;
  setSelectedDriverId: (id?: string) => void;
}) {
  const contact = useMemo(
    () =>
      unwrap(props.trip.contactsWithDefault(), contacts =>
        contacts.find(contact => contact.userType === UserType.driver.rawValue),
      ) || (props.trip.isDone() ? TripContact.none() : undefined),
    [props.trip],
  );

  const dispatch = useCallback(
    (driverId: string, driver: TripDriver) => (event: React.MouseEvent) => {
      if (!window.confirm(`Dispatch to ${driver.firstName} ${driver.lastName}?`)) {
        return;
      }
      RealtimeDatabase.instance.batchUpdate_dispatchTrip(props.trip.id, props.trip, driverId, driver).catch(error => {
        window.alert(`There was an error dispatching the trip. "${error.message}"`);
      });
    },
    [props.trip],
  );

  if (!contact) return null;

  return (
    <div>
      {/* heading */}

      <div className="row no-gutters p-2 p-md-3 bg-gray-200">
        <div className="col">
          <h5 className="m-0">{props.trip.confirmedAt ? 'Dispatched driver' : 'Assigned driver'}</h5>
        </div>
      </div>

      <div className="p-2 p-md-3 bg-white border-bottom">
        {/* contact info */}

        <div className="row no-gutters mb-2">
          <div className="col">
            <ContactCard contact={contact} location={undefined} distanceMi={undefined} />
          </div>
          {!props.trip.isDone() && (
            <div className="col-auto d-flex align-items-center">
              <ZoomButton
                driverId={props.trip.driverId}
                selectedDriverId={props.selectedDriverId}
                setSelectedDriverId={props.setSelectedDriverId}
              />
            </div>
          )}
        </div>

        <div className="row justify-content-end">
          {/* confirm button */}

          {!props.trip.isDone() && (
            <div className="col-auto">
              <ConfirmButton
                tripId={props.trip.id}
                customerId={props.trip.customerId}
                driverConfirmationTimestampMs={props.trip.driverConfirmationTimestampMs}
              />
            </div>
          )}

          {/* start button */}

          {!props.trip.confirmedAt && props.trip.driver && (
            <div className="col-auto">
              <button
                onClick={dispatch(props.trip.driverId, props.trip.driver)}
                className="btn btn-sm btn-outline-success">
                Start…
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
