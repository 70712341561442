import HttpReference from '../platform/HttpReference';
import AcceptQuote from './OloAcceptQuote';

export default class OloQuote extends HttpReference {
  constructor(path, parent) {
    super(path, parent);

    this.accept = new AcceptQuote('accept', this);
  }
}
