import firebase from 'firebase/app';
import filter from 'lodash/filter';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, {useContext, useMemo, useState} from 'react';
import {Badge, Button} from 'react-bootstrap';
import Alert from '../../components/Alert/Alert';
import FirebaseStorageCircleImage from '../../components/FirebaseStorageCircleImage';
import FontAwesome from '../../components/FontAwesome';
import Join from '../../components/Join';
import LoadingAnimation from '../../components/LoadingAnimation';
import StandardSpacer from '../../components/Spacer';
import {useAlert} from '../../contexts/AlertContext';
import {useAuthContext} from '../../contexts/AuthContext';
import expect from '../../functions/expect';
import {unwrap} from '../../functions/unwrap';
import useCallbackCaller from '../../hooks/useCallbackCaller';
import useFirestoreQueryListener from '../../hooks/useFirestoreQueryListener';
import {useUserGroupForSelectedVendor} from '../../hooks/useUserGroups';
import {UserWithAuthData, useUsersWithAuthData} from '../../hooks/useUsers';
import CustomClaim from '../../models/scoopm/CustomClaim';
import Invite from '../../models/scoopm/Invite';
import UserGroupInvite from '../../models/scoopm/UserGroupInvite';
import UserType from '../../models/scoopm/UserType';
import ScoopMApi from '../../references/scoopm-api';

export default function Team() {
  const {uid, claims} = useAuthContext();

  const {isLoading: groupIsLoading, error: groupError, group} = useUserGroupForSelectedVendor();

  const [groupMembers, setGroupMembers] = useState(group?.members); // this is a special state variable which can be manually set to force a refresh of users
  useCallbackCaller(setGroupMembers, group?.members);
  function refreshUsers() {
    setGroupMembers([...(groupMembers ?? [])]);
  }

  const {isLoading: usersAreLoading, error: usersError, users} = useUsersWithAuthData(groupMembers);

  const inviteReference = useMemo(
    () =>
      unwrap(group?.id, id =>
        firebase
          .firestore()
          .collection('user-group-invites')
          .where('isAccepted', '==', false)
          .where('groupId', '==', id),
      ),
    [group?.id],
  );
  const {
    isLoading: invitesAreLoading,
    error: invitesError,
    models: invites,
  } = useFirestoreQueryListener(inviteReference, UserGroupInvite);
  const sortedInvites = useMemo(
    () =>
      unwrap(invites, invites =>
        reverse(sortBy(filter(invites, i => i?.isCurrent()) as Invite[], 'expirationTimestampMs')),
      ),
    [invites],
  );

  return (
    <div>
      <Join
        array={[
          ...(users
            ? users.map(user => <UserCard key={user.id} user={user} myUid={uid} refreshUsers={refreshUsers} />)
            : ['a', 'b', 'c'].map(id => (
                <UserCard key={id} user={undefined} myUid={undefined} refreshUsers={refreshUsers} />
              ))),
          ...((users &&
            sortedInvites?.map(invite => (
              <UserCard
                key={invite?.id}
                user={undefined}
                myUid={undefined}
                invite={invite}
                refreshUsers={refreshUsers}
              />
            ))) ??
            []),
        ]}
        separator={StandardSpacer}
      />
      <StandardSpacer />
      <InviteButton disabled={!users || !claims || !claims[CustomClaim.manageOwnGroups]} groupId={group?.id} />
      {(claims === null || (claims && !claims[CustomClaim.manageOwnGroups])) && (
        <div>
          <small className="text-muted">Only admins can invite new users</small>
        </div>
      )}
    </div>
  );
}

function UserCard(props: {
  user: UserWithAuthData | undefined;
  myUid: string | undefined | null;
  invite?: Invite;
  refreshUsers: () => void;
}) {
  return (
    <div className="row align-items-center">
      <div className="col-auto">
        {props.user ?? props.invite ? (
          <FirebaseStorageCircleImage
            path={props.user?.id && `/profiles/${props.user.id}`}
            fallbackUrl={UserType.customer.imgSrc}
            diameterRem={2}
            containerClassName="d-block"
          />
        ) : (
          <LoadingAnimation style={{width: '2rem', height: '2rem', borderRadius: '1rem'}} />
        )}
      </div>
      <div className="col">
        {!props.user && !props.invite && <LoadingAnimation style={{height: '2rem'}} />}
        {props.user?.casualName()}
        {props.myUid && props.user?.id === props.myUid && ` (you)`}
        {props.user?.claims && props.user.claims[CustomClaim.manageOwnGroups] && (
          <>
            {' '}
            <Badge variant="primary">Admin</Badge>
          </>
        )}
        {props.invite && (
          <span className="text-muted">
            Invited {props.invite.email} (Expires {moment(props.invite.expirationTimestampMs).calendar()})
          </span>
        )}
      </div>
      {props.user && (
        <>
          <div className="col-auto">
            <EditButton
              userId={props.user.id}
              manageOwnGroups={Boolean(props.user.claims?.manageOwnGroups)}
              refreshUsers={props.refreshUsers}
            />
          </div>
          <div className="col-auto">
            <DeleteButton />
          </div>
        </>
      )}
    </div>
  );
}

function EditButton(props: {userId: string; manageOwnGroups: boolean; refreshUsers: () => void}) {
  const {setAlert} = useAlert();
  function onClick() {
    const result = {value: false};
    setAlert(
      new Alert(
        'Edit user',
        <CheckBox id={props.userId} defaultChecked={props.manageOwnGroups} result={result} />,
        'Save',
        () =>
          ScoopMApi.instance.users
            .child(props.userId)
            .setManageOwnGroups(result.value)
            .then(() => {
              props.refreshUsers();
            }),
        'Cancel',
      ),
    );
  }

  return (
    <Button onClick={onClick} variant="outline-secondary" size="sm" title="Edit">
      <FontAwesome.Edit />
    </Button>
  );
}

function CheckBox(props: {id: string; defaultChecked: boolean; result: {value: boolean}}) {
  const [checked, setChecked] = useState(props.defaultChecked);
  return (
    <div className="custom-control custom-switch">
      <input
        id={props.id + 'role-checkbox'}
        type="checkbox"
        checked={checked}
        onChange={event => {
          setChecked(event.target.checked);
          props.result.value = event.target.checked;
        }}
        className="custom-control-input"
      />
      <label htmlFor={props.id + 'role-checkbox'} className="custom-control-label">
        User can manage the group
      </label>
    </div>
  );
}

function DeleteButton() {
  const {setAlert} = useAlert();
  function onClick() {
    setAlert(new Alert('Remove user', 'Are you sure you want to remove this user?', 'Remove', async () => {}));
  }
  return (
    <Button onClick={onClick} variant="outline-danger" size="sm" title="Remove">
      <FontAwesome.TrashAlt />
    </Button>
  );
}

function InviteButton(props: {disabled: boolean; groupId: string | undefined}) {
  const {setAlert} = useAlert();

  function onClick() {
    const result = {value: ''};
    setAlert(
      new Alert(
        "Enter new member's email",
        (
          <>
            <p>The recipient will receive a link to join the team</p>
            <input
              defaultValue={result.value}
              onChange={event => (result.value = event.target.value)}
              type="email"
              autoComplete="email"
              placeholder="Email address"
              className="form-control"
            />
          </>
        ),
        'Invite',
        async () => {
          if (!result.value) {
            throw new Error('Please enter an email address before continuing');
          }
          await ScoopMApi.instance.userGroupInvites.create(result.value, expect(props.groupId));
        },
        'Cancel',
      ),
    );
  }

  return (
    <Button variant="primary" onClick={onClick} disabled={props.disabled}>
      Invite new member...
    </Button>
  );
}
