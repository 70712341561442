import firebase from 'firebase/app';
import querystringify from 'querystringify';
import React, {useMemo, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import unwrap from '../../functions/unwrap';
import AuthLayout from './AuthLayout';

export default function SignUp() {
  const history = useHistory();
  const location = useLocation();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [validation, setValidation] = useState();
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const signInLink = useMemo(
    () => unwrap(querystringify.parse(location.search).from, from => `/sign-in?from=${from}`, '/sign-in'),
    [location.search],
  );

  const onSubmit = event => {
    event.preventDefault();

    const validation = {
      firstName: Boolean(firstName),
      lastName: Boolean(lastName),
      email: Boolean(email),
      password: Boolean(password),
    };
    setValidation(validation);

    if (Object.values(validation).includes(false)) {
      return;
    }

    setIsSigningUp(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(user => firebase.database().ref(`/customers/${user.uid}`).set({firstName, lastName}))
      .then(
        () => {
          history.push(querystringify.parse(location.search).from || '/');
        },
        error => {
          setIsSigningUp(false);
          switch (error.code) {
            case 'auth/email-already-in-use':
              return setErrorMessage('Sorry, that email address is not available');
            case 'auth/invalid-email':
              return setErrorMessage("Sorry, that email address isn't valid");
            case 'auth/weak-password':
              return setErrorMessage('Sorry, that password is not strong enough');
            default:
              return setErrorMessage('Sorry, there was an error creating your account.');
          }
        },
      );
  };

  return (
    <AuthLayout>
      <form onSubmit={onSubmit}>
        <div className="px-2 py-3 p-md-4 mx-n2 mx-md-n4 my-3 my-md-4 bg-dark text-white">
          <p className="lead text-center">
            Sign up for Wave and receive launch news, discounts and free ride&nbsp;promotions!
          </p>
          <hr className="border-light my-3 my-md-4" />
          <h5 className="mb-1 mb-md-2">Bonus</h5>
          <p className="mb-0">
            Sign up now and get 1,000 free{' '}
            <a href="http://www.scoopxt.com/" className="text-primary">
              WaveCoin
            </a>{' '}
            tokens redeemable for free rides!
          </p>
        </div>

        <h3>Sign Up</h3>

        <p>
          Already have an account?{' '}
          <Link to={signInLink} className="text-primary">
            Sign in
          </Link>
        </p>

        {errorMessage && <div className="alert alert-warning">{errorMessage}</div>}

        <div className="mb-3">
          <input
            type="text"
            value={firstName || ''}
            onChange={event => setFirstName(event.target.value)}
            placeholder="First name"
            className={'form-control ' + (validation && !validation.firstName ? 'is-invalid' : '')}
            disabled={isSigningUp}
          />
          <div className="invalid-feedback">Required</div>
        </div>

        <div className="mb-3">
          <input
            type="text"
            value={lastName || ''}
            onChange={event => setLastName(event.target.value)}
            placeholder="Last name"
            className={'form-control ' + (validation && !validation.lastName ? 'is-invalid' : '')}
            disabled={isSigningUp}
          />
          <div className="invalid-feedback">Required</div>
        </div>

        <div className="mb-3">
          <input
            type="email"
            value={email || ''}
            onChange={event => setEmail(event.target.value)}
            placeholder="Email"
            className={'form-control ' + (validation && !validation.email ? 'is-invalid' : '')}
            disabled={isSigningUp}
          />
          <div className="invalid-feedback">Required</div>
        </div>

        <div className="mb-3">
          <input
            type="password"
            value={password || ''}
            onChange={event => setPassword(event.target.value)}
            placeholder="Password"
            className={'form-control ' + (validation && !validation.password ? 'is-invalid' : '')}
            disabled={isSigningUp}
          />
          <div className="invalid-feedback">Required</div>
        </div>

        <div className="mb-1">
          <input type="submit" value="Create account" className="btn btn-primary" disabled={isSigningUp} />
        </div>

        <small className="mb-3">
          By clicking "Create account", you agree to our <Link to="/terms-and-conditions">Terms and Conditions</Link>{' '}
          and <Link to="/privacy-policy">Privacy Policy</Link>
        </small>
      </form>
    </AuthLayout>
  );
}
