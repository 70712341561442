import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {lowercaseFirstLetter} from 'wave-common';
import FontAwesome from '../../components/FontAwesome';
import joinClassNames from '../../functions/joinClassNames';
import unwrap from '../../functions/unwrap';
import PropertyType from '../types/PropertyType';

export default function PropertyForm({
  property,
  value,
  model,
  onChange,
  validationError,
  disabled,
  isCustomerFacing = false,
}) {
  const FormComponent = PropertyType.FormComponent(property.type);

  const id = property.name + 'Input';

  return (
    <div className="form-group">
      <label htmlFor={id}>{property.userFacingName}</label>
      {property.isArray ? (
        <>
          {PropertyType.arrayDisplayComponent(property.type) ? (
            <FormComponent
              // index={i}
              // key={id + i}
              property={property}
              id={id}
              value={value}
              model={model}
              onChange={onChange}
              validationError={validationError}
              disabled={disabled || property.readonly}
              isCustomerFacing={isCustomerFacing}
            />
          ) : (
            <div className="bg-light rounded px-2 px-md-3 pt-2 pt-md-3">
              {(value ?? []).map((itemValue, i, array) => (
                <div key={id + i} className="row no-gutters mb-1 mb-md-2">
                  <div className="col mb-2 mb-md-3">
                    <FormComponent
                      index={i}
                      key={id + i}
                      property={property}
                      id={id + i}
                      value={itemValue || null}
                      model={model}
                      onChange={newValue => {
                        if (!newValue) return;
                        const newArray = unwrap(value, array => [...array], []);
                        newArray.splice(i, 1, newValue);
                        onChange(newArray);
                      }}
                      validationError={unwrap(validationError, error => error[i])}
                      disabled={disabled || property.readonly}
                      isCustomerFacing={isCustomerFacing}
                    />
                  </div>
                  <div className="col-auto">
                    <OverlayTrigger overlay={<Tooltip>Remove this item</Tooltip>}>
                      <button
                        type="button"
                        onClick={event => {
                          event.preventDefault();
                          const newArray = unwrap(value, array => [...array], []);
                          newArray.splice(i, 1);
                          if (newArray.length) {
                            onChange(newArray);
                          } else {
                            onChange(null);
                          }
                        }}
                        className="btn btn-light"
                        disabled={!itemValue || disabled || property.readonly}>
                        <i className={joinClassNames('fas fa-trash-alt', !itemValue && 'text-muted')} />
                      </button>
                    </OverlayTrigger>
                  </div>
                </div>
              ))}

              <button
                type="button"
                onClick={() => onChange([...(value ?? []), null])}
                className="btn btn-outline-secondary mb-3">
                <FontAwesome.Plus /> Add {lowercaseFirstLetter(property.userFacingName.slice(undefined, -1))}
              </button>
            </div>
          )}
        </>
      ) : (
        <FormComponent
          id={id}
          property={property}
          value={value}
          model={model}
          onChange={onChange}
          validationError={validationError}
          disabled={disabled || property.readonly}
          isCustomerFacing={isCustomerFacing}
        />
      )}

      {property.isOptional && <small className="text-muted">Optional</small>}
    </div>
  );
}
