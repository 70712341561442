import React, {useEffect, useState} from 'react';

/**
 * You can specify a 'selected' string for the selected tab id, or allow TabContainer to manage this internally
 */
const TabContainer = ({tabs, selected = null, onSelect = null, onClickPlus}) => {
  // state

  const [interalSelected, setInternalSelected] = useState(tabs ? Object.keys(tabs)[0] : null);
  const [selectedComponent, setSelectedComponent] = useState();

  // selected --> render new tab component

  useEffect(() => {
    try {
      const selectedComponent = tabs ? tabs[selected || interalSelected].render() : null;
      setSelectedComponent(selectedComponent);
    } catch (error) {
      setSelectedComponent(null);
    }
  }, [selected, interalSelected, tabs]);

  // view events

  const onClickTabTitle = i => event => {
    const tabId = Object.keys(tabs)[i];
    onSelect && onSelect(tabId);
    setInternalSelected(tabId);
  };

  // render

  return (
    <div>
      {/* tab buttons */}
      <div className="d-flex overflow-scroll">
        {tabs &&
          Object.values(tabs).map((tab, i) => (
            <TabButton
              key={i}
              text={tab.title}
              onClick={onClickTabTitle(i)}
              selected={tab === tabs[selected || interalSelected]}
            />
          ))}
        {onClickPlus && <TabButton text={<i className="fas fa-plus fa-sm"></i>} onClick={onClickPlus} />}
      </div>

      {/* selected tab */}
      <div className="bg-white p-2 p-md-3 rounded-bottom">{selectedComponent}</div>
    </div>
  );
};

function TabButton({text, onClick, selected}) {
  return (
    <div
      onClick={onClick}
      className={'px-4 py-3 mr-2 rounded-top cursor-pointer' + (selected ? ' bg-white' : ' bg-gray-200')}
      style={{whiteSpace: 'nowrap'}}
    >
      {text}
    </div>
  );
}

export default TabContainer;
