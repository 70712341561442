import React from 'react';
import FontAwesome from '../../../components/FontAwesome';

export default function MenuToggleButton(props: {isExpanded: boolean; setIsExpanded: (isExpanded: boolean) => void}) {
  return (
    <button
      type="button"
      onClick={event => props.setIsExpanded(!props.isExpanded)}
      className="d-md-none btn btn-sm bg-white">
      {props.isExpanded ? <FontAwesome.Minus /> : <FontAwesome.Plus />}
    </button>
  );
}
