import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import FirebaseStorageCircleImage from '../../components/FirebaseStorageCircleImage';
import {useIsMinimalUi} from '../../contexts/MinimalUiContext';
import ThemeColorContext from '../../contexts/ThemeColorContext';
import isDark from '../../functions/isDark';
import joinClassNames from '../../functions/joinClassNames';
import unwrap from '../../functions/unwrap';
import ClaimsGuard from '../../guards/ClaimsGuard';
import useUriIsSelected from '../../hooks/useLocationIsSelected';
import useUserData from '../../hooks/useUserData';
import logoWhite from '../../images/logo.png';
import CustomClaim from '../../models/scoopm/CustomClaim';
import UserType from '../../models/scoopm/UserType';
import links from '../../routes/home/links';
import HeaderLink from './HeaderLink';
import VendorSwitcher from './VendorSwitcher';

export default function Header() {
  const {user, data, type} = useUserData();

  const {themeColor} = useContext(ThemeColorContext);

  const isDarkTheme = isDark(themeColor);

  const isMinimal = useIsMinimalUi();

  return (
    <header>
      <nav
        nav="true"
        className={joinClassNames(
          'navbar navbar-expand-xl p-1 bg-blur',
          !isMinimal && 'shadow-light',
          isDarkTheme ? 'navbar-dark' : 'navbar-light',
        )}
        style={{background: themeColor}}>
        <div className="container">
          <Link to="/#top" className="navbar-brand">
            <img src={logoWhite} alt="Logo" width="48" />
          </Link>

          {!isMinimal && (
            <>
              <button
                className="navbar-toggler border-0"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <i className={joinClassNames('fas fa-bars', isDarkTheme ? 'text-white' : 'text-dark')} />
              </button>

              <div className="collapse navbar-collapse p-2" id="navbarCollapse">
                <ul className="navbar-nav mr-auto">
                  {links.map(link => (
                    <HeaderLink key={link.to} to={link.to} isDarkTheme={isDarkTheme}>
                      {link.label}
                    </HeaderLink>
                  ))}
                </ul>

                {user !== undefined && (
                  <ul className="navbar-nav">
                    <div className="d-lg-none my-0 mx-1">&mdash;</div>

                    {user && (
                      <HeaderLink to="/jobs" isDarkTheme={isDarkTheme}>
                        My rides
                      </HeaderLink>
                    )}

                    <ClaimsGuard claim={CustomClaim.manualEntry}>
                      <HeaderLink to="/dispatch" isDarkTheme={isDarkTheme}>
                        Dispatch portal
                      </HeaderLink>
                    </ClaimsGuard>

                    {type === UserType.admin && (
                      <li className="nav-item">
                        <Link
                          to="/admin"
                          className={joinClassNames('nav-link', isDarkTheme ? 'text-light' : 'text-dark')}>
                          {/* <i className="fas fa-lock" /> */}Admin portal
                        </Link>
                      </li>
                    )}

                    <ProfileItem data={data} user={user} type={type} isDarkTheme={isDarkTheme} />

                    <VendorSwitcher />

                    {!user && (
                      <li className="nav-item my-1 my-xl-0">
                        <Link to="/sign-in" className="btn btn-primary">
                          Sign in
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}

function ProfileItem({data, user, type, isDarkTheme}) {
  const isSelected = useUriIsSelected('/profile');
  return (
    data &&
    data.firstName && (
      <li className="nav-item d-flex align-items-center mt-4 mt-xl-0 mb-2 mb-xl-0 mr-xl-3 ml-xl-4">
        <div className="nav-text d-flex align-items-center">
          <FirebaseStorageCircleImage
            path={`/profiles/${user.uid}`}
            fallbackUrl={unwrap(type, type => type.imgSrc)}
            diameterRem={2}
            containerClassName="mr-2"
          />
          <Link
            to="/profile"
            className={joinClassNames(isDarkTheme ? 'text-light' : 'text-dark', isSelected && 'font-weight-bold')}>
            {data.nickName || data.firstName}
          </Link>
        </div>
      </li>
    )
  );
}
