import {useEffect, useState} from 'react';

// TYPES

type Status_Base<Type extends string> = {type: Type};
export type LoadingStatus = Status_Base<'LOADING'>;
export type FailureStatus = Status_Base<'FAILURE'> & {error: Error};
export type SuccessStatus<T> = Status_Base<'SUCCESS'> & {result: T};
export type Status<T> = LoadingStatus | FailureStatus | SuccessStatus<T>;

export type SubscribeFunction<T, U = undefined> = (
  setupResult: U,
  handleSuccess: (result: T) => void,
  handleFailure: (error: Error) => void,
) => UnsubscribeFunction;

export type UnsubscribeFunction = () => void;

// HOOK

export default function useListenerStatus<T, U>(subscribe: SubscribeFunction<T, U>, setup?: () => Promise<U>) {
  const [status, setStatus] = useState<Status<T>>({type: 'LOADING'});

  useEffect(() => {
    let ubsubscribe: () => void;

    const setupPromise = setup ? setup() : Promise.resolve(undefined);
    setupPromise.then(setupResult => {
      ubsubscribe = subscribe(
        setupResult as never,
        result => {
          setStatus({type: 'SUCCESS', result});
        },
        error => {
          setStatus({type: 'FAILURE', error});
        },
      );
    });

    return () => {
      if (ubsubscribe) {
        ubsubscribe();
      }
    };
  }, [setup, subscribe]);

  return {
    status,
    isLoading: status.type === 'LOADING',
    error: (status as FailureStatus)?.error,
    result: (status as SuccessStatus<T>)?.result as T | undefined,
  };
}
