import firebase from 'firebase/app';
import CustomClaim from '../../models/scoopm/CustomClaim';
import Location from '../../models/scoopm/Location';
import VendorApplicationModel from '../../models/scoopm/VendorApplication';
import VendorApplication from '../additional-content-components/VendorApplication';
import Property from '../types/Property';
import PropertyType from '../types/PropertyType';
import Schema from '../types/Schema';

export interface Interface {
  name: string;
  location: Location;
  primaryContactName: string;
  primaryContactPhone: string;
  placeCountEstimate: number;
  deliveryCountEstimate: number;
}

const vendorApplicationSchema = new Schema<VendorApplicationModel>(
  'vendor-application',
  [
    new Property('name', PropertyType.string, {
      userFacingName: 'Company name',
    }),
    new Property('location', PropertyType.location, {
      userFacingName: 'Company address',
    }),
    new Property('primaryContactName', PropertyType.fullName),
    new Property('primaryContactPhone', PropertyType.phone),
    new Property('placeCountEstimate', PropertyType.number, {
      userFacingName: 'Number of physical locations',
    }),
    new Property('deliveryCountEstimate', PropertyType.number, {
      userFacingName: 'Estimated deliveries per month',
    }),
    new Property('comments', PropertyType.string, {isOptional: true}),
    new Property('creatorId', PropertyType.uid, {
      readonly: true,
      isOptional: true,
      customClaims: [CustomClaim.manageLogistics],
      userFacingName: 'Submitted by',
      defaultValue: getUserId,
    }),
    new Property('timestamp', PropertyType.timestamp, {
      readonly: true,
      userFacingName: 'Submission time',
      customClaims: [CustomClaim.manageLogistics],
      defaultValue: Date.now,
    }),
    new Property('vendorId', PropertyType.firestoreId, {
      readonly: true,
      isOptional: true,
      customClaims: [CustomClaim.manageLogistics],
      defaultValue: () => null,
    }),
  ],
  VendorApplication,
  undefined,
  [
    {
      name: 'Unapproved',
      get: reference => reference.where('vendorId', '==', null),
    },
    {
      name: 'Approved',
      get: reference => reference.where('vendorId', '!=', null),
    },
  ],
);
export default vendorApplicationSchema;

function getUserId(): string | undefined {
  return firebase.auth().currentUser?.uid;
}
