import StringEnum from '../base/StringEnum';

export default class CustomClaim extends StringEnum {
  static getComponent(claimType) {
    switch (claimType) {
      case CustomClaim.manageApi:
        return 'Manage API';
      case CustomClaim.manageDrivers:
        return 'Manage drivers';
      case CustomClaim.manualEntry:
        return 'Manual entry & dispatch portal access';
      case CustomClaim.manageFinancial:
        return 'Manage financial (general)';
      case CustomClaim.manageLogistics:
        return 'Manage logistics';
      case CustomClaim.manageOwnGroups:
        return 'Manage own groups';
      case CustomClaim.manageTrips:
        return 'Manage trips';
      case CustomClaim.manageUsers:
        return 'Manage users';
      case CustomClaim.overridePayout:
        return 'Override job pricing and payout';
      case CustomClaim.panicEvents:
        return 'View/manage panic events';
      case CustomClaim.selfAssign:
        return 'Assign to self (in dispatch portal)';
      default:
        return null;
    }
  }
}

CustomClaim.manageApi = 'manageApi';
CustomClaim.manageDrivers = 'manageDrivers';
CustomClaim.manualEntry = 'manualEntry';
CustomClaim.manageFinancial = 'manageFinancial';
CustomClaim.manageLogistics = 'manageLogistics';
CustomClaim.manageOwnGroups = 'manageOwnGroups';
CustomClaim.manageTrips = 'manageTrips';
CustomClaim.manageUsers = 'manageUsers';
CustomClaim.overridePayout = 'overridePayout';
CustomClaim.panicEvents = 'panicEvents';
CustomClaim.selfAssign = 'selfAssign';
