"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.driverStatusOrder = exports.driverStatusTitle = exports.driverStatusDescription = void 0;
const Enum_1 = require("../type-descriptions/Enum");
const driverStatusAll = ['pending', 'approved', 'suspended', 'rejected'];
exports.driverStatusDescription = (0, Enum_1.enumDescription)({
    userFacingTitle: 'status',
    userFacingValue: driverStatusTitle,
    values: driverStatusAll,
    order: driverStatusOrder,
});
function driverStatusTitle(status) {
    return driverStatusTitles[status];
}
exports.driverStatusTitle = driverStatusTitle;
const driverStatusTitles = {
    pending: 'Pending',
    approved: 'Approved',
    suspended: 'Suspended',
    rejected: 'Rejected',
};
function driverStatusOrder(status) {
    return driverStatusAll.indexOf(status);
}
exports.driverStatusOrder = driverStatusOrder;
