import unhandledCase from '../../../functions/unhandledCase';
import StringEnum from '../../../models/base/StringEnum';
import TripStatus from '../../../models/scoopm/TripStatus';
import SelectOption from '../../../models/SelectOption';

export default class StatusGroup extends StringEnum {
  static unaccepted = 'UNACCEPTED';

  static underway = 'UNDERWAY';
  static upNext = 'UP_NEXT';

  static underwayAndUpNext = 'UNDERWAY_AND_UP_NEXT';

  static complete = 'COMPLETE';
  static canceled = 'CANCELED';
  static completeAndCanceled = 'COMPLETE_AND_CANCELED';

  static all = 'ALL';
  static allSuccessful = 'ALL_SUCCESSFUL';

  static options = StatusGroup.allCases().map(
    (group: StatusGroup) => new SelectOption(group as string, StatusGroup.title(group)),
  );

  static title(group: StatusGroup) {
    switch (group) {
      case StatusGroup.unaccepted:
        return 'Unaccepted';
      case StatusGroup.underway:
        return 'Underway';
      case StatusGroup.upNext:
        return 'Up next';
      case StatusGroup.underwayAndUpNext:
        return 'Unaccepted, underway, & up next';
      case StatusGroup.complete:
        return 'Complete';
      case StatusGroup.canceled:
        return 'Canceled';
      case StatusGroup.completeAndCanceled:
        return 'Complete + canceled';
      case StatusGroup.all:
        return 'All';
      case StatusGroup.allSuccessful:
        return 'All except canceled';
      default:
        throw unhandledCase(group);
    }
  }

  static statuses(group: StatusGroup): TripStatus[] {
    switch (group) {
      case StatusGroup.unaccepted:
        return [TripStatus.created];
      case StatusGroup.underway:
        return [TripStatus.accepted, TripStatus.started];
      case StatusGroup.upNext:
        return [TripStatus.scheduled];
      case StatusGroup.underwayAndUpNext:
        return [
          ...StatusGroup.statuses(StatusGroup.unaccepted),
          ...StatusGroup.statuses(StatusGroup.underway),
          ...StatusGroup.statuses(StatusGroup.upNext),
        ];
      case StatusGroup.complete:
        return [TripStatus.completed];
      case StatusGroup.canceled:
        return TripStatus.failed();
      case StatusGroup.completeAndCanceled:
        return TripStatus.done();
      case StatusGroup.all:
        return TripStatus.inOrder();
      case StatusGroup.allSuccessful:
        return [...TripStatus.notDone(), TripStatus.completed];
      default:
        throw unhandledCase(group);
    }
  }
}
