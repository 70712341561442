import Property from '../types/Property';
import PropertyType from '../types/PropertyType';
import Schema from '../types/Schema';

const placeSchema = new Schema('place', [
  new Property('name', PropertyType.string),
  new Property('invoiceFormulaId', PropertyType.firestoreId, {
    isOptional: true,
  }),
  new Property('payoutFormulaId', PropertyType.firestoreId, {
    isOptional: true,
  }),
  new Property('couponCode', PropertyType.realtimeDatabaseId, {
    isOptional: true,
    path: 'coupons',
    useIdAsName: key => key.toUpperCase(),
  }),
  new Property('destinations', PropertyType.destination, {isArray: true, isOptional: true}),
  new Property('location', PropertyType.location),
  new Property('zones', PropertyType.zone, {isArray: true, isOptional: true}),
  new Property('polygons', PropertyType.polygon, {isArray: true, isOptional: true}),
]);

export default placeSchema;
