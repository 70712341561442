export default class Customer {
  constructor({firstName, lastName, cellphone}) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.cellphone = cellphone;
  }

  static Key = {
    currentRide: 'currentRide',
  };

  safePhoneNumber() {
    return this.cellphone || this.phone || null;
  }

  isValid() {
    return Boolean(this.firstName && this.lastName && Customer.prototype.safePhoneNumber.apply(this));
  }
}
