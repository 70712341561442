import sortBy from 'lodash/sortBy';
import firebase from 'firebase/app';
import {useMemo} from 'react';
import useFirestoreQueryListener from './hooks/useFirestoreQueryListener';
import Vendor from './models/scoopm/Vendor';

export default function useVendors() {
  const reference = useMemo(() => firebase.firestore().collection('vendors'), []);
  const state = useFirestoreQueryListener(reference, Vendor);
  const stateWithSortedModels = useMemo(() => Object.assign(state, {models: sortBy(state.models, 'name')}), [state]);
  return stateWithSortedModels;
}
