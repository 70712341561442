const {default: HttpReference} = require('../platform/HttpReference');

export default class UserClaims extends HttpReference {
  async get() {
    return this.request();
  }

  async set(claims) {
    return this.request({
      method: 'PUT',
      data: claims,
    });
  }
}
