import Price from '../../../models/stripe/Price';
import StripePage from '../../../models/stripe/StripePage';
import HttpReference from '../../platform/HttpReference';
import DeliverySubscriptionPrice from './DeliverySubscriptionPrice';
import Stripe from './Stripe';
import StripePrice from './StripePrice';

export default class StripePrices extends HttpReference<StripePage<Array<Price>>, StripePrice, Stripe> {
  deliverySubscription: DeliverySubscriptionPrice;

  get childReferenceType() {
    return StripePrice;
  }

  constructor(path: string, parent: Stripe) {
    super(path, parent);

    this.deliverySubscription = new DeliverySubscriptionPrice('delivery-subscription', this);
  }

  async list(productId: string) {
    return this.request({
      params: {
        productId,
      },
    });
  }
}
