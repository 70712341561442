import unwrap from '../../functions/unwrap';

export default class Route {
  constructor({
    parent,
    name,
    path,
    link,
    requiredClaims,
    Component,
    Icon,
    children,
    exact,
    hideFromMenu,
    separator,
    queryParameters,
  }) {
    this.parent = parent; // TBD

    this.name = name || null;
    this.path = path || null;
    this.link = link || null;
    this.requiredClaims = requiredClaims || null;
    this.Component = Component || null;
    this.Icon = Icon || null;
    this.children = children || null;
    this.exact = exact || false;
    this.hideFromMenu = hideFromMenu || false;
    this.separator = separator || false;
    this.queryParameters = queryParameters || undefined;

    // add parents to children

    if (children) {
      children.forEach(child => {
        child.parent = this;
      });
    }
  }

  get chain() {
    if (this.parent) {
      return [...this.parent.chain, this];
    } else {
      return [this];
    }
  }

  get absolutePath() {
    if (!this.__absolutePath)
      this.__absolutePath = this.chain
        .map(route => route.path)
        .filter(Boolean)
        .join('/');
    return this.__absolutePath;
  }

  get linkPath() {
    if (!this.__linkPath)
      this.__linkPath = this.chain
        .map(route => route.link || route.path)
        .filter(Boolean)
        .join('/');
    return this.__linkPath;
  }

  get queryPath() {
    return unwrap(
      this.queryParameters,
      parameters =>
        '?' +
        Object.entries(parameters)
          .map(([key, value]) => `${key}=${value}`)
          .join('&'),
    );
  }
}
