import React from 'react';
import unwrap from '../../functions/unwrap';
import PropertyType from '../types/PropertyType';
import InvalidFeedback from './InvalidFeedback';

export default function EnumInput({property, id, value, onChange, disabled, validationError}) {
  return (
    <>
      <select
        name={id}
        id={id}
        value={value || ''}
        onChange={event => onChange(event.target.value || null)}
        className={'custom-select ' + unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid'), '')}
        disabled={disabled}>
        <option value="">{property.isOptional ? 'None' : 'Select...'}</option>
        {PropertyType.enumType(property.type).allCases.map(enumCase => (
          <option key={enumCase.rawValue} value={enumCase.rawValue}>
            {typeof enumCase.title === 'function' ? enumCase.title() : enumCase.title}
          </option>
        ))}
      </select>
      <InvalidFeedback error={validationError} property={property} />
    </>
  );
}
