import {useMemo} from 'react';
import unwrap from '../functions/unwrap';
import useMarkersForTrips from './useMarkersForTrips';

export default function useTripsMarkers({tripEntries, onClick, showOnlyPickupExceptFor}) {
  const trips = useMemo(
    () => unwrap(tripEntries, entries => entries.map(([id, trip]) => Object.assign(trip, {id}))),
    [tripEntries],
  );
  return useMarkersForTrips(trips, onClick, showOnlyPickupExceptFor);
}
