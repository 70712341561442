import React, {useMemo, useRef} from 'react';
import {GridChildComponentProps, VariableSizeGrid} from 'react-window';
import {baseUserFormalName, useStatus} from 'wave-common';
import {driversListNearCoordinates} from 'wave-common/lib/controllers/Driver';
import {COORDINATES_ARRAY_ATLANTA} from 'wave-common/lib/models/CoordinatesArray';
import {driverIsApprovedForRideshare} from 'wave-common/lib/models/Driver';
import Delay from '../../../components/Delay';
import {FontAwesomeV5} from '../../../components/FontAwesome';
import Spinner from '../../../components/Spinner';
import RealtimeDatabaseDataSource from '../../../data-sources/RealtimeDatabaseDataSource';
import csvFromArrayOfArrays from '../../../functions/csvFromArrayOfArrays';
import {simluateDownloadClickCsv} from '../../../functions/simulateDownloadClick';
import useElementSize from '../../../hooks/useElementSize';

export default function AtlRideshareCsv() {
  const gridElementRef = useRef<HTMLElement>();
  const divSize = useElementSize(gridElementRef);

  const {
    isPending,
    error,
    value: data,
  } = useStatus(
    React as never,
    useMemo(async () => {
      const drivers = await driversListNearCoordinates(
        COORDINATES_ARRAY_ATLANTA,
        100000,
        RealtimeDatabaseDataSource.instance,
      );
      const rideshareDrivers = drivers.filter(driverIsApprovedForRideshare);
      const data = [
        ['Driver', "Driver's License Number", 'State licence issued', 'Date of birth'],
        ...(await Promise.all(
          rideshareDrivers.map(async driver => {
            // const applicant = await ScoopMApi.instance.fountain.applicants.child(driver.fountainApplicantId!).get();
            return [
              baseUserFormalName(driver),
              '?', //   applicant.data.secure_data.driver_license_number,
              driver.address.region ?? 'None',
              '?', // (await unwrap(driver.types, driverTypesDescription.userFacingValue)) ?? '',
            ];
          }),
        )),
      ];
      return data;
    }, []),
  );

  return (
    <div className="h-100 d-flex">
      <div ref={gridElementRef as never} className="flex-grow-1 overflow-auto">
        {error
          ? error.message
          : isPending
          ? 'Loading'
          : divSize &&
            (data?.length ? (
              <VariableSizeGrid
                height={divSize.height}
                width={divSize.width}
                rowCount={data.length}
                rowHeight={() => 48}
                columnCount={4}
                columnWidth={columnWidth}
                itemData={data}>
                {Cell}
              </VariableSizeGrid>
            ) : (
              'No drivers'
            ))}
      </div>

      <div className="p-2 p-md-3 border-left">
        <h4>ATL Rideshare CSV</h4>
        <p className="lead">This is used to report to ATL govt</p>
        <button
          type="button"
          onClick={() => {
            simluateDownloadClickCsv(csvFromArrayOfArrays(data!), 'ATL_Rideshare');
          }}
          className="btn btn-primary"
          disabled={isPending || !data}>
          {isPending ? (
            <Delay childrenBeforeTimeout={<FontAwesomeV5 name="file-csv" />}>
              <Spinner small />
            </Delay>
          ) : (
            <FontAwesomeV5 name="file-csv" />
          )}{' '}
          Download
        </button>
      </div>
    </div>
  );
}

function Cell({data, columnIndex, rowIndex, style}: GridChildComponentProps<string[][]>) {
  const datum = data[rowIndex][columnIndex];
  return (
    <div style={style} className="p-2 d-flex align-items-center bg-white border-right border-bottom">
      {datum}
    </div>
  );
}

function columnWidth() {
  return 250;
}
