import React from 'react';
import './CateringDelivery.css';

export default function CateringDelivery() {
  return (
    <div className="catering-delivery-page">
      <header className="header-section">
        <div className="header-image-container">
          <img 
            src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/catering-delivery-website-images%2F452050149_808514851426193_1679363225595272529_n.jpg?alt=media&token=a4de845c-04bf-46a0-8e32-7fe3e7cf2534" 
            alt="Catering Delivery" 
            className="header-image" 
          />
          <div className="header-overlay">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/catering-delivery-website-images%2FWave%20Logo%20Catering%20Trans%20(1).png?alt=media&token=001bf529-fcf3-4dcc-8776-da23ef3539c9" 
              alt="Wave Catering Delivery Logo" 
              className="header-logo" 
            />
            <h1 className="header-title">Seamless Catering Delivery</h1>
            <p className="header-subtitle">We provide dependable catering delivery services tailored to your business needs.</p>
            <a href="https://calendly.com/waveoutreach/30min" className="btn btn-primary header-cta">Schedule a Call</a>
          </div>
        </div>
      </header>

      <section className="use-cases-section">
        <h2>Who Benefits from Our Services?</h2>
        <div className="use-cases">
          <div className="use-case">
            <h3>Restaurants</h3>
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/catering-delivery-website-images%2F454352183_816229790654699_1226448383480439228_n.jpg?alt=media&token=7eba7143-f323-437c-ba98-31df143e56d4" 
              alt="Restaurant Service" 
              className="use-case-image" 
            />
            <p>
              Whether you don’t want to deal with bad drivers, angry customers, or unaccountable delivery services, <span className="highlight">Wave</span> can help. Our services include:
            </p>
            <ul>
              <li>Custom setups that ensure your catering is presented exactly as desired, maintaining the high standards of your organization.</li>
              <li>Dedicated agents that mediate communication between the driver, customer, and restaurant.</li>
              <li>Complete management of your delivery needs, whether it's handling all your orders or just the overflow as your catering business grows.</li>
              <li>Reliable delivery partners who are trained to represent your brand with professionalism.</li>
            </ul>
          </div>
          <div className="use-case">
            <h3>Corporate Offices & Inpatient Facilities</h3>
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/catering-delivery-website-images%2F454450945_818048877139457_7733263436355115951_n.jpg?alt=media&token=7dfad811-0b3a-4e16-9b80-ede8beee5011" 
              alt="Corporate Catering" 
              className="use-case-image" 
            />
            <p>
              For businesses requiring reliable delivery every time, <span className="highlight">Wave</span> offers a thoughtful, white-glove catering delivery experience tailored to meet the unique needs of corporate environments and healthcare facilities. Our services include:
            </p>
            <ul>
              <li>Flexible scheduling with your preferred driver to accommodate the specific timing and coordination needs of your events.</li>
              <li>White-glove service, ensuring that every detail of the delivery process is handled with the utmost care, from timely arrival to precise setup.</li>
              <li>Professional drivers trained to handle the specific requirements of delivering to corporate offices and inpatient facilities, including navigating security protocols and adhering to strict schedules.</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="commitment-section">
        <h2>Our Commitment to You</h2>
        <ul>
          <li><strong>Proactive Customer Service:</strong> Automation-assisted, team-based approach ensuring timely and high-quality deliveries with a human touch. Our driver training includes initiation ride-alongs.</li>
          <li><strong>Unmatched Accountability:</strong> We hold ourselves accountable for our ac;ons and take full responsibility for our work. We take pride in delivering on our promises and con;nuously strive for the highest level of quality, reliability, and professionalism.</li>
          <li><strong>Fast Response Time:</strong> Our dispatch and intake specialists are dedicated and prepared to help drivers, riders, and restaurants.</li>
          <li><strong>Insured Drivers:</strong> All our drivers are covered by third-party insurance, ensuring peace of mind for your business. Additional COI provided to all restaurant partners.</li>
          <li><strong>Customized Solutions:</strong> From setup to delivery, we customize our services to align with your specific requirements.</li>
        </ul>
      </section>

      <section className="service-details-section">
        <h2>How We Work</h2>
        <ul>
          <li><span className="highlight">Our objective is to expand your delivery footprint</span> as an extension of your business. Our drivers are trained to set up the food for the customer and can be trained on any additional setup or way that you like your food to be.</li>
          <li><span className="highlight">We handle any catering orders you receive.</span> Usually, a day’s notice is enough, but we’ve been able to take orders on a tighter schedule before. For the orders, we will need all the pertinent information regarding the catering order, preferably on one invoice.</li>
          <li><span className="highlight">Our operations team will handle communications</span> between the restaurant and the driver as well as between the driver and the customer. We have driver tracking capabilities and can provide limited access to our proprietary internal portal so you and your catering team can do the same.</li>
          <li><span className="highlight">After a brief consultation with our Sales team,</span> we will provide you a copy of our MOU which outlines our pricing.</li>
        </ul>
      </section>

      <section className="contact-section">
        <h2>Contact Us</h2>
        <img 
          src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/catering-delivery-website-images%2FWave%20Logo%20Catering%20Trans%20(1).png?alt=media&token=001bf529-fcf3-4dcc-8776-da23ef3539c9" 
          alt="Wave Catering Delivery Logo" 
          className="contact-logo" 
        />
        <p className="highlighted-contact">
          Need Catering Delivery? <a href="tel:18339283663" className="contact-link">Call us at 1-833-WAVE2ME</a>
        </p>
      </section>

      <section className="cta-section">
        <p>Interested in learning more about how our catering delivery services can help your business?</p>
        <a href="https://calendly.com/waveoutreach/30min" className="btn btn-primary">Schedule a Call</a>
      </section>
    </div>
  );
}
