import unwrap from '../../functions/unwrap';
import PricingFormulaInput from './PricingFormulaInput';
import PricingFormulaItemCondition from './PricingFormulaItemCondition';

export default class PricingFormulaItem {
  constructor({name = null, amount, input = null, conditions = null}) {
    this.name = unwrap(name);
    this.amount = amount;
    this.input = input ? input.rawValue : null;
    this.conditions = unwrap(conditions);
  }

  isValid() {
    if (this.name && typeof this.name !== 'string') {
      throw new Error('Invalid name');
    }
    if (!this.amount || isNaN(Number(this.amount))) {
      throw new Error('Invalid amount');
    }
    if (this.input && !PricingFormulaInput.from(this.input)) {
      throw new Error('Invalid input');
    }
    if (this.conditions) {
      if (
        !Array.isArray(this.conditions) ||
        this.conditions.find(condition => !condition.minimumInclusive && !condition.maximumExclusive)
      ) {
        throw new Error('Invalid conditions');
      }
      if (this.conditions.find(condition => condition.applyRangeToInputValue && condition.input !== this.input)) {
        throw new Error('Condition range does not match item input. Turn off the switch, or change the input');
      }
    }
    return true;
  }

  passesConditions(charge, miles, minutes, tip, revenue) {
    // has conditions

    if (this.conditions) {
      const failedCondition = this.conditions.find(condition => {
        const value = PricingFormulaInput.value(condition.input, charge, miles, minutes, tip, revenue);
        return !PricingFormulaItemCondition.prototype.isInRange.apply(condition, [value]);
      });

      // found a failed condition

      if (failedCondition) {
        return false;
      }
    }

    // passed

    return true;
  }
}
