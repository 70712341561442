import React, {useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Location, unitedStates} from 'wave-common';
import {emptyTripRideshareNew} from 'wave-common/lib/models/EmptyTrip';
import FontAwesome from '../../components/FontAwesome';
import Autocomplete from '../../components/GoogleMaps/Autocomplete';
import {latLngBounds} from '../../components/GoogleMaps/LatLng+additions';
import Input from '../../components/Input';
import {useNewTripContext} from '../../contexts/NewTripContext';
import RealtimeDatabaseDataSource from '../../data-sources/RealtimeDatabaseDataSource';
import useScreenIsSmall from '../../hooks/useScreenIsSmall';

export default function RideNow() {
  // refs

  const inputRef = useRef<HTMLInputElement>();

  // state

  // const [hasRequestedLocationPermisson] = useLocalStorage('HAS_REQUESTED_LOCATION_PERMISSION', false);

  const history = useHistory();

  const screenIsSmall = useScreenIsSmall();

  const [, setNewTrip] = useNewTripContext();

  const [hideSearchButton, setHideSearchButton] = useState(false);

  const searchBounds = useMemo(() => latLngBounds(unitedStates), []);

  // methods

  function onLocationChange(location: Location) {
    // set up new trip

    const trip = emptyTripRideshareNew(
      {
        deviceType: 'WEB',
        source: 'CUSTOMER_PORTAL',
        locations: [undefined, undefined],
      },
      RealtimeDatabaseDataSource.instance,
    );
    trip.locations[1] = location;
    setNewTrip(trip);

    // redirect

    history.push('/jobs/create');
  }
  // render

  return (
    <div className="mb-5">
      <h3>Where to?</h3>

      <div className="row no-gutters align-items-center bg-white text-dark rounded-xl">
        {(!screenIsSmall || !hideSearchButton) && (
          <div className="col-auto">
            <FontAwesome.MagnifyingGlass className="ml-3 mr-1" />
          </div>
        )}

        <div className="col">
          <Autocomplete bounds={searchBounds} onChange={onLocationChange}>
            <Input
              ref={inputRef as never}
              type="text"
              onKeyDown={() => setHideSearchButton(true)}
              className="form-control border-0 ellipsis rounded-right-xl"
              placeholder="Addresses, venues, airports..."
            />
          </Autocomplete>
        </div>

        {!hideSearchButton && (
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-primary"
              style={{
                borderTopRightRadius: '1rem',
                borderBottomRightRadius: '1rem',
                borderTopLeftRadius: '0rem',
                borderBottomLeftRadius: '0rem',
              }}
              onClick={() => {
                setHideSearchButton(true);
                inputRef.current?.focus();
              }}>
              Search
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
