import {useContext, useEffect} from 'react';
import ThemeColorContext from '../contexts/ThemeColorContext';

export default function useThemeColor(color: string) {
  const {setThemeColor} = useContext(ThemeColorContext);

  useEffect(() => {
    setThemeColor(color);
    return () => setThemeColor('#FFFFFF');
  }, [color, setThemeColor]);
}
