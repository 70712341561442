import firebase from 'firebase/app';
import AppPathname from '../AppPathname';
import Constants from '../Constants';
import storageAvailable from '../functions/storageAvailable';
import Device from '../models/scoopm/Device';
import DeviceType from '../models/scoopm/DeviceType';
import RealtimeDatabase from '../references/database/RealtimeDatabase';

const LOCAL_STORAGE_HAS_NOTIFIED_INABILITY = 'HAS_NOTIFIED_NOTIFICATIONS_INABILITY';

export default class MessagingService {
  static __instance = null;

  /**
   * @returns {MessagingService}
   */
  static get instance() {
    if (this.__instance === null) {
      this.__instance = new MessagingService();
      this.__instance.start();
    }
    return this.__instance;
  }

  start() {
    console.log('Starting messaging service');

    if (!window.Notification || !firebase.messaging.isSupported() || !storageAvailable()) {
      console.warn('No `window.Notification` object');

      if (!window.localStorage.getItem(LOCAL_STORAGE_HAS_NOTIFIED_INABILITY)) {
        alert(
          "Your web browser doesn't support push notifications. To get them, try out the latest version of Google Chrome.",
        );
        window.localStorage.setItem(LOCAL_STORAGE_HAS_NOTIFIED_INABILITY, 'YES');
      }

      return;
    }

    this.messaging = firebase.messaging();

    console.log(`Notification permission: "${window.Notification.permission}"`);

    switch (window.Notification.permission) {
      case 'default': {
        this.requestPermission();
        break;
      }
      case 'denied': {
        break;
      }
      case 'granted': {
        this.setUpMessaging();
        break;
      }
      default:
        return;
    }
  }

  requestPermission() {
    console.log('Requesting notification permission...');

    window.Notification.requestPermission().then(permission => {
      if (permission !== 'granted') {
        console.warn(`Notification permission: "${permission}"`);
        // window.alert('You won\'t be able to get push notifications from this site. You can change this in your browser settings.')
        return;
      }

      console.log(`Notification permission: "${permission}"`);
      this.setUpMessaging();
    });
  }

  setUpMessaging() {
    console.log('Setting up messaging');

    this.messaging.usePublicVapidKey(Constants.publicKeys.firebaseMessagingPublicVAPIDKey);

    this.getToken();

    this.messaging.onTokenRefresh(() => {
      console.log('Messaging token was refreshed');
      this.getToken();
    });

    this.messaging.onMessage(payload => {
      console.log('Received message', payload, AppPathname.instance.value);

      const {title, body, image} = payload.notification;

      if (payload.fcmOptions.link.endsWith(AppPathname.instance.value)) {
        // if at link, don't show notification
        console.info('Not showing notification');
        return;
      }

      new window.Notification(title, {body, icon: image});
    });
  }

  getToken() {
    console.log('Getting messaging token...');

    this.messaging.getToken().then(
      token => {
        if (!token) {
          // this happens when permission isn't granted. we've already covered this case above.
          return;
        }

        console.log(`Got messaging token "${token}"`);

        this.saveTokenToDevice(token);
      },
      error => {
        console.error('Error getting messaging token', error);
      },
    );
  }

  saveTokenToDevice(token) {
    console.log('Save token to device node', token);

    const uid = firebase.auth().currentUser.uid;
    const DEVICE_ID_UID = 'deviceId.' + uid;

    // get device id

    const deviceId = window.localStorage.getItem(DEVICE_ID_UID);

    // if device id

    if (deviceId) {
      // update

      RealtimeDatabase.instance.devices.child(deviceId).update({
        fcmRegistrationToken: token,
      });
    }

    // else no device id
    else {
      // new device id, save it, set

      const reference = RealtimeDatabase.instance.devices.push();

      window.localStorage.setItem(DEVICE_ID_UID, reference.path);

      reference.set(
        new Device({
          type: DeviceType.web,
          uid,
          fcmRegistrationToken: token,
        }),
      );
    }
  }
}
