import React, {Component} from 'react';
import FontAwesome from '../components/FontAwesome';
import {environmentCurrent} from '../Environment';
import CenteredLayout from '../layouts/CenteredLayout';

const withErrorBoundary = WrappedComponent =>
  class extends Component {
    static getDerivedStateFromError(error) {
      return {hasError: true, error};
    }

    state = {
      hasError: false,
      error: undefined,
    };

    componentDidCatch(error, errorInfo) {
      console.error('Component did catch error', errorInfo, error);
    }
    render() {
      if (this.state.hasError) {
        return (
          <CenteredLayout>
            <span>
              {environmentCurrent === 'development' ? (
                <pre style={{whiteSpace: 'pre-wrap'}} className="p-2">
                  {this.state.error.message}
                  <br />
                  <br />
                  {this.state.error.stack}
                </pre>
              ) : (
                <>
                  <FontAwesome.ExclamationCircleSolid /> Something went wrong. Try reloading the page
                </>
              )}
            </span>
          </CenteredLayout>
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  };

export default withErrorBoundary;
