import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import firebase from 'firebase/app';
import cleanForFirebase from '../../functions/cleanForFirebase';
import logPromise from '../../functions/logPromise';
import removeNullValues from '../../functions/removeNullValues';
import Type from '../../functions/Type';
import unwrap, {unwrap as unwrapNew} from '../../functions/unwrap';
import JsonSerializable from '../../models/base/JsonSerializable';
import ApiError from '../../models/scoopm/ApiError';
import Reference, {AnyReference} from './Reference';

export default class HttpReference<
  Model extends object | void = void,
  Child extends AnyReference | void = any,
  Parent extends AnyReference | void = any,
> extends Reference<Child, Parent> {
  Model(): Type<Model> | null {
    return null;
  }

  /**
   * Override this method to provide headers that will be used for this and all children's methods
   * Must return an object (no falsy values)
   */
  async headers(): Promise<Record<string, string> | null> {
    if (this.parent && this.parent instanceof HttpReference) {
      return this.parent.headers();
    } else {
      return {};
    }
  }

  /**
   * Convenience method that generates an `Authorization` header in the format "Bearer: base64encoded<username:password>"
   * @param {String} username
   * @param {String} password
   */
  userNameAndPasswordAuthorization(username: string, password: string) {
    return {
      Authorization: `Bearer ${Buffer.from(`${username}:${password}`).toString('base64')}`,
    };
  }

  /**
   * Convenience method that generates an `Authorization` header in the format "Bearer: <firebase auth token of current user>"
   */
  async firebaseAuthorization(): Promise<Record<string, string> | null> {
    return unwrap(firebase.auth().currentUser, user =>
      user.getIdToken().then(token => ({Authorization: `Bearer ${token}`})),
    );
  }

  async request<T = Model, J extends JsonSerializable<T> | void = void>(
    config?: Omit<AxiosRequestConfig, 'url'> & {model?: J},
  ): Promise<AxiosResponseWithModel<T>> {
    let request: AxiosRequestConfig = {
      headers: Object.assign({}, await this.headers(), config?.headers ?? {}),
      method: config?.method ?? 'GET',
      url: this.fullPath,
      params: config?.params,
      data: unwrapNew(config?.data, data => removeNullValues(cleanForFirebase(data))),
    };

    const response: AxiosResponseWithModel<T> = await logPromise(
      `${request.method} ${request.url}`,
      performRequest<T>(request),
    );

    if (config?.model) {
      response.model = config.model.fromJson(response.data as never, config.model);
    } else {
      const Model = this.Model();
      if (Model) {
        response.model = Object.assign(Object.create(Model.prototype), response.data);
      }
    }

    return response;
  }
}

/**
 *  Performs request and checks response status code
 */
async function performRequest<T>(request: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    console.log('request details', request, JSON.stringify(request, null, '   '));
  }

  let response = null;
  try {
    response = await axios.request(Object.assign(request, {validateStatus}));
    console.log(`${request.method} ${request.url}: ${response.status}`);
  } catch (error) {
    throw (error as AxiosError).response ? new ApiError((error as AxiosError).response!) : error;
  }

  if (!validateStatus(response.status)) {
    throw new ApiError(response);
  }

  return response;
}

/**
 * Returns `true` if status is good
 */
function validateStatus(status: number) {
  return status >= 200 && status < 300;
}

export interface AxiosResponseWithModel<Model> extends AxiosResponse<Model> {
  model?: Model | undefined;
}
