import React, {useState} from 'react';
import Card from '../../../components/Card';
import UserType from '../../../models/scoopm/UserType';
import ValidateableInput from '../../../components/bootstrap/ValidateableInput';
import unwrap from '../../../functions/unwrap';
import {PhoneNumberUtil} from 'google-libphonenumber';
import RealtimeDatabase from '../../../references/database/RealtimeDatabase';
import ScoopMApi from '../../../references/scoopm-api';
import {formatPhoneE164} from '../../../functions/formatPhone';

const isValidPhone = string => {
  try {
    const instance = PhoneNumberUtil.getInstance();
    const phoneNumber = instance.parse(string, 'US');
    return instance.isValidNumber(phoneNumber);
  } catch (error) {
    console.error(error);
    return false;
  }
};

export default function Create({history}) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [user, setUser] = useState({type: UserType.customer});
  const [validation, setValidation] = useState();
  const [isCreating, setIsCreating] = useState(false);

  const onSubmit = event => {
    event.preventDefault();

    // validate

    const validation = {
      email: Boolean(email),
      password: Boolean(password),
      firstName: Boolean(user.firstName),
      lastName: Boolean(user.lastName),
      cellphone: isValidPhone(user.cellphone),
      type: true,
    };
    setValidation(validation);

    if (Object.values(validation).includes(false)) {
      return;
    }

    // parse

    user.cellphone = formatPhoneE164(user.cellphone);

    // create user

    setIsCreating(true);
    ScoopMApi.instance.users
      .create(email, password)
      .then(response =>
        RealtimeDatabase.instance
          .child('customers')
          .child(response.data.uid)
          .set(user)
          .then(() => {
            history.push(`/admin/users/${response.data.uid}`);
          }),
      )
      .catch(error => {
        console.error(error);
        window.alert(`Error creating user. ${error.message}`);
        setIsCreating(false);
      });
  };

  return (
    <Card padded>
      <h3>Create customer</h3>
      {/* <div className="alert alert-warning">
                <h5 className="alert-heading mb-1">Warning</h5>
                <p className="mb-0">This page can currently only be used to create users for the restuarant dispatch page.</p>
            </div> */}
      <form onSubmit={onSubmit}>
        <div className="form-row">
          <div className="col-sm form-group">
            <label htmlFor="emailInput">Email</label>
            <ValidateableInput
              type="text"
              autoComplete="email"
              name="emailInput"
              id="emailInput"
              value={email || ''}
              onChange={event => setEmail(event.target.value)}
              className="form-control"
              isValid={unwrap(validation, v => v.email)}
            />
          </div>
          <div className="col-sm form-group">
            <label htmlFor="passwordInput">Password</label>
            <ValidateableInput
              type="password"
              name="passwordInput"
              autoComplete="new-password"
              id="passwordInput"
              value={password || ''}
              onChange={event => setPassword(event.target.value)}
              className="form-control"
              isValid={unwrap(validation, v => v.password)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm form-group">
            <label htmlFor="firstNameInput">First name</label>
            <ValidateableInput
              type="text"
              name="firstNameInput"
              id="firstNameInput"
              value={user.firstName || ''}
              onChange={event => setUser({...user, firstName: event.target.value})}
              className="form-control"
              isValid={unwrap(validation, v => v.firstName)}
            />
          </div>
          <div className="col-sm form-group">
            <label htmlFor="lastNameInput">Last name</label>
            <ValidateableInput
              type="text"
              name="lastNameInput"
              id="lastNameInput"
              value={user.lastName || ''}
              onChange={event => setUser({...user, lastName: event.target.value})}
              className="form-control"
              isValid={unwrap(validation, v => v.lastName)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm form-group">
            <label htmlFor="phoneInput">Phone</label>
            <ValidateableInput
              type="text"
              name="phoneInput"
              id="phoneInput"
              value={user.cellphone || ''}
              onChange={event => setUser({...user, cellphone: event.target.value})}
              className="form-control"
              isValid={unwrap(validation, v => v.cellphone)}
            />
          </div>
          <div className="col-sm form-group">
            {/* <label htmlFor="typeSelect">Type</label>
                        <ValidateableInput
                            elementType="select"
                            name="typeSelect" id="typeSelect"
                            value={user.type.rawValue}
                            onChange={event => setUser({ ...user, type: UserType.from(event.target.value) })}
                            className="custom-select"
                            isValid={unwrap(validation, v => v.type)}
                        >
                            {[UserType.customer].map(userType => (
                                <option key={userType.rawValue} value={userType.rawValue}>{userType.title}</option>
                            ))}
                        </ValidateableInput> */}
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <input type="submit" value="Create" className="btn btn-primary" disabled={isCreating} />
        </div>
      </form>
    </Card>
  );
}
