import React, {useEffect, useState} from 'react';
import CenteredLayout from '../../layouts/CenteredLayout';
import Api from '../../references/scoopm-api';
import firebase from 'firebase/app';
import {Redirect} from 'react-router-dom';
import Constants from '../../Constants';

class State {
  withUrl(url) {
    this.url = url;
    return this;
  }
}
State.loading = new State();
State.unauthenticated = new State();
State.notADriver = new State();
State.error = new State();
State.success = new State();

/**
 * If user isn't signed in, redirect to login page. Otherwise, fetch the user's stripe account id and fetch the account link.
 */
export default function Refresh() {
  const [state, setState] = useState(State.loading);

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        setState(State.unauthenticated);
        return;
      }
      Api.instance.stripe.accountLinks.create().then(
        accountLink => {
          setState(State.success.withUrl(accountLink.data.url));
        },
        error => {
          if (error.notADriver) {
            setState(State.notADriver);
          } else {
            setState(State.error);
          }
        },
      );
    });
  }, []);

  return (
    <CenteredLayout className="bg-white">
      <RefreshInner state={state} />
    </CenteredLayout>
  );
}

function RefreshInner({state}) {
  switch (state) {
    case State.loading:
      return <i className="fas fa-circle-notch fa-spin" />;

    case State.unauthenticated:
      return <Redirect to="/sign-in?from=/earnings/refresh" />;

    case State.notADriver:
      return (
        <>
          <h4>
            <i className="fa fa-frown mr-2" /> You're not signed in with your driver account
          </h4>
          <p className="lead">Sign out and try again</p>
          <p>
            <button
              onClick={event => {
                event.preventDefault();
                firebase.auth().signOut();
              }}
              className="btn btn-outline-primary mr-3"
            >
              Sign out
            </button>
          </p>
        </>
      );

    case State.error:
      return (
        <>
          <h4>
            <i className="fa fa-frown mr-2" /> Something went wrong
          </h4>
          <p className="lead">Contact support for help</p>
          <p>
            <a href={`mailto:${Constants.information.supportEmailFull}`} className="btn btn-outline-primary mr-3">
              {Constants.information.supportEmail}
            </a>
            <a href={`tel:${Constants.information.supportPhoneE164}`} className="btn btn-outline-primary">
              {Constants.information.supportPhone}
            </a>
          </p>
        </>
      );

    case State.success:
      window.location.assign(state.url);
      return <i className="fas fa-circle-notch fa-spin" />;

    default:
      return null;
  }
}
