import React, {useMemo} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {formatPhone, formatPhoneE164} from '../functions/formatPhone';
import joinClassNames from '../functions/joinClassNames';
import unwrap from '../functions/unwrap';
import markerImage from '../images/marker.png';
import TripContact from '../models/scoopm/TripContact';
import TripLocation from '../models/scoopm/TripLocation';
import UserType from '../models/scoopm/UserType';
import FirebaseStorageCircleImage from './FirebaseStorageCircleImage';

export default function ContactCard(props: {
  contact?: TripContact;
  location?: TripLocation;
  distanceMi?: number;
  className?: string;
}) {
  const {contact, location, distanceMi} = props;

  const path = useMemo(() => unwrap(contact, contact => unwrap(contact.uid, uid => `/profiles/${uid}`)), [contact]);
  const fallbackUrl = useMemo(
    () =>
      unwrap(contact, contact => unwrap(UserType.from(contact.userType) as UserType, userType => userType.imgSrc)) ||
      markerImage,
    [contact],
  );

  const [userLink, userLinkTitle] = useMemo(
    () =>
      contact?.userType === (UserType.driver.rawValue as string)
        ? [`/admin/drivers/${contact.uid}`, 'View profile']
        : contact?.uid
        ? [`/admin/users/${contact.uid}`, 'View profile']
        : [undefined, undefined],
    [contact],
  );

  const formattedPhone = useMemo(() => unwrap(contact, contact => unwrap(contact.phone, formatPhone)), [contact]);
  const formattedPhoneUrl = useMemo(
    () => unwrap(contact, contact => unwrap(formatPhoneE164(contact.phone, true), e164 => `tel:${e164}`)),
    [contact],
  );

  return (
    <div className={joinClassNames('d-flex align-items-start', props.className)}>
      <div className="mr-3">
        <Link href={userLink} title={userLinkTitle}>
          <FirebaseStorageCircleImage
            path={path}
            fallbackUrl={fallbackUrl}
            diameterRem={3.5}
            link={undefined}
            className={undefined}
            style={undefined}
          />
        </Link>
      </div>

      <div>
        {/* location or contact name */}

        <div className="d-flex justify-content-between">
          {(contact?.name ?? location?.name) && (
            <Link href={userLink} title={userLinkTitle}>
              <strong>{contact?.name ?? location?.name}</strong>
            </Link>
          )}
        </div>

        {/* phone */}

        {formattedPhoneUrl && (
          <div>
            <a href={formattedPhoneUrl} className="text-muted">
              {formattedPhone}
            </a>
          </div>
        )}

        {/* location address */}

        {location?.address && (
          <div>
            <a href={googleMapsUrl(location)} target="_blank" rel="noopener noreferrer" className="text-muted">
              {/* location name if not above */}
              {Boolean(location?.name) && Boolean(contact?.name) && contact?.name !== location?.name && (
                <>
                  {location?.name}
                  <br />
                </>
              )}

              {/* address */}
              {location.address}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

function googleMapsUrl(location: TripLocation) {
  if (location.googlePlaceId) {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      [location.name, location.address].filter(Boolean).join(' '),
    )}&query_place_id=${location.googlePlaceId}`;
  } else {
    return `https://www.google.com/maps/search/?api=1&query=${location.address}`;
  }
}

function Link(args: {href?: string | null; title?: string | null; children: React.ReactElement}) {
  if (args.href) {
    return (
      <a href={args.href}>
        <OverlayTrigger overlay={<Tooltip id={args.href ?? ''}>{args.title}</Tooltip>}>{args.children}</OverlayTrigger>
      </a>
    );
  } else {
    return args.children;
  }
}
