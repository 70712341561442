import ScoopMApi from '../../references/scoopm-api';
import {Interface} from '../../schemas/wavem/VendorApplication';
import Location from './Location';

export default class VendorApplication implements Interface {
  constructor(
    public comments: string | undefined,
    public creatorId: string | undefined,
    public deliveryCountEstimate: number,
    public name: string,
    public location: Location,
    public placeCountEstimate: number,
    public primaryContactName: string,
    public primaryContactPhone: string,
    public timestamp: number,
    public vendorId?: string,
  ) {}

  static create(application: VendorApplication) {
    return ScoopMApi.instance.vendorApplications.create(application);
  }
}
