import firebase from 'firebase/app';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import unwrap from '../../functions/unwrap';
import Delay from '../Delay';
import Spinner from '../Spinner';
import Table from '../Table';
import DataRow from '../Table/DataRow';
import SortingDirection from '../Table/SortingDirection';
import FilterType from './FilterType';

export default function FirestoreTable({
  collection,
  columns,
  sortingColumn,
  onChangeSortingColumn,
  sortingDirection,
  onChangeSortingDirection,
  filters,
  query,
  orderBy,
  limit,
  link,
  onChangeRows,
  onChangeIsFetching,
  search,
  height,
  additionalFilter,
}) {
  // STATE

  const [snapshot, setSnapshot] = useState();
  const [rows, setRows] = useState();
  const [error, setError] = useState();

  // EFFECT: create queryToUse

  useEffect(() => {
    console.log('Updating queryToUse...', collection, filters);

    let queryToUse = query || firebase.firestore().collection(collection);

    // iterate selected filters

    filters &&
      filters.forEach(filter => {
        if (!filter.value) return;

        switch (filter.type) {
          case FilterType.boolean:
          case FilterType.singleValue:
          case FilterType.driverId:
            queryToUse = queryToUse.where(filter.field, '==', filter.value);

            break;

          case FilterType.array:
            queryToUse = queryToUse.where(filter.field, 'in', filter.value);

            break;

          case FilterType.dateRange:
            if (filter.value === 'TODAY_ONLY') {
              queryToUse = queryToUse
                .where(filter.field, '>=', moment().startOf('day').valueOf())
                .where(filter.field, '<=', moment().endOf('day').valueOf());
            } else {
              const [start, end] = filter.value;

              if (start) {
                queryToUse = queryToUse.where(filter.field, '>=', start);
              }
              if (end) {
                queryToUse = queryToUse.where(filter.field, '<=', end);
              }
            }

            break;

          default:
            break;
        }
      });

    // order

    if (orderBy) {
      queryToUse = queryToUse.orderBy(...orderBy);
    }

    // limit

    queryToUse = queryToUse.limit(limit || 500);

    // fetch data

    onChangeIsFetching && onChangeIsFetching(true);
    return queryToUse.onSnapshot(
      snapshot => {
        setError(null);
        setSnapshot(snapshot);
        onChangeIsFetching && onChangeIsFetching(false);
      },
      error => {
        console.error(error);
        setError(error);
        onChangeIsFetching && onChangeIsFetching(false);
      },
    );
  }, [collection, filters, limit, onChangeIsFetching, query]);

  // EFFECT: snapshot docs / search --> rows

  useEffect(() => {
    const rows = unwrap(snapshot, snap => {
      // map docs

      const create = DataRow.creator(columns, sortingColumn);
      let rows = snap.docs.map(doc => {
        const id = doc.id;
        const data = doc.data();
        return create(
          id,
          data,
          unwrap(link, () => link(id, data)),
        );
      });

      // search

      if (search) {
        const searchLowerCase = search.toLowerCase();
        rows = rows.filter(row => row.searchValue.includes(searchLowerCase));
      }

      // if additional filter is set or being set (`null`), use it

      if (additionalFilter !== undefined) {
        if (additionalFilter) {
          rows = rows.filter(additionalFilter);
        } else {
          rows = [];
        }
      }

      // sort

      if (sortingColumn) {
        rows = sortBy(rows, 'sortingValue');
        if (sortingDirection === SortingDirection.descending) rows = reverse(rows);
      }

      return rows;
    });

    setRows(rows);

    onChangeRows && onChangeRows(rows);
  }, [columns, snapshot, sortingColumn, sortingDirection, link, search, onChangeRows, additionalFilter]);

  // RENDER

  if (rows === undefined) {
    return (
      <div className="flex-grow-1 p-4 bg-white">
        <Delay>
          <Spinner />
        </Delay>
      </div>
    );
  }

  return error ? (
    `Error. ${error.message}`
  ) : (
    <Table
      columns={columns}
      rows={rows}
      height={height}
      sortingDirection={sortingDirection}
      onChangeSortingDirection={onChangeSortingDirection}
      sortingColumn={sortingColumn}
      onChangeSortingColumn={onChangeSortingColumn}
      className="flex-grow-1"
    />
  );
}
