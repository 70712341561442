export class ScoopMarker {
  /**
   * Constructor
   * @param {{ id: string, coords: window.google.maps.LatLng, iconSrc: string, infoWindow: { title: string, subtitle: string, details: string, openByDefault: boolean } }} params Parameters
   */
  constructor(params) {
    // check required props
    if (!params.id) {
      throw new Error("Each ScoopMarker must specify it's 'id'");
    }
    if (!params.coords) {
      throw new Error("Each ScoopMarker must specify it's 'coords'");
    }

    // required props
    this.id = params.id;
    this.coords = params.coords;

    // optional props
    this.iconSrc = params.iconSrc || undefined;

    // infoWindow props
    let infoWindow = params.infoWindow;
    if (infoWindow) {
      this.infoWindow = {};
      this.infoWindow.title = infoWindow.title || undefined;
      this.infoWindow.subtitle = infoWindow.subtitle || undefined;
      this.infoWindow.details = infoWindow.details || undefined;
      this.infoWindow.openByDefault = true;
      if (infoWindow.openByDefault === false) this.infoWindow.openByDefault = false;
    }
  }
}
