import {decode} from '@googlemaps/polyline-codec';
import flatMap from 'lodash/flatMap';
import pluralize from 'pluralize';
import React, {useMemo} from 'react';
import ReactGoogleMapsPolygon from 'react-google-maps/lib/components/Polygon';
import {unwrap} from 'wave-common';
import {latLngLiteralFromCoordinatesArray} from 'wave-common/lib/models/CoordinatesArray';
import Polygon from 'wave-common/lib/models/Polygon';
import GoogleMap from '../../components/GoogleMaps/GoogleMap';
import {boundsForLatLngs} from '../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../components/GoogleMaps/MapContents';
import Constants from '../../Constants';
import {PropertyDisplayProps} from './PropertyDisplay';

export default function PolygonsDisplay({schema, property, value: polygons}: PropertyDisplayProps<Polygon[]>) {
  const latLngsArray = useMemo(
    () => polygons?.map(polygon => decode(polygon.coordinatesEncoded).map(latLngLiteralFromCoordinatesArray)),
    [polygons],
  );

  const mapContents = useMemo(
    () =>
      unwrap(
        latLngsArray,
        latLngsArray =>
          new MapContents.Bounds({
            id: 'polygons',
            name: pluralize('Polygon', latLngsArray.length),
            latLngBounds: boundsForLatLngs(flatMap(latLngsArray)),
          }),
      ),
    [latLngsArray],
  );

  return (
    <div style={{height: '22rem'}} className="mb-4">
      <GoogleMap
        mapContents={mapContents}
        polygons={polygons}
        fullscreenControl={false}
        streetViewControl={false}
        useDefaultMapStyles
        rounded>
        {latLngsArray?.map((latLngs, index) => (
          <ReactGoogleMapsPolygon key={index} path={latLngs} options={polygonOptionsPrimary} />
        ))}
      </GoogleMap>
    </div>
  );
}

export const polygonOptions = {
  strokeColor: '#000000',
  strokeOpacity: 0.8,
  strokeWeight: 3,
  fillColor: '#000000',
  fillOpacity: 0.35,
};

export const polygonOptionsPrimary = {
  strokeColor: Constants.theme.primaryColorHexString,
  strokeOpacity: 0.8,
  strokeWeight: 3,
  fillColor: Constants.theme.primaryColorHexString,
  fillOpacity: 0.35,
};
