import React from 'react';
import {match, Redirect} from 'react-router-dom';

interface RideRedirectParams {
  customerId: string;
  tripId: string;
}

export default function RideRedirect(props: {match: match<RideRedirectParams>}) {
  const {customerId, tripId} = props.match.params;

  return <Redirect to={`/admin/jobs/${customerId}/${tripId}`} />;
}
