import reduce from 'lodash/reduce';
import round from 'lodash/round';
import React, {useMemo} from 'react';
import {formatCurrency, Trip, useStatus} from 'wave-common';
import {tripListForDelayedEarnings, tripListForInstantEarnings} from 'wave-common/lib/controllers/Trip';
import EarningsType from 'wave-common/lib/models/EarningsType';
import {DelaySpinner} from '../../../../components/Spinner';
import FirestoreDataSource from '../../../../data-sources/FirestoreDataSource';

export default function EarningsTotal({type, driverId}: {type: EarningsType; driverId: string}) {
  const {
    isPending,
    error,
    value: total,
  } = useStatus(
    React as any,
    useMemo(async () => {
      switch (type) {
        case 'INSTANT':
          return tripListForInstantEarnings(driverId, FirestoreDataSource).then(totalForTrips);
        case 'DELAYED':
          return tripListForDelayedEarnings(driverId, FirestoreDataSource).then(totalForTrips);
      }
    }, [type, driverId]),
  );

  if (isPending) {
    return <DelaySpinner small />;
  }

  if (error) {
    return <span>Unable to load earnings total</span>;
  }

  return <span>{formatCurrency(total)}</span>;
}

async function totalForTrips(trips: Trip[]) {
  return round(
    reduce(trips, (total, trip) => total + (trip.driverEarningsD ?? 0), 0),
    2,
  );
}
