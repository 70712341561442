import React, {useCallback, useContext} from 'react';
import UpdateTripForm from '../components/TripForm/UpdateTripForm';
import Trip from '../models/scoopm/Trip';
import BootstrapModalContext from '../routes/BootstrapModalContext';

export default function useEditTripModal(tripId: string, trip: Trip) {
  const {setModalContent} = useContext(BootstrapModalContext);

  return useCallback(() => {
    setModalContent(
      <>
        <div className="modal-header">
          <h5 className="modal-title" id="rootModalLabel">
            Edit trip
          </h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <UpdateTripForm
            tripId={tripId}
            defaultValue={trip}
            onComplete={() => {
              setModalContent(null);
            }}
          />
        </div>
      </>,
    );
  }, [setModalContent, trip, tripId]);
}
