import React from 'react';
import Vehicle from 'wave-common/lib/models/Vehicle';
import FuelSquareImage from '../../components/FuelSquareImage';
import Property from '../types/Property';
import PropertyType from '../types/PropertyType';
import Schema from '../types/Schema';

const vehicleSchema = new Schema<Vehicle>(
  'vehicle',
  [
    new Property('year', PropertyType.vehicleYear),
    new Property('make', PropertyType.vehicleMake),
    new Property('model', PropertyType.vehicleModel),
    new Property('color', PropertyType.string),
    new Property('trimId', PropertyType.vehicleTrimId, {isOptional: true}),
    new Property('licensePlateNumber', PropertyType.string),
    new Property('driverId', PropertyType.realtimeDatabaseId),
    new Property('transportType', PropertyType.transportType, {isOptional: true}),
  ],
  vehicle => (
    <div className="p-2 p-md-3">
      <FuelSquareImage vehicle={vehicle.model} />
    </div>
  ),
);

export default vehicleSchema;
