"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function useIsMountedRef(react) {
    const isMountedRef = react.useRef(true);
    react.useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);
    return isMountedRef;
}
exports.default = useIsMountedRef;
