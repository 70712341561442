import {useMemo} from 'react';
import {latLng} from '../components/GoogleMaps/LatLng+additions';
import Marker from '../components/GoogleMaps/Marker';
import Constants from '../Constants';
import unwrap from '../functions/unwrap';
import Driver from '../models/scoopm/Driver';

const path =
  'M 4.815 0 L 10.25 0 C 12.445 0 14.227 1.782 14.227 3.978 L 14.227 28.022 C 14.227 30.218 12.445 32 10.25 32 L 4.815 32 C 2.619 32 0.837 30.218 0.837 28.022 L 0.837 3.978 C 0.837 1.782 2.619 0 4.815 0 Z M 13.841 11.42 L 15 11.42 L 15 12.336 L 13.841 12.336 L 13.841 11.42 Z M 0 11.42 L 1.159 11.42 L 1.159 12.336 L 0 12.336 L 0 11.42 Z M 2.189 7.389 L 2.189 12.336 L 13.133 12.336 L 13.133 7.389 L 2.189 7.389 Z M 2.189 13.13 L 2.189 17.832 L 3.412 17.832 L 3.412 13.13 L 2.189 13.13 Z M 2.189 18.504 L 2.189 23.084 L 3.412 23.084 L 3.412 18.504 L 2.189 18.504 Z M 11.91 13.527 L 11.91 18.229 L 13.133 18.229 L 13.133 13.527 L 11.91 13.527 Z M 11.91 18.901 L 11.91 23.481 L 13.133 23.481 L 13.133 18.901 L 11.91 18.901 Z M 3.412 28.763 L 3.412 30.107 L 11.91 30.107 L 11.91 28.763 L 3.412 28.763 Z';

export default function useDriversMarkers({driverEntries, onClick, modifyMarker}) {
  return useMemo(
    () =>
      unwrap(
        driverEntries,
        entries => {
          let markers = entries.map(([driverId, driver]) => {
            const position = latLng(driver.coordinate || [80, 35]);
            return new Marker({
              id: driverId,
              clusterName: 'DRIVERS',
              label: Driver.prototype.casualName.apply(driver),
              position,
              icon: {
                path,
                fillColor: 'black',
                fillOpacity: 1,
                strokeWeight: 0.5,
                strokeColor: 'white',
                scale: 1,
                anchor: new window.google.maps.Point(7, 16),
                labelOrigin: new window.google.maps.Point(10, -30),
                rotation: driver.bearing,
              },
              onClick: unwrap(onClick, callback => () => callback(driverId)),
              zIndex: Constants.zIndex.level1,
            });
          });
          if (modifyMarker) markers = markers.map(modifyMarker);
          return markers;
        },
        [],
      ),
    [driverEntries, onClick, modifyMarker],
  );
}
