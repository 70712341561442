import chunk from 'lodash/chunk';
import React, {useMemo} from 'react';
import Delay from '../../components/Delay';
import Spinner from '../../components/Spinner';
import unwrap from '../../functions/unwrap';
import PropertyType from '../types/PropertyType';
import PropertyDisplay, {None} from './PropertyDisplay';

export default function SchemaDisplay({schema, model = null}) {
  const propertyChunks = useMemo(() => chunk(schema.properties, 2), [schema]);

  return propertyChunks.map((chunk, chunkIndex) => (
    <div key={chunkIndex}>
      <div key={chunk[0].name} className="row">
        {chunk.map(property => (
          <div key={property.name} className="col-md-6 form-group" style={{overflowWrap: 'anywhere'}}>
            <PropertyHeading property={property} />

            {unwrap(
              model,
              model =>
                property.isArray ? (
                  unwrap(
                    model[property.name],
                    values => {
                      if (!values.length) return <None />;
                      if (PropertyType.arrayDisplayComponent(property.type)) {
                        return <PropertyDisplay schema={schema} property={property} value={values} model={model} />;
                      } else {
                        return values.map((value, i) => (
                          <div key={i}>
                            <PropertyDisplay schema={schema} property={property} value={value} model={model} />
                          </div>
                        ));
                      }
                    },
                    () => <None />,
                  )
                ) : (
                  <PropertyDisplay schema={schema} property={property} value={model[property.name]} model={model} />
                ),
              () => (
                <Delay>
                  <Spinner small />
                </Delay>
              ),
            )}
          </div>
        ))}
      </div>
    </div>
  ));
}

export function PropertyHeading({property, additionalContent}) {
  return (
    <div className="mb-3">
      <h6 className="mb-0">{property.userFacingName}</h6>
      {property.description && (
        <div style={{lineHeight: '1rem'}}>
          <small>{property.description}</small>
        </div>
      )}
      {additionalContent}
    </div>
  );
}
