import React, {CSSProperties} from 'react';
import joinClassNames from '../functions/joinClassNames';

interface CardProps {
  className?: string;
  innerClassName?: string;
  padded?: boolean;
  innerPadded?: boolean;
  white?: boolean;
  style?: CSSProperties;
  children: React.ReactNode;
}

const Card = ({className, innerClassName, padded, innerPadded, white, style, children, ...props}: CardProps) => (
  <div style={style} className={joinClassNames(className, padded && 'p-2 p-md-3')} {...props}>
    <div
      className={joinClassNames(
        'rounded',
        (white ?? true) && 'bg-white',
        (innerPadded ?? true) && 'p-2 p-md-3',
        innerClassName,
      )}>
      {children}
    </div>
  </div>
);

export default Card;
