import React from 'react';
import './RideshareLeadGen.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

export default function RideshareLeadGen() {
    return (
      <div className="rideshare-leadgen-page">
        <header className="header-section">
          <div className="header-image-container">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/rideshare-website-images%2Fpexels-pavel-danilyuk-8425057.jpeg?alt=media&token=8f457968-c286-4654-8ae2-0b26957dd3e9"
              alt="Rideshare Services"
              className="header-image"
            />
            <div className="header-overlay">
              <div className="header-logo-container">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/rideshare-website-images%2FWave%20Logo%20Large%20(2).png?alt=media&token=7b576627-7bcf-4b12-924c-1314f87ae1ab"
                  alt="Wave Rideshare Logo"
                  className="header-logo"
                />
              </div>
              <h1 className="header-title">
                Transforming Transportation with Wave Rideshare
              </h1>
              <p className="header-subtitle">
                Specialized rideshare services designed to meet the needs of
                diverse communities.
              </p>
              <a
                href="https://calendly.com/waveoutreach/wave-service-partnership"
                className="btn btn-primary header-cta"
              >
                Schedule a Call
              </a>
            </div>
          </div>
        </header>

      <section className="service-section alt-background">
        <div className="service-content">
          <div className="text-content">
            <h2>Nonprofit Partnerships</h2>
            <h3>Tailored Solutions for Nonprofits</h3>
            <ul>
              <li>Wave collaborates with local nonprofits to identify and address transportation challenges in underserved communities.</li>
              <li>We design and implement reliable, accessible, and affordable rideshare services tailored to the specific needs of these communities.</li>
            </ul>
            <h3>Empowering Communities Through Mobility</h3>
            <ul>
              <li>Wave facilitates transportation to medical care, educational opportunities, and employment.</li>
              <li>We actively promote social inclusion by ensuring that our transportation services are equitably accessible to all community members.</li>
            </ul>
          </div>
          <div className="image-content">
            <img src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/rideshare-website-images%2Fpexels-rdne-6646779.jpg?alt=media&token=ad8fa0eb-d6ce-4d04-8ff9-954b26756097" alt="Nonprofit Partnerships" />
          </div>
        </div>
      </section>

      <section className="service-section">
        <div className="service-content">
          <div className="image-content">
            <img src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/rideshare-website-images%2F1723994164258.jpeg?alt=media&token=9e94fe46-d825-413e-8d81-56d08cbfcb6a" alt="Kids Rides" />
          </div>
          <div className="text-content">
            <h2>Kids/Rides</h2>
            <h3>Safe and Secure Transportation for Children</h3>
            <ul>
              <li>Wave prioritizes the safety and security of children by offering meticulously vetted and trained drivers who specialize in child transportation.</li>
              <li>We implement stringent safety protocols, ensuring that every ride is a secure and worry-free experience for both children and their parents.</li>
            </ul>
            <h3>Reliable School and Activity Transport</h3>
            <ul>
              <li>Wave provides dependable transportation services for daily school drop-offs and pick-ups, ensuring children arrive on time, every time.</li>
              <li>We accommodate extracurricular activities, offering flexible scheduling to ensure children can participate fully in after-school programs and special events.</li>
            </ul>
            <h3>Peace of Mind for Parents</h3>
            <ul>
                <li>Wave keeps parents informed with real-time updates and tracking, offering peace of mind by ensuring they always know their child’s location and status.</li>
                <li>We create a seamless communication channel between parents, drivers, and schools to ensure every ride meets the highest standards of care and reliability.</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="service-section alt-background">
        <div className="service-content">
          <div className="text-content">
            <h2>Elderly/Rides</h2>
            <h3>Comfortable and Convenient Transportation for Seniors</h3>
            <ul>
              <li>Wave provides tailored transportation services designed specifically for the elderly, ensuring comfort and ease throughout the journey.</li>
              <li>We offer door-to-door service, assisting seniors from their homes to their destinations with the utmost care and attention.</li>
            </ul>
            <h3>Personalized Care and Attention</h3>
            <ul>
              <li>Wave goes beyond transportation by offering personalized care, understanding the unique needs of elderly passengers, and adapting our services accordingly.</li>
              <li>We maintain regular communication with families to provide updates and ensure peace of mind, making each ride a positive experience for both seniors and their loved ones.</li>
            </ul>
          </div>
          <div className="image-content">
            <img src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/rideshare-website-images%2F1723237715357.jpeg?alt=media&token=bd52e1c4-03d1-463b-ba25-8152bc9290d5" alt="Elderly Rides" />
          </div>
        </div>
      </section>

      <section className="service-section">
        <div className="service-content">
          <div className="image-content">
            <img src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/rideshare-website-images%2Fistockphoto-1294976110-2048x2048.png?alt=media&token=a5250339-1263-4a22-80de-14a57d2cca86" alt="Medical Rides" />
          </div>
          <div className="text-content">
            <h2>Medical/Rides</h2>
            <h3>Non-Emergency Medical Transportation</h3>
            <ul>
              <li>Wave provides reliable NEMT services, ensuring patients reach their medical appointments safely and on time.</li>
              <li>We understand the importance of punctuality in healthcare, and our drivers are trained to handle any special needs or requirements.</li>
            </ul>
            <h3>Comfortable and Well-Equipped Vehicles</h3>
            <ul>
              <li>Wave uses vehicles that are clean, comfortable, and equipped to meet the specific needs of medical passengers.</li>
              <li>Our fleet includes features like wheelchair accessibility and extra space for medical equipment, ensuring a smooth and secure journey.</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="service-section alt-background">
        <div className="service-content">
          <div className="text-content">
            <h2>B2B (Business-to-Business) Transportation Solutions</h2>
            <h3>Corporate Rideshare Solutions</h3>
            <ul>
              <li>Wave offers customized rideshare solutions designed to meet the unique transportation needs of businesses.</li>
              <li>We work closely with companies to create transport plans that align with their goals, ensuring efficiency and reliability.</li>
            </ul>
            <h3>Employee Transport and Client Shuttles</h3>
            <ul>
              <li>Wave provides safe and timely transportation for employees, streamlining daily commutes and reducing logistical challenges.</li>
              <li>We offer client shuttles that reflect your company’s professionalism, ensuring guests and partners arrive at their destinations comfortably and on time.</li>
              <li>We prioritize punctuality and reliability, allowing your team to focus on business while we manage the transportation.</li>
            </ul>
          </div>
          <div className="image-content">
            <img src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/rideshare-website-images%2F1721244985916.jpeg?alt=media&token=3d5d946e-44bb-44cf-a499-99eb8273399c" alt="B2B Solutions" />
          </div>
        </div>
      </section>

      <section className="contact-section">
        <h2>Contact Us</h2>
        <img src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/rideshare-website-images%2FWave%20Logo%20Large%20(2).png?alt=media&token=7b576627-7bcf-4b12-924c-1314f87ae1ab" alt="Wave Rideshare Logo" className="contact-logo" />
        <p className="highlighted-contact">
          Need Transportation Services? <a href="tel:18339283663" className="contact-link">Call us at 1-833-WAVE2ME</a>
        </p>
      </section>

      <section className="cta-section contrast-background">
        <p>Interested in learning more about how our rideshare services can benefit your organization?</p>
        <a href="https://calendly.com/waveoutreach/wave-service-partnership" className="btn btn-primary">Schedule a Call</a>
      </section>

      <section className="carousel-section contrast-background">
        <h2>What Our Customers Say</h2>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={false}
          containerClass="carousel-container"
        >
          <div className="carousel-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/0khTpDcMwPM"
              title="Wave Testimonial 1"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>

          <div className="carousel-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/p679v45Mito"
              title="Wave Testimonial 2"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>

          <div className="carousel-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/MpXMWMnotCY"
              title="Wave Testimonial 3"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>

          <div className="carousel-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/5TkH-7gH9EY"
              title="Wave Testimonial 4"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>

          <div className="carousel-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/vAutU8tDhJA"
              title="Wave Testimonial 5"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>

          <div className="carousel-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/8zeH2yNjpd0"
              title="Wave Testimonial 6"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>

          <div className="carousel-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/RcCUkCMYmhg"
              title="Wave Testimonial 7"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>

          <div className="carousel-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/ZkPySQJenG0"
              title="Wave Testimonial 8"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>

          <div className="carousel-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/XPpdkCyPpAE"
              title="Wave Testimonial 9"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>

          <div className="carousel-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/SIFkNIVnSww"
              title="Wave Testimonial 10"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        </Carousel>
      </section>
    </div>
  );
}
