import firebase from 'firebase/app';
import moment from 'moment';
import FirestoreModel from '../base/FirestoreModel';

export default class Invite extends FirestoreModel {
  creatorId: string;
  email: string;
  expirationTimestampMs: number;
  isAccepted: boolean;

  constructor(snapshot?: firebase.firestore.DocumentSnapshot) {
    super(snapshot);

    this.creatorId = snapshot?.get('creatorId');
    this.email = snapshot?.get('email');
    this.expirationTimestampMs = snapshot?.get('expirationTimestampMs');
    this.isAccepted = snapshot?.get('isAccepted');
  }

  isCurrent() {
    return moment().isBefore(moment(this.expirationTimestampMs));
  }
}
