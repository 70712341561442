import omit from 'lodash/omit';
import React, {useCallback} from 'react';
import Subscription, {subscriptionDescription} from '../../models/stripe/Subscription';
import ScoopMApi from '../../references/scoopm-api';
import {PropertyDisplayProps} from './PropertyDisplay';
import StripeObjectDisplay from './StripeObjectDisplay';

export default function StripeSubscriptionDisplay(props: PropertyDisplayProps<string>) {
  const referenceForId = useCallback((id: string) => ScoopMApi.instance.stripe.subscriptions.child(id), []);
  const format = useCallback((subscription: Subscription) => subscriptionDescription(subscription), []);
  return (
    <StripeObjectDisplay referenceForId={referenceForId} format={format} {...omit(props, 'referenceForId', 'format')} />
  );
}
