export default function unwrapOld<Input, Output>(
  value: Input | null | undefined,
  successCallbackOrValueOrNull?: ((result: Input) => Output) | Output | null,
  failureCallbackOrValue?: (() => Output) | Output,
): Output | null {
  if (value === null || value === undefined) {
    if (failureCallbackOrValue !== undefined) {
      if (failureCallbackOrValue instanceof Function) {
        return failureCallbackOrValue();
      } else {
        return failureCallbackOrValue;
      }
    } else {
      return null;
    }
  } else {
    if (successCallbackOrValueOrNull) {
      if (successCallbackOrValueOrNull instanceof Function) {
        return successCallbackOrValueOrNull(value);
      } else {
        return successCallbackOrValueOrNull;
      }
    } else {
      return value as unknown as Output;
    }
  }
}

export function unwrap<Input>(input: Input | undefined | null): Input | undefined;

export function unwrap<Input, Output>(
  input: Input | undefined | null,
  outputOrOutputCallback: (input: Input) => Output,
): Output | undefined;
export function unwrap<Input, Output>(
  input: Input | undefined | null,
  outputOrOutputCallback: Output | undefined | null,
): Output | undefined;

export function unwrap<Input, Output>(
  input: Input | undefined | null,
  outputOrOutputCallback: (input: Input) => Output,
  fallbackCallback: () => Output,
): Output | undefined;
export function unwrap<Input, Output>(
  input: Input | undefined | null,
  outputOrOutputCallback: (input: Input) => Output,
  fallback: Output,
): Output | undefined;
export function unwrap<Input, Output>(
  input: Input | undefined | null,
  outputOrOutputCallback: Output | undefined | null,
  fallbackCallback: () => Output,
): Output | undefined;
export function unwrap<Input, Output>(
  input: Input | undefined | null,
  outputOrOutputCallback: Output | undefined | null,
  fallback: Output,
): Output | undefined;

export function unwrap<Input, Output>(
  input: Input | undefined | null,
  outputOrOutputCallback?: Output | ((input: Input) => Output),
  fallbackOrFallbackCallback?: Output | (() => Output),
): Input | Output | undefined {
  if (input !== null && input !== undefined) {
    if (outputOrOutputCallback === undefined || outputOrOutputCallback === null) {
      return input;
    } else if (outputOrOutputCallback instanceof Function) {
      return outputOrOutputCallback(input);
    } else {
      return outputOrOutputCallback;
    }
  } else {
    if (fallbackOrFallbackCallback === undefined || fallbackOrFallbackCallback === null) {
      return undefined;
    } else if (fallbackOrFallbackCallback instanceof Function) {
      return fallbackOrFallbackCallback();
    } else {
      return fallbackOrFallbackCallback;
    }
  }
}
