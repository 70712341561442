import React, {useMemo} from 'react';
import unwrap from '../../functions/unwrap';
import {LocationsColumn} from './LocationsColumn';
import {MapColumn} from './MapColumn';

export function LocationsRow({model}) {
  const locations = useMemo(() => unwrap(model, model => model.locationsWithDefault()), [model]);

  return (
    <div className="row mb-5">
      <LocationsColumn
        locations={locations}
        driverId={unwrap(model, model => model.driverId)}
        driver={unwrap(model, model => model.driver)}
      />
      <MapColumn locations={locations} />
    </div>
  );
}
