import firebase from 'firebase/app';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import React, {useEffect, useState} from 'react';
import formatCurrency from '../../functions/formatCurrency';
import {unwrap} from '../../functions/unwrap';
import ContentType from '../../models/scoopm/ContentType';
import DispatchCancellationReason from '../../models/scoopm/DispatchCancellationReason';
import Trip from '../../models/scoopm/Trip';
import TripLocation from '../../models/scoopm/TripLocation';
import Delay from '../Delay';
import FontAwesome from '../FontAwesome';
import Spinner from '../Spinner';
import PaddingRow from './PaddingRow';
import RideDetailsRow from './RideDetailsRow';

type Receipt = {contentType: string; id: string};

export function TripDetails({trip, tripId}: {trip: Trip; tripId: string}) {
  const receiptUrls = useReceiptUrls(trip ? (trip.receipts as Receipt[]) : undefined);

  return (
    <>
      {/* cancellation reason */}

      {trip.dispatchCancellationReason ? (
        <RideDetailsRow title="Cancellation reason">
          {unwrap(
            DispatchCancellationReason.from(trip.dispatchCancellationReason) as DispatchCancellationReason,
            reason => reason.title,
          )}
        </RideDetailsRow>
      ) : (
        trip.cancellationReason && (
          <RideDetailsRow title="Cancellation reason">
            {capitalize(trip.cancellationReason.replace(/_/, ' '))}
          </RideDetailsRow>
        )
      )}

      {/* cancellation comments */}

      {trip.cancellationComments && (
        <RideDetailsRow title="Cancellation comments">{trip.cancellationComments}</RideDetailsRow>
      )}

      {/* wait time / task time */}

      {unwrap(
        find(trip.locations as TripLocation[], l => l.waitTimeS),
        (waitTimeS: number) => (
          <RideDetailsRow title="Wait time / task time">
            {(() => {
              const hours = Math.round((waitTimeS / 60 / 60) * 100) / 100;
              const hoursWord = hours === 1 ? 'hour' : 'hours';
              return `${hours} ${hoursWord}`;
            })()}
          </RideDetailsRow>
        ),
      )}

      {/* order value */}

      <RideDetailsRow title="Order value">
        {unwrap(trip.orderValue, orderValue => formatCurrency(orderValue))}
      </RideDetailsRow>

      {/* tip */}

      <RideDetailsRow title="Tip">{unwrap(trip.tip, formatCurrency)}</RideDetailsRow>

      {/* estimate */}

      {/* {claims && !claims[CustomClaim.selfAssign] && (
                <RideDetailsRow title={EstimateModel.name(trip.type)}>
                    <Estimate tripId={tripId} trip={trip} />
                </RideDetailsRow>
            )} */}

      {/* driver rate */}

      {/* <ClaimsGuard claim={CustomClaim.manageDrivers}>
                <RideDetailsRow title="Driver rate">
                    <DriverEarnings trip={trip} tripId={tripId} />
                </RideDetailsRow>
            </ClaimsGuard> */}

      {/* uploads */}

      {trip.receipts && (
        <RideDetailsRow
          title={
            <>
              <i className="fas fa-receipt mr-2" />
              Uploads
            </>
          }>
          {unwrap(trip.receipts, receipts =>
            unwrap(
              receiptUrls,
              (receiptUrls: string[]) =>
                receiptUrls.map((url, i) =>
                  unwrap(ContentType.from(receipts[i].contentType) as ContentType, contentType =>
                    contentType.isImage ? (
                      <div key={i} className="receipt-image">
                        <a key={i} href={url} target="_blank" rel="noopener noreferrer" className="mr-3">
                          <img src={url} alt={'Image' + (receiptUrls.length > 1 && ` ${i + 1}`)} className="receipt" />
                        </a>
                      </div>
                    ) : (
                      <a key={i} href={url} target="_blank" rel="noopener noreferrer" className="mr-3">
                        <FontAwesome.ExternalLinkAltSolid className={iconClassName} /> {contentType.label}{' '}
                        {receiptUrls.length > 1 && ` ${i + 1}`}
                      </a>
                    ),
                  ),
                ),
              () =>
                (
                  <Delay>
                    <Spinner />
                  </Delay>
                ) as never,
            ),
          )}
        </RideDetailsRow>
      )}

      {/* padding */}

      {unwrap(trip.ezCaterPaddingM, paddingM => (
        <RideDetailsRow title="Padding">
          <PaddingRow defaultValue={paddingM} deliveryId={trip.ezCaterDeliveryId} />
        </RideDetailsRow>
      ))}
    </>
  );
}

export const iconClassName = 'd-none d-md-inline';

export const useReceiptUrls = (
  receipts: Receipt[] | undefined,
  getDownloadURL = (path: string): Promise<string> => firebase.storage().ref(path).getDownloadURL(),
) => {
  const [receiptUrls, setReceiptUrls] = useState<string[]>();

  // after receipt reference is set, get download url

  useEffect(() => {
    if (!receipts) {
      setReceiptUrls(undefined);
    } else {
      const promises = receipts.map(receipt => getDownloadURL(`/receipts/${receipt.id}`));

      console.log('Getting upload URLs...');

      Promise.all(promises).then(
        urls => {
          console.log('Got upload URLs', urls);

          setReceiptUrls(urls);
        },
        error => {
          console.error('Error getting upload URLs', error);
        },
      );
    }
  }, [receipts, getDownloadURL]);

  return receiptUrls;
};
