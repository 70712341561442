import firebase from 'firebase/app';
import round from 'lodash/round';
import HttpReference from '../platform/HttpReference';

export default class UpdatePadding extends HttpReference {
  async put(paddingM) {
    const user = firebase.auth().currentUser;
    if (!user) throw new Error('Unauthenticated');
    const token = await user.getIdToken();
    return this.request({
      method: 'PUT',
      headers: {Authorization: `Bearer ${token}`},
      data: {
        paddingM: round(paddingM),
      },
    });
  }
}
