import React, {useEffect, useState, ReactNode} from 'react';

interface Value {
  themeColor: string;
  setThemeColor: React.Dispatch<React.SetStateAction<string>>;
}

const ThemeColorContext = React.createContext<Value>(undefined as any);
export default ThemeColorContext;

export function ThemeColorContextWrapper(props: {children: ReactNode}) {
  const [themeColor, setThemeColor] = useState('#FFFFFF');

  useEffect(() => {
    window.document.querySelector('meta[name="theme-color"]')?.setAttribute('content', themeColor);
  }, [themeColor]);

  return <ThemeColorContext.Provider value={{themeColor, setThemeColor}}>{props.children}</ThemeColorContext.Provider>;
}
