import TripModel from '../../models/scoopm/Trip';
import TripStatus from '../../models/scoopm/TripStatus';
import DatabaseReference from '../platform/DatabaseReference';
import firebase from 'firebase/app';

export default class Trip extends DatabaseReference {
  get Model() {
    return TripModel;
  }

  async advanceToNextLocation(tripData) {
    switch (TripStatus.from(tripData.status)) {
      case TripStatus.created:
      case TripStatus.scheduled:
        const customerId = tripData.customerId;
        if (!customerId) throw new Error('Trip is missing customer ID');
        const tripId = this.path;
        return this.root.update({
          [`/trips/${customerId}/${tripId}/status`]: TripStatus.accepted.rawValue,
          [`/trips/${customerId}/${tripId}/confirmedAt`]: firebase.database.ServerValue.TIMESTAMP,
          [`/customers/${customerId}/currentRide`]: tripId,
        });

      case TripStatus.accepted:
        return this.update({
          status: TripStatus.started.rawValue,
          startedAt: firebase.database.ServerValue.TIMESTAMP,
        });

      case TripStatus.started:
        return this.update({
          status: TripStatus.completed.rawValue,
          completedAt: firebase.database.ServerValue.TIMESTAMP,
        });

      default:
        throw new Error('Invalid status');
    }
  }

  async reverseToPreviousLocation(tripData) {
    switch (TripStatus.from(tripData.status)) {
      case TripStatus.accepted:
        if (!TripModel.prototype.isScheduledWithDefault.apply(tripData))
          throw new Error('Trip is not scheduled, cannot reverse from "ACCEPTED"');

        return this.update({
          status: TripStatus.scheduled.rawValue,
          confirmedAt: null,
        });

      case TripStatus.started:
        return this.update({
          status: TripStatus.accepted.rawValue,
          startedAt: null,
          'locations/0/completionTimestampMs': null,
          'locations/0/arrivalTimestampMs': null,
        });

      case TripStatus.completed:
        return this.update({
          status: TripStatus.started.rawValue,
          completedAt: null,
          'locations/1/completionTimestampMs': null,
          'locations/1/arrivalTimestampMs': null,
        });

      default:
        throw new Error('Invalid status');
    }
  }
}
