"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sfSymbolScaleForIconScale = void 0;
function sfSymbolScaleForIconScale(iconScale) {
    switch (iconScale) {
        case 'small':
            return 1;
        case 'medium':
            return 2;
        case 'large':
            return 3;
    }
}
exports.sfSymbolScaleForIconScale = sfSymbolScaleForIconScale;
