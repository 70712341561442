import unwrap from '../functions/unwrap';
import useRealtimeDatabase from './useRealtimeDatabase';

export default function useTripEzCaterEvents(tripData) {
  return useRealtimeDatabase(
    unwrap(tripData, data =>
      unwrap(data.ezCaterDeliveryId, id => ({
        path: '/events',
        orderByChild: 'requestBody/id',
        equalTo: id,
      })),
    ) || {
      path: null,
    },
  );
}
