import omit from 'lodash/omit';
import React, {useCallback} from 'react';
import Customer, {customerDescription} from '../../models/stripe/Customer';
import ScoopMApi from '../../references/scoopm-api';
import {PropertyDisplayProps} from './PropertyDisplay';
import StripeObjectDisplay from './StripeObjectDisplay';

export default function StripeCustomerDisplay(props: PropertyDisplayProps<string>) {
  const referenceForId = useCallback((id: string) => ScoopMApi.instance.stripe.customers.child(id), []);
  const format = useCallback((customer: Customer) => customerDescription(customer), []);
  return (
    <StripeObjectDisplay referenceForId={referenceForId} format={format} {...omit(props, 'referenceForId', 'format')} />
  );
}
