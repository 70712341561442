import {useRef} from 'react';
import {useEffect} from 'react';

export default function usePrevious(value, startingValue = {}) {
  const ref = useRef(startingValue || value || undefined);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
