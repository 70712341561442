import moment from 'moment';

import querystringify from 'querystringify';
import React, {Component} from 'react';

import {Pin, CustomPin} from '../../components/Maps/GooglePin';

import {ScoopMap} from '../../components/Maps/ScoopMap';
import {dmfError} from '../../functions/dmflog';
import FuelSquareImage from '../../images/car.png';

import {DbRefs, Db} from '../../services/Db';
import {SharedRide} from './SharedRide';

const ERROR = 'ERROR';
const INVALID = 'INVALID';
const SUCCESS = 'SUCCESS';

export class ShareMyRide extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let token = querystringify.parse(window.location.search).token;
    if (!token) {
      this.tokenIsInvalid();
      return;
    }
    DbRefs.sharedRide(token)
      .once(`value`)
      .then(snapshot => {
        let val = snapshot.val();
        if (!val) {
          this.setState({status: INVALID});
          return;
        }
        let sharedRide = new SharedRide(val);
        this.firebaseGotRideId(sharedRide);
      })
      .catch(this.firebaseGotError.bind(this));
  }

  firebaseGotRideId(sharedRide) {
    // create firebase refs
    if (sharedRide.isRide) {
      // it's a ride
      this.ref = DbRefs.ride(sharedRide.rideId);
    } else {
      // it's a trip
      this.ref = DbRefs.trip(sharedRide.customerId, sharedRide.tripId);
      this.driverRef = DbRefs.driver(sharedRide.driverId);
    }

    // observe trip/ride
    this.callback = this.ref.on(
      `value`,
      snapshot => {
        // set data
        let val = snapshot.val();
        sharedRide.setData(val);
        // create pins
        let fromPin = new Pin('Pickup', sharedRide.fromCoords, sharedRide.fromAddress, sharedRide.fromName);
        let toPin = new Pin('Drop Off', sharedRide.toCoords, sharedRide.toAddress, sharedRide.toName);
        // check if ride is complete
        if (sharedRide.isComplete) {
          // remove driver observer
          if (this.driverCallback) {
            this.driverRef.off(`value`, this.driverCallback);
          }
          // remove custom pins (driver pin)
          this.setState({customPins: []});
        }
        // set state
        this.setState({
          status: 'SUCCESS',
          sharedRide,
          fromPin,
          toPin,
        });
      },
      this.firebaseGotError.bind(this),
    );

    // observe driver
    if (this.driverRef) {
      this.driverCallback = this.driverRef.on(
        `value`,
        snapshot => {
          let driver = snapshot.val();
          if (driver) {
            // let location = {
            //   lat: driver.coordinate[0],
            //   lng: driver.coordinate[1],
            // };
            let driverPin = new CustomPin('Driver', {lat: 35.249579, lng: -80.865435}, FuelSquareImage);
            let customPins = [driverPin];
            this.setState({customPins});
          }
        },
        this.firebaseGotError.bind(this),
      );
    }
  }

  firebaseGotError(error) {
    this.setState({status: ERROR});
    dmfError(error);
  }

  componentWillUnmount() {
    if (this.callback) {
      this.ref.off(`value`, this.callback);
    }
    if (this.driverCallback) {
      this.driverRef.off(`value`, this.driverCallback);
    }
  }

  render() {
    console.log(this.state.sharedRide);

    // invalid token message
    if (this.state.status === INVALID)
      return (
        <div className="card card-basic">
          <h3>We're sorry</h3>
          <p>This token is not valid</p>
        </div>
      );

    // error message
    if (this.state.status === ERROR)
      return (
        <div className="card card-basic">
          <h3>We're sorry</h3>
          <p>There was an error viewing the ride</p>
        </div>
      );

    // got trip/ride
    if (this.state.status === SUCCESS) {
      return (
        <div className="card card-basic">
          <h3 className="mb-0">
            {this.state.sharedRide.customer.firstName || ''} {this.state.sharedRide.customer.lastName || ''} shared
            their location with you
          </h3>{' '}
          <hr />
          <div className="row">
            <div className="col-auto">
              <h4>{Db.fields.types[this.state.sharedRide.type].name}</h4>
              <ul className="list-group mb-3">
                <li className="list-group-item">
                  <h5>Pickup</h5>
                  {this.state.sharedRide.toName && <span>{this.state.sharedRide.toName}</span>}
                  <span>{this.state.sharedRide.fromAddress}</span>
                </li>
                <li className="list-group-item">
                  <h5>Drop Off</h5>
                  {this.state.sharedRide.fromName && <span>{this.state.sharedRide.fromName}</span>}
                  <span>{this.state.sharedRide.toAddress}</span>
                </li>
              </ul>
            </div>
            <div className="col-auto">
              {this.state.sharedRide.createdAt && (
                <div>
                  <h5>Progress</h5>
                  <ul>
                    {this.state.sharedRide.createdAt && (
                      <li>Requested {moment(this.state.sharedRide.createdAt).calendar()}</li>
                    )}
                    {this.state.sharedRide.confirmedAt && (
                      <li>Accepted {moment(this.state.sharedRide.confirmedAt).calendar()}</li>
                    )}
                    {this.state.sharedRide.startedAt && (
                      <li>Rider picked up {moment(this.state.sharedRide.startedAt).calendar()}</li>
                    )}
                    {this.state.sharedRide.completedAt && (
                      <li>Completed {moment(this.state.sharedRide.completedAt).calendar()}</li>
                    )}
                    {this.state.sharedRide.canceledAt && (
                      <li>
                        Cancelled {moment(this.state.sharedRide.canceledAt).calendar()}
                        {this.state.sharedRide.isCanceledByRider && ' by rider'}
                        {this.state.sharedRide.isCanceledByDriver && ' by driver'}
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <ScoopMap
            originPin={this.state.fromPin}
            destinationPin={this.state.toPin}
            customPins={this.state.customPins}
            containerClassName="w-100"
          />
        </div>
      );
    } else {
      // loading
      return <div>Loading...</div>;
    }
  }
}
