import BillingPortalSession from '../../../models/stripe/BillingPortalSession';
import HttpReference from '../../platform/HttpReference';
import BillingPortal from './BillingPortal';

export default class BillingPortalSessions extends HttpReference<BillingPortalSession, void, BillingPortal> {
  create(stripeCustomerId: string) {
    return this.request({
      method: 'POST',
      data: {
        stripeCustomerId,
      },
    });
  }
}
