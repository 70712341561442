import firebase from 'firebase/app';
import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Card from '../../components/Card';
import {DelaySpinner} from '../../components/Spinner';
import ViewInFirebase from '../../components/ViewInFirebase';
import unwrap from '../../functions/unwrap';
import useFirestoreListener from '../../hooks/useFirestoreListener';
import CenteredLayout from '../../layouts/CenteredLayout';
import FirestoreModelForm from './FirestoreModelForm';
import SchemaDisplay from './SchemaDisplay';

export default function FirestoreModelDisplay({schema, id}) {
  const history = useHistory();
  const reference = useMemo(() => firebase.firestore().collection(schema.collection()).doc(id), [id, schema]);
  const {isLoading, snapshot, error} = useFirestoreListener(reference);
  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const [isDeleting, setIsDeleting] = useState(false);

  // METHODS

  const deleteModel = event => {
    if (!window.confirm('Delete this item?')) {
      return;
    }
    console.log(`Deleting ${reference.path}...`);
    setIsDeleting(true);
    reference.delete().then(
      () => {
        setIsDeleting(false);
        console.log(`Deleted ${reference.path}`);
        history.push(`/admin/${schema.collection()}`);
      },
      error => {
        setIsDeleting(false);
        console.error(`Error deleting ${reference.path}`, error);
      },
    );
  };

  // RENDER

  if (error) {
    return <div className="alert alert-warning">Error. {error.message}</div>;
  }

  if (isEditing) {
    return (
      <FirestoreModelForm
        schema={schema}
        id={id}
        model={snapshot.data()}
        onSave={toggleIsEditing}
        onCancel={toggleIsEditing}
        shouldUpdate
      />
    );
  }

  if (snapshot && !snapshot.exists && !isDeleting) {
    return 'This item does not exist';
  }

  const model = unwrap(snapshot, snap => snap.data());

  if (isLoading) {
    return (
      <CenteredLayout>
        <DelaySpinner />
      </CenteredLayout>
    );
  }

  return (
    <>
      <Card padded>
        <SchemaDisplay schema={schema} model={model} />

        <div className="d-flex justify-content-end">
          <ViewInFirebase firestorePath={`${schema.collection()}/${id}`} padded={false} className="mr-2" />

          <button onClick={deleteModel} className="btn btn-outline-danger mr-2" disabled={isDeleting}>
            Delete...
          </button>

          <button onClick={toggleIsEditing} className="btn btn-outline-primary" disabled={isDeleting}>
            Edit
          </button>
        </div>
      </Card>

      {unwrap(schema.AdditionalContentComponent, Component => (
        <Card innerPadded={false} className="px-2 px-md-3 pb-2 pb-md-3">
          <Component schema={schema} id={id} model={model} />
        </Card>
      ))}
    </>
  );
}
