import capitalize from 'lodash/capitalize';
import React from 'react';
import Card from '../../components/Card';
import RealtimeDatabaseModelDisplay from '../../schemas/components/RealtimeDatabaseModelDisplay';
import RealtimeDatabaseModelForm from '../../schemas/components/RealtimeDatabaseModelForm';
import RealtimeDatabaseModelTable from '../../schemas/components/RealtimeDatabaseModelTable';
import Route from './Route';

export default class RealtimeDatabaseSchemaRoute extends Route {
  constructor({schema, Icon, requiredClaims}) {
    super({
      path: schema.collection(),
      name: capitalize(schema.userFacingName()) + 's',
      Icon,
      requiredClaims,
      children: [
        new Route({
          name: 'All',
          exact: true,
          Component: () => <RealtimeDatabaseModelTable schema={schema} />,
        }),
        new Route({
          name: `Add ${schema.userFacingName()}`,
          path: 'add',
          Component: () => (
            <Card padded>
              <RealtimeDatabaseModelForm schema={schema} />
            </Card>
          ),
        }),
        new Route({
          name: capitalize(schema.userFacingName()),
          path: ':id',
          hideFromMenu: true,
          Component: ({match}) => <RealtimeDatabaseModelDisplay id={match.params.id} schema={schema} />,
        }),
      ],
    });
  }
}
