import {decode} from '@googlemaps/polyline-codec';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {auth, User} from 'firebase/app';
import isSafeInteger from 'lodash/isSafeInteger';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import flatMap from 'lodash/flatMap';
import last from 'lodash/last';
import round from 'lodash/round';
import times from 'lodash/times';
import moment from 'moment';
import React, {ChangeEvent, FormEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Marker, Polyline} from 'react-google-maps';
import {Link, Redirect} from 'react-router-dom';
import {StringParam, useQueryParam} from 'use-query-params';
import {
  BaseUser,
  baseUserFromUser,
  coordinatesArrayFromObject,
  ErrorWithReason,
  exists,
  expect,
  filterBoolean,
  formatPhone,
  formatPhoneE164,
  Join,
  latLngFromCoordinatesObject,
  locationDescription,
  locationDescriptionSimple,
  tripDeserializeWithoutId,
  unwrap,
  useDeepComparisonMemo,
  useLogOnChange,
  useStatus,
} from 'wave-common';
import {userRead, userUpdatePhone} from 'wave-common/lib/controllers/User';
import useTripEstimates from 'wave-common/lib/hooks/useTripEstimates';
import {directionsFromGoogleMapsClientDirectionsResult} from 'wave-common/lib/models/Directions';
import {emptyTripRideshareNew} from 'wave-common/lib/models/EmptyTrip';
import {tripLocationFromPlace} from 'wave-common/lib/models/TripLocation';
import GeolocationPositionErrorCode, {
  geolocationPositionErrorCodeCustomerDescription,
} from 'wave-common/lib/models/web/GeolocationPositionErrorCode';
import Alert from '../../../components/Alert/Alert';
import ValidateableInput from '../../../components/bootstrap/ValidateableInput';
import FontAwesome, {FontAwesomeV5} from '../../../components/FontAwesome';
import Autocomplete from '../../../components/GoogleMaps/Autocomplete';
import GoogleMap from '../../../components/GoogleMaps/GoogleMap';
import {iconMapPin3FillBorder, iconMapPin3FillBorderPink, labelWhite} from '../../../components/GoogleMaps/helpers';
import {boundsForLatLngs, latLng} from '../../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../../components/GoogleMaps/MapContents';
import Input from '../../../components/Input';
import PrivacyNotice from '../../../components/PrivacyNotice';
import {SeparatorSm} from '../../../components/Separators';
import Constants from '../../../Constants';
import {useAlert} from '../../../contexts/AlertContext';
import {useAuthContext} from '../../../contexts/AuthContext';
import {useMinimalUi} from '../../../contexts/MinimalUiContext';
import {useNewTripContext} from '../../../contexts/NewTripContext';
import {directionsReadForLocations} from '../../../controllers/Directions';
import FirestoreDataSource from '../../../data-sources/FirestoreDataSource';
import RealtimeDatabaseDataSource from '../../../data-sources/RealtimeDatabaseDataSource';
import {environmentSelect, environmentVariable} from '../../../Environment';
import joinClassNames from '../../../functions/joinClassNames';
import withStripe from '../../../hocs/withStripe';
import useGeolocation from '../../../hooks/useGeolocation';
import useScreenIsSmall from '../../../hooks/useScreenIsSmall';
import useUserData from '../../../hooks/useUserData';
import MapPin3FillBlue from '../../../images/MapPin3FillBlue';
import MapPin3FillPink from '../../../images/MapPin3FillPink';
import ContainerLayout from '../../../layouts/ContainerLayout';
import ScoopMApi from '../../../references/scoopm-api';
import {useValidation} from '../../../Validation';
import DatePicker from './DatePicker';
import EstimateCard from './EstimateCard';

const PANTHERS_PROMO_CODE = 'TSE123';

type ValidationKey =
  | 'passengerCount'
  | 'pickupLocation'
  | 'pickupTimestamp'
  | 'pickupInstructions'
  | 'dropOffLocation'
  | 'couponCode'
  | 'payment'
  | 'userFirstName'
  | 'userLastName'
  | 'userCellphone'
  | 'userEmail'
  | 'userPassword'
  | 'general';

function RideForm() {
  const [promoInitialValue] = useQueryParam('promo', StringParam);

  // refs

  const didAttemptGeocodeRef = useRef(false);

  // state

  useMinimalUi();
  const screenIsSmall = useScreenIsSmall();
  const {user} = useAuthContext();
  const {type: userType} = useUserData();

  const {setAlert} = useAlert();

  const [passengerCount, setPassengerCount] = useState<string>();

  const [couponCode, setCouponCode] = useState<string>(promoInitialValue ?? '');
  const shouldShowUserFields = useMemo(() => !auth().currentUser, []);
  const [shouldCreateAccount, setShouldCreateAccount] = useState(true);
  const minimumPickupDate = useMemo(
    () => moment().add(Constants.minimumBookingTimeH, 'hour').endOf('hour').toDate(),
    [],
  );
  const maximumPickupDate = useMemo(
    () => moment().add(Constants.maximumBookingTimeH, 'hour').endOf('hour').toDate(),
    [],
  );
  const [firstName, setFirstName] = useState<string>(environmentSelect({development: 'Tester', default: ''}));
  const [lastName, setLastName] = useState<string>(environmentSelect({development: 'Person', default: ''}));
  const [cellphone, setCellphone] = useState<string>(environmentSelect({development: '(704) 706-2272', default: ''}));
  const [email, setEmail] = useState<string>(environmentSelect({development: 'tester@example.com', default: ''}));
  const [password, setPassword] = useState<string>(environmentSelect({development: '123123', default: ''}));
  const [newTrip, setNewTrip] = useNewTripContext();
  const pickup = first(newTrip?.locations);
  const dropOff = last(newTrip?.locations);

  const [pickupHasBeenFocused, setPickupHasBeenFocused] = useState(false);

  const {validation, setAndCheckValidation} = useValidation<ValidationKey>();

  const stripe = useStripe();
  const elements = useElements();
  // const {value: intent} = usePaymentIntent(newTrip);

  const [paymentIsReady, setPaymentIsReady] = useState(false);

  const {startObserving, isPending: myLocationIsPending, error: myLocationError, value: myLocation} = useGeolocation();

  const searchBounds = useMemo(
    () =>
      myLocation?.coords
        ? boundsForLatLngs([myLocation.coords])
        : dropOff?.coordinates
        ? boundsForLatLngs([dropOff.coordinates])
        : undefined,
    [myLocation?.coords, dropOff?.coordinates],
  );

  useEffect(() => {
    if (myLocationError) {
      setAndCheckValidation<true>({
        pickupLocation: `${geolocationPositionErrorCodeCustomerDescription(
          myLocationError.code as GeolocationPositionErrorCode,
        )}. Type the location here`,
      });
    }
  }, [myLocationError, setAndCheckValidation]);

  const pickupExists = Boolean(pickup);
  useEffect(() => {
    if (pickupExists) {
      setAndCheckValidation<true>(oldValidation => ({
        pickupLocation: exists(oldValidation?.pickupLocation) ? true : undefined,
      }));
    }
  }, [pickupExists, setAndCheckValidation]);

  const {
    isPending: geocodeIsPending,
    error: geocodeError,
    handlePromise: handleGeocodePromise,
  } = useStatus(React as never);

  useEffect(() => {
    if (geocodeError) {
      setAndCheckValidation<true>({
        pickupLocation: 'Unable to determine pickup based on GPS position',
      });
    }
  }, [geocodeError, setAndCheckValidation]);

  const {
    isPending: estimatesArePending,
    error: estimateError,
    value: estimates,
  } = useTripEstimates(React as never, newTrip, environmentVariable('NODE_SERVER_URL'));

  const [nonCouponError, couponError] = useMemo(
    () =>
      estimateError
        ? estimateError instanceof ErrorWithReason &&
          ['INVALID_COUPON_CODE', 'EXPIRED_COUPON'].includes(estimateError.reason)
          ? [undefined, estimateError]
          : [estimateError, undefined]
        : [undefined, undefined],
    [estimateError],
  );

  useEffect(() => {
    setAndCheckValidation<true>(oldValidation => ({
      couponCode: couponError ? couponError.message : oldValidation?.couponCode ? true : undefined,
    }));
  }, [couponError, setAndCheckValidation]);

  const {
    status: bookNowStatus,
    isPending: bookNowIsPending,
    error: bookNowError,
    handlePromise: handleBookNowPromise,
  } = useStatus(React as never);

  const isPending = myLocationIsPending || geocodeIsPending || estimatesArePending || bookNowIsPending;
  const disableForm: boolean = isPending || Boolean(estimateError) || !newTrip?.type;

  // gms directions

  const {value: gmsDirections} = useStatus(
    React as never,
    useDeepComparisonMemo(
      React as never,
      () => unwrap(pickup, p => unwrap(dropOff, d => directionsReadForLocations([p, d]))),
      [pickup, dropOff],
    ),
  );

  // directions

  const directions = useMemo(
    () => unwrap(gmsDirections, it => directionsFromGoogleMapsClientDirectionsResult(it, '', FirestoreDataSource)),
    [gmsDirections],
  );

  // polyline coordinates

  const polylineCoordinates = useMemo(
    () => unwrap(directions, it => flatMap(it.legs, leg => decode(leg.encodedPath)).map(latLng)),
    [directions],
  );

  // set trip initial

  const hasNewTrip = Boolean(newTrip);

  useEffect(() => {
    if (!hasNewTrip) {
      setNewTrip({
        ...emptyTripRideshareNew(
          {
            deviceType: 'WEB',
            source: 'CUSTOMER_PORTAL',
            locations: [undefined, undefined],
          },
          RealtimeDatabaseDataSource.instance,
        ),
      });
    } else {
    }
  }, [hasNewTrip, setNewTrip]);

  // update trip based on promo code

  useEffect(() => {
    setNewTrip(newTrip => {
      newTrip.couponCode = promoInitialValue ?? undefined;
    });
  }, [promoInitialValue, setNewTrip]);

  // update trip based on directions

  useEffect(() => {
    //     status: TripStatus.scheduled,
    if (directions) {
      setNewTrip(newTrip => {
        newTrip.estimatedMiles = round(directions.legs[0].distanceM * Constants.metersPerMile, 2);
        newTrip.estimatedMinutes = round(directions.legs[0].durationS / 60, 2);
      });
    }
  }, [directions, setNewTrip]);

  // effect: set pickup based on location

  useEffect(() => {
    // check for my location

    const coords = myLocation?.coords;
    if (coords && !newTrip?.locations[0]) {
      // mark as attempted

      if (didAttemptGeocodeRef.current) {
        return;
      }
      didAttemptGeocodeRef.current = true;

      // handler

      const handler = async () => {
        // geocode my location

        const geocodeResponse = await new (window as any).google.maps.Geocoder().geocode({
          location: latLngFromCoordinatesObject(coords),
        });
        const geocodeResult: any = expect(first(geocodeResponse?.results), 'reverse geocode result');

        const place: never = await new Promise((resolve, reject) => {
          new (window as any).google.maps.places.PlacesService(document.createElement('div')).getDetails(
            {placeId: geocodeResult.place_id, fields: ['name', 'utc_offset']},
            (result: any, status: string) => {
              if (status === (window as any).google.maps.places.PlacesServiceStatus.OK) {
                resolve(result);
              } else {
                reject(new Error(`Bad response: ${status}`));
              }
            },
          );
        });

        // create trip location from result

        const location = await tripLocationFromPlace(place, coordinatesArrayFromObject(coords));

        // update trip / pickup

        setNewTrip(newTrip => (newTrip.locations[0] = location));
      };

      handleGeocodePromise(handler());
    }
  }, [myLocation, newTrip, setNewTrip, handleGeocodePromise]);

  // effect: select trip type

  useEffect(() => {
    if (!newTrip?.type && estimates) {
      setNewTrip(newTrip => {
        newTrip.type = first(estimates)?.tripType;
      });
    }
  }, [estimates, newTrip?.type, setNewTrip]);

  // callbacks

  // callback: on submit coupon form

  const onCouponCodeReady = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      const code = couponCode.trim() || undefined; // make sure not to use an empty string value

      if (code !== newTrip?.couponCode) {
        setNewTrip(newTrip => {
          newTrip.couponCode = code;
        });
      }
    },
    [couponCode, setNewTrip, newTrip?.couponCode],
  );

  useLogOnChange(React as never, 'estimate', newTrip?.estimate);

  const onClickBookNow = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      // parse

      // const waitTimeHNumber = Number(waitTimeH.trim()) || undefined;
      // const waitTimeSValidated = waitTimeHNumber ? waitTimeHNumber * 60 * 60 : undefined;
      const instructionsValidated = (first(newTrip?.locations)?.instructions || '').trim() || undefined;
      const firstNameValidated = firstName.trim();
      const lastNameValidated = lastName.trim();
      const cellphoneTrimmed = cellphone.trim();
      let cellphoneValidated: string | undefined;
      try {
        cellphoneValidated = formatPhoneE164(cellphoneTrimmed);
      } catch (error) {
        // continue for now
      }
      const emailTrimmed = email.trim();

      const passengerCountValidated: number | undefined = passengerCount?.trim()
        ? Number(passengerCount.trim())
        : undefined;

      // validate

      const pickupMoment = unwrap(newTrip?.locations[0]?.scheduledTimestampMs, moment);
      const shouldContinue = setAndCheckValidation({
        passengerCount:
          couponCode === PANTHERS_PROMO_CODE
            ? (passengerCountValidated && isSafeInteger(passengerCountValidated)) || 'Required'
            : true,
        pickupLocation: Boolean(pickup) || 'Required',
        pickupTimestamp: pickupMoment
          ? pickupMoment.isSameOrAfter(minimumPickupDate)
            ? pickupMoment.isSameOrBefore(maximumPickupDate)
              ? true
              : `Latest pickup allowed is ${moment(maximumPickupDate).calendar()}`
            : `Earliest pickup allowed is ${moment(minimumPickupDate).calendar()}`
          : true,
        pickupInstructions: true,
        dropOffLocation: Boolean(dropOff) || 'Required',
        payment: couponCode === PANTHERS_PROMO_CODE || paymentIsReady || 'Required',
        couponCode: true,
        userFirstName: Boolean(user) || !shouldCreateAccount || Boolean(firstNameValidated) || 'Required',
        userLastName: Boolean(user) || !shouldCreateAccount || Boolean(lastNameValidated) || 'Required',
        userCellphone:
          Boolean(user) ||
          !shouldCreateAccount ||
          Boolean(cellphoneValidated) ||
          (cellphoneTrimmed ? 'Required' : 'Invalid phone number'),
        userEmail: user ? true : emailTrimmed ? true : 'Required',
        userPassword: Boolean(user) || Boolean(password) || 'Required',
        general: true,
      });

      if (!shouldContinue) {
        return;
      }

      // confirm payment

      async function handler() {
        if (!user) {
          if (shouldCreateAccount) {
            // create account

            let user: User; //= 'yEHhidWcuSdT0heQ7gSAxbh8Re02';
            // await auth().signInWithEmailAndPassword('test@example.com', '123123');
            try {
              const result = await auth().createUserWithEmailAndPassword(emailTrimmed, password);
              user = expect(result.user, 'user result user');
            } catch (error: any) {
              switch (error.code) {
                case 'auth/email-already-in-use':
                  setAndCheckValidation<true>({
                    userEmail: 'That email address is already in use',
                  });
                  break;
                case 'auth/invalid-email':
                  setAndCheckValidation<true>({
                    userEmail: 'Invalid email address',
                  });
                  break;
                case 'auth/weak-password':
                  setAndCheckValidation<true>({
                    userPassword: 'That password is too weak. Try adding numbers and special characters',
                  });
                  break;
                default:
                  setAndCheckValidation<true>({
                    general: 'Unable to create user account',
                  });
                  break;
              }
              throw error;
            }

            // create customer

            const customer: BaseUser = {
              firstName: firstNameValidated,
              lastName: lastNameValidated,
              cellphone: cellphoneValidated,
            };
            try {
              await ScoopMApi.instance.customers.create(customer);
            } catch (error) {
              setAndCheckValidation<true>({
                general: 'Unable to complete account creation',
              });
              throw error;
            }

            // phone confirmation

            try {
              await userUpdatePhone(user, cellphoneValidated!, undefined, environmentVariable('NODE_SERVER_URL'));
            } catch (error) {
              setAndCheckValidation<true>({
                userCellphone: 'Unable to confirm phone number',
              });
              throw error;
            }

            await new Promise((resolve, reject) => {
              setAlert(
                new Alert(
                  'Verify your phone number',
                  `We've texted a confirmation code to ${formatPhone(cellphoneValidated!)}`,
                  'Verify',
                  async text => {
                    await userUpdatePhone(
                      user,
                      cellphoneValidated!,
                      text?.trim(),
                      environmentVariable('NODE_SERVER_URL'),
                    );
                    resolve(undefined);
                  },
                  'Cancel',
                  async () => {
                    reject(new Error('User canceled phone verification'));
                    setAndCheckValidation<true>({
                      userCellphone: 'Click "Book now" to confirm phone number',
                    });
                  },
                  undefined,
                  'Confirmation code',
                ),
              );
            });
          } else {
            // sign in

            try {
              await auth().signInWithEmailAndPassword(emailTrimmed, password);
            } catch (error: any) {
              switch (error.code) {
                case 'auth/invalid-email':
                  setAndCheckValidation<true>({
                    userEmail: 'Ivalid email address',
                  });
                  break;
                case 'auth/user-not-found':
                  setAndCheckValidation<true>({
                    userEmail: 'User account / email not found',
                  });
                  break;
                case 'auth/wrong-password':
                  setAndCheckValidation<true>({
                    userPassword: 'Incorrect password',
                  });
                  break;
                default:
                  setAndCheckValidation<true>({
                    general: 'Unable to sign in',
                  });
                  break;
              }
              throw error;
            }
          }
        }

        // create payment method

        let paymentMethodId: string | undefined;
        if (couponCode === PANTHERS_PROMO_CODE) {
          // don't bother with paymetn
        } else {
          try {
            const paymentMethodResult = await expect(stripe, 'stripe').createPaymentMethod({
              type: 'card',
              card: expect(expect(elements, 'elements').getElement(CardElement), 'card element'),
            });
            // weird design pattern from Stripe doesn't just throw the error
            if (paymentMethodResult.error) {
              throw new Error(paymentMethodResult.error.message);
            }
            paymentMethodId = paymentMethodResult.paymentMethod.id;
          } catch (error) {
            setAndCheckValidation<true>({
              general: 'Payment error. Try again later',
            });
            throw error;
          }
        }

        // update trip properties

        const trip = cloneDeep(expect(newTrip, 'newTrip upon submission'));
        trip.locations[0]!.instructions = instructionsValidated;
        trip.locations[0]!.passengerCount = passengerCountValidated;
        // trip.locations[0]!.waitTimeS = waitTimeSValidated;
        // trip.locations[0]!.scheduledTimestampMs = pickupTimestampMs;
        trip.locations[0]!.contactUserType = 'CUSTOMER';
        trip.customerId = expect(auth().currentUser?.uid, 'auth().currentUser?.uid');

        trip.customer = user
          ? baseUserFromUser(await userRead(user.uid, RealtimeDatabaseDataSource.instance))
          : {
              firstName: firstNameValidated,
              lastName: lastNameValidated,
              cellphone: cellphoneValidated,
            };

        const estimate = estimates?.find(estimate => estimate.tripType === trip.type);
        if (estimate?.valueD) {
          const intentResponse = await ScoopMApi.instance.stripe.paymentIntents.create(expect(trip, 'trip'));
          trip.paymentIntentId = expect(intentResponse.data.id, 'intent id');
        }

        // schedule trip

        await ScoopMApi.instance.trips.create(tripDeserializeWithoutId(trip), paymentMethodId);
      }

      // execute handler

      handleBookNowPromise(handler());
    },
    [
      cellphone,
      couponCode,
      dropOff,
      elements,
      email,
      estimates,
      firstName,
      handleBookNowPromise,
      lastName,
      maximumPickupDate,
      minimumPickupDate,
      newTrip,
      passengerCount,
      password,
      paymentIsReady,
      pickup,
      setAlert,
      setAndCheckValidation,
      shouldCreateAccount,
      stripe,
      user,
    ],
  );

  // memos

  // map contents

  const mapContents = useMemo(() => {
    const coordinates = filterBoolean([
      ...(newTrip?.locations.map(location => location?.coordinates) ?? []),
      unwrap(myLocation?.coords, coordinatesArrayFromObject),
    ]);

    if (coordinates?.length) {
      if (coordinates.length === 1) {
        return new MapContents.Coordinates({
          id: 'location',
          latLng: latLng(coordinates[0]),
          zoom: 14,
          name: null,
        });
      } else {
        return new MapContents.Bounds({
          id: 'locations',
          latLngBounds: boundsForLatLngs(coordinates),
          name: null,
        });
      }
    }
  }, [newTrip?.locations, myLocation?.coords]);

  // render

  // redirect if no drop-off set

  // if (!dropOff) {
  //   return <Redirect to="/" />;
  // }

  // if success

  if (bookNowStatus.type === 'success') {
    return <Redirect to="/jobs" />;
  }

  // main form

  const shouldShowShareLocationButton: boolean = !myLocation && !myLocationError && !newTrip?.locations[0];

  return (
    <div className="container">
      {/* back button */}

      <Link to="/" className="text-gray-500">
        <FontAwesome.ArrowLeftSolid /> Back
      </Link>

      {userType?.rawValue === 'DRIVER' ? (
        <ContainerLayout>
          <h3>You're signed in with a driver account</h3>
          <p className="lead">
            We are currently unable to provide rides for driver accounts. Simply sign out, and use a different account.
            Thanks!
          </p>
          <button
            type="button"
            onClick={() => auth().signOut().catch(console.error)}
            className="btn btn-outline-primary">
            Sign out
          </button>
        </ContainerLayout>
      ) : (
        <>
          {/* title */}

          <h2 className="mb-4">Ride now</h2>

          <div className="row mx-n4">
            {/* map column */}

            <div className="col-md px-4 order-md-last">
              <div style={{height: screenIsSmall ? '20rem' : '36rem'}} className="mb-3 sticky-top pt-3 mt-n3">
                <GoogleMap
                  mapContents={mapContents}
                  fullscreenControl={false}
                  streetViewControl={false}
                  mapTypeControl={false}
                  zoomControl={false}
                  rounded>
                  {/* polyline */}

                  <Polyline
                    path={polylineCoordinates}
                    options={{
                      strokeOpacity: 1.0,
                      strokeColor: Constants.theme.primaryColorHexString,
                      strokeWeight: 3,
                    }}
                  />

                  {/* trip markers */}

                  {newTrip?.locations.map((location, index, locations) =>
                    unwrap(location, location => (
                      <Marker
                        key={index}
                        position={latLng(location.coordinates)}
                        label={labelWhite(locationDescriptionSimple(location))}
                        // label={labelWhite(index === 0 ? 'Adjust pickup' : locationDescriptionSimple(location))}
                        icon={index === locations.length - 1 ? iconMapPin3FillBorderPink() : iconMapPin3FillBorder()}
                        // draggable={index === 0}
                      />
                    )),
                  )}

                  {/* my location marker */}

                  {/* {myLocation && (
                <Marker
                  position={latLngFromCoordinatesObject(myLocation.coords)}
                  label={labelWhite('My location')}
                  icon={iconMapPin3FillBorderGray()}
                />
              )} */}
                </GoogleMap>
              </div>
            </div>

            {/* form column */}

            <div className="col-md px-4">
              {/* pickup */}

              <div className="form-group">
                <h6>
                  <MapPin3FillBlue /> Pickup
                </h6>
                <div className={joinClassNames('input-group', validation && 'has-validation')}>
                  {shouldShowShareLocationButton && (
                    <div className="input-group-prepend">
                      <button
                        type="button"
                        onClick={event => {
                          event.preventDefault(); // stops selection from occuring and passing to the next available button when this one disappears
                          startObserving();
                        }}
                        className="btn btn-primary"
                        disabled={myLocationIsPending}>
                        {myLocationIsPending ? (
                          'Getting location...'
                        ) : (
                          <>
                            <FontAwesomeV5 name="crosshairs" />
                            <span className={joinClassNames(pickupHasBeenFocused) && 'd-none d-md-inline'}>
                              {' '}
                              Share location
                            </span>
                          </>
                        )}
                      </button>
                    </div>
                  )}
                  <Autocomplete
                    bounds={searchBounds}
                    onChange={location => setNewTrip(newTrip => (newTrip.locations[0] = location))}>
                    <ValidateableInput
                      elementType={Input}
                      onFocus={() => {
                        setPickupHasBeenFocused(true);
                      }}
                      defaultValue={unwrap(pickup, locationDescription) ?? ''}
                      placeholder="Search addresses, venues, airports..."
                      className="form-control form-control-lg"
                      validation={validation?.pickupLocation}
                    />
                  </Autocomplete>
                </div>
                {shouldShowShareLocationButton ? (
                  <PrivacyNotice>Wave cares about your privacy. Location data is secure</PrivacyNotice>
                ) : (
                  <>
                    {/* schedule button */}

                    {newTrip?.locations[0]?.scheduledTimestampMs ? (
                      <div className="mt-3 bg-light p-3 rounded-xl">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <label htmlFor="datePicker" className="mb-0">
                            <FontAwesome.ClockSolid /> Scheduled at
                          </label>
                          <button
                            type="button"
                            onClick={() => {
                              setNewTrip(newTrip => (newTrip.locations[0]!.scheduledTimestampMs = undefined));
                            }}
                            className="btn btn-sm btn-link text-danger">
                            <FontAwesomeV5 name="trash" /> Remove time
                          </button>
                        </div>
                        {unwrap(newTrip!.locations[0]!.scheduledTimestampMs, timestamp => {
                          const date = new Date(timestamp as number);
                          const theMoment = moment(date);
                          return (
                            <DatePicker
                              id="datePicker"
                              selected={date}
                              onChange={date => {
                                if (date instanceof Date) {
                                  setNewTrip(newTrip => (newTrip.locations[0]!.scheduledTimestampMs = date.valueOf()));
                                }
                              }}
                              minDate={minimumPickupDate}
                              minTime={
                                theMoment.isSame(minimumPickupDate, 'day')
                                  ? minimumPickupDate
                                  : theMoment.startOf('day').toDate()
                              }
                              maxDate={maximumPickupDate}
                              maxTime={
                                theMoment.isSame(maximumPickupDate, 'day')
                                  ? maximumPickupDate
                                  : theMoment.endOf('day').toDate()
                              }
                              dateFormat="Pp"
                              showTimeSelect
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          setNewTrip(
                            newTrip => (newTrip.locations[0]!.scheduledTimestampMs = minimumPickupDate.valueOf()),
                          );
                        }}
                        className="btn btn-sm btn-link text-primary mt-1 mr-1">
                        <FontAwesome.ClockSolid /> Schedule for later
                      </button>
                    )}

                    {/* instructions */}

                    {typeof newTrip?.locations[0]?.instructions === 'string' ? (
                      <div className="mt-3 bg-light p-3 rounded-xl">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <label htmlFor="instructionsInput" className="mb-0">
                            <FontAwesomeV5 name="note-sticky" /> Instructions
                          </label>
                          <button
                            type="button"
                            onClick={() => {
                              setNewTrip(newTrip => (newTrip.locations[0]!.instructions = undefined));
                            }}
                            className="btn btn-sm btn-link text-danger">
                            <FontAwesomeV5 name="trash" /> Remove instructions
                          </button>
                        </div>
                        <textarea
                          id="instructionsInput"
                          placeholder="Any special instructions?"
                          value={newTrip?.locations[0]?.instructions}
                          onChange={event => {
                            setNewTrip(newTrip => (newTrip.locations[0]!.instructions = event.target.value));
                          }}
                          className="form-control"
                        />
                      </div>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          setNewTrip(newTrip => (newTrip.locations[0]!.instructions = ''));
                        }}
                        className="btn btn-sm btn-link text-primary mt-1 mr-1">
                        <FontAwesomeV5 name="note-sticky" /> Add instructions
                      </button>
                    )}
                  </>
                )}
                {/* party count */}
                {couponCode === PANTHERS_PROMO_CODE && (
                  <div className="form-group mt-2">
                    <h6>
                      <FontAwesomeV5 name="people-group" /> Party count
                    </h6>
                    <ValidateableInput
                      elementType={Input}
                      type="number"
                      value={passengerCount ?? ''}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setPassengerCount(event.target.value)}
                      placeholder="How many passengers?"
                      className="form-control form-control-lg"
                      validation={validation?.passengerCount}
                    />
                  </div>
                )}
              </div>

              {/* separator (if needed) */}

              {(newTrip?.locations[0]?.scheduledTimestampMs ||
                typeof newTrip?.locations[0]?.instructions === 'string') && <hr className="my-5" />}

              {/* drop-off */}

              <div className={joinClassNames('form-group', !pickup && !dropOff && 'blur')}>
                <h6>
                  <MapPin3FillPink /> Drop-off
                </h6>
                <Autocomplete
                  bounds={searchBounds}
                  onChange={location => setNewTrip(newTrip => (newTrip.locations[1] = location))}>
                  <ValidateableInput
                    elementType={Input}
                    defaultValue={unwrap(dropOff, locationDescription) ?? ''}
                    placeholder="Search addresses, venues, airports..."
                    className="form-control form-control-lg"
                    validation={validation?.dropOffLocation}
                  />
                </Autocomplete>
              </div>

              <hr className={joinClassNames('my-5', (isPending || !estimates) && 'blur')} />

              {/* estimates */}

              <div className={joinClassNames('form-group', (isPending || (!estimates && !estimateError)) && 'blur')}>
                <h6>Select a service</h6>
                <Join react={React as never} separator={SeparatorSm}>
                  {isPending
                    ? times(4).map(i => <EstimateCard key={i} isLoading />)
                    : nonCouponError
                    ? [
                        <div className="alert alert-warning">
                          <FontAwesome.InfoCircle />{' '}
                          {(nonCouponError as ErrorWithReason)?.reason
                            ? nonCouponError.message
                            : 'No services available at this time'}
                        </div>,
                      ]
                    : estimates
                    ? estimates.map(estimate => (
                        <EstimateCard
                          key={estimate.tripType}
                          estimate={estimate}
                          isSelected={estimate.tripType === newTrip?.type}
                          onClick={() => {
                            setNewTrip(newTrip => {
                              newTrip.type = estimate.tripType;
                            });
                          }}
                        />
                      ))
                    : times(4).map(i => <EstimateCard key={i} />)}
                </Join>
              </div>

              {/* <hr  className="my-5"/> */}

              {/* promo code */}

              <div className={joinClassNames('form-group', (isPending || !newTrip?.type) && 'blur')}>
                <h6>Promo code</h6>
                <form onSubmit={onCouponCodeReady} onBlur={onCouponCodeReady}>
                  <ValidateableInput elementType="div" validation={validation?.couponCode} className="input-group">
                    <input
                      type="text"
                      // placeholder="Enter promo code"
                      className="form-control form-control-lg"
                      value={couponCode}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setCouponCode(event.target.value.toUpperCase())
                      }
                      disabled={isPending || !newTrip?.type}
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-secondary"
                        disabled={isPending || !newTrip?.type || couponCode === newTrip.couponCode}>
                        Apply
                      </button>
                    </div>
                  </ValidateableInput>
                </form>
              </div>

              {/* <hr  className="my-5"/> */}

              {/* payment */}

              <div className={joinClassNames('form-group', disableForm && 'blur')}>
                <h6>Pay with card</h6>
                <CardElement
                  onChange={event => setPaymentIsReady(event.complete)}
                  options={{
                    style: {
                      base: {
                        color: '#172433',
                        fontFamily: 'Roboto, sans-serif',
                        fontSmoothing: 'antialiased',
                        fontSize: '20px',
                        '::placeholder': {
                          color: '#6c757d', // color: "#172433"
                        },
                      },
                      invalid: {
                        fontFamily: 'Roboto, sans-serif',
                        color: '#FF6B6B',
                        iconColor: '#FF6B6B',
                      },
                    },
                    disabled: disableForm,
                  }}
                  className={joinClassNames(
                    'p-card-element border rounded rounded-lg',
                    disableForm && 'bg-gray-200',
                    unwrap(
                      validation?.payment,
                      v => (v === true ? 'border-success' : 'border-danger is-invalid'),
                      () => 'border-gray-400',
                    ),
                  )}
                />
                {typeof validation?.payment === 'string' && (
                  <span className="invalid-feedback">{validation.payment}</span>
                )}
              </div>

              <hr className="my-5" />

              {/* account section */}

              {shouldShowUserFields && (
                <div className="form-group">
                  {/* title */}

                  <h6>{shouldCreateAccount ? 'Create account' : 'Sign in'}</h6>

                  {/* sign in / create account */}

                  <div className="d-flex align-items-center mb-3">
                    {shouldCreateAccount ? 'Already have an account?' : "Don't have an acccount?"}
                    &nbsp;
                    <button
                      type="button"
                      onClick={() => setShouldCreateAccount(!shouldCreateAccount)}
                      className={joinClassNames(
                        'btn btn-sm btn-outline-secondary',
                        (disableForm || Boolean(user)) && 'blur',
                      )}
                      disabled={disableForm || Boolean(user)}>
                      {shouldCreateAccount ? 'Sign in' : 'Create account'}
                    </button>
                  </div>

                  {/* first name */}

                  {shouldCreateAccount && (
                    <div className="mb-3">
                      <ValidateableInput
                        elementType={Input}
                        type="text"
                        placeholder="First name"
                        autoComplete="given-name"
                        className={joinClassNames(
                          'form-control form-control-lg',
                          (disableForm || Boolean(user)) && 'blur',
                        )}
                        value={firstName}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value)}
                        validation={unwrap(validation, v => v.userFirstName)}
                        disabled={disableForm || Boolean(user)}
                      />
                    </div>
                  )}

                  {/* last name */}

                  {shouldCreateAccount && (
                    <div className="mb-3">
                      <ValidateableInput
                        elementType={Input}
                        type="text"
                        placeholder="Last name"
                        autoComplete="family-name"
                        className={joinClassNames(
                          'form-control form-control-lg',
                          (disableForm || Boolean(user)) && 'blur',
                        )}
                        value={lastName}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setLastName(event.target.value)}
                        validation={unwrap(validation, v => v.userLastName)}
                        disabled={disableForm || Boolean(user)}
                      />
                    </div>
                  )}

                  {/* email */}

                  <div className="mb-3">
                    <ValidateableInput
                      elementType={Input}
                      type="email"
                      placeholder="Email address"
                      autoComplete="email"
                      className={joinClassNames(
                        'form-control form-control-lg',
                        (disableForm || Boolean(user)) && 'blur',
                      )}
                      value={email}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                      validation={unwrap(validation, v => v.userEmail)}
                      disabled={disableForm || Boolean(user)}
                    />
                  </div>

                  {/* password */}

                  <div className="mb-3">
                    <ValidateableInput
                      elementType={Input}
                      type="password"
                      placeholder="Password"
                      autoComplete={shouldCreateAccount ? 'new-password' : 'current-password'}
                      className={joinClassNames(
                        'form-control form-control-lg',
                        (disableForm || Boolean(user)) && 'blur',
                      )}
                      value={password}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                      validation={unwrap(validation, v => v.userPassword)}
                      disabled={disableForm || Boolean(user)}
                    />
                  </div>

                  {/* mobile phone */}

                  {shouldCreateAccount && (
                    <div>
                      <ValidateableInput
                        elementType={Input}
                        type="text"
                        placeholder="Mobile phone number"
                        autoComplete="tel"
                        className={joinClassNames('form-control form-control-lg', disableForm && 'blur')}
                        value={cellphone}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setCellphone(event.target.value)}
                        validation={unwrap(validation, v => v.userCellphone)}
                        disabled={disableForm}
                      />
                      <PrivacyNotice>
                        Your number will be masked from the driver. You will also receive text message updates during
                        the trip
                      </PrivacyNotice>
                    </div>
                  )}
                </div>
              )}

              {/* book now */}

              <div className="mb-5 d-flex flex-column">
                {/* book now button */}

                <button
                  className={joinClassNames('btn btn-lg btn-primary mb-2 rounded-xl', disableForm && 'blur')}
                  disabled={disableForm}
                  onClick={onClickBookNow}>
                  Book now
                </button>

                {(typeof validation?.general === 'string' || bookNowError) && (
                  <div className="alert alert-danger mt-3">
                    <FontAwesome.InfoCircle />{' '}
                    {typeof validation?.general === 'string' ? validation.general : 'Unable to book ride'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default withStripe(RideForm);
