import round from 'lodash/round';
import React, {useCallback, useEffect, useMemo} from 'react';
import {CoordinatesArray, expect} from 'wave-common';
import {GeoProperties} from '.';
import GoogleMap from '../../../../components/GoogleMaps/GoogleMap';
import {latLng} from '../../../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../../../components/GoogleMaps/MapContents';
import Constants from '../../../../Constants';
import unwrap from '../../../../functions/unwrap';
import {useDeepComparisonMemo} from '../../../../hooks/useDeepComparisonEffect';
import useDriversMarkers from '../../../../hooks/useDriversMarkers';
import {Item} from '../../../../hooks/useGeoFireQuery';
import useTripDirections from '../../../../hooks/useTripDirections';
import useTripPaths from '../../../../hooks/useTripPaths';
import useTripsMarkers from '../../../../hooks/useTripsMarkers';
import Driver from '../../../../models/scoopm/Driver';
import Trip from '../../../../models/scoopm/Trip';
import TripStatus from '../../../../models/scoopm/TripStatus';

export default function Map(props: {
  items?: Item<Driver>[];
  trip: Trip;
  selectedDriverId?: string;
  setSelectedDriverId: (id?: string) => void;
  geoProperties?: GeoProperties;
  setGeoProperties: (properties: GeoProperties) => void;
  onChangeDistancesMi: (distances: number[]) => void;
  showNearbyDrivers: boolean;
}) {
  const {setSelectedDriverId, setGeoProperties, onChangeDistancesMi} = props;

  // trip state

  const tripEntries = useMemo(() => unwrap(props.trip, trip => [[trip.id, trip]]), [props.trip]);
  const tripMarkers = useTripsMarkers({tripEntries} as any);
  const directions = useTripDirections(props.trip?.id);
  const {paths, distancesMi, bounds: tripBounds} = useTripPaths({trip: props.trip, directions});

  // driver state

  const selectedDriverItem: Item<Driver> | undefined = useMemo(
    () =>
      unwrap(props.selectedDriverId, id => unwrap(props.items, items => items.find(item => item.key === id))) ??
      undefined,
    [props.selectedDriverId, props.items],
  );
  const tripDriverItem: Item<Driver> | undefined = useMemo(
    () =>
      unwrap(props.trip.driverId, id => unwrap(props.items, items => items.find(item => item.key === id))) ?? undefined,
    [props.trip, props.items],
  );
  const onClickDriverMarker = useCallback(
    id => {
      setSelectedDriverId(props.selectedDriverId === id ? undefined : id);
    },
    [props.selectedDriverId, setSelectedDriverId],
  );
  const driverEntries = useMemo(
    () =>
      unwrap(props.items, items => {
        let itemsToShow: Item<Driver>[] = [];
        if (props.showNearbyDrivers) {
          itemsToShow = items;
        } else if (selectedDriverItem ?? tripDriverItem) {
          itemsToShow = [selectedDriverItem ?? tripDriverItem!];
        }
        return itemsToShow.map(item => [
          item.key,
          Object.assign(expect(item.value), {coordinate: item.coordinatesArray}), // create entries and copy coordinates
        ]);
      }),
    [props.items, props.showNearbyDrivers, selectedDriverItem, tripDriverItem],
  );
  const modifyMarker = useCallback(
    marker => {
      if (marker.id === props.selectedDriverId) marker.icon.fillColor = Constants.theme.primaryColorHexString;
      return marker;
    },
    [props.selectedDriverId],
  );

  const driverMarkers = useDriversMarkers({
    driverEntries,
    onClick: onClickDriverMarker,
    modifyMarker,
  }) as any[] | null;

  const nearestDriverItems = useMemo(() => {
    // map contents -> extend by 5 closest drivers
    const first5 = props.items?.slice(0, 4);
    if (!first5?.length) return undefined;
    return first5;
  }, [props.items]);

  // combined state

  const markers = useMemo(() => [...(driverMarkers ?? []), ...(tripMarkers ?? [])], [tripMarkers, driverMarkers]);
  const searchBoundsCircles = useDeepComparisonMemo(
    () =>
      unwrap(props.geoProperties?.radiusKm, radiusKm =>
        unwrap(props.geoProperties?.centerCoordinatesArray, coordinates => [
          {
            center: latLng(coordinates),
            radiusM: radiusKm * 1000,
            fillOpacity: 0,
          },
        ]),
      ),
    [props.geoProperties],
  );
  const mapContents = useDeepComparisonMemo(
    () =>
      unwrap(tripBounds, tripBounds => {
        const driverItemsToInclude = selectedDriverItem
          ? [selectedDriverItem]
          : tripDriverItem && [TripStatus.accepted, TripStatus.started].includes(props.trip.statusType() as any)
          ? [tripDriverItem]
          : props.showNearbyDrivers
          ? nearestDriverItems
          : undefined;
        const indexOfLocationToUse = selectedDriverItem
          ? props.trip.startedAt
            ? props.trip.locationsWithDefault()?.length - 1 ?? 0
            : 0
          : undefined;
        return MapContents.Bounds.forTripBoundsAndDrivers(
          props.trip.id,
          props.trip,
          tripBounds,
          driverItemsToInclude,
          indexOfLocationToUse,
        );
      }),
    [props.trip, tripBounds, selectedDriverItem, tripDriverItem, nearestDriverItems, props.showNearbyDrivers],
  );

  // methods

  const onBoundsChange = useCallback(
    bounds => {
      const centerLatLng = bounds.getCenter();
      const centerArray: CoordinatesArray = [centerLatLng.lat(), centerLatLng.lng()];
      const northEastLatLng = bounds.getNorthEast();
      const diagonalKm =
        (window as any).google.maps.geometry.spherical.computeDistanceBetween(centerLatLng, northEastLatLng) / 1000;
      let radiusKm = Math.max(
        Math.min(Constants.maxDriverSearchRadiusKm, diagonalKm),
        Constants.minDriverSearchRadiusKm,
      );
      radiusKm = round(radiusKm, 2);
      const centerCoordinatesArray = centerArray.map(coordinate => round(coordinate, 8)) as CoordinatesArray;
      setGeoProperties({radiusKm, centerCoordinatesArray});
    },
    [setGeoProperties],
  );

  // effects

  useEffect(() => {
    onChangeDistancesMi(distancesMi);
  }, [distancesMi, onChangeDistancesMi]);

  // render

  return (
    <GoogleMap
      markers={markers}
      onBoundsChange={onBoundsChange}
      circles={searchBoundsCircles}
      paths={paths}
      mapContents={mapContents}
    />
  );
}
