import $ from 'jquery';
import React, {useCallback, useEffect} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import FirebaseStorageCircleImage from '../../../components/FirebaseStorageCircleImage';
import FontAwesome from '../../../components/FontAwesome';
import {formatPhone} from '../../../functions/formatPhone';
import unwrap from '../../../functions/unwrap';
import Driver from '../../../models/scoopm/Driver';
import UserType from '../../../models/scoopm/UserType';

export default function DriverRow({
  style,
  index,
  data,
  selectedDriverId,
  onChangeSelectedDriverId,
  driverIdForQuery,
  onChangeDriverIdForQuery,
}) {
  const [driverId, driver] = data[index];

  const selectDriver = useCallback(
    event => {
      onChangeSelectedDriverId(driverId === selectedDriverId ? null : driverId);
    },
    [onChangeSelectedDriverId, driverId, selectedDriverId],
  );

  const onClickSearchDriverButton = useCallback(
    event => {
      $('.search-driver-button').tooltip('hide'); // hide tooltip
      if (driverIdForQuery === driverId) {
        // if already selected, unselect
        onChangeDriverIdForQuery(null);
      } else {
        onChangeDriverIdForQuery(driverId);
      }
    },
    [driverIdForQuery, onChangeDriverIdForQuery, driverId],
  );

  // refresh tooltips so content can change

  useEffect(() => {
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 100);
  }, [driverIdForQuery]);

  return (
    <div
      style={style}
      className={[
        'd-flex flex-row align-items-stretch border-bottom',
        driverId === selectedDriverId ? 'bg-gray-200' : '',
      ].join(' ')}>
      {/* profile image */}

      <div
        className={'p-2 d-flex align-items-center' + (driver.coordinate ? ' cursor-pointer' : '')}
        onClick={driver.coordinate && selectDriver}>
        <OverlayTrigger
          placement="top-start"
          overlay={
            <Tooltip>
              {driver.firstName} is {driver.inPickupModeEnabled ? <strong>active</strong> : 'not active'}
            </Tooltip>
          }>
          <FirebaseStorageCircleImage
            path={`/profiles/${driverId}`}
            fallbackUrl={UserType.driver.imgSrc}
            diameterRem={2.5}
            style={driver.inPickupModeEnabled ? {border: '3px solid #fad63c'} : null}
          />
        </OverlayTrigger>
      </div>

      <div
        onClick={driver.coordinate && selectDriver}
        className={
          'flex-grow-1 py-1 d-flex flex-column justify-items-center' + (driver.coordinate ? ' cursor-pointer' : '')
        }
        style={{maxWidth: '196px'}}>
        {/* name, location icon */}

        <div className="ellipsis d-flex align-items-center">
          {Driver.prototype.nickNameWithLastName.apply(driver)}
          {/* {driver.coordinate && <FontAwesome.LocationArrowSolid className="text-primary fa-xs ml-1" />} */}
        </div>

        {/* phone */}

        <div>{unwrap(Driver.prototype.safePhoneNumber.apply(driver), formatPhone)}</div>

        {/* job count */}

        <div className="d-flex align-items-center">
          {driver.tripCount > 0 && (
            <div
              className="d-inline-block bg-primary mr-1"
              style={{
                width: '0.5rem',
                height: '0.5rem',
                borderRadius: '0.25rem',
              }}
            />
          )}
          <small>
            {driver.tripCount > 0 ? `${driver.tripCount} ${driver.tripCount === 1 ? 'job' : 'jobs'}` : null}
          </small>
        </div>
      </div>

      <div className="p-2 d-flex align-items-center">
        <button
          type="button"
          className={
            'search-driver-button btn btn-sm' + (driverId === driverIdForQuery ? ' btn-primary' : ' text-muted')
          }
          data-toggle="tooltip"
          title={`${driverId === driverIdForQuery ? 'Un-select' : 'Show'} ${driver.firstName}'s jobs`}
          onClick={onClickSearchDriverButton}>
          <i className="fas fa-list" />
        </button>
      </div>
    </div>
  );
}
