import {CoordinatesArray} from 'wave-common';
import {latLng} from '../../components/GoogleMaps/LatLng+additions';
import unwrap from '../../functions/unwrap';

export default class Location {
  address?: string | null;
  coordinates: CoordinatesArray;
  googlePlaceId?: string | null;
  name?: string | null;
  geohash?: string | null;
  state?: string | null;

  constructor({address, coordinates, googlePlaceId, name, geohash, state}: Location) {
    this.address = address || null;
    this.coordinates = coordinates;
    this.googlePlaceId = googlePlaceId || null;
    this.name = name || null;
    this.geohash = geohash || null;
    this.state = state || null;
  }

  coordinatesLatLng() {
    return latLng(this.coordinates);
  }

  isValid() {
    return (
      unwrap(this.address, address => typeof address === 'string' && Boolean(address), true) &&
      Array.isArray(this.coordinates) &&
      this.coordinates.length === 2 &&
      unwrap(this.googlePlaceId, id => typeof id === 'string' && Boolean(id), true) &&
      unwrap(this.name, name => typeof name === 'string' && Boolean(name), true) &&
      unwrap(this.geohash, hash => typeof hash === 'string' && Boolean(hash), true)
    );
  }

  simplestTitle() {
    return this.name || this.address || 'GPS Coordinates';
  }

  title() {
    return unwrap(
      this.name,
      name =>
        unwrap(
          this.address,
          address => {
            if (address.startsWith(name)) return address;
            else return `${name}, ${address}`;
          },
          name,
        ),
      this.address,
    );
  }
}
