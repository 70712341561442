import {dmflog} from './dmflog';

/**
 * Check if the prop has been provided by the containing component
 * @param {String} name Name of prop
 * @param {React.Component} component Component
 */
export function checkForProp(name, component, optional) {
  let missing = Object.keys(component.props).indexOf(name) < 0;
  if (missing) {
    if (!optional) {
      throw new Error(`${component.constructor.name} requires the "${name}" prop.`);
    } else {
      // optional
      dmflog(component, `The optional prop "${name}" was not set.`);
    }
  }
}
