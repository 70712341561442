import React, {useState} from 'react';
import joinClassNames from '../functions/joinClassNames';
import Delay from './Delay';
import FontAwesome from './FontAwesome';
import Spinner from './Spinner';

export default function SaveableInput<R>(props: {
  initialValue: string;
  save: (value: string) => Promise<R>;
  onComplete: () => void;
}) {
  const [value, setValue] = useState(props.initialValue);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isPending, setIsPending] = useState(false);

  function submit(event: React.FormEvent) {
    event.preventDefault();

    setIsPending(true);
    props.save(value).then(
      () => {
        props.onComplete();
      },
      error => {
        setIsPending(false);
        setErrorMessage(error.message);
      },
    );
  }

  const saveContent = (
    <>
      <FontAwesome.SaveSolid /> Save
    </>
  );

  return (
    <form onSubmit={submit} className="row no-gutters align-items-center">
      <div className="col">
        <input
          type="text"
          value={value}
          onChange={event => setValue(event.target.value)}
          className={joinClassNames('form-control form-control-sm fle-grow-1', errorMessage ? 'is-invalid' : null)}
          disabled={isPending}
        />
        {errorMessage && <small className="invalid-feedback">{errorMessage}</small>}
      </div>
      <div className="col-auto">
        <button type="submit" className="btn btn-sm btn-light ml-1" disabled={isPending}>
          {isPending ? (
            <Delay childrenBeforeTimeout={saveContent}>
              <Spinner small />
            </Delay>
          ) : (
            saveContent
          )}
        </button>
      </div>
      <div className="col-auto">
        <button type="button" onClick={event => props.onComplete()} className="btn btn-sm btn-light ml-1">
          <FontAwesome.Times /> Cancel
        </button>
      </div>
    </form>
  );
}
