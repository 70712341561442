import logPromise from '../../functions/logPromise';
import HttpReference from '../platform/HttpReference';
import Drivers from './Drivers';

export default class Applicant extends HttpReference<any, void, Drivers> {
  create(token: string, trimId: string, color: string, licensePlate: string) {
    return logPromise(
      'Create driver/applicant',
      this.request({
        method: 'POST',
        data: {
          token,
          trimId,
          color,
          licensePlate,
        },
      }),
    );
  }
}
