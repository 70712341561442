import sortBy from 'lodash/sortBy';

const {default: Enum} = require('../../../models/base/Enum');

export default class TripsFilter extends Enum {
  name() {
    switch (this) {
      case TripsFilter.open:
        return 'open';
      case TripsFilter.dispatched:
        return 'dispatched';
      case TripsFilter.underway:
        return 'underway';
      case TripsFilter.atRisk:
        return 'at risk';
      default:
        return '';
    }
  }

  order() {
    switch (this) {
      case TripsFilter.open:
        return 0;
      case TripsFilter.dispatched:
        return 1;
      case TripsFilter.underway:
        return 2;
      case TripsFilter.atRisk:
        return 3;
      default:
        return 4;
    }
  }
}

TripsFilter.open = new TripsFilter('OPEN');
TripsFilter.dispatched = new TripsFilter('DISPATCHED');
TripsFilter.underway = new TripsFilter('UNDERWAY');
TripsFilter.atRisk = new TripsFilter('AT_RISK');

TripsFilter.inOrder = () => sortBy(TripsFilter.allCases, status => status.order());
