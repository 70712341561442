"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function expect(value, label) {
    if (value === null)
        throw new Error(`${label !== null && label !== void 0 ? label : 'Value'} is null`);
    if (value === undefined)
        throw new Error(`${label !== null && label !== void 0 ? label : 'Value'} is undefined`);
    return value;
}
exports.default = expect;
