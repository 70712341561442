import React from 'react';
import {CellOfHeading as CellOfHeadingType} from './Cell';
import CellProps from './CellProps';

export default function CellOfHeading({cell, style}: CellProps<CellOfHeadingType>) {
  return (
    <div style={style} onMouseEnter={cell.onMouseEnter} className="d-flex align-items-end">
      <h5 className="mb-2">
        {cell.groupUserFacingValue} <span className="font-weight-normal">({cell.count})</span>
      </h5>
    </div>
  );
}
