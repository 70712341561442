import {capitalize} from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, {useMemo, useRef, useState} from 'react';
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker';
import {VariableSizeGrid} from 'react-window';
import {objectPick} from 'wave-common/lib/helpers/Object';
import {TripOfNumber} from 'wave-common/lib/models/Trip';
import Description from 'wave-common/lib/type-descriptions/Description';
import PropertyDescription, {AnyPropertyDescription} from 'wave-common/lib/type-descriptions/PropertyDescription';
import {tripDescription} from 'wave-common/lib/type-descriptions/wave/Trip';
import Delay from '../../../../components/Delay';
import {FontAwesomeV5} from '../../../../components/FontAwesome';
import Spinner, {DelaySpinner} from '../../../../components/Spinner';
import Constants from '../../../../Constants';
import joinClassNames from '../../../../functions/joinClassNames';
import {dateNow} from '../../../../helpers/Date';
import useElementSize from '../../../../hooks/useElementSize';
import useListenerStatus from '../../../../hooks/useListenerStatus';
import CenteredLayout from '../../../../layouts/CenteredLayout';
import DuplicateTripLink from '../../rides/DuplicateTripLink';
import {cellWidth} from './Cell';
import CellSwitch from './CellSwitch';
import subscribeForDate from './subscribeForDate';
import TripDetails from './TripDetails';
import useCells from './useCells';

export type DescriptionForGrouping<T = any> = Pick<
  Description<T> | PropertyDescription<T, TripOfNumber>,
  'userFacingTitle' | 'userFacingValue'
> & {
  key: keyof TripOfNumber;
};

export type DescriptionForColumn<T = any> = AnyPropertyDescription<T, TripOfNumber, boolean> & {
  key: keyof TripOfNumber;
};

export const MINIMIZED_GROUP_LENGTH = 3;

const DESCRIPTIONS_FOR_GROUPING = Object.entries({
  ...objectPick(tripDescription.properties, 'driverId', 'source', 'status', 'type', 'vendorId'),
  ...objectPick(tripDescription.derivedProperties as any, 'region', 'primaryAndSecondaryStatus'),
}).map<DescriptionForGrouping>(([key, {userFacingValue, ...value}]) => ({
  key: key as keyof TripOfNumber,
  userFacingValue: userFacingValue as (value: any) => string | Promise<string>,
  ...value,
}));

const ALL_DESCRIPTIONS_FOR_COLUMNS = Object.entries({
  ...objectPick(tripDescription.properties, 'status'),
  ...objectPick(
    tripDescription.derivedProperties as any,
    'title',
    'primaryAndSecondaryStatus',
    'date',
    'times',
    'driverPhone',
  ),
}).map<DescriptionForColumn>(([key, description]) => ({key: key as keyof TripOfNumber, ...description}));

const INITIAL_DESCRIPTIONS_FOR_COLUMNS = [
  ALL_DESCRIPTIONS_FOR_COLUMNS[0],
  ALL_DESCRIPTIONS_FOR_COLUMNS[1],
  ALL_DESCRIPTIONS_FOR_COLUMNS[3],
  ALL_DESCRIPTIONS_FOR_COLUMNS[4],
];

export default function UniversalView() {
  // element sizing

  const gridElementRef = useRef<HTMLElement>();
  const divSize = useElementSize(gridElementRef);

  // date

  const [date, setDate] = useState(dateNow);

  // grouping

  const [descriptionForGrouping, setDescriptionForGrouping] = useState<DescriptionForGrouping>(
    DESCRIPTIONS_FOR_GROUPING[3],
  );

  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});

  // columns

  const [selectedDescriptionsForColumns, setSelectedDescriptionsForColumns] = useState<DescriptionForColumn[]>(
    INITIAL_DESCRIPTIONS_FOR_COLUMNS,
  );

  // data

  const subscribe = useMemo(() => subscribeForDate(date), [date]);
  const {result: trips, error: tripsError, isLoading: tripsAreLoading} = useListenerStatus(subscribe);

  // hovered/selected model

  const [highlightedTrip, setHighlightedTrip] = useState<TripOfNumber>();
  const [selectedTrip, setSelectedTrip] = useState<TripOfNumber>();

  // rows

  const {
    isLoading: cellsAreLoading,
    error: cellsError,
    value: cellRows,
    selectedTrips,
  } = useCells({
    descriptionForGrouping,
    expandedGroups,
    setExpandedGroups,
    trips,
    selectedDescriptionsForColumns,
    setHighlightedTrip,
    setSelectedTrip,
    highlightedTrip,
    selectedTrip,
  });

  // useLogOnChange(React as never, 'cells', cellRows);
  // console.log(cellRows);
  // console.log(selectedTrips);

  // render

  const error = tripsError ?? cellsError;

  const isLoading = tripsAreLoading || cellsAreLoading;

  const gridHasHorizonalScroll = useMemo(
    () => cellRows?.length && cellRows[1].reduce((total, cell) => total + cellWidth(cell!), 0) > (divSize?.width ?? 0),
    [cellRows, divSize?.width],
  );

  // console.log(cellRows);

  // return (
  //   <div>
  //     {primaryAndSecondaryTripStatusAllKnown.map(status => (
  //       <div
  //         style={{width: 200, height: 32, backgroundColor: primaryAndSecondaryTripStatusDescription.color!(status)}}
  //         className="text-white">
  //         {status}
  //       </div>
  //     ))}
  //   </div>
  // );

  const selectedJobCount = Object.keys(selectedTrips).length;

  // const onClickEdit = useEditTripModal(null as never, null as never);

  return (
    <div className="h-100 d-flex">
      <div className="flex-grow-1 d-flex flex-column">
        <div className="border-bottom p-2 p-md-3 mb-2 mb-md-3">
          {selectedJobCount ? (
            <>
              {Boolean(selectedJobCount) && `${selectedJobCount} selected ${pluralize('job', selectedJobCount)}`}
              {/* <div className="flex-shrink-0 flex-grow-1 btn-group"> */}
              <button
                // onClick={onClickEdit}
                className="btn btn-sm btn-light">
                <FontAwesomeV5 name="file-edit" /> Edit&#8230;
              </button>
              <DuplicateTripLink trip={null as never} className="btn btn-sm btn-light">
                <FontAwesomeV5 name="clone" type="regular" /> Duplicate
              </DuplicateTripLink>
              <button className="btn btn-sm btn-light text-success" disabled>
                <FontAwesomeV5 name="check" /> Accept&#8230;
              </button>
              <button className="btn btn-sm btn-light text-danger" disabled>
                <FontAwesomeV5 name="ban" /> Cancel&#8230;
              </button>
              {/* </div> */}
            </>
          ) : trips ? (
            `${trips.length} ${pluralize('job', trips.length)}`
          ) : (
            ''
          )}
        </div>
        <div ref={gridElementRef as never} className="flex-grow-1 overflow-auto">
          {error ? (
            <CenteredLayout>
              <h4>Unable to load data</h4>
              <pre>{error.message}</pre>
            </CenteredLayout>
          ) : cellRows?.length && divSize ? (
            <VariableSizeGrid
              height={divSize.height}
              width={divSize.width}
              rowCount={cellRows.length}
              rowHeight={() => 48}
              columnCount={cellRows[1].length}
              columnWidth={columnIndex => cellWidth(cellRows[1][columnIndex]!)}
              itemData={cellRows}>
              {CellSwitch}
            </VariableSizeGrid>
          ) : (
            isLoading && (
              <CenteredLayout>
                <DelaySpinner />
              </CenteredLayout>
            )
          )}
        </div>
      </div>
      <div
        style={gridHasHorizonalScroll ? {zIndex: Constants.zIndex.level1} : undefined}
        className={joinClassNames(
          'flex-shrink-0 border-left overflow-scroll position-relative',
          gridHasHorizonalScroll && 'shadow',
        )}>
        <div className="d-flex">
          {selectedTrip && (
            <div
              style={{zIndex: Constants.zIndex.level1}}
              className="flex-grow-1 bg-white p-2 p-md-3 border-left overflow-scroll border-right">
              <TripDetails trip={selectedTrip} unsetSelectedTrip={() => setSelectedTrip(undefined)} />
            </div>
          )}
          <div className="p-2 p-md-3">
            {isLoading && (
              <div className="position-absolute" style={{top: '0.5rem', right: '1rem'}}>
                <Delay amountMs={250}>
                  <Spinner small />
                </Delay>
              </div>
            )}
            <div className="form-group">
              <h6>Date</h6>
              <ReactDatePicker
                selected={date}
                onChange={setDate as ReactDatePickerProps['onChange']}
                dateFormat="LLL do, y"
                className="form-control form-control-sm border-0"
                dayClassName={day => (moment(day).isSame(moment(date), 'day') ? 'bg-primary' : null)}
                inline
              />
            </div>
            <div className="form-group">
              <h6>Group by</h6>
              {DESCRIPTIONS_FOR_GROUPING.map(property => {
                const id = `GROUP-${property.key}`;
                return (
                  <div key={property.key} className="custom-control custom-radio">
                    <input
                      type="radio"
                      id={id}
                      name="GROUP_BY"
                      checked={property.key === descriptionForGrouping.key}
                      onChange={() => setDescriptionForGrouping(property)}
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor={id}>
                      {capitalize(property.userFacingTitle)}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="form-group">
              <h6>Columns</h6>
              {ALL_DESCRIPTIONS_FOR_COLUMNS.map(property => {
                const id = `COLUMNS-${property.key}`;
                const checked = Boolean(selectedDescriptionsForColumns.find(desc => desc.key === property.key));
                return (
                  <div key={property.key} className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id={id}
                      checked={checked}
                      onChange={() => {
                        setSelectedDescriptionsForColumns(old => {
                          const isRemoving = old.includes(property);
                          console.log({old, isRemoving});
                          if (isRemoving) {
                            return [...old].filter(p => p !== property);
                          } else {
                            return ALL_DESCRIPTIONS_FOR_COLUMNS.filter(
                              p => p === property || old.find(old => old.key === p.key),
                            );
                          }
                        });
                      }}
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor={id}>
                      {capitalize(property.userFacingTitle)}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="form-group">
              <h6>CSV Export</h6>
              <button className="btn btn-primary">
                <FontAwesomeV5 name="file-download" /> Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
