export default class Field {
  static firstScheduledTimestampMs = new Field('firstScheduledTimestampMs', 'ASCENDING');
  static firstScheduledTimestampMsDescending = new Field('firstScheduledTimestampMs', 'DESCENDING');
  static createdAt = new Field('createdAt', 'DESCENDING');
  static status = new Field('status', 'ASCENDING');
  static source = new Field('source', 'ASCENDING');
  static driverId = new Field('driverId', 'ASCENDING');
  static state = new Field('state', 'ASCENDING');
  static vendorId = new Field('vendorId', 'ASCENDING');
  static type = new Field('type', 'ASCENDING');

  path: string;
  direction: 'ASCENDING' | 'DESCENDING';

  constructor(path: string, direction: 'ASCENDING' | 'DESCENDING') {
    this.path = path;
    this.direction = direction;
  }
}
