import firebase from 'firebase/app';

export default class Message {
  /**
   *
   * @param {String} body
   * @param {String} recipientId
   * @param {UserType} recipientType
   * @param {Array<String>} seenBy
   * @param {String} senderId
   * @param {UserType} senderType
   * @param {Number} sentAt Timestamp in milliseconds
   */
  constructor(
    body,
    recipientId,
    recipientType,
    seenBy,
    senderId,
    senderType,
    sentAt = firebase.database.ServerValue.TIMESTAMP,
  ) {
    this.body = body;
    this.recipientId = recipientId;
    this.recipientType = recipientType.rawValue;
    this.seenBy = seenBy;
    this.senderId = senderId;
    this.senderType = senderType.rawValue;
    this.sentAt = sentAt;
  }
}
