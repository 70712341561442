import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {useAuthContext} from '../../contexts/AuthContext';
import joinClassNames from '../../functions/joinClassNames';
import unwrap from '../../functions/unwrap';
import useLocalStorage from '../../hooks/useLocalStorage';
import CurrentRoutesContext from './CurrentRoutesContext';

function routesAreEqual(route1, route2 = null) {
  return route1 === route2;
  return route1.path === unwrap(route2, r2 => r2.path) && route1.name === unwrap(route2, r2 => r2.name);
}

export default function MenuItem({route, isInCollapsable}) {
  const {claims} = useAuthContext();
  const {currentRoute, currentVisibleRoute} = useContext(CurrentRoutesContext);

  const isHighlighted = routesAreEqual(route, currentRoute) || routesAreEqual(route, currentVisibleRoute);

  if (!claims) {
    return null;
  } else if (route.requiredClaims && route.requiredClaims.find(requiredClaim => !claims[requiredClaim])) {
    // not allowed

    return null;
  } else if (route.hideFromMenu && route.children) {
    // hidden but with children, still render the children

    return route.children.map(child => <MenuItem key={child.absolutePath} route={child} />);
  } else if (route.hideFromMenu) {
    // hidden

    return null;
  } else if (route.children) {
    return <CollapsableMenuItem route={route} isHighlighted={isHighlighted} />;
  } else {
    // link

    return <LinkMenuItem route={route} isInCollapsable={isInCollapsable} isHighlighted={isHighlighted} />;
  }
}

function CollapsableMenuItem({route, isHighlighted}) {
  const [isCollapsed, setIsCollapsed] = useLocalStorage(`${route.path}_IS_COLLAPSED`, true);

  return (
    <div className={joinClassNames(isCollapsed ? 'py-2 px-3' : 'mx-2 py-2 px-2 bg-light rounded mb-2')}>
      <div
        onClick={route.children ? () => setIsCollapsed(!isCollapsed) : null}
        className={joinClassNames(
          'd-flex align-items-middle',
          isHighlighted && 'text-primary',
          route.children && 'cursor-pointer',
          !isCollapsed && 'mb-1',
        )}>
        {route.Icon && <route.Icon />}
        <div>
          {' '}
          {/* div is used for alignment */}
          <h6 className={joinClassNames('d-inline mb-0', route.Icon && 'ml-2')}>{route.name || 'No name'}</h6>
        </div>
      </div>
      {route.children && !isCollapsed && (
        <div style={{marginLeft: '2rem'}}>
          {route.children.map(child => (
            <MenuItem key={child.absolutePath} route={child} isInCollapsable />
          ))}
        </div>
      )}
    </div>
  );
}

function LinkMenuItem({route, isInCollapsable, isHighlighted}) {
  return (
    <>
      {route.separator &&
        (typeof route.separator === 'string' ? (
          <div className="d-flex align-items-center mt-2 mb-1">
            <small className="text-gray-500 font-weight-bold">{route.separator.toUpperCase()}</small>
            <div className="ml-2 border-bottom w-100" />
          </div>
        ) : (
          <hr className="my-1" />
        ))}
      <Link
        to={'/' + route.linkPath + (route.queryPath || '')}
        className={
          'd-flex align-items-middle ' +
          (isHighlighted ? ' font-weight-b old text-primary ' : ' ') +
          (isInCollapsable ? 'mb-1' : 'py-2 px-3')
        }>
        {route.Icon && <route.Icon />}
        <span className={route.Icon ? 'ml-2' : ''}>{route.name || 'No name'}</span>
      </Link>
    </>
  );
}
