import flatMap from 'lodash/flatMap';
import React, {useMemo} from 'react';
import {match} from 'react-router-dom';
import FullSizeContent from '../../components/FullSizeContent';
import GoogleMap from '../../components/GoogleMaps/GoogleMap';
import {latLng} from '../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../components/GoogleMaps/MapContents';
import {DelaySpinner} from '../../components/Spinner';
import unwrap from '../../functions/unwrap';
import useDriversMarkers from '../../hooks/useDriversMarkers';
import useRealtimeDatabase from '../../hooks/useRealtimeDatabase';
import useTripDirections from '../../hooks/useTripDirections';
import useTripPaths from '../../hooks/useTripPaths';
import useTripsMarkers from '../../hooks/useTripsMarkers';
import TripModel from '../../models/scoopm/Trip';
import TripStatus from '../../models/scoopm/TripStatus';
import LocationDetails from '../admin/rides/LocationDetails';
import RideOverview from '../admin/rides/RideOverview';

export default function Trip({match}: {match: match<{customerId: string; tripId: string}>}) {
  const trip = useRealtimeDatabase({
    path: `/trips/${match.params.customerId}/${match.params.tripId}`,
    Model: TripModel,
  });

  const tripEntries = useMemo(() => unwrap(trip.data, data => [[trip.key, data]]), [trip.key, trip.data]);

  const tripMarkers = useTripsMarkers({
    tripEntries,
    onClick: undefined,
    showOnlyPickupExceptFor: undefined,
  });

  const driver = useRealtimeDatabase<any>({
    path: unwrap(trip.data, data =>
      [TripStatus.scheduled.rawValue, TripStatus.accepted.rawValue, TripStatus.started.rawValue].includes(data.status)
        ? unwrap(data.driverId, id => `/drivers/${id}`)
        : null,
    ),
  });
  const driverEntries = useMemo(() => unwrap(driver.data, data => [[driver.key, data]]), [driver.key, driver.data]);
  const driverMarkers = useDriversMarkers({
    driverEntries,
    modifyMarker: undefined,
    onClick: undefined,
  });

  const markers = useMemo(() => flatMap([tripMarkers, driverMarkers].filter(Boolean)), [tripMarkers, driverMarkers]);

  const directions = useTripDirections(match.params.tripId);

  const {paths, distancesMi, bounds: tripBounds} = useTripPaths({trip: trip.data, directions});
  const tripAndDriverBounds = useMemo(
    () =>
      unwrap(tripBounds, tripBounds =>
        unwrap(
          driver.data,
          driverData => unwrap(driverData.coordinate, coordinate => tripBounds.extend(latLng(coordinate)), tripBounds),
          tripBounds,
        ),
      ),
    [driver.data, tripBounds],
  );
  const mapContents = useMemo(
    () =>
      unwrap(
        tripAndDriverBounds,
        latLngBounds =>
          new MapContents.Bounds({
            id: 'overview',
            name: 'Overview',
            latLngBounds,
          } as any),
      ),
    [tripAndDriverBounds],
  );

  if (trip.isLoading) {
    return (
      <FullSizeContent>
        <DelaySpinner />
      </FullSizeContent>
    );
  }

  if (!trip.data) {
    return 'No data';
  }

  return (
    <div className="row">
      <div className="col col-xl-10">
        <div className="mb-3">
          <RideOverview trip={trip.data} tripId={trip.key!} minimal={false} />
        </div>

        <div className="mb-3">
          <LocationDetails trip={trip} distancesMi={distancesMi} minimal={false} />
        </div>

        <div style={{height: '400px'}}>
          <GoogleMap
            mapContents={mapContents}
            markers={markers}
            paths={paths}
            streetViewControl={false}
            fullscreenControl={false}
          />
        </div>
      </div>
    </div>
  );
}
