import React from 'react';

export default class SelectOption {
  static render(options: SelectOption[]) {
    return options.map(option => (
      <option key={option.value} value={option.value} disabled={!option.enabled}>
        {option.text}
      </option>
    ));
  }

  value: string;
  text?: string;
  enabled: Boolean;

  constructor(value: string, text?: string, enabled?: Boolean) {
    this.value = value;
    this.text = text;
    this.enabled = enabled ?? true;
  }
}
