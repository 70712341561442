import firebase from 'firebase/app';
import RealtimeDatabaseModel from '../base/RealtimeDatabaseModel';

export default class TripTypeDetail extends RealtimeDatabaseModel {
  constructor({invoiceFormulaId, payoutFormulaId}) {
    super();
    this.invoiceFormulaId = invoiceFormulaId;
    this.payoutFormulaId = payoutFormulaId;
  }
}

TripTypeDetail.path = 'trip-type-details';

TripTypeDetail.fetch = async tripType =>
  (await firebase.database().ref(`/trip-type-details/${tripType}`).once('value')).val();
