import firebase from 'firebase/app';
import first from 'lodash/first';
import React, {useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import FirestoreTable from '../../components/FirestoreTable';
import ColumnMetadata from '../../components/Table/ColumnMetadata';
import {useAdditionalNavbarContent} from '../../contexts/AdditionalNavbarContentContext';
import unwrap from '../../functions/unwrap';
import PropertyType from '../types/PropertyType';

export default function FirestoreModelTable({schema, selectedQuery}) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    Promise.all(
      schema.properties.map(async property => {
        let get = null;
        if (PropertyType.isObjectId(property.type)) {
          const objectEntries = await PropertyType.fetchObjectEntries(property);
          get = model =>
            unwrap(
              objectEntries.find(([id, name]) => id === model[property.name]),
              value => value[1],
            );
        } else {
          get = model => PropertyType.formatForTable(property, model[property.name]);
        }
        return new ColumnMetadata({
          path: property.name,
          name: property.userFacingName,
          width: property.tableColumnWidth || PropertyType.tableColumnWidth(property),
          get,
        });
      }),
    ).then(setColumns);
  }, [schema.properties]);

  useAdditionalNavbarContent(
    useMemo(
      () => (
        <div className="col d-flex align-items-center justify-content-end px-2">
          <Link to={`/admin/${schema.collection()}/add`} className="btn bg-white">
            <i className="fa fa-plus" /> Add
          </Link>
        </div>
      ),
      [schema],
    ),
  );

  const collection = useMemo(() => schema.collection(), [schema]);
  const query = useMemo(
    () => unwrap(selectedQuery, query => query.get(firebase.firestore().collection(collection))),
    [selectedQuery, collection],
  );

  return (
    <div className="d-flex flex-column h-100">
      <FirestoreTable
        collection={collection}
        columns={columns}
        sortingColumn={first(schema.properties).name}
        onChangeSortingColumn={null}
        sortingDirection={null}
        onChangeSortingDirection={null}
        filters={null}
        query={query}
        limit={null}
        link={(id, data) => `/admin/${schema.collection()}/${id}`}
        onChangeRows={null}
        onChangeIsFetching={null}
        search={null}
        height={null}
      />
    </div>
  );
}
