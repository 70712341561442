import Region from '../../models/scoopm/Region';
import Property from '../types/Property';
import PropertyType from '../types/PropertyType';
import Schema from '../types/Schema';

const regionSchema = new Schema(
  'region',
  [
    new Property('name', PropertyType.string),
    new Property('circle', PropertyType.geoCircle, {
      userFacingName: 'Boundary',
    }),
    new Property('deliveriesSlackChannelId', PropertyType.slackChannelId, {
      isOptional: true,
      tableColumnWidth: 300,
    }),
    new Property('completedDeliveriesSlackChannelId', PropertyType.slackChannelId, {
      isOptional: true,
      tableColumnWidth: 300,
    }),
  ],
  null,
  Region,
);

export default regionSchema;
