"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
async function logPromise(label, promise) {
    var _a;
    console.log(`${label}: START`);
    try {
        const result = await promise;
        console.log(`${label}: SUCCESS (${typeof result === 'object' ? 'OBJECT' : result})`);
        return result;
    }
    catch (error) {
        console.error(`${label}: FAILURE (${(_a = error.message) !== null && _a !== void 0 ? _a : error})`);
        throw error;
    }
}
exports.default = logPromise;
