"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authErrorCodeMessage = exports.authErrorCodeMessageAdmin = void 0;
var AuthErrorCode;
(function (AuthErrorCode) {
    AuthErrorCode["ADMIN_ONLY_OPERATION"] = "auth/admin-restricted-operation";
    AuthErrorCode["ARGUMENT_ERROR"] = "auth/argument-error";
    AuthErrorCode["APP_NOT_AUTHORIZED"] = "auth/app-not-authorized";
    AuthErrorCode["APP_NOT_INSTALLED"] = "auth/app-not-installed";
    AuthErrorCode["CAPTCHA_CHECK_FAILED"] = "auth/captcha-check-failed";
    AuthErrorCode["CODE_EXPIRED"] = "auth/code-expired";
    AuthErrorCode["CORDOVA_NOT_READY"] = "auth/cordova-not-ready";
    AuthErrorCode["CORS_UNSUPPORTED"] = "auth/cors-unsupported";
    AuthErrorCode["CREDENTIAL_ALREADY_IN_USE"] = "auth/credential-already-in-use";
    AuthErrorCode["CREDENTIAL_MISMATCH"] = "auth/custom-token-mismatch";
    AuthErrorCode["CREDENTIAL_TOO_OLD_LOGIN_AGAIN"] = "auth/requires-recent-login";
    AuthErrorCode["DYNAMIC_LINK_NOT_ACTIVATED"] = "auth/dynamic-link-not-activated";
    AuthErrorCode["EMAIL_CHANGE_NEEDS_VERIFICATION"] = "auth/email-change-needs-verification";
    AuthErrorCode["EMAIL_EXISTS"] = "auth/email-already-in-use";
    AuthErrorCode["EMULATOR_CONFIG_FAILED"] = "auth/emulator-config-failed";
    AuthErrorCode["EXPIRED_OOB_CODE"] = "auth/expired-action-code";
    AuthErrorCode["EXPIRED_POPUP_REQUEST"] = "auth/cancelled-popup-request";
    AuthErrorCode["INTERNAL_ERROR"] = "auth/internal-error";
    AuthErrorCode["INVALID_API_KEY"] = "auth/invalid-api-key";
    AuthErrorCode["INVALID_APP_CREDENTIAL"] = "auth/invalid-app-credential";
    AuthErrorCode["INVALID_APP_ID"] = "auth/invalid-app-id";
    AuthErrorCode["INVALID_AUTH"] = "auth/invalid-user-token";
    AuthErrorCode["INVALID_AUTH_EVENT"] = "auth/invalid-auth-event";
    AuthErrorCode["INVALID_CERT_HASH"] = "auth/invalid-cert-hash";
    AuthErrorCode["INVALID_CODE"] = "auth/invalid-verification-code";
    AuthErrorCode["INVALID_CONTINUE_URI"] = "auth/invalid-continue-uri";
    AuthErrorCode["INVALID_CORDOVA_CONFIGURATION"] = "auth/invalid-cordova-configuration";
    AuthErrorCode["INVALID_CUSTOM_TOKEN"] = "auth/invalid-custom-token";
    AuthErrorCode["INVALID_DYNAMIC_LINK_DOMAIN"] = "auth/invalid-dynamic-link-domain";
    AuthErrorCode["INVALID_EMAIL"] = "auth/invalid-email";
    AuthErrorCode["INVALID_IDP_RESPONSE"] = "auth/invalid-credential";
    AuthErrorCode["INVALID_MESSAGE_PAYLOAD"] = "auth/invalid-message-payload";
    AuthErrorCode["INVALID_MFA_SESSION"] = "auth/invalid-multi-factor-session";
    AuthErrorCode["INVALID_OAUTH_CLIENT_ID"] = "auth/invalid-oauth-client-id";
    AuthErrorCode["INVALID_OAUTH_PROVIDER"] = "auth/invalid-oauth-provider";
    AuthErrorCode["INVALID_OOB_CODE"] = "auth/invalid-action-code";
    AuthErrorCode["INVALID_ORIGIN"] = "auth/unauthorized-domain";
    AuthErrorCode["INVALID_PASSWORD"] = "auth/wrong-password";
    AuthErrorCode["INVALID_PERSISTENCE"] = "auth/invalid-persistence-type";
    AuthErrorCode["INVALID_PHONE_NUMBER"] = "auth/invalid-phone-number";
    AuthErrorCode["INVALID_PROVIDER_ID"] = "auth/invalid-provider-id";
    AuthErrorCode["INVALID_RECIPIENT_EMAIL"] = "auth/invalid-recipient-email";
    AuthErrorCode["INVALID_SENDER"] = "auth/invalid-sender";
    AuthErrorCode["INVALID_SESSION_INFO"] = "auth/invalid-verification-id";
    AuthErrorCode["INVALID_TENANT_ID"] = "auth/invalid-tenant-id";
    AuthErrorCode["MFA_INFO_NOT_FOUND"] = "auth/multi-factor-info-not-found";
    AuthErrorCode["MFA_REQUIRED"] = "auth/multi-factor-auth-required";
    AuthErrorCode["MISSING_ANDROID_PACKAGE_NAME"] = "auth/missing-android-pkg-name";
    AuthErrorCode["MISSING_APP_CREDENTIAL"] = "auth/missing-app-credential";
    AuthErrorCode["MISSING_AUTH_DOMAIN"] = "auth/auth-domain-config-required";
    AuthErrorCode["MISSING_CODE"] = "auth/missing-verification-code";
    AuthErrorCode["MISSING_CONTINUE_URI"] = "auth/missing-continue-uri";
    AuthErrorCode["MISSING_IFRAME_START"] = "auth/missing-iframe-start";
    AuthErrorCode["MISSING_IOS_BUNDLE_ID"] = "auth/missing-ios-bundle-id";
    AuthErrorCode["MISSING_OR_INVALID_NONCE"] = "auth/missing-or-invalid-nonce";
    AuthErrorCode["MISSING_MFA_INFO"] = "auth/missing-multi-factor-info";
    AuthErrorCode["MISSING_MFA_SESSION"] = "auth/missing-multi-factor-session";
    AuthErrorCode["MISSING_PHONE_NUMBER"] = "auth/missing-phone-number";
    AuthErrorCode["MISSING_SESSION_INFO"] = "auth/missing-verification-id";
    AuthErrorCode["MODULE_DESTROYED"] = "auth/app-deleted";
    AuthErrorCode["NEED_CONFIRMATION"] = "auth/account-exists-with-different-credential";
    AuthErrorCode["NETWORK_REQUEST_FAILED"] = "auth/network-request-failed";
    AuthErrorCode["NULL_USER"] = "auth/null-user";
    AuthErrorCode["NO_AUTH_EVENT"] = "auth/no-auth-event";
    AuthErrorCode["NO_SUCH_PROVIDER"] = "auth/no-such-provider";
    AuthErrorCode["OPERATION_NOT_ALLOWED"] = "auth/operation-not-allowed";
    AuthErrorCode["OPERATION_NOT_SUPPORTED"] = "auth/operation-not-supported-in-this-environment";
    AuthErrorCode["POPUP_BLOCKED"] = "auth/popup-blocked";
    AuthErrorCode["POPUP_CLOSED_BY_USER"] = "auth/popup-closed-by-user";
    AuthErrorCode["PROVIDER_ALREADY_LINKED"] = "auth/provider-already-linked";
    AuthErrorCode["QUOTA_EXCEEDED"] = "auth/quota-exceeded";
    AuthErrorCode["REDIRECT_CANCELLED_BY_USER"] = "auth/redirect-cancelled-by-user";
    AuthErrorCode["REDIRECT_OPERATION_PENDING"] = "auth/redirect-operation-pending";
    AuthErrorCode["REJECTED_CREDENTIAL"] = "auth/rejected-credential";
    AuthErrorCode["SECOND_FACTOR_ALREADY_ENROLLED"] = "auth/second-factor-already-in-use";
    AuthErrorCode["SECOND_FACTOR_LIMIT_EXCEEDED"] = "auth/maximum-second-factor-count-exceeded";
    AuthErrorCode["TENANT_ID_MISMATCH"] = "auth/tenant-id-mismatch";
    AuthErrorCode["TIMEOUT"] = "auth/timeout";
    AuthErrorCode["TOKEN_EXPIRED"] = "auth/user-token-expired";
    AuthErrorCode["TOO_MANY_ATTEMPTS_TRY_LATER"] = "auth/too-many-requests";
    AuthErrorCode["UNAUTHORIZED_DOMAIN"] = "auth/unauthorized-continue-uri";
    AuthErrorCode["UNSUPPORTED_FIRST_FACTOR"] = "auth/unsupported-first-factor";
    AuthErrorCode["UNSUPPORTED_PERSISTENCE"] = "auth/unsupported-persistence-type";
    AuthErrorCode["UNSUPPORTED_TENANT_OPERATION"] = "auth/unsupported-tenant-operation";
    AuthErrorCode["UNVERIFIED_EMAIL"] = "auth/unverified-email";
    AuthErrorCode["USER_CANCELLED"] = "auth/user-cancelled";
    AuthErrorCode["USER_DELETED"] = "auth/user-not-found";
    AuthErrorCode["USER_DISABLED"] = "auth/user-disabled";
    AuthErrorCode["USER_MISMATCH"] = "auth/user-mismatch";
    AuthErrorCode["USER_SIGNED_OUT"] = "auth/user-signed-out";
    AuthErrorCode["WEAK_PASSWORD"] = "auth/weak-password";
    AuthErrorCode["WEB_STORAGE_UNSUPPORTED"] = "auth/web-storage-unsupported";
})(AuthErrorCode || (AuthErrorCode = {}));
exports.default = AuthErrorCode;
function authErrorCodeMessageAdmin(code) {
    switch (code) {
        case AuthErrorCode.ADMIN_ONLY_OPERATION:
            return 'This operation is restricted to administrators only.';
        case AuthErrorCode.ARGUMENT_ERROR:
            return 'Argument error';
        case AuthErrorCode.APP_NOT_AUTHORIZED:
            return ("This app, identified by the domain where it's hosted, is not " +
                'authorized to use Firebase Authentication with the provided API key. ' +
                'Review your key configuration in the Google API console.');
        case AuthErrorCode.APP_NOT_INSTALLED:
            return ('The requested mobile application corresponding to the identifier (' +
                'Android package name or iOS bundle ID) provided is not installed on ' +
                'this device.');
        case AuthErrorCode.CAPTCHA_CHECK_FAILED:
            return ('The reCAPTCHA response token provided is either invalid, expired, ' +
                'already used or the domain associated with it does not match the list ' +
                'of whitelisted domains.');
        case AuthErrorCode.CODE_EXPIRED:
            return 'The SMS code has expired. Please re-send the verification code to try ' + 'again.';
        case AuthErrorCode.CORDOVA_NOT_READY:
            return 'Cordova framework is not ready.';
        case AuthErrorCode.CORS_UNSUPPORTED:
            return 'This browser is not supported.';
        case AuthErrorCode.CREDENTIAL_ALREADY_IN_USE:
            return 'This credential is already associated with a different user account.';
        case AuthErrorCode.CREDENTIAL_MISMATCH:
            return 'The custom token corresponds to a different audience.';
        case AuthErrorCode.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
            return ('This operation is sensitive and requires recent authentication. Log in ' +
                'again before retrying this request.');
        case AuthErrorCode.DYNAMIC_LINK_NOT_ACTIVATED:
            return 'Please activate Dynamic Links in the Firebase Console and agree to the terms and ' + 'conditions.';
        case AuthErrorCode.EMAIL_CHANGE_NEEDS_VERIFICATION:
            return 'Multi-factor users must always have a verified email.';
        case AuthErrorCode.EMAIL_EXISTS:
            return 'The email address is already in use by another account.';
        case AuthErrorCode.EMULATOR_CONFIG_FAILED:
            return ('Auth instance has already been used to make a network call. Auth can ' +
                'no longer be configured to use the emulator. Try calling ' +
                '"useEmulator()" sooner.');
        case AuthErrorCode.EXPIRED_OOB_CODE:
            return 'The action code has expired.';
        case AuthErrorCode.EXPIRED_POPUP_REQUEST:
            return 'This operation has been cancelled due to another conflicting popup being opened.';
        case AuthErrorCode.INTERNAL_ERROR:
            return 'An internal AuthError has occurred.';
        case AuthErrorCode.INVALID_APP_CREDENTIAL:
            return ('The phone verification request contains an invalid application verifier.' +
                ' The reCAPTCHA token response is either invalid or expired.');
        case AuthErrorCode.INVALID_APP_ID:
            return 'The mobile app identifier is not registed for the current project.';
        case AuthErrorCode.INVALID_AUTH:
            return ("This user's credential isn't valid for this project. This can happen " +
                "if the user's token has been tampered with, or if the user isn't for " +
                'the project associated with this API key.');
        case AuthErrorCode.INVALID_AUTH_EVENT:
            return 'An internal AuthError has occurred.';
        case AuthErrorCode.INVALID_CODE:
            return ('The SMS verification code used to create the phone auth credential is ' +
                'invalid. Please resend the verification code sms and be sure use the ' +
                'verification code provided by the user.');
        case AuthErrorCode.INVALID_CONTINUE_URI:
            return 'The continue URL provided in the request is invalid.';
        case AuthErrorCode.INVALID_CORDOVA_CONFIGURATION:
            return ('The following Cordova plugins must be installed to enable OAuth sign-in: ' +
                'cordova-plugin-buildinfo, cordova-universal-links-plugin, ' +
                'cordova-plugin-browsertab, cordova-plugin-inappbrowser and ' +
                'cordova-plugin-customurlscheme.');
        case AuthErrorCode.INVALID_CUSTOM_TOKEN:
            return 'The custom token format is incorrect. Please check the documentation.';
        case AuthErrorCode.INVALID_DYNAMIC_LINK_DOMAIN:
            return 'The provided dynamic link domain is not configured or authorized for the current project.';
        case AuthErrorCode.INVALID_EMAIL:
            return 'The email address is badly formatted.';
        case AuthErrorCode.INVALID_API_KEY:
            return 'Your API key is invalid, please check you have copied it correctly.';
        case AuthErrorCode.INVALID_CERT_HASH:
            return 'The SHA-1 certificate hash provided is invalid.';
        case AuthErrorCode.INVALID_IDP_RESPONSE:
            return 'The supplied auth credential is malformed or has expired.';
        case AuthErrorCode.INVALID_MESSAGE_PAYLOAD:
            return ('The email template corresponding to this action contains invalid characters in its message. ' +
                'Please fix by going to the Auth email templates section in the Firebase Console.');
        case AuthErrorCode.INVALID_MFA_SESSION:
            return 'The request does not contain a valid proof of first factor successful sign-in.';
        case AuthErrorCode.INVALID_OAUTH_PROVIDER:
            return ('EmailAuthProvider is not supported for this operation. This operation ' + 'only supports OAuth providers.');
        case AuthErrorCode.INVALID_OAUTH_CLIENT_ID:
            return 'The OAuth client ID provided is either invalid or does not match the ' + 'specified API key.';
        case AuthErrorCode.INVALID_ORIGIN:
            return ('This domain is not authorized for OAuth operations for your Firebase ' +
                'project. Edit the list of authorized domains from the Firebase console.');
        case AuthErrorCode.INVALID_OOB_CODE:
            return ('The action code is invalid. This can happen if the code is malformed, ' + 'expired, or has already been used.');
        case AuthErrorCode.INVALID_PASSWORD:
            return 'The password is invalid or the user does not have a password.';
        case AuthErrorCode.INVALID_PERSISTENCE:
            return 'The specified persistence type is invalid. It can only be local, session or none.';
        case AuthErrorCode.INVALID_PHONE_NUMBER:
            return ('The format of the phone number provided is incorrect. Please enter the ' +
                'phone number in a format that can be parsed into E.164 format. E.164 ' +
                'phone numbers are written in the format case +]case country code]case subscriber ' +
                'number including area code].');
        case AuthErrorCode.INVALID_PROVIDER_ID:
            return 'The specified provider ID is invalid.';
        case AuthErrorCode.INVALID_RECIPIENT_EMAIL:
            return ('The email corresponding to this action failed to send as the provided ' + 'recipient email address is invalid.');
        case AuthErrorCode.INVALID_SENDER:
            return ('The email template corresponding to this action contains an invalid sender email or name. ' +
                'Please fix by going to the Auth email templates section in the Firebase Console.');
        case AuthErrorCode.INVALID_SESSION_INFO:
            return 'The verification ID used to create the phone auth credential is invalid.';
        case AuthErrorCode.INVALID_TENANT_ID:
            return "The Auth instance's tenant ID is invalid.";
        case AuthErrorCode.MISSING_ANDROID_PACKAGE_NAME:
            return 'An Android Package Name must be provided if the Android App is required to be installed.';
        case AuthErrorCode.MISSING_AUTH_DOMAIN:
            return ('Be sure to include authDomain when calling firebase.initializeApp(), ' +
                'by following the instructions in the Firebase console.');
        case AuthErrorCode.MISSING_APP_CREDENTIAL:
            return ('The phone verification request is missing an application verifier ' +
                'assertion. A reCAPTCHA response token needs to be provided.');
        case AuthErrorCode.MISSING_CODE:
            return 'The phone auth credential was created with an empty SMS verification code.';
        case AuthErrorCode.MISSING_CONTINUE_URI:
            return 'A continue URL must be provided in the request.';
        case AuthErrorCode.MISSING_IFRAME_START:
            return 'An internal AuthError has occurred.';
        case AuthErrorCode.MISSING_IOS_BUNDLE_ID:
            return 'An iOS Bundle ID must be provided if an App Store ID is provided.';
        case AuthErrorCode.MISSING_OR_INVALID_NONCE:
            return ('The request does not contain a valid nonce. This can occur if the ' +
                'SHA-256 hash of the provided raw nonce does not match the hashed nonce ' +
                'in the ID token payload.');
        case AuthErrorCode.MISSING_MFA_INFO:
            return 'No second factor identifier is provided.';
        case AuthErrorCode.MISSING_MFA_SESSION:
            return 'The request is missing proof of first factor successful sign-in.';
        case AuthErrorCode.MISSING_PHONE_NUMBER:
            return 'To send verification codes, provide a phone number for the recipient.';
        case AuthErrorCode.MISSING_SESSION_INFO:
            return 'The phone auth credential was created with an empty verification ID.';
        case AuthErrorCode.MODULE_DESTROYED:
            return 'This instance of FirebaseApp has been deleted.';
        case AuthErrorCode.MFA_INFO_NOT_FOUND:
            return 'The user does not have a second factor matching the identifier provided.';
        case AuthErrorCode.MFA_REQUIRED:
            return 'Proof of ownership of a second factor is required to complete sign-in.';
        case AuthErrorCode.NEED_CONFIRMATION:
            return ('An account already exists with the same email address but different ' +
                'sign-in credentials. Sign in using a provider associated with this ' +
                'email address.');
        case AuthErrorCode.NETWORK_REQUEST_FAILED:
            return 'A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred.';
        case AuthErrorCode.NO_AUTH_EVENT:
            return 'An internal AuthError has occurred.';
        case AuthErrorCode.NO_SUCH_PROVIDER:
            return 'User was not linked to an account with the given provider.';
        case AuthErrorCode.NULL_USER:
            return ('A null user object was provided as the argument for an operation which ' + 'requires a non-null user object.');
        case AuthErrorCode.OPERATION_NOT_ALLOWED:
            return ('The given sign-in provider is disabled for this Firebase project. ' +
                'Enable it in the Firebase console, under the sign-in method tab of the ' +
                'Auth section.');
        case AuthErrorCode.OPERATION_NOT_SUPPORTED:
            return ('This operation is not supported in the environment this application is ' +
                'running on. "location.protocol" must be http, https or chrome-extension' +
                ' and web storage must be enabled.');
        case AuthErrorCode.POPUP_BLOCKED:
            return 'Unable to establish a connection with the popup. It may have been blocked by the browser.';
        case AuthErrorCode.POPUP_CLOSED_BY_USER:
            return 'The popup has been closed by the user before finalizing the operation.';
        case AuthErrorCode.PROVIDER_ALREADY_LINKED:
            return 'User can only be linked to one identity for the given provider.';
        case AuthErrorCode.QUOTA_EXCEEDED:
            return "The project's quota for this operation has been exceeded.";
        case AuthErrorCode.REDIRECT_CANCELLED_BY_USER:
            return 'The redirect operation has been cancelled by the user before finalizing.';
        case AuthErrorCode.REDIRECT_OPERATION_PENDING:
            return 'A redirect sign-in operation is already pending.';
        case AuthErrorCode.REJECTED_CREDENTIAL:
            return 'The request contains malformed or mismatching credentials.';
        case AuthErrorCode.SECOND_FACTOR_ALREADY_ENROLLED:
            return 'The second factor is already enrolled on this account.';
        case AuthErrorCode.SECOND_FACTOR_LIMIT_EXCEEDED:
            return 'The maximum allowed number of second factors on a user has been exceeded.';
        case AuthErrorCode.TENANT_ID_MISMATCH:
            return "The provided tenant ID does not match the Auth instance's tenant ID";
        case AuthErrorCode.TIMEOUT:
            return 'The operation has timed out.';
        case AuthErrorCode.TOKEN_EXPIRED:
            return "The user's credential is no longer valid. The user must sign in again.";
        case AuthErrorCode.TOO_MANY_ATTEMPTS_TRY_LATER:
            return 'We have blocked all requests from this device due to unusual activity. ' + 'Try again later.';
        case AuthErrorCode.UNAUTHORIZED_DOMAIN:
            return ('The domain of the continue URL is not whitelisted.  Please whitelist ' + 'the domain in the Firebase console.');
        case AuthErrorCode.UNSUPPORTED_FIRST_FACTOR:
            return 'Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.';
        case AuthErrorCode.UNSUPPORTED_PERSISTENCE:
            return 'The current environment does not support the specified persistence type.';
        case AuthErrorCode.UNSUPPORTED_TENANT_OPERATION:
            return 'This operation is not supported in a multi-tenant context.';
        case AuthErrorCode.UNVERIFIED_EMAIL:
            return 'The operation requires a verified email.';
        case AuthErrorCode.USER_CANCELLED:
            return 'The user did not grant your application the permissions it requested.';
        case AuthErrorCode.USER_DELETED:
            return 'There is no user record corresponding to this identifier. The user may ' + 'have been deleted.';
        case AuthErrorCode.USER_DISABLED:
            return 'The user account has been disabled by an administrator.';
        case AuthErrorCode.USER_MISMATCH:
            return 'The supplied credentials do not correspond to the previously signed in user.';
        case AuthErrorCode.USER_SIGNED_OUT:
            return 'User signed out';
        case AuthErrorCode.WEAK_PASSWORD:
            return 'The password must be 6 characters long or more.';
        case AuthErrorCode.WEB_STORAGE_UNSUPPORTED:
            return 'This browser is not supported or 3rd party cookies and data may be disabled.';
    }
}
exports.authErrorCodeMessageAdmin = authErrorCodeMessageAdmin;
function authErrorCodeMessage(code) {
    switch (code) {
        case AuthErrorCode.ADMIN_ONLY_OPERATION:
            return 'This operation is restricted to administrators only.';
        case AuthErrorCode.ARGUMENT_ERROR:
            return 'Argument error';
        case AuthErrorCode.APP_NOT_AUTHORIZED:
            return ("This app, identified by the domain where it's hosted, is not " +
                'authorized to use Firebase Authentication with the provided API key. ' +
                'Review your key configuration in the Google API console.');
        case AuthErrorCode.APP_NOT_INSTALLED:
            return ('The requested mobile application corresponding to the identifier (' +
                'Android package name or iOS bundle ID) provided is not installed on ' +
                'this device.');
        case AuthErrorCode.CAPTCHA_CHECK_FAILED:
            return ('The reCAPTCHA response token provided is either invalid, expired, ' +
                'already used or the domain associated with it does not match the list ' +
                'of whitelisted domains.');
        case AuthErrorCode.CODE_EXPIRED:
            return 'The SMS code has expired. Please re-send the verification code to try ' + 'again.';
        case AuthErrorCode.CORDOVA_NOT_READY:
            return 'Cordova framework is not ready.';
        case AuthErrorCode.CORS_UNSUPPORTED:
            return 'This browser is not supported.';
        case AuthErrorCode.CREDENTIAL_ALREADY_IN_USE:
            return 'This credential is already associated with a different user account.';
        case AuthErrorCode.CREDENTIAL_MISMATCH:
            return 'The custom token corresponds to a different audience.';
        case AuthErrorCode.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
            return ('This operation is sensitive and requires recent authentication. Log in ' +
                'again before retrying this request.');
        case AuthErrorCode.DYNAMIC_LINK_NOT_ACTIVATED:
            return 'Please activate Dynamic Links in the Firebase Console and agree to the terms and ' + 'conditions.';
        case AuthErrorCode.EMAIL_CHANGE_NEEDS_VERIFICATION:
            return 'Multi-factor users must always have a verified email.';
        case AuthErrorCode.EMAIL_EXISTS:
            return 'The email address is already in use by another account.';
        case AuthErrorCode.EMULATOR_CONFIG_FAILED:
            return ('Auth instance has already been used to make a network call. Auth can ' +
                'no longer be configured to use the emulator. Try calling ' +
                '"useEmulator()" sooner.');
        case AuthErrorCode.EXPIRED_OOB_CODE:
            return 'The action code has expired.';
        case AuthErrorCode.EXPIRED_POPUP_REQUEST:
            return 'This operation has been cancelled due to another conflicting popup being opened.';
        case AuthErrorCode.INTERNAL_ERROR:
            return 'An internal AuthError has occurred.';
        case AuthErrorCode.INVALID_APP_CREDENTIAL:
            return ('The phone verification request contains an invalid application verifier.' +
                ' The reCAPTCHA token response is either invalid or expired.');
        case AuthErrorCode.INVALID_APP_ID:
            return 'The mobile app identifier is not registed for the current project.';
        case AuthErrorCode.INVALID_AUTH:
            return ("Your credential isn't valid for this project. This can happen " +
                "if your token has been tampered with, or if you isn't for " +
                'the project associated with this API key.');
        case AuthErrorCode.INVALID_AUTH_EVENT:
            return 'An internal AuthError has occurred.';
        case AuthErrorCode.INVALID_CODE:
            return ('The SMS verification code used to create the phone auth credential is ' +
                'invalid. Please resend the verification code sms and be sure use the ' +
                'verification code provided by you.');
        case AuthErrorCode.INVALID_CONTINUE_URI:
            return 'The continue URL provided in the request is invalid.';
        case AuthErrorCode.INVALID_CORDOVA_CONFIGURATION:
            return ('The following Cordova plugins must be installed to enable OAuth sign-in: ' +
                'cordova-plugin-buildinfo, cordova-universal-links-plugin, ' +
                'cordova-plugin-browsertab, cordova-plugin-inappbrowser and ' +
                'cordova-plugin-customurlscheme.');
        case AuthErrorCode.INVALID_CUSTOM_TOKEN:
            return 'The custom token format is incorrect. Please check the documentation.';
        case AuthErrorCode.INVALID_DYNAMIC_LINK_DOMAIN:
            return 'The provided dynamic link domain is not configured or authorized for the current project.';
        case AuthErrorCode.INVALID_EMAIL:
            return 'The email address is badly formatted.';
        case AuthErrorCode.INVALID_API_KEY:
            return 'Your API key is invalid, please check you have copied it correctly.';
        case AuthErrorCode.INVALID_CERT_HASH:
            return 'The SHA-1 certificate hash provided is invalid.';
        case AuthErrorCode.INVALID_IDP_RESPONSE:
            return 'The supplied auth credential is malformed or has expired.';
        case AuthErrorCode.INVALID_MESSAGE_PAYLOAD:
            return ('The email template corresponding to this action contains invalid characters in its message. ' +
                'Please fix by going to the Auth email templates section in the Firebase Console.');
        case AuthErrorCode.INVALID_MFA_SESSION:
            return 'The request does not contain a valid proof of first factor successful sign-in.';
        case AuthErrorCode.INVALID_OAUTH_PROVIDER:
            return ('EmailAuthProvider is not supported for this operation. This operation ' + 'only supports OAuth providers.');
        case AuthErrorCode.INVALID_OAUTH_CLIENT_ID:
            return 'The OAuth client ID provided is either invalid or does not match the ' + 'specified API key.';
        case AuthErrorCode.INVALID_ORIGIN:
            return ('This domain is not authorized for OAuth operations for your Firebase ' +
                'project. Edit the list of authorized domains from the Firebase console.');
        case AuthErrorCode.INVALID_OOB_CODE:
            return ('The action code is invalid. This can happen if the code is malformed, ' + 'expired, or has already been used.');
        case AuthErrorCode.INVALID_PASSWORD:
            return 'The password is invalid or the you do not have a password.';
        case AuthErrorCode.INVALID_PERSISTENCE:
            return 'The specified persistence type is invalid. It can only be local, session or none.';
        case AuthErrorCode.INVALID_PHONE_NUMBER:
            return ('The format of the phone number provided is incorrect. Please enter the ' +
                'phone number in a format that can be parsed into E.164 format. E.164 ' +
                'phone numbers are written in the format case +]case country code]case subscriber ' +
                'number including area code].');
        case AuthErrorCode.INVALID_PROVIDER_ID:
            return 'The specified provider ID is invalid.';
        case AuthErrorCode.INVALID_RECIPIENT_EMAIL:
            return ('The email corresponding to this action failed to send as the provided ' + 'recipient email address is invalid.');
        case AuthErrorCode.INVALID_SENDER:
            return ('The email template corresponding to this action contains an invalid sender email or name. ' +
                'Please fix by going to the Auth email templates section in the Firebase Console.');
        case AuthErrorCode.INVALID_SESSION_INFO:
            return 'The verification ID used to create the phone auth credential is invalid.';
        case AuthErrorCode.INVALID_TENANT_ID:
            return "The Auth instance's tenant ID is invalid.";
        case AuthErrorCode.MISSING_ANDROID_PACKAGE_NAME:
            return 'An Android Package Name must be provided if the Android App is required to be installed.';
        case AuthErrorCode.MISSING_AUTH_DOMAIN:
            return ('Be sure to include authDomain when calling firebase.initializeApp(), ' +
                'by following the instructions in the Firebase console.');
        case AuthErrorCode.MISSING_APP_CREDENTIAL:
            return ('The phone verification request is missing an application verifier ' +
                'assertion. A reCAPTCHA response token needs to be provided.');
        case AuthErrorCode.MISSING_CODE:
            return 'The phone auth credential was created with an empty SMS verification code.';
        case AuthErrorCode.MISSING_CONTINUE_URI:
            return 'A continue URL must be provided in the request.';
        case AuthErrorCode.MISSING_IFRAME_START:
            return 'An internal AuthError has occurred.';
        case AuthErrorCode.MISSING_IOS_BUNDLE_ID:
            return 'An iOS Bundle ID must be provided if an App Store ID is provided.';
        case AuthErrorCode.MISSING_OR_INVALID_NONCE:
            return ('The request does not contain a valid nonce. This can occur if the ' +
                'SHA-256 hash of the provided raw nonce does not match the hashed nonce ' +
                'in the ID token payload.');
        case AuthErrorCode.MISSING_MFA_INFO:
            return 'No second factor identifier is provided.';
        case AuthErrorCode.MISSING_MFA_SESSION:
            return 'The request is missing proof of first factor successful sign-in.';
        case AuthErrorCode.MISSING_PHONE_NUMBER:
            return 'To send verification codes, provide a phone number for the recipient.';
        case AuthErrorCode.MISSING_SESSION_INFO:
            return 'The phone auth credential was created with an empty verification ID.';
        case AuthErrorCode.MODULE_DESTROYED:
            return 'This instance of FirebaseApp has been deleted.';
        case AuthErrorCode.MFA_INFO_NOT_FOUND:
            return 'The you do not have a second factor matching the identifier provided.';
        case AuthErrorCode.MFA_REQUIRED:
            return 'Proof of ownership of a second factor is required to complete sign-in.';
        case AuthErrorCode.NEED_CONFIRMATION:
            return ('An account already exists with the same email address but different ' +
                'sign-in credentials. Sign in using a provider associated with this ' +
                'email address.');
        case AuthErrorCode.NETWORK_REQUEST_FAILED:
            return 'A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred.';
        case AuthErrorCode.NO_AUTH_EVENT:
            return 'An internal AuthError has occurred.';
        case AuthErrorCode.NO_SUCH_PROVIDER:
            return 'You were not linked to an account with the given provider.';
        case AuthErrorCode.NULL_USER:
            return ('A null user object was provided as the argument for an operation which ' + 'requires a non-null user object.');
        case AuthErrorCode.OPERATION_NOT_ALLOWED:
            return ('The given sign-in provider is disabled for this Firebase project. ' +
                'Enable it in the Firebase console, under the sign-in method tab of the ' +
                'Auth section.');
        case AuthErrorCode.OPERATION_NOT_SUPPORTED:
            return ('This operation is not supported in the environment this application is ' +
                'running on. "location.protocol" must be http, https or chrome-extension' +
                ' and web storage must be enabled.');
        case AuthErrorCode.POPUP_BLOCKED:
            return 'Unable to establish a connection with the popup. It may have been blocked by the browser.';
        case AuthErrorCode.POPUP_CLOSED_BY_USER:
            return 'The popup has been closed by you before finalizing the operation.';
        case AuthErrorCode.PROVIDER_ALREADY_LINKED:
            return 'User can only be linked to one identity for the given provider.';
        case AuthErrorCode.QUOTA_EXCEEDED:
            return "The project's quota for this operation has been exceeded.";
        case AuthErrorCode.REDIRECT_CANCELLED_BY_USER:
            return 'The redirect operation has been cancelled by you before finalizing.';
        case AuthErrorCode.REDIRECT_OPERATION_PENDING:
            return 'A redirect sign-in operation is already pending.';
        case AuthErrorCode.REJECTED_CREDENTIAL:
            return 'The request contains malformed or mismatching credentials.';
        case AuthErrorCode.SECOND_FACTOR_ALREADY_ENROLLED:
            return 'The second factor is already enrolled on this account.';
        case AuthErrorCode.SECOND_FACTOR_LIMIT_EXCEEDED:
            return 'The maximum allowed number of second factors on a user has been exceeded.';
        case AuthErrorCode.TENANT_ID_MISMATCH:
            return "The provided tenant ID does not match the Auth instance's tenant ID";
        case AuthErrorCode.TIMEOUT:
            return 'The operation has timed out.';
        case AuthErrorCode.TOKEN_EXPIRED:
            return 'Your credential is no longer valid. You must sign in again.';
        case AuthErrorCode.TOO_MANY_ATTEMPTS_TRY_LATER:
            return 'We have blocked all requests from this device due to unusual activity. ' + 'Try again later.';
        case AuthErrorCode.UNAUTHORIZED_DOMAIN:
            return ('The domain of the continue URL is not whitelisted.  Please whitelist ' + 'the domain in the Firebase console.');
        case AuthErrorCode.UNSUPPORTED_FIRST_FACTOR:
            return 'Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.';
        case AuthErrorCode.UNSUPPORTED_PERSISTENCE:
            return 'The current environment does not support the specified persistence type.';
        case AuthErrorCode.UNSUPPORTED_TENANT_OPERATION:
            return 'This operation is not supported in a multi-tenant context.';
        case AuthErrorCode.UNVERIFIED_EMAIL:
            return 'The operation requires a verified email.';
        case AuthErrorCode.USER_CANCELLED:
            return 'You did not grant your application the permissions it requested.';
        case AuthErrorCode.USER_DELETED:
            return 'There is no user record corresponding to this identifier. You may ' + 'have been deleted.';
        case AuthErrorCode.USER_DISABLED:
            return 'Your account has been disabled by an administrator.';
        case AuthErrorCode.USER_MISMATCH:
            return 'The supplied credentials do not correspond to the previously signed in user.';
        case AuthErrorCode.USER_SIGNED_OUT:
            return 'User signed out';
        case AuthErrorCode.WEAK_PASSWORD:
            return 'The password must be 6 characters long or more.';
        case AuthErrorCode.WEB_STORAGE_UNSUPPORTED:
            return 'This browser is not supported or 3rd party cookies and data may be disabled.';
    }
}
exports.authErrorCodeMessage = authErrorCodeMessage;
