import flatMap from 'lodash/flatMap';

export default class Path {
  constructor({id, googlePath}) {
    this.id = id;
    this.googlePath = googlePath;
  }
}

Path.forGoogleMapsDirections = (id, directions) =>
  new Path({
    id,
    googlePath: directions.routes[0].overview_path,
  });

Path.forDirections = (id, directions) =>
  new Path({
    id,
    googlePath: flatMap(directions.legs.map(leg => window.google.maps.geometry.encoding.decodePath(leg.encodedPath))),
  });
