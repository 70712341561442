import Schema from '../types/Schema';
import Property from '../types/Property';
import PropertyType from '../types/PropertyType';

const userGroupSchema = new Schema('user-group', [
  new Property('name', PropertyType.string, {tableColumnWidth: 350}),
  new Property('members', PropertyType.uid, {
    isOptional: true,
    isArray: true,
  }),
  new Property('filterCities', PropertyType.city, {
    isOptional: true,
    isArray: true,
    description: 'Users in this group will only be able to view orders and drivers within the filter cities',
  }),
  new Property('vendorId', PropertyType.firestoreId, {isOptional: true}),
  new Property('restrictCustomerFilter', PropertyType.boolean, {
    isOptional: true,
    userFacingName: `Hide customer filter from users`,
    tableColumnWidth: 300,
    description:
      'This is used on the old "Rides/tasks" tables to stop Florida users from being able to change the customer filter, which was set to their user ID',
  }),
]);

export default userGroupSchema;
