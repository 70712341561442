import firebase from 'firebase/app';
import TripStatus from '../../models/scoopm/TripStatus';

export default class Firestore {
  static instance = new Firestore();

  uncompletedTripForDriverId(driverId) {
    return firebase
      .firestore()
      .collection('trips')
      .where('driverId', '==', driverId)
      .where('status', 'in', [TripStatus.created.rawValue, TripStatus.scheduled.rawValue, TripStatus.accepted.rawValue])
      .get();
  }
}
