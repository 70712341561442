"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tripStatusCustomerTitle = exports.tripStatusAdminTitle = exports.tripStatusTitle = exports.tripStatusDeserialize = exports.tripStatusCanceled = exports.tripStatusDone = exports.tripStatusActive = exports.tripStatusAll = void 0;
exports.tripStatusAll = [
    'CREATED',
    'SCHEDULED',
    'ACCEPTED',
    'STARTED',
    'COMPLETED',
    'TRIP-TIMED-OUT',
    'CANCELED-BY-RIDER',
    'CANCELED-BY-DRIVER',
];
exports.tripStatusActive = ['CREATED', 'ACCEPTED', 'STARTED'];
exports.tripStatusDone = ['COMPLETED', 'CANCELED-BY-RIDER', 'CANCELED-BY-DRIVER', 'TRIP-TIMED-OUT'];
exports.tripStatusCanceled = ['CANCELED-BY-RIDER', 'CANCELED-BY-DRIVER'];
function tripStatusDeserialize(value) {
    if (!exports.tripStatusAll.includes(value))
        throw new Error('Not a valid TripStatus');
    return value;
}
exports.tripStatusDeserialize = tripStatusDeserialize;
function tripStatusTitle(status) {
    switch (status) {
        case 'ACCEPTED':
            return 'Accepted';
        case 'CANCELED-BY-DRIVER':
            return 'Canceled by driver';
        case 'CANCELED-BY-RIDER':
            return 'Canceled';
        case 'COMPLETED':
            return 'Completed';
        case 'CREATED':
            return 'Created';
        case 'SCHEDULED':
            return 'Scheduled';
        case 'STARTED':
            return 'Started';
        case 'TRIP-TIMED-OUT':
            return 'Trip timed out';
    }
}
exports.tripStatusTitle = tripStatusTitle;
function tripStatusAdminTitle(status) {
    switch (status) {
        case 'ACCEPTED':
            return 'Accepted';
        case 'CANCELED-BY-DRIVER':
            return 'Canceled by driver';
        case 'CANCELED-BY-RIDER':
            return 'Canceled';
        case 'COMPLETED':
            return 'Completed';
        case 'CREATED':
            return 'New';
        case 'SCHEDULED':
            return 'Up next';
        case 'STARTED':
            return 'Started';
        case 'TRIP-TIMED-OUT':
            return 'Trip timed out';
    }
}
exports.tripStatusAdminTitle = tripStatusAdminTitle;
function tripStatusCustomerTitle(status) {
    switch (status) {
        case 'SCHEDULED':
            return 'Up next';
        case 'CREATED':
            return 'New';
        case 'ACCEPTED':
            return 'On route to pickup';
        case 'STARTED':
            return 'On route';
        case 'COMPLETED':
            return 'Done';
        case 'TRIP-TIMED-OUT':
            return 'No driver found';
        case 'CANCELED-BY-DRIVER':
            return 'Canceled by driver';
        case 'CANCELED-BY-RIDER':
            return 'Canceled';
    }
}
exports.tripStatusCustomerTitle = tripStatusCustomerTitle;
