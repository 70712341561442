import React, {useEffect, useState} from 'react';
import GoogleMap from '../../../components/GoogleMaps/GoogleMap';
import {boundsForLatLngs, latLng} from '../../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../../components/GoogleMaps/MapContents';
import Marker from '../../../components/GoogleMaps/Marker';
import MapContainer from '../../../components/MapContainer';
import MapContainerColumn from '../../../components/MapContainerColumn';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';
import PositionInList from '../../../models/scoopm/PositionInList';
import RealtimeDatabase from '../../../references/database/RealtimeDatabase';
import TripsPanel from '../dashboard/TripsPanel';

export default function Marketplace() {
  // STATE

  const marketplace = useRealtimeDatabase({path: '/marketplace'});
  const [trips, setTrips] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [paths, setPaths] = useState([]);
  const [mapContents, setMapContents] = useState();

  // EFFECT: marketplace --> trips

  useEffect(() => {
    Promise.all(
      Object.entries(marketplace.data || {}).map(([tripId, trip]) =>
        RealtimeDatabase.instance.tripsAll
          .child(tripId)
          .once()
          .then(([trip, tripId]) => [tripId, trip]),
      ),
    ).then(setTrips, error => {});
  }, [marketplace.data]);

  // EFFFECT: trips --> markers and paths

  useEffect(() => {
    // markers

    const markers = [];
    trips.forEach(([tripId, trip]) =>
      trip.locationsWithDefault().forEach((location, i, locations) => {
        markers.push(
          new Marker({
            id: `${tripId}-${i}`,
            label: PositionInList.for(i, locations).locationName(locations.length),
            position: location.coordinates,
            infoWindowContent: `<div>
                            <h6>${trip.title()}</h6>
                            <p>
                                ${locations
                                  .map(
                                    (location, i) =>
                                      `<span><strong>${PositionInList.for(i, locations).locationName(
                                        locations.length,
                                      )}</strong> ${location.name} ${location.address}</span>`,
                                  )
                                  .join('<br />\n')}
                            </p>
                            <p>
                                <a href="/admin/jobs/${
                                  trip.customerId
                                }/${tripId}" class="btn btn-sm btn-primary">Details</a>
                            </p>
                        </div>`,
          }),
        );
      }),
    );
    setMarkers(markers.filter(marker => marker.position));

    // // paths

    // // remove paths that no longer exist

    // const currentTripIds = trips.map(([tripId]) => tripId)
    // let currentPaths = []
    // setPaths(oldPaths => {
    //     currentPaths = oldPaths.filter(path => currentTripIds.includes(path.id))
    //     return currentPaths
    // })

    // // fetch paths that aren't present and aren't single-location

    // const unfetchedTrips = trips.filter(([tripId, trip]) =>
    //     !currentPaths.includes(tripId) && !trip.isSingleLocation()
    // )
    // Promise.all(
    //     unfetchedTrips.map(([tripId, trip]) => DirectionsService.getDirectionsForTrip(tripId, trip))
    // ).then(tripIdsAndDirections => {
    //     setPaths(tripIdsAndDirections.map(([tripId, directions]) => Path.forGoogleMapsDirections(tripId, directions)))
    // }, error => { })
  }, [trips]);

  // EFFECT: markers --> map contents

  useEffect(() => {
    setMapContents(
      markers && markers.length
        ? new MapContents.Bounds({
            id: 'trips',
            name: 'All trips',
            latLngBounds: boundsForLatLngs(markers.map(marker => latLng(marker.position))),
          })
        : null,
    );
  }, [markers]);

  // RENDER
  // console.log(markers && markers.map(marker => marker.position))
  return (
    <MapContainer>
      <GoogleMap markers={markers} paths={paths} mapContents={mapContents} />
      <MapContainerColumn>
        <TripsPanel tripEntries={trips} />
      </MapContainerColumn>
    </MapContainer>
  );
}
