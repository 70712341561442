import React, {useCallback, useState} from 'react';
import {Link, match, useHistory} from 'react-router-dom';
import {tripDeserialize} from 'wave-common';
import Card from '../../../components/Card';
import Collapsable from '../../../components/Collapsable';
import Delay from '../../../components/Delay';
import FontAwesome, {FontAwesomeV5} from '../../../components/FontAwesome';
import RawData from '../../../components/RawData';
import Spinner from '../../../components/Spinner';
import ViewInFirebase from '../../../components/ViewInFirebase';
import Constants from '../../../Constants';
import unwrap from '../../../functions/unwrap';
import ClaimsGuard from '../../../guards/ClaimsGuard';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';
import ChatType from '../../../models/scoopm/ChatType';
import CustomClaim from '../../../models/scoopm/CustomClaim';
import Trip from '../../../models/scoopm/Trip';
import RealtimeDatabase from '../../../references/database/RealtimeDatabase';
import Chat from './Chat';
import DispatchPanel from './dispatch-panel';
import {GeoProperties} from './dispatch-panel/index';
import LocationDetails from './LocationDetails';
import RideOverview from './RideOverview';
import TripDirectionsSelect from './TripDirectionsSelect';
import TripEzCaterEvents from './TripEzCaterEvents';
import TripEzCaterEventsOutgoing from './TripEzCaterEventsOutgoing';

// TODO:
//- show vehicle type

export default function Ride({
  match,
  minimal,
}: {
  match: match<{customerId: string; tripId: string}>;
  minimal?: boolean;
}) {
  const history = useHistory();

  const trip = useRealtimeDatabase({
    path: RealtimeDatabase.instance.trips.child(match.params.customerId).child(match.params.tripId).fullPath,
    Model: Trip,
    addKeyToModel: true,
    deserialize: tripDeserialize,
  });

  const [distancesMi, setDistancesMi] = useState<number[]>();
  const [geoProperties, setGeoProperties] = useState<GeoProperties>();

  const customerId = unwrap(trip.data, data => data.customerId);

  const onClickDelete = useCallback(
    event => {
      if (!window.confirm('Are you sure you want to permanently delete this trip?')) {
        return;
      }

      RealtimeDatabase.instance.trips
        .child(customerId)
        .child(trip.key)
        .remove()
        .then(
          () => {
            history.goBack();
            // history.push('/admin/rides')
          },
          error => {
            console.error(error);
            window.alert(`Error deleting trip. ${error.message}`);
          },
        );
    },
    [history, customerId, trip.key],
  );

  // RENDER

  if (trip.isLoading) {
    return (
      <Delay>
        <Spinner />
      </Delay>
    );
  }

  if (trip.error) {
    return (
      <>
        <Card padded>
          <p className="lead mb-0">
            <FontAwesomeV5 name="info-circle" /> Unable to load job
          </p>
        </Card>
        <ClaimsGuard claims={[CustomClaim.manageLogistics, CustomClaim.manageApi]}>
          <Card padded>
            <h6>Technical data</h6>
            <pre>{trip.error.message}</pre>
            <pre>{trip.error.stack}</pre>
          </Card>
        </ClaimsGuard>
      </>
    );
  }

  if (!trip.data) {
    return 'No data';
  }
  const tripData = trip.data;

  return (
    <div className={minimal ? '' : 'bg-light'}>
      {/* ride overview, chat */}

      <Card padded={!minimal} innerPadded={!minimal} className={minimal ? 'mb-2 mb-md-3' : ''}>
        <div className="row">
          <div className="col">
            <Card className="h-100" innerPadded={false}>
              <RideOverview trip={trip.data} tripId={trip.key!} minimal={minimal} isAdmin />
            </Card>
          </div>
          <div className="col-xl-3">
            <Chat trip={trip} chatType={ChatType.adminDriver} />
          </div>
        </div>
      </Card>

      {/* location details */}

      <div className={minimal ? '' : 'px-2 px-md-3'}>
        <LocationDetails
          trip={trip}
          distancesMi={unwrap(distancesMi, distances =>
            distances.length > Trip.prototype.locationsWithDefault.apply(trip.data).length
              ? distances.splice(0, 1)
              : distances,
          )}
          minimal={minimal}
        />
      </div>

      {!minimal && (
        <>
          {/* map */}

          <Card padded innerPadded={false}>
            <div style={{height: '600px'}}>
              <DispatchPanel
                trip={tripData}
                onChangeGeoProperties={setGeoProperties}
                onChangeDistancesMi={setDistancesMi}
              />
            </div>
            {geoProperties?.radiusKm && geoProperties.radiusKm >= Constants.maxDriverSearchRadiusKm && (
              <div className="p-2 text-right">
                <small className="text-muted">
                  <FontAwesome.InfoCircle /> Searching for drivers within the circle. If you move or zoom the map, the
                  circle will adjust accordingly
                </small>
              </div>
            )}
          </Card>

          {/* raw data */}

          <Card innerPadded={false} padded>
            <RawData data={trip.data} defaultIsCollapsed />
          </Card>

          <ClaimsGuard claim={CustomClaim.manageApi}>
            {/* http requests */}

            <Card innerPadded={false} padded>
              <Collapsable title={<pre className="mb-0">HTTP Requests</pre>} defaultIsCollapsed>
                <TripEzCaterEvents tripData={trip.data} />
              </Collapsable>
            </Card>

            {/* outgoing http requests */}

            <Card innerPadded={false} padded>
              <Collapsable title={<pre className="mb-0">Outgoing HTTP Requests</pre>} defaultIsCollapsed>
                <TripEzCaterEventsOutgoing tripData={trip.data} />
              </Collapsable>
            </Card>

            <div className="p-2 p-md-3">
              <div className="row">
                <div className="col">
                  {/* view in firebase */}

                  <ViewInFirebase
                    path={`/trips/${trip.data.customerId}/${trip.key}`}
                    firestorePath={`/trips/${trip.key}`}
                    padded={false}
                    className="d-inline-block"
                  />

                  {/* trip directions */}

                  <TripDirectionsSelect tripId={match.params.tripId} />

                  <Link
                    to={`/admin/jobs/${match.params.customerId}/${match.params.tripId}/ez-cater-map`}
                    className="btn btn-link">
                    ezCater Map
                  </Link>
                </div>

                {/* delete */}

                <div className="col-auto">
                  <button onClick={onClickDelete} className="btn btn-outline-danger">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </ClaimsGuard>
        </>
      )}
    </div>
  );
}
