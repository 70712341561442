import firebase from 'firebase/app';
import round from 'lodash/round';
import moment from 'moment';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import joinClassNames from '../../functions/joinClassNames';
import unwrap from '../../functions/unwrap';
import Property from '../types/Property';

export default function FirestoreTimestampInput(props: {
  property: Property;
  id: string;
  value: any;
  onChange: (newValue: any) => void;
  disabled: boolean;
  validationError?: Error;
}) {
  return (
    <div className="row">
      <div className="col">
        <ReactDatePicker
          id={`datePicker-${props.id}`}
          selected={unwrap(props.value, tsObject => moment(tsObject.seconds * 1000).toDate())}
          onChange={(date: Date | null) =>
            props.onChange(unwrap(date, date => new firebase.firestore.Timestamp(round(date.valueOf() / 1000), 0)))
          }
          dateFormat="Pp"
          className={joinClassNames(
            'form-control',
            unwrap(props.validationError, error => (error ? ' is-invalid' : ' is-valid')),
          )}
          showTimeSelect
          placeholderText={undefined}
          disabled={props.disabled}
          timeIntervals={5}
          autoComplete="off"
          // autoCorrect="off"
          // autoCapitalize="off"
          // spellCheck="false"
        />
      </div>
      <div className="col-auto">
        <button
          type="button"
          onClick={() => props.onChange(firebase.firestore.Timestamp.now())}
          className="btn btn-outline-primary">
          Now
        </button>
      </div>
    </div>
  );
}
