import React, {useEffect, useState} from 'react';
import {tripShortName} from 'wave-common';
import Card from '../../../components/Card';
import {environmentFirebaseConfig} from '../../../Environment';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';
import Trip from '../../../models/scoopm/Trip';
import RealtimeDatabase from '../../../references/database/RealtimeDatabase';

export default function MarketplaceList() {
  const {data, error, isLoading} = useRealtimeDatabase({path: '/marketplace'});
  const [trips, setTrips] = useState<[Trip | null, string][]>([]);

  useEffect(() => {
    Promise.all(
      Object.entries(data || ({} as any)).map(([tripId, trip]) =>
        RealtimeDatabase.instance.tripsAll.child(tripId).once(),
      ),
    ).then(setTrips as any, error => {});
  }, [data]);

  return (
    <Card padded>
      <p>
        <div>Error message: {String(error?.message)}</div>
        <div>isLoading: {String(isLoading)}</div>
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      </p>

      <h6>Trips</h6>
      <ul>
        {trips.map(([trip, tripId]) => (
          <li>
            {trip ? <a href={`/admin/jobs/${tripId}`}>{tripShortName(trip as any)}</a> : String(trip)}{' '}
            <a
              href={`https://console.firebase.google.com/u/0/project/${environmentFirebaseConfig.projectId}/database/${environmentFirebaseConfig.projectId}/data/marketplace/${tripId}`}
              className="text-muted">
              ({tripId})
            </a>
          </li>
        ))}
      </ul>
    </Card>
  );
}
