import React from 'react';
import FirebaseTable from '../../../components/FirebaseTable';
import moment from 'moment';
import EventOutgoing from '../../../models/scoopm/EventOutgoing';
import unwrap from '../../../functions/unwrap';

export default function EzCater() {
  return (
    <FirebaseTable
      title="Outgoing API Events (ezCater)"
      path="events-outgoing"
      orderByChild="path"
      equalTo="https://delivery-management.ezcater.com/api/graphql"
      limitToLast={1000}
      link={event => `/admin/events-outgoing/${event.key}`}
      linkTitle={event => JSON.stringify(event, null, '\t')}
      columns={[
        {
          heading: 'Mutation',
          value: event => EventOutgoing.mutationName(event.requestBody),
        },
        {
          heading: 'Delivery ID',
          value: event => unwrap(event.requestBody.variables, vars => vars.input.deliveryId),
        },
        {heading: 'Method', value: event => event.method},
        {heading: 'Path', value: event => event.path},
        {
          heading: 'Time',
          value: event => moment(event.timestampMs).calendar(),
        },
        {
          heading: 'Response',
          value: event => {
            if (event.responseCode >= 200 && event.responseCode < 300) {
              return (
                <span className="text-success">
                  {event.responseCode} {event.responseText}
                </span>
              );
            } else {
              return (
                <span className="text-danger">
                  {event.responseCode} {event.responseText}
                </span>
              );
            }
          },
        },
        {heading: 'Error?', value: event => (event.jsError ? 'Yes' : '')},
      ]}
      reverse
    />
  );
}
