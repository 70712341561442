import {useEffect, useState} from 'react';
import {useAuthContext} from '../contexts/AuthContext';
import RealtimeDatabase from '../references/database/RealtimeDatabase';
import MessagingService from '../services/MessagingService';
import useIsMountedRef from './useIsMountedRef';

export default function useIsAdmin() {
  const isMountedRef = useIsMountedRef();

  const [state, setState] = useState({isAdmin: undefined, error: undefined});

  const {user, error} = useAuthContext();

  useEffect(() => {
    if (user === undefined) {
      // wait for user

      return;
    } else if (error) {
      // check for error

      setState({isAdmin: null, error});
    } else if (!user) {
      // not authenticated?

      setState({isAdmin: false, error: null});
    } else {
      RealtimeDatabase.instance
        .child('admins')
        .child(user.uid)
        .once()
        .then(
          ([isAdmin]) => {
            // fetch admin
            if (!isMountedRef.current) return;

            // set state

            setState({isAdmin: Boolean(isAdmin), error: null});

            // also start messaging service

            if (isAdmin) {
              // eslint-disable-next-line no-unused-vars
              let m = MessagingService.instance;
            }
          },
          error => {
            if (!isMountedRef.current) return;

            // error

            console.error('Error determining if admin', error);
            setState({isAdmin: null, error});
          },
        );
    }
  }, [user, error, isMountedRef]);

  return state;
}
