import Axios from 'axios';
import {dmfError} from '../functions/dmflog';
import {Api} from './Api';
import firebase from 'firebase/app';

/**
 * @static @property {EmailService} instance
 */
export class EmailService {
  static instance = new EmailService();

  static type = {
    approvedScoopM: 'approved-scoopm',
    approvedScoopXl: 'approved-scoopxl',
    approvedButler: 'approved-butler',
    approvedNanny: 'approved-nanny',
    approvedTaxi: 'approved-taxi',
    approvedHeli: 'approved-heli',
    approvedRunner: 'approved-runner',
  };

  send(to, type, successHandler) {
    let uid = firebase.auth().currentUser.uid;

    // get id token
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(idToken => {
        // POST options
        let options = {
          to: to,
          type: type,
          uid,
          idToken,
        };

        // backend request
        Axios.post(Api.ScoopMEmail, options)
          .catch(e => {
            dmfError(`Error sending email:`, e);
            successHandler(false);
          })
          .then(() => {
            successHandler(true);
          });
      })
      .catch(error => {
        dmfError(`Error getting ID token:`, error);
        successHandler(true);
      });
  }
}
