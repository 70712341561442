import React, {Component} from 'react';
export class Card extends Component {
  render() {
    return (
      <div className="card limited-width mt-5 py-md-5">
        <div className="card-body">{this.props.children}</div>
      </div>
    );
  }
}
