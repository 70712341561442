"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.periodOrder = exports.periodTitle = exports.periodDescription = void 0;
const Enum_1 = require("../../type-descriptions/Enum");
const periodAll = ['P1', 'P2', 'P3'];
exports.periodDescription = (0, Enum_1.enumDescription)({
    userFacingTitle: 'period',
    userFacingValue: periodTitle,
    order: periodOrder,
    values: periodAll,
});
function periodTitle(period) {
    return periodTitles[period];
}
exports.periodTitle = periodTitle;
const periodTitles = {
    P1: 'Period 1',
    P2: 'Period 2',
    P3: 'Period 3',
};
function periodOrder(period) {
    return periodAll.indexOf(period);
}
exports.periodOrder = periodOrder;
