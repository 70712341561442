import firebase from 'firebase/app';
import sortBy from 'lodash/sortBy';
import {useEffect, useMemo, useState} from 'react';
import {unwrap} from '../functions/unwrap';
import useFirestoreListener from './useFirestoreListener';

export default function useTripDirections(tripId?: string) {
  const [directions, setDirections] = useState<firebase.firestore.DocumentSnapshot[]>();

  const reference = useMemo(
    () => unwrap(tripId, id => firebase.firestore().collection('directions').where('tripId', '==', id)),
    [tripId],
  );
  const {snapshot} = useFirestoreListener(reference);

  useEffect(() => {
    setDirections(unwrap(snapshot, snapshot => sortBy(snapshot.docs, doc => doc.data().creationTimestamp.toMillis())));
  }, [snapshot]);

  return directions;
}
