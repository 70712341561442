import {ReactNode} from 'react';
import Trip from '../../../../models/scoopm/Trip';
import {TripSource} from '../../../../models/scoopm/TripSource';
import TripStatus from '../../../../models/scoopm/TripStatus';

export default function DriverAssignGuard({trip, children}: {trip: Trip; children: any}) {
  // no data?

  if (!trip) return null;

  // trip is done?

  if (trip.isDone()) return null;

  // ez cater but not yet accepted?

  if (trip.source === TripSource.ezCater.rawValue && trip.status === TripStatus.created.rawValue) return null;

  // continue...

  return children;
}
