import React from 'react';
import {match} from 'react-router-dom';
import {DelaySpinner} from '../../components/Spinner';
import useScreenIsSmall from '../../hooks/useScreenIsSmall';
import useTrip from '../../hooks/useTrip';
import CenteredLayout from '../../layouts/CenteredLayout';
import ContainerLayout from '../../layouts/ContainerLayout';
import ChatType from '../../models/scoopm/ChatType';
import ChatComponent from '../admin/rides/Chat';

export default function Chat({match}: {match: match<{tripId: string; customerId: string}>}) {
  const {tripId, customerId} = match.params;

  const screenIsSmall = useScreenIsSmall();

  const trip = useTrip(tripId, customerId);

  if (trip.isLoading) {
    return (
      <CenteredLayout>
        <DelaySpinner />
      </CenteredLayout>
    );
  }

  if (trip.error) {
    return <CenteredLayout>Unable to load chat</CenteredLayout>;
  }

  if (!trip) {
    return null;
  }

  if (screenIsSmall) {
    return (
      <div className="p-2">
        <ChatComponent trip={trip} chatType={ChatType.riderDriver} />
      </div>
    );
  } else {
    return (
      <ContainerLayout>
        <div className="row">
          <div className="col-6 offset-3">
            <ChatComponent trip={trip} chatType={ChatType.riderDriver} />
          </div>
        </div>
      </ContainerLayout>
    );
  }
}
