import React from 'react';
import Agora from '../../../components/agora/Agora';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';
import {Link} from 'react-router-dom';

const DashcamContent = ({driverId, driver}) => (
  <div className="flex-grow-1 bg-light p-2 p-md-3">
    <div className="bg-white rounded">
      <div className="p-2 p-md-3">
        <h1>Dashcam</h1>

        {!driver.isLoading && driver.data && (
          <Link id="driverLink" to={`/admin/drivers/${driverId}`}>
            {driver.data.firstName} {driver.data.lastName}
          </Link>
        )}
      </div>

      <Agora tripIdOrDriverId={driverId} />
    </div>
  </div>
);

const Dashcam = ({match}) => {
  if (!match) throw new Error('"match" is a required prop of Dashcam. Not rendering.');

  const driver = useRealtimeDatabase({
    path: `/drivers/${match.params.driverId}`,
  });

  return <DashcamContent driverId={match.params.driverId} driver={driver} />;
};

export default Dashcam;
