import isObjectLike from 'lodash/isObjectLike';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';

export default function ColumnComponent({column, href, data}) {
  const content = useMemo(() => {
    if (column.Component)
      return (
        <column.Component
          column={column}
          rawValue={column.rawValue}
          formattedValue={column.formattedValue}
          data={data}
        />
      );
    else {
      const value = column.formattedValue || column.rawValue;
      if (isObjectLike(value)) {
        return JSON.stringify(value);
      } else {
        return value;
      }
    }
  }, [column, data]);

  const style = useMemo(() => ({width: column.width}), [column]);

  const result = useMemo(
    () =>
      href ? (
        href.startsWith('http') ? (
          <a href={href} className="firestore-column" style={style}>
            {content || <>&nbsp;</>}
          </a>
        ) : (
          <Link to={href} className="firestore-column" style={style}>
            {content || <>&nbsp;</>}
          </Link>
        )
      ) : (
        <div className="firestore-column" style={style}>
          {content}
        </div>
      ),
    [content, style, href],
  );

  return result;
}
