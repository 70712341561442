import HttpReference from '../platform/HttpReference';
import Deliveries from './Deliveries';

export default class EzCater extends HttpReference {
  constructor(path, parent) {
    super(path, parent);

    this.deliveries = new Deliveries('deliveries', this);
  }
}
