import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import AppPathname from '../AppPathname';
import Router from './router';

export default function Routes() {
  const location = useLocation();

  useEffect(() => {
    AppPathname.instance.value = location.pathname;
  }, [location]);

  return <Router />;
}
