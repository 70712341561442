import unhandledCase from '../../functions/unhandledCase';
import Enum from '../base/Enum';

export default class Workspace extends Enum {
  title() {
    switch (this) {
      case Workspace.dAndCEnterprise:
        return 'D&C Enterprise';
      case Workspace.ibgsoftware:
        return 'ibgsoftware';
      case Workspace.wave_ops:
        return 'wave_ops';
      default:
        throw unhandledCase(this);
    }
  }
}

Workspace.dAndCEnterprise = new Workspace('D_AND_C_ENTERPRISE');
Workspace.ibgsoftware = new Workspace('IBG_SOFTWARE');
Workspace.wave_ops = new Workspace('WAVE_OPS');
