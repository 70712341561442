import React from 'react';
import CreateTripForm from '../../components/TripForm/CreateTripForm';
import {TripSource} from '../../models/scoopm/TripSource';

export default function Form({history}) {
  return (
    <div className="row">
      <div className="col col-xl-10">
        <CreateTripForm source={TripSource.dispatchPortal} completionPath={() => '/dispatch'} />
      </div>
    </div>
  );
}
