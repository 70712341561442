import unhandledCase from '../../../functions/unhandledCase';
import StringEnum from '../../../models/base/StringEnum';

export default class Timezone extends StringEnum {
  static americaNewYork = 'America/New_York';
  static americaChicago = 'America/Chicago';
  static americaDenver = 'America/Denver';
  static americaLosAngeles = 'America/Los_Angeles';

  static title(timezone: Timezone) {
    switch (timezone) {
      case Timezone.americaNewYork:
        return 'Eastern (New York)';
      case Timezone.americaChicago:
        return 'Central (Chicago)';
      case Timezone.americaDenver:
        return 'Mountain (Denver)';
      case Timezone.americaLosAngeles:
        return 'Pacific (Los Angeles)';
      default:
        throw unhandledCase(timezone);
    }
  }
}
