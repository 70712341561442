import firebase from 'firebase/app';
import isEqual from 'lodash/isEqual';
import round from 'lodash/round';
import times from 'lodash/times';
import moment from 'moment';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  couponRead,
  deserializeNumber,
  exists,
  formatCurrency,
  pricingFormulaForTrip,
  pricingFormulaItemIsRelevant,
  pricingFormulaSubtotalWithMinimum,
  pricingFormulaTotal,
  PricingFormulaType,
  pricingFormulaTypeCollection,
  pricingFormulaTypeTitle,
  Trip,
  tripPricingFormulaInputValues,
  tripTypeRideshare,
  tripUpdate,
  // tripUpdate,
  unwrap,
  useStatus,
} from 'wave-common';
import {momentFormatDateTime} from 'wave-common/lib/helpers/MomentHelper';
import PricingFormula, {pricingFormulaSubtotal} from 'wave-common/lib/models/PricingFormula';
import {
  pricingFormulaTypeOverridePropertyName,
  pricingFormulaTypeTripPropertyName,
} from 'wave-common/lib/models/PricingFormulaType';
import {tripSourceCanEditEstimate} from 'wave-common/lib/models/TripSource';
import Alert from '../../../../components/Alert/Alert';
import FontAwesome from '../../../../components/FontAwesome';
import RideDetailsRow from '../../../../components/Ride/RideDetailsRow';
import {DelaySpinner} from '../../../../components/Spinner';
import {useAlert} from '../../../../contexts/AlertContext';
import {useAuthContext} from '../../../../contexts/AuthContext';
import FirestoreDataSource from '../../../../data-sources/FirestoreDataSource';
import RealtimeDatabaseDataSource from '../../../../data-sources/RealtimeDatabaseDataSource';
import joinClassNames from '../../../../functions/joinClassNames';
import ClaimsGuard from '../../../../guards/ClaimsGuard';
import useFirestoreListener from '../../../../hooks/useFirestoreListener';
import usePrevious from '../../../../hooks/usePrevious';
import useRealtimeDatabase from '../../../../hooks/useRealtimeDatabase';
import CustomClaim from '../../../../models/scoopm/CustomClaim';
import PricingFormulaItem from './PricingFormulaItem';
import Row from './Row';

export default function PricingFormulaDetails({trip, type}: {type: PricingFormulaType; trip: Trip}) {
  // trip is cloning

  const {data} = useRealtimeDatabase({path: `/trips/${trip.customerId}/${trip.id}`, addKeyToModel: false});
  const {snapshot} = useFirestoreListener(
    useMemo(() => firebase.firestore().collection('trips').doc(trip.id), [trip.id]),
  );
  const tripIsCloning = useMemo(() => {
    if (snapshot) {
      return !isEqual(snapshot.data(), data);
    } else {
      return false;
    }
  }, [snapshot, data]);

  // hooks

  const {claims} = useAuthContext();
  const {setAlert} = useAlert();

  // trip details

  const {charge, miles, minutes, tip, revenue} = useMemo(() => tripPricingFormulaInputValues(trip), [trip]);

  // has amount

  const actualAmount = useMemo(() => trip[pricingFormulaTypeTripPropertyName(type)], [type, trip]);
  const previousAmount = usePrevious(actualAmount);

  // did override

  const didOverride = useMemo(() => {
    switch (type) {
      case 'invoice':
        return trip.didOverrideEstimate;
      case 'payout':
        return trip.didOverrideDriverEarnings;
    }
  }, [type, trip]);

  // coupon

  const {
    isPending: couponIsPending,
    error: couponError,
    value: coupon,
  } = useStatus(
    React as any,
    useMemo(
      () =>
        type === 'invoice'
          ? unwrap(trip.couponCode, code => couponRead(code.toLocaleLowerCase(), RealtimeDatabaseDataSource.instance))
          : undefined,
      [type, trip.couponCode],
    ),
  );

  // formula

  const {
    isPending,
    error,
    value: formula,
    handlePromise: handleFormulaPromise,
  } = useStatus<PricingFormula>(React as any);

  useEffect(() => {
    // only refresh the formula when the actual amount changes. otherwise, the formula will refresh when the trip is changed, and then again when the auto-calculate sets the new amount
    if (actualAmount !== previousAmount) {
      handleFormulaPromise(pricingFormulaForTrip(type, trip, RealtimeDatabaseDataSource.instance, FirestoreDataSource));
    }
  }, [type, trip, actualAmount, previousAmount, handleFormulaPromise]);

  // relevant pricing items

  const relevantItems = useMemo(
    () => formula?.items.filter(item => pricingFormulaItemIsRelevant(item, charge, miles, minutes, tip, revenue)),
    [formula, charge, miles, minutes, tip, revenue],
  );

  // subtotal

  const subtotal = useMemo(
    () => unwrap(formula, it => pricingFormulaSubtotal(it, charge, miles, minutes, tip, revenue)),
    [formula, charge, miles, minutes, tip, revenue],
  );

  // subtotal with minimum

  const subtotalWithMinimum = useMemo(
    () => unwrap(formula, it => pricingFormulaSubtotalWithMinimum(it, charge, miles, minutes, tip, revenue)),
    [formula, charge, miles, minutes, tip, revenue],
  );

  // total

  const total = useMemo(
    () =>
      // wait for formula
      unwrap(formula, formula =>
        // load if payout, or if no coupon code, or if coupon has loaded
        type === 'payout' || !trip.couponCode || coupon
          ? pricingFormulaTotal(type, formula, charge, miles, minutes, tip, revenue, coupon)
          : undefined,
      ),
    [type, trip.couponCode, formula, charge, miles, minutes, tip, revenue, coupon],
  );

  // callbacks

  const setAmount = useCallback(
    (title: string, body?: string, confirmText?: string) => {
      if (!formula) return;
      setAlert(
        new Alert(
          title,
          body,
          confirmText,
          async () => {
            // calculate total amount
            const total = pricingFormulaTotal(type, formula, charge, miles, minutes, tip, revenue, coupon);
            // save it to the trip and undo the override
            await tripUpdate(
              trip.customerId,
              trip.id,
              {
                [pricingFormulaTypeTripPropertyName(type)]: total,
                [pricingFormulaTypeOverridePropertyName(type)]: null,
                cashedOutAt: type === 'payout' ? -1 : undefined,
              },
              RealtimeDatabaseDataSource.instance,
            );
          },
          'Cancel',
        ),
      );
    },
    [type, formula, charge, miles, minutes, tip, revenue, coupon, setAlert, trip],
  );

  const overrideAmount = useCallback(() => {
    setAlert(
      new Alert(
        'Enter amount',
        (
          <>
            <p>The pricing formula will no longer be used</p>
            <input id="override-amount-input" type="number" defaultValue={total} className="form-control" />
          </>
        ),
        'Save',
        async () => {
          const element = document.getElementById('override-amount-input') as HTMLInputElement;
          const number = round(deserializeNumber(Number(element.value), 0), 2);
          // save number to the trip
          await tripUpdate(
            trip.customerId,
            trip.id,
            {
              [pricingFormulaTypeTripPropertyName(type)]: number,
              [pricingFormulaTypeOverridePropertyName(type)]: true,
              cashedOutAt: type === 'payout' ? -1 : undefined,
            },
            RealtimeDatabaseDataSource.instance,
          );
        },
        'Cancel',
        undefined,
      ),
    );
    setTimeout(() => {
      (document.getElementById('override-amount-input') as HTMLInputElement).select();
    }, 500);
  }, [setAlert, trip.customerId, trip.id, type, total]);

  // render

  const strikeThroughClassName = didOverride ? 'text-decoration-line-through font-italic text-muted' : undefined;

  const amountIsEditable = useMemo(() => {
    switch (type) {
      case 'invoice':
        return trip.source && tripSourceCanEditEstimate(trip.source);
      case 'payout':
        return trip.cashedOutAt ? trip.cashedOutAt === -1 : true;
    }
  }, [type, trip.source, trip.cashedOutAt]);

  const amountPaidAtSafe = useMemo(() => {
    switch (type) {
      case 'invoice':
        return undefined;
      case 'payout':
        return unwrap(trip?.cashedOutAt, ts => (ts === -1 ? null : ts));
    }
  }, [type, trip.cashedOutAt]);

  const amountPaidAtString = useMemo(
    () => unwrap(amountPaidAtSafe, ts => momentFormatDateTime(moment(ts))),
    [amountPaidAtSafe],
  );

  // assumed number of items (for loading)
  // start with 3. update this and remember it once we've loaded everything

  const [assumedNumberOfItems, setAssumedNumberOfItems] = useState(2);
  useEffect(() => {
    if (total) {
      setAssumedNumberOfItems(
        (relevantItems?.length ?? 2) +
          (subtotal !== total ? 1 : 0) +
          (subtotal !== subtotalWithMinimum ? 1 : 0) +
          (type === 'invoice' && coupon ? 1 : 0) +
          1,
      );
    }
  }, [coupon, relevantItems, subtotal, subtotalWithMinimum, type, total]);

  return (
    <>
      {/* heading row */}

      <RideDetailsRow
        title={
          <>
            {pricingFormulaTypeTitle(type, trip.type)}
            <ClaimsGuard claim={CustomClaim.manageFinancial}>
              {formula && (
                <Link
                  to={`/admin/${pricingFormulaTypeCollection(type)}/${formula.id}`}
                  className={joinClassNames('ml-3 font-weight-normal text-gray-500', strikeThroughClassName)}>
                  {formula.name ?? `Unnamed ${pricingFormulaTypeTitle(type, trip.type)} formula`} &rarr;
                </Link>
              )}
            </ClaimsGuard>
          </>
        }>
        {/* cashed out timestamp */}

        {amountPaidAtString && (
          <span className="text-success align-self-center">
            <FontAwesome.CheckSolid /> Paid {amountPaidAtString}
          </span>
        )}

        <ClaimsGuard claim="overridePayout">
          {/* mark as paid / unpaid */}

          {type === 'payout' && (
            <button
              onClick={() => {
                setAlert(
                  new Alert(
                    'Are you sure?',
                    amountPaidAtSafe
                      ? 'Unpaid jobs may become eligible for payout from the driver app'
                      : 'Job will no longer be available for payout',
                    amountPaidAtSafe ? 'Mark as unpaid' : 'Mark as paid',
                    async () => {
                      await tripUpdate(
                        trip.customerId,
                        trip.id,
                        {
                          cashedOutAt: amountPaidAtSafe ? -1 : firebase.database.ServerValue.TIMESTAMP,
                        },
                        RealtimeDatabaseDataSource.instance,
                      );
                    },
                    'Cancel',
                  ),
                );
              }}
              className="btn btn-light btn-sm ml-2 nowrap"
              disabled={tripIsCloning}>
              {amountPaidAtSafe ? 'Mark as unpaid' : 'Mark as paid'}...
            </button>
          )}

          {amountIsEditable &&
            (isPending ? (
              <DelaySpinner small />
            ) : (
              formula && (
                <>
                  {/* edit / "remove override" button */}

                  {exists(actualAmount) && formula && (
                    <button
                      onClick={
                        didOverride
                          ? () => {
                              setAmount(
                                'Are you sure?',
                                `The ${pricingFormulaTypeTitle(
                                  type,
                                  trip.type,
                                ).toLocaleLowerCase()} will be re-calculated from the formula`,
                                'Remove override',
                              );
                            }
                          : overrideAmount
                      }
                      className="btn btn-light btn-sm text-decoration-none ml-2 nowrap"
                      disabled={tripIsCloning}>
                      {didOverride ? 'Remove override' : 'Edit'}...
                    </button>
                  )}

                  {/* "calculate" button */}

                  {!exists(actualAmount) && formula && (
                    <button
                      onClick={() => {
                        setAmount(`Calculate the ${pricingFormulaTypeTitle(type, trip.type).toLocaleLowerCase()}?`);
                      }}
                      className="btn btn-light btn-sm ml-2"
                      disabled={tripIsCloning}>
                      Calculate...
                    </button>
                  )}
                </>
              )
            ))}
        </ClaimsGuard>
      </RideDetailsRow>

      {/* actual amount? */}

      {!exists(actualAmount) ? (
        <Row title="No amount set" />
      ) : isPending ? (
        times(assumedNumberOfItems).map(i => <Row key={i} />)
      ) : error ? (
        <Row title="Unable to load" />
      ) : (
        <div className={strikeThroughClassName}>
          {/* item */}

          {relevantItems?.map((item, index) => (
            <PricingFormulaItem
              key={index}
              item={item}
              charge={charge}
              minutes={minutes}
              miles={miles}
              tip={tip}
              revenue={revenue}
            />
          ))}

          {/* subtotal */}

          {subtotal !== total && <Row title="Subtotal" amountD={subtotal} />}

          {/* minimum */}

          {subtotal !== subtotalWithMinimum && (
            <Row title={`Minimum ${formatCurrency(formula?.minimumD)}`} amountD={subtotalWithMinimum} />
          )}

          {/* coupon */}

          {type === 'invoice' && coupon && (
            <Row
              title={
                <>
                  Coupon
                  {claims && claims[CustomClaim.manageFinancial] ? (
                    <Link to={`/admin/coupon-codes/${coupon.id}`} className="ml-2 text-gray-500">
                      {coupon.id.toUpperCase()} &rarr;
                    </Link>
                  ) : (
                    ` "${coupon.id.toUpperCase()}"`
                  )}
                </>
              }
              amountD={total}
            />
          )}

          {/* total */}

          <Row
            title={
              <>
                Total
                {type === 'payout' && !strikeThroughClassName && (
                  <Link
                    to={`/admin/driver-earnings-d-logs?tripId=${trip.id}`}
                    className="ml-3 font-weight-normal text-gray-500">
                    History &rarr;
                  </Link>
                )}
              </>
            }
            amountD={couponIsPending ? <DelaySpinner small /> : couponError ? 'Unable to load coupon details' : total}
            emphasis
          />
        </div>
      )}

      {/* override */}

      {didOverride && (
        <Row
          title={
            <>
              Manual override
              {type === 'payout' && strikeThroughClassName && (
                <Link
                  to={`/admin/driver-earnings-d-logs?tripId=${trip.id}`}
                  className="ml-3 font-weight-normal text-gray-500">
                  History &rarr;
                </Link>
              )}
            </>
          }
          amountD={actualAmount}
          emphasis
        />
      )}

      {/* errors */}

      {type === 'payout' &&
        tripTypeRideshare.includes(trip.type as never) &&
        exists(actualAmount) &&
        !trip.cashedOutAt && (
          <div className="alert alert-danger">
            <FontAwesome.ExclamationCircle marginRight /> Payout isn't set up correctly. Contact development team
          </div>
        )}

      {exists(actualAmount) &&
        exists(total) &&
        actualAmount !== total &&
        !trip[pricingFormulaTypeOverridePropertyName(type)] && (
          <>
            <Row title="Actual amount" amountD={actualAmount} emphasis />
            <div className="alert alert-danger d-flex align-items-center justify-content-between">
              <span>
                <FontAwesome.ExclamationCircle marginRight /> Actual amount doesn't match formula.{' '}
              </span>
              <button
                onClick={() => setAmount('Reset amount to match formula', undefined, 'Reset amount')}
                className="btn btn-danger"
                disabled={tripIsCloning}>
                Reset amount to match formula...
              </button>
            </div>
          </>
        )}
    </>
  );
}
