import firebase from 'firebase/app';
import React, {useEffect, useState} from 'react';
import cleanForFirebase from '../../functions/cleanForFirebase';
import unwrap from '../../functions/unwrap';
import usePrevious from '../../hooks/usePrevious';
import Trip from '../../models/scoopm/Trip';
import TripForm from './TripForm';

export default function UpdateTripForm({
  tripId,
  defaultValue,
  onChangeIsPending,
  onComplete,
}: {
  tripId: string;
  defaultValue: Trip;
  onChangeIsPending?: (isPending: boolean) => void;
  onComplete: () => void;
}) {
  const [isPending, setIsPending] = useState(false);
  const previousIsPending = usePrevious(isPending);

  useEffect(() => {
    if (isPending !== previousIsPending) {
      onChangeIsPending && onChangeIsPending(isPending);
    }
  }, [isPending, previousIsPending, onChangeIsPending]);

  const onSubmit = (trip: Trip) => {
    let update: Partial<Trip> = {
      contacts: trip.contacts,
      dropOffTimestampMs: trip.dropOffTimestampMs,
      estimatedMiles: trip.estimatedMiles,
      estimatedMinutes: trip.estimatedMinutes,
      fromAddress: trip.fromAddress,
      fromCoordinate: trip.fromCoordinate,
      fromPlaceId: trip.fromPlaceId,
      fromPlaceName: trip.fromPlaceName,
      locations: trip.locations,
      orderValue: trip.orderValue,
      scheduleTimestamp: trip.scheduleTimestamp,
      toAddress: trip.toAddress,
      toCoordinate: trip.toCoordinate,
      toPlaceId: trip.toPlaceId,
      toPlaceName: trip.toPlaceName,
      type: trip.type,
      tip: trip.tip,
      orderId: trip.orderId,
      vendorId: trip.vendorId,
      vendorName: trip.vendorName,
      state: trip.state,
      status: trip.status,
    };
    unwrap(trip.estimate, estimate => (update.estimate = estimate));
    update = cleanForFirebase(update);

    if (!trip.customerId || !tripId) return console.error('Missing customer ID or trip ID');

    console.log('Updating trip...', trip.customerId, tripId, update);
    setIsPending(true);
    firebase
      .database()
      .ref(`/trips/${trip.customerId}/${tripId}`)
      .update(update)
      .then(
        () => {
          console.info('Updated trip');
          onComplete && onComplete();
        },
        error => {
          console.error('Error updating trip', error);
          window.alert(`There was an error updating the trip. "${error.message}"`);
        },
      )
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <TripForm
      defaultValue={defaultValue as any}
      onSubmit={onSubmit}
      submitButton="Save changes"
      isPending={isPending}
      isAdmin
      isUpdate
    />
  );
}
