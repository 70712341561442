import times from 'lodash/times';
import React from 'react';
import {Link} from 'react-router-dom';
import {filterBoolean, Trip} from 'wave-common';
import {tripLongName} from 'wave-common/lib/models/Trip';
import Delay from '../../../components/Delay';
import LoadingAnimation from '../../../components/LoadingAnimation';
import WhiteText from '../../../components/WhiteText';
import useHistoryTrips from '../../../hooks/useHistoryTrips';

export default function Jobs({uid}: {uid: string}) {
  const {isLoading, error, models: trips} = useHistoryTrips(5, uid);

  if (isLoading) {
    return (
      <>
        {times(5).map(i => (
          <div key={i} className="mb-1">
            <Delay childrenBeforeTimeout={<WhiteText />}>
              <LoadingAnimation />
            </Delay>
          </div>
        ))}
      </>
    );
  }

  if (error) {
    return <span>Unable to load jobs</span>;
  }

  return (
    <>
      <div className="form-group">
        {trips?.length
          ? filterBoolean(trips)?.map(trip => (
              <div key={trip.id} className="mb-1">
                <Link to={`/admin/jobs/${trip.customerId}/${trip.id}`}>{tripLongName(trip as Trip<number>)}</Link>
              </div>
            ))
          : 'None'}
      </div>
      <Link to={`/admin/jobs/customer/${uid}`}>View all &rarr;</Link>
    </>
  );
}
