import {AxiosResponse} from 'axios';
import unwrap from '../../functions/unwrap';
import ErrorReason from './ErrorReason';

export default class ApiError extends Error {
  data: any;
  reason?: ErrorReason;

  constructor(response: AxiosResponse) {
    const message =
      unwrap(response.data, data => data.message) ||
      `Bad status code: ${[response.status, response.request?.method, response.request?.url]
        .filter(Boolean)
        .join(' ')}`;
    super(message);
    this.data = response.data;
    this.reason = response.data?.reason;
  }
}
