import React from 'react';
import joinClassNames from '../functions/joinClassNames';

export default function LoadingAnimation({
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div className={joinClassNames('loading-animation', className)} {...props}>
      &nbsp;
    </div>
  );
}
