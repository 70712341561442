import React, {useEffect, useState} from 'react';
import FontAwesome from './FontAwesome';

export default function CopyButton({text, className = 'btn btn-light'}) {
  // Click count is incremented each time the user clicks copy. Every time, a timeout is set, and given that the user stops clicking, the timeout will complete and the click count and label will be reset.

  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    if (clickCount > 0) {
      let handle = setTimeout(() => {
        setClickCount(0);
      }, 5_000);
      return () => {
        clearTimeout(handle);
      };
    }
  }, [clickCount]);

  function onClick(event) {
    event.preventDefault();

    window.navigator.clipboard.writeText(text);
    setClickCount(clickCount + 1);
  }

  if (!window.navigator.clipboard) {
    return null;
  }

  return (
    <button className={className} onClick={onClick}>
      {clickCount ? (
        <>
          <FontAwesome.ClipboardCheck /> Copied
        </>
      ) : (
        <>
          <FontAwesome.Clipboard /> Copy
        </>
      )}
    </button>
  );
}
