const driverFlags = [
  {property: 'isNewRecruit', name: 'New recruit (NR)', shortName: 'NR'},
  {property: 'hasBeenContacted', name: 'Contacted'},
  {property: 'isScheduledForInterview', name: 'Scheduled for interview'},
  {property: 'isAwaitingBackgroundCheck', name: 'Awaiting Background Check'},
  {property: 'documentsAreExpired', name: 'Expired Documents'},
  {
    property: 'agreedToScoopMDriver',
    name: 'Applied through ScoopM',
    searchOnly: true,
  },
  {
    property: 'agreedToDMFRunner',
    name: 'Applied through DoMeAFavor',
    searchOnly: true,
  },
  {
    property: 'fountainApplicantId',
    name: 'Fountain applicant',
    searchOnly: true,
  },
  {
    property: 'allowPayout',
    name: 'Allow instant payout',
    searchOnly: true,
  },
  {
    property: 'autoCalculateDelayedEarnings',
    name: 'Auto delayed earnings',
    searchOnly: true,
  },
  {
    property: 'autoCalculateInstantEarnings',
    name: 'Auto instant earnings',
    searchOnly: true,
  },
  {property: 'isTestingAccount', name: 'Testing account'},
];

export default driverFlags;
