import React from 'react';
import AboutApp from './AboutApp';
import Features from './Features';
import HowItWorks from './HowItWorks';
import Intro from './Intro';
import OfficialRidesharePartner from './OfficialRidesharePartner';
import OtherServices from './OtherServices';
import Quality from './Quality';

export default function Home() {
  return (
    <div className="flex-grow-1">
      {/* <RideNow /> */}
      <Intro />
      <OfficialRidesharePartner />
      <AboutApp />
      <OtherServices />
      <Features />
      <Quality />
      <HowItWorks />
    </div>
  );
}
