import unwrap from '../../functions/unwrap';

export default class PricingFormulaItemCondition {
  constructor({input, minimumInclusive, maximumExclusive}) {
    this.input = input;
    this.minimumInclusive = minimumInclusive || null;
    this.maximumExclusive = maximumExclusive || null;
  }

  isInRange(inputValue) {
    const passesMinimum = unwrap(this.minimumInclusive, min => inputValue >= min, true);
    const passesMaximum = unwrap(this.maximumExclusive, max => inputValue < max, true);
    return passesMinimum && passesMaximum;
  }
}
