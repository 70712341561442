import firebase from 'firebase/app';
import {useMemo} from 'react';
import {useAuthContext} from '../contexts/AuthContext';
import unwrap from '../functions/unwrap';
import Trip from '../models/scoopm/Trip';
import TripStatus from '../models/scoopm/TripStatus';
import useFirestoreQueryListener from './useFirestoreQueryListener';

export default function useHistoryTrips(limit = 40, uid?: string) {
  const {user} = useAuthContext();

  const uidToUse = uid ?? user?.uid;

  const query = useMemo(
    () =>
      unwrap(uidToUse, uidToUse =>
        firebase
          .firestore()
          .collection('trips')
          .where('customerId', '==', uidToUse)
          .where(
            'status',
            'in',
            TripStatus.done().map(s => s.rawValue),
          )
          // .orderBy('completedAt', 'desc')
          // .orderBy('canceledAt', 'desc')
          .orderBy('createdAt', 'desc')
          .limit(limit),
      ),
    [limit, uidToUse],
  );

  return useFirestoreQueryListener(query, Trip);
}
