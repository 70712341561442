import {useMemo} from 'react';
import unwrap from '../functions/unwrap';
import Trip from '../models/scoopm/Trip';
import useRealtimeDatabase from './useRealtimeDatabase';

export default function useTrip(tripId?: string | null, customerId?: string | null) {
  const path = useMemo(
    () => unwrap(tripId, tripId => unwrap(customerId, customerId => `/trips/${customerId}/${tripId}`)),
    [tripId, customerId],
  );
  return useRealtimeDatabase({path, Model: Trip});
}
