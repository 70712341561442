export default class DmfError extends Error {
  constructor(callingObject, doingWhat, error) {
    super('Error ' + doingWhat + '; ' + error.message);
    // console.log(error)
  }
}

// export default class DmfError {
//     constructor(fromObject, doingWhat, error) {
//         console.log(fromObject.constructor.name + ' Error ' + doingWhat + ': ' + error.code)
//         console.log(error.message)
//     }
// }
