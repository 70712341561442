import chunk from 'lodash/chunk';
import HttpReference from '../platform/HttpReference';

export default class UsersEmails extends HttpReference {
  /**
   * Safe method.
   * Warning: Responses will be parsed and merged into one large object, keyed by the UIDs.
   * @param {Array<String>} uids User Ids
   */
  async getOver100Safe(uids) {
    const uidGroups = chunk(uids, 100);
    const responses = await Promise.all(uidGroups.map(uidGroup => this.get(uidGroup)));
    const users = Object.assign({}, ...responses.map(response => response.data));
    return users;
  }

  /**
   * @param {Array<String>} uids User IDs. The max allowed by Firebase is 100. Use `UserEmails.getOver100Safe` if more than 100
   */
  get(uids) {
    return this.request({method: 'GET', params: {uids}});
  }
}
