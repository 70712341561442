import HttpReference from '../platform/HttpReference';
import UserEmail from './UserEmail';
import UserClaims from './UserClaims';

export default class User extends HttpReference {
  constructor(path, parent) {
    super(path, parent);

    this.email = new UserEmail('email', this);
    this.claims = new UserClaims('claims', this);
  }

  setManageOwnGroups(manageOwnGroups) {
    return this.request({
      method: 'PATCH',
      data: {
        action: 'SET_MANAGE_OWN_GROUPS',
        value: manageOwnGroups,
      },
    });
  }
}
