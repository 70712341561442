import moment from 'moment';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-content">
          &copy; Wave {moment().year()} &mdash; <Link to="/credits">Credits</Link>
        </div>
      </footer>
    );
  }
}
