"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tripStatusDescription = void 0;
const TripStatus_1 = require("../../models/TripStatus");
const Enum_1 = require("../Enum");
exports.tripStatusDescription = (0, Enum_1.enumDescription)({
    userFacingTitle: 'status',
    userFacingValue: TripStatus_1.tripStatusAdminTitle,
    values: TripStatus_1.tripStatusAll,
    order: status => TripStatus_1.tripStatusAll.indexOf(status),
});
