import {useEffect} from 'react';

// From https://overreacted.io/making-setinterval-declarative-with-react-hooks/

/**
 * Calls the callback at a regular interval. Only resets when the interval number is changed.
 * A mutable reference to the callback is kept, so the latest callback will be used, while the interval can remain desipte callback changes.
 * @param {Function} callback
 * @param {Number} interval Interval, in milliseconds
 */
export default function useInterval(callback, interval, disabled = false) {
  useEffect(() => {
    if (disabled) {
      return;
    } else {
      console.info(`Using ${interval}ms interval`);
      callback();

      const handle = setInterval(function () {
        console.log(`Reached ${interval}ms interval, firing`);
        callback();
      }, interval);

      return () => {
        clearInterval(handle);
      };
    }
  }, [callback, interval, disabled]);
}
