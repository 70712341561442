import Enum from '../base/Enum';

class StreamType extends Enum {
  static riderDriver = new StreamType('RIDER_DRIVER');
  static riderHQ = new StreamType('RIDER_HQ');
  static driverHQ = new StreamType('DRIVER_HQ');
  static driverGuest = new StreamType('DRIVER_GUEST');
}

export default StreamType;
