import uniqBy from 'lodash/uniqBy';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import FontAwesome from '../../../components/FontAwesome';
import GoogleMap from '../../../components/GoogleMaps/GoogleMap';
import {boundsForLatLngs} from '../../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../../components/GoogleMaps/MapContents';
import Constants from '../../../Constants';
import joinClassNames from '../../../functions/joinClassNames';
import unwrap from '../../../functions/unwrap';
import useDriversMarkers from '../../../hooks/useDriversMarkers';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useMapContentsForTrips from '../../../hooks/useMapContentsForTrip';
import useMarkersForTrips from '../../../hooks/useMarkersForTrips';
import useTripDirections from '../../../hooks/useTripDirections';
import useTripPaths from '../../../hooks/useTripPaths';
import Driver from '../../../models/scoopm/Driver';
import Trip from '../../../models/scoopm/Trip';
import DriversPanel from '../dashboard/DriversPanel';
import TripsList from '../dashboard/TripsList';
import withJobsData from './withJobsData';

const maxJobs = 100;

function JobsMap(props: {
  trips?: Trip[];
  error?: Error;
  timezone: string;
  driverId?: string;
  onChangeDriverId: (id: string) => void;
}) {
  // refs

  const driversContainerDivRef = useRef();
  const tripsContainerDivRef = useRef();

  // state

  // trip state

  const tripEntries = useMemo(() => props.trips?.map(trip => [trip.id, trip]), [props.trips]);
  const [selectedTripId, setSelectedTripIdRaw] = useLocalStorage('DISPATCH_SELECTED_TRIP_ID');
  const [highlightedTripId, setHighlightedTripId] = useState<string>();
  const selectedTrip = useMemo(
    () => props.trips?.find(trip => trip.id === selectedTripId),
    [selectedTripId, props.trips],
  );
  const highlightedTrip = useMemo(
    () => props.trips?.find(trip => trip.id === highlightedTripId),
    [highlightedTripId, props.trips],
  );
  const selectedTrips = useMemo(() => unwrap(selectedTrip, trip => [trip]) as Trip[], [selectedTrip]);
  const mapContentsForSelectedTrip = useMapContentsForTrips(selectedTrips);
  const mapContentsForAllTrips = useMapContentsForTrips(selectedTrips ? undefined : props.trips);
  const directions = useTripDirections(selectedTripId || (highlightedTripId as any));
  const {paths} = useTripPaths({
    trip: selectedTrip || highlightedTrip,
    directions,
  } as any);

  // driver state

  const [allDrivers, setAllDrivers] = useState<Driver[]>(); // comes from drivers panel
  const [driverEntries, setDriverEntries] = useState<any[][]>(); // comes from drivers panel
  const [showDriverMarkers, setShowDriverMarkers] = useLocalStorage('DRIVERS_PANEL_SHOW_MARKERS', true as any);
  const [selectedDriverId, setSelectedDriverId] = useLocalStorage('DISPATCH_SELECTED_DRIVER_ID');
  const selectedDriver = useMemo(
    () => unwrap(selectedDriverId, id => allDrivers?.find(driver => driver.id === id)) ?? undefined,
    [allDrivers, selectedDriverId],
  );
  const driverEntriesToShowOnMap = useMemo(
    () =>
      driverEntries || selectedDriver
        ? uniqBy(
            [
              ...(showDriverMarkers ? driverEntries ?? [] : []),
              selectedDriver ? [selectedDriver.id, selectedDriver] : [],
            ],
            entry => entry[0],
          ).filter(entry => entry.length)
        : undefined,
    [driverEntries, selectedDriver, showDriverMarkers],
  );

  const driverMarkers = useDriversMarkers({
    driverEntries: driverEntriesToShowOnMap,
    onClick: useCallback(
      (id: string) => {
        setSelectedDriverId(id === selectedDriverId ? undefined : id);
      },
      [selectedDriverId, setSelectedDriverId],
    ),
    modifyMarker: useCallback(
      (marker: any) => {
        if (marker.id === selectedDriverId) {
          marker.label += ' (LIVE UPDATES)';
          marker.icon.fillColor = Constants.theme.primaryDarkColorHexString;
        }
        return marker;
      },
      [selectedDriverId],
    ),
  }) as any[];
  const mapContentsForSelectedDriver = useMemo(
    () => unwrap(selectedDriver, driver => MapContents.Coordinates.forDriver(driver, driver.id)),
    [selectedDriver],
  );

  const setSelectedTripId = useCallback(
    id => {
      if (id) {
        // if selecting a trip, try to select the driver

        const driverId = props.trips?.find(trip => trip.id === id)?.driverId;
        if (driverId) if (allDrivers?.find(driver => driver.id === driverId)) setSelectedDriverId(driverId);
      } else {
        // if unselecting a trip, try to unselect the driver also

        if (selectedDriverId === selectedTrip?.driverId) {
          setSelectedDriverId(undefined);
        }
      }

      // set state

      setSelectedTripIdRaw(id);
    },
    [props.trips, setSelectedTripIdRaw, allDrivers, setSelectedDriverId, selectedDriverId, selectedTrip],
  );

  // map contents

  const mapContents = useMemo(() => {
    if (mapContentsForSelectedTrip && mapContentsForSelectedDriver) {
      const id = `${mapContentsForSelectedTrip.id}+${mapContentsForSelectedDriver.id}` as any;
      const bounds = boundsForLatLngs(mapContentsForSelectedTrip.latLngArray()).extend(
        mapContentsForSelectedDriver.latLng,
      );
      const name = (mapContentsForSelectedTrip.name + '/' + mapContentsForSelectedDriver.name) as any;
      const padded = true;
      const mergedMapContents = new MapContents.Bounds({
        id,
        latLngBounds: bounds,
        name,
        padded,
      });
      return mergedMapContents;
    } else {
      return mapContentsForSelectedTrip || mapContentsForSelectedDriver || mapContentsForAllTrips || undefined;
    }
  }, [mapContentsForAllTrips, mapContentsForSelectedTrip, mapContentsForSelectedDriver]);

  // effect: if selected trip isn't found in trips, un-select it

  useEffect(() => {
    if (!props.trips) return; // wait
    if (selectedTrip && !props.trips.includes(selectedTrip)) setSelectedTripId(undefined);
  }, [props.trips, selectedTrip, setSelectedTripId]);

  // effect: if selected driver isn't found in drivers, un-select them.

  useEffect(() => {
    if (!allDrivers) return; // wait
    if (!allDrivers.length) return; // wait
    if (selectedDriverId && !allDrivers.find(driver => driver.id === selectedDriverId)) setSelectedDriverId(undefined);
  }, [allDrivers, selectedDriverId, setSelectedDriverId]);

  // on click marker

  const onClickMarker = useCallback(
    trip => {
      if (trip.id === selectedTripId) return setSelectedTripId(undefined); // un-select
      else return setSelectedTripId(trip.id); // select
    },
    [selectedTripId, setSelectedTripId],
  );

  const tripMarkers = useMarkersForTrips(props.trips, onClickMarker);
  const limitedMarkers = useMemo(() => tripMarkers?.slice(0, maxJobs), [tripMarkers]);

  // render

  const allMarkers = useMemo(
    () => [...(limitedMarkers || []), ...(driverMarkers || [])],
    [limitedMarkers, driverMarkers],
  );

  return (
    <div className="h-100 w-100 row no-gutters d-flex align-items-stretch justify-content-stretch bg-white">
      <div
        ref={driversContainerDivRef as any}
        className="d-none d-lg-block col-md-auto shadow-lg"
        style={{zIndex: Constants.zIndex.level1}}>
        <DriversPanel
          onChangeAllDrivers={setAllDrivers}
          onChangeDriverEntries={setDriverEntries}
          selectedDriverId={selectedDriverId}
          onChangeSelectedDriverId={setSelectedDriverId}
          driverIdForQuery={props.driverId}
          onChangeDriverIdForQuery={props.onChangeDriverId}
          additionalContent={
            <div className="col-auto">
              <OverlayTrigger
                placement="top-start"
                overlay={
                  <Tooltip id="SHOW_HIDE_MARKERS">
                    {showDriverMarkers ? 'Hide drivers from map' : 'Show all drivers on map'}
                  </Tooltip>
                }>
                <button
                  type="button"
                  onClick={event => setShowDriverMarkers(!showDriverMarkers)}
                  className={joinClassNames('btn', showDriverMarkers && 'btn-primary')}>
                  <FontAwesome.MapMarkerAltSolid />
                </button>
              </OverlayTrigger>
            </div>
          }
        />
      </div>

      <div className="col-6 col-md positon-relative">
        {/* limit warning */}

        {(props.trips?.length ?? 0) > maxJobs && (
          <div
            className="position-absolute alert alert-warning border border-warning m-2 m-md-3"
            style={{zIndex: Constants.zIndex.level1}}>
            <FontAwesome.ExclamationCircleSolid /> Too many jobs to display. This can slow down your computer. Try more
            specific filters. Showing only {maxJobs} out of {props.trips?.length} jobs
          </div>
        )}

        {/* map */}

        <GoogleMap
          mapContents={mapContents || MapContents.Bounds.unitedStates()}
          markers={allMarkers}
          gestureHandling="greedy"
          paths={paths}
        />
      </div>

      <div ref={tripsContainerDivRef as any} className="col-6 col-md-5 col-lg-4 col-xl-3 shadow">
        <TripsList
          containerDiv={tripsContainerDivRef as any}
          filteredEntries={tripEntries}
          selectedTripId={selectedTripId}
          onChangeSelectedTripId={setSelectedTripId}
          timezone={props.timezone}
          onChangeHighlightedTripId={setHighlightedTripId}
          highlightedTripId={highlightedTripId}
        />
      </div>
    </div>
  );
}

export default withJobsData(JobsMap);
