import formatCurrency from '../../functions/formatCurrency';
import CurrencyCode from '../iso/CurrencyCode';
import ObjectType from './ObjectType';
import StripeID from './StripeId';
import StripeObject from './StripeObject';

export default interface Price extends StripeObject {
  id: StripeID;
  object: ObjectType;
  currency: CurrencyCode;
  product: StripeID;
  recurring: {
    interval: 'year' | 'month' | 'week' | 'day';
    interval_count: number;
    usage_type: 'metered' | 'licensed';
  };
  transform_quantity?: {
    divide_by: number;
    round: 'up' | 'down';
  };
  unit_amount: number;
}

export function priceDescription(price: Price): string {
  return `${formatCurrency(price.unit_amount / 100, price.currency) ?? '?'} for ${
    price.transform_quantity?.divide_by
  } orders per ${price.recurring.interval_count === 1 ? '' : `${price.recurring.interval_count} `}${
    price.recurring.interval
  }`;
}
