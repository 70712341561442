import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import React from 'react';
import createPalette from '../createPalette';

export default function withTheme(Component) {
  return function ({...props}) {
    const prefersDarkMode = false; // useMediaQuery('(prefers-color-scheme: dark)')

    const theme = React.useMemo(() => createTheme({palette: createPalette(prefersDarkMode)}), [prefersDarkMode]);

    return (
      <ThemeProvider theme={theme}>
        <Component {...props} />
      </ThemeProvider>
    );
  };
}
