import firebase from 'firebase/app';
import React, {useContext, useEffect, useState} from 'react';
import {FormControl} from 'react-bootstrap';
import {MediumSpacer} from '../../components/Spacer';
import {useAuthContext} from '../../contexts/AuthContext';
import unwrap from '../../functions/unwrap';
import BootstrapModalContext from '../BootstrapModalContext';

export default function EmailPassword() {
  const {user} = useAuthContext();

  const [email, setEmail] = useState('');
  const [isSendingReset, setIsSendingReset] = useState(false);
  const [passwordResetError, setPasswordResetError] = useState<Error>();

  const [emailHasChanges, setEmailHasChanges] = useState(false);
  const [isUpdatingEmail, setIsUpdatingEmail] = useState(false);
  const [updateEmailError, setUpdateEmailError] = useState<Error>();

  const [password, setPassword] = useState('');
  const {setModalContent} = useContext(BootstrapModalContext);

  useEffect(() => {
    setEmail(unwrap(user, (user: firebase.User) => user.email) ?? '');
  }, [user]);

  function sendPasswordResetEmail(event: any) {
    event.preventDefault();

    setIsSendingReset(true);
    setPasswordResetError(undefined);
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(
        () => {
          console.log('Sent reset');
          setPasswordResetError(undefined);
        },
        error => {
          console.error(error);
          setPasswordResetError(error);
        },
      )
      .finally(() => {
        setIsSendingReset(false);
      });
  }

  function reauthenticate(event: any) {
    event.preventDefault();

    setModalContent(
      <>
        <div className="modal-header">
          <h5 className="modal-title" id="rootModalLabel">
            Enter password to make changes
          </h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <label htmlFor="reauthPasswordInput">Password</label>
          <FormControl type="password" value={password} onChange={event => setPassword(event.target.value)} />
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" onClick={changeEmailAddress} className="btn btn-primary">
            Continue
          </button>
        </div>
      </>,
    );
  }

  function changeEmailAddress(event: any) {
    event.preventDefault();

    setModalContent(null);

    setIsUpdatingEmail(true);
    setUpdateEmailError(undefined);
    firebase
      .auth()
      .currentUser?.updateEmail(email)
      .then(
        () => {
          console.log('Updated email');
          setUpdateEmailError(undefined);
        },
        error => {
          console.error(error);
          setUpdateEmailError(error);
        },
      )
      .finally(() => {
        setIsUpdatingEmail(false);
      });
  }

  const signOut = (event: any) => {
    firebase.auth().signOut();
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Reset password</label>
          <div>
            <button
              type="button"
              onClick={sendPasswordResetEmail}
              className="btn btn-primary"
              disabled={isSendingReset}>
              {passwordResetError === undefined
                ? 'Send password reset'
                : passwordResetError === null
                ? 'Sent reset'
                : 'Error'}
            </button>
          </div>
          <small className="text-muted">
            You will receive a password reset link at {user?.email ?? '-'}. Follow the link to choose a new password
          </small>
        </div>

        <div className="col-md-6">
          <form onSubmit={reauthenticate}>
            <label htmlFor="emailInput">Change email address</label>
            <div className="form-row">
              <div className="col">
                <FormControl
                  id="emailInput"
                  type="text"
                  value={email}
                  onChange={event => {
                    setEmail(event.target.value);
                    setEmailHasChanges(true);
                  }}
                  autoComplete="email"
                  className="mb-1"
                />
              </div>
              <div className="col">
                <input
                  type="submit"
                  value={
                    updateEmailError === undefined
                      ? 'Save email address'
                      : updateEmailError === null
                      ? 'Saved address'
                      : 'Error saving address'
                  }
                  className="btn btn-primary align-self-end"
                  disabled={!emailHasChanges || isUpdatingEmail}
                />
              </div>
            </div>

            <small className="text-muted d-block">This is the email address you use to sign in to your account</small>
          </form>
        </div>
      </div>

      <MediumSpacer />

      <div className="row">
        <div className="col-md-6">
          <label>Sign out</label>
          <div>
            <button onClick={signOut} className="btn btn-primary">
              Sign out
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
