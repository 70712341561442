import Enum from '../base/Enum';

export default class DeliveryCountEstimate extends Enum {
  static small = new DeliveryCountEstimate('SMALL');
  static medium = new DeliveryCountEstimate('MEDIUM');
  static large = new DeliveryCountEstimate('LARGE');
  static xl = new DeliveryCountEstimate('XL');

  title() {
    switch (this) {
      case DeliveryCountEstimate.small:
        return '0-20';
      case DeliveryCountEstimate.medium:
        return '21-50';
      case DeliveryCountEstimate.large:
        return '50-100';
      case DeliveryCountEstimate.xl:
        return '100+';
    }
  }

  order() {
    switch (this) {
      case DeliveryCountEstimate.small:
        return 0;
      case DeliveryCountEstimate.medium:
        return 1;
      case DeliveryCountEstimate.large:
        return 2;
      case DeliveryCountEstimate.xl:
        return 3;
    }
  }
}
