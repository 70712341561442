import pluralize from 'pluralize';
import {LatLng} from 'wave-common';
import unwrap from '../../functions/unwrap';
import {Item} from '../../hooks/useGeoFireQuery';
import City from '../../models/scoopm/City';
import Driver from '../../models/scoopm/Driver';
import Trip from '../../models/scoopm/Trip';
import TripLocation from '../../models/scoopm/TripLocation';
import {boundsForLatLngs, latLng, latLngBounds} from './LatLng+additions';
import Marker, {GoogleLatLng} from './Marker';

export default class MapContents {
  static Coordinates = class {
    id: string | null;
    latLng: LatLng | GoogleLatLng;
    zoom: number;
    name: string | null;

    constructor({
      id = null,
      latLng,
      zoom = 13,
      name = null,
    }: {
      id: string | null;
      zoom?: number;
      latLng: LatLng | GoogleLatLng;
      name: string | null;
    }) {
      this.id = id || null;
      this.latLng = latLng;
      this.zoom = zoom;
      this.name = name;
    }

    latLngArray() {
      return [this.latLng];
    }

    static forDriver(driver: Driver, driverId: string) {
      const id = driverId || driver.id || 'driver';
      if (!driver.coordinate) return undefined;
      const latLngObject = latLng(driver.coordinate)!;
      const zoom = 16;
      const name = Driver.prototype.fullName.apply(driver);
      return new MapContents.Coordinates({
        id,
        latLng: latLngObject,
        zoom,
        name,
      });
    }
  };

  static Bounds = class {
    id: string | null;
    latLngBounds: any;
    name: string | null;
    padded: boolean;

    constructor({
      id = null,
      latLngBounds,
      name = null,
      padded = true,
    }: {
      id?: string | null;
      latLngBounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral;
      name?: string | null;
      padded?: boolean;
    }) {
      this.id = id || null;
      this.latLngBounds = latLngBounds;
      this.name = name;
      this.padded = Boolean(padded);
    }

    latLngArray() {
      return [this.latLngBounds.getNorthEast(), this.latLngBounds.getSouthWest()];
    }

    static unitedStates() {
      return new MapContents.Bounds({
        id: 'USA',
        latLngBounds: {
          north: 49.3,
          south: 24.14,
          east: -66.51,
          west: -125.31,
        },
        name: 'United States',
      });
    }

    static unitedStatesContiguousExact() {
      return new MapContents.Bounds({
        id: 'USA',
        latLngBounds: {
          north: 49.384472, // Northwest Angle, MN
          south: 24.54409, // Key West, FL
          east: -66.949778, // West Quoddy Head, ME
          west: -124.733056, // Cape Alava, WA
        },
        name: 'United States',
      });
    }

    static charlotte() {
      return new MapContents.Bounds({
        id: 'id',
        latLngBounds: City.charlotte.latLngBounds(),
      });
    }

    static forMarkers(markers: Marker[], {id, name, padded}: {id: string; name?: string; padded?: boolean}) {
      if (!markers || !markers.length) return undefined;
      // const firstMarker = first(markers)
      let latLngBounds = new (window as any).google.maps.LatLngBounds(/*{
              ne: {
                  lat: firstMarker.position.lat(),
                  lng: firstMarker.position.lng()
              }, sw: {
                  lat: firstMarker.position.lat(),
                  lng: firstMarker.position.lng()
              }
          }*/);
      markers.forEach(marker => {
        latLngBounds = latLngBounds.extend(marker.position);
      });
      return new MapContents.Bounds({id, latLngBounds, name, padded});
    }

    static forTripBoundsAndDrivers(
      tripId: string,
      trip: Trip,
      tripBounds: any,
      driverItems: Item<Driver>[] | undefined,
      indexOfLocationToInclude: number | undefined,
    ) {
      if (!tripId || !tripBounds) {
        return undefined;
      }

      const id = [tripId, ...(driverItems || []).map((item: any) => item.key)].join('_');

      let newBounds = null;
      if (indexOfLocationToInclude) {
        const locationToInclude = unwrap(trip.locationsWithDefault(), locations => locations[indexOfLocationToInclude]);
        if (locationToInclude) {
          const coordinates = TripLocation.prototype.coordinatesLatLng.apply(locationToInclude);

          newBounds = boundsForLatLngs([coordinates]);
        }
      }
      if (!newBounds) {
        newBounds = latLngBounds(tripBounds);
      }

      let name = trip.simplestTitle();
      if (driverItems) {
        for (const item of driverItems) {
          newBounds.extend(latLng(item.coordinatesArray));
        }
        name += ` + ${
          driverItems.length > 1
            ? pluralize('driver', driverItems.length)
            : driverItems[0].value?.nickNameWithLastName() ?? ''
        }`;
      }

      return new MapContents.Bounds({
        id,
        latLngBounds: newBounds,
        name,
        padded: true,
      });
    }
  };
}
