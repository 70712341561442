import {Db} from '../../services/Db';

export class SharedRide {
  constructor(val) {
    // set isRide
    this.isRide = val.isRide;

    // set uid's
    if (this.isRide) {
      this.rideId = val.rideId;
    } else {
      this.tripId = val.tripId;
      this.customerId = val.customerId;
      this.driverId = val.driverId;
    }
  }

  setData(data) {
    if (this.isRide) {
      this.type = data.type;

      this.toCoords = data.location.dropOff.coords;
      this.toAddress = data.location.dropOff.address;
      this.toName = data.location.dropOff.name;

      this.fromCoords = data.location.pickup.coords;
      this.fromAddress = data.location.pickup.address;
      this.fromName = data.location.pickup.name;

      this.customerId = data.requester;
      this.driverId = data.runner;

      this.driver = data.driver;
      this.customer = data.customer;

      this.isComplete =
        data.status === Db.constants.ride.status.completed ||
        data.status === Db.constants.ride.status.canceledByRider ||
        data.status === Db.constants.ride.status.canceledByDriver
          ? true
          : false;
      // this.createdAt = data.createdAt
      // this.confirmedAt = data.confirmedAt
      // this.startedAt = data.startedAt
      // this.completedAt = data.completedAt
      // this.canceledAt = data.canceledAt

      // this.isCanceledByRider = data.isCanceledByRider
      // this.isCanceledByDriver = data.isCanceledByDriver
    } else {
      this.status = data.status;
      this.type = data.type;

      this.toCoords = {
        lat: data.toCoordinate[0],
        lng: data.toCoordinate[1],
      };
      this.toAddress = data.toAddress;

      this.fromCoords = {
        lat: data.fromCoordinate[0],
        lng: data.fromCoordinate[1],
      };
      this.fromAddress = data.fromAddress;

      // this.customerId = data.customerId
      this.driverId = data.driverId;

      this.driver = data.driver;
      this.customer = data.customer;

      this.createdAt = data.createdAt;
      this.confirmedAt = data.confirmedAt;
      this.startedAt = data.startedAt;
      this.completedAt = data.completedAt;
      this.canceledAt = data.canceledAt;

      this.isCanceledByRider = data.isCanceledByRider;
      this.isCanceledByDriver = data.isCanceledByDriver;

      this.isComplete =
        data.status === Db.constants.trip.status.completed ||
        data.status === Db.constants.trip.status.canceledByRider ||
        data.status === Db.constants.trip.status.canceledByDriver
          ? true
          : false;
    }
  }
}
