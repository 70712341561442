import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Card} from './Card';
import {GeneralPage} from './GeneralPage';

export class AboutPage extends Component {
  render() {
    return (
      <GeneralPage>
        <Card>
          <div className="text-center mx-auto" style={{'max-width': '450px'}}>
            <p className="h3 mb-4">About ScoopM</p>
            <Link to="/sign-up" className="btn btn-secondary mt-3 px-5">
              Sign Up Now!
            </Link>
          </div>
        </Card>
      </GeneralPage>
    );
  }
}
