import round from 'lodash/round';
import React, {useMemo} from 'react';
import GoogleMap from '../../components/GoogleMaps/GoogleMap';
import {boundsforCircle, latLng} from '../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../components/GoogleMaps/MapContents';
import {ScoopMap} from '../../components/Maps/ScoopMap';
import unwrap from '../../functions/unwrap';

export default function GeoCircleDisplay({value}) {
  const circle = useMemo(
    () => ({
      center: unwrap(value, value => unwrap(value.center, latLng)) || ScoopMap.charlotte,
      radiusM: unwrap(value, value => value.radiusM) || 10_000,
    }),
    [value],
  );

  const mapContents = useMemo(
    () =>
      new MapContents.Bounds({
        id: 'CIRCLE',
        name: 'Circle',
        latLngBounds: boundsforCircle(circle.center, circle.radiusM),
      }),
    [circle],
  );

  return (
    <div className="position-relative">
      Radius:{' '}
      {unwrap(value, value => unwrap(value.radiusM, radius => `${round(radius / 1000, 1)} kilometers`)) || 'Not set'}
      <div style={{height: '350px'}} className="mt-2">
        <GoogleMap
          mapContents={mapContents}
          circles={[circle]}
          fullscreenControl={false}
          streetViewControl={false}
          rounded
        />
      </div>
    </div>
  );
}
