import kebabCase from 'lodash/kebabCase';
import React, {ReactNode, useMemo} from 'react';
import {Container, Nav, NavItem} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import joinClassNames from '../functions/joinClassNames';
import useUriIsSelected from '../hooks/useLocationIsSelected';

export default function TabbedLayout(props: {
  rootPath: string;
  displayNames: string[];
  heading: string | undefined;
  children: ReactNode;
}) {
  return (
    <Container className="py-2 py-md-3" style={{minHeight: '800px'}}>
      <Nav className="nav-pills mb-2 mb-md-3">
        {props.heading && <h4 className="d-inline mb-0 align-self-center mr-4">{props.heading}</h4>}

        {props.displayNames.map(name => (
          <Item key={name} rootPath={props.rootPath} name={name} />
        ))}
      </Nav>

      <hr className="mt-0 mb-3" />

      {props.children}
    </Container>
  );
}

function Item(props: {rootPath: string; name: string}) {
  const path = useMemo(() => props.rootPath + '/' + kebabCase(props.name), [props.rootPath, props.name]);

  const isSelected = useUriIsSelected(path);

  return (
    <NavItem>
      <Link className={joinClassNames('nav-link', isSelected && 'active')} to={path}>
        {props.name}
      </Link>
    </NavItem>
  );
}

export function withTabbedLayout(rootPath: string, displayNames: string[], heading?: string) {
  return function (props: {children: ReactNode}) {
    return (
      <TabbedLayout rootPath={rootPath} displayNames={displayNames} heading={heading}>
        {props.children}
      </TabbedLayout>
    );
  };
}
