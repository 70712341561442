import chunk from 'lodash/chunk';
import React, {useMemo, useState} from 'react';
import StandardSpacer from '../../components/Spacer';
import Spinner from '../../components/Spinner';
import {useAuthContext} from '../../contexts/AuthContext';
import unwrap from '../../functions/unwrap';
import ClaimsGuard from '../../guards/ClaimsGuard';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import CustomClaim from '../../models/scoopm/CustomClaim';
import PropertyType from '../types/PropertyType';
import PropertyDisplay from './PropertyDisplay';
import PropertyForm from './PropertyForm';
import {PropertyHeading} from './SchemaDisplay';

export default function SchemaForm({schema, onSave, defaultValue, onCancel, isCustomerFacing = false, saveButtonText}) {
  const isMountedRef = useIsMountedRef();
  const {claims} = useAuthContext();

  const [model, setModel] = useState(defaultValue || newModel(schema));
  const [hasChanges, setHasChanges] = useState(false);
  const [validationErrors, setValidationErrors] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState();

  const onSubmit = event => {
    event.preventDefault();

    const parsedModel = {...model};

    for (const property of schema.properties) {
      // apply default values to properties which are not set

      if (property.defaultValue && !unwrap(parsedModel[property.name])) {
        parsedModel[property.name] = property.defaultValue();
      }

      // trim strings

      if (typeof parsedModel[property.name] === 'string') {
        parsedModel[property.name] = parsedModel[property.name].trim();
      }
    }

    console.log({parsedModel});

    // validate

    const validationErrors = {};

    schema.properties.forEach(property => {
      const value = parsedModel[property.name];

      if (property.isArray) {
        validationErrors[property.name] = {};

        // optional?

        if (!property.isOptional && (!value || !value.length)) {
          validationErrors[property.name] = new Error('Required');
        } else {
          // validate property

          for (let i = 0; i < (value || []).length; i++) {
            const itemValue = value[i];
            try {
              property.validate(itemValue);
            } catch (error) {
              validationErrors[property.name][i] = error;
            }
          }
          if (Object.keys(validationErrors[property.name]).length === 0) {
            delete validationErrors[property.name];
          }
        }
      } else {
        // validate property

        try {
          property.validate(value);
        } catch (error) {
          validationErrors[property.name] = error;
        }
      }
    });
    setValidationErrors(validationErrors);

    // validation errors?

    if (Object.keys(validationErrors).length) {
      console.error('Validation errors', validationErrors);
      return;
    }

    // save

    setIsSaving(true);
    onSave(parsedModel)
      .catch(error => {
        if (isMountedRef.current) {
          console.log('Is mounted');
          setError(error);
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          console.log('Is mounted');
          setIsSaving(false);
        }
      });
  };

  const onChange = property => value => {
    setHasChanges(true);
    setModel(Object.assign(newModel(schema), model, {[property.name]: value}));
  };

  const propertyChunks = useMemo(() => {
    const properties = schema.properties.filter(property => {
      if (property.customClaims) {
        if (!claims) {
          return false;
        } else {
          return property.customClaims.find(claim => claims[claim] === true);
        }
      } else {
        return true;
      }
    });
    return chunk(properties, 2);
  }, [schema, claims]);

  return (
    <form onSubmit={onSubmit} noValidate>
      {propertyChunks.map(chunk => (
        <div key={chunk[0].name} className="row">
          {chunk.map(property =>
            property.type === PropertyType.spacer ? (
              <div className="col" />
            ) : (
              <div
                key={property.name}
                className={PropertyType.isWide(property.type) || isCustomerFacing ? 'col-md-12' : 'col-md-6'}>
                {property.readonly ? (
                  <>
                    <PropertyHeading
                      property={property}
                      additionalContent={<span className="text-muted">Read-only</span>}
                    />
                    <PropertyDisplay schema={schema} property={property} value={model[property.name]} />
                    <StandardSpacer />
                  </>
                ) : (
                  <PropertyForm
                    property={property}
                    value={model[property.name]}
                    model={model}
                    onChange={onChange(property)}
                    validationError={unwrap(validationErrors, errors => errors[property.name] || false)}
                    disabled={isSaving}
                    isCustomerFacing={isCustomerFacing}
                  />
                )}
              </div>
            ),
          )}
        </div>
      ))}

      <div className="d-flex flex-column justify-content-end align-items-end">
        {error && (
          <div className="alert alert-danger">
            <i className="fa fa-exclamation-circle"></i> {error.message}
          </div>
        )}
        <div className="d-flex">
          {onCancel && (
            <button type="button" onClick={onCancel} className="btn btn-outline-secondary mr-2">
              Cancel
            </button>
          )}
          <button className="btn btn-primary" disabled={!hasChanges}>
            {isSaving ? <Spinner small /> : saveButtonText || 'Save'}
          </button>
        </div>
      </div>

      {!isCustomerFacing && (
        <ClaimsGuard claim={CustomClaim.manageApi}>
          <h5>Model data</h5>
          <pre className="w-auto bg-light rounded p-2 p-md-3">{JSON.stringify(model, null, '   ')}</pre>
        </ClaimsGuard>
      )}
    </form>
  );
}

const newModel = schema =>
  unwrap(
    schema.Model,
    Model => new Model(),
    () => ({}),
  );

// ({
//     "name": "Willy's",
//     "minimumD": 10,
//     "items": [
//        {
//           "name": "General fee",
//           "amount": "10"
//        },
//        {
//           "input": "MILES",
//           "amount": "1"
//        },
//        {
//           "input": "MINUTES",
//           "amount": "1"
//        },
//        {
//           "input": "MILES",
//           "amount": "2",
//           "conditions": [
//              {
//                 "input": "CHARGE",
//                 "minimumInclusive": 148,
//                 "maximumExclusive": null
//              }
//           ]
//        }
//     ]
//  })
