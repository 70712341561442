import {expect} from 'wave-common';
import {environmentDeserialize} from 'wave-common/lib/models/Environment';

type Environment = 'production' | 'sandbox' | 'development';

export default Environment;

export const environmentAll: Environment[] = ['production', 'sandbox', 'development'];

export const environmentVisibleInProduction: Environment[] = ['production', 'sandbox'];

export type Variables = {
  ENVIRONMENT: Environment;
  GOOGLE_CLOUD_API_KEY: string;
  NODE_SERVER_URL: string;
  SCRAPER_BASE_URL: string;
  URL: string;
};

// current environment

export const environmentCurrent = process.env.REACT_APP_ENVIRONMENT as Environment;

// current environment, compliant with Wave Common type

export const environmentCurrentWaveCommon = environmentDeserialize(environmentCurrent.toUpperCase());

// environment variable (any)

export function environmentVariable<Variable extends keyof Variables>(variable: Variable): Variables[Variable] {
  const variableName = 'REACT_APP_' + variable;
  return expect(process.env[variableName], variableName) as never as Variables[Variable];
}

// environment select

export function environmentSelect<T, Default extends T | undefined>(
  values: Partial<Record<Environment, T>> & {default?: Default},
): undefined extends Default ? T | undefined : T {
  return values[environmentCurrent] ?? (values.default as never);
}

// react url for environment

export function environmentUrlForEnvironment(environment: Environment) {
  switch (environment) {
    case 'development':
      return 'http://localhost:3000';
    case 'sandbox':
      return 'https://sandbox.scoopm.com';
    case 'production':
      return 'https://www.waveride.co';
  }
}

// firebase config

export let environmentFirebaseConfig: {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
};

switch (environmentCurrent) {
  case 'development':
  case 'sandbox':
    environmentFirebaseConfig = {
      apiKey: 'AIzaSyANedD4pjyd8XCrn_0YKm-EwhYDV0nQ4e0',
      authDomain: 'scoopm-development.firebaseapp.com',
      databaseURL: 'https://scoopm-development.firebaseio.com',
      projectId: 'scoopm-development',
      storageBucket: 'scoopm-development.appspot.com',
      messagingSenderId: '424537235246',
      appId: '1:424537235246:web:cfafbbe84d649c8411a4bf',
      measurementId: 'G-YYEXDXFXPQ',
    };
    break;
  // case 'development':
  case 'production':
    environmentFirebaseConfig = {
      apiKey: 'AIzaSyABxuuMXGiTO3OMCrcF0w7AhS_HTDAPh98',
      authDomain: 'towmasters-2f8b3.firebaseapp.com',
      databaseURL: 'https://towmasters-2f8b3.firebaseio.com',
      projectId: 'towmasters-2f8b3',
      storageBucket: 'towmasters-2f8b3.appspot.com',
      messagingSenderId: '693451317656',
      appId: '1:693451317656:web:266e062ca792e05e40cbed',
    };
}
