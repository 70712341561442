import HttpReference from '../platform/HttpReference';
import AcceptDelivery from './AcceptDelivery';
import RejectDelivery from './RejectDelivery';
import UpdatePadding from './UpdatePadding';

export default class Delivery extends HttpReference {
  constructor(path, parent) {
    super(path, parent);

    this.accept = new AcceptDelivery('accept', this);
    this.reject = new RejectDelivery('reject', this);
    this.updatePadding = new UpdatePadding('update-padding', this);
  }
}
