import Axios from 'axios';
import upperCaseFirst from 'upper-case-first';
import {dmfError, dmflog} from '../functions/dmflog';
import {Api} from './Api';

export class Twilio {
  // parse phone
  static parsePhone(phoneStringOrNumber) {
    const PNF = require('google-libphonenumber').PhoneNumberFormat;
    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
    let phoneString = String(phoneStringOrNumber);
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phoneString, 'US'); // number should be a string
    let result = phoneUtil.format(phoneNumber, PNF.E164);
    return result;
  }

  // format phone
  static formatPhone(phoneStringOrNumber) {
    const PNF = require('google-libphonenumber').PhoneNumberFormat;
    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
    let phoneString = String(phoneStringOrNumber);
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phoneString, 'US'); // number should be a string
    let result = phoneUtil.format(phoneNumber, PNF.NATIONAL);
    return result;
  }

  static async acceptedRide(phoneStringOrNumber, driver) {
    // // get requester's phone number
    // let rawPhoneNumber = ride.requesterPhone;
    // if (!ride.requesterPhone) {
    //   let requesterIsCustomer =
    //     ride.userType === Db.constants.ride.userType.customer;
    //   let ref = requesterIsCustomer
    //     ? DbRefs.customer(ride.requester)
    //     : DbRefs.driver(ride.requester);
    //   try {
    //     let snapshot = await ref.once("value");
    //     let user = snapshot.val();
    //     rawPhoneNumber = user.phone;
    //   } catch (error) {
    //     throw error;
    //   }
    // }

    const to = Twilio.parsePhone(phoneStringOrNumber);

    let driverName = `${upperCaseFirst(driver.firstName)} ${upperCaseFirst(driver.lastName)}`;
    let color = upperCaseFirst(driver.carColor);
    let year = upperCaseFirst(driver.year);
    let make = upperCaseFirst(driver.make);
    let model = upperCaseFirst(driver.model);
    let license = upperCaseFirst(driver.licensePlateNumber);

    try {
      let response = await Axios.post(Api.TwilioServer.acceptedRide, {
        to,
        driverName,
        color,
        year,
        make,
        model,
        license,
      });
      dmflog(this, response);
      return response;
    } catch (error) {
      dmfError(error);
      throw error;
    }
  }

  // customer / ride-created
  static async customerRideCreated(to) {
    let phone = Twilio.parsePhone(to);
    try {
      let response = await Axios.post(Api.TwilioServer.customerRideCreated, {
        phone,
      });
      dmflog(this, response);
      return response;
    } catch (error) {
      dmfError(error);
      throw error;
    }
  }

  // customer / driver-dispatched
  static async customerDriverDispatched(to, firstName, lastName, year, make, model, color, licensePlate) {
    let toParsed = Twilio.parsePhone(to);
    try {
      let response = await Axios.post(Api.TwilioServer.customerDriverDispatched, {
        to: toParsed,
        firstName,
        lastName,
        year,
        make,
        model,
        color,
        license: licensePlate,
      });
      dmflog(this, response);
      return response;
    } catch (error) {
      dmfError(error);
      throw error;
    }
  }

  // driver / dispatched
  static async driverDispatched(to, firstName, lastName, currentLocation, fromAddress, toAddress) {
    let toParsed = Twilio.parsePhone(to);
    try {
      let response = await Axios.post(Api.TwilioServer.driverDispatched, {
        to: toParsed,
        firstName,
        lastName,
        currentLocation,
        fromAddress,
        toAddress,
      });
      dmflog(this, response);
      return response;
    } catch (error) {
      dmfError(error);
      throw error;
    }
  }

  // customer / ride-completed
  static async customerRideCompleted(to) {
    let toParsed = Twilio.parsePhone(to);
    try {
      let response = await Axios.post(Api.TwilioServer.customerRideCompleted, {
        to: toParsed,
      });
      dmflog(this, response);
      return response;
    } catch (error) {
      dmfError(error);
      throw error;
    }
  }

  // driver / ride-completed
  static async driverRideCompleted(to) {
    let toParsed = Twilio.parsePhone(to);
    try {
      let response = await Axios.post(Api.TwilioServer.driverRideCompleted, {
        to: toParsed,
      });
      dmflog(this, response);
      return response;
    } catch (error) {
      dmfError(error);
      throw error;
    }
  }
}
