import React from 'react';
import unwrap from '../../functions/unwrap';
import Property from '../types/Property';
import PropertyType from '../types/PropertyType';
import Schema from '../types/Schema';

export default function PropertyDisplay<T, Model>({
  schema,
  property,
  value,
  model,
}: PropertyDisplayProps<T, Model>): unknown {
  return unwrap<React.FunctionComponent<PropertyDisplayProps<T, Model>>, React.ReactNode>(
    PropertyType.DisplayComponent(property.type, property.isArray) as React.FunctionComponent<
      PropertyDisplayProps<T, Model>
    >,
    Component => <Component schema={schema} property={property} value={value} model={model} />,
    () =>
      unwrap(
        PropertyType.format(property.type, value, model),
        formatted => <div style={{whiteSpace: 'break-spaces'}}>{formatted}</div>,
        None,
      ),
  );
}

export interface PropertyDisplayProps<T, Model = any> {
  schema: Schema<Model>;
  property: Property<T>;
  value: T | undefined;
  model: Model;
}

export function None() {
  return <span className="text-gray-500">None</span>;
}
