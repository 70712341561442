import $ from 'jquery';
import moment from 'moment';
import React, {MouseEvent, useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {momentFormatDateTime} from 'wave-common/lib/helpers/MomentHelper';
import ContactCard from '../../../components/ContactCard';
import DriverSelect from '../../../components/DriverSelect';
import {TripDetails} from '../../../components/Ride/TripDetails';
import {useAuthContext} from '../../../contexts/AuthContext';
import {useTitle} from '../../../contexts/TitleContext';
import unwrap from '../../../functions/unwrap';
import ClaimsGuard from '../../../guards/ClaimsGuard';
import CustomClaim from '../../../models/scoopm/CustomClaim';
import Driver from '../../../models/scoopm/Driver';
import Trip from '../../../models/scoopm/Trip';
import {TripSource} from '../../../models/scoopm/TripSource';
import TripStatus from '../../../models/scoopm/TripStatus';
import UserType from '../../../models/scoopm/UserType';
import RealtimeDatabase from '../../../references/database/RealtimeDatabase';
import AcceptDelivery from './AcceptDelivery';
import CancelTripComponent from './CancelTripComponent';
import DriverAssignGuard from './dispatch-panel/DriverAssignGuard';
import DuplicateTrip from './DuplicateTripLink';
import EditRide from './EditRide';
import PricingFormulaDetails from './pricing-formula-details';

export default function RideOverview({
  trip,
  tripId,
  minimal,
  isAdmin = false,
}: {
  tripId: string;
  trip: Trip;
  minimal?: boolean;
  isAdmin?: boolean;
}) {
  const [isChangingStatus, setIsChangingStatus] = useState(false);
  // const {claims} = useAuthContext();

  useEffect(() => {
    setTimeout(() => {
      ($('[data-toggle="tooltip"]') as any).tooltip();
    }, 100);
  }, [trip]);

  function onSlickSwipe(event: MouseEvent) {
    event.preventDefault();

    if (!window.confirm(`Advance to "${trip.statusType()?.next()?.title(trip)}"?`)) {
      return;
    }
    setIsChangingStatus(true);
    (RealtimeDatabase.instance.trips.child(trip.customerId).child(tripId) as any).advanceToNextLocation(trip).then(
      () => {
        setIsChangingStatus(false);
      },
      (error: any) => {
        window.alert(`Error changing status: "${error.message}"`);
        setIsChangingStatus(false);
      },
    );
  }

  function onSlickReverseSwipe(event: MouseEvent) {
    event.preventDefault();

    if (!window.confirm(`Reverse to "${trip.statusType()?.previous()?.title(trip)}"?`)) {
      return;
    }
    setIsChangingStatus(true);
    (RealtimeDatabase.instance.trips.child(trip.customerId).child(tripId) as any).reverseToPreviousLocation(trip).then(
      () => {
        setIsChangingStatus(false);
      },
      (error: any) => {
        window.alert(`Error changing status: "${error.message}"`);
        setIsChangingStatus(false);
      },
    );
  }

  const onChangeDriverId = useCallback(
    (driverId, driver) => {
      if (!window.confirm(driverId ? `Assign to ${Driver.prototype.fullName.apply(driver)}?` : 'Unassign trip?'))
        return;
      RealtimeDatabase.instance.batchUpdate_assignNewDriver(tripId, trip, driverId, driver);
    },
    [tripId, trip],
  );

  const universalContacts = trip.universalContacts.filter(contact => contact.userType !== UserType.driver.rawValue);

  return (
    <>
      <div className="row pr-2 mb-2 mb-xl-0">
        <div className="col-md-auto order-md-last pr-0 d-flex justify-content-end align-items-start flex-wrap">
          {isAdmin && <DuplicateTrip trip={trip} />}
          <EditRide tripId={tripId} trip={trip} minimal={minimal} />
          <AcceptDelivery trip={trip} />
          <CancelTripComponent tripId={tripId} trip={trip} />
        </div>

        <div className="col-md order-md-first">
          <Title trip={trip} />

          {/* <Subtitle trip={trip} /> */}

          {/* status, cancellation time */}

          {unwrap(TripStatus.from(trip.status) as TripStatus, status => (
            <h5 className="font-weight-normal">
              {status.titleComponent(trip)}
              {trip.canceledAt && (
                <span className="text-muted">{' ' + momentFormatDateTime(moment(trip.canceledAt))}</span>
              )}
              <span
                data-toggle="tooltip"
                data-html="true"
                title={`<div class="text-left"><h5>Possible trip statuses</h5><h6>Standard flow</h6><ol>${[
                  'Unaccepted',
                  'Unassigned',
                  'Assigned',
                  'On route to pickup',
                  'At pickup',
                  'On route to drop-off',
                  'At drop-off',
                  'Completed',
                ]
                  .map(s => `<li>${s}</li>`)
                  .join(
                    '',
                  )}</ol><h6>Other cases</h6><ul><li>${TripStatus.canceledByRider.title()}</li><li>${TripStatus.canceledByDriver.title()}</li><li>${TripStatus.tripTimedOut.title()}</li></ul></div>`}>
                <i className="fa fa-question-circle fa-xs ml-2 text-gray-400 mb-2" />
              </span>
              {trip.isAdvanceable() && (
                <button onClick={onSlickSwipe} className="btn btn-light ml-2" disabled={isChangingStatus}>
                  Swipe
                </button>
              )}
              {trip.isReversable() && (
                <button onClick={onSlickReverseSwipe} className="btn btn-link ml-2" disabled={isChangingStatus}>
                  Reverse swipe
                </button>
              )}
            </h5>
          ))}
        </div>
      </div>

      <DriverAssignGuard trip={trip}>
        <DriverSelect
          defaultText="No driver"
          value={trip.driverId}
          onChange={onChangeDriverId}
          className="custom-select d-block mb-2 w-auto"
        />
      </DriverAssignGuard>

      {/* creation, deletion times */}

      <div className="text-muted mb-2 mb-md-3">
        {trip.createdAt && 'Created ' + momentFormatDateTime(moment(trip.createdAt))}
        {unwrap(trip.sourceType() as TripSource, source => ` on ${source.title}`)}
        {unwrap(trip.createdBy, by => ` by ${by}`)}
        {trip.confirmedAt && ', dispatched ' + momentFormatDateTime(moment(trip.confirmedAt))}
        <ClaimsGuard claim={CustomClaim.manageApi}>
          {' '}
          <Link to={`/admin/trip-logs?objectId=${tripId}`} className="ml-2 text-gray-500">
            History &rarr;
          </Link>
        </ClaimsGuard>
      </div>

      <div className="row">
        <div className="col">
          {/* trip details */}

          <TripDetails trip={trip} tripId={tripId} />

          {/* invoice  */}

          <PricingFormulaDetails type="invoice" trip={trip as any} />

          {/* payout */}

          <PricingFormulaDetails type="payout" trip={trip as any} />

          {/* universal contacts */}

          {Boolean(universalContacts.length) && (
            <div className="mt-3">
              {universalContacts.map((contact, i) => (
                <ContactCard key={i} contact={contact} />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function Title({trip}: {trip: Trip}) {
  const {claims} = useAuthContext();

  const vendorName = trip.vendorName;
  const sourceName = unwrap(TripSource.from(trip.source) as TripSource, source => source.title);
  const orderId = trip.orderId || trip.ezCaterOrderIdFormatted();

  useTitle(vendorName || orderId ? [vendorName, orderId].filter(Boolean).join(' ') : trip.title());

  if (vendorName || sourceName || orderId) {
    return (
      <>
        <h5 className="text-muted mb-1 font-weight-normal">{trip.title()}</h5>
        <h2>
          {/* vendor or source name */}

          {vendorName ? (
            claims && claims[CustomClaim.manageFinancial] ? (
              <Link to={`/admin/vendors/${trip.vendorId}`}>{vendorName}</Link>
            ) : (
              vendorName
            )
          ) : (
            sourceName
          )}

          {/* order id */}

          {unwrap(orderId, id => ` ${id}`)}
        </h2>
      </>
    );
  } else {
    return <h2>{trip.title()}</h2>;
  }
}

// function Subtitle({ trip }) {
//     return unwrap(trip.store)
// }
