import TripModel from '../../models/scoopm/Trip';
import TripStatus from '../../models/scoopm/TripStatus';
import HttpReference from '../platform/HttpReference';
import Trips from './Trips';

export default class Trip extends HttpReference<TripModel, void, Trips> {
  async cancel() {
    return this.update({
      status: TripStatus.canceledByRider.rawValue,
    });
  }

  async update(data: object) {
    return this.request({
      method: 'PATCH',
      data,
    });
  }
}
