import React, { Component } from 'react';
import { LegalText } from './LegalText';

export class PrivacyPolicy extends Component {
  render() {
    return <LegalText>{this.theTerms()}</LegalText>;
  }

  theTerms() {
    return (
      <div>
        <p>&nbsp;</p>

        <h1 className="text-center">Wave Privacy Policy & Driver Statement</h1>
        
        <h2>Introduction</h2>
        <p>Wave is committed to safeguarding the privacy of our service users; this policy sets out how we will treat your personal information. We understand the importance of privacy and want to be transparent about how we collect, use, and share information about you. This policy is intended to help you understand:</p>
        <ul>
          <li>The types of personal information we collect and why we collect it.</li>
          <li>How we use and protect your personal information.</li>
          <li>Your rights regarding your personal information and how you can exercise them.</li>
        </ul>

        <h2>Data Collection and Use</h2>
        <h3>Information You Provide to Us</h3>
        <p>We collect information you provide directly to us. For example, we collect information when you create an account, complete a form, or communicate with us. The types of information we may collect include your name, email address, postal address, phone number, payment information, and any other information you choose to provide.</p>
        
        <h3>Information We Collect Automatically When You Use Our Services</h3>
        <p>When you access or use our services, we automatically collect information about you, including:</p>
        <ul>
          <li><strong>Log Information:</strong> We log information about your use of our services, including the type of browser you use, access times, pages viewed, your IP address, and the page you visited before navigating to our services.</li>
          <li><strong>Device Information:</strong> We collect information about the computer or mobile device you use to access our services, including the hardware model, operating system and version, unique device identifiers, and mobile network information.</li>
          <li><strong>Location Information:</strong> We may collect information about the location of your device each time you access or use one of our mobile applications or otherwise consent to the collection of this information.</li>
          <li><strong>Information Collected by Cookies and Other Tracking Technologies:</strong> We use different technologies to collect information, including cookies and web beacons. Cookies are small data files stored on your hard drive or in device memory that help us improve our services and your experience, see which areas and features of our services are popular, and count visits.</li>
        </ul>

        <h3>Information We Collect From Other Sources</h3>
        <p>We may also obtain information from other sources and combine that with information we collect through our services. For example, if you create or log into your Wave account through a third-party platform (like Facebook), we will have access to certain information from that service, such as your name and account information, in accordance with the authorization procedures determined by such third-party platform.</p>

        <h2>Use of Personal Information</h2>
        <p>We use the information we collect about you for various purposes, including to:</p>
        <ul>
          <li>Provide, maintain, and improve our services, including to process transactions, develop new products and services, and manage the performance of our services;</li>
          <li>Personalize the services and provide advertisements, content, or features that match user profiles or interests;</li>
          <li>Process and deliver contest entries and rewards;</li>
          <li>Respond to your comments, questions, and requests, and provide customer service;</li>
          <li>Communicate with you about services, offers, promotions, rewards, and events offered by Wave and others, and provide news and information we think will be of interest to you;</li>
          <li>Monitor and analyze trends, usage, and activities in connection with our services;</li>
          <li>Detect, investigate, and prevent fraudulent transactions and other illegal activities and protect the rights and property of Wave and others;</li>
          <li>Link or combine with information we get from others to help understand your needs and provide you with better service; and</li>
          <li>Carry out any other purpose described to you at the time the information was collected.</li>
        </ul>

        <h2>Sharing of Personal Information</h2>
        <p>We may share information about you as follows or as otherwise described in this Privacy Policy:</p>
        <ul>
          <li>With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf;</li>
          <li>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, or legal process;</li>
          <li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of Wave or others;</li>
          <li>In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company;</li>
          <li>Between and among Wave and our current and future parents, affiliates, subsidiaries, and other companies under common control and ownership; and</li>
          <li>With your consent or at your direction.</li>
        </ul>
        <p>We also may share aggregated or de-identified information, which cannot reasonably be used to identify you.</p>

        <h2>Protection of Personal Information</h2>
        <p>Wave takes reasonable measures to help protect information about you from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. Our security measures include:</p>
        <ul>
          <li>Using encryption where appropriate;</li>
          <li>Using secure communication channels such as HTTPS for data transmission;</li>
          <li>Implementing access control measures to ensure only authorized personnel have access to your personal information;</li>
          <li>Conducting regular security assessments and penetration testing;</li>
          <li>Maintaining policies and procedures for handling security breaches.</li>
        </ul>
        <p>Despite our efforts, we cannot guarantee the absolute security of your personal information. We encourage you to take steps to protect your personal information, including keeping your passwords confidential and using secure networks when transmitting data.</p>

        <h2>User Rights and Choices</h2>
        <p>Users have certain rights regarding their personal information collected by Wave. These rights include:</p>
        <ul>
          <li><strong>Access to Your Information:</strong> You have the right to request a copy of your personal information we hold.</li>
          <li><strong>Rectification:</strong> If you believe any information we hold about you is incorrect or incomplete, you have the right to request we correct or complete it.</li>
          <li><strong>Deletion:</strong> You have the right to request that we delete your personal information under certain conditions.</li>
          <li><strong>Restriction:</strong> You have the right to request that we restrict the processing of your personal information, under certain conditions.</li>
          <li><strong>Object to Processing:</strong> You have the right to object to our processing of your personal information, under certain conditions.</li>
          <li><strong>Data Portability:</strong> You have the right to request that we transfer the data we have collected to another organization, or directly to you, under certain conditions.</li>
        </ul>
        <p>To exercise any of these rights, please contact us at support@waveride.co. We may require you to verify your identity before responding to such requests.</p>

        <h2>Data Retention</h2>
        <p>We retain your personal information only for as long as is necessary for the purposes set out in this privacy policy, for as long as your account is active (i.e., for the lifetime of your Wave account), or as needed to provide services to you. If you no longer want Wave to use your information to provide services to you, you can request that we close your account and delete your personal information. We will retain and use your information to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>

        <h2>International Data Transfers</h2>
        <p>We may transfer, store, and process your information in countries other than your own. Our corporate affiliates and service providers may be located in countries with different data protection laws than your country. We take all reasonable precautions to ensure that your data is treated securely and in accordance with this privacy policy and no transfer of your personal information will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

        <h2>Changes to This Privacy Policy</h2>
        <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the "Effective Date" at the top of this privacy policy. You are advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page.</p>

        <h2>Contact Information</h2>
        <p>If you have any questions about this privacy policy, the practices of this site, or your dealings with this website, please contact us at support@waveride.co, or by mail at:</p>
        <p>333 West Trade St., STE 100, Charlotte, NC 28202</p>
        <p>We value your privacy and are committed to protecting your personal data. Understanding and exercising your rights regarding your personal information is important to us.</p>

        <p>We regularly review our compliance with this privacy policy. When we receive formal written complaints, we will contact the person who made the complaint to follow up. We work closely with the relevant regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of personal data that we cannot resolve with our users directly.</p>

        <h2>Updates to Our Privacy Policy</h2>
        <p>We reserve the right to update this privacy policy at any time to reflect changes in our practices, service offerings, or legal or regulatory requirements. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with more prominent notice (such as adding a statement to our homepage or sending you a notification directly). We encourage you to review the privacy policy whenever you access our services to stay informed about our information practices and the ways you can help protect your privacy.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions or comments about this privacy policy, please contact us at:</p>
        <ul>
            <li>Email: support@waveride.co</li>
        </ul>
        <p>Your feedback is important to us as we strive to improve our services and safeguard your privacy.</p>

        <h2>Effective Date</h2>
        <p>This privacy policy is effective as of January 1, 2024, and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>


        <h2 style = {{textAlign: 'center'}}>Driver Representations and Warranties</h2>
        <p>As a driver on the Wave platform, you make the following representations and warranties to us and agree to abide by them:</p>
        
        <h3>Licensing and Legal Compliance</h3>
        <p>You affirm that you have a valid driver's license and are authorized to operate a motor vehicle in the jurisdictions where you intend to provide transportation services. You warrant that you will comply with all local, state, and federal laws applicable to the operation of a motor vehicle and the provision of transportation services.</p>

        <h3>Vehicle Condition and Standards</h3>
        <p>You guarantee that any vehicle you use for providing services on the Wave platform is in good operating condition and complies with all applicable industry safety standards and regulations. You are responsible for ensuring that your vehicle meets the legal requirements for a vehicle of its kind in every jurisdiction in which you provide services.</p>

        <h3>Insurance</h3>
        <p>You declare that you have a valid policy of liability insurance that meets or exceeds the minimum legal requirements for the vehicle and type of transportation services you are providing. This insurance policy must cover any damages or injuries caused while you are providing services on the Wave platform.</p>

        <h3>Safe Operation and Conduct</h3>
        <p>You commit to operating your vehicle in a safe and lawful manner at all times while providing services on the Wave platform. This includes adhering to all traffic laws, not engaging in reckless driving behaviors, and ensuring the safety and comfort of your passengers.</p>

        <h3>Accurate and Truthful Information</h3>
        <p>You agree to provide accurate and truthful information to Wave and its users, including but not limited to your identity, qualifications, availability, and the condition of your vehicle. You will not misrepresent your services or engage in fraudulent activities.</p>

        <h3>Non-Discrimination Policy</h3>
        <p>You adhere to Wave’s non-discrimination policy, agreeing to provide services to all users without discrimination based on race, color, religion, national origin, sex, marital status, physical or mental disability, sexual orientation, or age. This includes making reasonable accommodations for passengers with disabilities.</p>

        <h3>Reporting Obligations</h3>
        <p>In the event of any incidents, accidents, or safety concerns while using the Wave platform, you agree to report these issues to Wave and your insurance provider in a timely and comprehensive manner, in accordance with Wave's policies and applicable laws.</p>

        <h3>Tax Compliance</h3>
        <p>You are solely responsible for reporting and paying any taxes arising from earnings generated through the Wave platform. You agree to comply with all applicable tax laws and regulations.</p>

        <h2>Suspensions and Communication for Policy Violations</h2>
        <p>Wave is committed to maintaining a safe, respectful, and lawful environment for both drivers and riders. To uphold these standards, we have established clear policies and expectations for driver conduct. Non-compliance with these policies may result in disciplinary action, up to and including suspension or termination of access to the Wave platform.</p>
        
        <h3>Grounds for Suspension</h3>
        <p>Violations that may lead to suspension include, but are not limited to:</p>
        <ul>
          <li>Failure to maintain valid licensing or insurance;</li>
          <li>Providing false or misleading information to Wave or its users;</li>
          <li>Engaging in discriminatory behavior or harassment;</li>
          <li>Violating local, state, or federal laws while using the Wave platform;</li>
          <li>Unsafe driving practices or failure to comply with traffic regulations;</li>
          <li>Failure to report incidents, accidents, or safety concerns in a timely manner.</li>
        </ul>

        <h3>Communication of Suspension</h3>
        <p>In the event of a suspension for policy violations, Wave will communicate the decision to the affected driver in the following manner:</p>
        <ul>
          <li><strong>Notification:</strong> Drivers will receive an initial notification of suspension via email or in-app message. This notification will include details of the policy violation, the duration of the suspension, and any required actions to lift the suspension.</li>
          <li><strong>Explanation:</strong> We believe in transparency and will provide a clear explanation of the reasons for the suspension, including specific examples of the violation(s) when possible.</li>
          <li><strong>Appeal Process:</strong> Drivers will have the opportunity to appeal the suspension. The initial notification will include information on how to initiate an appeal, along with any relevant deadlines.</li>
          <li><strong>Support:</strong> Wave offers support to suspended drivers seeking clarification or assistance. Contact information for driver support will be included in the suspension notification.</li>
        </ul>

        <h3>Reinstatement</h3>
        <p>Following a suspension, drivers may be eligible for reinstatement, provided they have taken corrective action to address the violations. Reinstatement is contingent upon a review of the driver’s account and actions, and is at the discretion of Wave.</p>
        <ul>
          <li>Drivers seeking reinstatement are encouraged to contact Wave support for guidance on the process and requirements.</li>
          <li>Reinstatement may require the completion of additional training, submission of updated documentation, or other measures to ensure compliance with Wave policies.</li>
        </ul>

        <p>Wave is dedicated to working with our driver community to promote a positive and safe experience. By adhering to these policies, drivers contribute to the integrity and trustworthiness of the Wave platform.</p>

        <p>By agreeing to these terms, you understand that failure to comply with any of these representations and warranties may result in the termination of your access to the Wave platform and other legal or remedial actions.</p>
        <h3 className='text-center'>ADDITIONAL DISCLOSURES </h3>
        <p>ADDITIONAL DISCLOSURES IN ADDITION TO THE TERMS SPECIFIED ABOVE, YOU ALSO AGREE THAT WHILE OPERATING ON THE DIGITAL NETWORK OR SOFTWARE APPLICATION OF THE COMMERCIAL TRANSPORTATION SERVICES PROVIDER, YOUR PRIVATE PASSENGER AUTOMOBILE INSURANCE POLICY MIGHT NOT AFFORD LIABILITY, UNDERINSURED MOTORIST, PERSONAL INJURY PROTECTION, COMPEREHENSIVE, OR COLLISION COVERAGE, DEPENDING ON THE TERMS OF THE POLICY.

IF THE VEHICLE THAT YOU PLAN TO USE TO PROVIDE COMMERICIAL TRANSPORTATION SERVICES FOR Wave Mobile Technologies HAS A LIEN AGAINST IT, YOU MUST NOTIFY THE LIENHOLDER THAT YOU YOU WILL BE USING THE VEHICLE FOR COMMERCIAL TRANSPORTATION SERVICES WHICH MAY VIOLATE THE TERMS OF YOUR CONTRACT WITH THE LIENHOLDER.</p>
        <p className="text-center"><strong>Last Updated Date: April 9, 2024</strong></p>
        <p className="text-center"><strong>© Wave Mobile Technologies 2024 - All Rights Reserved</strong></p>
      </div>
    );
  }
}
