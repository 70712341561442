import firebase from 'firebase/app';
import first from 'lodash/first';
import last from 'lodash/last';
import round from 'lodash/round';
import Constants from '../../Constants';
import unwrap from '../../functions/unwrap';
import DirectionsService from '../../services/Directions';
import Place from './Place';
import PricingFormulaInput from './PricingFormulaInput';
import PricingFormulaItem from './PricingFormulaItem';
import Trip from './Trip';
import TripSourceDetail from './TripSourceDetail';
import TripType from './TripType';
import TripTypeDetail from './TripTypeDetail';
import Vendor from './Vendor';

export default class PricingFormula {
  constructor({name, items, minimumD}) {
    this.name = name;
    this.items = items;
    this.minimumD = minimumD;
  }

  calculate(charge, miles, minutes, tip, revenue) {
    // start

    // console.log(`${this.name || 'Unnamed formula'}: calculate(charge: ${charge}, miles: ${miles}, minutes: ${minutes}, tip: ${tip}, revenue: ${revenue})`)

    let valueD = 0;

    // filter relevant items

    const relvantItems = this.items.filter(item => {
      if (item.input && !PricingFormulaInput.value(item.input, charge, miles, minutes, tip, revenue)) {
        // input but no input value
        return false;
      }
      if (!PricingFormulaItem.prototype.passesConditions.apply(item, [charge, miles, minutes, tip, revenue])) {
        // doesn't pass conditions
        return false;
      }
      return true;
    });

    // console.log(`Using ${relvantItems.length} relevant items ouf of ${this.items.length}`)

    // apply items

    relvantItems.forEach(item => {
      // add?

      if (!item.input) {
        const valueDBefore = valueD;
        valueD += item.amount;
        // console.log(`$${valueDBefore} + $${item.amount} = $${valueD}`)
      }

      // multiply
      else {
        let value = PricingFormulaInput.value(item.input, charge, miles, minutes, tip, revenue);

        // apply only within range?
        unwrap(item.conditions, conditions =>
          unwrap(
            conditions.find(condition => condition.applyRangeToInputValue),
            condition => {
              if (condition.maximumExclusive) {
                // if max limit, set price to max possible
                value = condition.maximumExclusive - 0.01;
              }
              if (condition.minimumInclusive) {
                // if min limit, subtract that from the input's value
                value -= condition.minimumInclusive;
              }
              if (value < 0) {
                // check for weird behavior
                throw new Error("Conditional item's value is non-positive");
              }
            },
          ),
        );

        // multiply value by input amount

        const valueDBefore = valueD;
        valueD += value * item.amount;
        // console.log(`$${valueDBefore} + (${value} ${item.input.toLowerCase()} x $${item.amount}) = $${valueD}`)
      }
    });

    // check minimum

    if (this.minimumD && valueD < this.minimumD) {
      // console.log(`Increasing $${valueD} total to $${this.minimumD} minimum`)
      valueD = this.minimumD;
    }

    // done

    // console.log(`Total: $${valueD}\n----------`)

    return valueD;
  }

  async calculateForTrip(trip) {
    const charge = trip.orderValue || null;

    let miles = trip.estimatedMiles || 0;
    let minutes = trip.estimatedMinutes || 0;

    const locations = trip.locationsWithDefault();
    if ((!miles || !minutes) && locations.length > 1 && !locations.includes(null)) {
      if (!miles || !minutes) {
        const directions = await DirectionsService.getDirections(
          first(locations).coordinates,
          null,
          last(locations).coordinates,
        );
        const leg = directions.routes[0].legs[0];
        miles = round(leg.distance.value * Constants.metersPerMile, 2);
        minutes = round(leg.duration.value / 60, 2);
      }
    }

    for (const location of locations) {
      if (location && location.waitTimeS) {
        minutes += location.waitTimeS / 60;
      }
    }

    const tip = trip.tip || null;

    const revenue = unwrap(trip.estimate, null, 0) + unwrap(trip.tip, null, 0); // revenue

    return this.calculate(charge, miles, minutes, tip, revenue);
  }
}

PricingFormula.withId = async (collection, id) => {
  const snapshot = await firebase.firestore().collection(collection).doc(id).get();
  return snapshot.exists ? new PricingFormula(snapshot.data()) : null;
};

PricingFormula.fetch = async (
  formulaIdPropertyName,
  collection,
  uid,
  pickupCoordinatesArray,
  pickupName = null,
  tripSource = null,
  tripType,
) => {
  // try by user ID

  const snapshot =
    uid && (await firebase.database().ref('users').child(uid).child(formulaIdPropertyName).once('value'));
  if (snapshot && snapshot.exists()) {
    console.log(`Using formula "${snapshot.val()}" for user "${uid}"`);
    return await PricingFormula.withId(collection, snapshot.val());
  }

  // try by place

  const placeSnapshot = pickupCoordinatesArray && (await Place.nearCoordinatesArray(pickupCoordinatesArray));
  if (placeSnapshot && placeSnapshot.get(formulaIdPropertyName)) {
    console.log(
      `Using formula ${placeSnapshot.get(formulaIdPropertyName)} for place "${placeSnapshot.get('name') || '??'}"`,
    );
    return await PricingFormula.withId(collection, placeSnapshot.get(formulaIdPropertyName));
  }

  // try by vendor

  const vendor = pickupName && (await Vendor.withName(pickupName));
  if (vendor && vendor[formulaIdPropertyName]) {
    console.log(`Using formula "${vendor[formulaIdPropertyName]}" for vendor "${vendor.name}"`);
    return await PricingFormula.withId(collection, vendor[formulaIdPropertyName]);
  }

  // try by trip source

  const tripSourceDetail = tripSource && (await TripSourceDetail.fetch(tripSource));
  if (tripSourceDetail && tripSourceDetail[formulaIdPropertyName]) {
    console.log(`Using formula "${tripSourceDetail[formulaIdPropertyName]}" for trip source "${tripSource}"`);
    return await PricingFormula.withId(collection, tripSourceDetail[formulaIdPropertyName]);
  }

  // try by trip type

  const tripTypeDetail = await TripTypeDetail.fetch(tripType);
  if (tripTypeDetail && tripTypeDetail[formulaIdPropertyName]) {
    console.log(`Using formula "${tripTypeDetail[formulaIdPropertyName]}" for trip type "${tripType}"`);
    return await PricingFormula.withId(collection, tripTypeDetail[formulaIdPropertyName]);
  }

  // fall back to rideshare (only if system hasn't been set up properly)

  const rideshareTripTypeDetail = await TripTypeDetail.withId(TripType.scoopM.rawValue);
  if (!rideshareTripTypeDetail || !rideshareTripTypeDetail[formulaIdPropertyName]) {
    throw new Error(
      'Pricing formulas are not set up. You at least need one for "scoopM". Also add driver payout formula(s)',
    );
  }
  console.log(
    `Falling back to formula "${rideshareTripTypeDetail[formulaIdPropertyName]}" for default trip type "${TripType.scoopM.rawValue}"`,
  );
  return await PricingFormula.withId(collection, rideshareTripTypeDetail[formulaIdPropertyName]);
};
