import moment from 'moment';
import pluralize from 'pluralize';
import unwrap from './unwrap';

export default class MomentHelper {
  static ISO_WEEKDAY_MONDAY = 1;
  static ISO_WEEKDAY_TUESDAY = 2;
  static ISO_WEEKDAY_WEDNESDAY = 3;
  static ISO_WEEKDAY_THURSDAY = 4;
  static ISO_WEEKDAY_FRIDAY = 5;
  static ISO_WEEKDAY_SATURDAY = 6;
  static ISO_WEEKDAY_SUNDAY = 7;

  static ifValid<T>(moment: moment.Moment, callback: (moment: moment.Moment) => T) {
    if (!moment.isValid()) return undefined;
    return callback(moment);
  }

  static daysHoursMinues(duration: moment.Duration): string {
    return [
      stringOrFalse(duration.days(), 'day'),
      stringOrFalse(duration.hours(), 'hour'),
      stringOrFalse(duration.minutes(), 'minute'),
    ]
      .filter(Boolean)
      .join(' ');
  }

  static daysHoursMinutesFromSeconds(seconds: number) {
    return this.daysHoursMinues(moment.duration(seconds, 'seconds'));
  }

  static roundUpMinutes(m1: moment.Moment, minutes: number) {
    const remainder = minutes - (m1.minutes() % minutes);
    return moment(m1).add(remainder, 'minutes');
  }

  static durationString(duration: moment.Duration) {
    return [
      unwrap(duration.years(), v => (v === 0 ? false : `${v}yr`)),
      unwrap(duration.months(), v => (v === 0 ? false : `${v}mon`)),
      unwrap(duration.days(), v => (v === 0 ? false : `${v}d`)),
      unwrap(duration.hours(), v => (v === 0 ? false : `${v}h`)),
      unwrap(duration.minutes(), v => (v === 0 ? false : `${v}m`)),
    ]
      .filter(Boolean)
      .slice(0, 2)
      .join(' ');
  }
}

function stringOrFalse(value: number, unitString: string) {
  if (value) {
    // i.e., if not zero
    return `${value} ${pluralize(unitString, value)}`;
  } else {
    return false;
  }
}
