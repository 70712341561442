import Property from '../types/Property';
import PropertyType from '../types/PropertyType';
import Schema from '../types/Schema';

const tripTemplateSchema = new Schema('trip-template', [
  new Property('name', PropertyType.string),
  new Property('type', PropertyType.tripType, {isOptional: true}),
  new Property('vendorId', PropertyType.firestoreId, {isOptional: true}),
  new Property('locations', PropertyType.tripLocation, {
    isOptional: true,
    isArray: true,
  }),
]);

export default tripTemplateSchema;
