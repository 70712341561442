import Property from '../types/Property';
import PropertyType from '../types/PropertyType';
import Schema from '../types/Schema';

const deviceSchema = new Schema('device', [
  new Property('userType', PropertyType.userType, {
    isOptional: true,
    readonly: true,
    userFacingName: 'User/app type',
  }),
  new Property('type', PropertyType.deviceType, {
    readonly: true,
    userFacingName: 'Device/system type',
  }),
  new Property('make', PropertyType.string, {
    isOptional: true,
    readonly: true,
  }),
  new Property('model', PropertyType.string, {
    isOptional: true,
    readonly: true,
  }),
  new Property('lastUsedTimestampMs', PropertyType.timestamp, {
    readonly: true,
    userFacingName: 'Last used',
  }),
  new Property('isSignedIn', PropertyType.boolean, {readonly: true}),
  new Property('uid', PropertyType.uid, {
    readonly: true,
    userFacingName: 'User',
  }),
  new Property('creationTimestampMs', PropertyType.timestamp, {
    readonly: true,
    userFacingName: 'Created at',
  }),
  new Property('deviceUniqueId', PropertyType.string, {
    readonly: true,
    userFacingName: 'Device UID',
  }),
  new Property('fcmRegistrationToken', PropertyType.string, {
    isOptional: true,
    readonly: true,
    userFacingName: 'FCM registration token',
  }),
]);

export default deviceSchema;
