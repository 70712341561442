import firebase from 'firebase/app';
import first from 'lodash/first';
import React, {useState} from 'react';
import {Button, FormControl} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import ValidateableInput from '../../../components/bootstrap/ValidateableInput';
import Card from '../../../components/Card';
import StandardSpacer from '../../../components/Spacer';
import Order from '../../../models/ezCater/Order';
import Trip from '../../../models/scoopm/Trip';

export default function Find() {
  return (
    <Card padded>
      <h3>Quick Job Lookup</h3>
      <hr />

      <Row>
        <Column>
          <Section name="Order ID" fieldPath="orderId" />
        </Column>
      </Row>

      <StandardSpacer />

      <Row>
        <Column>
          <Section name="ezCater delivery ID" fieldPath="ezCaterDeliveryId" />
        </Column>
      </Row>
    </Card>
  );
}

function Row(props: {children: React.ReactNode}) {
  return <div className="row">{props.children}</div>;
}

function Column(props: {title?: string; children: React.ReactNode}) {
  return (
    <div className="col-md-6 col-lg-4">
      {props.title && <h5>{props.title}</h5>}
      {props.children}
    </div>
  );
}

function Section(props: {name: string; fieldPath: string}) {
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsSearching(true);
    setError(undefined);

    find(props.fieldPath, searchValue)
      .then(trips => {
        const trip = first(trips)!;
        const uri = `/admin/jobs/${trip.customerId}/${trip.id}`;
        history.push(uri);
      })
      .catch(error => {
        console.error(error);
        setError(error);
      })
      .finally(() => {
        setIsSearching(false);
      });
  }

  return (
    <Card innerClassName="bg-light" white={false}>
      <form onSubmit={onSubmit} className="has-validation">
        <div className="form-row">
          <div className="col">
            <ValidateableInput
              elementType={FormControl}
              value={searchValue}
              onChange={(event: any) => setSearchValue(event.target.value)}
              placeholder={`Find by ${props.name}`}
              isValid={error ? false : null}
              invalidFeedback={error?.message}
              disabled={isSearching}
            />
          </div>
          <div className="col-auto">
            <Button type="submit" disabled={isSearching}>
              Find
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
}

async function find(fieldPath: string, query: string): Promise<Trip[]> {
  // trim, uppercase

  let parsedQuery = query.trim();

  // check formatting

  if (/^# [a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}$/.test(parsedQuery)) {
    // example: "# FCV-9VW"
    parsedQuery = `#${parsedQuery.slice(2)}`;
  } else if (/^[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}$/.test(parsedQuery)) {
    // example: "FCV-9VW"
    parsedQuery = `#${parsedQuery}`;
  } else if (/^[a-zA-Z0-9]{6}$/.test(parsedQuery)) {
    // example "FCV9VW"
    parsedQuery = Order.formatId(parsedQuery);
  }

  if (/^#[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}$/.test(parsedQuery)) {
    parsedQuery = parsedQuery.toUpperCase();
  }

  // find trip

  console.log('Finding...', fieldPath, parsedQuery);

  const snapshot = await firebase
    .database()
    .ref('trips-all')
    .orderByChild(fieldPath)
    .equalTo(parsedQuery)
    .once('value');

  if (!snapshot.exists()) {
    throw new Error('Job not found');
  }

  // convert to trips array

  const trips = Object.entries(snapshot.val()).map(([id, trip]: any) => new Trip({id, ...trip}));

  return trips;
}
