import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../../images/logo_words_trans_sm.png';

export default function AuthLayout({children}) {
  return (
    <div className="container-fluid h-100">
      <div className="row h-100 d-flex align-items-center">
        <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 py-3 p-md-4 my-3">
          <div className="mb-md-4 mb-2 text-center">
            <Link to="/">
              <img src={logo} alt="Wave" width="125" height="138" />
            </Link>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
}
