import React from 'react';
import {Redirect} from 'react-router-dom';
import Delay from '../../components/Delay';
import Spinner from '../../components/Spinner';
import Constants from '../../Constants';
import {useAuthContext} from '../../contexts/AuthContext';
import useIsAdmin from '../../hooks/useIsAdmin';
import useThemeColor from '../../hooks/useThemeColor';
import AdminLayout from '../../layouts/AdminLayout';
import './drivers/drivers.css';

export default function Admin({children}) {
  const {isAdmin, error} = useIsAdmin();
  const {claims} = useAuthContext();

  useThemeColor(Constants.theme.primaryColorHexString);

  // wait for admin status, claims

  if (isAdmin === undefined || claims === undefined) {
    return (
      <Delay>
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      </Delay>
    );
  }

  if (error) {
    return <div className="w-100 h-100 d-flex align-items-center justify-content-center">Unable to verify access</div>;
  }

  // if not admin, redirect

  if (isAdmin === false) {
    return <Redirect to="/" />;
  }

  return <AdminLayout>{children}</AdminLayout>;
}
