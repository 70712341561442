import findIndex from 'lodash/findIndex';
import pluralize from 'pluralize';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import Constants from '../Constants';
import joinClassNames from '../functions/joinClassNames';
import unwrap from '../functions/unwrap';
import SelectOption from '../models/SelectOption';
import FontAwesome from './FontAwesome';

export default function DropdownSelect({
  id,
  options,
  selectedValues,
  onChange,
  disabled,
  className,
  buttonClassName,
  onlyShowSelectionCount = false,
  itemName,
  width,
  orderable = false,
  onChangeOrder,
}: {
  id?: string;
  options: SelectOption[];
  selectedValues: string[] | undefined;
  onChange: (values: string[]) => void;
  disabled?: boolean;
  className?: string;
  buttonClassName?: string;
  onlyShowSelectionCount?: boolean;
  itemName?: string;
  width?: string;
  orderable?: boolean;
  onChangeOrder?: (options: SelectOption[]) => void;
}) {
  // STATE

  const [optionsInternal, setOptionsInternal] = useState(options);
  const dropdown = useRef();
  const [isShowing, setIsShowing] = useState(false);
  const [internalSelectedValues, setInternalSelectedValues] = useState(selectedValues ?? []);

  const isDragging = useRef(false);
  const [highlightedValue, setHighlightedValue] = useState<string>();
  // const [dragOverValue, setDragOverValue] = useState<string>()

  // EFFECT: set up dropdown

  useEffect(() => {
    const listener = (event: any) => {
      if (dropdown.current === event.target || (window as any).$(dropdown.current).has(event.target).length > 0) {
        return;
      }
      setIsShowing(false);
    };
    window.document.body.addEventListener('click', listener);
    return () => window.document.body.removeEventListener('click', listener);
  }, []);

  // EFFECT: selected values --> update internal selected values

  useEffect(() => {
    setInternalSelectedValues(selectedValues ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedValues)]);

  // METHODS

  const onMouseLeaveContainer = useCallback((event: any) => {
    setHighlightedValue(undefined);
  }, []);

  const onClickDropdownButton = useCallback((event: any) => {
    setIsShowing(isShowing => !isShowing);
  }, []);

  const onChangeCheckbox = useCallback(
    option => (event: any) => {
      const newInternal = optionsInternal
        .map(v => v.value)
        .filter(v => {
          if (v === option.value) {
            return event.target.checked;
          } else {
            return internalSelectedValues.includes(v);
          }
        });
      setInternalSelectedValues(newInternal);
      onChange && onChange(newInternal);
    },
    [internalSelectedValues, onChange, optionsInternal],
  );

  const onClickClear = useCallback(
    (event: any) => {
      setInternalSelectedValues([]);
      onChange && onChange([]);
    },
    [onChange],
  );

  const onMouseEnterOption = useCallback(
    option => (event: any) => {
      console.log('onMouseEnter');
      if (isDragging.current) {
        return;
      }
      setHighlightedValue(option.value);
    },
    [],
  );

  const onDragStart = useCallback((event: any) => {
    console.log('onDragStart');
    isDragging.current = true;
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDragOver = useCallback(
    option => (event: any) => {
      console.log('onDragOver');
      event.preventDefault();

      // console.log(event.target)

      // setDragOverValue(option.value)

      // setDragOverValue(option.value)

      if (option.value === highlightedValue) {
        return;
      }

      const options = [...optionsInternal];

      // remove highlighted value

      const indexOfHighlightedValue = findIndex(options, option => option.value === highlightedValue);
      const [removedValue] = options.splice(indexOfHighlightedValue, 1);

      // find location of dragged over (this) value

      const index = findIndex(optionsInternal, _ => _.value === option.value);
      if (index === indexOfHighlightedValue) return;

      // put highlighted value into hovered place value

      options.splice(index, 0, removedValue);

      setOptionsInternal(options);
    },
    [optionsInternal, highlightedValue],
  );

  const onDrop = useCallback(
    (event: any) => {
      console.log('onDrop');
      event.preventDefault();

      isDragging.current = false;
      setHighlightedValue(undefined);

      setTimeout(() => {
        if (onChangeOrder) {
          onChangeOrder(optionsInternal);
        }
      }, 100);
    },
    [optionsInternal, onChangeOrder],
  );

  // RENDER

  return (
    <div
      ref={dropdown as any}
      className={joinClassNames('dropdown d-inline-block', className)}
      onMouseLeave={onMouseLeaveContainer}>
      <button
        type="button"
        onClick={onClickDropdownButton}
        className={[
          'btn dropdown-toggle border border-gray-400 ',
          buttonClassName || '',
          disabled ? 'bg-gray-200' : 'bg-white',
        ].join(' ')}
        disabled={disabled}
        style={{whiteSpace: 'normal'}}>
        {onlyShowSelectionCount
          ? `${internalSelectedValues.length}${
              itemName ? ` ${pluralize(itemName, internalSelectedValues.length)}` : ''
            } selected`
          : unwrap(
              internalSelectedValues,
              internalSelectedValues =>
                internalSelectedValues.length
                  ? internalSelectedValues
                      .map(selectedValue =>
                        unwrap(
                          optionsInternal.find(v => v.value === selectedValue),
                          option => option.text ?? (option as any).name,
                          'Unknown',
                        ),
                      )
                      .join(', ')
                  : 'Any',
              'Any',
            )}
      </button>
      <div
        style={{width: width || '20rem'}}
        className={joinClassNames('dropdown-menu dropdown-menu-right shadow p-0', isShowing && 'show')}>
        <div className="p-1 p-md-2">
          {/* checkboxes */}

          {optionsInternal.map(option => {
            const isHighlighted = option.value === highlightedValue;
            const optionId = `${id ?? ''}${option.value}StatusCheckBox`;
            return (
              <div
                key={option.value}
                className={joinClassNames(
                  'd-flex custom-control custom-checkbox py-1 pr-1',
                  orderable && 'cursor-pointer',
                  isHighlighted && 'bg-gray-100',
                  // option.value === dragOverValue && 'bg-blue'
                )}
                onMouseEnter={orderable ? onMouseEnterOption(option) : undefined}
                draggable={orderable}
                onDragStart={onDragStart}
                onDragEnter={onDragOver(option)}
                onDrop={onDrop}>
                <input
                  type="checkbox"
                  id={optionId}
                  checked={internalSelectedValues && internalSelectedValues.includes(option.value)}
                  onChange={onChangeCheckbox(option)}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor={optionId}>
                  {option.text ?? (option as any).name}
                </label>
                {isHighlighted && (
                  <div className="flex-grow-1 d-flex justify-content-end align-items-center">
                    <FontAwesome.GripVerticalSolid className="text-muted" />
                  </div>
                )}
              </div>
            );
          })}

          {/* clear button */}

          {internalSelectedValues && internalSelectedValues.length > 0 && (
            <button
              onClick={onClickClear}
              className="position-absolute btn btn-sm btn-light m-2 m-md-3"
              style={{
                top: 0,
                right: 0,
                zIndex: Constants.zIndex.level1,
              }}>
              Clear
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
