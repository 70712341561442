import React, {useState} from 'react';
import Autocomplete from '../../components/GoogleMaps/Autocomplete';
import Input from '../../components/Input';
import SearchNear from '../../components/SearchNear';
import unwrap from '../../functions/unwrap';
import PropertyType from '../types/PropertyType';
import InvalidFeedback from './InvalidFeedback';
import LocationMap from './LocationMap';

export default function LocationInput({id, property, value, onChange, validationError, disabled, isCustomerFacing}) {
  const [searchBounds, setSearchBounds] = useState({
    north: 49.3,
    south: 24.14,
    east: -66.51,
    west: -125.31,
  });

  // render

  return (
    <div className="position-relative">
      {!isCustomerFacing && (
        <div className="position-absolute" style={{top: '-2.25rem', right: '0'}}>
          <SearchNear onChangeBounds={setSearchBounds} disabled={disabled} />
        </div>
      )}
      <Autocomplete bounds={searchBounds} onChange={onChange}>
        <Input
          id={id}
          name={id}
          type="text"
          defaultValue={PropertyType.format(PropertyType.location, value)}
          className={'form-control ' + unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid'), '')}
          placeholder="Search"
          disabled={disabled}
        />
      </Autocomplete>
      <InvalidFeedback property={property} error={validationError} />
      {!isCustomerFacing && <LocationMap location={value} />}
      {/* {unwrap(value, value => (
                <small className="text-muted">
                    Geohash <pre className="d-inline bg-light p-1">
                        {value.geohash}
                    </pre>
                </small>
            ))} */}
    </div>
  );
}
