import React, {useMemo} from 'react';
import {TripOfNumber} from 'wave-common/lib/models/Trip';
import {anyPropertyDescriptionValue} from 'wave-common/lib/type-descriptions/PropertyDescription';
import {DelaySpinner} from '../../../../components/Spinner';
import joinClassNames from '../../../../functions/joinClassNames';
import useResult from '../../../../hooks/useResultNew';
import {CellOfProperty as CellOfPropertyType} from './Cell';
import CellProps from './CellProps';

export default function CellOfProperty<Key extends keyof TripOfNumber>({
  cell,
  style,
}: CellProps<CellOfPropertyType<Key>>) {
  const {isLoading, error, value} = useResult(
    useMemo(async () => {
      const value = anyPropertyDescriptionValue<Key, TripOfNumber>(
        cell.description,
        cell.model,
        cell.description.key as Key,
      );
      return cell.description.userFacingValue(value, cell.model);
    }, [cell.description, cell.model]),
  );
  return (
    <div
      onMouseEnter={cell.onMouseEnter}
      onClick={cell.onSelect}
      style={style}
      className={joinClassNames('cursor-pointer border-right')}>
      <div className={joinClassNames('w-100 h-100 d-flex border-bottom', cell.isHighlighted ? 'bg-light' : 'bg-white')}>
        <div className="px-2 px-md-3 d-flex align-items-center ellipsis-pre line-height-md">
          {error ? error.message : value ? value : isLoading && <DelaySpinner small />}
        </div>
      </div>
    </div>
  );
}
