import React from 'react';

// from https://play.google.com/intl/en_us/badges/

export default function GetItOnGooglePlay({imgProps = {}, driver = false, ...props}) {
  return (
    <a
      href={
        driver
          ? 'https://play.google.com/store/apps/details?id=com.ibgsoftware.scoopdriver&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
          : 'https://play.google.com/store/apps/details?id=com.ibgsoftware.scoop&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
      }
      {...props}
    >
      <img
        alt="Get it on Google Play"
        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        width="156"
        {...imgProps}
      />
    </a>
  );
}
