import React, {useCallback, useMemo} from 'react';
import {driverUpdate, useStatus} from 'wave-common';
import WithId from 'wave-common/lib/models/WithId';
import RealtimeDatabaseDataSource from '../data-sources/RealtimeDatabaseDataSource';

export default function PropertySwitch<T extends WithId>({
  property,
  object,
  title,
}: {
  property: keyof T;
  object: T;
  title: string;
}) {
  let checked = Boolean(object[property]);

  const id = useMemo(() => property.toString() + '-switch', [property]);

  const {isPending, error, handlePromise} = useStatus(React as never);

  const onChange = useCallback(() => {
    handlePromise(driverUpdate(object.id, {[property]: !checked || null}, RealtimeDatabaseDataSource.instance));
  }, [checked, object.id, handlePromise, property]);

  return (
    <div className="custom-control custom-switch">
      <input
        id={id}
        type="checkbox"
        className="custom-control-input"
        checked={checked}
        onChange={onChange}
        disabled={Boolean(isPending || error)}
      />
      <label htmlFor={id} className="custom-control-label">
        {title}
      </label>
    </div>
  );
}
