import pluralize from 'pluralize';
import React, {useMemo} from 'react';
import {unwrap} from 'wave-common';
import {latLngLiteralFromCoordinatesArray} from 'wave-common/lib/models/CoordinatesArray';
import {latLngBoundsAroundLatLng} from 'wave-common/lib/models/google-maps/LatLngBounds';
import Zone from 'wave-common/lib/models/Zone';
import GoogleMap from '../../components/GoogleMaps/GoogleMap';
import {iconMapPin3FillBorder, labelWhite} from '../../components/GoogleMaps/helpers';
import {boundsForLatLngs, latLngBounds} from '../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../components/GoogleMaps/MapContents';
import {MarkerInterface} from '../../components/GoogleMaps/Marker';
import {PropertyDisplayProps} from './PropertyDisplay';

export default function ZonesDisplay({schema, property, value}: PropertyDisplayProps<Zone[]>) {
  const mapContents = useMemo(
    () =>
      unwrap(
        value,
        zones =>
          new MapContents.Bounds({
            id: 'zones',
            name: pluralize('Zone', zones.length),
            latLngBounds:
              zones.length === 1
                ? latLngBounds(latLngBoundsAroundLatLng(latLngLiteralFromCoordinatesArray(zones[0].coordinates!), 750))
                : boundsForLatLngs(zones.map(zone => latLngLiteralFromCoordinatesArray(zone.coordinates))),
          }),
      ),
    [value],
  );

  const markers: MarkerInterface[] | undefined = useMemo(
    () =>
      value?.map(zone => ({
        id: zone.id,
        position: latLngLiteralFromCoordinatesArray(zone.coordinates),
        label: labelWhite(zone.name),
        icon: iconMapPin3FillBorder(),
      })),
    [value],
  );

  return (
    value && (
      <div style={{height: '22rem'}} className="mb-4">
        <GoogleMap
          mapContents={mapContents}
          markers={markers}
          fullscreenControl={false}
          streetViewControl={false}
          useDefaultMapStyles
          rounded
        />
      </div>
    )
  );
}
