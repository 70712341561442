import Enum from '../base/Enum';

class ContentType extends Enum {
  static jpeg = new ContentType('image/jpeg');
  static png = new ContentType('image/png');
  static gif = new ContentType('image/gif');
  static pdf = new ContentType('application/pdf');

  get label() {
    switch (this) {
      case ContentType.jpeg:
      case ContentType.png:
      case ContentType.gif:
        return 'Image';
      case ContentType.pdf:
        return 'PDF';
      default:
        return 'Unknown type';
    }
  }

  get isImage() {
    switch (this) {
      case ContentType.jpeg:
      case ContentType.png:
      case ContentType.gif:
        return true;
      case ContentType.pdf:
        return false;
      default:
        return false;
    }
  }
}

export default ContentType;
