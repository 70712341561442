import sortBy from 'lodash/sortBy';
import React from 'react';
import FontAwesome from '../../components/FontAwesome';
import Enum from '../base/Enum';
import Trip from './Trip';

export default class TripStatus extends Enum {
  static created = new TripStatus('CREATED');
  static scheduled = new TripStatus('SCHEDULED');
  static accepted = new TripStatus('ACCEPTED');
  static started = new TripStatus('STARTED');

  static completed = new TripStatus('COMPLETED');
  static canceledByRider = new TripStatus('CANCELED-BY-RIDER');
  static canceledByDriver = new TripStatus('CANCELED-BY-DRIVER');
  static tripTimedOut = new TripStatus('TRIP-TIMED-OUT');

  customerFacingTitle() {
    switch (this) {
      case TripStatus.created:
        return 'Requested';
      case TripStatus.scheduled:
        return 'Scheduled';
      case TripStatus.accepted:
        return 'Driver on route';
      case TripStatus.started:
        return 'On route to drop-off';
      case TripStatus.completed:
        return 'Completed';
      case TripStatus.canceledByRider:
        return 'Canceled';
      case TripStatus.canceledByDriver:
        return 'Canceled by driver';
      case TripStatus.tripTimedOut:
        return 'No drivers found';
      default:
        return '';
    }
  }

  title(trip) {
    if (trip && trip.requiresResponse) return 'Requires response';
    switch (this) {
      case TripStatus.created:
        return 'Unaccepted';
      case TripStatus.scheduled:
        return !trip ? 'Accepted' : trip.driverId ? 'Assigned' : 'Unassigned';
      case TripStatus.accepted:
        return !trip ? 'Pickup' : Trip.prototype.isAtCurrentLocation.apply(trip) ? 'At pickup' : 'On route to pickup';
      case TripStatus.started:
        return !trip
          ? 'Drop-off'
          : Trip.prototype.isAtCurrentLocation.apply(trip)
          ? 'At drop-off'
          : 'On route to drop off';
      case TripStatus.completed:
        return 'Completed';
      case TripStatus.canceledByRider:
        return 'Canceled';
      case TripStatus.canceledByDriver:
        return 'Canceled by driver';
      case TripStatus.tripTimedOut:
        return 'Search timed out';
      default:
        return '-';
    }
  }

  titleWithEmoji = this.title;

  titleComponent(trip) {
    switch (this) {
      case TripStatus.scheduled:
        return (
          <span>
            {this.titleIcon(trip)}
            {this.title(trip)}
          </span>
        );

      case TripStatus.created:
        return (
          <span>
            {this.titleIcon(trip)}
            {this.title(trip)}
          </span>
        );

      case TripStatus.accepted:
        return (
          <span>
            {this.titleIcon(trip)}
            {this.title(trip)}
          </span>
        );

      case TripStatus.started:
        return (
          <span>
            {this.titleIcon(trip)}
            {this.title(trip)}
          </span>
        );

      case TripStatus.completed:
        return (
          <span>
            {this.titleIcon(trip)}
            {this.title(trip)}
          </span>
        );
      case TripStatus.tripTimedOut:
        return (
          <span className="text-orange">
            {this.titleIcon(trip)}
            {this.title(trip)}
          </span>
        );

      case TripStatus.canceledByRider:
      case TripStatus.canceledByDriver:
        return (
          <span className="text-danger">
            {this.titleIcon(trip)}
            {this.title(trip)}
          </span>
        );

      default:
        return this.title(trip);
    }
  }

  titleIcon(trip) {
    switch (this) {
      case TripStatus.scheduled:
        return <FontAwesome.Clock className="mr-2 fa-sm" />;
      case TripStatus.created:
        return <i className="fas fa-seedling mr-2 fa-sm" />;
      case TripStatus.accepted:
        return <i className="fas fa-car-side mr-2 fa-sm" />;
      case TripStatus.started:
        return <i className="fas fa-car-side mr-2 fa-sm" />;
      case TripStatus.completed:
        return <i className="fa fa-check-circle mr-2 fa-sm" />;
      case TripStatus.tripTimedOut:
        return <i className="fa fa-dizzy mr-1 fa-sm" />;
      case TripStatus.canceledByRider:
      case TripStatus.canceledByDriver:
        return <i className="fa fa-times-circle mr-1 fa-sm" />;
      default:
        return null;
    }
  }

  next() {
    switch (this) {
      case TripStatus.created:
        return TripStatus.accepted;
      case TripStatus.scheduled:
        return TripStatus.accepted;
      case TripStatus.accepted:
        return TripStatus.started;
      case TripStatus.started:
        return TripStatus.completed;
      case TripStatus.completed:
        return null;
      case TripStatus.canceledByRider:
        return null;
      case TripStatus.canceledByDriver:
        return null;
      case TripStatus.tripTimedOut:
        return null;
      default:
        return null;
    }
  }

  previous() {
    switch (this) {
      case TripStatus.created:
        return null;
      case TripStatus.scheduled:
        return null;
      case TripStatus.accepted:
        return TripStatus.scheduled;
      case TripStatus.started:
        return TripStatus.accepted;
      case TripStatus.completed:
        return TripStatus.started;
      case TripStatus.canceledByRider:
        return null;
      case TripStatus.canceledByDriver:
        return null;
      case TripStatus.tripTimedOut:
        return null;
      default:
        return null;
    }
  }

  order() {
    switch (this) {
      case TripStatus.created:
        return 0;
      case TripStatus.scheduled:
        return 1;
      case TripStatus.accepted:
        return 2;
      case TripStatus.started:
        return 3;
      case TripStatus.completed:
        return 4;
      case TripStatus.canceledByRider:
        return 5;
      case TripStatus.canceledByDriver:
        return 6;
      case TripStatus.tripTimedOut:
        return 7;
      default:
        return 8;
    }
  }
}

TripStatus.inOrder = () => sortBy(TripStatus.allCases, status => status.order());

TripStatus.notDone = () => [TripStatus.created, TripStatus.scheduled, TripStatus.accepted, TripStatus.started];

TripStatus.done = () => [
  TripStatus.completed,
  TripStatus.canceledByRider,
  TripStatus.canceledByDriver,
  TripStatus.tripTimedOut,
];

TripStatus.failed = () => [TripStatus.canceledByRider, TripStatus.canceledByDriver, TripStatus.tripTimedOut];
