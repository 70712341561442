export default function csvFromKeysAndObjects(keys: Key[], objects: Record<string, any>[]): string {
  // header: join keys with commas
  const headerString = keys.map(key => `"${key.heading}"`).join(',') + '\n';

  // rows: map each row, mapping the keys and their values into a string
  const rows = objects.map(object => {
    const values = keys.map((key: Key) => {
      let value = null;

      if (key.getValue) {
        value = key.getValue(object);
      } else if (key.key) {
        value = object[key.key];
      } else {
        console.log({key});
        throw new Error('Key must have either a "key" property or "getValue" property');
      }

      if (key.format) {
        value = key.format(value);
      }

      if (value === null || value === undefined) return '';

      const valueString = String(value);

      const commaEscapedValueString = valueString.replace(/"/g, `""`);

      return '"' + commaEscapedValueString + '"';
    });

    const valuesString = values.join(',');

    return valuesString;
  });

  const rowsString = rows.join('\n');

  return headerString + rowsString;
}

export interface Key {
  heading: string;
  key?: string | number;
  getValue?: (object: Record<string, any>) => any;
  format?: (value: any) => any;
}
