"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function checkStatus(response) {
    var _a;
    if (response.status !== 'OK') {
        console.log(`Bad response status "${response.status}"`);
        throw new Error((_a = response.error_message) !== null && _a !== void 0 ? _a : 'No error message included in response');
    }
}
exports.default = checkStatus;
