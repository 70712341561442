import React from 'react';
import {Link} from 'react-router-dom';
import {baseUserShortName, formatPhone, tripIsActive} from 'wave-common';
import FirebaseStorageSquareImage from '../../components/FirebaseStorageSquareImage';
import {FontAwesomeV5} from '../../components/FontAwesome';
import PrivacyNotice from '../../components/PrivacyNotice';
import useStatus from '../../hooks/useStatus';
import Trip from '../../models/scoopm/Trip';
import UserType from '../../models/scoopm/UserType';
import ScoopMApi from '../../references/scoopm-api';
import {Card} from './Card';

export function DriverRow({trip}: {trip: Trip | undefined}) {
  const {status, handlePromise: handleProxyPromise} = useStatus();

  return (
    <>
      {trip && trip.driverId && trip.driver && (
        <div className="row mb-5">
          <div className="col-md-6">
            <Card className="border">
              <div className="row">
                <div className="col">
                  {/* title */}

                  <span>Driver</span>

                  {/* name */}

                  <h4 className="mb-2 font-weight-bold">{baseUserShortName(trip.driver)}</h4>

                  {/* phone/chat */}

                  {tripIsActive(trip as any) && (
                    <>
                      {/* call */}

                      {trip.customer.proxyPhone ? (
                        <a href={`tel:${trip.customer.proxyPhone}`} className="btn btn-success rounded-pill mr-2">
                          <FontAwesomeV5 name="phone" /> {formatPhone(trip.customer.proxyPhone)}
                        </a>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            handleProxyPromise(
                              ScoopMApi.instance.trips
                                .child(trip.id)
                                .update({action: 'CREATE_PHONE_PROXY'})
                                .then(response => {
                                  if (response.data.driver?.proxyPhone) {
                                    window.open(`tel:${response.data.driver?.proxyPhone}`);
                                  }
                                }),
                              true,
                            );
                          }}
                          className="btn btn-success rounded-pill mr-2"
                          disabled={status === 'PENDING'}>
                          <FontAwesomeV5 name="phone" /> Call
                        </button>
                      )}

                      {/* message */}

                      <Link to={`/jobs/${trip.customerId}/${trip.id}/chat`} className="btn btn-success rounded-pill">
                        <FontAwesomeV5 name="comment" /> Message
                      </Link>
                    </>
                  )}
                </div>

                <div className="col-auto">
                  <FirebaseStorageSquareImage
                    path={`/profiles/${trip.driverId}`}
                    fallbackUrl={UserType.driver.imgSrc}
                    disableClick
                    roundedXl
                  />
                </div>
              </div>

              {/* phone privacy notice */}

              {tripIsActive(trip as any) && (
                <PrivacyNotice>A proxy phone number is provided for your privacy</PrivacyNotice>
              )}
            </Card>
          </div>
        </div>
      )}
    </>
  );
}
