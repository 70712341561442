"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.destinationCategoryTitle = exports.destinationCategoryDesrialize = exports.destinationCategoryAll = void 0;
const deserializeString_1 = require("./deserializeString");
const tupleForUnionType_1 = require("./tupleForUnionType");
exports.destinationCategoryAll = (0, tupleForUnionType_1.tupleForUnionType)({
    AIRLINE: undefined,
});
function destinationCategoryDesrialize(value) {
    const string = (0, deserializeString_1.deserializeString)(value);
    if (exports.destinationCategoryAll.includes(string)) {
        return string;
    }
    throw new Error(`Invalid destination category "${string}"`);
}
exports.destinationCategoryDesrialize = destinationCategoryDesrialize;
function destinationCategoryTitle(category) {
    switch (category) {
        case 'AIRLINE':
            return 'Airline';
        default:
            return undefined;
    }
}
exports.destinationCategoryTitle = destinationCategoryTitle;
