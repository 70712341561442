import firebase from 'firebase/app';
import GeoFireReference from './GeoFireReference';
import Reference from './Reference';

/**
 * Serves as a wrapper for references, providing common functionality
 */
class DatabaseReference extends Reference {
  get geoFireReference() {
    return new GeoFireReference(this.path, this.parent, this.query.ref);
  }

  constructor(path, parent) {
    // remove disallowed characters
    let safePath = !path
      ? null
      : path.replace('.', '').replace('#', '').replace('$', '').replace('[', '').replace(']', '');

    // init
    super(safePath, parent);

    // set up query
    let reference = parent ? parent.query.ref : firebase.database().ref();
    if (path) reference = reference.child(path);
    this.query = reference;
  }

  child(path) {
    const ReferenceType = this.childReferenceType;

    return new ReferenceType(path, this);
  }

  push() {
    const id = this.query.ref.push().key;

    console.log(`Pushing new reference ${id} at ${this.fullPath}`);

    return this.child(id);
  }

  // Overrideable getters

  get childReferenceType() {
    return DatabaseReference;
  }
  get Model() {
    return null;
  }
  get isArray() {
    return false;
  }

  // Query mutation methods

  orderByChild(path) {
    const copy = Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    copy.query = copy.query.orderByChild(path);
    return copy;
  }

  equalTo(value) {
    const copy = Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    copy.query = copy.query.equalTo(value);
    return copy;
  }

  // DB mutation methods

  prepare(value) {
    return JSON.parse(JSON.stringify(value));
  }

  async set(value) {
    console.log(`Setting ${this.fullPath}...`, value);

    const preparedValue = this.prepare(value);

    try {
      await this.query.ref.set(preparedValue);
    } catch (error) {
      console.error(`Error setting ${this.fullPath}`, error);

      throw error;
    }

    console.log(`Set ${this.fullPath}`);

    return Promise.resolve();
  }

  async update(values) {
    const preparedValues = this.prepare(values);

    console.log(`Updating ${Object.keys(preparedValues).length} field(s) at ${this.fullPath || 'root'}...`, values);

    try {
      await this.query.ref.update(preparedValues);
    } catch (error) {
      console.error(`Error updating ${this.fullPath}`, error);

      throw error;
    }

    console.log(`Updated ${this.fullPath}`);

    return Promise.resolve();
  }

  async remove() {
    console.log(`Deleting ${this.fullPath || 'root'}...`);
    try {
      await this.query.ref.remove();
    } catch (error) {
      console.error(`Error deleting ${this.fullPath || 'root'}`, error);
      throw error;
    }
    console.log(`Deleted ${this.fullPath || 'root'}`);
  }

  // DB access methods

  deserialize(snapshot, silent) {
    if (!snapshot.exists()) {
      !silent && console.log('No value exists at that path');
      return null;
    } else if (this.isArray) {
      const array = [];
      snapshot.forEach(childSnapshot => {
        array.push({
          key: childSnapshot.key,
          value: childSnapshot.val(),
        });
      });
      !silent && console.log(`Deserialized as array, ${array.length} items`, array);

      return array;
    } else if (this.Model) {
      const model = Object.create(this.Model.prototype);
      const value = snapshot.val();
      !silent && console.log(`Deserializing as ${this.Model.name}`, value);
      model.id = snapshot.key;
      return Object.assign(model, value);
    } else {
      const value = snapshot.val();
      !silent && console.log(`Deserializing as plain value`, value);
      return value;
    }
  }

  /**
   * Gets the value of this reference once. Returns an array containing two elements: the model, and it's key
   * @param {String} eventType The event type to listen for
   * @returns {Promise<Array<any>>} An array containing the model first (Object / custom model type), and then the key (String)
   */
  async once(eventType = 'value', silent = true) {
    if (!silent) {
      console.log(`Listening once for ${eventType} event at ${this.fullPath}...`);
    }

    let snapshot;
    try {
      snapshot = await this.query.once(eventType);
    } catch (error) {
      if (!silent) {
        console.error(`Error listening once for ${eventType} event at ${this.fullPath}`, error);
      }

      throw error;
    }

    if (!silent) {
      console.log(`Heard ${eventType} event once at ${this.fullPath}`);
    }

    // deserialize
    const model = this.deserialize(snapshot, silent);

    return [model, snapshot.key];
  }

  on(eventType, onModel, onError) {
    console.log(`Listening for ${eventType} events at ${this.fullPath}...`);

    return this.query.on(
      eventType,
      snapshot => {
        console.log(`Got ${eventType} event at ${this.fullPath}`);

        const model = this.deserialize(snapshot);

        onModel(model);
      },
      error => {
        console.log(`Error listening for ${eventType} events at ${this.fullPath}`, error);

        if (onError) {
          onError(error);
        }
      },
    );
  }

  off(handler) {
    console.log(`Removing listener at ${this.fullPath}`);
    this.query.off(handler);
  }

  async search(property, value) {
    console.log(`Searching "${this.fullPath}" for "${property}" equal to "${value}"...`);
    const [search] = await this.orderByChild(property).equalTo(value).once();
    const results = Object.keys(search).map((key, i) => [Object.values(search)[i], key]);
    console.info(`Found ${results.length} results`);
    return results;
  }
}

export default DatabaseReference;
