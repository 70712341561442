import {environmentVariable} from '../../Environment';

function icon24(fileName: string): google.maps.Icon {
  return {
    url: `${environmentVariable('URL')}/images/${fileName}`,
    scaledSize: new google.maps.Size(24, 24),
    labelOrigin: new google.maps.Point(12, -24),
  };
}

export function iconMapPin3FillBorder() {
  return icon24('map-pin-3-fill-border.png');
}

export function iconMapPin3FillBorderPink() {
  return icon24('map-pin-3-fill-border-pink.png');
}

export function iconMapPin3FillBorderGray() {
  return icon24('map-pin-3-fill-border-gray.png');
}

export function labelWhite(text: string) {
  return {
    text,
    className: 'map-label-text color-dark shadow rounded-xl px-3 py-2',
  };
}
