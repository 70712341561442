import HttpReference from '../platform/HttpReference';
import firebase from 'firebase/app';

export default class RejectDelivery extends HttpReference {
  async patch() {
    const user = firebase.auth().currentUser;
    if (!user) throw new Error('Unauthenticated');
    const token = await user.getIdToken();
    return this.request({
      method: 'PATCH',
      headers: {Authorization: `Bearer ${token}`},
    });
  }
}
