import React, {useMemo} from 'react';
import {ServiceArea} from 'wave-common';
import Card from '../../components/Card';
import Constants from '../../Constants';
import {unwrap} from '../../functions/unwrap';
import GeoCircleDisplay from '../components/GeoCircleDisplay';
import Property from '../types/Property';
import PropertyType from '../types/PropertyType';
import Schema from '../types/Schema';

const serviceAreaSchema = new Schema<ServiceArea>(
  'service-area',
  [
    new Property('center', PropertyType.location),
    new Property('radiusMi', PropertyType.number, {
      userFacingName: 'Radius (in miles)',
    }),
    new Property('availableTypes', PropertyType.tripType, {
      isOptional: true,
      isArray: true,
      userFacingName: 'Available service types',
    }),
  ],
  ({model}) => {
    const geocircle = useMemo(
      () =>
        unwrap(model, model => ({
          center: model.center.coordinates,
          radiusM: model.radiusMi / Constants.metersPerMile,
        })),
      [model],
    );
    if (!geocircle) return null;
    return (
      <Card innerPadded>
        <h6>The resulting circle looks like this</h6>
        <GeoCircleDisplay value={geocircle} />
      </Card>
    );
  },
);

export default serviceAreaSchema;
