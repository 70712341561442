"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.directionsInputString = void 0;
function directionsInputString(input) {
    const placeId = input.placeId;
    if (placeId) {
        return `place_id:${placeId}`;
    }
    const address = input.address;
    if (address) {
        return address;
    }
    if (Array.isArray(input)) {
        return input.join(',');
    }
    throw new Error('Invalid directions input');
}
exports.directionsInputString = directionsInputString;
