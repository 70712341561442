import React from 'react';
import joinClassNames from '../../../../functions/joinClassNames';
import {CellOfCheckbox as CellOfCheckboxType} from './Cell';
import CellProps from './CellProps';

export default function CellOfCheckbox({cell, style}: CellProps<CellOfCheckboxType>) {
  const id = cell.trip.id;
  return (
    <div
      style={style}
      onMouseEnter={cell.onMouseEnter}
      className={joinClassNames(
        'custom-control custom-checkbox border-bottom',
        cell.isHighlighted ? 'bg-light' : 'bg-white',
      )}>
      <input
        id={id}
        type="checkbox"
        checked={cell.isChecked}
        onChange={event => cell.onChange(event.target.checked)}
        className="custom-control-input"
      />
      <label htmlFor={id} className="custom-control-label ml-2 mt-2" />
    </div>
  );
}
