import {useMemo} from 'react';
import {RouteProps, useLocation} from 'react-router-dom';

export default function useQuery() {
  const location = useLocation();
  return useQueryWithLocation(location);
}

export function useQueryWithLocation(location: NonNullable<RouteProps['location']>) {
  return useMemo(() => (location.search ? new URLSearchParams(location.search) : undefined), [location.search]);
}
