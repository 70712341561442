import HttpReference from '../platform/HttpReference';
import Customers from './Customers';
import Dispatch from './Dispatch';
import Drivers from './Drivers';
import Earnings from './Earnings';
import Fountain from './fountain';
import Gatekeeper from './Gatekeeper';
import Messages from './Messages';
import Olo from './Olo';
import Pricing from './Pricing';
import Streaming from './Streaming';
import Stripe from './stripe/Stripe';
import TripEstimates from './TripEstimates';
import Trips from './Trips';
import UserGroupInvites from './UserGroupInvites';
import Users from './Users';
import Vehicles from './Vehicles';
import VendorApplications from './VendorApplications';

/**
 * @static @property {ScoopMApi} instance
 */
class ScoopMApi extends HttpReference {
  static instance = new ScoopMApi(process.env.REACT_APP_NODE_SERVER_URL);

  constructor(path, parent) {
    super(path, parent);

    this.customers = new Customers('customers', this);
    this.dispatch = new Dispatch('dispatch', this);
    this.drivers = new Drivers('drivers', this);
    this.earnings = new Earnings('earnings', this);
    this.fountain = new Fountain('fountain', this);
    this.gatekeeper = new Gatekeeper('gatekeeper', this);
    this.messages = new Messages('messages', this);
    this.olo = new Olo('olo', this);
    this.pricing = new Pricing('pricing', this);
    this.streaming = new Streaming('streaming', this);
    this.stripe = new Stripe('stripe', this);
    this.tripEstimates = new TripEstimates('trip-estimates', this);
    this.trips = new Trips('trips', this);
    this.users = new Users('users', this);
    this.userGroupInvites = new UserGroupInvites('user-group-invites', this);
    this.vehicles = new Vehicles('vehicles', this);
    this.vendorApplications = new VendorApplications('vendor-applications', this);
  }

  async headers() {
    return this.firebaseAuthorization();
  }
}

export default ScoopMApi;
