import {useEffect, useState} from 'react';

export default function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function listen() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', listen);
    return () => window.removeEventListener('resize', listen);
  }, []);

  return width;
}
