import React, {ReactNode, useMemo} from 'react';
import {expectAsync} from '../functions/expect';
import joinClassNames from '../functions/joinClassNames';
import {unwrap} from '../functions/unwrap';
import useResult from '../hooks/useResult';
import useStatus, {StatusButton} from '../hooks/useStatus';
import useUserGroupVendor from '../hooks/useUserGroupVendor';
import Vendor from '../models/scoopm/Vendor';
import CheckoutSession from '../models/stripe/CheckoutSession';
import {priceDescription} from '../models/stripe/Price';
import {subscriptionDescription} from '../models/stripe/Subscription';
import {subscriptionStatusBootstrapColor, subscriptionStatusTitle} from '../models/stripe/SubscriptionStatus';
import {AxiosResponseWithModel} from '../references/platform/HttpReference';
import ScoopMApi from '../references/scoopm-api';
import {BootstrapActionColor} from '../styles/BootstrapColor';
import Spinner from './Spinner';

const unableToLoadSubscriptionDetails = 'Unable to load subscription details';

export default function SubscriptionCard() {
  const {isLoading, error, model: vendor} = useUserGroupVendor();

  if (isLoading) {
    return <Layout title={<Spinner />} />;
  }

  if (error) {
    return <Layout title={unableToLoadSubscriptionDetails} />;
  }

  if (vendor?.stripeSubscriptionId) {
    return <ManageSubscription vendor={vendor} />;
  }

  if (vendor?.desiredPriceId) {
    return <ActivateSubscription vendor={vendor} />;
  }

  return null;
}

function ManageSubscription(props: {vendor: Vendor}) {
  const productResult = useResult(useMemo(() => ScoopMApi.instance.stripe.products.deliveryServices.request(), []));
  const subscriptionResult = useResult(
    useMemo(
      () =>
        unwrap(props.vendor.stripeSubscriptionId, id => ScoopMApi.instance.stripe.subscriptions.child(id).request()),
      [props.vendor.stripeSubscriptionId],
    ),
  );

  if (!productResult || !subscriptionResult) {
    return <Layout title={<Spinner />} />;
  }

  if (productResult.error || subscriptionResult.error) {
    return <Layout title={unableToLoadSubscriptionDetails} />;
  }

  return (
    <Layout
      title={productResult?.value?.data.name ?? 'Subscription'}
      subtitle={unwrap(subscriptionResult.value?.data, subscriptionDescription)}
      badge={unwrap(subscriptionResult?.value?.data.status, subscriptionStatusTitle)}
      badgeColor={unwrap(subscriptionResult?.value?.data.status, subscriptionStatusBootstrapColor)}
      actions={<ManageSubscriptionButton vendor={props.vendor} />}
    />
  );
}

function ActivateSubscription(props: {vendor: Vendor}) {
  const productResult = useResult(useMemo(() => ScoopMApi.instance.stripe.products.deliveryServices.request(), []));
  const priceResult = useResult(
    useMemo(
      () => unwrap(props.vendor.desiredPriceId, id => ScoopMApi.instance.stripe.prices.child(id).request()),
      [props.vendor.desiredPriceId],
    ),
  );

  if (!productResult || !priceResult) {
    return <Layout title={<Spinner />} />;
  }

  if (productResult.error || priceResult.error) {
    return <Layout title={unableToLoadSubscriptionDetails} />;
  }

  return (
    <Layout
      title={productResult?.value?.data.name}
      subtitle={unwrap(priceResult.value?.data, priceDescription)}
      actions={<ActivateSubscriptionButton vendor={props.vendor} />}
    />
  );
}

function Layout(props: {
  title: ReactNode;
  subtitle?: ReactNode;
  badge?: ReactNode;
  badgeColor?: BootstrapActionColor;
  actions?: ReactNode;
}) {
  return (
    <div className="d-flex align-items-center">
      <div className="flex-grow-1 d-flex flex-column">
        <strong>
          {props.title}
          {props.badge && (
            <span className={joinClassNames('badge ml-2', `badge-${props.badgeColor}`)}>{props.badge}</span>
          )}
        </strong>
        {props.subtitle}
      </div>
      {props.actions}
    </div>
  );
}

function ActivateSubscriptionButton(props: {vendor: Vendor}) {
  const {status, handlePromise} = useStatus<AxiosResponseWithModel<CheckoutSession>>();

  function onClick() {
    handlePromise(expectAsync(props.vendor.id).then(id => ScoopMApi.instance.stripe.checkout.sessions.create(id))).then(
      response => {
        if (response?.data.url) {
          window.location.assign(response.data.url);
        }
      },
    );
  }

  return (
    <StatusButton status={status} onClick={onClick} color="primary">
      Activate subscription
    </StatusButton>
  );
}

function ManageSubscriptionButton(props: {vendor: Vendor}) {
  const {status, handlePromise} = useStatus<AxiosResponseWithModel<CheckoutSession>>();

  function onClick() {
    if (props.vendor.stripeCustomerId) {
      handlePromise(ScoopMApi.instance.stripe.billingPortal.sessions.create(props.vendor.stripeCustomerId)).then(
        response => {
          if (response?.data.url) {
            window.location.assign(response.data.url);
          }
        },
      );
    }
  }

  if (!props.vendor.stripeCustomerId) return null;

  return (
    <StatusButton status={status} onClick={onClick} color="primary">
      Manage subscription
    </StatusButton>
  );
}
