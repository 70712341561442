import firebase from 'firebase/app';
import React, {useEffect, useMemo} from 'react';
import Card from '../../../components/Card';
import cleanForFirebase from '../../../functions/cleanForFirebase';
import joinClassNames from '../../../functions/joinClassNames';
import useFirestoreListener from '../../../hooks/useFirestoreListener';
import StripeSettings from '../../../models/scoopm/StripeSettings';

export default function Payouts() {
  const reference = useMemo(() => firebase.firestore().collection('settings').doc('stripe'), []);
  const {error, isLoading, model} = useFirestoreListener(reference, StripeSettings);

  useEffect(() => {
    if (!isLoading && !error && !model) {
      // if no document exists to update, go ahead an create it
      const newSetings = new StripeSettings({enablePayouts: false});
      const data = cleanForFirebase(newSetings);
      reference.set(data);
    }
  }, [reference, error, isLoading, model]);

  const enablePayouts = model?.enablePayouts || false;

  return (
    <Card padded>
      <h4>Global switch</h4>
      <div className="custom-control custom-switch">
        <input
          id="payouts-cutoff-input"
          type="checkbox"
          onChange={event => reference.update({enablePayouts: !enablePayouts})}
          className={joinClassNames('custom-control-input', error && 'is-invalid')}
          checked={enablePayouts}
          disabled={isLoading || error}
        />
        <label htmlFor="payouts-cutoff-input" className="custom-control-label">
          {isLoading ? 'Loading...' : `Instant payout ${enablePayouts ? 'enabled' : 'disabled'}`}
        </label>
        <small className="invalid-feedback">{error?.message}</small>
      </div>
      <small>
        If you need to enable or disable a specific driver: go to their profile page, then scroll down to "Earnings".
      </small>

      {/* <hr /> */}
      {/* <h4>Payouts</h4>
            <PayoutsTable /> */}
    </Card>
  );
}
