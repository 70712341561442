import React from 'react';
import unwrap from '../../../functions/unwrap';
import useEditTripModal from '../../../hooks/useEditTripModal';
import Trip from '../../../models/scoopm/Trip';
import {TripSource} from '../../../models/scoopm/TripSource';

export default function EditRide({tripId, trip, minimal}: {tripId: string; trip: Trip; minimal?: boolean}) {
  const onClickEdit = useEditTripModal(tripId, trip);

  if (minimal) {
    return null;
  }

  const isEditable = unwrap(
    trip,
    data =>
      unwrap(
        data.source,
        source =>
          unwrap(
            TripSource.from(source) as TripSource,
            source => source.isEditable(),
            true, // if couldn't determine source
          ),
        true, // if coulen't unwrap source (null)
      ),
    false, // if trip data isn't loaded yet
  );

  return isEditable ? (
    <button onClick={onClickEdit} className="btn btn-outline-secondary ml-2">
      Edit
    </button>
  ) : null;
}
