import firebase from 'firebase/app';
import RealtimeDatabaseModel from '../base/RealtimeDatabaseModel';
import TripType from './TripType';

export default class DriverGeoFire extends RealtimeDatabaseModel {
  static remove(id: string) {
    const batch = Object.assign(
      {},
      ...TripType.allCases.map(status => ({
        [`drivers-geo-fire/${id}/${status.rawValue}`]: null,
      })),
    );
    return firebase.database().ref().update(batch);
  }
}
