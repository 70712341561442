import StripeObject from './StripeObject';

export default interface Customer extends StripeObject {
  name?: string;
  email?: string;
}

export function customerDescription(customer: Customer): string {
  return customer.name || customer.email || customer.id;
}
