import React from 'react';
import {Link} from 'react-router-dom';
import Delay from '../../components/Delay';
import Spinner from '../../components/Spinner';
import unwrap from '../../functions/unwrap';
import useRealtimeDatabase from '../../hooks/useRealtimeDatabase';
import Driver from '../../models/scoopm/Driver';
import {None} from './PropertyDisplay';

export default function UidDisplay({value}) {
  const {data, error} = useRealtimeDatabase({
    path: unwrap(value, value => `/users/${value}`),
  });

  if (!value) {
    return <None />;
  }

  if (error) {
    return <span className="text-muted">Unable to get user name</span>;
  }

  if (!data) {
    return (
      <Delay>
        <Spinner small />
      </Delay>
    );
  }

  return (
    <Link to={`/admin/users/${value}`} className="text-decoration-underline">
      {Driver.prototype.fullName.apply(data)}
    </Link>
  );
}
