import firebase from 'firebase/app';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import FirestoreQueryTable from '../../components/FirestoreQueryTable';
import {useAuthContext} from '../../contexts/AuthContext';
import TitleContext from '../../contexts/TitleContext';
import cleanForFirebase from '../../functions/cleanForFirebase';
import reversed from '../../functions/reversed';
import unwrap from '../../functions/unwrap';
import useFirestoreQueryListener from '../../hooks/useFirestoreQueryListener';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import FirestoreQuery from '../../models/scoopm/FirestoreQuery';
// import useVendors from '../../useVendors'
// import Invoice from '../admin/invoices/Invoice'
import CurrentRoutesContext from './CurrentRoutesContext';
import ModifiedRootContext from './ModifiedRootContext';
import rootInitialValue from './root';
import Route from './Route';
import RouteComponent from './RouteComponent';

function match(route, pathElements, currentRoutes = []) {
  // console.log('ROUTER', 'currentRoutes', currentRoutes.map(r => r.path || 'null').join('/'))

  if (!pathElements.length) {
    if (route.children) {
      for (let i = 0; i < route.children.length; i++) {
        const child = route.children[i];
        if (!child.path) {
          // console.log('ROUTER', 'Found emptry child', child)
          currentRoutes.push(child);
          return currentRoutes;
        }
      }
    }

    // console.log('ROUTER', 'No more path elements')
    return currentRoutes;
  }

  if (!route.children) {
    // console.log('ROUTER', 'No children')
    return currentRoutes;
  }

  for (let i = 0; i < route.children.length; i++) {
    const child = route.children[i];

    // console.log('ROUTER', 'FINDING', child.path, pathElements[0])

    if (child.path === pathElements[0] || (child.path && child.path[0] === ':')) {
      currentRoutes.push(child);
      // console.log('ROUTER', 'going deeper')
      return match(child, pathElements.slice(1), currentRoutes);
    }

    if (!child.path && child.children) {
      for (let j = 0; j < child.children.length; j++) {
        const child2 = child.children[j];

        // console.log('ROUTER', 'FINDING 2', child2.path, pathElements[0])

        if (child2.path === pathElements[0] || (child2.path && child2.path[0] === ':')) {
          currentRoutes.push(child, child2);
          // console.log('ROUTER', 'going deeper with child 2', child2)
          return match(child2, pathElements.slice(1), currentRoutes);
        }
      }
    }
  }

  // console.log('ROUTER', 'Child not found')
  return currentRoutes;
}

export default function Router() {
  const isMountedRef = useIsMountedRef();

  const [root, setRoot] = useState(rootInitialValue);
  const {user} = useAuthContext();

  const {snapshot: queries} = useFirestoreQueryListener(
    useMemo(
      () =>
        unwrap(user, user =>
          firebase.firestore().collection('queries').where('uid', '==', user.uid).orderBy('timestamp'),
        ),
      [user],
    ),
  );

  const location = useLocation();
  const [currentRoutesState, setCurrentRoutesState] = useState({
    currentRoutes: undefined,
    currentRoute: undefined,
    currentVisibleRoute: undefined,
  });
  const {title} = useContext(TitleContext);

  // const vendors = useVendors()

  // EFFECT: only once, set up queries if none

  useEffect(() => {
    if (user) {
      // check queries
      firebase
        .firestore()
        .collection('queries')
        .where('uid', '==', user.uid)
        .orderBy('timestamp')
        .get()
        .then(snapshot => {
          if (!isMountedRef.current) return;
          // if no queries
          if (snapshot.empty) {
            // add new queries
            console.log('Settting up queries...');
            return firebase
              .auth()
              .currentUser.getIdTokenResult(true)
              .then(result =>
                Promise.all(
                  FirestoreQuery.defaultQueries(result.claims).map(([queryId, query]) =>
                    firebase.firestore().collection('queries').doc(queryId).set(cleanForFirebase(query)),
                  ),
                ),
              );
          }
        })
        .catch(error => {
          console.error('Error setting up new firestore queries', error);
        });
    }
  }, [user, isMountedRef]);

  // EFFECT: firestore queries, vendors --> menu items

  useEffect(() => {
    let root = rootInitialValue;

    // if (vendors.models) {
    //     const routes = vendors.models.map(
    //         vendor =>
    //             new Route({
    //                 name: vendor.name ?? '',
    //                 path: vendor.id,
    //                 Component: () => <Invoice vendor={vendor} />,
    //             })
    //     )
    //     root = newRoot(root, routes, 'invoices')
    // }

    if (queries) {
      const routes = queries.docs.map(
        doc =>
          new Route({
            name: doc.get('name') || 'Unnamed',
            path: doc.get('path'),
            Component: () => <FirestoreQueryTable queryId={doc.id} />,
          }),
      );
      root = newRoot(root, routes, 'rides');
    }

    setRoot(root);
  }, [queries]);

  // EFFECT: location --> current routes state

  useEffect(() => {
    const currentRoutes = match(root, location.pathname.split('/').filter(Boolean));
    const currentRoute = last(currentRoutes);

    setCurrentRoutesState({
      currentRoutes,
      currentRoute,
      currentVisibleRoute: last(currentRoutes.filter(route => route.name && !route.hideFromMenu)),
    });

    let titleNames = [];
    if (title) titleNames.push(title);
    if (currentRoute) {
      const chainNames = reversed(currentRoute.chain.map(route => route.name).filter(Boolean));
      titleNames.push(...chainNames);
    }
    titleNames.push('Wave');
    const titleString = titleNames.join(' | ');
    window.document.title = titleString;
  }, [root, location, title]);

  // RENDER

  return (
    <CurrentRoutesContext.Provider value={currentRoutesState}>
      <ModifiedRootContext.Provider value={root}>
        <RouteComponent route={root} />
      </ModifiedRootContext.Provider>
    </CurrentRoutesContext.Provider>
  );
}

function newRoot(root, routes, searchPath, before = true) {
  let newRoot = new Route(cloneDeep(root));
  if (routes) {
    const index = newRoot.children[0].children.findIndex(route => route.path === searchPath);
    newRoot.children[0].children[index] = new Route(newRoot.children[0].children[index]);
    newRoot.children[0].children[index].children = [...newRoot.children[0].children[index].children];
    if (before) {
      newRoot.children[0].children[index].children = [
        ...routes.map(route => {
          route.parent = newRoot.children[0].children[index];
          return route;
        }),
        ...newRoot.children[0].children[index].children,
      ];
    } else {
      newRoot.children[0].children[index].children = [
        ...newRoot.children[0].children[index].children,
        ...routes.map(route => {
          route.parent = newRoot.children[0].children[index];
          return route;
        }),
      ];
    }
  }
  return newRoot;
}
