import React, {MouseEvent, useState} from 'react';
import DropdownMenu from '../../../components/DropdownMenu';
import Trip from '../../../models/scoopm/Trip';
import ScoopMApi from '../../../references/scoopm-api';

export default function AcceptDelivery({trip, className}: {trip: Trip; className?: string}) {
  const [isAccepting, setIsAccepting] = useState(false);

  const [paddingM, setPaddingM] = useState(trip.ezCaterPaddingM || '');

  const __float = parseFloat(paddingM);
  const parsedPaddingM = isNaN(paddingM) ? null : isNaN(__float) ? null : __float;

  const onClickAccept = (event: MouseEvent) => {
    setIsAccepting(true);
    ScoopMApi.instance.dispatch.ezCater.deliveries
      .child(trip.ezCaterDeliveryId)
      .accept.patch(parsedPaddingM)
      .then(
        () => {
          window.alert('Successfully accepted');
        },
        (error: any) => {
          console.error('Error accepting delivery', error);
          window.alert(`Unable to accept delivery. ${error.message}`);
        },
      )
      .finally(() => {
        setIsAccepting(false);
      });
  };

  if (!Trip.prototype.canBeAcceptedForEzCater.apply(trip)) {
    return null;
  }

  return (
    <DropdownMenu
      text={trip.requiresResponse ? 'Accept (confirm update)' : 'Accept'}
      buttonClassName="btn-outline-success ml-2"
      className={className}>
      <div className="p-2 p-md-3">
        <label htmlFor="paddingInput">Padding</label>
        <input
          id="paddingInput"
          name="paddingInput"
          // type="number"
          value={paddingM}
          onChange={event => setPaddingM(event.target.value)}
          placeholder="Padding in minutes"
          className="form-control mb-2 mb-md-3"
          autoComplete="off"
        />
        <button
          onClick={onClickAccept}
          className="btn btn-success"
          disabled={!parsedPaddingM || parsedPaddingM <= 0 || isAccepting}>
          Confirm {isAccepting && <i className="fa fa-cog fa-spin ml-1" />}
        </button>
      </div>
    </DropdownMenu>
  );
}
