import find from 'lodash/find';
import {useMemo, useState} from 'react';
import {CoordinatesArray} from 'wave-common';
import unwrap from '../functions/unwrap';
import useDeepComparisonEffect from './useDeepComparisonEffect';
import useGeoFireQuery, {Item} from './useGeoFireQuery';

interface Result<T> {
  isLoading: boolean;
  items?: Item<T>[];
}

export default function useGeoFireDatabaseJoin<T>(args: {
  realtimeDatabaseReference: any;
  centerCoordinatesArray?: CoordinatesArray;
  radiusKm?: number;
  getJoinReference: (key: string) => any;
}) {
  const {realtimeDatabaseReference, centerCoordinatesArray, radiusKm, getJoinReference} = args;

  const geoFireItems = useGeoFireQuery<T>({
    realtimeDatabaseReference,
    centerCoordinatesArray,
    radiusKm,
  });
  const [geoFireItemsJoined, setGeoFireItemsJoined] = useState<Item<T>[] | undefined>();

  useDeepComparisonEffect(() => {
    // find items which don't exist in the join
    // for those do, preserve their values

    const itemsForJoin = [] as Item<T>[];

    const joinedItems = (geoFireItems || []).map(item => {
      const joinedItem = find(geoFireItemsJoined, joinedItem => joinedItem.key === item.key);
      if (joinedItem) {
        return new Item(item.key, item.coordinatesArray, item.distanceKm, joinedItem.value);
      } else {
        itemsForJoin.push(item);
        return item;
      }
    });
    setGeoFireItemsJoined(joinedItems);

    // fetch un-joined items

    Promise.all(
      itemsForJoin.map(async item => {
        console.log(`Joining ${item.key}...`);
        const [value] = await getJoinReference(item.key).once();
        return {item, value};
      }),
    ).then(results => {
      setGeoFireItemsJoined(latestJoinedItems => {
        const newJoinedItems = [...(latestJoinedItems ?? [])];
        const newEntriesWithModels = newJoinedItems.map(item => {
          const result = results.find(result => result.item.key === item.key);
          if (result) {
            return new Item(item.key, item.coordinatesArray, item.distanceKm, result.value);
          } else {
            return item;
          }
        });
        return newEntriesWithModels;
      });
    }, console.warn);
  }, [geoFireItems, geoFireItemsJoined, getJoinReference]);

  const result: Result<T> = useMemo(() => {
    const loadedItems = unwrap(geoFireItemsJoined, items => items.filter(item => item.value)) || undefined;
    return {
      isLoading: !loadedItems || !geoFireItemsJoined || loadedItems.length < geoFireItemsJoined.length,
      items: loadedItems,
    };
  }, [geoFireItemsJoined]);

  return result;
}
