import WaveCommonTrip from 'wave-common/lib/models/Trip';
import ScoopMApi from '.';
import TripModel from '../../models/scoopm/Trip';
import HttpReference from '../platform/HttpReference';
import Trip from './Trip';

export default class Trips extends HttpReference<TripModel, Trip, ScoopMApi> {
  child(path: string): Trip {
    return super.child(path) as Trip;
  }

  get childReferenceType() {
    return Trip;
  }

  create(trip: Omit<WaveCommonTrip, 'id'>, paymentMethodId: string | undefined) {
    return this.request({
      method: 'POST',
      data: {
        trip,
        paymentMethodId,
      },
    });
  }
}
