import firebase from 'firebase/app';
import chunk from 'lodash/chunk';
import flatMap from 'lodash/flatMap';
import React from 'react';
import Card from '../../../components/Card';
import csvFromKeysAndObjects, {Key} from '../../../functions/csvFromKeysAndObjects';
import {formatPhone} from '../../../functions/formatPhone';
import {simluateDownloadClickCsv} from '../../../functions/simulateDownloadClick';
import {unwrap} from '../../../functions/unwrap';
import useStatus, {StatusButton} from '../../../hooks/useStatus';
import Driver from '../../../models/scoopm/Driver';
import ScoopMApi from '../../../references/scoopm-api';

export default function CSV() {
  const {status, handlePromise} = useStatus();

  return (
    <Card padded>
      <h4>Users CSV</h4>
      <p>
        <small>All users will be downloaded, including their email addresses</small>
      </p>
      <StatusButton status={status} onClick={() => handlePromise(getData(), true, true)} color="primary">
        Download
      </StatusButton>
    </Card>
  );
}

async function getData() {
  console.clear();

  const usersSnapshot = await firebase.database().ref('users').once('value');

  const users = Object.entries(usersSnapshot.val()).map(([uid, user]: [string, any]) => ({
    id: uid,
    ...user,
  }));

  const userChunks = chunk(users, 100);

  const usersWithEmails = flatMap(
    await Promise.all(
      userChunks.map(users =>
        ScoopMApi.instance.users.emails.get(users.map(user => user.id)).then((response: any) =>
          users.map(user => ({
            email: response.data[user.id]?.email ?? user.email,
            ...user,
          })),
        ),
      ),
    ),
  );

  (window as any).usersWithEmails = usersWithEmails;
  console.log(usersWithEmails);

  const keys: Key[] = [
    {heading: 'ID', key: 'id'},
    {heading: 'First name', key: 'firstName'},
    {heading: 'Last name', key: 'lastName'},
    {heading: 'Email', key: 'email'},
    {
      heading: 'Phone',
      getValue: value => Driver.prototype.safePhoneNumber.apply(value),
      format: formatPhone,
    },
    {
      heading: 'Address',
      key: 'address',
      format: value => unwrap(value, Driver.prototype.addressString),
    },
  ];
  const csvString = csvFromKeysAndObjects(keys, usersWithEmails);
  simluateDownloadClickCsv(csvString, undefined, 'users', 'emails');
}
