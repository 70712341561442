import React, {useContext, useEffect} from 'react';

interface AdditionalMenuSectionsContextInterface {
  setAdditionalMenuSections: (sections?: React.ReactNode) => void;
}

const AdditionalMenuSectionsContext = React.createContext({} as AdditionalMenuSectionsContextInterface);

export default AdditionalMenuSectionsContext;

export function useAdditionalMenuSections(sections: React.ReactNode) {
  const {setAdditionalMenuSections} = useContext(AdditionalMenuSectionsContext);

  useEffect(() => {
    setAdditionalMenuSections(sections);
    return function () {
      setAdditionalMenuSections(undefined);
    };
  }, [sections, setAdditionalMenuSections]);
}
