"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function unwrap(value, callback, fallbackCallback) {
    if (value === undefined ||
        value === null ||
        (typeof value === 'string' && value.length === 0) ||
        (typeof value === 'number' && isNaN(value))) {
        if (fallbackCallback) {
            return fallbackCallback();
        }
        else {
            return undefined;
        }
    }
    else {
        return callback(value);
    }
}
exports.default = unwrap;
