import React from 'react';
import TransportType from 'wave-common/lib/models/TransportType';
import joinClassNames from '../functions/joinClassNames';

/**
 * Find icons here: https://fontawesome.com/v5/search?m=free
 */

interface FontAwesomeElementProps {
  fontClassName: string;
  className?: string;
  style?: object;
  marginRight?: boolean;
  square?: boolean;
}

function FontAwesomeElement(props: FontAwesomeElementProps) {
  return (
    <i
      className={joinClassNames(
        props.fontClassName,
        props.className,
        props.marginRight && 'mr-2',
        props.square && 'text-center',
      )}
      style={props.square ? {width: '1em', ...props.style} : props.style}
    />
  );
}

function withFontClassName(fontClassName: string, isSolid: Boolean = false) {
  return function (props: Omit<FontAwesomeElementProps, 'fontClassName'>) {
    return <FontAwesomeElement fontClassName={joinClassNames(isSolid ? 'fas' : 'fa', fontClassName)} {...props} />;
  };
}

const FontAwesome = {
  Empty: withFontClassName('not-an-icon'),

  ArrowDownSolid: withFontClassName('fa-arrow-down', true),
  ArrowLeftSolid: withFontClassName('fa-arrow-left', true),
  ArrowRightSolid: withFontClassName('fa-arrow-right', true),
  ArrowRightFromBracket: withFontClassName('fa-arrow-right-from-bracket', true),
  ArrowRightToBracket: withFontClassName('fa-arrow-right-to-bracket', true),
  Backward: withFontClassName('fa-backward', true),
  BrowserSolid: withFontClassName('fa-Browser', true),
  CalendarAlt: withFontClassName('fa-calendar-alt', true),
  CarSolid: withFontClassName('fa-car', true),
  CheckSolid: withFontClassName('fa-check', true),
  CheckCircle: withFontClassName('fa-check-circle'),
  CheckCircleSolid: withFontClassName('fa-check-circle', true),
  Circle: withFontClassName('fa-circle', true),
  Clipboard: withFontClassName('fa-clipboard', true),
  ClipboardCheck: withFontClassName('fa-clipboard-check', true),
  Clock: withFontClassName('fa-clock'),
  ClockSolid: withFontClassName('fa-clock', true),
  Cog: withFontClassName('fa-cog', true),
  CrosshairsSolid: withFontClassName('fa-crosshairs', true),
  DollarSignSolid: withFontClassName('fa-dollar-sign', true),
  DownloadSolid: withFontClassName('fa-download', true),
  Dot: withFontClassName('fa-dot-circle'),
  Edit: withFontClassName('fa-edit'),
  EditSolid: withFontClassName('fa-edit', true),
  EllipsisVerticalSolid: withFontClassName('fa-ellipsis-v', true),
  ExclamationSolid: withFontClassName('fa-exclamation', true),
  ExclamationCircle: withFontClassName('fa-exclamation-circle'),
  ExclamationCircleSolid: withFontClassName('fa-exclamation-circle', true),
  ExternalLinkAlt: withFontClassName('fa-external-link-alt'),
  ExternalLinkAltSolid: withFontClassName('fa-external-link-alt', true),
  EyeSolid: withFontClassName('fa-eye', true),
  EyeSlashSolid: withFontClassName('fa-eye-slash', true),
  Forward: withFontClassName('fa-forward', true),
  GripVerticalSolid: withFontClassName('fa-grip-vertical', true),
  InfoCircle: withFontClassName('fa-info-circle', true),
  LinkSolid: withFontClassName('fa-link', true),
  LocationArrowSolid: withFontClassName('fa-location-arrow', true),
  Lock: withFontClassName('fa-lock', true),
  LongArrowAltDown: withFontClassName('fa-long-arrow-alt-down', true),
  LightBulb: withFontClassName('fa-lightbulb'),
  List: withFontClassName('fa-list'),
  MapMarkerAltSolid: withFontClassName('fa-map-marker-alt', true),
  MagnifyingGlass: withFontClassName('fa-magnifying-glass', true),
  Minus: withFontClassName('fa-minus'),
  MobileSolid: withFontClassName('fa-mobile', true),
  Plus: withFontClassName('fa-plus', true),
  RotateLeft: withFontClassName('fa-rotate-left', true),
  RotateRight: withFontClassName('fa-rotate-right', true),
  RouteSolid: withFontClassName('fa-route', true),
  Save: withFontClassName('fa-save'),
  SaveSolid: withFontClassName('fa-save', true),
  ServerSolid: withFontClassName('fa-server', true),
  Times: withFontClassName('fa-times'),
  TimesCircle: withFontClassName('fa-times-circle'),
  TimesCircleSolid: withFontClassName('fa-times-circle', true),
  Trash: withFontClassName('fa-trash'),
  TrashAlt: withFontClassName('fa-trash-alt'),
  TrashAltSolid: withFontClassName('fa-trash-alt', true),
  UpDownLeftRight: withFontClassName('fa-up-down-left-right', true),
  UserSolid: withFontClassName('fa-user', true),
  VideoSolid: withFontClassName('fa-video', true),
  Bicycle: withFontClassName('fa-bicycle', true),
  Motorcycle: withFontClassName('fa-motorcycle', true),
  Pen: withFontClassName('fa-pen', true),
  PersonWalking: withFontClassName('fa-person-walking', true),
  QuestionCircle: withFontClassName('fa-question-circle', true),
};

export default FontAwesome;

export function FontAwesomeForTransportType({type}: {type: TransportType}) {
  switch (type) {
    case 'BICYCLE':
      return <FontAwesome.Bicycle />;
    case 'CYCLE_WITH_CARGO':
      return <FontAwesome.Motorcycle />;
    case 'WALKING':
      return <FontAwesome.PersonWalking />;
    default:
      return null;
  }
}

export function FontAwesomeV5({
  name,
  type = 'solid',
  className,
}: {
  name: FontAwesomeV5Name;
  type?: FontAwesomeV5Type;
  className?: string;
}) {
  return <i className={joinClassNames(`fa-${name} fa-${type}`, className)} />;
}

type FontAwesomeV5Type = 'solid' | 'regular';

type FontAwesomeV5Name =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | 'a'
  | 'address-book'
  | 'contact-book'
  | 'address-card'
  | 'contact-card'
  | 'vcard'
  | 'align-center'
  | 'align-justify'
  | 'align-left'
  | 'align-right'
  | 'anchor'
  | 'anchor-circle-check'
  | 'anchor-circle-exclamation'
  | 'anchor-circle-xmark'
  | 'anchor-lock'
  | 'angle-down'
  | 'angle-left'
  | 'angle-right'
  | 'angle-up'
  | 'angles-down'
  | 'angle-double-down'
  | 'angles-left'
  | 'angle-double-left'
  | 'angles-right'
  | 'angle-double-right'
  | 'angles-up'
  | 'angle-double-up'
  | 'ankh'
  | 'apple-whole'
  | 'apple-alt'
  | 'archway'
  | 'arrow-down'
  | 'arrow-down-1-9'
  | 'sort-numeric-asc'
  | 'sort-numeric-down'
  | 'arrow-down-9-1'
  | 'sort-numeric-desc'
  | 'sort-numeric-down-alt'
  | 'arrow-down-a-z'
  | 'sort-alpha-asc'
  | 'sort-alpha-down'
  | 'arrow-down-long'
  | 'long-arrow-down'
  | 'arrow-down-short-wide'
  | 'sort-amount-desc'
  | 'sort-amount-down-alt'
  | 'arrow-down-up-across-line'
  | 'arrow-down-up-lock'
  | 'arrow-down-wide-short'
  | 'sort-amount-asc'
  | 'sort-amount-down'
  | 'arrow-down-z-a'
  | 'sort-alpha-desc'
  | 'sort-alpha-down-alt'
  | 'arrow-left'
  | 'arrow-left-long'
  | 'long-arrow-left'
  | 'arrow-pointer'
  | 'mouse-pointer'
  | 'arrow-right'
  | 'arrow-right-arrow-left'
  | 'exchange'
  | 'arrow-right-from-bracket'
  | 'sign-out'
  | 'arrow-right-long'
  | 'long-arrow-right'
  | 'arrow-right-to-bracket'
  | 'sign-in'
  | 'arrow-right-to-city'
  | 'arrow-rotate-left'
  | 'arrow-left-rotate'
  | 'arrow-rotate-back'
  | 'arrow-rotate-backward'
  | 'undo'
  | 'arrow-rotate-right'
  | 'arrow-right-rotate'
  | 'arrow-rotate-forward'
  | 'redo'
  | 'arrow-trend-down'
  | 'arrow-trend-up'
  | 'arrow-turn-down'
  | 'level-down'
  | 'arrow-turn-up'
  | 'level-up'
  | 'arrow-up'
  | 'arrow-up-1-9'
  | 'sort-numeric-up'
  | 'arrow-up-9-1'
  | 'sort-numeric-up-alt'
  | 'arrow-up-a-z'
  | 'sort-alpha-up'
  | 'arrow-up-from-bracket'
  | 'arrow-up-from-ground-water'
  | 'arrow-up-from-water-pump'
  | 'arrow-up-long'
  | 'long-arrow-up'
  | 'arrow-up-right-dots'
  | 'arrow-up-right-from-square'
  | 'external-link'
  | 'arrow-up-short-wide'
  | 'sort-amount-up-alt'
  | 'arrow-up-wide-short'
  | 'sort-amount-up'
  | 'arrow-up-z-a'
  | 'sort-alpha-up-alt'
  | 'arrows-down-to-line'
  | 'arrows-down-to-people'
  | 'arrows-left-right'
  | 'arrows-h'
  | 'arrows-left-right-to-line'
  | 'arrows-rotate'
  | 'refresh'
  | 'sync'
  | 'arrows-spin'
  | 'arrows-split-up-and-left'
  | 'arrows-to-circle'
  | 'arrows-to-dot'
  | 'arrows-to-eye'
  | 'arrows-turn-right'
  | 'arrows-turn-to-dots'
  | 'arrows-up-down'
  | 'arrows-v'
  | 'arrows-up-down-left-right'
  | 'arrows'
  | 'arrows-up-to-line'
  | 'asterisk'
  | 'at'
  | 'atom'
  | 'audio-description'
  | 'austral-sign'
  | 'award'
  | 'b'
  | 'baby'
  | 'baby-carriage'
  | 'carriage-baby'
  | 'backward'
  | 'backward-fast'
  | 'fast-backward'
  | 'backward-step'
  | 'step-backward'
  | 'bacon'
  | 'bacteria'
  | 'bacterium'
  | 'bag-shopping'
  | 'shopping-bag'
  | 'bahai'
  | 'haykal'
  | 'baht-sign'
  | 'ban'
  | 'cancel'
  | 'ban-smoking'
  | 'smoking-ban'
  | 'bandage'
  | 'band-aid'
  | 'barcode'
  | 'bars'
  | 'navicon'
  | 'bars-progress'
  | 'tasks-alt'
  | 'bars-staggered'
  | 'reorder'
  | 'stream'
  | 'baseball'
  | 'baseball-ball'
  | 'baseball-bat-ball'
  | 'basket-shopping'
  | 'shopping-basket'
  | 'basketball'
  | 'basketball-ball'
  | 'bath'
  | 'bathtub'
  | 'battery-empty'
  | 'battery-0'
  | 'battery-full'
  | 'battery'
  | 'battery-5'
  | 'battery-half'
  | 'battery-3'
  | 'battery-quarter'
  | 'battery-2'
  | 'battery-three-quarters'
  | 'battery-4'
  | 'bed'
  | 'bed-pulse'
  | 'procedures'
  | 'beer-mug-empty'
  | 'beer'
  | 'bell'
  | 'bell-concierge'
  | 'concierge-bell'
  | 'bell-slash'
  | 'bezier-curve'
  | 'bicycle'
  | 'binoculars'
  | 'biohazard'
  | 'bitcoin-sign'
  | 'blender'
  | 'blender-phone'
  | 'blog'
  | 'bold'
  | 'bolt'
  | 'zap'
  | 'bolt-lightning'
  | 'bomb'
  | 'bone'
  | 'bong'
  | 'book'
  | 'book-atlas'
  | 'atlas'
  | 'book-bible'
  | 'bible'
  | 'book-bookmark'
  | 'book-journal-whills'
  | 'journal-whills'
  | 'book-medical'
  | 'book-open'
  | 'book-open-reader'
  | 'book-reader'
  | 'book-quran'
  | 'quran'
  | 'book-skull'
  | 'book-dead'
  | 'book-tanakh'
  | 'tanakh'
  | 'bookmark'
  | 'border-all'
  | 'border-none'
  | 'border-top-left'
  | 'border-style'
  | 'bore-hole'
  | 'bottle-droplet'
  | 'bottle-water'
  | 'bowl-food'
  | 'bowl-rice'
  | 'bowling-ball'
  | 'box'
  | 'box-archive'
  | 'archive'
  | 'box-open'
  | 'box-tissue'
  | 'boxes-packing'
  | 'boxes-stacked'
  | 'boxes'
  | 'boxes-alt'
  | 'braille'
  | 'brain'
  | 'brazilian-real-sign'
  | 'bread-slice'
  | 'bridge'
  | 'bridge-circle-check'
  | 'bridge-circle-exclamation'
  | 'bridge-circle-xmark'
  | 'bridge-lock'
  | 'bridge-water'
  | 'briefcase'
  | 'briefcase-medical'
  | 'broom'
  | 'broom-ball'
  | 'quidditch'
  | 'quidditch-broom-ball'
  | 'brush'
  | 'bucket'
  | 'bug'
  | 'bug-slash'
  | 'bugs'
  | 'building'
  | 'building-circle-arrow-right'
  | 'building-circle-check'
  | 'building-circle-exclamation'
  | 'building-circle-xmark'
  | 'building-columns'
  | 'bank'
  | 'institution'
  | 'museum'
  | 'university'
  | 'building-flag'
  | 'building-lock'
  | 'building-ngo'
  | 'building-shield'
  | 'building-un'
  | 'building-user'
  | 'building-wheat'
  | 'bullhorn'
  | 'bullseye'
  | 'burger'
  | 'hamburger'
  | 'burst'
  | 'bus'
  | 'bus-simple'
  | 'bus-alt'
  | 'business-time'
  | 'briefcase-clock'
  | 'c'
  | 'cable-car'
  | 'tram'
  | 'cake-candles'
  | 'birthday-cake'
  | 'cake'
  | 'calculator'
  | 'calendar'
  | 'calendar-check'
  | 'calendar-day'
  | 'calendar-days'
  | 'calendar-alt'
  | 'calendar-minus'
  | 'calendar-plus'
  | 'calendar-week'
  | 'calendar-xmark'
  | 'calendar-times'
  | 'camera'
  | 'camera-alt'
  | 'camera-retro'
  | 'camera-rotate'
  | 'campground'
  | 'candy-cane'
  | 'cannabis'
  | 'capsules'
  | 'car'
  | 'automobile'
  | 'car-battery'
  | 'battery-car'
  | 'car-burst'
  | 'car-crash'
  | 'car-on'
  | 'car-rear'
  | 'car-alt'
  | 'car-side'
  | 'car-tunnel'
  | 'caravan'
  | 'caret-down'
  | 'caret-left'
  | 'caret-right'
  | 'caret-up'
  | 'carrot'
  | 'cart-arrow-down'
  | 'cart-flatbed'
  | 'dolly-flatbed'
  | 'cart-flatbed-suitcase'
  | 'luggage-cart'
  | 'cart-plus'
  | 'cart-shopping'
  | 'shopping-cart'
  | 'cash-register'
  | 'cat'
  | 'cedi-sign'
  | 'cent-sign'
  | 'certificate'
  | 'chair'
  | 'chalkboard'
  | 'blackboard'
  | 'chalkboard-user'
  | 'chalkboard-teacher'
  | 'champagne-glasses'
  | 'glass-cheers'
  | 'charging-station'
  | 'chart-area'
  | 'area-chart'
  | 'chart-bar'
  | 'bar-chart'
  | 'chart-column'
  | 'chart-gantt'
  | 'chart-line'
  | 'line-chart'
  | 'chart-pie'
  | 'pie-chart'
  | 'chart-simple'
  | 'check'
  | 'check-double'
  | 'check-to-slot'
  | 'vote-yea'
  | 'cheese'
  | 'chess'
  | 'chess-bishop'
  | 'chess-board'
  | 'chess-king'
  | 'chess-knight'
  | 'chess-pawn'
  | 'chess-queen'
  | 'chess-rook'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'child'
  | 'child-dress'
  | 'child-reaching'
  | 'child-rifle'
  | 'children'
  | 'church'
  | 'circle'
  | 'circle-arrow-down'
  | 'arrow-circle-down'
  | 'circle-arrow-left'
  | 'arrow-circle-left'
  | 'circle-arrow-right'
  | 'arrow-circle-right'
  | 'circle-arrow-up'
  | 'arrow-circle-up'
  | 'circle-check'
  | 'check-circle'
  | 'circle-chevron-down'
  | 'chevron-circle-down'
  | 'circle-chevron-left'
  | 'chevron-circle-left'
  | 'circle-chevron-right'
  | 'chevron-circle-right'
  | 'circle-chevron-up'
  | 'chevron-circle-up'
  | 'circle-dollar-to-slot'
  | 'donate'
  | 'circle-dot'
  | 'dot-circle'
  | 'circle-down'
  | 'arrow-alt-circle-down'
  | 'circle-exclamation'
  | 'exclamation-circle'
  | 'circle-h'
  | 'hospital-symbol'
  | 'circle-half-stroke'
  | 'adjust'
  | 'circle-info'
  | 'info-circle'
  | 'circle-left'
  | 'arrow-alt-circle-left'
  | 'circle-minus'
  | 'minus-circle'
  | 'circle-nodes'
  | 'circle-notch'
  | 'circle-pause'
  | 'pause-circle'
  | 'circle-play'
  | 'play-circle'
  | 'circle-plus'
  | 'plus-circle'
  | 'circle-question'
  | 'question-circle'
  | 'circle-radiation'
  | 'radiation-alt'
  | 'circle-right'
  | 'arrow-alt-circle-right'
  | 'circle-stop'
  | 'stop-circle'
  | 'circle-up'
  | 'arrow-alt-circle-up'
  | 'circle-user'
  | 'user-circle'
  | 'circle-xmark'
  | 'times-circle'
  | 'xmark-circle'
  | 'city'
  | 'clapperboard'
  | 'clipboard'
  | 'clipboard-check'
  | 'clipboard-list'
  | 'clipboard-question'
  | 'clipboard-user'
  | 'clock'
  | 'clock-four'
  | 'clock-rotate-left'
  | 'history'
  | 'clone'
  | 'closed-captioning'
  | 'cloud'
  | 'cloud-arrow-down'
  | 'cloud-download'
  | 'cloud-download-alt'
  | 'cloud-arrow-up'
  | 'cloud-upload'
  | 'cloud-upload-alt'
  | 'cloud-bolt'
  | 'thunderstorm'
  | 'cloud-meatball'
  | 'cloud-moon'
  | 'cloud-moon-rain'
  | 'cloud-rain'
  | 'cloud-showers-heavy'
  | 'cloud-showers-water'
  | 'cloud-sun'
  | 'cloud-sun-rain'
  | 'clover'
  | 'code'
  | 'code-branch'
  | 'code-commit'
  | 'code-compare'
  | 'code-fork'
  | 'code-merge'
  | 'code-pull-request'
  | 'coins'
  | 'colon-sign'
  | 'comment'
  | 'comment-dollar'
  | 'comment-dots'
  | 'commenting'
  | 'comment-medical'
  | 'comment-slash'
  | 'comment-sms'
  | 'sms'
  | 'comments'
  | 'comments-dollar'
  | 'compact-disc'
  | 'compass'
  | 'compass-drafting'
  | 'drafting-compass'
  | 'compress'
  | 'computer'
  | 'computer-mouse'
  | 'mouse'
  | 'cookie'
  | 'cookie-bite'
  | 'copy'
  | 'copyright'
  | 'couch'
  | 'cow'
  | 'credit-card'
  | 'credit-card-alt'
  | 'crop'
  | 'crop-simple'
  | 'crop-alt'
  | 'cross'
  | 'crosshairs'
  | 'crow'
  | 'crown'
  | 'crutch'
  | 'cruzeiro-sign'
  | 'cube'
  | 'cubes'
  | 'cubes-stacked'
  | 'd'
  | 'database'
  | 'delete-left'
  | 'backspace'
  | 'democrat'
  | 'desktop'
  | 'desktop-alt'
  | 'dharmachakra'
  | 'diagram-next'
  | 'diagram-predecessor'
  | 'diagram-project'
  | 'project-diagram'
  | 'diagram-successor'
  | 'diamond'
  | 'diamond-turn-right'
  | 'directions'
  | 'dice'
  | 'dice-d20'
  | 'dice-d6'
  | 'dice-five'
  | 'dice-four'
  | 'dice-one'
  | 'dice-six'
  | 'dice-three'
  | 'dice-two'
  | 'disease'
  | 'display'
  | 'divide'
  | 'dna'
  | 'dog'
  | 'dollar-sign'
  | 'dollar'
  | 'usd'
  | 'dolly'
  | 'dolly-box'
  | 'dong-sign'
  | 'door-closed'
  | 'door-open'
  | 'dove'
  | 'down-left-and-up-right-to-center'
  | 'compress-alt'
  | 'down-long'
  | 'long-arrow-alt-down'
  | 'download'
  | 'dragon'
  | 'draw-polygon'
  | 'droplet'
  | 'tint'
  | 'droplet-slash'
  | 'tint-slash'
  | 'drum'
  | 'drum-steelpan'
  | 'drumstick-bite'
  | 'dumbbell'
  | 'dumpster'
  | 'dumpster-fire'
  | 'dungeon'
  | 'e'
  | 'ear-deaf'
  | 'deaf'
  | 'deafness'
  | 'hard-of-hearing'
  | 'ear-listen'
  | 'assistive-listening-systems'
  | 'earth-africa'
  | 'globe-africa'
  | 'earth-americas'
  | 'earth'
  | 'earth-america'
  | 'globe-americas'
  | 'earth-asia'
  | 'globe-asia'
  | 'earth-europe'
  | 'globe-europe'
  | 'earth-oceania'
  | 'globe-oceania'
  | 'egg'
  | 'eject'
  | 'elevator'
  | 'ellipsis'
  | 'ellipsis-h'
  | 'ellipsis-vertical'
  | 'ellipsis-v'
  | 'envelope'
  | 'envelope-circle-check'
  | 'envelope-open'
  | 'envelope-open-text'
  | 'envelopes-bulk'
  | 'mail-bulk'
  | 'equals'
  | 'eraser'
  | 'ethernet'
  | 'euro-sign'
  | 'eur'
  | 'euro'
  | 'exclamation'
  | 'expand'
  | 'explosion'
  | 'eye'
  | 'eye-dropper'
  | 'eye-dropper-empty'
  | 'eyedropper'
  | 'eye-low-vision'
  | 'low-vision'
  | 'eye-slash'
  | 'f'
  | 'face-angry'
  | 'angry'
  | 'face-dizzy'
  | 'dizzy'
  | 'face-flushed'
  | 'flushed'
  | 'face-frown'
  | 'frown'
  | 'face-frown-open'
  | 'frown-open'
  | 'face-grimace'
  | 'grimace'
  | 'face-grin'
  | 'grin'
  | 'face-grin-beam'
  | 'grin-beam'
  | 'face-grin-beam-sweat'
  | 'grin-beam-sweat'
  | 'face-grin-hearts'
  | 'grin-hearts'
  | 'face-grin-squint'
  | 'grin-squint'
  | 'face-grin-squint-tears'
  | 'grin-squint-tears'
  | 'face-grin-stars'
  | 'grin-stars'
  | 'face-grin-tears'
  | 'grin-tears'
  | 'face-grin-tongue'
  | 'grin-tongue'
  | 'face-grin-tongue-squint'
  | 'grin-tongue-squint'
  | 'face-grin-tongue-wink'
  | 'grin-tongue-wink'
  | 'face-grin-wide'
  | 'grin-alt'
  | 'face-grin-wink'
  | 'grin-wink'
  | 'face-kiss'
  | 'kiss'
  | 'face-kiss-beam'
  | 'kiss-beam'
  | 'face-kiss-wink-heart'
  | 'kiss-wink-heart'
  | 'face-laugh'
  | 'laugh'
  | 'face-laugh-beam'
  | 'laugh-beam'
  | 'face-laugh-squint'
  | 'laugh-squint'
  | 'face-laugh-wink'
  | 'laugh-wink'
  | 'face-meh'
  | 'meh'
  | 'face-meh-blank'
  | 'meh-blank'
  | 'face-rolling-eyes'
  | 'meh-rolling-eyes'
  | 'face-sad-cry'
  | 'sad-cry'
  | 'face-sad-tear'
  | 'sad-tear'
  | 'face-smile'
  | 'smile'
  | 'face-smile-beam'
  | 'smile-beam'
  | 'face-smile-wink'
  | 'smile-wink'
  | 'face-surprise'
  | 'surprise'
  | 'face-tired'
  | 'tired'
  | 'fan'
  | 'faucet'
  | 'faucet-drip'
  | 'fax'
  | 'feather'
  | 'feather-pointed'
  | 'feather-alt'
  | 'ferry'
  | 'file'
  | 'file-arrow-down'
  | 'file-download'
  | 'file-arrow-up'
  | 'file-upload'
  | 'file-audio'
  | 'file-circle-check'
  | 'file-circle-exclamation'
  | 'file-circle-minus'
  | 'file-circle-plus'
  | 'file-circle-question'
  | 'file-circle-xmark'
  | 'file-code'
  | 'file-contract'
  | 'file-csv'
  | 'file-excel'
  | 'file-export'
  | 'arrow-right-from-file'
  | 'file-image'
  | 'file-import'
  | 'arrow-right-to-file'
  | 'file-invoice'
  | 'file-invoice-dollar'
  | 'file-lines'
  | 'file-alt'
  | 'file-text'
  | 'file-medical'
  | 'file-pdf'
  | 'file-pen'
  | 'file-edit'
  | 'file-powerpoint'
  | 'file-prescription'
  | 'file-shield'
  | 'file-signature'
  | 'file-video'
  | 'file-waveform'
  | 'file-medical-alt'
  | 'file-word'
  | 'file-zipper'
  | 'file-archive'
  | 'fill'
  | 'fill-drip'
  | 'film'
  | 'filter'
  | 'filter-circle-dollar'
  | 'funnel-dollar'
  | 'filter-circle-xmark'
  | 'fingerprint'
  | 'fire'
  | 'fire-burner'
  | 'fire-extinguisher'
  | 'fire-flame-curved'
  | 'fire-alt'
  | 'fire-flame-simple'
  | 'burn'
  | 'fish'
  | 'fish-fins'
  | 'flag'
  | 'flag-checkered'
  | 'flag-usa'
  | 'flask'
  | 'flask-vial'
  | 'floppy-disk'
  | 'save'
  | 'florin-sign'
  | 'folder'
  | 'folder-blank'
  | 'folder-closed'
  | 'folder-minus'
  | 'folder-open'
  | 'folder-plus'
  | 'folder-tree'
  | 'font'
  | 'football'
  | 'football-ball'
  | 'forward'
  | 'forward-fast'
  | 'fast-forward'
  | 'forward-step'
  | 'step-forward'
  | 'franc-sign'
  | 'frog'
  | 'futbol'
  | 'futbol-ball'
  | 'soccer-ball'
  | 'g'
  | 'gamepad'
  | 'gas-pump'
  | 'gauge'
  | 'dashboard'
  | 'gauge-med'
  | 'tachometer-alt-average'
  | 'gauge-high'
  | 'tachometer-alt'
  | 'tachometer-alt-fast'
  | 'gauge-simple'
  | 'gauge-simple-med'
  | 'tachometer-average'
  | 'gauge-simple-high'
  | 'tachometer'
  | 'tachometer-fast'
  | 'gavel'
  | 'legal'
  | 'gear'
  | 'cog'
  | 'gears'
  | 'cogs'
  | 'gem'
  | 'genderless'
  | 'ghost'
  | 'gift'
  | 'gifts'
  | 'glass-water'
  | 'glass-water-droplet'
  | 'glasses'
  | 'globe'
  | 'golf-ball-tee'
  | 'golf-ball'
  | 'gopuram'
  | 'graduation-cap'
  | 'mortar-board'
  | 'greater-than'
  | 'greater-than-equal'
  | 'grip'
  | 'grip-horizontal'
  | 'grip-lines'
  | 'grip-lines-vertical'
  | 'grip-vertical'
  | 'group-arrows-rotate'
  | 'guarani-sign'
  | 'guitar'
  | 'gun'
  | 'h'
  | 'hammer'
  | 'hamsa'
  | 'hand'
  | 'hand-paper'
  | 'hand-back-fist'
  | 'hand-rock'
  | 'hand-dots'
  | 'allergies'
  | 'hand-fist'
  | 'fist-raised'
  | 'hand-holding'
  | 'hand-holding-dollar'
  | 'hand-holding-usd'
  | 'hand-holding-droplet'
  | 'hand-holding-water'
  | 'hand-holding-hand'
  | 'hand-holding-heart'
  | 'hand-holding-medical'
  | 'hand-lizard'
  | 'hand-middle-finger'
  | 'hand-peace'
  | 'hand-point-down'
  | 'hand-point-left'
  | 'hand-point-right'
  | 'hand-point-up'
  | 'hand-pointer'
  | 'hand-scissors'
  | 'hand-sparkles'
  | 'hand-spock'
  | 'handcuffs'
  | 'hands'
  | 'sign-language'
  | 'signing'
  | 'hands-asl-interpreting'
  | 'american-sign-language-interpreting'
  | 'asl-interpreting'
  | 'hands-american-sign-language-interpreting'
  | 'hands-bound'
  | 'hands-bubbles'
  | 'hands-wash'
  | 'hands-clapping'
  | 'hands-holding'
  | 'hands-holding-child'
  | 'hands-holding-circle'
  | 'hands-praying'
  | 'praying-hands'
  | 'handshake'
  | 'handshake-angle'
  | 'hands-helping'
  | 'handshake-simple'
  | 'handshake-alt'
  | 'handshake-simple-slash'
  | 'handshake-alt-slash'
  | 'handshake-slash'
  | 'hanukiah'
  | 'hard-drive'
  | 'hdd'
  | 'hashtag'
  | 'hat-cowboy'
  | 'hat-cowboy-side'
  | 'hat-wizard'
  | 'head-side-cough'
  | 'head-side-cough-slash'
  | 'head-side-mask'
  | 'head-side-virus'
  | 'heading'
  | 'header'
  | 'headphones'
  | 'headphones-simple'
  | 'headphones-alt'
  | 'headset'
  | 'heart'
  | 'heart-circle-bolt'
  | 'heart-circle-check'
  | 'heart-circle-exclamation'
  | 'heart-circle-minus'
  | 'heart-circle-plus'
  | 'heart-circle-xmark'
  | 'heart-crack'
  | 'heart-broken'
  | 'heart-pulse'
  | 'heartbeat'
  | 'helicopter'
  | 'helicopter-symbol'
  | 'helmet-safety'
  | 'hard-hat'
  | 'hat-hard'
  | 'helmet-un'
  | 'highlighter'
  | 'hill-avalanche'
  | 'hill-rockslide'
  | 'hippo'
  | 'hockey-puck'
  | 'holly-berry'
  | 'horse'
  | 'horse-head'
  | 'hospital'
  | 'hospital-alt'
  | 'hospital-wide'
  | 'hospital-user'
  | 'hot-tub-person'
  | 'hot-tub'
  | 'hotdog'
  | 'hotel'
  | 'hourglass'
  | 'hourglass-empty'
  | 'hourglass-end'
  | 'hourglass-3'
  | 'hourglass-half'
  | 'hourglass-2'
  | 'hourglass-start'
  | 'hourglass-1'
  | 'house'
  | 'home'
  | 'home-alt'
  | 'home-lg-alt'
  | 'house-chimney'
  | 'home-lg'
  | 'house-chimney-crack'
  | 'house-damage'
  | 'house-chimney-medical'
  | 'clinic-medical'
  | 'house-chimney-user'
  | 'house-chimney-window'
  | 'house-circle-check'
  | 'house-circle-exclamation'
  | 'house-circle-xmark'
  | 'house-crack'
  | 'house-fire'
  | 'house-flag'
  | 'house-flood-water'
  | 'house-flood-water-circle-arrow-right'
  | 'house-laptop'
  | 'laptop-house'
  | 'house-lock'
  | 'house-medical'
  | 'house-medical-circle-check'
  | 'house-medical-circle-exclamation'
  | 'house-medical-circle-xmark'
  | 'house-medical-flag'
  | 'house-signal'
  | 'house-tsunami'
  | 'house-user'
  | 'home-user'
  | 'hryvnia-sign'
  | 'hryvnia'
  | 'hurricane'
  | 'i'
  | 'i-cursor'
  | 'ice-cream'
  | 'icicles'
  | 'icons'
  | 'heart-music-camera-bolt'
  | 'id-badge'
  | 'id-card'
  | 'drivers-license'
  | 'id-card-clip'
  | 'id-card-alt'
  | 'igloo'
  | 'image'
  | 'image-portrait'
  | 'portrait'
  | 'images'
  | 'inbox'
  | 'indent'
  | 'indian-rupee-sign'
  | 'indian-rupee'
  | 'inr'
  | 'industry'
  | 'infinity'
  | 'info'
  | 'italic'
  | 'j'
  | 'jar'
  | 'jar-wheat'
  | 'jedi'
  | 'jet-fighter'
  | 'fighter-jet'
  | 'jet-fighter-up'
  | 'joint'
  | 'jug-detergent'
  | 'k'
  | 'kaaba'
  | 'key'
  | 'keyboard'
  | 'khanda'
  | 'kip-sign'
  | 'kit-medical'
  | 'first-aid'
  | 'kitchen-set'
  | 'kiwi-bird'
  | 'l'
  | 'land-mine-on'
  | 'landmark'
  | 'landmark-dome'
  | 'landmark-alt'
  | 'landmark-flag'
  | 'language'
  | 'laptop'
  | 'laptop-code'
  | 'laptop-file'
  | 'laptop-medical'
  | 'lari-sign'
  | 'layer-group'
  | 'leaf'
  | 'left-long'
  | 'long-arrow-alt-left'
  | 'left-right'
  | 'arrows-alt-h'
  | 'lemon'
  | 'less-than'
  | 'less-than-equal'
  | 'life-ring'
  | 'lightbulb'
  | 'lines-leaning'
  | 'link'
  | 'chain'
  | 'link-slash'
  | 'chain-broken'
  | 'chain-slash'
  | 'unlink'
  | 'lira-sign'
  | 'list'
  | 'list-squares'
  | 'list-check'
  | 'tasks'
  | 'list-ol'
  | 'list-1-2'
  | 'list-numeric'
  | 'list-ul'
  | 'list-dots'
  | 'litecoin-sign'
  | 'location-arrow'
  | 'location-crosshairs'
  | 'location'
  | 'location-dot'
  | 'map-marker-alt'
  | 'location-pin'
  | 'map-marker'
  | 'location-pin-lock'
  | 'lock'
  | 'lock-open'
  | 'locust'
  | 'lungs'
  | 'lungs-virus'
  | 'm'
  | 'magnet'
  | 'magnifying-glass'
  | 'search'
  | 'magnifying-glass-arrow-right'
  | 'magnifying-glass-chart'
  | 'magnifying-glass-dollar'
  | 'search-dollar'
  | 'magnifying-glass-location'
  | 'search-location'
  | 'magnifying-glass-minus'
  | 'search-minus'
  | 'magnifying-glass-plus'
  | 'search-plus'
  | 'manat-sign'
  | 'map'
  | 'map-location'
  | 'map-marked'
  | 'map-location-dot'
  | 'map-marked-alt'
  | 'map-pin'
  | 'marker'
  | 'mars'
  | 'mars-and-venus'
  | 'mars-and-venus-burst'
  | 'mars-double'
  | 'mars-stroke'
  | 'mars-stroke-right'
  | 'mars-stroke-h'
  | 'mars-stroke-up'
  | 'mars-stroke-v'
  | 'martini-glass'
  | 'glass-martini-alt'
  | 'martini-glass-citrus'
  | 'cocktail'
  | 'martini-glass-empty'
  | 'glass-martini'
  | 'mask'
  | 'mask-face'
  | 'mask-ventilator'
  | 'masks-theater'
  | 'theater-masks'
  | 'mattress-pillow'
  | 'maximize'
  | 'expand-arrows-alt'
  | 'medal'
  | 'memory'
  | 'menorah'
  | 'mercury'
  | 'message'
  | 'comment-alt'
  | 'meteor'
  | 'microchip'
  | 'microphone'
  | 'microphone-lines'
  | 'microphone-alt'
  | 'microphone-lines-slash'
  | 'microphone-alt-slash'
  | 'microphone-slash'
  | 'microscope'
  | 'mill-sign'
  | 'minimize'
  | 'compress-arrows-alt'
  | 'minus'
  | 'subtract'
  | 'mitten'
  | 'mobile'
  | 'mobile-android'
  | 'mobile-phone'
  | 'mobile-button'
  | 'mobile-retro'
  | 'mobile-screen'
  | 'mobile-android-alt'
  | 'mobile-screen-button'
  | 'mobile-alt'
  | 'money-bill'
  | 'money-bill-1'
  | 'money-bill-alt'
  | 'money-bill-1-wave'
  | 'money-bill-wave-alt'
  | 'money-bill-transfer'
  | 'money-bill-trend-up'
  | 'money-bill-wave'
  | 'money-bill-wheat'
  | 'money-bills'
  | 'money-check'
  | 'money-check-dollar'
  | 'money-check-alt'
  | 'monument'
  | 'moon'
  | 'mortar-pestle'
  | 'mosque'
  | 'mosquito'
  | 'mosquito-net'
  | 'motorcycle'
  | 'mound'
  | 'mountain'
  | 'mountain-city'
  | 'mountain-sun'
  | 'mug-hot'
  | 'mug-saucer'
  | 'coffee'
  | 'music'
  | 'n'
  | 'naira-sign'
  | 'network-wired'
  | 'neuter'
  | 'newspaper'
  | 'not-equal'
  | 'notdef'
  | 'note-sticky'
  | 'sticky-note'
  | 'notes-medical'
  | 'o'
  | 'object-group'
  | 'object-ungroup'
  | 'oil-can'
  | 'oil-well'
  | 'om'
  | 'otter'
  | 'outdent'
  | 'dedent'
  | 'p'
  | 'pager'
  | 'paint-roller'
  | 'paintbrush'
  | 'paint-brush'
  | 'palette'
  | 'pallet'
  | 'panorama'
  | 'paper-plane'
  | 'paperclip'
  | 'parachute-box'
  | 'paragraph'
  | 'passport'
  | 'paste'
  | 'file-clipboard'
  | 'pause'
  | 'paw'
  | 'peace'
  | 'pen'
  | 'pen-clip'
  | 'pen-alt'
  | 'pen-fancy'
  | 'pen-nib'
  | 'pen-ruler'
  | 'pencil-ruler'
  | 'pen-to-square'
  | 'edit'
  | 'pencil'
  | 'pencil-alt'
  | 'people-arrows'
  | 'people-arrows-left-right'
  | 'people-carry-box'
  | 'people-carry'
  | 'people-group'
  | 'people-line'
  | 'people-pulling'
  | 'people-robbery'
  | 'people-roof'
  | 'pepper-hot'
  | 'percent'
  | 'percentage'
  | 'person'
  | 'male'
  | 'person-arrow-down-to-line'
  | 'person-arrow-up-from-line'
  | 'person-biking'
  | 'biking'
  | 'person-booth'
  | 'person-breastfeeding'
  | 'person-burst'
  | 'person-cane'
  | 'person-chalkboard'
  | 'person-circle-check'
  | 'person-circle-exclamation'
  | 'person-circle-minus'
  | 'person-circle-plus'
  | 'person-circle-question'
  | 'person-circle-xmark'
  | 'person-digging'
  | 'digging'
  | 'person-dots-from-line'
  | 'diagnoses'
  | 'person-dress'
  | 'female'
  | 'person-dress-burst'
  | 'person-drowning'
  | 'person-falling'
  | 'person-falling-burst'
  | 'person-half-dress'
  | 'person-harassing'
  | 'person-hiking'
  | 'hiking'
  | 'person-military-pointing'
  | 'person-military-rifle'
  | 'person-military-to-person'
  | 'person-praying'
  | 'pray'
  | 'person-pregnant'
  | 'person-rays'
  | 'person-rifle'
  | 'person-running'
  | 'running'
  | 'person-shelter'
  | 'person-skating'
  | 'skating'
  | 'person-skiing'
  | 'skiing'
  | 'person-skiing-nordic'
  | 'skiing-nordic'
  | 'person-snowboarding'
  | 'snowboarding'
  | 'person-swimming'
  | 'swimmer'
  | 'person-through-window'
  | 'person-walking'
  | 'walking'
  | 'person-walking-arrow-loop-left'
  | 'person-walking-arrow-right'
  | 'person-walking-dashed-line-arrow-right'
  | 'person-walking-luggage'
  | 'person-walking-with-cane'
  | 'blind'
  | 'peseta-sign'
  | 'peso-sign'
  | 'phone'
  | 'phone-flip'
  | 'phone-alt'
  | 'phone-slash'
  | 'phone-volume'
  | 'volume-control-phone'
  | 'photo-film'
  | 'photo-video'
  | 'piggy-bank'
  | 'pills'
  | 'pizza-slice'
  | 'place-of-worship'
  | 'plane'
  | 'plane-arrival'
  | 'plane-circle-check'
  | 'plane-circle-exclamation'
  | 'plane-circle-xmark'
  | 'plane-departure'
  | 'plane-lock'
  | 'plane-slash'
  | 'plane-up'
  | 'plant-wilt'
  | 'plate-wheat'
  | 'play'
  | 'plug'
  | 'plug-circle-bolt'
  | 'plug-circle-check'
  | 'plug-circle-exclamation'
  | 'plug-circle-minus'
  | 'plug-circle-plus'
  | 'plug-circle-xmark'
  | 'plus'
  | 'add'
  | 'plus-minus'
  | 'podcast'
  | 'poo'
  | 'poo-storm'
  | 'poo-bolt'
  | 'poop'
  | 'power-off'
  | 'prescription'
  | 'prescription-bottle'
  | 'prescription-bottle-medical'
  | 'prescription-bottle-alt'
  | 'print'
  | 'pump-medical'
  | 'pump-soap'
  | 'puzzle-piece'
  | 'q'
  | 'qrcode'
  | 'question'
  | 'quote-left'
  | 'quote-left-alt'
  | 'quote-right'
  | 'quote-right-alt'
  | 'r'
  | 'radiation'
  | 'radio'
  | 'rainbow'
  | 'ranking-star'
  | 'receipt'
  | 'record-vinyl'
  | 'rectangle-ad'
  | 'ad'
  | 'rectangle-list'
  | 'list-alt'
  | 'rectangle-xmark'
  | 'rectangle-times'
  | 'times-rectangle'
  | 'window-close'
  | 'recycle'
  | 'registered'
  | 'repeat'
  | 'reply'
  | 'mail-reply'
  | 'reply-all'
  | 'mail-reply-all'
  | 'republican'
  | 'restroom'
  | 'retweet'
  | 'ribbon'
  | 'right-from-bracket'
  | 'sign-out-alt'
  | 'right-left'
  | 'exchange-alt'
  | 'right-long'
  | 'long-arrow-alt-right'
  | 'right-to-bracket'
  | 'sign-in-alt'
  | 'ring'
  | 'road'
  | 'road-barrier'
  | 'road-bridge'
  | 'road-circle-check'
  | 'road-circle-exclamation'
  | 'road-circle-xmark'
  | 'road-lock'
  | 'road-spikes'
  | 'robot'
  | 'rocket'
  | 'rotate'
  | 'sync-alt'
  | 'rotate-left'
  | 'rotate-back'
  | 'rotate-backward'
  | 'undo-alt'
  | 'rotate-right'
  | 'redo-alt'
  | 'rotate-forward'
  | 'route'
  | 'rss'
  | 'feed'
  | 'ruble-sign'
  | 'rouble'
  | 'rub'
  | 'ruble'
  | 'rug'
  | 'ruler'
  | 'ruler-combined'
  | 'ruler-horizontal'
  | 'ruler-vertical'
  | 'rupee-sign'
  | 'rupee'
  | 'rupiah-sign'
  | 's'
  | 'sack-dollar'
  | 'sack-xmark'
  | 'sailboat'
  | 'satellite'
  | 'satellite-dish'
  | 'scale-balanced'
  | 'balance-scale'
  | 'scale-unbalanced'
  | 'balance-scale-left'
  | 'scale-unbalanced-flip'
  | 'balance-scale-right'
  | 'school'
  | 'school-circle-check'
  | 'school-circle-exclamation'
  | 'school-circle-xmark'
  | 'school-flag'
  | 'school-lock'
  | 'scissors'
  | 'cut'
  | 'screwdriver'
  | 'screwdriver-wrench'
  | 'tools'
  | 'scroll'
  | 'scroll-torah'
  | 'torah'
  | 'sd-card'
  | 'section'
  | 'seedling'
  | 'sprout'
  | 'server'
  | 'shapes'
  | 'triangle-circle-square'
  | 'share'
  | 'arrow-turn-right'
  | 'mail-forward'
  | 'share-from-square'
  | 'share-square'
  | 'share-nodes'
  | 'share-alt'
  | 'sheet-plastic'
  | 'shekel-sign'
  | 'ils'
  | 'shekel'
  | 'sheqel'
  | 'sheqel-sign'
  | 'shield'
  | 'shield-blank'
  | 'shield-cat'
  | 'shield-dog'
  | 'shield-halved'
  | 'shield-alt'
  | 'shield-heart'
  | 'shield-virus'
  | 'ship'
  | 'shirt'
  | 't-shirt'
  | 'tshirt'
  | 'shoe-prints'
  | 'shop'
  | 'store-alt'
  | 'shop-lock'
  | 'shop-slash'
  | 'store-alt-slash'
  | 'shower'
  | 'shrimp'
  | 'shuffle'
  | 'random'
  | 'shuttle-space'
  | 'space-shuttle'
  | 'sign-hanging'
  | 'sign'
  | 'signal'
  | 'signal-5'
  | 'signal-perfect'
  | 'signature'
  | 'signs-post'
  | 'map-signs'
  | 'sim-card'
  | 'sink'
  | 'sitemap'
  | 'skull'
  | 'skull-crossbones'
  | 'slash'
  | 'sleigh'
  | 'sliders'
  | 'sliders-h'
  | 'smog'
  | 'smoking'
  | 'snowflake'
  | 'snowman'
  | 'snowplow'
  | 'soap'
  | 'socks'
  | 'solar-panel'
  | 'sort'
  | 'unsorted'
  | 'sort-down'
  | 'sort-desc'
  | 'sort-up'
  | 'sort-asc'
  | 'spa'
  | 'spaghetti-monster-flying'
  | 'pastafarianism'
  | 'spell-check'
  | 'spider'
  | 'spinner'
  | 'splotch'
  | 'spoon'
  | 'utensil-spoon'
  | 'spray-can'
  | 'spray-can-sparkles'
  | 'air-freshener'
  | 'square'
  | 'square-arrow-up-right'
  | 'external-link-square'
  | 'square-caret-down'
  | 'caret-square-down'
  | 'square-caret-left'
  | 'caret-square-left'
  | 'square-caret-right'
  | 'caret-square-right'
  | 'square-caret-up'
  | 'caret-square-up'
  | 'square-check'
  | 'check-square'
  | 'square-envelope'
  | 'envelope-square'
  | 'square-full'
  | 'square-h'
  | 'h-square'
  | 'square-minus'
  | 'minus-square'
  | 'square-nfi'
  | 'square-parking'
  | 'parking'
  | 'square-pen'
  | 'pen-square'
  | 'pencil-square'
  | 'square-person-confined'
  | 'square-phone'
  | 'phone-square'
  | 'square-phone-flip'
  | 'phone-square-alt'
  | 'square-plus'
  | 'plus-square'
  | 'square-poll-horizontal'
  | 'poll-h'
  | 'square-poll-vertical'
  | 'poll'
  | 'square-root-variable'
  | 'square-root-alt'
  | 'square-rss'
  | 'rss-square'
  | 'square-share-nodes'
  | 'share-alt-square'
  | 'square-up-right'
  | 'external-link-square-alt'
  | 'square-virus'
  | 'square-xmark'
  | 'times-square'
  | 'xmark-square'
  | 'staff-snake'
  | 'rod-asclepius'
  | 'rod-snake'
  | 'staff-aesculapius'
  | 'stairs'
  | 'stamp'
  | 'stapler'
  | 'star'
  | 'star-and-crescent'
  | 'star-half'
  | 'star-half-stroke'
  | 'star-half-alt'
  | 'star-of-david'
  | 'star-of-life'
  | 'sterling-sign'
  | 'gbp'
  | 'pound-sign'
  | 'stethoscope'
  | 'stop'
  | 'stopwatch'
  | 'stopwatch-20'
  | 'store'
  | 'store-slash'
  | 'street-view'
  | 'strikethrough'
  | 'stroopwafel'
  | 'subscript'
  | 'suitcase'
  | 'suitcase-medical'
  | 'medkit'
  | 'suitcase-rolling'
  | 'sun'
  | 'sun-plant-wilt'
  | 'superscript'
  | 'swatchbook'
  | 'synagogue'
  | 'syringe'
  | 't'
  | 'table'
  | 'table-cells'
  | 'th'
  | 'table-cells-large'
  | 'th-large'
  | 'table-columns'
  | 'columns'
  | 'table-list'
  | 'th-list'
  | 'table-tennis-paddle-ball'
  | 'ping-pong-paddle-ball'
  | 'table-tennis'
  | 'tablet'
  | 'tablet-android'
  | 'tablet-button'
  | 'tablet-screen-button'
  | 'tablet-alt'
  | 'tablets'
  | 'tachograph-digital'
  | 'digital-tachograph'
  | 'tag'
  | 'tags'
  | 'tape'
  | 'tarp'
  | 'tarp-droplet'
  | 'taxi'
  | 'cab'
  | 'teeth'
  | 'teeth-open'
  | 'temperature-arrow-down'
  | 'temperature-down'
  | 'temperature-arrow-up'
  | 'temperature-up'
  | 'temperature-empty'
  | 'temperature-0'
  | 'thermometer-0'
  | 'thermometer-empty'
  | 'temperature-full'
  | 'temperature-4'
  | 'thermometer-4'
  | 'thermometer-full'
  | 'temperature-half'
  | 'temperature-2'
  | 'thermometer-2'
  | 'thermometer-half'
  | 'temperature-high'
  | 'temperature-low'
  | 'temperature-quarter'
  | 'temperature-1'
  | 'thermometer-1'
  | 'thermometer-quarter'
  | 'temperature-three-quarters'
  | 'temperature-3'
  | 'thermometer-3'
  | 'thermometer-three-quarters'
  | 'tenge-sign'
  | 'tenge'
  | 'tent'
  | 'tent-arrow-down-to-line'
  | 'tent-arrow-left-right'
  | 'tent-arrow-turn-left'
  | 'tent-arrows-down'
  | 'tents'
  | 'terminal'
  | 'text-height'
  | 'text-slash'
  | 'remove-format'
  | 'text-width'
  | 'thermometer'
  | 'thumbs-down'
  | 'thumbs-up'
  | 'thumbtack'
  | 'thumb-tack'
  | 'ticket'
  | 'ticket-simple'
  | 'ticket-alt'
  | 'timeline'
  | 'toggle-off'
  | 'toggle-on'
  | 'toilet'
  | 'toilet-paper'
  | 'toilet-paper-slash'
  | 'toilet-portable'
  | 'toilets-portable'
  | 'toolbox'
  | 'tooth'
  | 'torii-gate'
  | 'tornado'
  | 'tower-broadcast'
  | 'broadcast-tower'
  | 'tower-cell'
  | 'tower-observation'
  | 'tractor'
  | 'trademark'
  | 'traffic-light'
  | 'trailer'
  | 'train'
  | 'train-subway'
  | 'subway'
  | 'train-tram'
  | 'transgender'
  | 'transgender-alt'
  | 'trash'
  | 'trash-arrow-up'
  | 'trash-restore'
  | 'trash-can'
  | 'trash-alt'
  | 'trash-can-arrow-up'
  | 'trash-restore-alt'
  | 'tree'
  | 'tree-city'
  | 'triangle-exclamation'
  | 'exclamation-triangle'
  | 'warning'
  | 'trophy'
  | 'trowel'
  | 'trowel-bricks'
  | 'truck'
  | 'truck-arrow-right'
  | 'truck-droplet'
  | 'truck-fast'
  | 'shipping-fast'
  | 'truck-field'
  | 'truck-field-un'
  | 'truck-front'
  | 'truck-medical'
  | 'ambulance'
  | 'truck-monster'
  | 'truck-moving'
  | 'truck-pickup'
  | 'truck-plane'
  | 'truck-ramp-box'
  | 'truck-loading'
  | 'tty'
  | 'teletype'
  | 'turkish-lira-sign'
  | 'try'
  | 'turkish-lira'
  | 'turn-down'
  | 'level-down-alt'
  | 'turn-up'
  | 'level-up-alt'
  | 'tv'
  | 'television'
  | 'tv-alt'
  | 'u'
  | 'umbrella'
  | 'umbrella-beach'
  | 'underline'
  | 'universal-access'
  | 'unlock'
  | 'unlock-keyhole'
  | 'unlock-alt'
  | 'up-down'
  | 'arrows-alt-v'
  | 'up-down-left-right'
  | 'arrows-alt'
  | 'up-long'
  | 'long-arrow-alt-up'
  | 'up-right-and-down-left-from-center'
  | 'expand-alt'
  | 'up-right-from-square'
  | 'external-link-alt'
  | 'upload'
  | 'user'
  | 'user-astronaut'
  | 'user-check'
  | 'user-clock'
  | 'user-doctor'
  | 'user-md'
  | 'user-gear'
  | 'user-cog'
  | 'user-graduate'
  | 'user-group'
  | 'user-friends'
  | 'user-injured'
  | 'user-large'
  | 'user-alt'
  | 'user-large-slash'
  | 'user-alt-slash'
  | 'user-lock'
  | 'user-minus'
  | 'user-ninja'
  | 'user-nurse'
  | 'user-pen'
  | 'user-edit'
  | 'user-plus'
  | 'user-secret'
  | 'user-shield'
  | 'user-slash'
  | 'user-tag'
  | 'user-tie'
  | 'user-xmark'
  | 'user-times'
  | 'users'
  | 'users-between-lines'
  | 'users-gear'
  | 'users-cog'
  | 'users-line'
  | 'users-rays'
  | 'users-rectangle'
  | 'users-slash'
  | 'users-viewfinder'
  | 'utensils'
  | 'cutlery'
  | 'v'
  | 'van-shuttle'
  | 'shuttle-van'
  | 'vault'
  | 'vector-square'
  | 'venus'
  | 'venus-double'
  | 'venus-mars'
  | 'vest'
  | 'vest-patches'
  | 'vial'
  | 'vial-circle-check'
  | 'vial-virus'
  | 'vials'
  | 'video'
  | 'video-camera'
  | 'video-slash'
  | 'vihara'
  | 'virus'
  | 'virus-covid'
  | 'virus-covid-slash'
  | 'virus-slash'
  | 'viruses'
  | 'voicemail'
  | 'volcano'
  | 'volleyball'
  | 'volleyball-ball'
  | 'volume-high'
  | 'volume-up'
  | 'volume-low'
  | 'volume-down'
  | 'volume-off'
  | 'volume-xmark'
  | 'volume-mute'
  | 'volume-times'
  | 'vr-cardboard'
  | 'w'
  | 'walkie-talkie'
  | 'wallet'
  | 'wand-magic'
  | 'magic'
  | 'wand-magic-sparkles'
  | 'magic-wand-sparkles'
  | 'wand-sparkles'
  | 'warehouse'
  | 'water'
  | 'water-ladder'
  | 'ladder-water'
  | 'swimming-pool'
  | 'wave-square'
  | 'weight-hanging'
  | 'weight-scale'
  | 'weight'
  | 'wheat-awn'
  | 'wheat-alt'
  | 'wheat-awn-circle-exclamation'
  | 'wheelchair'
  | 'wheelchair-move'
  | 'wheelchair-alt'
  | 'whiskey-glass'
  | 'glass-whiskey'
  | 'wifi'
  | 'wifi-3'
  | 'wifi-strong'
  | 'wind'
  | 'window-maximize'
  | 'window-minimize'
  | 'window-restore'
  | 'wine-bottle'
  | 'wine-glass'
  | 'wine-glass-empty'
  | 'wine-glass-alt'
  | 'won-sign'
  | 'krw'
  | 'won'
  | 'worm'
  | 'wrench'
  | 'x'
  | 'x-ray'
  | 'xmark'
  | 'close'
  | 'multiply'
  | 'remove'
  | 'times'
  | 'xmarks-lines'
  | 'y'
  | 'yen-sign'
  | 'cny'
  | 'jpy'
  | 'rmb'
  | 'yen'
  | 'yin-yang'
  | 'z'
  | '42-group'
  | 'innosoft'
  | '500px'
  | 'accessible-icon'
  | 'accusoft'
  | 'adn'
  | 'adversal'
  | 'affiliatetheme'
  | 'airbnb'
  | 'algolia'
  | 'alipay'
  | 'amazon'
  | 'amazon-pay'
  | 'amilia'
  | 'android'
  | 'angellist'
  | 'angrycreative'
  | 'angular'
  | 'app-store'
  | 'app-store-ios'
  | 'apper'
  | 'apple'
  | 'apple-pay'
  | 'artstation'
  | 'asymmetrik'
  | 'atlassian'
  | 'audible'
  | 'autoprefixer'
  | 'avianex'
  | 'aviato'
  | 'aws'
  | 'bandcamp'
  | 'battle-net'
  | 'behance'
  | 'bilibili'
  | 'bimobject'
  | 'bitbucket'
  | 'bitcoin'
  | 'bity'
  | 'black-tie'
  | 'blackberry'
  | 'blogger'
  | 'blogger-b'
  | 'bluetooth'
  | 'bluetooth-b'
  | 'bootstrap'
  | 'bots'
  | 'btc'
  | 'buffer'
  | 'buromobelexperte'
  | 'buy-n-large'
  | 'buysellads'
  | 'canadian-maple-leaf'
  | 'cc-amazon-pay'
  | 'cc-amex'
  | 'cc-apple-pay'
  | 'cc-diners-club'
  | 'cc-discover'
  | 'cc-jcb'
  | 'cc-mastercard'
  | 'cc-paypal'
  | 'cc-stripe'
  | 'cc-visa'
  | 'centercode'
  | 'centos'
  | 'chrome'
  | 'chromecast'
  | 'cloudflare'
  | 'cloudscale'
  | 'cloudsmith'
  | 'cloudversify'
  | 'cmplid'
  | 'codepen'
  | 'codiepie'
  | 'confluence'
  | 'connectdevelop'
  | 'contao'
  | 'cotton-bureau'
  | 'cpanel'
  | 'creative-commons'
  | 'creative-commons-by'
  | 'creative-commons-nc'
  | 'creative-commons-nc-eu'
  | 'creative-commons-nc-jp'
  | 'creative-commons-nd'
  | 'creative-commons-pd'
  | 'creative-commons-pd-alt'
  | 'creative-commons-remix'
  | 'creative-commons-sa'
  | 'creative-commons-sampling'
  | 'creative-commons-sampling-plus'
  | 'creative-commons-share'
  | 'creative-commons-zero'
  | 'critical-role'
  | 'css3'
  | 'css3-alt'
  | 'cuttlefish'
  | 'd-and-d'
  | 'd-and-d-beyond'
  | 'dailymotion'
  | 'dashcube'
  | 'deezer'
  | 'delicious'
  | 'deploydog'
  | 'deskpro'
  | 'dev'
  | 'deviantart'
  | 'dhl'
  | 'diaspora'
  | 'digg'
  | 'digital-ocean'
  | 'discord'
  | 'discourse'
  | 'dochub'
  | 'docker'
  | 'draft2digital'
  | 'dribbble'
  | 'dropbox'
  | 'drupal'
  | 'dyalog'
  | 'earlybirds'
  | 'ebay'
  | 'edge'
  | 'edge-legacy'
  | 'elementor'
  | 'ello'
  | 'ember'
  | 'empire'
  | 'envira'
  | 'erlang'
  | 'ethereum'
  | 'etsy'
  | 'evernote'
  | 'expeditedssl'
  | 'facebook'
  | 'facebook-f'
  | 'facebook-messenger'
  | 'fantasy-flight-games'
  | 'fedex'
  | 'fedora'
  | 'figma'
  | 'firefox'
  | 'firefox-browser'
  | 'first-order'
  | 'first-order-alt'
  | 'firstdraft'
  | 'flickr'
  | 'flipboard'
  | 'fly'
  | 'font-awesome'
  | 'font-awesome-flag'
  | 'font-awesome-logo-full'
  | 'fonticons'
  | 'fonticons-fi'
  | 'fort-awesome'
  | 'fort-awesome-alt'
  | 'forumbee'
  | 'foursquare'
  | 'free-code-camp'
  | 'freebsd'
  | 'fulcrum'
  | 'galactic-republic'
  | 'galactic-senate'
  | 'get-pocket'
  | 'gg'
  | 'gg-circle'
  | 'git'
  | 'git-alt'
  | 'github'
  | 'github-alt'
  | 'gitkraken'
  | 'gitlab'
  | 'gitter'
  | 'glide'
  | 'glide-g'
  | 'gofore'
  | 'golang'
  | 'goodreads'
  | 'goodreads-g'
  | 'google'
  | 'google-drive'
  | 'google-pay'
  | 'google-play'
  | 'google-plus'
  | 'google-plus-g'
  | 'google-wallet'
  | 'gratipay'
  | 'grav'
  | 'gripfire'
  | 'grunt'
  | 'guilded'
  | 'gulp'
  | 'hacker-news'
  | 'hackerrank'
  | 'hashnode'
  | 'hips'
  | 'hire-a-helper'
  | 'hive'
  | 'hooli'
  | 'hornbill'
  | 'hotjar'
  | 'houzz'
  | 'html5'
  | 'hubspot'
  | 'ideal'
  | 'imdb'
  | 'instagram'
  | 'instalod'
  | 'intercom'
  | 'internet-explorer'
  | 'invision'
  | 'ioxhost'
  | 'itch-io'
  | 'itunes'
  | 'itunes-note'
  | 'java'
  | 'jedi-order'
  | 'jenkins'
  | 'jira'
  | 'joget'
  | 'joomla'
  | 'js'
  | 'jsfiddle'
  | 'kaggle'
  | 'keybase'
  | 'keycdn'
  | 'kickstarter'
  | 'kickstarter-k'
  | 'korvue'
  | 'laravel'
  | 'lastfm'
  | 'leanpub'
  | 'less'
  | 'line'
  | 'linkedin'
  | 'linkedin-in'
  | 'linode'
  | 'linux'
  | 'lyft'
  | 'magento'
  | 'mailchimp'
  | 'mandalorian'
  | 'markdown'
  | 'mastodon'
  | 'maxcdn'
  | 'mdb'
  | 'medapps'
  | 'medium'
  | 'medium-m'
  | 'medrt'
  | 'meetup'
  | 'megaport'
  | 'mendeley'
  | 'meta'
  | 'microblog'
  | 'microsoft'
  | 'mix'
  | 'mixcloud'
  | 'mixer'
  | 'mizuni'
  | 'modx'
  | 'monero'
  | 'napster'
  | 'neos'
  | 'nfc-directional'
  | 'nfc-symbol'
  | 'nimblr'
  | 'node'
  | 'node-js'
  | 'npm'
  | 'ns8'
  | 'nutritionix'
  | 'octopus-deploy'
  | 'odnoklassniki'
  | 'old-republic'
  | 'opencart'
  | 'openid'
  | 'opera'
  | 'optin-monster'
  | 'orcid'
  | 'osi'
  | 'padlet'
  | 'page4'
  | 'pagelines'
  | 'palfed'
  | 'patreon'
  | 'paypal'
  | 'perbyte'
  | 'periscope'
  | 'phabricator'
  | 'phoenix-framework'
  | 'phoenix-squadron'
  | 'php'
  | 'pied-piper'
  | 'pied-piper-alt'
  | 'pied-piper-hat'
  | 'pied-piper-pp'
  | 'pinterest'
  | 'pinterest-p'
  | 'pix'
  | 'playstation'
  | 'product-hunt'
  | 'pushed'
  | 'python'
  | 'qq'
  | 'quinscape'
  | 'quora'
  | 'r-project'
  | 'raspberry-pi'
  | 'ravelry'
  | 'react'
  | 'reacteurope'
  | 'readme'
  | 'rebel'
  | 'red-river'
  | 'reddit'
  | 'reddit-alien'
  | 'redhat'
  | 'renren'
  | 'replyd'
  | 'researchgate'
  | 'resolving'
  | 'rev'
  | 'rocketchat'
  | 'rockrms'
  | 'rust'
  | 'safari'
  | 'salesforce'
  | 'sass'
  | 'schlix'
  | 'screenpal'
  | 'scribd'
  | 'searchengin'
  | 'sellcast'
  | 'sellsy'
  | 'servicestack'
  | 'shirtsinbulk'
  | 'shopify'
  | 'shopware'
  | 'simplybuilt'
  | 'sistrix'
  | 'sith'
  | 'sitrox'
  | 'sketch'
  | 'skyatlas'
  | 'skype'
  | 'slack'
  | 'slack-hash'
  | 'slideshare'
  | 'snapchat'
  | 'snapchat-ghost'
  | 'soundcloud'
  | 'sourcetree'
  | 'space-awesome'
  | 'speakap'
  | 'speaker-deck'
  | 'spotify'
  | 'square-behance'
  | 'behance-square'
  | 'square-dribbble'
  | 'dribbble-square'
  | 'square-facebook'
  | 'facebook-square'
  | 'square-font-awesome'
  | 'square-font-awesome-stroke'
  | 'font-awesome-alt'
  | 'square-git'
  | 'git-square'
  | 'square-github'
  | 'github-square'
  | 'square-gitlab'
  | 'gitlab-square'
  | 'square-google-plus'
  | 'google-plus-square'
  | 'square-hacker-news'
  | 'hacker-news-square'
  | 'square-instagram'
  | 'instagram-square'
  | 'square-js'
  | 'js-square'
  | 'square-lastfm'
  | 'lastfm-square'
  | 'square-odnoklassniki'
  | 'odnoklassniki-square'
  | 'square-pied-piper'
  | 'pied-piper-square'
  | 'square-pinterest'
  | 'pinterest-square'
  | 'square-reddit'
  | 'reddit-square'
  | 'square-snapchat'
  | 'snapchat-square'
  | 'square-steam'
  | 'steam-square'
  | 'square-tumblr'
  | 'tumblr-square'
  | 'square-twitter'
  | 'twitter-square'
  | 'square-viadeo'
  | 'viadeo-square'
  | 'square-vimeo'
  | 'vimeo-square'
  | 'square-whatsapp'
  | 'whatsapp-square'
  | 'square-xing'
  | 'xing-square'
  | 'square-youtube'
  | 'youtube-square'
  | 'squarespace'
  | 'stack-exchange'
  | 'stack-overflow'
  | 'stackpath'
  | 'staylinked'
  | 'steam'
  | 'steam-symbol'
  | 'sticker-mule'
  | 'strava'
  | 'stripe'
  | 'stripe-s'
  | 'studiovinari'
  | 'stumbleupon'
  | 'stumbleupon-circle'
  | 'superpowers'
  | 'supple'
  | 'suse'
  | 'swift'
  | 'symfony'
  | 'teamspeak'
  | 'telegram'
  | 'telegram-plane'
  | 'tencent-weibo'
  | 'the-red-yeti'
  | 'themeco'
  | 'themeisle'
  | 'think-peaks'
  | 'tiktok'
  | 'trade-federation'
  | 'trello'
  | 'tumblr'
  | 'twitch'
  | 'twitter'
  | 'typo3'
  | 'uber'
  | 'ubuntu'
  | 'uikit'
  | 'umbraco'
  | 'uncharted'
  | 'uniregistry'
  | 'unity'
  | 'unsplash'
  | 'untappd'
  | 'ups'
  | 'usb'
  | 'usps'
  | 'ussunnah'
  | 'vaadin'
  | 'viacoin'
  | 'viadeo'
  | 'viber'
  | 'vimeo'
  | 'vimeo-v'
  | 'vine'
  | 'vk'
  | 'vnv'
  | 'vuejs'
  | 'watchman-monitoring'
  | 'waze'
  | 'weebly'
  | 'weibo'
  | 'weixin'
  | 'whatsapp'
  | 'whmcs'
  | 'wikipedia-w'
  | 'windows'
  | 'wirsindhandwerk'
  | 'wsh'
  | 'wix'
  | 'wizards-of-the-coast'
  | 'wodu'
  | 'wolf-pack-battalion'
  | 'wordpress'
  | 'wordpress-simple'
  | 'wpbeginner'
  | 'wpexplorer'
  | 'wpforms'
  | 'wpressr'
  | 'rendact'
  | 'xbox'
  | 'xing'
  | 'y-combinator'
  | 'yahoo'
  | 'yammer'
  | 'yandex'
  | 'yandex-international'
  | 'yarn'
  | 'yelp'
  | 'yoast'
  | 'youtube'
  | 'zhihu';
