import React from 'react';
import ReactDatePicker from 'react-datepicker';
import Card from '../../../components/Card';
import EditableInput from '../../../components/Forms/EditableInput';
import formatCurrency from '../../../functions/formatCurrency';
import unwrap from '../../../functions/unwrap';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';
import CouponModel from '../../../models/scoopm/Coupon';

import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

export default function Coupon({history, match}) {
  // hooks

  const coupon = useRealtimeDatabase({
    path: `/coupons/${match.params.couponCode}`,
    Model: CouponModel,
  });

  // view methods

  const onChange = ({name, value, done}) => {
    const cleanValue = value.replace(/[^\d\.]/gi, '');

    let update = cleanValue === '' ? null : Number(cleanValue);

    if (update) {
      switch (name) {
        case 'discountAmount': {
          update = update * 100; // dollars to cents
          break;
        }
        case 'discountFraction': {
          update = update / 100; // percentage to fraction
          break;
        }
        case 'flatRate': {
          update = update * 100; // dollars to cents
          break;
        }
        default:
          break;
      }
    }

    coupon.update({[name]: update}).then(
      () => done(),
      error => done(false),
    );
  };

  const onClickDelete = event => {
    if (!window.confirm(`Delete coupon ${match.params.couponCode.toUpperCase()}?`)) {
      return;
    }

    event.preventDefault();

    coupon.remove().then(
      () => {
        history.push('/admin/coupon-codes');
      },
      error => {
        alert('There was an error deleting the coupon');
      },
    );
  };

  const onDatePickerChange = date => {
    coupon.update({expirationTimestampMilliseconds: date.valueOf()}).catch(error => {
      alert('There was an error changing the expiration time');
    });
  };

  const onClickDeleteExpiration = event => {
    event.preventDefault();

    coupon.update({expirationTimestampMilliseconds: null}).catch(error => {
      alert('There was an error removing the expiration');
    });
  };

  return (
    <Card padded>
      <div className="row">
        <div className="col">
          <h2>Coupon details</h2>
        </div>
        <div className="col-auto">
          <button onClick={onClickDelete} className="btn btn-danger">
            <i className="fas fa-trash mr-1"></i>Delete
          </button>
        </div>
      </div>

      <h5>Code {match.params.couponCode.toUpperCase()}</h5>

      <hr />

      <div className="row">
        <div className="col-6 col-md-3">Discount amount</div>
        <div className="col">
          <EditableInput
            name="discountAmount"
            value={unwrap(coupon.data, data => unwrap(data.discountAmount, amount => formatCurrency(amount / 100)))}
            onChange={onChange}
            placeholder="None"
            disabled={coupon.isLoading}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6 col-md-3">Discount percentage</div>
        <div className="col">
          <EditableInput
            name="discountFraction"
            value={unwrap(coupon.data, data => data.discountPercentage)}
            onChange={onChange}
            placeholder="None"
            disabled={coupon.isLoading}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6 col-md-3">Flat rate</div>
        <div className="col">
          <EditableInput
            name="flatRate"
            value={unwrap(coupon.data, data => unwrap(data.flatRate, rate => formatCurrency(rate / 100)))}
            onChange={onChange}
            placeholder="None"
            disabled={coupon.isLoading}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6 col-md-3">Expiration</div>
        <div className="col d-flex align-items-center">
          <ReactDatePicker
            selected={unwrap(coupon.data, data =>
              unwrap(data.expirationTimestampMilliseconds, timestamp => moment(timestamp).toDate()),
            )}
            onChange={onDatePickerChange}
            dateFormat="Pp"
            className="form-control"
            showTimeSelect
            placeholderText="None"
          />
          {unwrap(coupon.data, data =>
            unwrap(data.expirationTimestampMilliseconds, timestamp => (
              <button onClick={onClickDeleteExpiration} className="btn btn-secondary ml-1">
                <i className="fas fa-minus-circle"></i>
              </button>
            )),
          )}
        </div>
      </div>
    </Card>
  );
}
