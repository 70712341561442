import Enum from '../base/Enum';

export default class DeviceType extends Enum {
  static get mobileTypes() {
    return [DeviceType.android, DeviceType.iOS];
  }
}

DeviceType.android = new DeviceType('ANDROID');
DeviceType.api = new DeviceType('API');
DeviceType.iOS = new DeviceType('IOS');
DeviceType.web = new DeviceType('WEB');
