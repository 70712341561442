import React from 'react';
import {HashLink} from 'react-router-hash-link';
import joinClassNames from '../../functions/joinClassNames';
import useUriIsSelected from '../../hooks/useLocationIsSelected';

export default function HeaderLink({to, isDarkTheme, children}) {
  const isSelected = useUriIsSelected(to);

  const onClickLink = event => {
    window.$('.navbar-collapse').collapse('hide'); // collapse navbar
  };

  return (
    <li className="nav-item">
      <HashLink
        to={to}
        onClick={onClickLink}
        className={joinClassNames(
          'nav-link',
          isDarkTheme ? 'text-white' : 'text-dark',
          isSelected ? ' font-weight-bold' : '',
        )}>
        {children}
      </HashLink>
    </li>
  );
}
