import React from 'react';
import Anchor from '../../components/Anchor';
import fourth from '../../images/home/fourth.png';

export default function Quality() {
  return (
    <div style={{overflow: 'hidden'}}>
      <Anchor id="quality" />

      <div className="container position-relative" style={{paddingTop: '8rem', paddingBottom: '8rem'}}>
        <img
          src={fourth}
          alt="Screenshot"
          style={{top: '2rem', left: '-28rem'}}
          className="position-absolute d-none d-lg-inline"
        />

        <div className="row">
          <div className="col-lg-7 offset-lg-5">
            <h1 className="display-4 mb-5">Quality is our obsession</h1>

            <div className="row mb-4">
              <div className="col-lg">
                <i className="fas fa-comments fa-lg text-teal mb-3"></i>
                <h4>You talk, we listen!</h4>
                <p className="text-muted">
                  This app is built for Riders and Drivers! We will do our best to take care of both and go above and
                  beyond!
                </p>
              </div>
              <div className="col-lg">
                <i className="fas fa-user-secret fa-lg text-teal mb-3"></i>
                <h4>No creepy drivers</h4>
                <p className="text-muted">
                  We have zero tolerance for inappropriate behavior that so frequently happens with our competitors'
                  drivers.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg">
                <i className="fas fa-star fa-lg text-teal mb-3"></i>
                <h4>Quality over quantity</h4>
                <p className="text-muted">
                  Our competitors are racing for volume and numbers. Wave is focused on making every customer
                  interaction a great one!
                </p>
              </div>
              <div className="col-lg">
                <i className="fa fa-laugh-beam fa-lg text-teal mb-3"></i>
                <h4>Satisfaction guaranteed</h4>
                <p className="text-muted">Don’t like your experience? Let us know and we’ll make it right!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
