import React, {ReactNode} from 'react';

export default function RideDetailsRow({title, children}: {title: string | JSX.Element; children?: ReactNode}) {
  return (
    <div className="row border-top align-items-center" style={{minHeight: '2.25rem'}}>
      <div className="col-auto font-weight-bold">{title}</div>
      {children && <div className="col d-flex justify-content-end">{children}</div>}
    </div>
  );
}
