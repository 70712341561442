import React from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import Delay from '../components/Delay';
import Spinner from '../components/Spinner';
import {useAuthContext} from '../contexts/AuthContext';

export default function UserGuard({children, to = null}) {
  const {user, error} = useAuthContext();
  const location = useLocation();

  if (user === undefined) {
    return (
      <Delay>
        <Spinner />
      </Delay>
    );
  }

  if (error) {
    return 'Error';
  }

  if (!user) {
    return <Redirect to={to || `/sign-in?from=${location.pathname}`} />;
  }

  return children;
}
