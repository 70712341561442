import Axios from 'axios';
import firebase from 'firebase/app';
import $ from 'jquery';
import moment from 'moment';
import pluralize from 'pluralize';
import React from 'react';
import {Link} from 'react-router-dom';
import {baseUserFullName, baseUserShortName, formatPhone, formatPhoneE164} from 'wave-common';
import {CountryOptions, StatesOptions} from '../../../Regions';
import Card from '../../../components/Card';
import FirebaseStorageCircleImage from '../../../components/FirebaseStorageCircleImage';
import FirebaseStorageSquareImage from '../../../components/FirebaseStorageSquareImage';
import FontAwesome, {FontAwesomeV5} from '../../../components/FontAwesome';
import EditableInput from '../../../components/Forms/EditableInput';
import EditableSelect from '../../../components/Forms/EditableSelect';
import EditableTextarea from '../../../components/Forms/EditableTextarea';
import RawData from '../../../components/RawData';
import {DelaySpinner} from '../../../components/Spinner';
import ViewInFirebase from '../../../components/ViewInFirebase';
import {useTitle} from '../../../contexts/TitleContext';
import {dmfError, dmflog} from '../../../functions/dmflog';
import unwrap from '../../../functions/unwrap';
import ClaimsGuard from '../../../guards/ClaimsGuard';
import fountainIcon from '../../../images/fountain.ico';
import CenteredLayout from '../../../layouts/CenteredLayout';
import ControlledSubstance from '../../../models/scoopm/ControlledSubstance';
import CustomClaim from '../../../models/scoopm/CustomClaim';
import DriverGeoFire from '../../../models/scoopm/DriverGeoFire';
import DriverGeoFireActive from '../../../models/scoopm/DriverGeoFireActive';
import TripType from '../../../models/scoopm/TripType';
import RealtimeDatabase from '../../../references/database/RealtimeDatabase';
import ScoopMApi from '../../../references/scoopm-api';
import {Api} from '../../../services/Api';
import {Db, DbRefs} from '../../../services/Db';
import {EmailService} from '../../../services/EmailService';
import UserTripsTable from './UserTripsTable';
import './driver.css';
import {Communication, CommunicationType, ReverseCommunicationType} from './misc/Communication';
import {DbConstants} from './misc/DbConstants';
import driverFlagsApproved from './misc/driverFlagsApproved';
import DriverAvailability from './sections/DriverAvailability';
import Earnings from './sections/Earnings';
import SectionCard from './sections/SectionCard';
import Vehicles from './sections/Vehicles';

const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export class Driver extends React.Component {
  constructor(props) {
    super(props);

    let driverId = props.match.params.uid;

    this.state = {
      driver: undefined,
      driverId,
      databaseReference: DbRefs.driver(driverId),
    };

    this.approveDriver = this.approveDriver.bind(this);
    this.setStatusToApproved = this.setStatusToApproved.bind(this);
    this.setApprovalForType = this.setApprovalForType.bind(this);
    this.suspendDriver = this.suspendDriver.bind(this);
    this.unSuspendDriver = this.unSuspendDriver.bind(this);
    this.toggleForType = this.toggleForType.bind(this);
    this.didPhone = this.didPhone.bind(this);
    this.didEmail = this.didEmail.bind(this);
    this.didContact = this.didContact.bind(this);
    this.rejectDriver = this.rejectDriver.bind(this);
  }

  componentWillMount() {
    // get driver once

    this.state.databaseReference.once('value').then(snapshot => {
      // get email address from API

      return ScoopMApi.instance.users
        .child(snapshot.key)
        .email.get()
        .then(
          response => {
            this.setState({driverEmail: response.data.value});
          },
          () => {},
        );
    });

    // observe driver

    this.state.databaseReference.on('value', snapshot => {
      let driver;
      if (snapshot.exists()) {
        driver = {id: snapshot.key, ...snapshot.val()};
      } else {
        driver = null;
      }

      this.setState({
        driver,
        zendriveTrip: null,
      });

      // communications
      if (driver && driver.communications) {
        let commsObj = driver.communications;
        let communications = Object.values(commsObj);
        communications.sort((a, b) => a - b);
        this.setState({communications});
      }

      if (driver && driver.currentZendriveTripId) {
        RealtimeDatabase.instance
          .child('zendrive-trips')
          .child(driver.currentZendriveTripId)
          .once()
          .then(
            ([zendriveTrip]) => {
              this.setState({zendriveTrip});
            },
            error => {
              console.error('Error getting Zendrive trip', error.message);
              this.setState({zendriveTrip: null});
            },
          );
      }
    });

    // get notes

    this.notesRef = DbRefs.notes.child(this.state.driverId);
    this.notesListener = this.notesRef.on(
      'value',
      snapshot => {
        this.setState({notes: snapshot.val()});
      },
      error => {
        this.setState({notes: 'Unable to get notes'});
      },
    );
  }

  componentWillUnmount() {
    this.state.databaseReference.off();
    this.notesRef.off('value', this.notesListener);
  }

  componentDidMount() {
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 500);
  }

  render() {
    if (this.state.driver === undefined) {
      return (
        <CenteredLayout>
          <DelaySpinner />
        </CenteredLayout>
      );
    }

    if (this.state.driver === null) {
      return (
        <CenteredLayout>
          <p>
            <FontAwesome.InfoCircle /> No driver with user id "{this.state.driverId}"
          </p>
          <p>Bear in mind, some users are set up as a "rider" but not a "driver".</p>
          <p>
            <Link to={`/admin/users/${this.state.driverId}`}>Try viewing user profile &rarr;</Link>
          </p>
        </CenteredLayout>
      );
    }

    return this.theComponents();
  }

  didPhone(e) {
    this.didContact(CommunicationType.phoneCall);
  }

  didEmail(e) {
    this.didContact(CommunicationType.email);
  }

  didContact(type) {
    // add phone communication to user
    let newCommunication = this.state.databaseReference.child('communications').push();
    let communication = new Communication(type);
    newCommunication.update(communication).catch(error => {
      alert(`Error: Couldn't add comm to the list.`);
    });
  }

  approveDriver(e) {
    e.preventDefault();

    if (!window.confirm('Approve this driver')) {
      return;
    }

    this.toggleForType(TripType.scoopM, true);
  }

  setStatusToApproved() {
    return this.state.databaseReference.update({
      status: DbConstants.status.approved.identifier,
      approved: true,
      suspended: false,
    });
  }

  setApprovalForType(type, approved) {
    console.log(`Setting type "${type.rawValue}" to ${approved}...`);

    this.state.databaseReference
      .child('types')
      .update({[type.rawValue]: approved})
      .then(
        () => {
          console.info(`Set type "${type.rawValue}" to ${approved}`);

          // send confirmation email
          if (approved === true) {
            // get email
            let emailAddress = this.state.driver.email;
            if (!emailAddress) {
              console.log('No email to send to');
              return;
            }

            // get email type
            let emailType;
            switch (type) {
              case TripType.scoopM:
                emailType = EmailService.type.approvedScoopM;
                break;
              case TripType.scoopXl:
                emailType = EmailService.type.approvedScoopXl;
                break;
              case TripType.nanny:
                emailType = EmailService.type.approvedNanny;
                break;
              case TripType.butler:
                emailType = EmailService.type.approvedButler;
                break;
              case TripType.runner:
                emailType = EmailService.type.approvedRunner;
                break;
              default:
                break; // do nothing
            }
            if (!emailType) {
              console.log('Unknown email type');
              return;
            }

            // send email
            console.log(`Sending email to ${emailAddress}...`);
            EmailService.instance.send(emailAddress, emailType, success => {
              // error?
              if (!success) {
                console.error('Error sending email');
                alert('Unable to send confirmation email to driver');
                return;
              }

              console.info('Sent email');
            });
          }
        },
        error => {
          console.error(`Error setting type "${type.rawValue}" to ${approved}...`, error);
          alert(`There was an error ${approved ? 'approving the driver' : 'removing the approval'} for ${type.title}.`);
        },
      );
  }

  toggleForType(type, checked) {
    if (checked) {
      if (!window.confirm(`Approve this driver for ${type.title}?`)) {
        return;
      }

      if (this.state.driver.status === DbConstants.status.suspended.identifier) {
        alert(`This driver is currently suspended.`);
        return;
      }

      this.setStatusToApproved().then(() => this.setApprovalForType(type, true));
    } else {
      if (!window.confirm(`Remove approval for ${type.name}?`)) {
        return;
      }

      this.setApprovalForType(type, false);
    }
  }

  suspendDriver() {
    if (!window.confirm('Suspend this driver')) {
      return;
    }

    this.state.databaseReference
      .update({
        status: 'suspended',
        approved: false,
        suspended: true,
        coordinates: null,
      })
      .then(() => DriverGeoFire.remove(this.state.driverId))
      .then(() => DriverGeoFireActive.remove(this.state.driverId))
      .catch(error => window.alert(`There was an error suspending the driver. "${error.message}"`));
  }

  rejectDriver(event) {
    event.preventDefault();

    if (!window.confirm('Reject this driver')) {
      return;
    }

    this.state.databaseReference
      .update({
        status: 'rejected',
        approved: false,
        suspended: false,
        coordinates: null,
      })
      .then(() => DriverGeoFire.remove(this.state.driverId))
      .then(() => DriverGeoFireActive.remove(this.state.driverId))
      .catch(error => window.alert(`There was an error rejecting the driver. "${error.message}"`));
  }

  unSuspendDriver() {
    if (window.confirm('Un-suspend driver')) {
      this.setStatusToApproved();
    }
  }

  createCheckrCandidate() {
    this.setState({checkrCall: 'pending'});

    // driver uid
    const uid = this.props.match.params.uid;

    // driver email
    const email = this.state.driver.email;

    if (!uid || !email) {
      this.setState({
        checkrCall: 'error',
        checkrCallError: 'No UID or no email',
      });
      return;
    }

    // user ID token
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(idToken => {
        Axios.post(Api.ScoopMDriver + '/create-checkr-candidate', {
          uid,
          idToken,
          email,
        })
          .then(axiosResponse => {
            dmflog('Got response to cand. creation call', axiosResponse);
            this.setState({checkrCall: 'success'});
          })
          .catch(error => {
            dmfError('Error with backend call', error);
            this.setState({
              checkrCall: 'error',
              checkrCallError: 'Error with backend call',
            });
          });
      })
      .catch(error => {
        dmfError("Couldn't get ID token", error);
        this.setState({
          checkrCall: 'error',
          checkrCallError: "Couldn't get ID token",
        });
      });
  }

  onChange({name, value, done}) {
    const parsedValue = typeof value === 'string' ? value.trim() : value;

    const update = {
      [name]:
        typeof parsedValue === 'string' && parsedValue.length === 0 // if it's an empty string
          ? null
          : parsedValue,
    };

    console.log('Updating database...', update);

    this.state.databaseReference.update(update).then(
      () => {
        console.info('Updated database');

        // call callback if present
        done && done(true);
      },
      error => {
        dmflog('Error saving data', error);

        // call callback if present
        done && done(false);
      },
    );
  }

  onSaveNotes(notes) {
    return this.notesRef.set(notes);
  }

  onSaveFelonies(felonies) {
    return new Promise((resolve, reject) => {
      this.onChange({
        name: 'convictedDescription',
        value: felonies,
        done: success => {
          console.log({success});
          if (success) {
            resolve();
          } else {
            reject();
          }
        },
      });
    });
  }

  onControlledSubstanceChange(substance) {
    return (event => {
      // get substances
      let controlledSubstances = this.state.driver.controlledSubstances || [];

      // if checked
      if (event.target.checked) {
        // add substance
        controlledSubstances.push(substance.rawValue);
      } else {
        // remove substance
        var index = controlledSubstances.indexOf(substance.rawValue);
        if (index !== -1) controlledSubstances.splice(index, 1);
      }

      // get unique values
      controlledSubstances = [...new Set(controlledSubstances)];

      this.onChange({
        name: 'controlledSubstances',
        value: controlledSubstances,
      });
    }).bind(this);
  }

  onFlagChange(flag) {
    return event =>
      this.onChange({
        name: flag.property,
        value: event.target.checked || null,
      });
  }

  theComponents() {
    return (
      <div
        className={
          'rounded bg-light p-2 p-md-3 ' + (this.state.driver.isTestingAccount ? 'testing-account-background' : '')
        }>
        {/* special title component */}

        <Title driver={this.state.driver} />

        {this.state.driver.isTestingAccount && <h6 className="alert alert-light text-center">TESTING ACCOUNT</h6>}

        <Card className="mb-2 mb-md-3">
          <div className="row">
            {/* profile picture */}

            <div className="col-auto">
              <FirebaseStorageCircleImage path={`/profiles/${this.state.driverId}`} diameterRem={6} modal />
            </div>

            {/* other content */}

            <div className="col d-flex flex-column justify-content-center">
              <div className="row mb-1">
                {/* first name */}

                <EditableInput
                  name="firstName"
                  value={this.state.driver.firstName}
                  placeholder="First name"
                  onChange={this.onChange.bind(this)}
                  style={{fontSize: '1.75rem', fontWeight: '500'}}
                />

                {/* nickname */}

                {/* "
                <EditableInput
                  name="nickName"
                  value={this.state.driver.nickName}
                  placeholder="Nickname"
                  onChange={this.onChange.bind(this)}
                  style={{ fontSize: '1.75rem', fontWeight: '500' }}
                />
                " */}

                {/* last name */}

                <EditableInput
                  name="lastName"
                  value={this.state.driver.lastName}
                  placeholder="Last name"
                  onChange={this.onChange.bind(this)}
                  style={{fontSize: '1.75rem', fontWeight: '500'}}
                />
              </div>

              <div className="row align-items-center">
                {/* rating */}

                {this.state.driver.stars && (
                  <div className="col-auto px-1 mb-2 mb-md-0">
                    <Link to={`/admin/drivers/feedback/${this.state.driverId}`} className="btn btn-sm btn-light">
                      <FontAwesomeV5 name="star" className="text-warning" /> {this.state.driver.stars}{' '}
                      {pluralize('star', this.state.driver.stars)}
                    </Link>
                  </div>
                )}

                {/* user page link */}

                <ClaimsGuard claim={CustomClaim.manageUsers}>
                  <div className="col-auto px-1 mb-2 mb-md-0">
                    <Link to={`/admin/users/${this.state.driverId}`} className="btn btn-sm btn-light">
                      <FontAwesome.UserSolid /> User profile
                    </Link>
                  </div>
                </ClaimsGuard>

                {/* founatin application link */}

                {unwrap(this.state.driver.fountainApplicantId, id => (
                  <div className="col-auto px-1 mb-2 mb-md-0">
                    <a
                      href={`https://app.fountain.com/wave-mobile-technologies/applicants?applicantId=${id}&page=1&per_page=20&persistedPopupType=applicantDrawerPopup&tabIndex=0`}
                      className="btn btn-sm btn-light">
                      <img
                        src={fountainIcon}
                        width={16}
                        height={16}
                        alt="Fountain logo"
                        style={{verticalAlign: 'text-bottom'}}
                      />{' '}
                      Fountain profile
                    </a>
                  </div>
                ))}

                {/* dashcam */}

                <div className="col-auto px-1 mb-2 mb-md-0">
                  <Link to={`/admin/streaming/dashcam/${this.state.driverId}`} className="btn btn-sm btn-light">
                    <FontAwesome.VideoSolid /> Dashcam
                  </Link>
                </div>

                {/* files */}

                <div className="col-auto px-1 mb-2 mb-md-0">
                  <Link to={`/admin/drivers/${this.state.driverId}/files`} className="btn btn-sm btn-light">
                    <FontAwesomeV5 name="file" /> Files
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2 mt-md-3">
            <div className="col">
              <UserTripsTable uid={this.state.driverId} userType="DRIVER" onWhiteBackground />
            </div>
          </div>
          <div className="text-right mb-n2">
            <small>
              <Link to={`/admin/jobs/driver/${this.state.driverId}`}>All jobs &rarr;</Link>
            </small>
          </div>
        </Card>

        <div className="row mb-2 mb-md-3">
          <div className="col mb-2 mb-md-3">
            <SectionCard title="Status" alternateTitle={capitalize(this.state.driver.status)}>
              {/* <Separator /> */}

              <div className="d-flex justify-content-between align-items-center mb-2 mb-md-3 rounded bg-light p-2 p-md-3">
                <strong>{capitalize(this.state.driver.status)}</strong>

                <div>
                  {/* approve button */}

                  {(this.state.driver.status === DbConstants.status.pending.identifier ||
                    (this.state.driver.status !== DbConstants.status.approved.identifier &&
                      this.state.driver.status !== DbConstants.status.suspended.identifier &&
                      this.state.driver[Db.fields.driver.firstName] &&
                      this.state.driver[Db.fields.driver.lastName] &&
                      (this.state.driver[Db.fields.driver.cellphone] || this.state.driver[Db.fields.driver.phone]) &&
                      this.state.driver[Db.fields.driver.licensePlateNumber] &&
                      this.state.driver[Db.fields.driver.color])) && (
                    <button type="button" onClick={this.approveDriver} className="btn btn-primary mr-2">
                      Approve
                    </button>
                  )}

                  {/* reject button */}

                  {this.state.driver.status !== DbConstants.status.approved.identifier &&
                    this.state.driver.status !== DbConstants.status.rejected.identifier &&
                    this.state.driver.status !== DbConstants.status.suspended.identifier && (
                      <button type="button" onClick={this.rejectDriver} className="btn btn-danger">
                        Reject
                      </button>
                    )}

                  {/* suspend button */}

                  {this.state.driver.status === 'approved' && (
                    <a onClick={this.suspendDriver} href="#suspend" className="btn btn-outline-danger">
                      <i className="fas fa-stop-circle mr-2"></i>Suspend
                    </a>
                  )}

                  {/* un-suspend button */}

                  {this.state.driver.status === 'suspended' && (
                    <a onClick={this.unSuspendDriver} href="#cancel-suspension" className="btn btn-outline-primary">
                      <i className="fas fa-play-circle mr-2"></i>Un-suspend
                    </a>
                  )}
                </div>
              </div>

              <div className="mb-2 mb-md-3">
                {/* applied through scoopm */}

                {this.state.driver.agreedToScoopMDriver && (
                  <span className="text-info">
                    <i className="fas fa-car mr-2 text-green" />
                    Applied through ScoopM driver signup
                    <br />
                  </span>
                )}

                {/* applied through dmf */}

                {this.state.driver.agreedToDMFRunner && (
                  <span className="text-danger">
                    <i className="fas fa-tasks mr-2" />
                    Applied through DoMeAFavor signup
                    <br />
                  </span>
                )}

                {/* applied for butler? */}

                {this.state.driver.appliedForButler && (
                  <span>
                    <FontAwesome.InfoCircle className="mr-2" />
                    Applied for ScoopM Butler
                    <br />
                  </span>
                )}

                {/* applied for nanny? */}

                {this.state.driver.appliedForNanny && (
                  <span>
                    <FontAwesome.InfoCircle className="mr-2" />
                    Applied for ScoopM Nanny &mdash; {this.state.driver.carSeats || 'no'} car seats
                    <br />
                  </span>
                )}
              </div>

              {this.state.driver.status === 'approved' && (
                <div className="mb-2 mb-md-3">
                  {TripType.allCases.map(type => {
                    const id = 'type-' + type.rawValue;
                    const checked = Boolean(
                      unwrap(this.state.driver, driver => unwrap(driver.types, types => types[type.rawValue])),
                    );
                    return (
                      <div key={type.rawValue} className="custom-control custom-switch">
                        <input
                          id={id}
                          type="checkbox"
                          className="custom-control-input"
                          checked={checked}
                          onChange={event => this.toggleForType(type, !checked)}
                        />
                        <label htmlFor={id} className="custom-control-label">
                          {type.title}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}

              <Separator />

              {driverFlagsApproved
                .filter(flag => !flag.searchOnly)
                .map((flag, i) => (
                  <div key={i} className="custom-control custom-checkbox">
                    <input
                      id={`flag-${flag.property}`}
                      type="checkbox"
                      value={flag.property}
                      onClick={this.onFlagChange(flag)}
                      onChange={e => {}}
                      className="custom-control-input"
                      checked={this.state.driver[flag.property]}
                    />
                    <label htmlFor={`flag-${flag.property}`} className="custom-control-label">
                      {flag.name}
                    </label>
                  </div>
                ))}

              <Separator />

              <Subtitle>CONTROLLED SUBSTANCES</Subtitle>

              {ControlledSubstance.allCases.map((substance, i) => {
                const id = `input-controlled-substance-${substance.rawValue}`;
                const checked = Boolean(
                  unwrap(
                    this.state.driver.controlledSubstances,
                    substances => substances.indexOf(substance.rawValue) !== -1,
                  ),
                );

                return (
                  <div key={i} className="custom-control custom-checkbox">
                    <input
                      id={id}
                      type="checkbox"
                      value={substance.rawValue}
                      onClick={this.onControlledSubstanceChange(substance)}
                      onChange={e => {}}
                      className="custom-control-input"
                      checked={checked}
                    />
                    <label htmlFor={id} className="custom-control-label">
                      {substance.label}
                    </label>
                  </div>
                );
              })}

              <Separator />

              <Subtitle>AVAILABILITY</Subtitle>
              <DriverAvailability driverId={this.state.driverId} data={this.state.driver} />

              <Separator />

              <ClaimsGuard claim={CustomClaim.manageApi}>
                <Subtitle>CURRENT ZENDRIVE PERIOD</Subtitle>
                {unwrap(this.state.zendriveTrip, zt => `${zt.period} ${zt.insurancePlan}`, 'None')}
                <Separator />
              </ClaimsGuard>

              <ClaimsGuard claim={CustomClaim.manageFinancial}>
                <Subtitle>STRIPE ACCOUNT</Subtitle>
                {unwrap(
                  this.state.driver.stripeAccountId,
                  id => (
                    <a
                      href={
                        process.env.REACT_APP_ENVIRONMENT === 'production'
                          ? `https://dashboard.stripe.com/connect/accounts/${id}`
                          : `https://dashboard.stripe.com/test/connect/accounts/${id}`
                      }>
                      Profile
                    </a>
                  ),
                  'None',
                )}
                <Separator />
              </ClaimsGuard>
            </SectionCard>

            <SectionCard title="Vehicles">
              <Vehicles driver={this.state.driver} />

              <Separator />

              <div className="row">
                <div className="col-auto">
                  <p className="mb-1">License state</p>
                  <EditableSelect
                    name="licenseState"
                    value={this.state.driver.licenseState}
                    options={StatesOptions}
                    allowOther
                    placeholder="License state"
                    onChange={this.onChange.bind(this)}
                  />
                </div>
                <div className="col-auto">
                  <p className="mb-1">Driver's License</p>
                  <FirebaseStorageSquareImage
                    path={'licenses/' + this.state.driver.id}
                    thumbnailPath={undefined}
                    isImage={undefined}
                  />
                </div>
                <div className="col-auto">
                  <p className="mb-1">Registration</p>
                  <FirebaseStorageSquareImage
                    path={'registrations/' + this.state.driver.id}
                    thumbnailPath={undefined}
                    isImage={undefined}
                  />
                </div>
              </div>
            </SectionCard>

            <ClaimsGuard claim={CustomClaim.manageFinancial}>
              <SectionCard title="Earnings">
                <Earnings driver={this.state.driver} />
              </SectionCard>
            </ClaimsGuard>
          </div>

          <div className="col">
            <SectionCard title="Details">
              {/* middle name, nickname */}

              <Subtitle>NICKNAME, MIDDLE NAME</Subtitle>

              <div>
                <EditableInput
                  name="nickName"
                  value={this.state.driver.nickName}
                  placeholder="Nickname"
                  onChange={this.onChange.bind(this)}
                  className={'h5 mb-0 '}
                />
              </div>
              <div>
                <EditableInput
                  name="middleName"
                  value={this.state.driver.middleName === false ? '' : this.state.driver.middleName}
                  placeholder="Middle name"
                  onChange={this.onChange.bind(this)}
                  className={'h5 mb-0 '}
                />
              </div>
              <p>
                <small className="text-muted">
                  <FontAwesome.InfoCircle /> As we improve our apps, our riders will see the nickname rather than the
                  formal first name
                </small>
              </p>

              <Separator />
              <Subtitle>CONTACT</Subtitle>

              {/* phone */}

              <div className="d-flex w-100 justify-content-between mb-2">
                <EditableInput
                  name="cellphone"
                  value={unwrap(this.state.driver.cellphone || this.state.driver.phone, formatPhone)}
                  placeholder="Phone number"
                  onChange={({value, ...args}) => this.onChange({value: formatPhoneE164(value), ...args})}
                />

                <div>
                  <button
                    onClick={this.didPhone}
                    className="btn btn-sm btn-outline-secondary no-min-width mr-2"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Click to save a timestamp of when you contacted the driver">
                    <FontAwesome.Plus />
                  </button>
                  <a
                    href={'tel:' + (this.state.driver.cellphone || this.state.driver.phone)}
                    className="btn btn-sm btn-outline-primary mr-2">
                    <i className="fas fa-phone" />
                  </a>
                </div>
              </div>

              {/* email */}

              <>
                <div className="d-flex w-100 justify-content-between">
                  <EditableInput
                    name="email"
                    value={this.state.driver.email || this.state.driverEmail}
                    placeholder="Correspondance email"
                    onChange={this.onChange.bind(this)}
                  />
                  <div>
                    <button
                      onClick={this.didEmail}
                      className="btn btn-sm btn-outline-secondary no-min-width mr-2"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="Click to save a timestamp of when you contacted the driver">
                      <FontAwesome.Plus />
                    </button>
                    <a
                      href={'mailto:' + (this.state.driver.email || this.state.driverEmail)}
                      className="btn btn-sm btn-outline-primary mr-2">
                      <i className="fas fa-envelope" />
                    </a>
                  </div>
                </div>
                <p>
                  <small className="text-muted">
                    <FontAwesome.InfoCircle /> This email is only for correspondance. The email{' '}
                    {baseUserShortName(this.state.driver)} uses to sign in is {this.state.driverEmail ?? '--'}. They can
                    change their sign-in email by clicking their <Link to="/profile/email-password">profile</Link> on
                    waveride.co
                  </small>
                </p>
              </>

              {/* Communications */}

              {this.state.communications && (
                <div className="mt-xl-4">
                  <Subtitle>COMMUNICATIONS</Subtitle>
                  <ul>
                    {this.state.communications.map((comm, i) => {
                      return (
                        <li key={i}>
                          {moment.unix(comm.timestamp).format(`MM/DD [at] h:mma`)} by{' '}
                          {CommunicationType[ReverseCommunicationType[comm.type]].description}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              <Separator />
              <Subtitle>ADDRESS</Subtitle>

              {/* Address */}

              {typeof this.state.driver.address === 'object' && (
                <div>
                  <div>
                    <EditableInput
                      name="address/line1"
                      value={this.state.driver.address.line1}
                      placeholder="Address line 1"
                      onChange={this.onChange.bind(this)}
                    />
                  </div>
                  <div>
                    <EditableInput
                      name="address/line2"
                      value={this.state.driver.address.line2}
                      placeholder="Address line 2"
                      onChange={this.onChange.bind(this)}
                    />
                  </div>
                  <EditableInput
                    name="address/city"
                    value={this.state.driver.address.city}
                    placeholder="City"
                    onChange={this.onChange.bind(this)}
                  />
                  ,
                  <EditableInput
                    name="address/region"
                    value={this.state.driver.address.region}
                    placeholder="State/province/region"
                    onChange={this.onChange.bind(this)}
                  />
                  <EditableInput
                    name="address/postalCode"
                    value={this.state.driver.address.postalCode}
                    placeholder="Postal code"
                    onChange={this.onChange.bind(this)}
                  />
                  <EditableSelect
                    name="address/country"
                    value={this.state.driver.address.country}
                    options={CountryOptions}
                    allowOther
                    placeholder="Country"
                    onChange={this.onChange.bind(this)}
                  />
                </div>
              )}
              {/* Old address format */}
              {typeof this.state.driver.address === 'string' && (
                <div>
                  <h5>Address</h5>
                  <h6>(old format, can't edit)</h6>
                  <p>
                    {this.state.driver.address}
                    <br />
                    {this.state.driver.city} {this.state.driver.state} {this.state.driver.zip}
                    <br />
                    United States
                  </p>
                </div>
              )}

              {/* Date of birth */}
              {!this.state.driver.fountainApplicantId && (
                <>
                  <Separator />
                  <Subtitle>DATE OF BIRTH</Subtitle>

                  <EditableInput
                    name="dob"
                    value={this.state.driver.dob}
                    placeholder="YYYY-MM-DD"
                    onChange={this.onChange.bind(this)}
                  />

                  {this.state.driver.dob &&
                    `(Age ${Math.floor((Date.now() - Date.parse(this.state.driver.dob)) / 31557600000)})`}

                  <Separator />

                  <Subtitle>BACKGROUND CHECK</Subtitle>

                  {/* CHECKR ERROR */}

                  {this.state.driver.checkrError && (
                    <div className="alert alert-warning" role={'alert'}>
                      <h5 className={'alert-heading'}>Error</h5>
                      <p>There was an error creating the candidate/report</p>
                      <Separator />
                      <span>{this.state.driver.checkrErrorMessage}</span>
                    </div>
                  )}

                  {/* re-try checkr candidate */}
                  {!this.state.driver.checkrCandidateId && (
                    <div>
                      {!this.state.checkrCall && (
                        <button
                          onClick={this.createCheckrCandidate.bind(this)}
                          className="btn btn-outline-secondary mr-2">
                          <i className="fas fa-redo mr-2"></i>Retry creating Checkr candidate
                        </button>
                      )}
                      {this.state.checkrCall === 'pending' && <div className="alert alert-info">Please wait...</div>}
                      {this.state.checkrCall === 'error' && (
                        <div className="alert alert-warning">
                          <p>Oh, no. There was an error.</p>
                          <pre>{this.state.checkrCallError}</pre>
                        </div>
                      )}
                      {this.state.checkrCall === 'success' && (
                        <div className="alert alert-success">Done! This message should disappear</div>
                      )}
                    </div>
                  )}

                  {/* CHECKR BUTTON */}
                  {this.state.driver.checkrCandidateId && (
                    <a
                      href={'https://dashboard.checkr.com/candidates/' + this.state.driver.checkrCandidateId}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-outline-secondary mr-2">
                      <i className="fas fa-user-shield mr-2"></i>View on Checkr
                      <FontAwesome.ExternalLinkAltSolid className="ml-2" />
                    </a>
                  )}

                  <Separator />

                  {/* Reference 1 */}

                  <div className="mb-2 mb-md-3">
                    <Subtitle>REFERENCE 1</Subtitle>

                    <div className="mb-2">
                      <EditableInput
                        name="ref1Name"
                        value={this.state.driver.ref1Name}
                        placeholder="Name"
                        onChange={this.onChange.bind(this)}
                        className=""
                      />
                    </div>

                    <div className="d-flex justify-content-between mb-2">
                      <EditableInput
                        name="ref1Phone"
                        value={this.state.driver.ref1Phone}
                        placeholder="Phone"
                        onChange={this.onChange.bind(this)}
                        className=""
                      />
                      {this.state.driver.ref1Phone && (
                        <a href={'tel:' + this.state.driver.ref1Phone} className="btn btn-outline-primary mr-2">
                          <i className="fas fa-phone" />
                        </a>
                      )}
                    </div>

                    <div className="d-flex justify-content-between mb-2">
                      <EditableInput
                        name="ref1Email"
                        value={this.state.driver.ref1Email}
                        placeholder="Email"
                        onChange={this.onChange.bind(this)}
                        className=""
                      />
                      {this.state.driver.ref1Email && (
                        <a href={'mailto:' + this.state.driver.ref1Email} className="btn btn-outline-primary mr-2">
                          <i className="fas fa-envelope" />
                        </a>
                      )}
                    </div>
                  </div>

                  {/* Reference 2 */}

                  <div>
                    <Subtitle>REFERENCE 2</Subtitle>

                    <div className="mb-2">
                      <EditableInput
                        name="ref2Name"
                        value={this.state.driver.ref2Name}
                        placeholder="Name"
                        onChange={this.onChange.bind(this)}
                        className=""
                      />
                    </div>

                    <div className="d-flex justify-content-between mb-2">
                      <EditableInput
                        name="ref2Phone"
                        value={this.state.driver.ref2Phone}
                        placeholder="Phone"
                        onChange={this.onChange.bind(this)}
                        className=""
                      />
                      {this.state.driver.ref2Phone && (
                        <a href={'tel:' + this.state.driver.ref2Phone} className="btn btn-outline-primary mr-2">
                          <i className="fas fa-phone" />
                        </a>
                      )}
                    </div>

                    <div className="d-flex justify-content-between mb-2">
                      <EditableInput
                        name="ref2Email"
                        value={this.state.driver.ref2Email}
                        placeholder="Email"
                        onChange={this.onChange.bind(this)}
                        className=""
                      />
                      {this.state.driver.ref2Email && (
                        <a href={'mailto:' + this.state.driver.ref2Email} className="btn btn-outline-primary mr-2">
                          <i className="fas fa-envelope" />
                        </a>
                      )}
                    </div>
                  </div>
                </>
              )}
            </SectionCard>

            <SectionCard title="Notes" alternateTitle={this.state.notes}>
              <EditableTextarea
                onSave={this.onSaveNotes.bind(this)}
                value={this.state.notes}
                className="form-control mb-0"
                rows={8}
              />
            </SectionCard>

            {!this.state.driver.fountainApplicantId && (
              <SectionCard title="Insurance">
                <Separator />

                {this.state.driver.policyProvider === 'buyInsurance' && (
                  <p>This driver wants to buy ScoopM Insurance</p>
                )}

                <div className="mb-0">
                  <EditableInput
                    name="policyProvider"
                    value={this.state.driver.policyProvider}
                    placeholder="Policy provider"
                    onChange={this.onChange.bind(this)}
                  />
                </div>

                <div className="mb-2 mb-md-3">
                  <EditableInput
                    name="policyNumber"
                    value={this.state.driver.policyNumber}
                    placeholder="Policy number"
                    onChange={this.onChange.bind(this)}
                  />
                </div>

                <div className="row">
                  <div className="col-sm-auto">
                    <Subtitle>INSURANCE CARD</Subtitle>
                    <FirebaseStorageSquareImage path={'insurance-cards/' + this.props.match.params.uid} />
                  </div>
                </div>
              </SectionCard>
            )}

            {!this.state.driver.fountainApplicantId && (
              <SectionCard title="Other uploads">
                <Separator />
                <div className="row">
                  <div className="col-sm-auto">
                    <p className="mb-1">W-9</p>
                    <FirebaseStorageSquareImage path={'w9s/' + this.props.match.params.uid} />
                  </div>
                  <div className="col-sm-auto">
                    <p className="mb-1">Fingerprints</p>
                    <FirebaseStorageSquareImage path={'figngerprints/' + this.props.match.params.uid} />
                  </div>
                </div>
              </SectionCard>
            )}

            {!this.state.driver.fountainApplicantId && (
              <SectionCard title="Felonies/Misdemeanors">
                <Separator />
                <EditableTextarea
                  name="convicted"
                  value={
                    this.state.driver.convictedDescription ||
                    (this.state.driver.convicted ? '' : 'Applicant claims none')
                  }
                  onSave={this.onSaveFelonies.bind(this)}
                  className="form-control"
                  rows={5}
                />
              </SectionCard>
            )}
          </div>
        </div>

        <Card innerPadded={false}>
          <RawData
            data={this.state.driver}
            keysToHide={['ssn', 'fcmRegistrationTokens', 'signatureSvgBase64', 'signatureDataBase30']}
            defaultIsCollapsed
          />
        </Card>

        <ViewInFirebase path={`/drivers/${this.state.driverId}`} />
      </div>
    );
  }
}

export function Subtitle({children}) {
  return <h6 className="text-muted">{children}</h6>;
}

export function Separator() {
  return <hr className="my-2 my-md-3" />;
}

function Title({driver}) {
  useTitle(baseUserFullName(driver));
  return null;
}
