import {last, omit, sortBy, times} from 'lodash';
import {useMemo, useState} from 'react';
import {unwrap} from 'wave-common';
import {primaryAndSecondaryTripStatusDescription} from 'wave-common/lib/models/PrimaryAndSecondaryTripStatus';
import {firstScheduledTimestampMsWithDefault, TripOfNumber} from 'wave-common/lib/models/Trip';
import EnumDescription from 'wave-common/lib/type-descriptions/Enum';
import {anyPropertyDescriptionValue} from 'wave-common/lib/type-descriptions/PropertyDescription';
import {DescriptionForColumn, DescriptionForGrouping, MINIMIZED_GROUP_LENGTH} from '.';
import useResult from '../../../../hooks/useResultNew';
import Cell, {CellOfEmpty, CellOfProperty} from './Cell';

interface Group {
  value: string | undefined;
  userFacingValue: string;
  trips: TripOfNumber[];
}

const userFacingValueCache: Record<string, string> = {};

export default function useCells({
  descriptionForGrouping,
  selectedDescriptionsForColumns,
  trips,
  expandedGroups,
  setExpandedGroups,
  setHighlightedTrip,
  setSelectedTrip,
  highlightedTrip,
  selectedTrip,
}: {
  descriptionForGrouping: DescriptionForGrouping;
  selectedDescriptionsForColumns: DescriptionForColumn[];
  trips: TripOfNumber[] | undefined;
  expandedGroups: Record<string, boolean>;
  setExpandedGroups: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  setHighlightedTrip: (trip: TripOfNumber | undefined) => void;
  highlightedTrip?: TripOfNumber;
  setSelectedTrip: (trip: TripOfNumber | undefined) => void;
  selectedTrip?: TripOfNumber;
}) {
  const [selectedTrips, setSelectedTrips] = useState<Record<string, TripOfNumber | undefined>>({});

  const rows = useResult(
    useMemo(async () => {
      // create groups

      let groups: Record<string, Group> = {};
      if (trips) {
        for (const trip of trips) {
          const value = unwrap(
            anyPropertyDescriptionValue(descriptionForGrouping, trip, descriptionForGrouping.key),
            String,
          );

          const groupKey = String(value);

          let userFacingValue: string;
          if (value && userFacingValueCache[groupKey]) {
            userFacingValue = userFacingValueCache[groupKey];
          } else {
            if (value) {
              userFacingValue = await descriptionForGrouping.userFacingValue(value, trip);
              userFacingValueCache[groupKey] = userFacingValue;
            } else {
              userFacingValue = 'None';
            }
          }

          if (groups[groupKey]) {
            groups[groupKey].trips.push(trip);
          } else {
            groups[groupKey] = {
              value,
              userFacingValue,
              trips: [trip],
            };
          }
        }
      }

      // sort groups

      const groupsArray = sortBy(Object.values(groups), group => {
        const order = (descriptionForGrouping as unknown as EnumDescription<any>).order;
        if (order) {
          return order(group.value);
        } else {
          return group.userFacingValue;
        }
      });

      // sort trips of group

      for (const group of groupsArray) {
        group.trips = sortBy(group.trips, firstScheduledTimestampMsWithDefault);
      }

      // build rows array

      const rows: Cell[][] = [];

      // iterate groups

      const unhighlight = () => setHighlightedTrip(undefined);
      const emptyCell: CellOfEmpty = {type: 'EMPTY', onMouseEnter: unhighlight};

      groupsArray.forEach(group => {
        // trip rows

        const groupValueString = String(group.value);
        const isExpanded = expandedGroups[groupValueString];

        const rowIndexForHeading = rows.length;

        (isExpanded ? group.trips : group.trips.slice(0, MINIMIZED_GROUP_LENGTH)).forEach(
          (trip, tripIndex, tripsArray) => {
            const firstRow = tripIndex === 0;
            const lastRow = tripIndex === tripsArray.length - 1;
            const isSelected = trip === selectedTrip;
            const isHighlighted = trip === highlightedTrip || isSelected;
            const highlight = () => setHighlightedTrip(trip);
            rows.push([
              {
                type: 'SPACER',
                onMouseEnter: unhighlight,
              },
              {
                type: 'MARKER',
                color: primaryAndSecondaryTripStatusDescription.color!(
                  primaryAndSecondaryTripStatusDescription.getValue(trip),
                ),
                roundedTopLeft: firstRow,
                roundedBottomLeft: lastRow,
                onMouseEnter: highlight,
              },
              {
                type: 'CHECKBOX',
                trip,
                isChecked: Boolean(selectedTrips[trip.id]),
                onChange: isChecked =>
                  setSelectedTrips(old => (isChecked ? {...old, [trip.id]: trip} : omit({...old}, trip.id))),
                isHighlighted,
                onMouseEnter: highlight,
              },
              ...selectedDescriptionsForColumns.map((description, descriptionIndex, descriptionsArray) => {
                const lastProperty = descriptionIndex === descriptionsArray.length - 1;
                return {
                  type: 'PROPERTY',
                  description,
                  model: trip,
                  lastProperty,
                  onMouseEnter: highlight,
                  onSelect: () => setSelectedTrip(isSelected ? undefined : trip),
                  isHighlighted,
                } as CellOfProperty<any>;
              }),
              {
                type: 'ACTION',
                roundedTopRight: firstRow,
                roundedBottomRight: lastRow,
                lastAction: true,
                model: trip,
                isHighlighted,
                onMouseEnter: highlight,
              },
              {
                type: 'SPACER',
                onMouseEnter: unhighlight,
              },
            ]);
          },
        );

        // heading (before trip rows)

        rows.splice(rowIndexForHeading, 0, [
          emptyCell,
          emptyCell,
          {
            type: 'HEADING',
            groupUserFacingValue: group.userFacingValue,
            count: group.trips.length,
            onMouseEnter: unhighlight,
          },
          ...times(last(rows)!.length - 3).map(() => emptyCell),
        ]);

        // toggle row

        if (group.trips.length > MINIMIZED_GROUP_LENGTH) {
          rows.push([
            emptyCell,
            emptyCell,
            {
              type: 'TOGGLER',
              isExpanded,
              count: group.trips.length,
              toggle: () => setExpandedGroups(old => ({...old, [groupValueString]: !old[groupValueString]})),
              onMouseEnter: unhighlight,
            },
            ...times(last(rows)!.length - 3).map(() => emptyCell),
          ]);
        }
      });

      // footer

      // rows.push([
      //   {
      //     type: 'SPACER',
      //   },
      // ]);

      return rows;
    }, [
      trips,
      descriptionForGrouping,
      setHighlightedTrip,
      expandedGroups,
      selectedTrip,
      highlightedTrip,
      selectedTrips,
      selectedDescriptionsForColumns,
      setSelectedTrip,
      setExpandedGroups,
    ]),
  );

  return {selectedTrips, ...rows};
}
