import {PaymentIntent as StripePaymentIntent} from '@stripe/stripe-js';
import {EmptyTrip} from 'wave-common';
import HttpReference from '../../platform/HttpReference';
import PaymentIntent from './PaymentIntent';
import Stripe from './Stripe';

export default class PaymentIntents extends HttpReference<void, PaymentIntent, Stripe> {
  get childReferenceType() {
    return PaymentIntent;
  }

  create(trip: EmptyTrip) {
    return this.request<StripePaymentIntent>({
      method: 'POST',
      data: trip,
    });
  }
}
