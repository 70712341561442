import firebase from 'firebase/app';
import FirestoreModel from '../base/FirestoreModel';

export default class UserGroup extends FirestoreModel {
  members: string[];

  constructor(snapshot?: firebase.firestore.DocumentSnapshot) {
    super(snapshot);

    this.members = snapshot?.get('members');
  }
}
