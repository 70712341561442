import React from 'react';
import Constants from '../Constants';

export default function MapContainerColumn({className, children, ...props}) {
  return (
    <div className={'bg-white shadow ' + (className || '')} style={{zIndex: Constants.zIndex.level1}} {...props}>
      {children}
    </div>
  );
}
