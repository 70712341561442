import omit from 'lodash/omit';
import React, {useCallback, useContext, useState} from 'react';
import Alert from '../components/Alert/Alert';
import FontAwesome from '../components/FontAwesome';
import {DelaySpinner} from '../components/Spinner';
import {useAlert} from '../contexts/AlertContext';
import joinClassNames from '../functions/joinClassNames';
import logPromise from '../functions/logPromise';
import BootstrapColor from '../styles/BootstrapColor';
import useIsMountedRef from './useIsMountedRef';

export default function useStatus<T>(): Output<T> {
  const {setAlert} = useAlert();

  const isMountedRef = useIsMountedRef();

  const [state, setState] = useState<State>({
    status: 'IDLE',
    error: undefined,
  });

  const setStatusAndError = useCallback((status: Status, error?: Error) => setState({status, error}), []);

  const handlePromise = useCallback(
    function <T>(promise: Promise<T>, showAlert = false, showErrorMessage = false): Promise<T | void> {
      setStatusAndError('PENDING', undefined);
      return logPromise('Handle promise', promise).then(
        result => {
          if (!isMountedRef.current) return;
          setStatusAndError('SUCCESS');
          return result;
        },
        error => {
          if (!isMountedRef.current) return;
          setStatusAndError('FAILURE', error);
          if (showAlert) {
            const alert = new Alert(defaultErrorMessage, showErrorMessage ? error.message : undefined);
            setAlert(alert);
          }
        },
      );
    },
    [setStatusAndError, setAlert, isMountedRef],
  );

  return {...state, setStatus: setStatusAndError, handlePromise};
}

type State = {
  status: Status;
  error: Error | undefined;
};

type Output<T> = State & {
  setStatus: (status: Status) => void;
  handlePromise: (promise: Promise<T>, showErrorMessage?: boolean, hideAlert?: boolean) => Promise<T | void>;
};

type Status = 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILURE';

const defaultErrorMessage = 'Sorry, there was an error';

/**
 * A button that changes state based on the `status` prop. While pending, the button will be disabled and a spinner will appear in the button after a delay
 */
export function StatusButton(
  props: {
    status: Status;
    errorMessage?: string;
    color?: BootstrapColor;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>,
) {
  return (
    <div>
      {/* button */}

      <button
        className={
          props.className ||
          joinClassNames(
            'btn',
            props.status === 'FAILURE' ? 'btn-outline-danger' : props.color ? `btn-${props.color}` : 'btn-success',
          )
        }
        disabled={props.disabled || props.status === 'PENDING'}
        {...omit(props, 'status', 'errorMessage', 'className', 'disabled', 'color')}>
        {props.status === 'PENDING' ? (
          <DelaySpinner childrenBeforeTimeout={props.children as undefined} small />
        ) : (
          props.children
        )}
      </button>

      {/* error alert */}

      {props.status === 'FAILURE' && (
        <div className="alert alert-danger p-2 mt-1 mt-md-2 mb-0">
          <FontAwesome.ExclamationCircleSolid /> {props.errorMessage ?? defaultErrorMessage}
        </div>
      )}
    </div>
  );
}
