import firebase from 'firebase/app';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {DelaySpinner} from '../../../components/Spinner';
import formatCurrency from '../../../functions/formatCurrency';
import joinClassNames from '../../../functions/joinClassNames';
import unwrap from '../../../functions/unwrap';
import useCallbackCaller from '../../../hooks/useCallbackCaller';
import {useDeepComparisonMemo} from '../../../hooks/useDeepComparisonEffect';
import useFirestoreListenerWithJoin from '../../../hooks/useFirestoreListenerWithJoin';
import Trip from '../../../models/scoopm/Trip';
import TripStatus from '../../../models/scoopm/TripStatus';
import RealtimeDatabase from '../../../references/database/RealtimeDatabase';

export default function UserTripsTable({
  uid,
  statuses = [TripStatus.created, TripStatus.scheduled, TripStatus.accepted, TripStatus.started],
  descending = false,
  limit,
  onChangeCount,
  onWhiteBackground = false,
  userType,
}: {
  userType: 'DRIVER' | 'CUSTOMER';
  uid: string;
  statuses?: TripStatus[];
  descending?: boolean;
  limit: number;
  onChangeCount?: (count: number | null) => void;
  onWhiteBackground?: boolean;
}) {
  const reference = useDeepComparisonMemo(() => {
    if (uid) {
      let reference = firebase
        .firestore()
        .collection('trips')
        .where(
          'status',
          'in',
          statuses.map(status => status.rawValue),
        )
        .where(userType === 'DRIVER' ? 'driverId' : 'customerId', '==', uid)
        .orderBy('createdAt', 'desc');
      if (limit) reference = reference.limit(limit);
      return reference;
    } else {
      return undefined;
    }
  }, [uid, statuses, limit, userType]);

  const {snapshot, isLoading, error, getAdditionalDoc} = useFirestoreListenerWithJoin({
    reference,
    callback,
  });

  const sortedDocs = useMemo(() => {
    if (!snapshot) return null;
    let sortedDocs = sortBy(snapshot.docs, doc => {
      const trip = doc.data();
      return Trip.prototype.mostRelevantTimestampMs.apply(trip);
    });
    if (descending) {
      sortedDocs = reverse(sortedDocs);
    }
    return sortedDocs;
  }, [snapshot, descending]);

  useCallbackCaller(
    onChangeCount,
    unwrap(sortedDocs, docs => docs.length),
  );

  // render

  if (!uid) return null;

  if (error) return <>Error loading driver trips</>;

  if (isLoading || !snapshot) return <DelaySpinner />;

  return (
    <div
      className={joinClassNames(
        'table-responsive mt-0',
        onWhiteBackground ? 'rounded border-left border-right border-bottom' : '',
      )}>
      <table className="table table-wrap table-wrap-smaller table-sm table-sm-text table-hover mb-0">
        <thead className="thead-light">
          <tr>
            <th>Order ID</th>
            <th>Brand name</th>
            <th>Status</th>
            <th>Pickup</th>
            <th>Drop-off</th>
            <th>Est. miles</th>
            <th>Tip</th>
            <th>Charge</th>
            <th>Driver</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {sortedDocs?.length === 0 ? (
            <tr>
              <td colSpan={10}>None</td>
            </tr>
          ) : (
            sortedDocs?.map(doc => {
              const trip = doc.data();
              const quote: any = getAdditionalDoc(doc);
              return (
                <tr key={doc.id}>
                  <Cell doc={doc}>{Trip.prototype.simplestTitle.apply(trip)}</Cell>
                  {/* <Cell doc={doc}>{JSON.stringify(trip.customer)}</Cell> */}
                  <Cell doc={doc}>{(quote && quote.brandName) ?? trip.vendorName}</Cell>
                  <Cell doc={doc}>
                    {unwrap(TripStatus.from(trip.status) as TripStatus, status => status.titleWithEmoji(trip))}
                  </Cell>
                  <Cell doc={doc}>{Trip.prototype.pickupComponent.apply(trip)}</Cell>
                  <Cell doc={doc}>{Trip.prototype.dropOffComponent.apply(trip)}</Cell>
                  <Cell doc={doc}>{quote && Math.round(quote.miles * 10) / 10}</Cell>
                  <Cell doc={doc}>{trip.tip && formatCurrency(trip.tip)}</Cell>
                  <Cell doc={doc}>{quote && quote.fee && formatCurrency(quote.fee)}</Cell>
                  <Cell doc={doc}>{!trip.driver ? null : `${trip.driver.firstName} ${trip.driver.lastName}`}</Cell>
                  <Cell doc={doc}>{Trip.prototype.time.apply(trip)}</Cell>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}

const Cell = ({doc, children}: {doc: firebase.firestore.DocumentSnapshot; children: any}) => {
  const tripId = doc.id;
  const customerId = doc.get('customerId');
  return (
    <td className="p-0">
      <Link to={`/admin/jobs/${customerId}/${tripId}`} className="d-block p-1 text-decoration-none text-dark">
        {children}
      </Link>
    </td>
  );
};

async function callback(tripDoc: firebase.firestore.DocumentSnapshot) {
  const quoteId = tripDoc.get('quoteId');
  return quoteId ? (await RealtimeDatabase.instance.child('quotes').child(quoteId).once())[0] : null;
}
