import GeoFireHelper from '../../functions/GeoFireHelper';

const first = require('lodash/first');

export default class Place {}

Place.nearCoordinatesArray = async coordinatesArray => {
  const placeSnapshots = await GeoFireHelper.query(
    'places',
    'location.geohash',
    'location.coordinates',
    coordinatesArray,
    100 /* meters */,
  );
  return first(placeSnapshots);
};
