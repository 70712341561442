import Enum from '../base/Enum';

export default class PositionInList extends Enum {
  locationName(count) {
    if (count === 1) {
      return 'Task';
    }
    switch (this) {
      case PositionInList.first:
        return 'Pickup';
      case PositionInList.last:
        return 'Drop-off';
      default:
        return 'Stop';
    }
  }

  locationFontAwesomeClassName() {
    switch (this) {
      case PositionInList.first:
        return 'fas fa-map-marker-alt';
      case PositionInList.last:
        return 'fas fa-flag-checkered';
      default:
        return 'fas fa-plus';
    }
  }

  mapIconPath() {
    switch (this) {
      case PositionInList.first:
        return buildingPath;
      case PositionInList.last:
        return flagPath;
      case PositionInList.middle:
      default:
        return pinPath;
    }
  }

  mapIconUrl() {
    switch (this) {
      case PositionInList.first:
        return '/images/building-solid.png';
      case PositionInList.middle:
        return '/images/map-pin-solid.png';
      case PositionInList.last:
        return '/images/flag-checkered-solid.png';
      default:
        return '/images/map-pin-solid.png';
    }
  }
}

PositionInList.first = new PositionInList('FIRST');
PositionInList.middle = new PositionInList('MIDDLE');
PositionInList.last = new PositionInList('LAST');

PositionInList.for = (i, array) => {
  if (i === 0) {
    return PositionInList.first;
  } else if (i === array.length - 1) {
    return PositionInList.last;
  } else {
    return PositionInList.middle;
  }
};

const buildingPath =
  'M 25.1136 27.648 h -1.152 V 1.3824 c 0 -0.7635 -0.6189 -1.3824 -1.3824 -1.3824 H 3.2256 C 2.4621 0 1.8432 0.6189 1.8432 1.3824 v 26.2656 H 0.6912 c -0.3817 0 -0.6912 0.3095 -0.6912 0.6912 v 1.152 h 25.8048 v -1.152 c 0 -0.3817 -0.3095 -0.6912 -0.6912 -0.6912 z M 7.3728 4.3776 c 0 -0.3817 0.3095 -0.6912 0.6912 -0.6912 h 2.304 c 0.3817 0 0.6912 0.3095 0.6912 0.6912 v 2.304 c 0 0.3817 -0.3095 0.6912 -0.6912 0.6912 h -2.304 c -0.3817 0 -0.6912 -0.3095 -0.6912 -0.6912 V 4.3776 z m 0 5.5296 c 0 -0.3817 0.3095 -0.6912 0.6912 -0.6912 h 2.304 c 0.3817 0 0.6912 0.3095 0.6912 0.6912 v 2.304 c 0 0.3817 -0.3095 0.6912 -0.6912 0.6912 h -2.304 c -0.3817 0 -0.6912 -0.3095 -0.6912 -0.6912 v -2.304 z m 2.9952 8.5248 h -2.304 c -0.3817 0 -0.6912 -0.3095 -0.6912 -0.6912 v -2.304 c 0 -0.3817 0.3095 -0.6912 0.6912 -0.6912 h 2.304 c 0.3817 0 0.6912 0.3095 0.6912 0.6912 v 2.304 c 0 0.3817 -0.3095 0.6912 -0.6912 0.6912 z m 4.3776 9.216 h -3.6864 v -4.8384 c 0 -0.3817 0.3095 -0.6912 0.6912 -0.6912 h 2.304 c 0.3817 0 0.6912 0.3095 0.6912 0.6912 v 4.8384 z m 3.6864 -9.9072 c 0 0.3817 -0.3095 0.6912 -0.6912 0.6912 h -2.304 c -0.3817 0 -0.6912 -0.3095 -0.6912 -0.6912 v -2.304 c 0 -0.3817 0.3095 -0.6912 0.6912 -0.6912 h 2.304 c 0.3817 0 0.6912 0.3095 0.6912 0.6912 v 2.304 z m 0 -5.5296 c 0 0.3817 -0.3095 0.6912 -0.6912 0.6912 h -2.304 c -0.3817 0 -0.6912 -0.3095 -0.6912 -0.6912 v -2.304 c 0 -0.3817 0.3095 -0.6912 0.6912 -0.6912 h 2.304 c 0.3817 0 0.6912 0.3095 0.6912 0.6912 v 2.304 z m 0 -5.5296 c 0 0.3817 -0.3095 0.6912 -0.6912 0.6912 h -2.304 c -0.3817 0 -0.6912 -0.3095 -0.6912 -0.6912 V 4.3776 c 0 -0.3817 0.3095 -0.6912 0.6912 -0.6912 h 2.304 c 0.3817 0 0.6912 0.3095 0.6912 0.6912 v 2.304 z';

const flagPath =
  'M 13.7894 10.7673 V 14.6286 c 1.4799 0.3345 2.7953 0.8845 4.1731 1.2644 v -3.8669 c -1.4742 -0.3289 -2.801 -0.8789 -4.1731 -1.2587 z m 12.6611 -6.9741 c -1.9448 0.9015 -4.3376 1.8087 -6.6339 1.8087 C 16.7832 5.602 14.2714 3.6288 10.4498 3.6288 c -1.4175 0 -2.6819 0.2495 -3.8556 0.6804 c 0.1588 -0.4139 0.2325 -0.8618 0.2041 -1.3381 C 6.6963 1.3608 5.3752 0.068 3.7592 0 C 1.9448 -0.0737 0.4536 1.3778 0.4536 3.1752 c 0 1.0773 0.5387 2.0299 1.3608 2.6025 V 27.6696 c 0 0.7541 0.6067 1.3608 1.3608 1.3608 h 0.9072 c 0.7541 0 1.3608 -0.6067 1.3608 -1.3608 v -5.3525 c 1.6046 -0.6861 3.6061 -1.2531 6.4865 -1.2531 c 3.0391 0 5.5453 1.9732 9.3668 1.9732 c 2.7329 0 4.9159 -0.9242 6.9457 -2.319 c 0.4933 -0.3402 0.7825 -0.8959 0.7825 -1.4969 V 5.4375 c 0.0057 -1.3211 -1.3721 -2.2 -2.5742 -1.6443 z M 9.6163 18.4558 c -1.4629 0.1531 -2.835 0.4649 -4.1731 0.9412 v -3.9973 c 1.4855 -0.5273 2.6933 -0.8505 4.1731 -0.9866 z M 26.3088 10.8297 c -1.3381 0.5557 -2.6252 1.1057 -4.1731 1.3551 V 16.2162 c 1.4062 -0.1928 2.9144 -0.6691 4.1731 -1.4742 v 3.9973 c -1.4232 0.9129 -2.75 1.4005 -4.1731 1.5366 V 16.2162 c -1.5309 0.2098 -2.7159 0.0851 -4.1731 -0.3175 v 3.8216 c -1.3551 -0.4196 -2.6819 -0.9469 -4.1731 -1.2077 V 14.6286 c -1.117 -0.2495 -2.3134 -0.3856 -4.1731 -0.2155 v -3.969 c -1.2701 0.1758 -2.5288 0.5783 -4.1731 1.185 v -3.9973 c 1.8824 -0.6917 2.8407 -1.1227 4.1731 -1.2474 v 4.0597 c 1.5309 -0.2098 2.7443 -0.0737 4.1731 0.3232 v -3.8216 c 1.3438 0.4196 2.6762 0.9469 4.1731 1.2077 v 3.8783 c 1.3438 0.3005 2.6989 0.3912 4.1731 0.1531 V 8.1081 c 1.5309 -0.2722 2.9654 -0.7711 4.1731 -1.2757 z';

const pinPath =
  'M 21 3.5 C 14.2275 3.5 8.75 8.9775 8.75 15.75 c 0 9.1875 12.25 22.75 12.25 22.75 s 12.25 -13.5625 12.25 -22.75 c 0 -6.7725 -5.4775 -12.25 -12.25 -12.25 z m 0 16.625 c -2.415 0 -4.375 -1.96 -4.375 -4.375 s 1.96 -4.375 4.375 -4.375 s 4.375 1.96 4.375 4.375 s -1.96 4.375 -4.375 4.375 z';
