import React, {Component} from 'react';

class InvalidCode extends Component {
  render() {
    return (
      <div>
        <h3>Try resetting your password again</h3>
        <p>Your request to reset your password has expired or the link has already been used</p>
      </div>
    );
  }
}

export default InvalidCode;
