import React, {Component} from 'react';

class PasswordWasReset extends Component {
  render() {
    return (
      <div>
        <h3>Your password has been reset</h3>
        <a href={this.props.continueUrl || '/'} className="btn btn-primary float-right">
          {this.props.continueUrl ? 'Continue' : 'Home'}
        </a>
      </div>
    );
  }
}

export default PasswordWasReset;
