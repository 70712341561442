import WaveCommonTripType from 'wave-common/lib/models/TripType';
import Enum from '../base/Enum';
import SelectOption from '../SelectOption';

export default class TripType extends Enum {
  static scoopM = new TripType('scoopM');
  static scoopXl = new TripType('scoopXl');

  static delivery = new TripType('delivery');

  static runner = new TripType('runner');

  static butler = new TripType('butler');
  static nanny = new TripType('nanny');
  static taxi = new TripType('taxi');

  static suv = new TripType('SUV');
  static lux = new TripType('LUX');
  static posh = new TripType('POSH');

  static selectOptions = TripType.allCases.map(source => new SelectOption(source.rawValue, source.title));

  static visibleToAdmins() {
    return [
      TripType.delivery,
      TripType.scoopM,
      TripType.scoopXl,
      // TripType.butler,
      // TripType.nanny,
      // TripType.taxi,
      TripType.suv,
      TripType.lux,
      TripType.posh,
      TripType.runner,
    ];
  }

  get title() {
    switch (this.rawValue) {
      case TripType.scoopM.rawValue:
        return 'Rideshare';
      case TripType.scoopXl.rawValue:
        return 'WaveXL';
      case TripType.delivery.rawValue:
        return 'Delivery';
      case TripType.runner.rawValue:
        return 'Task';
      case TripType.butler.rawValue:
        return 'Butler';
      case TripType.nanny.rawValue:
        return 'Nanny';
      case TripType.taxi.rawValue:
        return 'Taxi';
      case TripType.suv.rawValue:
        return 'SUV';
      case TripType.lux.rawValue:
        return 'LUX';
      case TripType.posh.rawValue:
        return 'Posh';
      default:
        return '-';
    }
  }

  customerFacingTitle() {
    switch (this.rawValue) {
      case TripType.scoopM.rawValue:
        return 'Rideshare';
      case TripType.scoopXl.rawValue:
        return 'WaveXL';
      case TripType.delivery.rawValue:
        return 'Delivery';
      case TripType.runner.rawValue:
        return 'Task';
      case TripType.butler.rawValue:
        return 'Butler Service';
      case TripType.nanny.rawValue:
        return 'Nanny Service';
      case TripType.taxi.rawValue:
        return 'Taxi';
      case TripType.suv.rawValue:
        return 'Premium SUV';
      case TripType.lux.rawValue:
        return 'Lux';
      case TripType.posh.rawValue:
        return 'Posh';
      default:
        return '';
    }
  }

  get titleWithEmoji() {
    switch (this.rawValue) {
      case TripType.scoopM.rawValue:
        return '🚗 Rideshare';
      case TripType.scoopXl.rawValue:
        return '🚙 ScoopXL';
      case TripType.delivery.rawValue:
        return '🍔 Delivery';
      case TripType.runner.rawValue:
        return '🗒 Task';
      case TripType.butler.rawValue:
        return '👨‍✈️ Butler';
      case TripType.nanny.rawValue:
        return '👵 Nanny';
      case TripType.taxi.rawValue:
        return '🚕 Taxi';
      case TripType.suv.rawValue:
        return '🚙 SUV';
      case TripType.lux.rawValue:
        return '🤵 LUX';
      case TripType.posh.rawValue:
        return '🏎 Posh';
      default:
        return '-';
    }
  }

  Icon() {
    switch (this.rawValue) {
      case TripType.scoopM.rawValue:
        return () => '🚗';
      case TripType.scoopXl.rawValue:
        return () => '🚙';
      case TripType.delivery.rawValue:
        return () => '🍔';
      case TripType.runner.rawValue:
        return () => '🗒';
      case TripType.butler.rawValue:
        return () => '👨‍✈️';
      case TripType.nanny.rawValue:
        return () => '👵';
      case TripType.taxi.rawValue:
        return () => '🚕';
      case TripType.suv.rawValue:
        return () => '🚙';
      case TripType.lux.rawValue:
        return () => '🤵';
      case TripType.posh.rawValue:
        return () => '🏎';
      default:
        return null;
    }
  }

  hasTripColors() {
    switch (this) {
      case TripType.runner:
      case TripType.delivery:
        return false;
      default:
        return true;
    }
  }
}

export function tripTypeImageSource(type: WaveCommonTripType) {
  switch (type) {
    case 'LUX':
      return '/images/trip-type-lux.png';
    case 'SUV':
      return '/images/trip-type-suv.png';
    default:
      return '/images/trip-type-xl.png';
  }
}
