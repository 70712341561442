import React from 'react';
import {Driver} from 'wave-common';
import Vehicle from 'wave-common/lib/models/Vehicle';
import useFuelImageUrl from '../hooks/useFuelImageUrl';
import SquareImage from './SquareImage';

export default function FuelSquareImage({
  vehicle,
  driver,
}: {
  vehicle?: Pick<Vehicle, 'year' | 'make' | 'model' | 'color'>;
  driver?: Driver;
}) {
  const url = useFuelImageUrl(vehicle, driver);
  return <SquareImage url={url} isImage />;
}
