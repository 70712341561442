import React from 'react';
import './MicroTransit.css';

export default function MicroTransit() {
  return (
    <div className="microtransit-page">
      <header className="header-section">
        <div className="header-image-container">
          <img 
            src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/microtransit-website-images%2F701A7509ps.jpg?alt=media&token=19ff36a9-ee53-402f-bc5a-7082444eed60" 
            alt="Microtransit Services" 
            className="header-image" 
          />
          <div className="header-overlay">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/microtransit-website-images%2Flogo_words_trans%20(1).png?alt=media&token=abedd2eb-3b9d-4934-a0c4-470f54620309" 
              alt="Wave MicroTransit Logo" 
              className="header-logo" 
            />
            <h1 className="header-title">Innovative Microtransit Solutions</h1>
            <p className="header-subtitle">Connecting Communities with Flexible Transportation Options.</p>
            <a 
              href="https://forms.gle/7T6fnjQVZbgB7S8d9" 
              className="btn btn-primary header-cta"
              target="_blank"
              rel="noopener noreferrer"
            >
              Express Your Interest
            </a>
          </div>
        </div>
      </header>

      <section className="service-section alt-background">
        <div className="service-content">
          <div className="text-content">
            <h2>Flexible Routing</h2>
            <h3>Adaptive Routes for Dynamic Needs</h3>
            <ul>
              <li>Wave designs routes based on real-time demand and community needs, ensuring efficient and effective transportation.</li>
              <li>Our technology-driven approach allows for adjustments to routes and schedules as needed, providing maximum flexibility.</li>
            </ul>
            <h3>Enhanced Accessibility</h3>
            <ul>
              <li>We prioritize accessibility, ensuring that our microtransit services are available to all community members, including those with mobility challenges.</li>
              <li>Our vehicles are equipped to handle various accessibility requirements, promoting inclusive transportation solutions.</li>
            </ul>
          </div>
          <div className="image-content">
            <img src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/microtransit-website-images%2F457512074_830495585894786_4129189068789811672_n.jpg?alt=media&token=25b2755d-f6cd-4750-a4aa-cc74874d9d4d" alt="Flexible Routing" />
          </div>
        </div>
      </section>

      <section className="service-section">
        <div className="service-content">
          <div className="image-content">
            <img src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/microtransit-website-images%2F701A7250ps.jpg?alt=media&token=e4277cf4-d928-49a2-bb7a-3f570f355e4e" alt="On-Demand Transit" />
          </div>
          <div className="text-content">
            <h2>On-Demand Transit</h2>
            <h3>Responsive Transportation Services</h3>
            <ul>
              <li>Wave offers on-demand transit options that cater to the specific timing and location needs of our users.</li>
              <li>Our app-based booking system makes it easy for users to request rides at their convenience.</li>
            </ul>
            <h3>Scalable Solutions</h3>
            <ul>
              <li>Our microtransit services can scale to accommodate varying levels of demand, from small communities to larger urban areas.</li>
              <li>We work closely with local governments and organizations to implement solutions that meet the unique needs of each area.</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="service-section alt-background">
        <div className="service-content">
          <div className="text-content">
            <h2>Technology Integration</h2>
            <h3>Smart Transportation Systems</h3>
            <ul>
              <li>Wave integrates advanced technologies to optimize route planning, vehicle tracking, and user experience.</li>
              <li>Our data-driven approach ensures that our services are both efficient and reliable.</li>
            </ul>
            <h3>Real-Time Tracking</h3>
            <ul>
              <li>Users can track their rides in real-time, providing transparency and peace of mind.</li>
              <li>Our system allows for seamless communication between riders and drivers, enhancing the overall experience.</li>
            </ul>
          </div>
          <div className="image-content">
            <img src="https://firebasestorage.googleapis.com/v0/b/towmasters-2f8b3.appspot.com/o/microtransit-website-images%2F455807287_823241753286836_8757105803326824644_n.jpg?alt=media&token=b3b09e7b-f0f3-408b-a650-237eab724d94" alt="Technology Integration" />
          </div>
        </div>
      </section>

      <section className="market-needs-section">
        <h2>Understanding the Need in Charlotte and Atlanta</h2>
        <div className="market-content">
          <div className="market-city alt-background">
            <h3>Charlotte</h3>
            <p>
              Charlotte, with its rapidly growing population and expanding urban landscape, faces significant transportation challenges. Microtransit offers a flexible solution to address:
            </p>
            <ul>
              <li>Reducing traffic congestion in key areas.</li>
              <li>Providing reliable transportation options for underserved neighborhoods.</li>
              <li>Enhancing connectivity between residential and commercial districts.</li>
            </ul>
          </div>
          <div className="market-city alt-background">
            <h3>Atlanta</h3>
            <p>
              Atlanta's diverse and sprawling metropolitan area demands innovative transportation solutions. Our microtransit services aim to:
            </p>
            <ul>
              <li>Improve accessibility in both central and peripheral areas.</li>
              <li>Support the transportation needs of a diverse population.</li>
              <li>Integrate seamlessly with existing public transit systems.</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Embedded Google Form */}
      <section className="form-section">
        <h2>Express Your Interest</h2>
        <div className="iframe-container">
          <iframe 
            src="https://docs.google.com/forms/d/e/1FAIpQLSeQqJaoj7M1nUw0Ahyi9Yz4zLgGorpoF8jVHGek4qY2kCgQdg/viewform?embedded=true" 
            width="640" 
            height="1475" 
            frameBorder="0" 
            marginHeight="0" 
            marginWidth="0"
            title="Express Your Interest Form"
            loading="lazy"
          >
            Loading…
          </iframe>
        </div>
      </section>
      
      <section className="contact-section">
        <h2>Contact Us</h2>
        {/* Removed the 200x100 image here */}
        <p className="highlighted-contact">
          Need Microtransit Services? <a href="tel:18339283663" className="contact-link">Call us at 1-833-WAVE2ME</a>
        </p>
      </section>
    </div>
  );
}