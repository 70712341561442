import omit from 'lodash/omit';
import React, {useCallback} from 'react';
import Price, {priceDescription} from '../../models/stripe/Price';
import ScoopMApi from '../../references/scoopm-api';
import {PropertyDisplayProps} from './PropertyDisplay';
import StripeObjectDisplay from './StripeObjectDisplay';

export default function StripePriceDisplay(props: PropertyDisplayProps<string>) {
  const referenceForId = useCallback((id: string) => ScoopMApi.instance.stripe.prices.child(id), []);
  const format = useCallback((price: Price) => priceDescription(price), []);
  return (
    <StripeObjectDisplay referenceForId={referenceForId} format={format} {...omit(props, 'referenceForId', 'format')} />
  );
}
