import capitalize from 'lodash/capitalize';
import {formatPhone} from '../../../../functions/formatPhone';
import unwrap from '../../../../functions/unwrap';
import Driver from '../../../../models/scoopm/Driver';
import driverFlags from './driverFlags';

const csvKeys = [
  {key: 'firstName', heading: 'First name'},
  {key: 'lastName', heading: 'Last name'},
  {key: 'email', heading: 'Email'},
  {
    getValue: driver => {
      const rawPhone = Driver.prototype.safePhoneNumber.apply(driver);
      const formattedPhone = unwrap(rawPhone, formatPhone);
      return formattedPhone;
    },
    heading: 'Phone',
  },
  {key: 'status', heading: 'Status', getValue: driver => capitalize(driver.status)},
  ...driverFlags.filter(flag => !flag.searchOnly).map(flag => ({key: flag.property, heading: flag.name})),
  {key: 'dob', heading: 'Date of birth'},
  {key: 'year', heading: 'Car year'},
  {key: 'make', heading: 'Car make'},
  {key: 'model', heading: 'Car model'},
  {key: 'carColor', heading: 'Car color'},
  {key: 'licenseNumber', heading: 'License number'},
  {
    getValue: driver => Driver.prototype.addressString.apply(driver),
    heading: 'Address',
  },
  {key: 'isActivated', heading: 'Active', getValue: driver => (driver.isActivated ? 'Yes' : 'No')},
];

export default csvKeys;
