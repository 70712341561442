import VendorApplicationModel from '../../models/scoopm/VendorApplication';
import HttpReference from '../platform/HttpReference';
import VendorApplications from './VendorApplications';

export default class VendorApplication extends HttpReference<VendorApplicationModel, void, VendorApplications> {
  approve(priceId: string) {
    return this.request({
      method: 'PATCH',
      data: {
        action: 'APPROVE',
        priceId,
      },
    });
  }
}
