import ScoopMApi from '.';
import Trip from '../../models/scoopm/Trip';
import HttpReference from '../platform/HttpReference';

export default class TripEstimates extends HttpReference<any, void, ScoopMApi> {
  create(trip: Trip) {
    return this.request({
      method: 'POST',
      data: trip,
      headers: {
        Authorization: null,
      },
    });
  }
}
