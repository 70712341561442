import firebase from 'firebase/app';
import find from 'lodash/find';
import first from 'lodash/first';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import React, {useEffect, useMemo, useState} from 'react';
import ReactDatePicker from 'react-datepicker';
import Delay from '../../../components/Delay';
import DropdownMenu from '../../../components/DropdownMenu';
import FontAwesome from '../../../components/FontAwesome';
import StandardSpacer from '../../../components/Spacer';
import Spinner from '../../../components/Spinner';
import {useAdditionalNavbarContent} from '../../../contexts/AdditionalNavbarContentContext';
import joinClassNames from '../../../functions/joinClassNames';
import unwrap from '../../../functions/unwrap';
import useApprovedDrivers from '../../../hooks/useApprovedDrivers';
import useFilterCities from '../../../hooks/useFilterCities';
import {useFirestoreQueryGetInterval} from '../../../hooks/useFirestoreQueryGet';
import useJobsTableTimezone from '../../../hooks/useJobsTableTimezone';
import useLocalStorage, {useLocalStorageDate} from '../../../hooks/useLocalStorage';
import useStatesAndRegions from '../../../hooks/useStatesAndRegions';
import Trip from '../../../models/scoopm/Trip';
import TripLocation from '../../../models/scoopm/TripLocation';
import {TripSource} from '../../../models/scoopm/TripSource';
import TripType from '../../../models/scoopm/TripType';
import Vendor from '../../../models/scoopm/Vendor';
import SelectOption from '../../../models/SelectOption';
import useVendors from '../../../useVendors';
import AdditionalMenuSectionsContext from './AdditionalMenuSectionsContext';
import MenuSection from './MenuSection';
import MenuToggleButton from './MenuToggleButton';
import NavbarColumn, {defaultClassName, defaultClassNameWithoutMargin} from './NavbarColumn';
import NavbarHeading from './NavbarHeading';
import NavbarSelect from './NavbarSelect';
import Sort from './Sort';
import StatusGroup from './StatusGroup';
import Timezone from './Timezone';

export interface WithJobsDataProps {
  trips?: Trip[];
  error?: Error;
  timezone: string;
  driverId?: string;
  onChangeDriverId: (id: string) => void;
}

export default function withJobsData(Component: React.ComponentType<WithJobsDataProps>) {
  return function () {
    const {isLoading: citiesAreLoading, cities: filterCities} = useFilterCities();

    // trip data

    const [reference, setReference] = useState<firebase.firestore.Query<firebase.firestore.DocumentData>>();
    const {error, isLoading, models} = useFirestoreQueryGetInterval(reference, Trip, 30_000);
    const [trips, setTrips] = useState<Trip[] | undefined>();

    // sort

    const [sort, setSort] = useLocalStorage('JOBS_TABLE_SORT', Sort.dropOffAscending as any);

    // start/end dates

    const [startDate, setStartDate] = useLocalStorageDate('JOBS_TABLE_START_DATE');
    const [endDate, setEndDate] = useLocalStorageDate('JOBS_TABLE_END_DATE');
    const endDateEndOfDay = useMemo(() => unwrap(endDate, date => moment(date).endOf('day').toDate()), [endDate]);

    // status

    const [statusGroup, setStatusGroup] = useLocalStorage('JOBS_TABLE_STATUS_GROUP', StatusGroup.allSuccessful as any);

    // source

    const sourceOptions = useMemo(() => TripSource.selectOptions(), []);
    const [sourceRawValue, setSourceRawValue] = useLocalStorage('JOBS_TABLE_SOURCE');

    // vendor

    const vendors = useVendors();
    const vendorOptions = useMemo(
      () =>
        unwrap(vendors.models, _ =>
          _.map((vendor: Vendor | undefined) => new SelectOption(vendor?.id || '', vendor?.name)),
        ),
      [vendors.models],
    );
    const [vendorId, setVendorId] = useLocalStorage('JOBS_TABLE_VENDOR_ID');

    // query size / limit

    const limitOptions = useMemo(
      () =>
        [10, 20, 50, 100, 250, 500, 1000, 5000].map(
          (number, i, arr) => new SelectOption(String(number), `${number} jobs`),
        ),
      [],
    );
    const [limit, setLimit] = useLocalStorage('JOBS_TABLE_LIMIT', limitOptions[6].value as any);

    // region

    const {regionOptions, regionValue, regionObject, setRegion, state} = useStatesAndRegions(limit);

    // driver

    const drivers = useApprovedDrivers();
    const driverOptions = useMemo(
      () => drivers?.map(driver => new SelectOption(driver.id, driver.fullName())),
      [drivers],
    );
    const [driverId, setDriverId] = useLocalStorage('JOSB_TABLE_DRIVER_ID');

    // type

    const [typeRawValue, setType] = useLocalStorage('JOBS_TABLE_TYPE');
    const type = TripType.from(typeRawValue);

    // search

    const [searchValue, setSearch] = useLocalStorage('JOBS_TABLE_SEARCH');
    const search = unwrap(searchValue, value => value.toString());

    // time zone

    const guessedTimezone = useMemo(() => moment.tz.guess(), []);
    const timezoneOptions = useMemo(() => {
      const timezones = Timezone.allCases();
      const options = timezones.map(timezone => new SelectOption(timezone, Timezone.title(timezone)));
      if (guessedTimezone && !timezones.includes(guessedTimezone)) {
        const localOption = new SelectOption(guessedTimezone, `Local (${guessedTimezone.replaceAll('_', ' ')})`);
        options.splice(0, 0, localOption);
      }
      return options;
    }, [guessedTimezone]);
    const [timezone, setTimezone] = useJobsTableTimezone();

    // ui state

    const [isExpanded, setIsExpanded] = useLocalStorage('JOBS_TABLE_SETTINGS_EXPANDED', true as any);
    const [additionalMenuSections, setAdditionalMenuSections] = useState<React.ReactNode | undefined>();

    // create reference

    useEffect(() => {
      let query: firebase.firestore.Query = firebase.firestore().collection('trips');

      // status

      query = query.where(
        'status',
        'in',
        StatusGroup.statuses(statusGroup).map(_ => _.rawValue),
      );

      // firstScheduledTimestampMs

      if (sort === Sort.dropOffAscending) {
        query = query.orderBy('firstScheduledTimestampMs', 'asc');
      } else if (sort === Sort.dropOffDescending) {
        query = query.orderBy('firstScheduledTimestampMs', 'desc');
      } else if (startDate || endDateEndOfDay) {
        query = query.orderBy('firstScheduledTimestampMs', 'asc');
      }

      // start/end timestamps

      if (startDate) {
        query = query.where('firstScheduledTimestampMs', '>=', startDate.valueOf());
      }

      if (endDateEndOfDay) {
        query = query.where('firstScheduledTimestampMs', '<=', endDateEndOfDay.valueOf());
      }

      // createdAt

      if (sort === Sort.createdAtDescending) {
        query = query.orderBy('createdAt', 'desc');
      }

      // source

      const source = TripSource.from(sourceRawValue);
      if (source) {
        query = query.where('source', '==', source.rawValue);
      }

      // driverId

      if (driverId) {
        query = query.where('driverId', '==', driverId);
      }

      // state

      if (state) {
        query = query.where('state', '==', state);
      }

      // vendorId

      if (vendorId) {
        query = query.where('vendorId', '==', vendorId);
      }

      // type

      if (type) {
        query = query.where('type', '==', type.rawValue);
      }

      // limit

      const limitNumber = Number(limit) || 1000; // failsafe
      query = query.limit(limitNumber);

      // set reference

      setReference(query);
    }, [sort, limit, driverId, sourceRawValue, state, statusGroup, type, vendorId, startDate, endDateEndOfDay]);

    // create trips

    useEffect(() => {
      setTrips(
        unwrap(models, (models: Trip[]) => {
          let trips: Trip[] = [];

          // filter docs

          if (citiesAreLoading) {
            return trips;
          }

          trips = models;

          if (filterCities) {
            // filter docs filter cities

            trips = models.filter(model => {
              const locations: TripLocation[] | null = model.locationsWithDefault();
              const pickup = first(locations)!;
              const cityContainingPickup = filterCities.find(city => city.contains(pickup.coordinates));
              const jobIsWithinCity = Boolean(cityContainingPickup);
              return jobIsWithinCity;
            });
          }

          // filter rows by search term

          if (search) {
            const searchLowerCase = search.toLowerCase().trim();
            trips = trips.filter(trip => trip.searchValue().includes(searchLowerCase));
          }

          // filter docs by selected region

          if (regionObject) {
            trips = trips.filter(trip => {
              const locations: TripLocation[] | null = trip.locationsWithDefault();
              const containedLocation = find(locations, location => regionObject.contains(location.coordinates));
              return Boolean(containedLocation);
            });
          }

          return trips;
        }) || undefined,
      );
    }, [models, search, citiesAreLoading, filterCities, regionObject, timezone]);

    // navbar content

    useAdditionalNavbarContent(
      useMemo(
        () => (
          <>
            {isExpanded && (
              <>
                {/* spinner */}

                {isLoading && (
                  <Delay amountMs={500}>
                    <div className="col-auto d-flex align-items-center px-1">
                      <Spinner />
                    </div>
                  </Delay>
                )}

                {/* sort */}

                <NavbarSelect
                  id="sort-select"
                  text="Sort by"
                  options={Sort.options}
                  value={sort}
                  onChange={setSort}
                  noEmptyValue
                />

                <NavbarColumn>
                  <NavbarHeading text="Date range" />
                  <div className="d-flex bg-white rounded">
                    <ReactDatePicker
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={
                        (([start, end]: Date[]) => {
                          setStartDate(start);
                          setEndDate(end);
                        }) as any
                      }
                      className="form-control form-control-sm border-0"
                      dateFormat="MM/dd"
                      selectsRange
                    />
                    {endDate && (
                      <button
                        type="button"
                        onClick={event => {
                          setStartDate(undefined);
                          setEndDate(undefined);
                        }}
                        className="btn btn-sm text-gray-700">
                        <FontAwesome.Times />
                      </button>
                    )}
                  </div>
                </NavbarColumn>

                {/* status */}

                <NavbarSelect
                  id="status-select"
                  text="Status"
                  options={StatusGroup.options}
                  value={statusGroup}
                  onChange={setStatusGroup}
                  noEmptyValue
                />

                {/* source */}

                <NavbarSelect
                  id="source-select"
                  text="Source"
                  options={sourceOptions}
                  value={sourceRawValue}
                  onChange={setSourceRawValue}
                />

                {/* vendor id */}

                <NavbarSelect
                  id="vendorid-select"
                  text="Vendor"
                  options={vendorOptions ?? undefined}
                  value={vendorId}
                  onChange={setVendorId}
                />

                {/* region */}

                <NavbarSelect
                  id="region-select"
                  text="Region"
                  options={regionOptions}
                  value={regionValue}
                  onChange={setRegion}
                />

                {/* driver */}

                <NavbarSelect
                  id="driver-select"
                  text="Driver"
                  options={driverOptions}
                  value={driverId}
                  onChange={setDriverId}
                />

                {/* type */}

                <NavbarSelect
                  id="type-select"
                  text="Type"
                  options={TripType.selectOptions}
                  value={type?.rawValue}
                  onChange={setType}
                />

                {/* search  */}

                <div className={joinClassNames('col-12 col-md col-xl-2 order-2 order-md-1 ', defaultClassName)}>
                  <NavbarHeading text="Search" />

                  <div className="d-flex justify-content-stretch">
                    <input
                      type="search"
                      value={search || ''}
                      onChange={event => setSearch(event.target.value)}
                      placeholder={unwrap(trips, _ => `${_.length} ${pluralize('job', _.length)}`) || undefined}
                      className="form-control form-control-sm border-0" // bg-primary text-white border-white placeholder-white
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                    />

                    {/* menu */}

                    {isExpanded && (
                      <DropdownMenu className="mx-2" buttonClassName="btn-sm bg-white" right>
                        <div className="p-2 p-md-3">
                          {/* additional menu sections */}

                          {additionalMenuSections && (
                            <>
                              {additionalMenuSections}
                              <StandardSpacer />
                              <hr />
                            </>
                          )}

                          {/* time zone */}

                          <MenuSection title="Time zone">
                            <select
                              value={timezone}
                              onChange={event => setTimezone(event.target.value)}
                              className="custom-select custom-select-sm">
                              {SelectOption.render(timezoneOptions)}
                            </select>
                          </MenuSection>

                          <StandardSpacer />

                          {/* page size */}

                          <MenuSection title="Query size">
                            <select
                              value={limit}
                              onChange={event => setLimit(event.target.value)}
                              className="custom-select custom-select-sm">
                              {SelectOption.render(limitOptions)}
                            </select>
                            <small>Smaller queries are faster, larger queries use more data</small>
                          </MenuSection>
                        </div>
                      </DropdownMenu>
                    )}

                    {/* minimize */}

                    <MenuToggleButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                  </div>
                </div>
              </>
            )}

            {!isExpanded && (
              <div className={joinClassNames('col align-items-end', defaultClassNameWithoutMargin)}>
                <MenuToggleButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
              </div>
            )}
          </>
        ),
        [
          isLoading,
          driverOptions,
          driverId,
          setDriverId,
          limitOptions,
          limit,
          setLimit,
          sort,
          setSort,
          regionOptions,
          regionValue,
          setRegion,
          search,
          setSearch,
          statusGroup,
          setStatusGroup,
          startDate,
          setStartDate,
          endDate,
          setEndDate,
          sourceOptions,
          sourceRawValue,
          setSourceRawValue,
          type,
          setType,
          vendorOptions,
          vendorId,
          setVendorId,
          trips,
          setIsExpanded,
          isExpanded,
          timezoneOptions,
          timezone,
          setTimezone,
          additionalMenuSections,
        ],
      ),
    );

    return (
      <AdditionalMenuSectionsContext.Provider value={{setAdditionalMenuSections}}>
        <Component
          trips={trips}
          error={error as any}
          driverId={driverId}
          onChangeDriverId={setDriverId}
          timezone={timezone}
        />
      </AdditionalMenuSectionsContext.Provider>
    );
  };
}
