import Enum from '../../models/base/Enum';

export default class SortingDirection extends Enum {
  firestoreValue() {
    switch (this) {
      case SortingDirection.ascending:
        return 'asc';
      case SortingDirection.descending:
        return 'desc';
      default:
        return null;
    }
  }

  opposite() {
    switch (this) {
      case SortingDirection.ascending:
        return SortingDirection.descending;
      case SortingDirection.descending:
        return SortingDirection.ascending;
      default:
        return null;
    }
  }
}
SortingDirection.ascending = new SortingDirection('asc');
SortingDirection.descending = new SortingDirection('desc');
