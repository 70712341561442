import React from 'react';
import unwrap from '../../functions/unwrap';
import LocationInput from './LocationInput';

export default function TripLocationInput({id, property, value, onChange, validationError, disabled}) {
  // render

  return (
    <div>
      <div className="form-group position-relative">
        <LocationInput
          id={id}
          property={property}
          value={value}
          onChange={onChange}
          validationError={validationError}
          disabled={disabled}
        />
      </div>
      <div className="form-group">
        <label htmlFor={id + `Instructions`}>Instructions</label>
        <textarea
          id={id + `Instructions`}
          className="form-control"
          rows={5}
          value={unwrap(value, value => value.instructions) || ''}
          onChange={event => onChange(Object.assign({}, value, {instructions: event.target.value}))}
        />
      </div>
    </div>
  );
}
