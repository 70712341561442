import React, {useContext} from 'react';
import joinClassNames from '../functions/joinClassNames';
import BootstrapModalContext from '../routes/BootstrapModalContext';
import FontAwesome from './FontAwesome';

/**
 * `url` can be the following:
 * - `undefined` (null output)
 * - falsy ("No image/file")
 * - a url (shows result)
 */
export default function SquareImage({
  url,
  isImage,
  fullsizeUrl,
  disableClick,
  roundedXl,
}: {
  url: string | undefined | null | false;
  isImage?: boolean;
  fullsizeUrl?: string;
  disableClick?: boolean;
  roundedXl?: boolean;
}) {
  const {setModalContent} = useContext(BootstrapModalContext);
  const shouldShowModalContent = isImage && (fullsizeUrl ?? url) && !disableClick;
  return (
    <div
      style={{width: 100, height: 100}}
      onClick={
        shouldShowModalContent
          ? () =>
              setModalContent(<ModalContent url={fullsizeUrl ?? (url || '')} onDismiss={() => setModalContent(null)} />)
          : undefined
      }
      className={joinClassNames(
        roundedXl ? 'rounded-xl' : 'rounded',
        'd-flex justify-content-center align-items-center bg-light',
        shouldShowModalContent && 'cursor-pointer',
      )}>
      {url === undefined ? null : !url ? (
        <span className="text-muted">No {isImage ? 'image' : 'file'}</span>
      ) : isImage ? (
        <img
          src={url}
          alt=""
          className={joinClassNames(roundedXl ? 'rounded-xl' : 'rounded', 'w-100 h-100 border')}
          style={{objectFit: 'cover'}}
        />
      ) : (
        <a
          href={fullsizeUrl ?? url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-center text-muted text-decoration-none">
          <h2 className="mb-0 text-gray-500">
            <i className="fas fa-folder-open" />
          </h2>
          Open &rarr;
        </a>
      )}
    </div>
  );
}

export function ModalContent({url, onDismiss}: {url: string; onDismiss: () => void}) {
  return (
    <div className="position-relative d-flex justify-content-center align-items-center bg-dark">
      <img src={url} alt="" className="mw-100 rounded" />
      <div style={{top: 8, right: 8}} className="d-flex position-absolute">
        <div className="dropdown">
          <button
            id="downloadImageDropdownButton"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
            className="btn p-1 m-1">
            <span className="fa-stack">
              <i className="fas fa-circle fa-stack-2x text-white"></i>
              <i className="fas fa-download fa-stack-1x text-dark"></i>
            </span>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="downloadImageDropdownButton"
            style={{width: '22rem'}}>
            <div className="row mx-2 align-items-center">
              <div className="col-auto">
                <FontAwesome.InfoCircle />
              </div>
              <div className="col">Right-click or long-press the image to show the download option</div>
            </div>
          </div>
        </div>
        <button onClick={onDismiss} className="btn p-1 m-1">
          <span className="fa-stack">
            <i className="fas fa-circle fa-stack-2x text-white"></i>
            <i className="fas fa-times fa-stack-1x text-dark"></i>
          </span>
        </button>
      </div>
    </div>
  );
}
