"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function useKeyboardVerticalOffset(react, useWindowDimensions) {
    const [viewHeight, setViewHeight] = react.useState(0);
    const dimensions = useWindowDimensions();
    const keyboardVerticalOffset = react.useMemo(() => (viewHeight ? dimensions.height - viewHeight : 44), [dimensions.height, viewHeight]);
    const onLayout = react.useCallback((event) => setViewHeight(event.nativeEvent.layout.height), []);
    return {
        keyboardVerticalOffset,
        onLayout,
    };
}
exports.default = useKeyboardVerticalOffset;
