import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';
import DownloadOnAppStore from '../../components/app-download-buttons/DownloadOnAppStore';
import GetItOnGooglePlay from '../../components/app-download-buttons/GetItOnGooglePlay';
import Constants from '../../Constants';
import logoWhite from '../../images/logo_white.png';
import links from '../../routes/home/links';

export default function Footer() {
  return (
    <footer className="footer h-auto bg-dark text-white">
      <div className="container py-5">
        <div className="row">
          <div className="col-sm-6 col-lg mb-4 mb-lg-0">
            <img src={logoWhite} alt="Wave" width="64" />
          </div>

          <div className="col-sm-6 col-lg mb-4 mb-lg-0">
            <h5 className="mb-1">Get the app</h5>
            <p className="text-muted">Download one of our apps. Share and let others know about Wave</p>

            <div className="mb-2">
              <DownloadOnAppStore />
            </div>

            <div className="mb-4">
              <GetItOnGooglePlay imgProps={{style: {margin: '-10px'}}} />
            </div>

            <h5 className="mb-0">Drive for us</h5>
            <p>
              <Link to="/openings">Jobs</Link>
            </p>
          </div>

          <div className="col-sm-6 col-lg mb-4 mb-lg-0">
            <h5>Contact</h5>

            <ul className="list-unstyled mb-4">
              <li>
                <a href={`tel:${Constants.information.supportPhoneE164}`} className="text-light">
                  {Constants.information.supportPhone}
                </a>
              </li>
              <li>
                <a href={`mailto:${Constants.information.supportEmail}`} className="text-light">
                  {Constants.information.supportEmail}
                </a>
              </li>
            </ul>

            <h5>Social</h5>
            <ul className="list-unstyled">
              <li>
                <a href="https://www.facebook.com/Scoop-Mobile-Technologies-202352377015652/" className="text-light">
                  <i className="fab fa-facebook mr-2" />
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://twitter.com/scoopmapp" className="text-light">
                  <i className="fab fa-twitter mr-2" />
                  Twitter
                </a>
              </li>
            </ul>
          </div>

          <div className="col-sm-6 col-lg mb-4 mb-lg-0">
            <h5>Site</h5>
            <ul className="list-unstyled">
              {links.map((link, i) => (
                <li key={i}>
                  <a href={link.to} className="text-light">
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="col">
            <small className="text-muted">&copy; {moment().year()} Wave Mobile Technology, Inc.</small>
          </div>
        </div>
      </div>
    </footer>
  );
}
