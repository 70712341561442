import React from 'react';

export default function CenteredLayout({children, className = undefined, ...props}) {
  return (
    <div
      className={'w-100 h-100 d-flex flex-column align-items-center justify-content-center ' + (className || '')}
      {...props}
    >
      {children}
    </div>
  );
}
