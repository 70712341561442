import React from 'react';
import Delay from '../../components/Delay';
import Spinner from '../../components/Spinner';
import {useAuthContext} from '../../contexts/AuthContext';
import CustomClaim from '../../models/scoopm/CustomClaim';

export default function Guard({children}) {
  const {claims} = useAuthContext();

  if (claims === undefined) {
    return <Loading />;
  } else if (!claims || !claims[CustomClaim.manualEntry]) {
    return (
      <div>
        <h3>You {"aren't"} configured to use the dispatch portal</h3>
        <p>
          Please contact us for support, at <a href="mailto:Wave Support <support@waveride.co>">support@waveride.co</a>.
        </p>
      </div>
    );
  } else {
    return children;
  }
}

function Loading() {
  return (
    <Delay>
      <div className="flex-grow-1 align-self-center d-flex justify-content-center">
        <Spinner className="text-muted" />
      </div>
    </Delay>
  );
}
