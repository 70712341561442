import React from 'react';
import {Link} from 'react-router-dom';
import {Footer} from '../components/Footer';
import logo from '../images/logo.png';

export function GeneralPage({children}) {
  return (
    <div className="d-flex flex-column h-100">
      <nav className="navbar justify-content-center">
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="Wave" width="120" height="120" />
        </Link>
      </nav>
      {children}
      <Footer />
    </div>
  );
}
