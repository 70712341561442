"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.latLngBoundsAroundLatLng = exports.latLngBoundsUnion = exports.latLngBoundsFromRegion = exports.latLngBoundsFromFirestoreBounds = exports.latLngBoundsCenter = void 0;
const geolib_1 = require("geolib");
const LatLng_1 = require("./LatLng");
function latLngBoundsCenter(bounds) {
    return {
        lat: (bounds.northeast.lat + bounds.southwest.lat) / 2,
        lng: (bounds.northeast.lng + bounds.southwest.lng) / 2,
    };
}
exports.latLngBoundsCenter = latLngBoundsCenter;
function latLngBoundsFromFirestoreBounds(bounds) {
    return {
        northeast: (0, LatLng_1.latLngFromCoordinatesObject)(bounds.northeast),
        southwest: (0, LatLng_1.latLngFromCoordinatesObject)(bounds.southwest),
    };
}
exports.latLngBoundsFromFirestoreBounds = latLngBoundsFromFirestoreBounds;
function latLngBoundsFromRegion(region) {
    return {
        northeast: {
            lat: region.latitude + Math.abs(region.latitudeDelta),
            lng: region.longitude + Math.abs(region.longitudeDelta),
        },
        southwest: {
            lat: region.latitude - Math.abs(region.latitudeDelta),
            lng: region.longitude - Math.abs(region.longitudeDelta),
        },
    };
}
exports.latLngBoundsFromRegion = latLngBoundsFromRegion;
function latLngBoundsUnion(bounds1, bounds2) {
    return {
        northeast: {
            lat: Math.max(bounds1.northeast.lat, bounds2.northeast.lat),
            lng: Math.min(bounds1.northeast.lng, bounds2.northeast.lng),
        },
        southwest: {
            lat: Math.min(bounds1.southwest.lat, bounds2.southwest.lat),
            lng: Math.min(bounds1.southwest.lng, bounds2.southwest.lng),
        },
    };
}
exports.latLngBoundsUnion = latLngBoundsUnion;
function latLngBoundsAroundLatLng(latLng, distanceM) {
    return {
        northeast: (0, LatLng_1.latLngFromCoordinatesObject)((0, geolib_1.computeDestinationPoint)(latLng, distanceM, 45)),
        southwest: (0, LatLng_1.latLngFromCoordinatesObject)((0, geolib_1.computeDestinationPoint)(latLng, distanceM, 225)),
    };
}
exports.latLngBoundsAroundLatLng = latLngBoundsAroundLatLng;
