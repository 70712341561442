import firebase from 'firebase/app';
import snakeCase from 'lodash/snakeCase';

export default class RealtimeDatabaseModel {
  static fromPojo = (type, pojo) => Object.assign(new type(), pojo);

  static fromSnapshot = (type, snapshot) => Object.assign(type.fromPojo(type, snapshot.val()), {id: snapshot.key});

  id;

  constructor() {
    //
  }
}

RealtimeDatabaseModel.create = async function (value) {
  if (!value) throw new Error(`RealtimeDatabaseModel.create: 'id' is ${value}`);
  const path = pathForType(this);
  if (!path) throw new Error(`The type '${this.name}' does not have a static 'path' property`);
  await firebase.database().ref(path).push(value);
};

RealtimeDatabaseModel.withId = async function (id) {
  if (!id) throw new Error(`RealtimeDatabaseModel.withId: 'id' is ${id}`);
  const path = pathForType(this);
  if (!path) throw new Error(`The type '${this.name}' does not have a static 'path' property`);
  const snapshot = await firebase.database().ref(path).child(id).once('value');
  if (!snapshot.exists()) return null;
  const data = snapshot.val();
  const Model = this;
  return new Model(data);
};

RealtimeDatabaseModel.update = async function (id, values) {
  if (!id) throw new Error(`RealtimeDatabaseModel.update: 'id' is ${id}`);
  const path = pathForType(this);
  if (!path) throw new Error(`The type '${this.name}' does not have a static 'path' property`);
  console.log('Updating...', path, id, values);
  await firebase.database().ref(path).child(id).update(values);
};

RealtimeDatabaseModel.remove = async function (id) {
  if (!id) throw new Error(`RealtimeDatabaseModel.delete: 'id' is ${id}`);
  const path = pathForType(this);
  if (!path) throw new Error(`The type '${this.name}' does not have a static 'path' property`);
  console.log('Deleting...', path, id);
  await firebase.database().ref(path).child(id).remove();
};

function pathForType(type) {
  return type.path || snakeCase(type.name) + 's';
}
