import React from 'react';
import {MINIMIZED_GROUP_LENGTH} from '.';
import {FontAwesomeV5} from '../../../../components/FontAwesome';
import {CellOfToggler as CellOfTogglerType} from './Cell';
import CellProps from './CellProps';

export default function CellOfToggler({cell, style}: CellProps<CellOfTogglerType>) {
  return (
    <div onMouseEnter={cell.onMouseEnter} style={style} className="d-flex p-1 p-md-2">
      <button type="button" onClick={cell.toggle} className="flex-grow-1 btn btn-sm btn-outline-secondary">
        <FontAwesomeV5 name={cell.isExpanded ? 'minus' : 'plus'} />{' '}
        {cell.isExpanded ? 'Hide' : `Show ${cell.count - MINIMIZED_GROUP_LENGTH} more`}
      </button>
    </div>
  );
}
