import HttpReference from '../platform/HttpReference';
import VehicleMakes from './VehicleMakes';
import VehiclesFuel from './VehiclesFuel';
import VehicleYears from './VehicleYears';

export default class Vehicles extends HttpReference {
  constructor(path, parent) {
    super(path, parent);

    this.fuel = new VehiclesFuel('fuel', this);
    this.makes = new VehicleMakes('makes', this);
    this.years = new VehicleYears('years', this);
  }
}
