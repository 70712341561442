import {BaseUser} from 'wave-common';
import ScoopMApi from '.';
import HttpReference from '../platform/HttpReference';

export default class Customers extends HttpReference<BaseUser, void, ScoopMApi> {
  create(customer: BaseUser) {
    return this.request({
      method: 'POST',
      data: customer,
    });
  }
}
