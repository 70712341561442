import React from 'react';
import {formatCurrency} from 'wave-common';
import unwrap from '../../functions/unwrap';
import {Card} from './Card';

export function PaymentRow({model}) {
  return unwrap(model, model =>
    unwrap(model.estimate, estimate => (
      <div className="row mb-5">
        <div className="col-md-6">
          <Card className="border">
            <div className="row">
              <div className="col">
                <h5>Payment</h5>
                <div>
                  {estimate === 0 ? 'FREE' : formatCurrency(estimate)}
                  {unwrap(model.couponCode, code => ` (${code})`)}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    )),
  );
}
