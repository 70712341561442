import React, {ReactNode, useMemo} from 'react';
import {unwrap} from 'wave-common';
import joinClassNames from '../../functions/joinClassNames';

export default function ValidateableInput({
  elementType = 'input',
  isValid,
  validFeedback,
  invalidFeedback,
  className,
  optional = false,
  intermediateChildren = null,
  validation,
  ...otherProps
}: {
  elementType?: any;
  isValid?: boolean | null;
  validFeedback?: string;
  invalidFeedback?: ReactNode;
  className?: string;
  optional?: boolean;
  intermediateChildren?: ReactNode;
  validation?: undefined | true | string;
} & Record<string, any>) {
  const isValidInternal = useMemo(() => {
    if (typeof isValid === 'boolean') {
      return isValid;
    }
    if (validation !== undefined) {
      return validation === true;
    }
  }, [isValid, validation]);

  const invalidFeedbackInternal = useMemo(
    () =>
      isValidInternal === false
        ? (typeof validation === 'string' ? validation : invalidFeedback) ?? 'Required'
        : undefined,
    [isValidInternal, invalidFeedback, validation],
  );

  return (
    <>
      {React.createElement(elementType, {
        className: joinClassNames(
          className,
          unwrap(isValidInternal, isValid => (isValid ? 'is-valid' : 'is-invalid')),
        ),
        ...otherProps,
      })}

      {intermediateChildren}

      {invalidFeedbackInternal ? (
        <span className="invalid-feedback">{invalidFeedbackInternal}</span>
      ) : (
        <>
          {validFeedback && <span className="valid-feedback">{validFeedback}</span>}
          {optional && <span className="form-text text-muted">Optional</span>}
        </>
      )}
    </>
  );
}
