"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.insurancePlanOrder = exports.insurancePlanTitle = exports.insurancePlanDescription = void 0;
const Enum_1 = require("../type-descriptions/Enum");
const insurancePlanAll = ['ODNC', 'RIDESHARE'];
exports.insurancePlanDescription = (0, Enum_1.enumDescription)({
    userFacingTitle: 'insurance plan',
    values: insurancePlanAll,
    userFacingValue: insurancePlanTitle,
    order: insurancePlanOrder,
});
function insurancePlanTitle(plan) {
    return insurancePlanTitles[plan];
}
exports.insurancePlanTitle = insurancePlanTitle;
const insurancePlanTitles = {
    ODNC: 'ODNC',
    RIDESHARE: 'rideshare',
};
function insurancePlanOrder(plan) {
    return insurancePlanAll.indexOf(plan);
}
exports.insurancePlanOrder = insurancePlanOrder;
