export default function expect<T>(value: T | null | undefined, label?: string): T {
  const labelString = label ?? 'Value';
  if (value === undefined) throw new Error(`${labelString} is undefined`);
  if (value === null) throw new Error(`${labelString} is null`);
  return value;
}

export async function expectAsync<T>(value: T | null | undefined, label?: string): Promise<T> {
  const labelString = label ?? 'Value';
  if (value === undefined) throw new Error(`${labelString} is undefined`);
  if (value === null) throw new Error(`${labelString} is null`);
  return value;
}
