import firebase from 'firebase/app';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactDatePicker from 'react-datepicker';
import DriverSelect from '../../../components/DriverSelect';
import DropdownMenu from '../../../components/DropdownMenu';
import unwrap from '../../../functions/unwrap';
import useFilterCities from '../../../hooks/useFilterCities';
import useJobsTableTimezone from '../../../hooks/useJobsTableTimezone';
import useLocalStorage from '../../../hooks/useLocalStorage';
import Trip from '../../../models/scoopm/Trip';
import TripStatus from '../../../models/scoopm/TripStatus';
import TripsFilter from './TripsFilter';
import TripsList from './TripsList';

export default function TripsPanel({
  onChangeTripEntries,

  selectedTripId,
  onChangeSelectedTripId,

  driverIdForQuery,
  onChangeDriverIdForQuery,
}) {
  const containerDiv = useRef();

  const {isLoading, cities} = useFilterCities();

  const [snapshot, setSnapshot] = useState();
  const [error, setError] = useState();
  const [mappedEntries, setMappedEntries] = useState();
  const [filteredEntries, setFilteredEntries] = useState();
  const [countsByTripsFilter, setCountsByTripsFilter] = useState();

  const [tripsFilterRawValue, setTripsFilterRawValue] = useLocalStorage('TRIPS_FILTER');
  const tripsFilter = TripsFilter.from(tripsFilterRawValue);
  const [startTimestamp, setStartTimestamp] = useLocalStorage('DISPATCH_START_TIMESTAMP');
  const [endTimestamp, setEndTimestamp] = useLocalStorage('DISPATCH_END_TIMESTAMP');
  const [orderBy, setOrderBy] = useLocalStorage('DISPATCH_ORDER_BY', 'dropOffTimestampMs');

  const [query, setQuery] = useState();

  const [timezone] = useJobsTableTimezone();

  // EFFECT: set up snapshot

  useEffect(() => {
    let query = firebase
      .firestore()
      .collection('trips') // .where('fromPlaceId', '==', 'ChIJM0ys1M4F9YgR8yYsxdCTBdg') // .where('fromPlaceName', '==', 'Bubba\'s 33')
      .where(
        'status',
        'in',
        [TripStatus.created, TripStatus.scheduled, TripStatus.accepted, TripStatus.started].map(s => s.rawValue),
      );

    if (startTimestamp) {
      query = query.where('dropOffTimestampMs', '>=', startTimestamp);
    }

    if (endTimestamp) {
      query = query.where('dropOffTimestampMs', '<=', endTimestamp);
    }

    if (driverIdForQuery) {
      query = query.where('driverId', '==', driverIdForQuery);
    }

    // if (orderBy) {
    //     if ((startTimestamp || endTimestamp) && orderBy !== 'dropOffTimestampMs') {
    //         query = query.orderBy('dropOffTimestampMs').orderBy(orderBy)
    //     } else {
    //         query = query.orderBy(orderBy)
    //     }
    // }

    return query.onSnapshot(
      snapshot => {
        setSnapshot(snapshot);
        setError(null);
      },
      error => {
        console.error(error);
        setError(error);
      },
    );
  }, [startTimestamp, endTimestamp, driverIdForQuery]);

  // EFFECT: snapshot --> mapped entries

  useEffect(() => {
    if (isLoading) return; // wait for groups to load

    const countsByTripsFilter = Object.assign({}, ...TripsFilter.allCases.map(tf => ({[tf.rawValue]: 0})));

    let entries = null;
    if (snapshot) {
      entries = snapshot.docs;

      // filter by user group city?

      if (cities) {
        entries = entries.filter(doc => {
          const pickupLatLng = Trip.prototype.fromLatLng.apply(doc.data());
          return cities.find(city => city.contains(pickupLatLng));
        });
      }

      // map

      entries = entries.map(doc => {
        const data = new Trip(doc.data());

        data.statusTitle = unwrap(TripStatus.from(data.status), status => status.title(data));

        data.driverProfilePath = unwrap(data.driverId, id => `/profiles/${id}`);

        data.quickQuery = Trip.prototype.quickQuery.apply(data);

        data.tripsFilters = [];

        if (!data.driverId) {
          data.tripsFilters.push(TripsFilter.open);
        } else if (data.status === TripStatus.scheduled.rawValue) {
          data.tripsFilters.push(TripsFilter.dispatched);
        } else if (data.status === TripStatus.accepted.rawValue || data.status === TripStatus.started.rawValue) {
          data.tripsFilters.push(TripsFilter.underway);
        }

        if (data.isOverdue()) {
          data.tripsFilters.push(TripsFilter.atRisk);
        }

        data.tripsFilters.forEach(filter => {
          countsByTripsFilter[filter.rawValue] += 1;
        });

        return [doc.id, data];
      });
    }

    setMappedEntries(entries);

    setCountsByTripsFilter(countsByTripsFilter);
  }, [isLoading, cities, snapshot]);

  // EFFECT: mapped entries --> filtered entries

  useEffect(() => {
    let entries = unwrap(mappedEntries, entries => [...entries]);
    if (entries) {
      if (tripsFilter) {
        entries = entries.filter(([tripId, trip]) => trip.tripsFilters.includes(tripsFilter));
      }
      if (query) {
        const ql = query.trim().toLowerCase();
        entries = entries.filter(([tripId, trip]) => trip.quickQuery.includes(ql));
      }
      entries = sortBy(
        entries,
        entry => entry[1][orderBy],
        entry => entry[1].createdAt,
      );
    }
    setFilteredEntries(entries);
  }, [mappedEntries, tripsFilter, query, orderBy]);

  // EFFECT: query changes --> check if selected trip is still part of the query

  useEffect(() => {
    if (filteredEntries && selectedTripId) {
      if (!filteredEntries.find(([tripId, trip]) => tripId === selectedTripId)) {
        onChangeSelectedTripId(null);
      }
    }
  }, [filteredEntries, selectedTripId, onChangeSelectedTripId]);

  // EFFECTS for callbacks

  useEffect(() => {
    onChangeTripEntries && onChangeTripEntries(filteredEntries);
  }, [filteredEntries, onChangeTripEntries]);

  // METHODS

  const onClickTripsFilter = useCallback(
    filter => {
      if (filter === tripsFilter) setTripsFilterRawValue(null);
      else setTripsFilterRawValue(filter.rawValue);
    },
    [tripsFilter, setTripsFilterRawValue],
  );
  const onChangeQuery = useCallback(event => setQuery(event.target.value), [setQuery]);
  const onChangeSortBy = useCallback(event => setOrderBy(event.target.value), [setOrderBy]);
  const onChangeStartTimestamp = useCallback(date => setStartTimestamp(date.valueOf()), [setStartTimestamp]);
  const onClearStartTimestamp = useCallback(event => setStartTimestamp(null), [setStartTimestamp]);
  const onChangeEndTimestamp = useCallback(
    date => setEndTimestamp(moment(date).endOf('day').valueOf()),
    [setEndTimestamp],
  );
  const onClearEndTimestamp = useCallback(event => setEndTimestamp(null), [setEndTimestamp]);

  // MEMOs

  const filterButtons = useMemo(
    () =>
      TripsFilter.inOrder().map(filter => (
        <button
          key={filter.rawValue}
          onClick={event => onClickTripsFilter(filter)}
          className={'btn btn-sm ' + (tripsFilter === filter ? 'btn-secondary' : 'btn-outline-secondary')}>
          {String(unwrap(countsByTripsFilter, c => c[filter.rawValue], 0))} {filter.name()}
        </button>
      )),
    [tripsFilter, countsByTripsFilter, onClickTripsFilter],
  );

  // RENDER

  if (error) {
    return <div className="alert alert-warning m-2">There was an error. Try reloading the page. {error.message}</div>;
  }

  return (
    <div className="h-100 d-flex flex-column" style={{maxWidth: '400px'}}>
      <div className="d-flex flex-column p-2 p-md-3 border-bottom" style={{height: '112px'}}>
        <div className="btn-group mb-3">{filterButtons}</div>

        <div>
          <div className="row">
            <div className="col">
              <input
                type="search"
                value={query || ''}
                onChange={onChangeQuery}
                className="form-control form-control-sm"
                placeholder="Search"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
              />
            </div>
            <div className="col-auto">
              <DropdownMenu
                buttonClassName={
                  'btn-sm ' +
                  (startTimestamp || endTimestamp || driverIdForQuery ? 'btn-primary' : 'btn-outline-primary')
                }
                right>
                <div className="p-3">
                  <div className="mb-3">
                    <label htmlFor="datePicker1">Drop-off time</label>
                    <div className="row no-gutters mb-1">
                      <ReactDatePicker
                        id="datePicker1"
                        selected={startTimestamp && new Date(startTimestamp)}
                        onChange={onChangeStartTimestamp}
                        className="form-control form-control-sm"
                        wrapperClassName="col"
                        placeholderText="Start date"
                        popperPlacement="top-end"
                      />
                      {startTimestamp && (
                        <div className="col-auto">
                          <button type="button" onClick={onClearStartTimestamp} className="btn btn-sm btn-light ml-1">
                            <i className="fas fa-times" />
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="row no-gutters">
                      <ReactDatePicker
                        id="datePicker2"
                        selected={endTimestamp && new Date(endTimestamp)}
                        onChange={onChangeEndTimestamp}
                        className="form-control form-control-sm"
                        wrapperClassName="col"
                        placeholderText="End date"
                        popperPlacement="top-end"
                      />
                      {endTimestamp && (
                        <div className="col-auto">
                          <button type="button" onClick={onClearEndTimestamp} className="btn btn-sm btn-light ml-1">
                            <i className="fas fa-times" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="driverInput">Driver</label>
                    <DriverSelect
                      value={driverIdForQuery}
                      onChange={onChangeDriverIdForQuery}
                      className="form-control form-control-sm"
                    />
                  </div>

                  <div>
                    <label htmlFor="sortSelect">Sort by</label>
                    <select
                      name="sortSelect"
                      id="sortSelect"
                      value={orderBy}
                      onChange={onChangeSortBy}
                      className="custom-select custom-select-sm">
                      <option value="createdAt">Created first</option>
                      <option value="scheduleTimestamp">Pickup first</option>
                      <option
                        value="dropOffTimestampMs"
                        // disabled={startTimestamp || endTimestamp}
                      >
                        Drop-off first
                      </option>
                    </select>
                  </div>
                </div>
              </DropdownMenu>
            </div>
            {/* <div className="col-auto">
                        <button
                            id="activeDriversFilterButton"
                            type="button"
                            onClick={onToggleActiveOnly}
                            className="btn"
                            data-toggle="tooltip"
                            title={`Show ${filterActiveOnly ? 'All' : 'Active'} drivers`}
                        >
                            <i className={filterActiveOnly ? `fas fa-lightbulb text-yellow` : `fa fa-lightbulb`} />
                        </button>
                    </div> */}
          </div>
        </div>
      </div>

      <div ref={containerDiv} className="flex-grow-1">
        <TripsList
          containerDiv={containerDiv}
          filteredEntries={filteredEntries}
          selectedTripId={selectedTripId}
          onChangeSelectedTripId={onChangeSelectedTripId}
          timezone={timezone}
        />
      </div>
    </div>
  );
}
