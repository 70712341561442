import sortBy from 'lodash/sortBy';
import React, {useEffect, useState} from 'react';
import Card from '../../../components/Card';
import Spinner from '../../../components/Spinner';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';
import ScoopMApi from '../../../references/scoopm-api';

const states = {
  idle: 'IDLE',
  sending: 'SENDING',
  error: 'ERROR',
  sent: 'SENT',
};

export default function Notifications() {
  // state

  const [driversWithEmails, setDriversWithEmails] = useState(); // a `null` value indicates an error
  const [title, setTitle] = useState('Test message');
  const [body, setBody] = useState('This is the body of the notification');
  const [selectedDriverId, setSelectedDriverId] = useState();
  const [state, setState] = useState(states.idle);

  // hooks

  const drivers = useRealtimeDatabase({
    path: '/drivers',
    isArray: true,
    orderByChild: 'isTestingAccount',
    equalTo: true,
  });

  // effect: drivers.data --> set inital selected driver

  useEffect(() => {
    if (!selectedDriverId) {
      setSelectedDriverId(drivers.data ? drivers.data[0].key : null);
    }
  }, [drivers.data, selectedDriverId]);

  // effect: fetch driver's email if their name is ambiguous

  useEffect(() => {
    if (drivers.error) {
      setDriversWithEmails(null);
    } else if (drivers.data) {
      const driverIds = drivers.data.map(driver => driver.key);

      ScoopMApi.instance.users.emails
        .getOver100Safe(driverIds)
        .then(users =>
          sortBy(drivers.data, 'firstName').map(driver => ({
            ...driver,
            nameIsAmbiguous:
              drivers.data.filter(d => d.firstName === driver.firstName && d.lastName === driver.lastName).length > 1,
            email: users[driver.key].email,
          })),
        )
        .then(drivers => {
          setDriversWithEmails(drivers);
        })
        .catch(error => {
          console.error(error);
          setDriversWithEmails(null);
        });
    } else {
      setDriversWithEmails(undefined);
    }
  }, [drivers]);

  // view callbacks

  const onChangeTitle = event => {
    setTitle(event.target.value);
  };

  const onChangeBody = event => {
    setBody(event.target.value);
  };

  const onChangeDriver = event => {
    setSelectedDriverId(event.target.value);
  };

  const onSubmitForm = event => {
    event.preventDefault();
    send();
  };

  // functions

  const send = () => {
    const data = {
      messages: [
        {
          uid: selectedDriverId,
          userType: 'DRIVER',
          title,
          body,
          messageType: 'TeSt',
          link: null,
          threadId: null,
          data: {
            foo: 'BAR',
          },
        },
      ],
    };
    setState(states.sending);
    ScoopMApi.instance.messages.create(data).then(
      response => {
        setState(states.sent);
      },
      error => {
        setState(states.error);
        window.alert(`Error. ${error.message}`);
      },
    );
  };

  // render

  return (
    <Card className="d-inline-block" padded>
      <h4>Test notification</h4>

      <form onSubmit={onSubmitForm}>
        <div className="mb-3">
          <label htmlFor="titleInput">Title</label>
          <input
            id="titleInput"
            type="text"
            value={title}
            onChange={onChangeTitle}
            className="form-control"
            disabled={!driversWithEmails}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="bodyInput">Body</label>
          <input
            id="bodyInput"
            type="text"
            value={body}
            onChange={onChangeBody}
            className="form-control"
            disabled={!driversWithEmails}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="driversList">Recipient (testing accounts only)</label>
          {driversWithEmails === null ? (
            <div>There was an error fetching the list of drivers</div>
          ) : (
            <select
              id="driversList"
              defaultValue="NO_DRIVERS"
              onChange={onChangeDriver}
              className="custom-select"
              disabled={!driversWithEmails}>
              {driversWithEmails &&
                driversWithEmails.map(driver => (
                  <option key={driver.key} value={driver.key}>
                    {driver.firstName} {driver.lastName}
                    {driver.nameIsAmbiguous && ` (${driver.email})`}
                  </option>
                ))}
            </select>
          )}
          <small className="text-muted">{selectedDriverId}</small>
        </div>

        <div>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={!driversWithEmails || !selectedDriverId || !title || !body || state === states.sending}>
            {!driversWithEmails ? <Spinner small /> : state === states.sending ? <Spinner small /> : 'Send'}
          </button>
          {state === states.error && (
            <span className="text-danger ml-3">
              <i className="fas fa-exclamation" /> Error
            </span>
          )}
          {state === states.sent && (
            <span className="text-success ml-3">
              <i className="fas fa-check" /> Sent
            </span>
          )}
        </div>
      </form>
    </Card>
  );
}
