import firebase from 'firebase/app';
import querystringify from 'querystringify';
import React, {useMemo, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useAuthContext} from '../../contexts/AuthContext';
import unwrap from '../../functions/unwrap';
import AuthLayout from './AuthLayout';

export default function SignIn() {
  const history = useHistory();
  const location = useLocation();
  const {user} = useAuthContext();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [validation, setValidation] = useState();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const signUpLink = useMemo(
    () => unwrap(querystringify.parse(location.search).from, from => `/sign-up?from=${from}`, '/sign-up'),
    [location.search],
  );

  const forgotPasswordLink = useMemo(
    () =>
      unwrap(querystringify.parse(location.search).from, from => `/forgot-password?from=${from}`, '/forgot-password'),
    [location.search],
  );

  const onSubmit = event => {
    event.preventDefault();

    const validation = {
      email: Boolean(email),
      password: Boolean(password),
    };

    setValidation(validation);

    if (!validation.email || !validation.password) {
      return;
    }

    setIsSigningIn(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(
        user => {
          history.push(querystringify.parse(location.search).from || '/');
        },
        error => {
          setIsSigningIn(false);
          switch (error.code) {
            case 'auth/invalid-email':
              return setErrorMessage('Invalid email address');
            case 'auth/user-not-found':
              return setErrorMessage('No account with that email address has been created');
            case 'auth/wrong-password':
              return setErrorMessage('Incorrect username or password');
            default:
              window.alert('There was an error signing in. Please try again later');
          }
        },
      );
  };

  const onClickSignOut = event => {
    firebase
      .auth()
      .signOut()
      .catch(error => {
        console.error('Error signing out', error);
        window.alert('Unable to sign out at this time');
      });
  };

  if (user === undefined) {
    return null;
  }

  if (user) {
    return (
      <AuthLayout>
        <div className="text-center">
          <h4 className="mb-3">You're already signed in</h4>
          <button onClick={onClickSignOut} className="btn btn-outline-secondary mr-3">
            Sign out
          </button>
          <Link to={querystringify.parse(location.search).from || '/'} className="btn btn-primary">
            Continue
          </Link>
        </div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <form onSubmit={onSubmit}>
        <h3>Sign In</h3>

        <p>
          Don't have an account?{' '}
          <Link to={signUpLink} className="text-primary">
            Sign up
          </Link>
        </p>

        {errorMessage && <div className="alert alert-warning">{errorMessage}</div>}

        <div className="mb-3">
          <input
            type="text"
            value={email || ''}
            onChange={event => setEmail(event.target.value)}
            placeholder="Email"
            className={'form-control ' + (validation && !validation.email ? 'is-invalid' : '')}
            disabled={isSigningIn}
          />
          <div className="invalid-feedback">Required</div>
        </div>

        <div className="mb-3">
          <input
            type="password"
            value={password || ''}
            onChange={event => setPassword(event.target.value)}
            placeholder="Password"
            className={'form-control ' + (validation && !validation.password ? 'is-invalid' : '')}
            disabled={isSigningIn}
          />
          <div className="invalid-feedback">Required</div>
        </div>

        <div className="row">
          <div className="col d-flex align-items-center">
            <Link to={forgotPasswordLink}>Forgot password?</Link>
          </div>
          <div className="col-auto">
            <input type="submit" value="Sign in" className="btn btn-primary" disabled={isSigningIn} />
          </div>
        </div>
      </form>
    </AuthLayout>
  );
}
