import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import React, {useMemo} from 'react';
import {match as Match} from 'react-router-dom';
import Card from '../../../components/Card';
import Collapsable from '../../../components/Collapsable';
import GoogleMap from '../../../components/GoogleMaps/GoogleMap';
import {latLng} from '../../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../../components/GoogleMaps/MapContents';
import Marker from '../../../components/GoogleMaps/Marker';
import {DelaySpinner} from '../../../components/Spinner';
import unwrap from '../../../functions/unwrap';
import useTrip from '../../../hooks/useTrip';
import useTripEzCaterEvents from '../../../hooks/useTripEzCaterEvents';
import useTripEzCaterEventsOutgoing from '../../../hooks/useTripEzCaterEventsOutgoing';
import TripEvents from '../rides/TripEzCaterEvents';
import TripEzCaterEventsOutgoing from '../rides/TripEzCaterEventsOutgoing';

export default function EzCaterMap(props: {match: Match<{tripId: string; customerId: string}>}) {
  // trip data

  const {data, isLoading: tripIsLoading} = useTrip(props.match.params.tripId, props.match.params.customerId);

  // event data

  const {data: eventsData, isLoading: eventsAreLoading} = useTripEzCaterEvents(data);
  const {data: eventsOutgoingData, isLoading: eventsOutgoingAreLoading} = useTripEzCaterEventsOutgoing(data);

  // event objects

  const events = useMemo(() => unwrap(eventsData, data => filterCreatedEvents(arrayFromData(data))), [eventsData]);
  const eventsOutgoing = useMemo(
    () => unwrap(eventsOutgoingData, data => filterDriverTrackingEvents(arrayFromData(data))),
    [eventsOutgoingData],
  );

  // event markers

  const eventMarkers = useMemo(
    () =>
      flatMap(
        events?.map((event, i) => [
          new Marker({
            id: `PICKUP-${i}`,
            position: positionFromAddress(event.requestBody.pickupAddress),
            label: 'Pickup',
          } as any),
          new Marker({
            id: `DROP_OFF-${i}`,
            position: positionFromAddress(event.requestBody.dropoffAddress),
            label: 'Drop-off',
          } as any),
        ]),
      ),
    [events],
  );
  const eventsOutgoingMarkers = useMemo(
    () =>
      eventsOutgoing?.map(
        (event, i) =>
          new Marker({
            id: `DRIVER_TRACKING_${i}`,
            position: positionFromOutgoingEvent(event),
            label: String(i + 1),
            icon: {
              path: 'M-2,0a2,2 0 1,0 4,0a2,2 0 1,0 -4,0',
              fillColor: 'white',
              fillOpacity: 1,
              labelOrigin: new (window as any).google.maps.Point(0, 10),
            },
          } as any),
      ) ?? [],
    [eventsOutgoing],
  );

  // map properties

  const markers = useMemo(() => [...eventMarkers, ...eventsOutgoingMarkers], [eventMarkers, eventsOutgoingMarkers]);

  // const markers = [
  //     [-113, 34],
  //     [-114, 35],
  //     [-115, 36],
  //     [-115.2, 36.1],
  //     [-115.26, 36.17],
  //     [-115.261, 36.177],
  //     [-115.2617, 36.1775],
  //     [-115.26177, 36.17756],
  //     [-115.261776, 36.177567],
  //     [-115.2617769, 36.1775674],
  // ].map(([longitude, latitude], i) => new Marker({
  //     id: i,
  //     position: latLng([latitude, longitude]),
  //     label: `${latitude}, ${longitude}`,
  // } as any))

  const mapContents = useMemo(
    () =>
      MapContents.Bounds.forMarkers(markers, {
        id: 'MAP_CONTENTS',
        name: undefined,
        padded: true,
      }),
    [markers],
  );

  // render

  console.log({data});

  if (!data) {
    return <DelaySpinner />;
  } else if (!data.ezCaterDeliveryId) {
    return "This job doesn't contain an ezCater delivery ID";
  } else
    return (
      <div className="h-100 row d-flex">
        {/* map */}

        <div className="col-8">
          <GoogleMap markers={markers} mapContents={mapContents} gestureHandling="greedy" />
        </div>

        {/* event data */}

        <div className="h-100 col-4 overflow-scroll">
          {(tripIsLoading || eventsAreLoading || eventsOutgoingAreLoading) && <DelaySpinner />}

          <Card innerPadded={false} padded>
            <Collapsable title={<pre className="mb-0">HTTP Requests</pre>} defaultIsCollapsed>
              <TripEvents tripData={data} />
            </Collapsable>
          </Card>

          <Card innerPadded={false} padded>
            <Collapsable title={<pre className="mb-0">Outgoing HTTP Requests</pre>} defaultIsCollapsed>
              <TripEzCaterEventsOutgoing tripData={data} />
            </Collapsable>
          </Card>
        </div>
      </div>
    );
}

function arrayFromData(data: object) {
  return Object.entries(data).map(([id, event]: any) => ({id, ...event}));
}

function filterCreatedEvents(events?: any[]) {
  return filter(
    events,
    event =>
      event.requestHeaders?.['x-ezcater-event'] === 'delivery_created' &&
      event.requestBody?.pickupAddress?.latitude &&
      event.requestBody?.pickupAddress?.longitude &&
      event.requestBody?.dropoffAddress?.latitude &&
      event.requestBody?.dropoffAddress?.longitude,
  );
}

function filterDriverTrackingEvents(events?: any[]) {
  return filter(
    events,
    event =>
      event.requestBody.query ===
      `mutation createDriverTrackingEvent($input: CreateDriverTrackingEventInput!) { createDriverTrackingEvent(input: $input) { success } }`,
  );
}

function positionFromAddress(address: any) {
  return latLng([address.latitude, address.longitude]);
}

function positionFromOutgoingEvent(event: any) {
  return latLng([
    event.requestBody.variables.input.coordinate.latitude,
    event.requestBody.variables.input.coordinate.longitude,
  ]);
}
