import HttpReference from '../../platform/HttpReference';
import BillingPortalSessions from './BillingPortalSessions';
import Stripe from './Stripe';

export default class BillingPortal extends HttpReference<void, BillingPortalSessions, Stripe> {
  sessions: BillingPortalSessions;

  constructor(path: string, parent: Stripe) {
    super(path, parent);

    this.sessions = new BillingPortalSessions('sessions', this);
  }
}
