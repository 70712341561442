import moment from 'moment';
import React from 'react';
import Card from '../../../components/Card';
import Delay from '../../../components/Delay';
import RawData from '../../../components/RawData';
import Spinner from '../../../components/Spinner';
import formatCurrency from '../../../functions/formatCurrency';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';

export default function Quote({match}) {
  const quote = useRealtimeDatabase({
    path: `/quotes/${match.params.quoteId}`,
  });

  if (quote.isLoading)
    return (
      <Delay>
        <Spinner />
      </Delay>
    );

  if (!quote.data) return 'No data';

  return (
    <div className="p-2 p-md-3">
      <h3>Quote details</h3>

      <Card className="mb-3">
        <p>{moment(quote.data.creationTimestampMs).calendar()}</p>

        <h6>{formatCurrency(quote.data.fee)}</h6>
      </Card>

      {/* <Card className="mb-3">
                <RawData title="Other data" data={quote.data} keysToHide={['requestBody', 'error']} />
            </Card>

            {quote.data.error && (

                <Card className="mb-3">

                    <h5 className="text-danger"><i className="fas fa-exclamation-triangle mr-2" />Error</h5>

                    <h6>
                        {quote.data.error.message}
                    </h6>

                    <table className="table table-sm table-striped table-borderless border-bottom table-hover mb-0">
                        <tbody>
                            {Object.values(quote.data.error.stackTrace).map((trace, i) => (
                                <tr key={i}>
                                    <td>{i}</td>
                                    <td>{trace}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </Card>

            )} */}

      <Card innerPadded={false}>
        <RawData data={quote.data} />
      </Card>
    </div>
  );
}
