import React from 'react';
import {useContext, useEffect} from 'react';

interface TitleContextInterface {
  title?: string;
  setTitle: (title?: string) => void;
}

const TitleContext = React.createContext<TitleContextInterface>({} as TitleContextInterface);

export default TitleContext;

export function useTitle(title: string) {
  const context = useContext(TitleContext);

  useEffect(() => {
    context.setTitle(title);

    return () => context.setTitle();
  }, [title, context]);
}
