import React from 'react';
import ClaimsGuard from '../../guards/ClaimsGuard';
import CustomClaim from '../../models/scoopm/CustomClaim';
import PropertyType from '../types/PropertyType';
import LocationMap from './LocationMap';

export default function LocationDisplay({schema, property, value}) {
  return (
    <div>
      <div>
        {PropertyType.format(property.type, value)}
        <ClaimsGuard claim={CustomClaim.manageApi}>
          {' '}
          <small className="text-muted">
            (Geohash <pre className="d-inline bg-light p-1">{value.geohash}</pre>)
          </small>
        </ClaimsGuard>
      </div>
      <LocationMap location={value} />
    </div>
  );
}
