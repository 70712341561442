import React from 'react';
import useTrip from '../../hooks/useTrip';
import ContainerLayout from '../../layouts/ContainerLayout';
import {ButtonsRow} from './ButtonsRow';
import {DriverRow} from './DriverRow';
import {LocationsRow} from './LocationsRow';
import {PaymentRow} from './PaymentRow';
import {TitleRow} from './TitleRow';
import {VehicleRow} from './VehicleRow';

export default function Trip({match}) {
  const {tripId, customerId} = match.params;

  const {data: model} = useTrip(tripId, customerId);

  return (
    <ContainerLayout>
      <div className="mb-5">
        <TitleRow model={model} />
        <VehicleRow trip={model} />
        <DriverRow trip={model} />
        <LocationsRow model={model} />
        <PaymentRow model={model} />
        <ButtonsRow tripId={tripId} model={model} />
      </div>
    </ContainerLayout>
  );
}
