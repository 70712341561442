import React, {useContext, useState} from 'react';
import {Container} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import Alert from '../../components/Alert/Alert';
import {useAlert} from '../../contexts/AlertContext';
import {useAuthContext} from '../../contexts/AuthContext';
import VendorApplication from '../../models/scoopm/VendorApplication';
import SchemaForm from '../../schemas/components/SchemaForm';
import vendorApplicationSchema from '../../schemas/wavem/VendorApplication';

export default function Create() {
  const {uid} = useAuthContext();
  const {setAlert} = useAlert();
  const [isDone, setIsDone] = useState(false);

  async function onSave(model: VendorApplication) {
    await VendorApplication.create(model);

    setAlert(
      new Alert('Submitted', 'Your application has been submitted. We will be in touch shortly.', undefined, async () =>
        setIsDone(true),
      ),
    );
  }

  if (isDone) return <Redirect to="/" />;

  if (uid === undefined) return null; // loading

  return (
    <Container className="py-4">
      <h1>Join our delivery partner network</h1>
      <p className="lead">
        Looking for a delivery partner? Let us know about your delivery needs, and we will be in touch soon.
      </p>
      <SchemaForm
        schema={vendorApplicationSchema}
        onSave={onSave}
        defaultValue={undefined}
        onCancel={undefined}
        isCustomerFacing
        saveButtonText="Submit"
      />
    </Container>
  );
}
