import firebase from 'firebase/app';
import first from 'lodash/first';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useAdditionalNavbarContent} from '../../contexts/AdditionalNavbarContentContext';
import {useAlert} from '../../contexts/AlertContext';
import cleanForFirebase from '../../functions/cleanForFirebase';
import joinClassNames from '../../functions/joinClassNames';
import {simluateDownloadClickCsv} from '../../functions/simulateDownloadClick';
import unwrap from '../../functions/unwrap';
import useFilterCities from '../../hooks/useFilterCities';
import useUserGroups from '../../hooks/useUserGroups';
import BootstrapModalContext from '../../routes/BootstrapModalContext';
import CSVHelper from '../../services/CSVHelper';
import Alert from '../Alert/Alert';
import Card from '../Card';
import FirestoreTable from '../FirestoreTable';
import FilterMetadata from '../FirestoreTable/FilterMetadata';
import ColumnMetadata from '../Table/ColumnMetadata';
import SortingDirection from '../Table/SortingDirection';
import {ViewInFirebaseLink} from '../ViewInFirebase';
import FiltersComponent from './FiltersComponent';

export default function FirestoreQueryTable({queryId}) {
  const {setModalContent} = useContext(BootstrapModalContext);

  const history = useHistory();
  const {groups} = useUserGroups();
  const {isLoading, cities} = useFilterCities();
  const [queryEntry, setQueryEntry] = useState();
  const [error, setError] = useState();
  const [columns, setColumns] = useState();
  const [filters, setFilters] = useState();
  const [isPending, setIsPending] = useState(false);
  const [rows, setRows] = useState();
  const [search, setSearch] = useState();
  const [isFetching, setIsFetching] = useState(false);

  // EFFECT: get data

  useEffect(() => {
    return firebase
      .firestore()
      .collection('queries')
      .doc(queryId)
      .onSnapshot(
        snapshot => {
          setError(null);
          if (!snapshot.exists) return;
          setQueryEntry([snapshot.id, snapshot.data()]);
        },
        error => {
          console.error('Error getting firestore query object', error);
          setError(error);
        },
      );
  }, [queryId, history]);

  // EFFECT: query selected columns --> columns, filters

  useEffect(() => {
    if (!queryEntry) return;
    const all = ColumnMetadata.all();
    setColumns(queryEntry[1].visibleColumns.map(path => all.find(ac => ac.path === path)).filter(Boolean));
    setFilters(
      FilterMetadata.forTrips(groups).map(filter => {
        const copy = {...filter};
        unwrap(queryEntry[1].filterValues[copy.field], value => {
          copy.value = value;
        });
        return copy;
      }),
    );
  }, [queryEntry, groups]);

  // METHOD

  const updateQuery = useCallback(
    update => {
      setIsPending(true);
      return firebase
        .firestore()
        .collection('queries')
        .doc(queryEntry[0])
        .update(cleanForFirebase(update))
        .then(
          snapshot => {
            //
          },
          error => {
            console.error('Error updating query snapshot', error);
            window.alert(`Unable to update settings. ${error.message}`);
          },
        )
        .finally(() => {
          setIsPending(false);
        });
    },
    [queryEntry],
  );

  const {setAlert} = useAlert();

  const onDeleteQuery = useCallback(() => {
    const alert = new Alert(
      'Delete this bucket',
      undefined,
      'Delete',
      async () => {
        setIsPending(true);
        setModalContent(null);

        await firebase.firestore().collection('queries').doc(queryEntry[0]).delete();

        const snapshot = await firebase
          .firestore()
          .collection('queries')
          .where('uid', '==', firebase.auth().currentUser.uid)
          .orderBy('timestamp', 'asc')
          .limit(1)
          .get();

        setIsPending(false);

        history.push(snapshot.empty ? `/admin` : `/admin/jobs/${snapshot.docs[0].data().path}`);
      },
      'Cancel',
      undefined,
      error => {
        if (error) {
          setAlert(new Alert('Error deleting bucket', error.message));
        }
      },
    );
    setAlert(alert);
  }, [history, queryEntry, setModalContent, setAlert]);

  const onChangeSortingColumn = useCallback(sortingColumn => updateQuery({sortingColumn}), [updateQuery]);

  const onChangeSortingDirection = useCallback(sortingDirection => updateQuery({sortingDirection}), [updateQuery]);

  const link = useCallback((id, data) => `/admin/jobs/${data.customerId}/${id}`, []);

  const queryName = unwrap(queryEntry, ([id, query]) => query.name);
  const onClickDownloadCsv = useCallback(
    event => {
      const string = CSVHelper.stringFromTableRows(columns, rows);
      const dataTypeName = 'jobs';
      const bucketName = unwrap(queryName, name => name.toLowerCase().replaceAll(' ', '-'), 'jobs');
      simluateDownloadClickCsv(string, null, dataTypeName, bucketName);
    },
    [rows, columns, queryName],
  );

  const showSettingsModal = useCallback(
    event => {
      setModalContent(
        <>
          <div className="modal-header">
            <h5 className="modal-title">
              <span id="rootModalLabel">Bucket settings</span>
              {isPending && <span className="text-muted ml-4 font-weight-normal">Saving...</span>}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <FiltersComponent queryId={queryId} updateQuery={updateQuery} onClickDownloadCsv={onClickDownloadCsv} />
          </div>

          <div className="modal-footer">
            <div className="row flex-grow-1">
              <div className="col d-flex align-items-center">
                <ViewInFirebaseLink path={`/queries/${queryEntry[0]}`} className="text-gray-500" firestore>
                  View in Firebase
                </ViewInFirebaseLink>
              </div>

              <div className="col-auto">
                <button onClick={onDeleteQuery} className="btn btn btn-outline-danger mr-2">
                  Delete bucket...
                </button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal" disabled={isPending}>
                  Done
                </button>
              </div>
            </div>
          </div>
        </>,
      );
    },
    [isPending, onClickDownloadCsv, onDeleteQuery, queryEntry, queryId, setModalContent, updateQuery],
  );

  const fetchingOrCount = useMemo(
    () =>
      isFetching || !queryEntry ? (
        <i className="fas fa-circle-notch fa-spin fa-md" />
      ) : (
        rows &&
        `${rows.length} ${rows.length === 1 ? 'item' : 'items'}${
          rows.length === (queryEntry[1].limit || 500) ? ' (limit)' : ''
        }`
      ),
    [isFetching, rows, queryEntry],
  );

  const additionalFilter = useCallback(
    isLoading || !cities
      ? undefined
      : row =>
          unwrap(row.rawValue, rawValue =>
            unwrap(rawValue.locations, locations =>
              unwrap(first(locations), location => cities.find(city => city.contains(location.coordinates))),
            ),
          ),
    [isLoading, cities],
  );

  useAdditionalNavbarContent(
    useMemo(
      () => (
        <>
          <div className="col" />
          <Column>{fetchingOrCount}</Column>
          <Column>
            <input
              type="search"
              value={search || ''}
              onChange={event => setSearch(event.target.value)}
              placeholder="Search"
              className="form-control"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
            />
          </Column>
          {filters && (
            <Column>
              <button type="button" onClick={showSettingsModal} className="btn bg-white">
                <i className="fas fa-sliders-h" />
                <span className="d-none d-md-inline"> Settings</span>
              </button>
            </Column>
          )}
        </>
      ),
      [fetchingOrCount, filters, search, showSettingsModal],
    ),
  );

  // RENDER

  // error?

  if (error) {
    return <Card padded>Error. {error.message}</Card>;
  }

  // wait for filters

  if (!queryEntry || !filters) return null;

  // ready

  return (
    <div className="h-100 d-flex flex-column">
      <FirestoreTable
        collection="trips"
        columns={columns}
        sortingColumn={queryEntry[1].sortingColumn}
        onChangeSortingColumn={onChangeSortingColumn}
        sortingDirection={SortingDirection.from(queryEntry[1].sortingDirection)}
        onChangeSortingDirection={onChangeSortingDirection}
        filters={filters}
        limit={queryEntry[1].limit}
        link={link}
        onChangeRows={setRows}
        search={search}
        onChangeIsFetching={setIsFetching}
        additionalFilter={additionalFilter}
      />
    </div>
  );
}

function Column({className, children}) {
  return <div className={joinClassNames('col-auto d-flex align-items-center px-2', className)}>{children}</div>;
}
