import {useCallback, useEffect, useState} from 'react';
import useFirestoreListener from './useFirestoreListener';

const useFirestoreListenerWithJoin = ({reference, callback}) => {
  const [additionalDocs, setAdditionalDocs] = useState(null);

  const {snapshot, ...props} = useFirestoreListener(reference);

  useEffect(() => {
    if (!snapshot) {
      setAdditionalDocs(null);
    } else {
      const promises = snapshot.docs.map(callback);
      Promise.all(promises).then(
        additionalDocsArray =>
          setAdditionalDocs(
            Object.assign(
              {},
              ...additionalDocsArray.map((additionalDoc, i) => ({
                [snapshot.docs[i].id]: additionalDoc,
              })),
            ),
          ),
        error => {
          console.error('Error getting additional doc', error);
        },
      );
    }
  }, [snapshot, callback]);

  const getAdditionalDoc = useCallback(doc => (!additionalDocs ? null : additionalDocs[doc.id]), [additionalDocs]);

  return {snapshot, getAdditionalDoc, ...props};
};

export default useFirestoreListenerWithJoin;
