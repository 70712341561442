import {useEffect, useState} from 'react';
import log from '../functions/log';
import {createModel} from './useFirestoreGet';

/**
 * Used for observing Firestore references.
 * The property `isLoading` is `true` by default.
 * You can disable the listener by passing a falsy `reference`.
 */
const useFirestoreListener = (reference, modelType) => {
  const [state, setState] = useState(new State(true, null, null, null, reference));

  useEffect(() => {
    if (reference) {
      log.i(`Observing snapshots at "${reference.path}"...`);
      return reference.onSnapshot(
        snapshot => {
          log.i(`Observed snapshot at "${reference.path}"`);
          const model = createModel(modelType, snapshot);
          setState(new State(false, snapshot, model, null, reference));
        },
        error => {
          log.e(`Error observing snapshot at "${reference.path}"`, error);
          setState(new State(false, null, null, error, reference));
        },
      );
    } else {
      log.i(`Unsetting reference`);
      setState(new State(false, null, null, null, reference));
    }
  }, [reference, modelType]);

  return state;
};

class State {
  constructor(isLoading, snapshot, model, error, reference) {
    this.isLoading = isLoading;
    this.snapshot = snapshot;
    this.model = model;
    this.error = error;
    this.reference = reference;
  }
}

export default useFirestoreListener;
