import React, {useCallback, useEffect, useRef, useState} from 'react';
import joinClassNames from '../../functions/joinClassNames';
import unwrap from '../../functions/unwrap';
import usePrevious from '../../hooks/usePrevious';
import FontAwesome from '../FontAwesome';
import Map from './Map';
import './Map.css';
import MapContents from './MapContents';

export default function MapWithTracking({onMap, mapContents, center, zoom, rounded, ...props}) {
  const previousMapContents = usePrevious(mapContents);
  const isChangingZoomProgrammatically = useRef(false);
  const [map, setMap] = useState();
  const [isTrackingMapContents, setIsTrackingMapContents] = useState(true);
  const [zoomInternal, setZoomInternal] = useState();
  const [centerInternal, setCenterInternal] = useState();

  // effect: set zoom/center or fit bounds, based on props

  useEffect(() => {
    if (!map) return;
    if (isTrackingMapContents) {
      isChangingZoomProgrammatically.current = true;
      if (mapContents instanceof MapContents.Bounds) {
        map.fitBounds(mapContents.latLngBounds, 65);
      } else if (mapContents instanceof MapContents.Coordinates) {
        setZoomInternal(mapContents.zoom);
        setCenterInternal(mapContents.latLng);
      } else {
        setZoomInternal(zoom);
        setCenterInternal(center);
      }
      setTimeout(() => {
        isChangingZoomProgrammatically.current = false;
      }, 1000);
    } else {
      setZoomInternal(null);
      setCenterInternal(null);
    }
  }, [isTrackingMapContents, mapContents, center, zoom, map]);

  // effect: if map contents id changes, start tracking

  useEffect(() => {
    if (unwrap(mapContents, contents => contents.id) !== unwrap(previousMapContents, contents => contents.id)) {
      setIsTrackingMapContents(true);
    }
  }, [mapContents, previousMapContents]);

  // methods

  const turnOffTracking = useCallback(() => {
    setIsTrackingMapContents(false);
  }, [setIsTrackingMapContents]);

  const turnOnTracking = useCallback(event => {
    setIsTrackingMapContents(true);
  }, []);

  const onZoomChanged = () => {
    if (!isChangingZoomProgrammatically.current) {
      turnOffTracking();
    }
  };

  // render

  return (
    <div className={joinClassNames('w-100 h-100 position-relative', rounded && 'rounded-xl border')}>
      <Map
        containerElement={<div className={joinClassNames('w-100 h-100', rounded && 'rounded-xl')} />}
        mapElement={<div className={joinClassNames('w-100 h-100', rounded && 'rounded-xl')} />}
        onMap={map => {
          setMap(map);
          onMap && onMap(map);
        }}
        // onClick={onClick}
        onZoomChanged={onZoomChanged}
        onDrag={turnOffTracking}
        onClickMarkerClusterer={turnOffTracking}
        zoom={zoomInternal}
        center={centerInternal}
        useDefaultZoomAndCenter={false}
        {...props}
      />
      {!isTrackingMapContents && mapContents && (
        <button onClick={turnOnTracking} className="track-map-contents-button">
          <FontAwesome.CrosshairsSolid /> Re-center {mapContents.name || null}
        </button>
      )}
    </div>
  );
}
