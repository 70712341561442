import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {
  filterBoolean,
  formatPhone,
  formatPhoneE164,
  Join,
  locationDescriptionSimple,
  PositionInList,
  TripContact,
} from 'wave-common';
import MomentHelper from 'wave-common/lib/helpers/MomentHelper';
import {tripLocationsWithDefault, TripOfNumber, tripTitleShortest} from 'wave-common/lib/models/Trip';
import {tripContentAsString} from 'wave-common/lib/models/TripContent';
import TripLocation from 'wave-common/lib/models/TripLocation';
import {FontAwesomeV5} from '../../../../components/FontAwesome';
import {SeparatorSm} from '../../../../components/Separators';
import Trip from '../../../../models/scoopm/Trip';

export default function TripDetails({trip, unsetSelectedTrip}: {trip: TripOfNumber; unsetSelectedTrip: () => void}) {
  const contacts = useMemo(() => Trip.prototype.contactsWithDefault.apply(trip) as TripContact[], [trip]);
  return (
    <div style={{width: '24rem'}}>
      <div className="row align-items-end mb-2">
        <div className="col">
          <h3 className="mb-0">{tripTitleShortest(trip)}</h3>
        </div>
        <div className="col-auto">
          <button type="button" onClick={unsetSelectedTrip} className="btn">
            <FontAwesomeV5 name="close" />
          </button>
        </div>
      </div>
      <Join react={React as never} separator={ArrowSeparator}>
        {tripLocationsWithDefault(trip).map((location, i, locations) => (
          <Location
            key={i}
            location={location}
            contact={contacts.find(contact => contact.userType === location.contactUserType)}
            // position={positionInListFor(i, locations)!}
          />
        ))}
      </Join>
    </div>
  );
}

function Location({
  location,
  contact,
  position,
}: {
  location: TripLocation;
  contact: TripContact | undefined;
  position?: PositionInList;
}) {
  return (
    <div>
      {/* <div className="lead">{positionInListLocationName(position)}</div> */}
      <div className="bg-light rounded p-2 p-md-3">
        <Join react={React as never} separator={SeparatorSm}>
          {filterBoolean([
            <div>
              <h5 className="mb-0">{locationDescriptionSimple(location)}</h5>
              {location.name && <div>{location.address}</div>}
              {contact?.uid && (
                <div>
                  <Link to={`/admin/users/${contact.uid}`}>{`${contact.name ?? 'User'}`}</Link>
                </div>
              )}
              {contact?.phone && (
                <div
                //  className="font-weight-bold"
                >
                  <a href={`"tel:+${formatPhoneE164(contact.phone)}"`} className="text-decoration-underline">
                    {formatPhone(contact.phone)}
                  </a>
                </div>
              )}

              {location.waitTimeS && <div>{MomentHelper.daysHoursMinutesFromSeconds(location.waitTimeS)}</div>}
              {location.zoneName && <div>{location.zoneName}</div>}
              {location.destinationName && <div>{location.destinationName}</div>}
              {/* { <small className="text-muted">[{location.coordinates.join(', ')}]</small>} */}
            </div>,
            location.instructions && <div className="white-space-pre-wrap">{location.instructions}</div>,
            location.contents && (
              <div>
                {location.contents.map((content, i) => (
                  <div key={i}>{tripContentAsString(content)}</div>
                ))}
              </div>
            ),
          ])}
        </Join>
      </div>
    </div>
  );
}

function ArrowSeparator() {
  return (
    <div className="my-2 d-flex justify-content-center">
      <FontAwesomeV5 name="arrow-down" />
    </div>
  );
}
