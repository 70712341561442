import React from 'react';
import {Redirect} from 'react-router-dom';
import Card from '../components/Card';
import Delay from '../components/Delay';
import Spinner from '../components/Spinner';
import {useAuthContext} from '../contexts/AuthContext';

export default function ClaimsGuard({
  claim,
  claims,
  redirect,
  showMessage = false,
  children,
}: {
  claim?: string;
  claims?: string[];
  redirect?: string;
  showMessage?: boolean;
  children: any;
}) {
  const auth = useAuthContext();
  const userClaims = auth.claims;
  const authError = auth.error;

  // no claims specified?

  if (!claim && !claims) {
    console.info('No custom claim specified, ignoring');
    return children;
  }

  // loading?

  if (userClaims === undefined) {
    return (
      <Delay>
        <Spinner />
      </Delay>
    );
  }

  // error?

  if (authError) {
    return 'Unable to verify access';
  }

  // insufficient permissions?

  if (!userClaims) {
    return <FailureComponent redirect={redirect} showMessage={showMessage} />;
  } else if (claim) {
    if (!userClaims[claim]) {
      return <FailureComponent redirect={redirect} showMessage={showMessage} />;
    }
  } else if (claims) {
    if (claims.find(claim => !userClaims[claim])) {
      return <FailureComponent redirect={redirect} showMessage={showMessage} />;
    }
  }

  // passed checks

  return children ?? null;
}

function FailureComponent({redirect, showMessage}: {redirect?: string; showMessage: boolean}) {
  if (redirect) {
    return <Redirect to={redirect} />;
  } else if (showMessage) {
    return <Card padded>Not authorized</Card>;
  } else {
    return null;
  }
}
