import Model from '../../../models/fountain/Applicant';
import HttpReference from '../../platform/HttpReference';
import Applicants from './Applicants';

export default class Applicant extends HttpReference<Model, void, Applicants> {
  // async headers(): Promise<Record<string, string> | null> {
  //   return null;
  // }

  get() {
    return this.request({method: 'get'});
  }
}
