"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transportTypeOrder = exports.transportTypeTitle = exports.transportTypeHasFuelImage = exports.transportTypeDescription = exports.transportTypeAll = void 0;
const Enum_1 = require("../type-descriptions/Enum");
exports.transportTypeAll = ['WALKING', 'BICYCLE', 'CYCLE_WITH_CARGO', 'CAR', 'VAN'];
exports.transportTypeDescription = (0, Enum_1.enumDescription)({
    values: exports.transportTypeAll,
    userFacingTitle: 'transport type',
    userFacingValue: transportTypeTitle,
    order: transportTypeOrder,
});
function transportTypeHasFuelImage(type) {
    switch (type) {
        case 'BICYCLE':
        case 'WALKING':
        case 'CYCLE_WITH_CARGO':
            return false;
        default:
            return true;
    }
}
exports.transportTypeHasFuelImage = transportTypeHasFuelImage;
function transportTypeTitle(type) {
    return transportTypeTitles[type];
}
exports.transportTypeTitle = transportTypeTitle;
const transportTypeTitles = {
    WALKING: 'Walking',
    BICYCLE: 'Bicycle',
    CYCLE_WITH_CARGO: 'Cycle with cargo',
    CAR: 'Car',
    VAN: 'Van',
};
function transportTypeOrder(type) {
    return exports.transportTypeAll.indexOf(type);
}
exports.transportTypeOrder = transportTypeOrder;
