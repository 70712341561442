import firebase from 'firebase/app';
import sortBy from 'lodash/sortBy';
import {useCallback, useEffect, useState} from 'react';
import log from '../functions/log';
import Type from '../functions/Type';
import FirestoreModel from '../models/base/FirestoreModel';
import FirestoreQueryState from './FirestoreQueryState';
import {createModel} from './useFirestoreGet';
import useInterval from './useInterval';

/**
 * Used for getting Firestore querys.
 * The property `isLoading` is `true` by default.
 * You can disable this hook by passing a falsy `query`.
 */
export default function useFirestoreQueryGet<Model extends FirestoreModel>(
  query?: firebase.firestore.Query,
  modelType?: Type<Model>,
) {
  const [state, setState] = useState<FirestoreQueryState<Model>>({
    isLoading: true,
  });

  useEffect(() => {
    handleQuery(setState, query, modelType);
  }, [query, modelType]);

  return state;
}

function handleQuery<Model extends FirestoreModel>(
  setState: React.Dispatch<React.SetStateAction<FirestoreQueryState<Model>>>,
  query?: firebase.firestore.Query,
  modelType?: Type<Model>,
  resetOnRefresh = true,
) {
  // console.log('query', query)

  if (query) {
    setState(oldState => (resetOnRefresh ? {isLoading: true, query} : Object.assign({}, oldState, {isLoading: true})));

    const path = (query as any).path || 'query';
    log.i(`Getting "${path}"...`);
    query.get().then(
      snapshot => {
        log.i(`Got "${path}"`);
        let models = snapshot.docs.map(doc => createModel(modelType, doc)).filter(Boolean) as Model[];
        if (models[0] && (models[0] as any).name) {
          models = sortBy(models, 'name') as Model[];
        }
        setState({isLoading: false, snapshot, models, query});
      },
      error => {
        log.e(`Error getting "${path}"`, error);
        setState({isLoading: false, error, query});
      },
    );
  } else {
    setState(oldState => {
      log.i(`Unsetting ${oldState.query ? (oldState.query as any).path || 'query' : 'query'}`);
      return {isLoading: true};
    });
  }
}

export function useFirestoreQueryGetInterval<Model extends FirestoreModel>(
  query?: firebase.firestore.Query,
  modelType?: Type<Model>,
  interval: number = 10_000,
) {
  const [state, setState] = useState<FirestoreQueryState<Model>>({
    isLoading: true,
  });

  useInterval(
    useCallback(() => {
      handleQuery(setState, query, modelType, false);
    }, [query, modelType]),
    interval,
  );

  return state;
}
