import Trip from '../../../models/scoopm/Trip';
import HttpReference from '../../platform/HttpReference';
import PaymentIntents from './PaymentIntents';

export default class PaymentIntent extends HttpReference<any, void, PaymentIntents> {
  update(trip: Trip) {
    return this.request({
      method: 'PATCH',
      data: trip,
    });
  }
}
