import ScoopMApi from '.';
import HttpReference from '../platform/HttpReference';
import UserGroupInvite from './UserGroupInvite';

export default class UserGroupInvites extends HttpReference<void, UserGroupInvite, ScoopMApi> {
  get childReferenceType() {
    return UserGroupInvite;
  }

  create(email: string, groupId: string) {
    return this.request({
      method: 'POST',
      data: {
        email,
        groupId,
      },
    });
  }
}
