import React, {useMemo} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import filterBoolean from '../../functions/filterBoolean';
import UserGuard from '../../guards/UserGuard';
import useUserGroups from '../../hooks/useUserGroups';
import useUserGroupVendor from '../../hooks/useUserGroupVendor';
import TabbedLayout from '../../layouts/TabbedLayout';
import EmailPassword from './EmailPassword';
import Name from './Name';
import Subscription from './Subscription';
import Team from './Team';

export default function Profile() {
  const {groups} = useUserGroups();
  const {model: vendor} = useUserGroupVendor();

  // show the tabs while data loads. Othwerise the user could be redirected away from a tab that is still loading

  const shouldShowGroups = Boolean(groups?.length);
  const shouldShowSubscription = Boolean(vendor?.stripeCustomerId);

  const names = useMemo(
    () =>
      filterBoolean(['Name', shouldShowSubscription && 'Subscription', shouldShowGroups && 'Team', 'Email/password']),
    [shouldShowSubscription, shouldShowGroups],
  );

  return (
    <UserGuard to={'/' as any}>
      <TabbedLayout heading="Profile" rootPath="/profile" displayNames={names}>
        <Switch>
          <Route path="/profile/name" component={Name} />
          {shouldShowSubscription && <Route path="/profile/subscription" component={Subscription} />}
          {shouldShowGroups && <Route path="/profile/team" component={Team} />}
          <Route path="/profile/email-password" component={EmailPassword} />
          <Route component={() => <Redirect to="/profile/name" />} />
        </Switch>
      </TabbedLayout>
    </UserGuard>
  );
}
