import React, {useMemo} from 'react';
import {unwrap, useStatus} from 'wave-common';
import {vehicleTrimListForYear_Make_Model} from 'wave-common/lib/controllers/VehicleTrim';
import FirestoreDataSource from '../../data-sources/FirestoreDataSource';
import joinClassNames from '../../functions/joinClassNames';
import InvalidFeedback from './InvalidFeedback';
import PropertyInputProps from './PropertyInputProps';

export default function VehicleTrimInput({
  id,
  property,
  value,
  model,
  onChange,
  validationError,
  disabled,
}: PropertyInputProps) {
  const {
    isPending,
    error,
    value: trims,
  } = useStatus(
    React as any,
    useMemo(
      () =>
        unwrap(model.year, year =>
          unwrap(model.make, make =>
            unwrap(model.model, model => vehicleTrimListForYear_Make_Model(year, make, model, FirestoreDataSource)),
          ),
        ),
      [model.year, model.make, model.model],
    ),
  );

  return (
    <>
      <select
        name={id}
        id={id}
        value={value || ''}
        onChange={event => onChange(event.target.value)}
        className={joinClassNames(
          'custom-select',
          unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid')),
        )}
        disabled={Boolean(isPending || error || !model.model || disabled)}>
        <option value="">{!model.model ? 'Select model' : property.isOptional ? 'None' : 'Select...'}</option>
        {trims?.map(trim => (
          <option key={trim.id} value={trim.id}>
            {trim.name}
          </option>
        ))}
      </select>
      <InvalidFeedback error={validationError} />
    </>
  );
}
