import firebase from 'firebase/app';
import kebabCase from 'lodash/kebabCase';
import round from 'lodash/round';
import moment from 'moment';
import {object} from 'prop-types';
import {baseUserFullName, deserializeNumber, deserializeString} from 'wave-common';
import {driverRead} from 'wave-common/lib/controllers/Driver';
import {destinationDescription, destinationDeserialize} from 'wave-common/lib/models/Destination';
import {polygonDeserialize} from 'wave-common/lib/models/Polygon';
import {zoneDeserialize} from 'wave-common/lib/models/Zone';
import ColumnMetadata from '../../components/Table/ColumnMetadata';
import RealtimeDatabaseDataSource from '../../data-sources/RealtimeDatabaseDataSource';
import {formatPhone, formatPhoneE164} from '../../functions/formatPhone';
import unhandledCase from '../../functions/unhandledCase';
import unwrap from '../../functions/unwrap';
import StringEnum from '../../models/base/StringEnum';
import City from '../../models/scoopm/City';
import DeliveryCountEstimate from '../../models/scoopm/DeliveryCountEstimate';
import DeviceType from '../../models/scoopm/DeviceType';
import Location from '../../models/scoopm/Location';
import PlaceCountEstimate from '../../models/scoopm/PlaceCountEstimate';
import PricingFormulaItem from '../../models/scoopm/PricingFormulaItem';
import TransportType from '../../models/scoopm/TransportType';
import {TripSource} from '../../models/scoopm/TripSource';
import TripType from '../../models/scoopm/TripType';
import UserType from '../../models/scoopm/UserType';
import Channel from '../../models/slack/Channel';
import BooleanInput from '../components/BooleanInput';
import DestinationInput from '../components/DestinationInput';
import EnumInput from '../components/EnumInput';
import FirestoreTimestampInput from '../components/FirestoreTimestampInput';
import GeoCircleDisplay from '../components/GeoCircleDisplay';
import GeoCircleInput from '../components/GeoCircleInput';
import LocationDisplay from '../components/LocationDisplay';
import LocationInput from '../components/LocationInput';
import NumberInput from '../components/NumberInput';
import ObjectDisplay from '../components/ObjectDisplay';
import ObjectForm from '../components/ObjectInput';
import PolygonsDisplay from '../components/PolygonsDisplay';
import PolygonsInput from '../components/PolygonsInput';
import PricingFormulaItemDisplay from '../components/PricingFormulaItemDisplay';
import PricingFormulaItemInput from '../components/PricingFormulaItemInput';
import StringInput, {NameInput} from '../components/StringInput';
import StringLongInput from '../components/StringLongInput';
import StripeCustomerDisplay from '../components/StripeCustomerDisplay';
import StripePriceDisplay from '../components/StripePriceDisplay';
import StripePriceInput from '../components/StripePriceInput';
import StripeSubscriptionDisplay from '../components/StripeSubscriptionDisplay';
import TimestampInput from '../components/TimestampInput';
import TripLocationDisplay from '../components/TripLocationDisplay';
import TripLocationInput from '../components/TripLocationInput';
import UidDisplay from '../components/UidDisplay';
import UidInput from '../components/UidInput';
import VehicleMakeInput from '../components/VehicleMakeInput';
import VehicleModelInput from '../components/VehicleModelInput';
import VehicleTrimDisplay from '../components/VehicleTrimDisplay';
import VehicleTrimInput from '../components/VehicleTrimInput';
import VehicleYearInput from '../components/VehicleYearInput';
import ZonesDisplay from '../components/ZonesDisplay';
import ZonesInput from '../components/ZonesInput';
import {EnumWithTitleClass} from '../../models/base/EnumWithTitle';
import {PhoneInput} from './../components/StringInput';
import Property from './Property';

export default class PropertyType extends StringEnum {
  static boolean = 'BOOLEAN';
  static city = 'CITY';
  static timestamp = 'TIMESTAMP';
  static firestoreTimestamp = 'FIRESTORE_TIMESTAMP';
  static deliveryCountEstimate = 'DELIVERY_COUNT_ESTIMATE';
  static destination = 'DESTINATION';
  static deviceType = 'DEVICE_TYPE';
  static firestoreId = 'FIRESTORE_ID';
  static location = 'LOCATION';
  static tripLocation = 'TRIP_LOCATION';
  static fullName = 'FULL_NAME';
  static jobsTableColumn = 'JOBS_TABLE_COLUMN';
  static number = 'NUMBER';
  static pricingFormulaItem = 'PRICING_ITEM';
  static placeCountEstimate = 'PLACE_COUNT_ESTIMATE';
  static phone = 'PHONE';
  static polygon = 'POLYGON';
  static realtimeDatabaseId = 'REALTIME_DATABASE_ID';
  static slackChannelId = 'SLACK_CHANNEL_ID';
  static spacer = 'SPACER';
  static string = 'STRING';
  static stringLong = 'STRING_LONG';
  static geoCircle = 'GEO_CIRCLE';
  static stripeCustomerId = 'STRIPE_CUSTOMER_ID';
  static stripePriceId = 'STRIPE_PRICE_ID';
  static stripeSubscriptionId = 'STRIPE_SUBSCRIPTION_ID';
  static transportType = 'TRANSPORT_TYPE';
  static tripSource = 'TRIP_SOURCE';
  static tripType = 'TRIP_TYPE';
  static uid = 'UID';
  static userType = 'USER_TYPE';
  static vehicleYear = 'VEHICLE_YEAR';
  static vehicleMake = 'VEHICLE_MAKE';
  static vehicleModel = 'VEHICLE_MODEL';
  static vehicleTrimId = 'VEHICLE_TRIM_ID';
  static zone = 'ZONE';

  static DisplayComponent(type: PropertyType, isArray: boolean): unknown {
    return unwrap(isArray ? this.arrayDisplayComponent(type) : undefined, undefined, () =>
      unwrap(
        this.enumType(type),
        () => {
          return null;
        },
        () => {
          if (this.isObjectId(type)) {
            return ObjectDisplay;
          } else {
            switch (type) {
              case this.boolean:
                return null;
              case this.destination:
                return null;
              case this.firestoreTimestamp:
                return null;
              case this.timestamp:
                return null;
              case this.location:
                return LocationDisplay;
              case this.geoCircle:
                return GeoCircleDisplay;
              case this.tripLocation:
                return TripLocationDisplay;
              case this.fullName:
                return null;
              case this.number:
                return null;
              case this.phone:
                return null;
              case this.pricingFormulaItem:
                return PricingFormulaItemDisplay;
              case this.string:
                return null;
              case this.stringLong:
                return null;
              case this.stripeCustomerId:
                return StripeCustomerDisplay;
              case this.stripePriceId:
                return StripePriceDisplay;
              case this.stripeSubscriptionId:
                return StripeSubscriptionDisplay;
              case this.uid:
                return UidDisplay;
              case this.vehicleYear:
                return null;
              case this.vehicleMake:
                return null;
              case this.vehicleModel:
                return null;
              case this.vehicleTrimId:
                return VehicleTrimDisplay;

              default:
                throw new Error(`Property type "${type}" doesn't have a DisplayComponent (or null if not needed)`);
            }
          }
        },
      ),
    );
  }

  static arrayDisplayComponent(type: PropertyType): unknown {
    switch (type) {
      case this.polygon:
        return PolygonsDisplay;
      case this.zone:
        return ZonesDisplay;
      default:
        return undefined;
    }
  }

  static format(type: PropertyType, value: any, model?: any): string | null {
    // value may be null/falsy
    const enumType = this.enumType(type);
    if (enumType) {
      return unwrap(
        enumType.from(value),
        type => (typeof type.title === 'function' ? type.title() : type.title),
        'None',
      );
    } else {
      switch (type) {
        case this.boolean:
          return unwrap(value, value => (value ? 'Yes' : 'No'), 'Unspecified');
        case this.destination:
          return destinationDescription(value);
        case this.firestoreTimestamp:
          return unwrap(value?.seconds, value => moment(value * 1000).calendar());
        case this.timestamp:
          return unwrap(value, value => moment(value).calendar());
        case this.geoCircle:
          return unwrap(value, value => `${round(value.radiusM / 1000, 1)} km`);
        case this.location:
        case this.tripLocation:
          return unwrap(value, value => [value.name, value.address].filter(Boolean).join(', '));
        case this.phone:
          return formatPhone(value);
        case this.pricingFormulaItem:
          return unwrap(value, value => unwrap(value.input, input => input.toLowerCase(), 'fee'));
        case this.polygon:
          return '-';
        case this.zone:
          return value?.name;
        default:
          return unwrap(value);
      }
    }
  }

  static formatForTable(property: Property, value: any): string | null {
    return unwrap(value, value => {
      if (property.isArray) {
        if (this.isObjectId(property.type) || property.type === this.uid) {
          return unwrap(value, value => value.length);
        } else {
          return value.map((item: any) => this.format(property.type, item)).join(', ');
        }
      } else {
        switch (property.type) {
          case this.boolean:
            return value ? '✓' : 'ｘ';
          default:
            return this.format(property.type, value);
        }
      }
    });
  }

  static tableColumnWidth(property: Property): number {
    switch (property.type) {
      case this.location:
        return 400;
      case this.tripLocation:
        return 700;
      default:
        return property.isArray ? 300 : 200;
    }
  }

  static FormComponent(type: PropertyType): unknown {
    const component = this.arrayFormComponent(type);
    if (component) {
      return component;
    }
    if (this.enumType(type)) {
      return EnumInput;
    } else if (this.isObjectId(type)) {
      return ObjectForm;
    } else {
      switch (type) {
        case this.boolean:
          return BooleanInput;
        case this.destination:
          return DestinationInput;
        case this.firestoreTimestamp:
          return FirestoreTimestampInput;
        case this.timestamp:
          return TimestampInput;
        case this.geoCircle:
          return GeoCircleInput;
        case this.location:
          return LocationInput;
        case this.fullName:
          return NameInput;
        case this.number:
          return NumberInput;
        case this.phone:
          return PhoneInput;
        case this.pricingFormulaItem:
          return PricingFormulaItemInput;
        case this.string:
          return StringInput;
        case this.stringLong:
          return StringLongInput;
        // case this.stripeCustomerId: return Stri pePriceInput
        case this.stripePriceId:
          return StripePriceInput;
        // case this.stripeSubscriptionId: ret urn null
        case this.tripLocation:
          return TripLocationInput;
        case this.uid:
          return UidInput;
        case this.vehicleYear:
          return VehicleYearInput;
        case this.vehicleMake:
          return VehicleMakeInput;
        case this.vehicleModel:
          return VehicleModelInput;
        case this.vehicleTrimId:
          return VehicleTrimInput;
        default:
          throw new Error("Property type doesn't have a FormComponent");
      }
    }
  }

  static arrayFormComponent(type: PropertyType): unknown {
    switch (type) {
      case this.polygon:
        return PolygonsInput;
      case this.zone:
        return ZonesInput;
      default:
        return undefined;
    }
  }

  static enumType(type: PropertyType): EnumWithTitleClass | undefined {
    switch (type) {
      case this.deliveryCountEstimate:
        return DeliveryCountEstimate;
      case this.deviceType:
        return DeviceType;
      case this.city:
        return City;
      case this.jobsTableColumn:
        return ColumnMetadata;
      case this.placeCountEstimate:
        return PlaceCountEstimate;
      case this.slackChannelId:
        return Channel;
      case this.transportType:
        return TransportType;
      case this.tripSource:
        return TripSource;
      case this.tripType:
        return TripType;
      case this.userType:
        return UserType;
      default:
        return undefined;
    }
  }

  static isObjectId(type: PropertyType): boolean {
    switch (type) {
      case this.firestoreId:
      case this.realtimeDatabaseId:
        return true;
      default:
        return false;
    }
  }

  static pathForObjectId(property: Property): string {
    if (property.path) {
      return property.path;
    } else {
      return kebabCase(property.name.replace('Id', '')) + 's';
    }
  }

  static async fetchObjectEntries(property: Property): Promise<Array<Array<string>>> {
    if (!this.isObjectId(property.type)) {
      throw new Error('Incorrect type');
    }
    const path = this.pathForObjectId(property);
    if (property.type === this.realtimeDatabaseId) {
      const snapshot = await firebase.database().ref(path).once('value');
      return Promise.all(
        Object.entries(snapshot.val() || {}).map(async ([id, model]: any[]) => [
          id,
          await this.fetchObjectName(property, id),
        ]),
      );
    } else {
      const snapshot = await firebase.firestore().collection(path).get();
      return Promise.all(
        snapshot.docs.map(async doc => [doc.id, (await this.fetchObjectName(property, doc.id)) ?? object.name]),
      );
    }
  }

  static async fetchObjectName(property: Property, objectId: string): Promise<string | null> {
    if (!this.isObjectId(property.type)) {
      throw new Error('Incorrect type');
    }
    if (!objectId) {
      return null;
    }
    if (property.useIdAsName) {
      return property.useIdAsName(objectId);
    }
    const path = this.pathForObjectId(property);
    if (property.type === this.realtimeDatabaseId) {
      if (path === 'drivers') {
        const driver = await driverRead(objectId, RealtimeDatabaseDataSource.instance);
        return baseUserFullName(driver);
      } else {
        return firebase
          .database()
          .ref(`/${path}/${objectId}/name`)
          .once('value')
          .then(snap => snap.val());
      }
    } else {
      return firebase
        .firestore()
        .collection(path)
        .doc(objectId)
        .get()
        .then(snaphot => snaphot.get('name'));
    }
  }

  static isWide(type: PropertyType): boolean {
    switch (type) {
      case this.pricingFormulaItem:
        return true;
      default:
        return false;
    }
  }

  static validate(type: PropertyType, value: any): boolean {
    // is object id?

    if (this.isObjectId(type)) {
      return true;
    }

    // enum?

    const enumType = this.enumType(type);
    if (enumType) {
      if (!enumType.allCases.find(enumCase => enumCase.rawValue === value)) {
        throw new Error('Required');
      }
    } else {
      switch (type) {
        case this.boolean: {
          return typeof value === 'boolean';
        }

        case this.destination: {
          destinationDeserialize(value);
          break;
        }

        case this.firestoreTimestamp:
          return Boolean(typeof value === 'object' && Number(value.seconds) && Number(value.nanoseconds));

        case this.geoCircle: {
          if (!value.center) {
            throw new Error('Center location required');
          }
          if (!Array.isArray(value.center) || value.center.length !== 2) {
            throw new Error('Invalid center coordinates');
          }
          if (!value.radiusM) {
            throw new Error('Radius required');
          }
          if (value.radiusM <= 0) {
            throw new Error('Invalid radius');
          }
          break;
        }

        case this.location: {
          return Location.prototype.isValid.apply(value) as boolean;
        }

        case this.tripLocation: {
          return Location.prototype.isValid.apply(value) as boolean;
        }

        case this.number: {
          if (isNaN(value)) throw new Error('Invalid number');
          break;
        }

        case this.phone: {
          formatPhoneE164(value, false);
          break;
        }

        case this.pricingFormulaItem: {
          PricingFormulaItem.prototype.isValid.apply(value);
          break;
        }

        case this.polygon: {
          polygonDeserialize(value);
          break;
        }

        case this.fullName:
        case this.stringLong:
        case this.string: {
          if (typeof value !== 'string') throw new Error('Must be a string');
          break;
        }

        case this.stripeCustomerId:
        case this.stripePriceId:
        case this.stripeSubscriptionId:
          if (!value) throw new Error('Invalid');
          break;

        case this.uid: {
          if (!value) {
            throw new Error('Required');
          }
          break;
        }

        case this.timestamp: {
          // if (isEqual(value, firebase.database.ServerValue.TIMESTAMP)) return true
          // if (typeof value !== 'number' || !moment(value).isValid()) throw new Error('Invalid timestamp value')
          break;
        }

        case this.vehicleYear: {
          deserializeNumber(value, 1800, 3000);
          break;
        }

        case this.vehicleMake: {
          deserializeString(value);
          break;
        }

        case this.vehicleModel: {
          deserializeString(value);
          break;
        }

        case this.vehicleTrimId: {
          deserializeString(value);
          break;
        }

        case this.zone: {
          zoneDeserialize(value);
          break;
        }

        default: {
          throw unhandledCase(type);
        }
      }
    }

    return true;
  }
}
