import {filterBoolean} from 'wave-common';
import Trip from '../../models/scoopm/Trip';
import TripContact from '../../models/scoopm/TripContact';
import TripLocation from '../../models/scoopm/TripLocation';
import UserType from '../../models/scoopm/UserType';

/**
 * Common function to keep the trip's contacts array up-to-date.
 */
export default function updateContactProperties(trip: Trip): Trip {
  // if single location trip

  if (trip.locations && filterBoolean(trip.locations)?.length === 1) {
    // make sure `contactUserType` is set

    trip.locations[0].contactUserType = UserType.customer;

    // try to set up contact

    if (trip.customer) {
      trip.contacts = [TripContact.fromTripCustomer(trip.customer, trip.customerId)];
    }
  }

  // if delivery, with location(s) that don't have a `contactUserType`

  if (
    trip.type === 'delivery' &&
    trip.locations &&
    filterBoolean<TripLocation>(trip.locations).find(location => !location.contactUserType)
  ) {
    // update locations

    for (let i = 0; i < trip.locations.length; i++) {
      const location = trip.locations[i];
      if (i === 0) {
        location.contactUserType = UserType.restaurant;
      } else if (i === 1) {
        location.contactUserType = UserType.orderer;
      } else {
        location.contactUserType = null; // stops should not have a contact type
      }
    }
  }

  // if delivery

  if (trip.type === 'delivery') {
    // if contacts

    if (trip.contacts) {
      for (let i = 0; i < trip.contacts.length; i++) {
        if (i === 0) {
          // if contact doesn't have type

          if (!trip.contacts[0].userType) {
            trip.contacts[0] = {
              ...trip.contacts[0],
              userType: UserType.restaurant,
            };
          }
        } else if (i === 1) {
          // if contact doesn't have type

          if (!trip.contacts[1].userType) {
            trip.contacts[1] = {
              ...trip.contacts[1],
              userType: UserType.orderer,
            };
          }
        }
      }
    } else {
      // set up contacts

      trip.contacts = [{userType: UserType.restaurant}, {userType: UserType.orderer}];
    }
  }

  return trip;
}
