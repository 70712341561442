import moment from 'moment';
import React from 'react';
import formatCurrency from '../../functions/formatCurrency';
import StripeObject from './StripeObject';
import StripePage from './StripePage';
import SubscriptionItem from './SubscriptionItem';
import SubscriptionStatus from './SubscriptionStatus';

export default interface Subscription extends StripeObject {
  status: SubscriptionStatus;
  items: StripePage<Array<SubscriptionItem>>;
  current_period_end: number;
}

export function subscriptionDescription(subscription: Subscription) {
  return (
    <>
      Billing {subscription.items.data[0].price.recurring.interval}ly &middot; Next invoice on{' '}
      {moment(subscription.current_period_end * 1000).format('MMM Do')} for{' '}
      {formatCurrency(subscription.items.data[0].price.unit_amount / 100, subscription.items.data[0].price.currency)}
    </>
  );
}
