import intlLibraryIsPresent from './intlLibraryIsPresent';

/**
 * Formats a number with a given locale and options, using the `Intl` API's `NumberFormat`
 *
 * For more, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat. Also, https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/resolvedOptions
 *
 * @param {number} number The number to be formatted
 * @param {object} options
 * @param {string} [options.style] The style of formatting ('currency', 'decimal', 'unit')
 * @param {string} [options.unit] The unit, if formatting by a unit
 * @param {string} locale The locale for formatting
 * @returns {string} The formatted result
 */
const formatIntl = (number, options, locale = 'en-US') => {
  if (intlLibraryIsPresent()) {
    try {
      return Intl.NumberFormat(locale, options).format(number);
    } catch (error) {
      console.error('Error formatting number, returning plain number', error);

      return number;
    }
  } else {
    console.error('Intl library is not present, returning plain number');

    return String(number);
  }
};

export default formatIntl;
