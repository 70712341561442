import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {unwrap} from 'wave-common';
import useQuery from '../../hooks/useQuery';
import VendorSwitcher from '../../layouts/main/VendorSwitcher';
import Trip from '../../models/scoopm/Trip';
import {TripSource} from '../../models/scoopm/TripSource';
import RealtimeDatabase from '../../references/database/RealtimeDatabase';
import TemplatesDropdown from './TemplatesDropdown';
import TripForm from './TripForm';

export default function CreateTripForm({
  isAdmin,
  source,
  completionPath = (tripId, trip) => `/admin/jobs/${trip.customerId}/${tripId}`,
  submitButton = 'Create job',
}: {
  isAdmin?: boolean;
  source: TripSource;
  completionPath: (tripId: string, trip: Trip) => string;
  submitButton?: string;
}) {
  const history = useHistory();

  const params = useQuery();
  const [defaultValue, setDefaultValue] = useState(
    useMemo(() => unwrap(params, params => unwrap(params.get('job'), JSON.parse)), [params]),
  );

  const onSubmit = (trip: Trip) => {
    // save trip and redirect

    console.log('Saving trip...', trip);

    const tripReference = RealtimeDatabase.instance.trips.child(trip.customerId).push();

    const value = JSON.parse(JSON.stringify(trip));

    tripReference.set(value).then(
      () => {
        history.push(completionPath(tripReference.path, trip));
      },
      error => {
        window.alert(`There was an error creating the trip. ${error.message}`);
      },
    );
  };

  return (
    <>
      <div className="row">
        <div className="col">{isAdmin && <h3 className="mb-2 mb-md-3">Manual entry</h3>}</div>

        {isAdmin && (
          <div className="col-auto">
            <div className="py-1">
              <VendorSwitcher fullWidth showAlways />
            </div>
          </div>
        )}

        {isAdmin && <TemplatesDropdown defaultValue={defaultValue} setDefaultValue={setDefaultValue} source={source} />}
      </div>

      <TripForm
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        submitButton={submitButton}
        isAdmin={isAdmin}
        source={source}
      />
    </>
  );
}
