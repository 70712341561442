import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';
import {Device, DeviceType, deviceTypeTitle, exists, unwrap, UserType, userTypeTitle} from 'wave-common';
import {momentFormatDateTime} from 'wave-common/lib/helpers/MomentHelper';
import FontAwesome from './FontAwesome';

export default function DeviceCard({device}: {device: Device}) {
  const lastOpenedString = unwrap(device.lastUsedTimestampMs, ts => momentFormatDateTime(moment(ts)));
  return (
    <Link
      to={`/admin/devices/${device.id}`}
      title={`Last opened ${lastOpenedString ?? 'unknown'}`}
      className={'text-decoration-none bg-white p-2 d-flex flex-column rounded-xl'}
      style={{minWidth: '8rem'}}
    >
      {/* icon */}

      <h3 className="align-self-center mb-1" aria-hidden>
        <IconForUserType type={device.userType} />
      </h3>

      {/* make/model */}

      <h6 className="align-self-center mb-1">{device.userType ? userTypeTitle(device.userType) : 'Unknown'} app</h6>

      <table className="mb-2 text-secondary small">
        <tbody>
          {/* device and user type */}

          <tr>
            <td className="text-center">
              <LogoForDeviceType type={device.type} />
            </td>
            <td>{device.model ?? `${deviceTypeTitle(device.type)} device`}</td>
          </tr>

          {/* last used timestamp */}

          <tr>
            <td>
              {exists(device.lastUsedTimestampMs) ? (
                <span>
                  <FontAwesome.EyeSolid />
                </span>
              ) : (
                <span>
                  <FontAwesome.EyeSolid />
                </span>
              )}
            </td>
            <td>{lastOpenedString ?? ' ?'}</td>
          </tr>
        </tbody>
      </table>

      {/* is signed in */}

      <div className="align-self-stretch mb-n2 mx-n2 text-center">
        <small>
          {unwrap(
            device.isSignedIn,
            isSignedIn =>
              isSignedIn ? (
                <div className="rounded-bottom-xl p-1 bg-success text-white">
                  <FontAwesome.ArrowRightToBracket /> Signed in
                </div>
              ) : (
                <div className="rounded-bottom-xl p-1 bg-danger text-white">
                  <FontAwesome.ArrowRightFromBracket /> Signed out
                </div>
              ),
            () => (
              <>Auth status unknown</>
            ),
          )}
        </small>
      </div>
    </Link>
  );
}

// function IconForDeviceType({ type }: { type: DeviceType }) {
//     switch (type) {
//         case 'ANDROID':
//         case 'IOS':
//             return <FontAwesome.MobileSolid />
//         case 'API':
//             return <FontAwesome.ServerSolid />
//         case 'WEB':
//             return <FontAwesome.BrowserSolid />
//     }
// }

function LogoForDeviceType({type}: {type: DeviceType}) {
  switch (type) {
    case 'ANDROID':
      return <i className="fa-brands fa-android"></i>;
    case 'IOS':
      return <i className="fa-brands fa-apple"></i>;
  }
  return null;
}

function IconForUserType({type}: {type: UserType | undefined}) {
  switch (type) {
    case 'CUSTOMER':
      return <FontAwesome.UserSolid />;
    case 'DRIVER':
      return <FontAwesome.CarSolid />;
  }
  return <FontAwesome.QuestionCircle />;
}

export function MiniDeviceDetails({device}: {device: Device}) {
  const lastOpenedString = unwrap(device.lastUsedTimestampMs, ts => momentFormatDateTime(moment(ts)));
  return (
    <Link to={`/admin/devices/${device.id}`} className="d-block">
      <IconForUserType type={device.userType} /> {device.userType ? userTypeTitle(device.userType) : 'Unknown'} app,{' '}
      {device.model ?? `${deviceTypeTitle(device.type)} device`}, {lastOpenedString ? `${lastOpenedString}, ` : ''}
      {device.isSignedIn ? 'is' : 'is not'} signed in
    </Link>
  );
}
