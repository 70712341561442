import first from 'lodash/first';
import last from 'lodash/last';
import {latLng} from '../components/GoogleMaps/LatLng+additions';
import Constants from '../Constants';

export default class DirectionsService {}

let __googleMapsDirectionsService = null;
const googleMapsDirectionsService = () => {
  if (!__googleMapsDirectionsService) __googleMapsDirectionsService = new window.google.maps.DirectionsService();
  return __googleMapsDirectionsService;
};

DirectionsService.getDirections = (originFriendly, waypointsFriendly, destinationFriendly) =>
  new Promise((resolve, reject) => {
    let request = {
      travelMode: 'DRIVING',
      origin: latLng(originFriendly),
    };

    if (waypointsFriendly) {
      request.waypoints = (Array.isArray(waypointsFriendly) ? waypointsFriendly : [waypointsFriendly]).map(
        waypointFriendly => ({
          location: latLng(waypointFriendly),
        }),
      );
    }

    if (destinationFriendly) {
      request.destination = latLng(destinationFriendly);
    }

    console.log('Requesting directions...', request);
    googleMapsDirectionsService().route(request, (result, status) => {
      if (status !== 'OK') {
        const error = new Error(`Couldn't get route directions: status "${status}"`);
        console.error(error);
        return reject(error);
      }

      console.log('Got directions', result);
      resolve(result);
    });
  });

DirectionsService.getDirectionsForTrip = (tripId, trip) => {
  const locations = trip.locationsWithDefault();
  return DirectionsService.getDirections(
    first(locations).coordinates,
    locations.length > 2 ? locations.slice(1, locations.length - 1).map(l => l.coordinates) : null,
    last(locations).coordinates,
  ).then(directions => [tripId, directions]);
};

DirectionsService.getPickupDistance = (pickupCoords, dropOffCoords) =>
  DirectionsService.getDirections(pickupCoords, null, dropOffCoords).then(directions => {
    let meters = directions.routes[0].legs[0].distance.value;
    let miles = meters * Constants.metersPerMile;
    return miles;
  });
