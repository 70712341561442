import DatabaseReference from '../platform/DatabaseReference';
import DriverGeoFire from './DriverGeoFire';

export default class DriversGeoFire extends DatabaseReference {
  constructor(path, parent) {
    super(path, parent);

    this.scoopM = new DriverGeoFire('scoopM', this);
  }
}
