import HttpReference from '../platform/HttpReference';
import Quote from './DispatchQuote';

export default class Quotes extends HttpReference {
  get childReferenceType() {
    return Quote;
  }

  post(username, password, partialTask) {
    return this.request({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${Buffer.from(`${username}:${password}`).toString('base64')}`,
      },
      data: partialTask,
    });
  }
}
