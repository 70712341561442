import React from 'react';
import {FontAwesomeV5} from '../../../../components/FontAwesome';
import joinClassNames from '../../../../functions/joinClassNames';
import BORDER_RADIUS from './BORDER_RADIUS';
import {CellOfAction as CellOfActionType} from './Cell';
import CellProps from './CellProps';

export default function CellOfAction({cell, style}: CellProps<CellOfActionType>) {
  return (
    <div
      style={{
        borderTopRightRadius: cell.lastAction && cell.roundedTopRight ? BORDER_RADIUS : undefined,
        borderBottomRightRadius: cell.lastAction && cell.roundedBottomRight ? BORDER_RADIUS : undefined,
        ...style,
      }}
      onMouseEnter={cell.onMouseEnter}
      className={joinClassNames('dropdown border-bottom border-right', cell.isHighlighted ? 'bg-light' : 'bg-white')}>
      <button type="button" data-toggle="dropdown" className="btn w-100 h-100">
        <FontAwesomeV5 name="ellipsis-vertical" />
      </button>
      <div className="dropdown-menu dropdown-menu-right">
        <button className="dropdown-item">hi</button>
      </div>
    </div>
  );
}
