import HttpReference from '../platform/HttpReference';
import OloQuote from './OloQuote';
import RealtimeDatabase from '../database/RealtimeDatabase';

export default class OloQuotes extends HttpReference {
  get childReferenceType() {
    return OloQuote;
  }

  async post(partialDelivery) {
    const [oloClientId] = await RealtimeDatabase.instance.config.child('keys').oloClientId.once();

    return await this.request({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${oloClientId}`,
      },
      data: partialDelivery,
    });
  }
}
