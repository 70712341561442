"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.secondaryTripStatusForTrip = exports.secondaryTripStatusTitle = exports.secondaryTripStatusDescription = exports.secondaryTripStatusAllKnown = void 0;
const Enum_1 = require("../type-descriptions/Enum");
const Trip_1 = require("./Trip");
exports.secondaryTripStatusAllKnown = [
    'REQUIRES_RESPONSE',
    'ASSIGNED',
    'UNASSIGNED',
    'ON_ROUTE_TO_PICKUP',
    'AT_PICKUP',
    'ON_ROUTE_TO_DROP_OFF',
    'AT_DROP_OFF',
    'UNKNOWN',
];
exports.secondaryTripStatusDescription = (0, Enum_1.enumDescription)({
    userFacingTitle: 'secondary status',
    userFacingValue: secondaryTripStatusTitle,
    values: exports.secondaryTripStatusAllKnown,
    order: status => exports.secondaryTripStatusAllKnown.indexOf(status),
});
function secondaryTripStatusTitle(status) {
    switch (status) {
        case 'REQUIRES_RESPONSE':
            return 'Requires response';
        case 'ASSIGNED':
            return 'Assigned';
        case 'UNASSIGNED':
            return 'Unassigned';
        case 'ON_ROUTE_TO_PICKUP':
            return 'On route to pickup';
        case 'AT_PICKUP':
            return 'At pickup';
        case 'ON_ROUTE_TO_DROP_OFF':
            return 'On route to drop-off';
        case 'AT_DROP_OFF':
            return 'At drop-off';
        case 'UNKNOWN':
            return 'unknown';
    }
}
exports.secondaryTripStatusTitle = secondaryTripStatusTitle;
function secondaryTripStatusForTrip(trip) {
    if (trip.requiresResponse) {
        return 'REQUIRES_RESPONSE';
    }
    switch (trip.status) {
        case 'SCHEDULED':
            return trip.driverId ? 'ASSIGNED' : 'UNASSIGNED';
        case 'ACCEPTED':
            return (0, Trip_1.tripIsAtCurrentLocation)(trip) ? 'AT_PICKUP' : 'ON_ROUTE_TO_PICKUP';
        case 'STARTED':
            return (0, Trip_1.tripIsAtCurrentLocation)(trip) ? 'AT_DROP_OFF' : 'ON_ROUTE_TO_DROP_OFF';
        case 'CREATED':
        case 'COMPLETED':
        case 'CANCELED-BY-RIDER':
        case 'CANCELED-BY-DRIVER':
        case 'TRIP-TIMED-OUT':
            return undefined;
    }
}
exports.secondaryTripStatusForTrip = secondaryTripStatusForTrip;
