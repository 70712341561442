import moment from 'moment';
import {useCallback, useMemo, useState} from 'react';
import unwrap from '../functions/unwrap';

export default function useLocalStorage<T = string>(
  key: string,
  defaultValue?: T,
  options?: {
    serialize: (value: T) => string;
    deserialize?: (rawValue: string) => T;
  },
): [T extends null | undefined ? T | null : T, (newValue: T | undefined) => void] {
  if (typeof key !== 'string') {
    throw new Error('Must specify a key');
  }

  const initialValue: T | null = useMemo(() => {
    let storedValue = window.localStorage.getItem(key);
    // console.log({storedValue});
    return determineDefaultValue<T>(storedValue, defaultValue, options?.deserialize);
  }, [key, defaultValue, options?.deserialize]);

  const [item, setItem] = useState<T | null>(initialValue);

  const setStorageAndState = useCallback(
    value => {
      setLocalStorage(key, value, options?.serialize);
      setItem(value);
    },
    [key, options?.serialize],
  );

  return [item as any, setStorageAndState];
}

function setLocalStorage<T>(key: string, value: T | null | undefined, serialize?: (value: T) => string) {
  const serializedValue = serialize ? unwrap(value, (value: T) => serialize(value)) : (value as unknown as string);
  try {
    if (value === undefined || value === null) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, serializedValue || '');
    }
  } catch (error) {
    // TODO
    console.error('Local storage: Error reading local storage', error);
  }
}

function determineDefaultValue<T>(
  actualValue: string | null,
  fallback?: T,
  deserialize?: (rawValue: string) => T,
): T | null {
  if (deserialize) {
    return unwrap(actualValue, value => deserialize(value));
  } else if (actualValue === 'true') {
    return true as unknown as T;
  } else if (actualValue === 'false') {
    return false as unknown as T;
  } else if (actualValue === 'null') {
    return null as unknown as T;
  } else if (actualValue === '') {
    return null as unknown as T;
  } else {
    return unwrap(
      actualValue,
      value => {
        const number = Number(value);
        if (isNaN(number)) {
          return value as unknown as T;
        } else {
          return number as unknown as T;
        }
      },
      fallback,
    );
  }
}

export function useLocalStorageDate(key: string, defaultValue?: Date) {
  return useLocalStorage(key, defaultValue, {
    serialize: serializeDate,
    deserialize: deserializeDate,
  });
}

const DATE_FORMAT: string = 'YYYY-MM-DD';

function serializeDate(date: Date): string {
  return moment(date).format(DATE_FORMAT);
}

function deserializeDate(string: string): Date {
  return moment(string, DATE_FORMAT).toDate();
}

export function useLocalStorageArray(key: string): [string[], (strings: string[]) => void] {
  const [value, setValue] = useLocalStorage(key, undefined, {
    serialize: serializeArray,
    deserialize: deserializeArray,
  });
  return [value || [], setValue];
}

function serializeArray(array: string[]): string {
  return array.join(',');
}

function deserializeArray(string: string): string[] {
  return string.split(',');
}

export function useLocalStorageBoolean(key: string, defaultValue = false): [boolean, (newValue: boolean) => void] {
  const initialValue = useMemo(() => {
    const storedValue = window.localStorage.getItem(key);
    if (storedValue !== null) {
      return storedValue === 'YES';
    } else {
      return defaultValue;
    }
  }, [key, defaultValue]);

  const [value, setValue] = useState<boolean>(initialValue);

  const setValueAndStorage = useCallback(
    (newValue: boolean) => {
      setValue(newValue);
      window.localStorage.setItem(key, newValue ? 'YES' : 'NO');
    },
    [key],
  );

  return [value, setValueAndStorage];
}
