import {useEffect, useState} from 'react';
import ScoopMApi from '../references/scoopm-api';

export default function useEmails(uids) {
  const [result, setResult] = useState();

  useEffect(() => {
    if (uids) {
      setResult(undefined);
      ScoopMApi.instance.users.emails
        .get(uids)
        .then(response => {
          setResult(response.data);
        })
        .catch(error => {
          console.error(error);
          setResult(null);
        });
    } else {
      setResult(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [`${uids}`]);

  return result;
}
