import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Delay from '../../../components/Delay';
import Spinner from '../../../components/Spinner';
import formatCurrency from '../../../functions/formatCurrency';
import unwrap from '../../../functions/unwrap';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';

export default function Olo() {
  // hooks

  const quoteEvents = useRealtimeDatabase({
    path: '/events',
    orderByChild: 'path',
    equalTo: '/v1/olo/quotes',
  });
  const acceptanceEvents = useRealtimeDatabase({
    path: '/events',
    orderByChild: 'responseBody/confirmationId',
    startAt: '-',
  });
  const [deliveries, setDeliveries] = useState();

  // effects

  useEffect(() => {
    if (quoteEvents.data && acceptanceEvents.data) {
      const deliveries = {};
      Object.entries(quoteEvents.data)
        .filter(([eventId, event]) =>
          Boolean(
            event.requestBody && event.requestBody.quoteExternalReference && event.responseBody && event.timestampMs,
          ),
        )
        .forEach(([eventId, event]) => {
          if (!deliveries[event.requestBody.quoteExternalReference]) {
            deliveries[event.requestBody.quoteExternalReference] = {
              events: {},
            };
          }
          deliveries[event.requestBody.quoteExternalReference].events[eventId] = {type: 'QUOTE', ...event};
        });
      Object.entries(acceptanceEvents.data)
        .filter(([eventId, event]) =>
          Boolean(
            event.requestBody && event.requestBody.quoteExternalReference && event.responseBody && event.timestampMs,
          ),
        )
        .forEach(([eventId, event]) => {
          if (!deliveries[event.requestBody.quoteExternalReference]) {
            deliveries[event.requestBody.quoteExternalReference] = {
              events: {},
            };
          }
          deliveries[event.requestBody.quoteExternalReference].events[eventId] = {type: 'ACCEPTANCE', ...event};
        });
      setDeliveries(
        Object.entries(deliveries).sort(
          ([quoteExternalReference1, deliveries1], [quoteExternalReference2, deliveries2]) => {
            const event1EarliestTimestamp =
              unwrap(
                Object.values(deliveries1.events).sort((event1, event2) => event1.timestampMs - event2.timestampMs)[0],
                quote => quote.timestampMs,
              ) || 0;
            const event2EarliestTimestamp =
              unwrap(
                Object.values(deliveries2.events).sort((event1, event2) => event1.timestampMs - event2.timestampMs)[0],
                quote => quote.timestampMs,
              ) || 0;
            return event2EarliestTimestamp - event1EarliestTimestamp;
          },
        ),
      );
    } else {
      setDeliveries(null);
    }
  }, [quoteEvents.data, acceptanceEvents.data]);

  // render

  if (quoteEvents.isLoading)
    return (
      <Delay>
        <Spinner />
      </Delay>
    );
  if (quoteEvents.error) return 'There was an error. Try reloading the page';
  if (!quoteEvents.data) return 'No data';
  if (!deliveries) return null;

  return (
    <div className="p-2 p-md-3">
      <div className="p-2 p-md-3 bg-white rounded mb-3">
        <h3>OLO Quotes</h3>
        <div className="d-inline-block bg-white rounded">
          <table className="table w-auto table-bordered">
            <thead className="thead-light">
              <tr>
                <th className="border-0">Restaurant</th>
                <th className="border-0">Quotes</th>
              </tr>
            </thead>
            <tbody>
              {deliveries.map(([quoteExternalReference, deliveries]) => {
                const events = Object.entries(deliveries.events);

                return (
                  <tr key={quoteExternalReference}>
                    <td>
                      <strong className="mr-2">{unwrap(events[0], event => event[1].requestBody.brandName)}</strong>
                      <div className="text-muted">{unwrap(events[0], event => event[1].requestBody.pickup.street)}</div>
                      <div className="text-muted">
                        {unwrap(events[0], event => formatCurrency(event[1].requestBody.orderValue))}
                      </div>
                      {unwrap(
                        events.find(event => event[1].type === 'ACCEPTANCE'),
                        ([eventId, event]) => (
                          <div className="text-muted">
                            {event.requestBody.pickupTime &&
                              `Pickup ${moment(event.requestBody.pickupTime).calendar()}`}
                            {event.requestBody.dropoffTime &&
                              `Drop off ${moment(event.requestBody.dropoffTime).calendar()}`}
                          </div>
                        ),
                      )}
                      <small className="text-medium-light">
                        <span className="no-select">Basket ID </span>
                        {quoteExternalReference}
                      </small>
                    </td>

                    <td>
                      {events.map(([eventId, event]) => (
                        <div key={eventId} className="d-flex justify-content-between align-items-center">
                          {event.type === 'QUOTE' ? (
                            <Link to={`/admin/quotes/${event.responseBody.quoteId}`}>
                              {event.responseBody.isAvailable ? (
                                formatCurrency(event.responseBody.fee)
                              ) : (
                                <span className="text-danger">Not available</span>
                              )}
                            </Link>
                          ) : (
                            <Link to={`/admin/events/${eventId}`}>
                              {event.responseCode >= 200 || event.responseCode < 300 ? (
                                <span className="text-success">Accepted</span>
                              ) : (
                                <span className="text-danger">Failed to accept</span>
                              )}
                            </Link>
                          )}
                          <small className="text-muted ml-2">
                            <Link to={`/admin/events/${eventId}`}>{moment(event.timestampMs).calendar()}</Link>
                          </small>
                        </div>
                      ))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
