export function maybe(object, ...propertyNames) {
  // let log = (...message) => console.log(`MAYBE:`, ...message);

  if (!Array.isArray(propertyNames)) {
    throw new Error('The "maybe" function must take an array of property names');
  }

  if (propertyNames.length === 0) {
    // log(`Got property:`, object);
    return object;
  }

  if (object === null) {
    return null;
  }

  if (object === undefined) {
    return null;
  }

  let propertyName = propertyNames.shift();
  let property = object[propertyName];
  // log(`Looking at`, object, `for`, propertyName, `found`, property);

  if (typeof property === 'undefined') {
    // log(`Undefined property "${propertyName}"`);
    return null;
  } else {
    // log(`Going further...`);
    return maybe(property, ...propertyNames);
  }
}
