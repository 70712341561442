import HttpReference from '../../platform/HttpReference';
import Product from './Product';
import Stripe from './Stripe';

export default class Products extends HttpReference<void, Product, Stripe> {
  get childReferenceType() {
    return Product;
  }

  get deliveryServices() {
    return this.child(process.env.REACT_APP_STRIPE_DELIVERY_SERVICES_PRODUCT_ID!);
  }
}
