import React, {useEffect} from 'react';
import {Link, match, Redirect} from 'react-router-dom';
import {DelaySpinner} from '../../components/Spinner';
import UserGuard from '../../guards/UserGuard';
import useStatus from '../../hooks/useStatus';
import CenteredLayout from '../../layouts/CenteredLayout';
import ApiError from '../../models/scoopm/ApiError';
import ScoopMApi from '../../references/scoopm-api';

export default function UserGroupInvite(props: {match: match<{id: string}>}) {
  return (
    <UserGuard>
      <Content match={props.match} />
    </UserGuard>
  );
}

function Content(props: {match: match<{id: string}>}) {
  const {status, handlePromise, error} = useStatus<any>();

  useEffect(() => {
    handlePromise(ScoopMApi.instance.userGroupInvites.child(props.match.params.id).accept());
  }, [handlePromise, props.match.params.id]);

  switch (status) {
    case 'IDLE':
      return null;
    case 'PENDING':
      return <PendingView />;
    case 'FAILURE':
      return <ErrorView error={error!} />;
    case 'SUCCESS':
      return <Redirect to="/profile/team" />;
  }
}

function PendingView() {
  return (
    <CenteredLayout>
      <div className="d-flex align-items-center text-muted">
        <DelaySpinner />
        <h3 className="ml-2 ml-md-3 mb-0">Joining team...</h3>
      </div>
    </CenteredLayout>
  );
}

function ErrorView(props: {error: Error | ApiError}) {
  return (
    <CenteredLayout>
      <div className="d-flex flex-column align-items-center text-muted">
        <h3>{(props.error instanceof ApiError && props.error.message) || 'Unable to join team'}</h3>
        <p>
          <Link to="/" className="text-decoration-underline">
            Home
          </Link>
        </p>
      </div>
    </CenteredLayout>
  );
}

async function delay(milliseconds: number) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}
