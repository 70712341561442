import {BaseUser} from 'wave-common';
import WaveCommonUserType from 'wave-common/lib/models/UserType';
import UserType from './UserType';

export default class TripContact {
  name?: string;
  phone?: string;
  supportsSms?: boolean;
  uid?: string;
  userType?: string;

  constructor({name, phone, supportsSms, uid, userType}: TripContact) {
    this.name = name;
    this.phone = phone;
    this.supportsSms = supportsSms || undefined;
    this.uid = uid || undefined;
    this.userType = userType;
  }

  static fromTripCustomer = (tripCustomer: BaseUser, uid: string) =>
    new TripContact({
      name: `${tripCustomer.firstName} ${tripCustomer.lastName}`,
      phone: tripCustomer.cellphone,
      uid,
      userType: UserType.customer as never as WaveCommonUserType,
    });

  static none = () =>
    new TripContact({
      name: 'None',
    });
}
