import React, {Component} from 'react';
import firebase from 'firebase/app';

class RecoverEmail extends Component {
  state = {
    pending: true,
  };

  componentDidMount() {
    var restoredEmail = null;

    // check action code
    firebase
      .auth()
      .checkActionCode(this.props.actionCode)
      .then(info => {
        // Get the restored email address.
        restoredEmail = info['data']['email'];

        // apply action code (revert to the old email)
        return firebase.auth().applyActionCode(this.props.actionCode);
      })
      .then(() => {
        // success
        this.setState({
          pending: false,
          email: restoredEmail,
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          pending: false,
          error: `Your request to recover your email has expired or the link has already been used`,
        });
      });
  }

  onClickResetPassword(e) {
    e.preventDefault();

    // send password reset email
    firebase
      .auth()
      .sendPasswordResetEmail(this.state.email)
      .then(() => {
        this.setState({
          message: `A password reset email has been sent to "${this.state.email}. Follow the link in the email"`,
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          error: `Sorry, there was an error sending a password reset email`,
        });
      });
  }

  render() {
    if (this.state.pending) return 'Please wait...';

    if (this.state.message) return this.state.message;

    if (this.state.error) return this.state.error;

    return (
      <div>
        <h4>Your email has been set to "{this.state.email}"</h4>
        <a onClick={this.onClickResetPassword.bind(this)} className="btn btn-link" href="#reset-password">
          Reset password
        </a>
        <a href={this.props.continueUrl || '/'} className="btn btn-primary float-right">
          {this.props.continueUrl ? 'Continue' : 'Home'}
        </a>
      </div>
    );
  }
}

export default RecoverEmail;
