import axios from 'axios';
import {useEffect, useMemo, useState} from 'react';
import {Driver, unwrap} from 'wave-common';
import {transportTypeHasFuelImage} from 'wave-common/lib/models/TransportType';
import Vehicle from 'wave-common/lib/models/Vehicle';

export default function useFuelImageUrl(
  vehicle: Pick<Vehicle, 'year' | 'make' | 'model' | 'color' | 'transportType'> | undefined,
  driver?: Driver,
) {
  const [url, setUrl] = useState<string>();

  const {year, make, model, color} = useMemo<
    Record<'make' | 'model' | 'color', string | undefined> & {year: number | undefined}
  >(
    () =>
      vehicle ??
      unwrap(driver, driver => ({
        year: driver.year,
        make: driver.make,
        model: driver.model,
        color: driver.carColor,
      })) ??
      {},
    [vehicle, driver],
  );

  useEffect(() => {
    setUrl(undefined);
    if (year && make && model && color) {
      if (vehicle?.transportType && !transportTypeHasFuelImage(vehicle.transportType)) {
        return;
      }
      axios.get(`https://driver.scoopm.com/carImage/${year}/${make}/${model}/${color}`).then(
        response => {
          console.log('response.data', response.data);
          setUrl(response.data);
        },
        error => {
          console.log('Error getting car image url', error);
          setUrl(undefined);
        },
      );
    }
  }, [year, make, model, color, vehicle?.transportType]);

  return url;
}
