import firebase from 'firebase/app';
import sortBy from 'lodash/sortBy';
import React, {useCallback, useContext, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {Driver, driverUpdate, useStatus} from 'wave-common';
import {vehicleCreate} from 'wave-common/lib/controllers/Vehicle';
import Vehicle, {vehicleDeserialize, vehicleFromDriver} from 'wave-common/lib/models/Vehicle';
import Alert from '../../../../components/Alert/Alert';
import FontAwesome from '../../../../components/FontAwesome';
import {DelaySpinner} from '../../../../components/Spinner';
import VechicleCard from '../../../../components/VehicleCard';
import {useAlert} from '../../../../contexts/AlertContext';
import FirestoreDataSource from '../../../../data-sources/FirestoreDataSource';
import RealtimeDatabaseDataSource from '../../../../data-sources/RealtimeDatabaseDataSource';
import {unwrap} from '../../../../functions/unwrap';
import useFirestoreQueryListener from '../../../../hooks/useFirestoreQueryListener';

export default function Vehicles({driver}: {driver: Driver}) {
  const {isLoading, error, snapshot} = useFirestoreQueryListener(
    useMemo(() => firebase.firestore().collection('vehicles').where('driverId', '==', driver.id), [driver.id]),
  );
  const firestoreVehicles = useMemo(
    () =>
      sortBy(
        snapshot?.docs.map(doc => vehicleDeserialize(doc.data(), doc.id)),
        'make',
        'model',
      ),
    [snapshot],
  );

  const vehicles = useMemo(
    () =>
      unwrap(firestoreVehicles, vehicles => {
        if (
          !vehicles.find(
            vehicle => vehicle.year === driver.year && vehicle.make === driver.make && vehicle.model === driver.model,
          )
        ) {
          try {
            return [vehicleFromDriver(driver), ...vehicles];
          } catch (error) {
            return vehicles;
          }
        } else {
          return vehicles;
        }
      }),
    [driver, firestoreVehicles],
  );

  const {handlePromise} = useStatus(React as any);
  const {setAlert} = useAlert();

  const onSelectVehicle = useCallback(
    (vehicle: Omit<Vehicle, 'id'> & {id?: string}) => {
      async function handler() {
        const currentVehicleIsSaved = Boolean(
          firestoreVehicles?.find(
            firestoreVehicle =>
              firestoreVehicle.year === driver.year &&
              firestoreVehicle.make === driver.make &&
              firestoreVehicle.model === driver.model,
          ),
        );
        if (!currentVehicleIsSaved) {
          const driverVehicle = vehicleFromDriver(driver);
          await vehicleCreate(driverVehicle, FirestoreDataSource);
        }
        try {
          driverUpdate(
            driver.id,
            {
              year: vehicle.year,
              make: vehicle.make,
              model: vehicle.model,
              carColor: vehicle.color,
              trimId: vehicle.trimId,
              licensePlateNumber: vehicle.licensePlateNumber,
              activeVehicleId: vehicle.id,
              transportType: vehicle.transportType ?? 'CAR',
            },
            RealtimeDatabaseDataSource.instance,
          );
        } catch (error) {
          setAlert(new Alert('Unable to set active vehicle', (error as any)?.message));
        }
      }
      handlePromise(handler());
    },
    [driver, handlePromise, setAlert, firestoreVehicles],
  );

  return (
    <>
      {isLoading ? (
        <DelaySpinner />
      ) : error ? (
        error.message
      ) : (
        <>
          <div className="row">
            {/* vehicle cards */}

            {vehicles?.map((vehicle, i) => {
              const isActive =
                vehicle.year === driver.year && vehicle.make === driver.make && vehicle.model === driver.model;
              return (
                <div key={vehicle.id ?? i} className="col-auto d-flex mb-2">
                  <VechicleCard
                    vehicle={vehicle}
                    isActive={isActive}
                    onSelect={isActive ? undefined : onSelectVehicle}
                  />
                </div>
              );
            })}

            {/* add vehicle card */}

            <div className="col-auto d-flex mb-2" style={{minHeight: '14rem'}}>
              <Link
                to={`/admin/vehicles/add?driverId=${driver.id}`}
                className="d-flex align-items-center justify-content-center rounded p-2 p-md-3 border text-muted text-decoration-none"
                style={{width: '11rem'}}>
                <h5 className="mb-0">
                  <FontAwesome.Plus /> Add
                </h5>
              </Link>
            </div>
          </div>

          <small className="text-muted">
            <FontAwesome.InfoCircle /> The active vehicle's details will be used for <strong>insurance</strong> and{' '}
            <strong>airport</strong> reporting, and will appear in the <strong>rider app</strong> during rideshare rides
          </small>
        </>
      )}
    </>
  );
}
