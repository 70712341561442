"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.positionInListLocationVerb = exports.positionInListLocationName = exports.positionInListFor = void 0;
var PositionInList;
(function (PositionInList) {
    PositionInList["first"] = "first";
    PositionInList["middle"] = "middle";
    PositionInList["last"] = "last";
})(PositionInList || (PositionInList = {}));
exports.default = PositionInList;
function positionInListFor(index, arrayOrCount) {
    var _a;
    const count = (_a = arrayOrCount.length) !== null && _a !== void 0 ? _a : arrayOrCount;
    if (count === 0) {
        return undefined;
    }
    else if (index === 0) {
        return PositionInList.first;
    }
    else if (index === count - 1) {
        return PositionInList.last;
    }
    else {
        return PositionInList.middle;
    }
}
exports.positionInListFor = positionInListFor;
function positionInListLocationName(positionInList) {
    if (!positionInList) {
        return 'Task';
    }
    switch (positionInList) {
        case PositionInList.first:
            return 'Pickup';
        case PositionInList.last:
            return 'Drop-off';
        case PositionInList.middle:
            return 'Stop';
    }
}
exports.positionInListLocationName = positionInListLocationName;
function positionInListLocationVerb(positionInList) {
    if (!positionInList) {
        return 'Perform';
    }
    switch (positionInList) {
        case PositionInList.first:
            return 'Pick up';
        case PositionInList.last:
            return 'Drop off';
        case PositionInList.middle:
            return 'Stop';
    }
}
exports.positionInListLocationVerb = positionInListLocationVerb;
