"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tupleForUnionType = void 0;
/**
 * Creates an array of all the values in the union type. Forces type compliance if you change the union type.
 *
 * NOTE: You must specify the generic parameter `T`. It should be a union type
 *
 * @param record A record, where each key is one of the union's types, and each value is always `undefined`
 * @returns An array containing the keys of the record
 */
function tupleForUnionType(record) {
    return Object.keys(record);
}
exports.tupleForUnionType = tupleForUnionType;
