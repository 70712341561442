import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FixedSizeList} from 'react-window';
import Delay from '../../../components/Delay';
import Spinner from '../../../components/Spinner';
import unwrap from '../../../functions/unwrap';
import TripRow from './TripRow';

export default function TripsList(props: {
  containerDiv: React.RefObject<HTMLDivElement>;
  filteredEntries?: any[];
  selectedTripId?: string;
  onChangeSelectedTripId: (id: string) => void;
  highlightedTripId?: string;
  onChangeHighlightedTripId: (id: string) => void;
  timezone: string;
}) {
  const [listDimensions, setListDimensions] = useState({
    width: 350,
    height: 500,
  }); // default value if resize observer not available

  // EFFECT: container div --> list height

  useEffect(() => {
    if (window.ResizeObserver) {
      const observer = new window.ResizeObserver(() => {
        setListDimensions({
          width: props.containerDiv.current!.offsetWidth,
          height: props.containerDiv.current!.offsetHeight,
        });
      });
      observer.observe(props.containerDiv.current!);
      const div = props.containerDiv.current!; // capture object reference
      return () => observer.unobserve(div);
    }
  }, [props.containerDiv]);

  const getRow = useCallback(
    secondaryProps => {
      return (
        <TripRow
          selectedTripId={props.selectedTripId}
          onChangeSelectedTripId={props.onChangeSelectedTripId}
          timezone={props.timezone}
          onChangeHighlightedTripId={props.onChangeHighlightedTripId}
          highlightedTripId={props.highlightedTripId}
          {...secondaryProps}
        />
      );
    },
    [
      props.selectedTripId,
      props.onChangeSelectedTripId,
      props.onChangeHighlightedTripId,
      props.timezone,
      props.highlightedTripId,
    ],
  );

  const list = useMemo(
    () =>
      unwrap(
        props.filteredEntries,
        entries =>
          entries.length ? (
            <FixedSizeList
              width={listDimensions.width}
              height={listDimensions.height}
              itemCount={entries.length}
              itemData={entries}
              itemKey={(index, data) => data[index][0]}
              itemSize={76}>
              {getRow}
            </FixedSizeList>
          ) : (
            <div className="m-3 p-3 text-center text-muted">No results</div>
          ),
        () => (
          <Delay>
            <div className="mt-5 d-flex align-items-center justify-content-center text-center text-muted">
              <Spinner />
            </div>
          </Delay>
        ),
      ),
    [props.filteredEntries, getRow, listDimensions],
  );

  return list;
}
