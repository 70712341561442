import React from 'react';
import profile from '../../images/profile.png';
import profileAdmin from '../../images/profile_admin.png';
import profileGuardian from '../../images/profile_guardian.png';
import profileOrderer from '../../images/profile_orderer.png';
import profileRestaurant from '../../images/profile_restaurant.png';
import profileDriver from '../../images/profile_driver.png';
import unhandledCase from '../../functions/unhandledCase';
const {default: Enum} = require('../base/Enum');

export default class UserType extends Enum {
  get icon() {
    switch (this) {
      case UserType.admin:
        return <i className="fas fa-headset mr-2" />;
      case UserType.customer:
        return <i className="fas fa-user mr-2" />;
      case UserType.driver:
        return <i className="fas fa-user-astronaut mr-2" />;
      case UserType.guardian:
        return <i className="fas fa-user-friends mr-2" />;
      case UserType.orderer:
        return <i className="fa fa-smile mr-2" />;
      case UserType.restaurant:
        return <i className="fas fa-utensils mr-2" />;
      default:
        throw unhandledCase(this);
    }
  }

  get imgSrc() {
    switch (this) {
      case UserType.admin:
        return profileAdmin;
      case UserType.customer:
        return profile;
      case UserType.driver:
        return profileDriver;
      case UserType.guardian:
        return profileGuardian;
      case UserType.orderer:
        return profileOrderer;
      case UserType.restaurant:
        return profileRestaurant;
      default:
        throw unhandledCase(this);
    }
  }

  get title() {
    switch (this) {
      case UserType.admin:
        return 'Admin';
      case UserType.customer:
        return 'Customer';
      case UserType.driver:
        return 'Driver';
      case UserType.guardian:
        return 'Guardian';
      case UserType.orderer:
        return 'Delivery customer';
      case UserType.restaurant:
        return 'Restaurant';
      default:
        throw unhandledCase(this);
    }
  }
}

UserType.admin = new UserType('ADMIN');
UserType.customer = new UserType('CUSTOMER');
UserType.driver = new UserType('DRIVER');
UserType.guardian = new UserType('GUARDIAN');
UserType.orderer = new UserType('ORDERER');
UserType.restaurant = new UserType('RESTAURANT');
