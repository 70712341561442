class CreateTokenRequest {
  constructor(streamType, streamMemberType, tripIdOrDriverId, accessToken = null) {
    this.streamType = streamType.rawValue;
    this.streamMemberType = streamMemberType.rawValue;
    this.tripIdOrDriverId = tripIdOrDriverId;
    this.accessToken = accessToken;
  }
}

export default CreateTokenRequest;
