export function join<T, U>(array: T[] | undefined, separator: U | ((index: number) => U)): Array<T | U> | undefined {
  const joinedArray = new Array<T | U>();

  if (!array) return undefined;

  const lastIndex = array.length - 1;

  array.forEach((element, index) => {
    joinedArray.push(element);
    if (index !== lastIndex) {
      joinedArray.push(separator instanceof Function ? separator(index + 1) : separator);
    }
  });

  return joinedArray;
}
