import pluralize from 'pluralize';
import {useMemo} from 'react';
import CoordinatesArray from 'wave-common/lib/models/CoordinatesArray';
import {latLng} from '../components/GoogleMaps/LatLng+additions';
import MapContents from '../components/GoogleMaps/MapContents';
import Trip from '../models/scoopm/Trip';

export default function useMapContentsForTrips(trips?: Trip[]) {
  return useMemo(() => {
    if (!trips || !trips.length) {
      return undefined;
    } else {
      if (trips[0].locations.length === 1) {
        const id: any = 'JOB_BOUNDS';
        const latLngObject = latLng(trips[0].locations[0].coordinates as CoordinatesArray);
        const zoom = 13;
        const name = trips[0].simplestTitle();
        return new MapContents.Coordinates({
          id,
          latLng: latLngObject,
          zoom,
          name,
        });
      } else {
        const id = 'JOBS_BOUNDS';
        const latLngBoundsArray = trips.map(trip => trip.latLngBounds());
        const latLngBounds = latLngBoundsArray.reduce(
          (previousValue, currentValue) => previousValue.union(currentValue),
          latLngBoundsArray[0],
        );
        const name =
          trips.length === 1 ? trips[0].simplestTitle() : `${trips.length} ${pluralize('job', trips.length)}`;
        const padded = true;
        return new MapContents.Bounds({
          id: id as any,
          latLngBounds,
          name: name as any,
          padded,
        });
      }
    }
  }, [trips]);
}
