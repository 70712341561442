import WaveCommonUser from 'wave-common/lib/models/User';
import HttpReference from '../platform/HttpReference';
import Driver from './Driver';
import User from './User';
import Emails from './UsersEmails';

export default class Users extends HttpReference {
  emails: Emails;
  driver: Driver;

  constructor(path: string, parent: HttpReference) {
    super(path, parent);

    this.emails = new Emails('emails', this);
    this.driver = new Driver('driver', this);
  }

  get childReferenceType() {
    return User;
  }

  create(email: string, password: string) {
    return this.request({method: 'POST', data: {email, password}});
  }

  list(params: {email?: string}) {
    return this.request<(Pick<WaveCommonUser, 'id'> & Partial<WaveCommonUser>)[]>({params});
  }
}
