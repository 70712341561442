import { environmentVariable } from '../Environment';

export default function logPromise<T>(label: string, promise: Promise<T>) {
  console.log(`START: ${label}...`);
  return promise.then(
    result => {
      console.log(
        `FINISH: ${label}`,
        environmentVariable('ENVIRONMENT') === 'production' ? (typeof result === 'object' ? 'OBJECT' : result) : result,
      );
      return result;
    },
    error => {
      console.error(`ERROR: ${label}`, error.message);
      throw error;
    },
  );
}
