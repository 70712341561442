import React, {useEffect, useState} from 'react';
import GoogleMap from '../../components/GoogleMaps/GoogleMap';
import {latLng} from '../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../components/GoogleMaps/MapContents';
import Marker from '../../components/GoogleMaps/Marker';
import unwrap from '../../functions/unwrap';

export default function LocationMap({location}) {
  const [markers, setMarkers] = useState(null);
  const [mapContents, setMapContents] = useState();

  // location --> markers and map contents

  useEffect(() => {
    setMarkers(
      unwrap(location, location => [
        new Marker({
          id: 'LOCATION',
          position: latLng(location.coordinates),
          icon: false,
          infoWindowContent: `<div>
                    <h6>${location.name || 'No name'}</h6>
                    <p>${location.address}</p>
                    <p class="mb-0">${unwrap(location.coordinates, coordinates => coordinates.join(', '), '')}</p>
                </div>`,
        }),
      ]),
    );

    setMapContents(
      unwrap(
        location,
        location =>
          new MapContents.Coordinates({
            id: 'COORDS',
            name: location.name,
            latLng: latLng(location.coordinates),
            zoom: 12,
          }),
      ) ?? MapContents.Bounds.unitedStates(),
    );
  }, [location]);

  return (
    <div style={{height: '19rem'}} className="mt-2">
      <GoogleMap
        markers={markers}
        mapContents={mapContents}
        fullscreenControl={false}
        streetViewControl={false}
        zoomControl={false}
        mapTypeControl={false}
        // useDefaultMapStyles={}
        rounded
      />
    </div>
  );
}
