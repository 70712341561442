import React from 'react';
import {useLocation} from 'react-router-dom';
import FontAwesome from './FontAwesome';

export default function Anchor({id, showHint = false}) {
  const location = useLocation();

  return (
    <>
      <div id={id} name={id} className="anchor" />
      {showHint && location.hash.slice(1) === id && (
        <div className="alert alert-primary">
          The link you selected points here <FontAwesome.ArrowDownSolid className="text-" />
        </div>
      )}
    </>
  );
}
