import React, {useMemo} from 'react';
import {useStatus} from 'wave-common';
import {vehicleYearList} from 'wave-common/lib/controllers/VehicleYear';
import FirestoreDataSource from '../../data-sources/FirestoreDataSource';
import unwrap from '../../functions/unwrap';
import InvalidFeedback from './InvalidFeedback';
import PropertyInputProps from './PropertyInputProps';

export default function VehicleYearInput({
  id,
  property,
  value,
  onChange,
  validationError,
  disabled,
}: PropertyInputProps) {
  // render

  const {
    isPending,
    error,
    value: years,
  } = useStatus(
    React as any,
    useMemo(() => vehicleYearList(FirestoreDataSource), []),
  );

  return (
    <>
      <select
        name={id}
        id={id}
        value={value || ''}
        onChange={event => onChange(Number(event.target.value))}
        className={'custom-select ' + unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid'), '')}
        disabled={Boolean(isPending || error || disabled)}>
        <option value="">{property.isOptional ? 'None' : 'Select...'}</option>
        {years?.map(year => (
          <option key={year.value} value={year.value}>
            {year.value}
          </option>
        ))}
      </select>
      <InvalidFeedback error={validationError} />
    </>
  );
}
