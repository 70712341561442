import capitalize from 'lodash/capitalize';
import snakeCase from 'lodash/snakeCase';
import React, {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import FirestoreModelDisplay from '../../schemas/components/FirestoreModelDisplay';
import FirestoreModelForm from '../../schemas/components/FirestoreModelForm';
import FirestoreModelTable from '../../schemas/components/FirestoreModelTable';
import Route from './Route';

export default class FirestoreSchemaRoute extends Route {
  constructor({schema, Icon, requiredClaims, allowAdding = true}) {
    const children = tables(schema);
    if (allowAdding) {
      children.push(
        new Route({
          name: `Add ${schema.userFacingName()}`,
          path: 'add',
          Component: () => {
            const {search} = useLocation();
            const model = useMemo(
              () =>
                Object.assign(
                  {},
                  ...Array.from(new URLSearchParams(search).entries(), ([key, value]) => ({[key]: value})),
                ),
              [search],
            );
            return <FirestoreModelForm schema={schema} model={model} hadQueryValues={search} />;
          },
        }),
      );
    }
    children.push(
      new Route({
        name: capitalize(schema.userFacingName()),
        path: ':id',
        hideFromMenu: true,
        Component: ({match}) => <FirestoreModelDisplay id={match.params.id} schema={schema} />,
      }),
    );
    super({
      path: schema.collection(),
      name: capitalize(schema.userFacingName()) + 's',
      Icon,
      requiredClaims,
      children: children,
    });
  }
}

function tables(schema) {
  let tables = [
    new Route({
      name: 'All',
      exact: true,
      Component: () => <FirestoreModelTable schema={schema} />,
    }),
  ];
  if (schema.queries) {
    schema.queries.forEach(query => {
      tables.push(
        new Route({
          name: query.name,
          path: snakeCase(query.name),
          exact: true,
          Component: () => <FirestoreModelTable schema={schema} selectedQuery={query} />,
        }),
      );
    });
  }
  return tables;
}
