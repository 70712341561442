import {AxiosResponse} from 'axios';
import firebase from 'firebase/app';
import EarningsModel from '../../models/scoopm/Earnings';
import HttpReference from '../platform/HttpReference';

export default class Earnings extends HttpReference {
  async get(driverId: string | undefined, tripId: string | undefined): Promise<AxiosResponse<EarningsModel>> {
    const user = firebase.auth().currentUser;
    if (!user) throw new Error('Unauthenticated');
    const token = await user.getIdToken();
    return this.request({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        driverId,
        tripId,
      },
    });
  }
}
