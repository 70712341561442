import firebase from 'firebase/app';
import first from 'lodash/first';
import {useEffect, useMemo, useState} from 'react';
import Type from '../functions/Type';
import unwrap from '../functions/unwrap';
import usePrevious from './usePrevious';

interface UseRealtimeDatabaseOptions<T, Data> {
  deserialize?: (value: unknown, id: unknown) => Data;
  path?: string | null;
  eventType?: firebase.database.EventType;
  Model?: Type<T>;
  isArray?: boolean;
  reverse?: boolean;
  orderByChild?: string;
  equalTo?: string;
  startAt?: string;
  endAt?: string;
  limitToLast?: number;
  limitToFirst?: number;
  addKeyToModel?: boolean;
  _firebaseDatabase?: any;
}

export default function useRealtimeDatabase<T, Data = unknown>(options: UseRealtimeDatabaseOptions<T, Data>) {
  // destructure options

  const {
    deserialize: customDeserialize,
    path,
    eventType = 'value',
    Model = Object,
    isArray,
    reverse,
    orderByChild,
    equalTo,
    startAt,
    endAt,
    limitToLast,
    limitToFirst,
    addKeyToModel = true,
  } = options;
  const firebaseDatabase = useMemo(() => options._firebaseDatabase ?? firebase.database(), [options._firebaseDatabase]);

  // state

  const reference = useMemo(
    () =>
      createNewReference(
        firebaseDatabase,
        path || undefined,
        orderByChild,
        equalTo,
        startAt,
        endAt,
        limitToLast,
        limitToFirst,
      ),
    [firebaseDatabase, path, orderByChild, equalTo, startAt, endAt, limitToLast, limitToFirst],
  );

  const [data, setData] = useState<T | undefined | null>();
  const [error, setError] = useState<Error | undefined>();

  // memos

  const previousPath = usePrevious(path, undefined);
  const isLoading = useMemo(
    () => Boolean(path !== previousPath || (path && data === undefined && error === undefined)),
    [path, previousPath, data, error],
  );
  const result = useMemo(
    () =>
      new Result(
        reference,
        isLoading,
        data,
        unwrap(data, data => (data as any).id),
        error,
      ),
    [reference, isLoading, data, error],
  );

  // effect: reference -> observe

  useEffect(() => {
    setData(undefined);
    setError(undefined);

    if (reference) {
      console.log('Listening for events...');
      const listener = reference.on(
        eventType,
        snapshot => {
          if (snapshot.exists()) {
            if (customDeserialize) {
              try {
                customDeserialize(snapshot.val(), snapshot.key);
              } catch (error: any) {
                setError(error);
                return;
              }
            }
            let data: T;
            if (isArray) {
              data = [] as any;
              snapshot.forEach(childSnapshot => {
                const model: T = deserialise<T>(Model as Type<T>, childSnapshot, true);
                (data as any).push(model);
              });
              if (reverse) (data as any).reverse();
            } else {
              data = deserialise<T>(Model as Type<T>, snapshot, addKeyToModel);
            }
            // console.log(`Got "${eventType}" event at "${reference.toString()}", deserialized as ${isArray ? 'array of ' : ''}"${unwrap(Model, m => m.name, 'raw value')}"`, data)
            setData(data);
          } else {
            console.log(`Got "${eventType}" event at "${reference.toString()}", null`);
            setData(null);
          }
        },
        (error: Error) => {
          console.log(`Error listening for "${eventType}" events at "${reference.toString()}"`, error);
          setError(error);
        },
      );
      return () => {
        console.log(`Removing listener at ${reference.toString()}`);
        reference.off(eventType, listener);
      };
    }
  }, [reference, eventType, Model, addKeyToModel, isArray, reverse]);

  return result;
}

export class Result<T> {
  reference?: firebase.database.Reference;
  isLoading?: boolean;
  data?: T;
  key?: string;
  error?: Error;

  constructor(reference?: firebase.database.Reference, isLoading?: boolean, data?: T, key?: string, error?: Error) {
    this.reference = reference;
    this.isLoading = isLoading;
    this.data = data;
    this.key = key;
    this.error = error;
  }

  async set(value: any) {
    console.log(`Setting value of ${this.reference}`);
    try {
      await this.reference?.set(value);
    } catch (error) {
      console.error(`Error setting ${this.reference}`, error);
      throw error;
    }
    console.log(`Set ${this.reference}`);
  }

  async update(value: any) {
    console.log(`Updating value of ${this.reference}`, this);
    try {
      await this.reference?.update(value);
    } catch (error) {
      console.error(`Error updating ${this.reference}`, error);
      throw error;
    }
    console.log(`Updated ${this.reference}`);
  }

  async remove() {
    console.log(`Removing value of ${this.reference}`);
    try {
      await this.reference?.remove();
    } catch (error) {
      console.error(`Error removing ${this.reference}`, error);
      throw error;
    }
    console.log(`Removed ${this.reference}`);
  }
}

function createNewReference(
  _firebaseDatabase: any,
  path?: string,
  orderByChild?: string,
  equalTo?: string,
  startAt?: string,
  endAt?: string,
  limitToLast?: number,
  limitToFirst?: number,
): firebase.database.Reference | undefined {
  if (!path) return undefined;
  let reference = _firebaseDatabase.ref(path);
  if (orderByChild) reference = reference.orderByChild(orderByChild);
  if (equalTo) reference = reference.equalTo(equalTo);
  if (startAt) reference = reference.startAt(startAt);
  if (endAt) reference = reference.endAt(endAt);
  if (limitToLast) reference = reference.limitToLast(limitToLast);
  if (limitToFirst) reference = reference.limitToFirst(limitToFirst);
  return reference;
}

function deserialise<T>(Model: Type<T>, snapshot: firebase.database.DataSnapshot, addKeyToModel: boolean): T {
  const val = snapshot.val();

  if (typeof val === 'object') {
    const model = Object.create(Model.prototype);
    const data = snapshot.val();
    let keyObject = null;
    if (addKeyToModel && !unwrap(first(Object.keys(val)), key => key[0] === '-')) {
      keyObject = {
        key: snapshot.key,
        id: snapshot.key,
      };
    }

    return Object.assign(model, data, keyObject);
  } else {
    return val;
  }
}
