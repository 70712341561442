import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import FirebaseTable from '../../components/FirebaseTable';
import unwrap from '../../functions/unwrap';
import PropertyType from '../types/PropertyType';

export default function RealtimeDatabaseModelTable({schema}) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    Promise.all(
      schema.properties.map(async property => {
        let value = null;
        if (PropertyType.isObjectId(property.type)) {
          const objectEntries = await PropertyType.fetchObjectEntries(property);
          value = model =>
            unwrap(
              objectEntries.find(([id, name]) => id === model[property.name]),
              value => value[1],
            );
        } else {
          value = model => PropertyType.formatForTable(property, model[property.name]);
        }
        return {
          heading: property.userFacingName,
          value,
        };
      }),
    ).then(setColumns);
  }, [schema.properties]);

  return (
    <FirebaseTable
      path={schema.collection()}
      searchTerm={JSON.stringify}
      link={coupon => `/admin/${schema.collection()}/${coupon.key}`}
      columns={columns}
      additionalBarComponents={
        <Link to={`/admin/${schema.collection()}/add`} className="btn bg-white">
          <i className="fa fa-plus" /> Add
        </Link>
      }
    />
  );
}
