import React, {useEffect, useState} from 'react';
import {FormControl} from 'react-bootstrap';
import DownloadOnAppStore from '../../components/app-download-buttons/DownloadOnAppStore';
import GetItOnGooglePlay from '../../components/app-download-buttons/GetItOnGooglePlay';
import ValidateableInput from '../../components/bootstrap/ValidateableInput';
import unwrap from '../../functions/unwrap';
import useLocalStorage from '../../hooks/useLocalStorage';
import useUserData from '../../hooks/useUserData';
import User from '../../models/scoopm/User';
import UserType from '../../models/scoopm/UserType';

export default function Name() {
  const [didShowGetStartedDialog, setDidShowGetStartedDialog] = useLocalStorage(
    'DID_SHOW_GET_STARTED_DIALOG',
    false as any,
  );

  const {data, type, update} = useUserData() as {
    data: User | null;
    type: UserType | null;
    update: any;
  };

  const [firstName, setFirstName] = useState('');
  const [nickName, setNickName] = useState('');
  const [lastName, setLastName] = useState('');
  const [profileHasChanges, setProfileHasChanges] = useState(false);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [validation, setValidation] = useState<{
    firstName: boolean;
    lastName: boolean;
    nickName: boolean;
  }>();

  useEffect(() => {
    setFirstName(unwrap(data, (data: User) => data.firstName) || '');
    setNickName(unwrap(data, (data: User) => data.nickName) || '');
    setLastName(unwrap(data, (data: User) => data.lastName) || '');
  }, [data]);

  function saveMyDetails(event: React.SyntheticEvent) {
    event.preventDefault();

    // validate

    const validation = {
      firstName: Boolean(firstName.trim()),
      lastName: Boolean(lastName.trim()),
      nickName: true,
    };

    setValidation(validation);

    if (Object.values(validation).includes(false)) {
      return;
    }

    if (!update) return;
    setIsSavingChanges(true);
    update({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      nickName: nickName ? nickName.trim() : null,
    }).finally(() => {
      setIsSavingChanges(false);
      setProfileHasChanges(false);
    });
  }

  return (
    <div>
      {type === UserType.driver && !didShowGetStartedDialog && (
        <div className="position-relative jumbotron bg-blue text-white mt-1 mb-4">
          <button
            onClick={event => setDidShowGetStartedDialog(true)}
            className="btn position-absolute"
            style={{top: '1rem', right: '1rem'}}>
            <i className="fas fa-times" />
          </button>
          <h1 className="display-4">Start driving</h1>
          <p className="lead">
            To get started, download the Wave Driver app from the Google Play Store or the App Store{' '}
          </p>
          <GetItOnGooglePlay driver />
          <DownloadOnAppStore driver />
        </div>
      )}

      <form onSubmit={saveMyDetails}>
        <div className="row form-group">
          <div className="col-sm">
            <label htmlFor="firstNameInput">First name</label>
            <ValidateableInput
              elementType={FormControl}
              id="firstNameInput"
              type="text"
              value={firstName}
              onChange={(event: any) => {
                setProfileHasChanges(true);
                setFirstName(event.target.value);
              }}
              isValid={unwrap(validation, validation => validation.firstName)}
              autoComplete="given-name"
              disabled={isSavingChanges}
            />
          </div>
          <div className="col-sm">
            <label htmlFor="lastNameInput">Last name</label>
            <ValidateableInput
              elementType={FormControl}
              id="lastNameInput"
              type="text"
              value={lastName}
              onChange={(event: any) => {
                setProfileHasChanges(true);
                setLastName(event.target.value);
              }}
              isValid={unwrap(validation, validation => validation.lastName)}
              autoComplete="family-name"
              disabled={isSavingChanges}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-sm">
            <label htmlFor="nickNameInput">Nick name</label>
            <ValidateableInput
              elementType={FormControl}
              id="nickNameInput"
              type="text"
              value={nickName}
              onChange={(event: any) => {
                setProfileHasChanges(true);
                setNickName(event.target.value);
              }}
              isValid={unwrap(validation, validation => validation.nickName)}
              placeholder="None"
              autoComplete="nick-name"
              disabled={isSavingChanges}
            />
          </div>
          <div className="col-sm d-flex align-items-end justify-content-end">
            <input
              type="submit"
              value="Save changes"
              className="btn btn-primary"
              disabled={!profileHasChanges || isSavingChanges}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
