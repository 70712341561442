import {MutableRefObject, useEffect, useState} from 'react';

export default function useElementSize(element: MutableRefObject<HTMLElement | undefined>) {
  const [size, setSize] = useState<Size>();

  useEffect(() => {
    const capturedElement = element.current;
    if (capturedElement) {
      const observer = new window.ResizeObserver(() => setSize(elementSize(capturedElement)));
      observer.observe(capturedElement);
      return () => observer.unobserve(capturedElement);
    }
  }, [element]);

  return size;
}

interface Size {
  width: number;
  height: number;
}

const elementSize = (element: HTMLElement) => ({width: element.offsetWidth, height: element.offsetHeight});
