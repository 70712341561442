import find from 'lodash/find';
import {useMemo} from 'react';
import unwrap from '../functions/unwrap';

interface ObjectWithId {
  id?: string;
}

export default function useObjectById<T extends ObjectWithId>(
  objects: T[] | null | undefined,
  id?: string,
  fallback?: T,
) {
  return useMemo(
    () => unwrap(objects, objects => find(objects, object => object.id === id), fallback),
    [objects, id, fallback],
  );
}
