import unhandledCase from '../../../functions/unhandledCase';
import StringEnum from '../../../models/base/StringEnum';
import SelectOption from '../../../models/SelectOption';

export default class Sort extends StringEnum {
  // static createdAtAscending = 'CREATED_AT_ASCENDING'
  static createdAtDescending = 'CREATED_AT_DESCENDING';
  static dropOffAscending = 'DROP_OFF_ASCENDING';
  static dropOffDescending = 'DROP_OFF_DESCENDING';

  static options = Sort.allCases().map(sort => new SelectOption(sort, Sort.text(sort)));

  static text(sort: Sort) {
    switch (sort) {
      // case Sort.createdAtAscending: return 'Oldest'
      case Sort.createdAtDescending:
        return 'Newest (all jobs)';
      case Sort.dropOffAscending:
        return 'Earliest (scheduled only)';
      case Sort.dropOffDescending:
        return 'Latest (scheduled only)';
    }
  }

  static fieldPath(sort: Sort): string {
    switch (sort) {
      case Sort.createdAtDescending:
        return 'createdAt';
      case Sort.dropOffAscending:
        return 'firstScheduledTimestampMs';
      case Sort.dropOffDescending:
        return 'firstScheduledTimestampMs';
      default:
        throw unhandledCase(sort);
    }
  }

  static directionStr(sort: Sort): 'asc' | 'desc' {
    switch (sort) {
      case Sort.createdAtDescending:
        return 'asc';
      case Sort.dropOffAscending:
        return 'asc';
      case Sort.dropOffDescending:
        return 'desc';
      default:
        throw unhandledCase(sort);
    }
  }
}
