export default class StringEnum extends String {
  /**
   *
   * @param {String} rawValue
   * @returns {StringEnum?} The corresponding case, if any
   */
  static for(rawValue) {
    return this.allCases().find(case_ => case_ === rawValue);
  }

  static allCases() {
    return Object.values(this).filter(value => typeof value === 'string');
  }
}
