import React from 'react';
import Delay from '../../components/Delay';
import Spinner from '../../components/Spinner';
import {Card} from './Card';

export function TitleRow({model}) {
  return (
    <Card>
      <h5 className="mb-0 text-gray-500">
        {model ? (
          model.customerFacingTitle()
        ) : (
          <span>
            <Delay>
              <Spinner />
            </Delay>
            &nbsp;
          </span>
        )}
      </h5>
      <h2 className="mb-0">{model ? model.statusTitle() : <span>&nbsp;</span>}</h2>
    </Card>
  );
}
