import firebase from 'firebase/app';
import React from 'react';
import {useHistory} from 'react-router-dom';
import cleanForFirebase from '../../functions/cleanForFirebase';
import SchemaForm from './SchemaForm';

export default function RealtimeDatabaseModelForm({
  schema,
  id = undefined,
  model = null,
  onSave = null,
  onCancel = null,
}) {
  const history = useHistory();

  const onSaveInternal = model => {
    console.log(`Setting "${schema.name}"...`, model);

    const reference = id
      ? firebase.database().ref(schema.collection()).child(id)
      : firebase.database().ref(schema.collection()).push();

    return reference.set(cleanForFirebase(model)).then(
      () => {
        console.info(`Set "${schema.name}"`);

        if (onSave) {
          onSave();
        } else {
          history.push(`/admin/${schema.collection()}/${reference.id}`);
        }
      },
      error => {
        console.error(`Error setting "${schema.name}"`, error);

        throw new Error('Error saving data');
      },
    );
  };

  return <SchemaForm schema={schema} onSave={onSaveInternal} defaultValue={model} onCancel={onCancel} />;
}
