import SelectOption from './models/SelectOption';

export const Countries = {
  US: 'United States',
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herzegowina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, the Democratic Republic of the',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Cote d'Ivoire",
  HR: 'Croatia (Hrvatska)',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  TP: 'East Timor',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  FX: 'France, Metropolitan',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard and McDonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea, Democratic People's Republic of",
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libyan Arab Jamahiriya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macau',
  MK: 'Macedonia, The Former Yugoslav Republic of',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States of',
  MD: 'Moldova, Republic of',
  MC: 'Monaco',
  MN: 'Mongolia',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia (Slovak Republic)',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SH: 'St. Helena',
  PM: 'St. Pierre and Miquelon',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen Islands',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan, Province of China',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna Islands',
  EH: 'Western Sahara',
  YE: 'Yemen',
  YU: 'Yugoslavia',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

export const CountryOptions = [
  {value: 'US', text: 'United States'},
  {value: 'AF', text: 'Afghanistan'},
  {value: 'AL', text: 'Albania'},
  {value: 'DZ', text: 'Algeria'},
  {value: 'AS', text: 'American Samoa'},
  {value: 'AD', text: 'Andorra'},
  {value: 'AO', text: 'Angola'},
  {value: 'AI', text: 'Anguilla'},
  {value: 'AQ', text: 'Antarctica'},
  {value: 'AG', text: 'Antigua and Barbuda'},
  {value: 'AR', text: 'Argentina'},
  {value: 'AM', text: 'Armenia'},
  {value: 'AW', text: 'Aruba'},
  {value: 'AU', text: 'Australia'},
  {value: 'AT', text: 'Austria'},
  {value: 'AZ', text: 'Azerbaijan'},
  {value: 'BS', text: 'Bahamas'},
  {value: 'BH', text: 'Bahrain'},
  {value: 'BD', text: 'Bangladesh'},
  {value: 'BB', text: 'Barbados'},
  {value: 'BY', text: 'Belarus'},
  {value: 'BE', text: 'Belgium'},
  {value: 'BZ', text: 'Belize'},
  {value: 'BJ', text: 'Benin'},
  {value: 'BM', text: 'Bermuda'},
  {value: 'BT', text: 'Bhutan'},
  {value: 'BO', text: 'Bolivia'},
  {value: 'BA', text: 'Bosnia and Herzegowina'},
  {value: 'BW', text: 'Botswana'},
  {value: 'BV', text: 'Bouvet Island'},
  {value: 'BR', text: 'Brazil'},
  {value: 'IO', text: 'British Indian Ocean Territory'},
  {value: 'BN', text: 'Brunei Darussalam'},
  {value: 'BG', text: 'Bulgaria'},
  {value: 'BF', text: 'Burkina Faso'},
  {value: 'BI', text: 'Burundi'},
  {value: 'KH', text: 'Cambodia'},
  {value: 'CM', text: 'Cameroon'},
  {value: 'CA', text: 'Canada'},
  {value: 'CV', text: 'Cape Verde'},
  {value: 'KY', text: 'Cayman Islands'},
  {value: 'CF', text: 'Central African Republic'},
  {value: 'TD', text: 'Chad'},
  {value: 'CL', text: 'Chile'},
  {value: 'CN', text: 'China'},
  {value: 'CX', text: 'Christmas Island'},
  {value: 'CC', text: 'Cocos (Keeling) Islands'},
  {value: 'CO', text: 'Colombia'},
  {value: 'KM', text: 'Comoros'},
  {value: 'CG', text: 'Congo'},
  {value: 'CD', text: 'Congo, the Democratic Republic of the'},
  {value: 'CK', text: 'Cook Islands'},
  {value: 'CR', text: 'Costa Rica'},
  {value: 'CI', text: "Cote d'Ivoire"},
  {value: 'HR', text: 'Croatia (Hrvatska)'},
  {value: 'CU', text: 'Cuba'},
  {value: 'CY', text: 'Cyprus'},
  {value: 'CZ', text: 'Czech Republic'},
  {value: 'DK', text: 'Denmark'},
  {value: 'DJ', text: 'Djibouti'},
  {value: 'DM', text: 'Dominica'},
  {value: 'DO', text: 'Dominican Republic'},
  {value: 'TP', text: 'East Timor'},
  {value: 'EC', text: 'Ecuador'},
  {value: 'EG', text: 'Egypt'},
  {value: 'SV', text: 'El Salvador'},
  {value: 'GQ', text: 'Equatorial Guinea'},
  {value: 'ER', text: 'Eritrea'},
  {value: 'EE', text: 'Estonia'},
  {value: 'ET', text: 'Ethiopia'},
  {value: 'FK', text: 'Falkland Islands (Malvinas)'},
  {value: 'FO', text: 'Faroe Islands'},
  {value: 'FJ', text: 'Fiji'},
  {value: 'FI', text: 'Finland'},
  {value: 'FR', text: 'France'},
  {value: 'FX', text: 'France, Metropolitan'},
  {value: 'GF', text: 'French Guiana'},
  {value: 'PF', text: 'French Polynesia'},
  {value: 'TF', text: 'French Southern Territories'},
  {value: 'GA', text: 'Gabon'},
  {value: 'GM', text: 'Gambia'},
  {value: 'GE', text: 'Georgia'},
  {value: 'DE', text: 'Germany'},
  {value: 'GH', text: 'Ghana'},
  {value: 'GI', text: 'Gibraltar'},
  {value: 'GR', text: 'Greece'},
  {value: 'GL', text: 'Greenland'},
  {value: 'GD', text: 'Grenada'},
  {value: 'GP', text: 'Guadeloupe'},
  {value: 'GU', text: 'Guam'},
  {value: 'GT', text: 'Guatemala'},
  {value: 'GN', text: 'Guinea'},
  {value: 'GW', text: 'Guinea-Bissau'},
  {value: 'GY', text: 'Guyana'},
  {value: 'HT', text: 'Haiti'},
  {value: 'HM', text: 'Heard and McDonald Islands'},
  {value: 'VA', text: 'Holy See (Vatican City State)'},
  {value: 'HN', text: 'Honduras'},
  {value: 'HK', text: 'Hong Kong'},
  {value: 'HU', text: 'Hungary'},
  {value: 'IS', text: 'Iceland'},
  {value: 'IN', text: 'India'},
  {value: 'ID', text: 'Indonesia'},
  {value: 'IR', text: 'Iran (Islamic Republic of)'},
  {value: 'IQ', text: 'Iraq'},
  {value: 'IE', text: 'Ireland'},
  {value: 'IL', text: 'Israel'},
  {value: 'IT', text: 'Italy'},
  {value: 'JM', text: 'Jamaica'},
  {value: 'JP', text: 'Japan'},
  {value: 'JO', text: 'Jordan'},
  {value: 'KZ', text: 'Kazakhstan'},
  {value: 'KE', text: 'Kenya'},
  {value: 'KI', text: 'Kiribati'},
  {value: 'KP', text: "Korea, Democratic People's Republic of"},
  {value: 'KR', text: 'Korea, Republic of'},
  {value: 'KW', text: 'Kuwait'},
  {value: 'KG', text: 'Kyrgyzstan'},
  {value: 'LA', text: "Lao People's Democratic Republic"},
  {value: 'LV', text: 'Latvia'},
  {value: 'LB', text: 'Lebanon'},
  {value: 'LS', text: 'Lesotho'},
  {value: 'LR', text: 'Liberia'},
  {value: 'LY', text: 'Libyan Arab Jamahiriya'},
  {value: 'LI', text: 'Liechtenstein'},
  {value: 'LT', text: 'Lithuania'},
  {value: 'LU', text: 'Luxembourg'},
  {value: 'MO', text: 'Macau'},
  {value: 'MK', text: 'Macedonia, The Former Yugoslav Republic of'},
  {value: 'MG', text: 'Madagascar'},
  {value: 'MW', text: 'Malawi'},
  {value: 'MY', text: 'Malaysia'},
  {value: 'MV', text: 'Maldives'},
  {value: 'ML', text: 'Mali'},
  {value: 'MT', text: 'Malta'},
  {value: 'MH', text: 'Marshall Islands'},
  {value: 'MQ', text: 'Martinique'},
  {value: 'MR', text: 'Mauritania'},
  {value: 'MU', text: 'Mauritius'},
  {value: 'YT', text: 'Mayotte'},
  {value: 'MX', text: 'Mexico'},
  {value: 'FM', text: 'Micronesia, Federated States of'},
  {value: 'MD', text: 'Moldova, Republic of'},
  {value: 'MC', text: 'Monaco'},
  {value: 'MN', text: 'Mongolia'},
  {value: 'MS', text: 'Montserrat'},
  {value: 'MA', text: 'Morocco'},
  {value: 'MZ', text: 'Mozambique'},
  {value: 'MM', text: 'Myanmar'},
  {value: 'NA', text: 'Namibia'},
  {value: 'NR', text: 'Nauru'},
  {value: 'NP', text: 'Nepal'},
  {value: 'NL', text: 'Netherlands'},
  {value: 'AN', text: 'Netherlands Antilles'},
  {value: 'NC', text: 'New Caledonia'},
  {value: 'NZ', text: 'New Zealand'},
  {value: 'NI', text: 'Nicaragua'},
  {value: 'NE', text: 'Niger'},
  {value: 'NG', text: 'Nigeria'},
  {value: 'NU', text: 'Niue'},
  {value: 'NF', text: 'Norfolk Island'},
  {value: 'MP', text: 'Northern Mariana Islands'},
  {value: 'NO', text: 'Norway'},
  {value: 'OM', text: 'Oman'},
  {value: 'PK', text: 'Pakistan'},
  {value: 'PW', text: 'Palau'},
  {value: 'PA', text: 'Panama'},
  {value: 'PG', text: 'Papua New Guinea'},
  {value: 'PY', text: 'Paraguay'},
  {value: 'PE', text: 'Peru'},
  {value: 'PH', text: 'Philippines'},
  {value: 'PN', text: 'Pitcairn'},
  {value: 'PL', text: 'Poland'},
  {value: 'PT', text: 'Portugal'},
  {value: 'PR', text: 'Puerto Rico'},
  {value: 'QA', text: 'Qatar'},
  {value: 'RE', text: 'Reunion'},
  {value: 'RO', text: 'Romania'},
  {value: 'RU', text: 'Russian Federation'},
  {value: 'RW', text: 'Rwanda'},
  {value: 'KN', text: 'Saint Kitts and Nevis'},
  {value: 'LC', text: 'Saint Lucia'},
  {value: 'VC', text: 'Saint Vincent and the Grenadines'},
  {value: 'WS', text: 'Samoa'},
  {value: 'SM', text: 'San Marino'},
  {value: 'ST', text: 'Sao Tome and Principe'},
  {value: 'SA', text: 'Saudi Arabia'},
  {value: 'SN', text: 'Senegal'},
  {value: 'SC', text: 'Seychelles'},
  {value: 'SL', text: 'Sierra Leone'},
  {value: 'SG', text: 'Singapore'},
  {value: 'SK', text: 'Slovakia (Slovak Republic)'},
  {value: 'SI', text: 'Slovenia'},
  {value: 'SB', text: 'Solomon Islands'},
  {value: 'SO', text: 'Somalia'},
  {value: 'ZA', text: 'South Africa'},
  {value: 'GS', text: 'South Georgia and the South Sandwich Islands'},
  {value: 'ES', text: 'Spain'},
  {value: 'LK', text: 'Sri Lanka'},
  {value: 'SH', text: 'St. Helena'},
  {value: 'PM', text: 'St. Pierre and Miquelon'},
  {value: 'SD', text: 'Sudan'},
  {value: 'SR', text: 'Suriname'},
  {value: 'SJ', text: 'Svalbard and Jan Mayen Islands'},
  {value: 'SZ', text: 'Swaziland'},
  {value: 'SE', text: 'Sweden'},
  {value: 'CH', text: 'Switzerland'},
  {value: 'SY', text: 'Syrian Arab Republic'},
  {value: 'TW', text: 'Taiwan, Province of China'},
  {value: 'TJ', text: 'Tajikistan'},
  {value: 'TZ', text: 'Tanzania, United Republic of'},
  {value: 'TH', text: 'Thailand'},
  {value: 'TG', text: 'Togo'},
  {value: 'TK', text: 'Tokelau'},
  {value: 'TO', text: 'Tonga'},
  {value: 'TT', text: 'Trinidad and Tobago'},
  {value: 'TN', text: 'Tunisia'},
  {value: 'TR', text: 'Turkey'},
  {value: 'TM', text: 'Turkmenistan'},
  {value: 'TC', text: 'Turks and Caicos Islands'},
  {value: 'TV', text: 'Tuvalu'},
  {value: 'UG', text: 'Uganda'},
  {value: 'UA', text: 'Ukraine'},
  {value: 'AE', text: 'United Arab Emirates'},
  {value: 'GB', text: 'United Kingdom'},
  {value: 'UM', text: 'United States Minor Outlying Islands'},
  {value: 'UY', text: 'Uruguay'},
  {value: 'UZ', text: 'Uzbekistan'},
  {value: 'VU', text: 'Vanuatu'},
  {value: 'VE', text: 'Venezuela'},
  {value: 'VN', text: 'Viet Nam'},
  {value: 'VG', text: 'Virgin Islands (British)'},
  {value: 'VI', text: 'Virgin Islands (U.S.)'},
  {value: 'WF', text: 'Wallis and Futuna Islands'},
  {value: 'EH', text: 'Western Sahara'},
  {value: 'YE', text: 'Yemen'},
  {value: 'YU', text: 'Yugoslavia'},
  {value: 'ZM', text: 'Zambia'},
  {value: 'ZW', text: 'Zimbabwe'},
];

export const StatesOptions = [
  {value: 'AL', text: 'Alabama'},
  {value: 'AK', text: 'Alaska'},
  {value: 'AZ', text: 'Arizona'},
  {value: 'AR', text: 'Arkansas'},
  {value: 'CA', text: 'California'},
  {value: 'CO', text: 'Colorado'},
  {value: 'CT', text: 'Connecticut'},
  {value: 'DE', text: 'Delaware'},
  {value: 'DC', text: 'Dist. Of Columbia'},
  {value: 'FL', text: 'Florida'},
  {value: 'GA', text: 'Georgia'},
  {value: 'HI', text: 'Hawaii'},
  {value: 'ID', text: 'Idaho'},
  {value: 'IL', text: 'Illinois'},
  {value: 'IN', text: 'Indiana'},
  {value: 'IA', text: 'Iowa'},
  {value: 'KS', text: 'Kansas'},
  {value: 'KY', text: 'Kentucky'},
  {value: 'LA', text: 'Louisiana'},
  {value: 'ME', text: 'Maine'},
  {value: 'MD', text: 'Maryland'},
  {value: 'MA', text: 'Massachusetts'},
  {value: 'MI', text: 'Michigan'},
  {value: 'MN', text: 'Minnesota'},
  {value: 'MS', text: 'Mississippi'},
  {value: 'MO', text: 'Missouri'},
  {value: 'MT', text: 'Montana'},
  {value: 'NE', text: 'Nebraska'},
  {value: 'NV', text: 'Nevada'},
  {value: 'NH', text: 'New Hampshire'},
  {value: 'NJ', text: 'New Jersey'},
  {value: 'NM', text: 'New Mexico'},
  {value: 'NY', text: 'New York'},
  {value: 'NC', text: 'North Carolina'},
  {value: 'ND', text: 'North Dakota'},
  {value: 'OH', text: 'Ohio'},
  {value: 'OK', text: 'Oklahoma'},
  {value: 'OR', text: 'Oregon'},
  {value: 'PA', text: 'Pennsylvania'},
  {value: 'RI', text: 'Rhode Island'},
  {value: 'SC', text: 'South Carolina'},
  {value: 'SD', text: 'South Dakota'},
  {value: 'TN', text: 'Tennessee'},
  {value: 'TX', text: 'Texas'},
  {value: 'UT', text: 'Utah'},
  {value: 'VT', text: 'Vermont'},
  {value: 'VA', text: 'Virginia'},
  {value: 'WA', text: 'Washington'},
  {value: 'WV', text: 'West Virginia'},
  {value: 'WI', text: 'Wisconsin'},
  {value: 'WY', text: 'Wyoming'},
];

export const statesOptionsLimited = [
  // new SelectOption('AL', 'Alabama'),
  // new SelectOption('AK', 'Alaska'),
  // new SelectOption('AZ', 'Arizona'),
  // new SelectOption('AR', 'Arkansas'),
  new SelectOption('CA', 'California'),
  // new SelectOption('CO', 'Colorado'),
  // new SelectOption('CT', 'Connecticut'),
  // new SelectOption('DE', 'Delaware'),
  new SelectOption('DC', 'Dist. Of Columbia'),
  new SelectOption('FL', 'Florida'),
  new SelectOption('GA', 'Georgia'),
  // new SelectOption('HI', 'Hawaii'),
  // new SelectOption('ID', 'Idaho'),
  new SelectOption('IL', 'Illinois'),
  // new SelectOption('IN', 'Indiana'),
  // new SelectOption('IA', 'Iowa'),
  // new SelectOption('KS', 'Kansas'),
  // new SelectOption('KY', 'Kentucky'),
  new SelectOption('LA', 'Louisiana'),
  // new SelectOption('ME', 'Maine'),
  // new SelectOption('MD', 'Maryland'),
  // new SelectOption('MA', 'Massachusetts'),
  // new SelectOption('MI', 'Michigan'),
  // new SelectOption('MN', 'Minnesota'),
  // new SelectOption('MS', 'Mississippi'),
  // new SelectOption('MO', 'Missouri'),
  // new SelectOption('MT', 'Montana'),
  // new SelectOption('NE', 'Nebraska'),
  new SelectOption('NV', 'Nevada'),
  // new SelectOption('NH', 'New Hampshire'),
  // new SelectOption('NJ', 'New Jersey'),
  // new SelectOption('NM', 'New Mexico'),
  new SelectOption('NY', 'New York'),
  new SelectOption('NC', 'North Carolina'),
  // new SelectOption('ND', 'North Dakota'),
  // new SelectOption('OH', 'Ohio'),
  // new SelectOption('OK', 'Oklahoma'),
  // new SelectOption('OR', 'Oregon'),
  // new SelectOption('PA', 'Pennsylvania'),
  // new SelectOption('RI', 'Rhode Island'),
  new SelectOption('SC', 'South Carolina'),
  // new SelectOption('SD', 'South Dakota'),
  // new SelectOption('TN', 'Tennessee'),
  new SelectOption('TX', 'Texas'),
  // new SelectOption('UT', 'Utah'),
  // new SelectOption('VT', 'Vermont'),
  new SelectOption('VA', 'Virginia'),
  // new SelectOption('WA', 'Washington'),
  // new SelectOption('WV', 'West Virginia'),
  // new SelectOption('WI', 'Wisconsin'),
  // new SelectOption('WY', 'Wyoming'),
];
