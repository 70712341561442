import sortBy from 'lodash/sortBy';
import React from 'react';
import {useHistory} from 'react-router-dom';
import unwrap from '../../../functions/unwrap';
import useTripDirections from '../../../hooks/useTripDirections';

export default function TripDirectionsSelect({value = undefined, tripId, directionsSnapshot = null}) {
  const history = useHistory();

  const directions = useTripDirections(tripId);

  return unwrap(directions || unwrap(directionsSnapshot, snap => snap.docs), docs => (
    <select
      value={value}
      onChange={event => event.target.value && history.push(`/admin/directions/${event.target.value}`)}
      className="custom-select w-auto">
      <option value="">Directions ({docs.length} items)</option>

      {sortBy(docs).map((directions, i) => (
        <option key={directions.id} value={directions.id}>
          Directions #{i + 1}
        </option>
      ))}
    </select>
  ));
}
