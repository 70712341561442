import MenuIcon from '@material-ui/icons/Menu';
import firebase from 'firebase/app';
import capitalize from 'lodash/capitalize';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Constants from '../Constants';
import {AdditionalNavbarContentContextProvider} from '../contexts/AdditionalNavbarContentContext';
import TitleContext from '../contexts/TitleContext';
import {
  environmentAll,
  environmentUrlForEnvironment,
  environmentVariable,
  environmentVisibleInProduction,
} from '../Environment';
import joinClassNames from '../functions/joinClassNames';
import unwrap from '../functions/unwrap';
import ClaimsGuard from '../guards/ClaimsGuard';
import useLocalStorage from '../hooks/useLocalStorage';
import useScreenIsSmall from '../hooks/useScreenIsSmall';
import CustomClaim from '../models/scoopm/CustomClaim';
import CurrentRoutesContext from '../routes/router/CurrentRoutesContext';
import MenuItem from '../routes/router/MenuItem';
import ModifiedRootContext from '../routes/router/ModifiedRootContext';

export default function AdminLayout({children}) {
  const [isMenuOpen, setIsMenuOpen] = useLocalStorage('ADMIN_MENU_IS_OPEN', true);

  const [additionalNavbarContent, setAdditionalNavbarContent] = useState();

  const screenIsSmall = useScreenIsSmall();

  // EFFECT: screen is small --> close menu

  useEffect(() => {
    if (screenIsSmall) {
      setIsMenuOpen(false);
    }
  }, [screenIsSmall, setIsMenuOpen]);

  //

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  // RENDER

  return (
    <div className="h-100 w-100 d-flex flex-column overflow-scroll">
      {/* nav bar */}

      <NavBar onClickMenuButton={toggleMenu} additionalNavbarContent={additionalNavbarContent} />

      {/* below navbar */}

      <div className="flex-grow-1 d-flex align-items-stretch overflow-scroll">
        {/* side menu (full size) */}

        {!screenIsSmall && isMenuOpen && <SideMenu onClickMenuButton={toggleMenu} screenIsSmall={screenIsSmall} />}

        <div className={joinClassNames('flex-grow-1 bg-light overflow-scroll', screenIsSmall && 'position-relative')}>
          {/* scrim */}

          {screenIsSmall && isMenuOpen && <Scrim onClick={toggleMenu} />}

          {/* side menu (small / overlay) */}

          {screenIsSmall && isMenuOpen && <SideMenu onClickMenuButton={toggleMenu} screenIsSmall={screenIsSmall} />}

          {/* main content */}

          <AdditionalNavbarContentContextProvider value={{additionalNavbarContent, setAdditionalNavbarContent}}>
            {children}
          </AdditionalNavbarContentContextProvider>
        </div>
      </div>
    </div>
  );
}

function Scrim({onClick}) {
  return (
    <div
      onClick={onClick}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: '#00000044',
        zIndex: Constants.zIndex.level2,
      }}
    />
  );
}

function NavBar({onClickMenuButton, additionalNavbarContent}) {
  const {currentRoute} = useContext(CurrentRoutesContext);
  const {title} = useContext(TitleContext);
  return (
    <div
      className="row no-gutters bg-primary text-white"
      style={{
        zIndex: Constants.zIndex.level4,
        boxShadow: '0rem 0.5rem 0.5rem -0.5rem rgba(0,0,0,0.3)',
      }}>
      <div className={'col-10 col-md-auto flex-grow-1 d-flex align-items-center pr-2'} style={{height: '64px'}}>
        <button onClick={onClickMenuButton} type="button" className="btn text-white p-2 m-1">
          <MenuIcon />
        </button>
        {unwrap(currentRoute, route => {
          const names = [...route.chain.map(route => route.name), title].filter(Boolean);
          const firstName = names.splice(0, 1);
          return (
            <div>
              <h5 className="mb-0">{firstName}</h5>
              {names.join(' / ')}
            </div>
          );
        })}
      </div>
      {additionalNavbarContent}
    </div>
  );
}

function SideMenu({screenIsSmall, onClickMenuButton}) {
  return (
    <div
      className={joinClassNames(
        'd-flex flex-column bg-white border-right overflow-scroll',
        screenIsSmall && 'position-absolute h-100',
      )}
      style={{
        width: '17rem',
        minWidth: '17rem',
        zIndex: Constants.zIndex.level3,
        boxShadow: screenIsSmall ? '0.5rem 0rem 0.5rem -0.5rem rgba(0,0,0,0.3)' : null,
      }}>
      <div className="flex-fill">
        <MenuItems />
      </div>
      <div className="d-flex justify-content-center p-1">
        <ModeSwitcher />
      </div>
      <div className="d-flex justify-content-center p-1 mb-2">
        <Link to="/" className="btn btn-sm btn-link">
          Home
        </Link>
        <button onClick={() => firebase.auth().signOut()} className="btn btn-sm btn-link ml-3">
          Sign out
        </button>
      </div>
    </div>
  );
}

function MenuItems() {
  const root = useContext(ModifiedRootContext);
  return root.children[0].children.map(child => <MenuItem key={child.absolutePath + child.name} route={child} />);
}

function ModeSwitcher() {
  const location = useLocation();

  const environments = useMemo(() => {
    if (environmentVariable('ENVIRONMENT') === 'production') {
      return environmentVisibleInProduction;
    } else {
      return environmentAll;
    }
  }, []);

  const onChangeEnvironmentSelect = useCallback(
    event => {
      const url = `${environmentUrlForEnvironment(event.target.value)}${
        location.pathname + location.hash + location.search
      }`;
      window.location = url;
    },
    [location],
  );

  return (
    <ClaimsGuard claim={CustomClaim.manageDrivers}>
      <select
        value={process.env.REACT_APP_ENVIRONMENT}
        onChange={onChangeEnvironmentSelect}
        className="custom-select w-auto custom-select-sm">
        {environments.map(environment => (
          <option key={environment} value={environment}>
            {capitalize(environment)} mode
          </option>
        ))}
      </select>
    </ClaimsGuard>
  );
}
