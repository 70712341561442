import first from 'lodash/first';
import React, {useEffect, useMemo} from 'react';
import {CoordinatesArray} from 'wave-common';
import unwrap from '../functions/unwrap';
import useFirestoreQueryGet from '../hooks/useFirestoreQueryGet';
import useLocalStorage from '../hooks/useLocalStorage';
import useObjectById from '../hooks/useObjectById';
import City from '../models/scoopm/City';
import Region from '../models/scoopm/Region';

export default function SearchNear({
  disabled,
  onChangeBounds,
  compact,
}: {
  onChangeBounds: (bounds: any) => void;
  disabled?: boolean;
  compact?: boolean;
}) {
  const {models: regions, isLoading} = useFirestoreQueryGet(
    useMemo(() => Region.reference(), []),
    Region,
  );
  const [searchRegionId, setSearchRegionId] = useLocalStorage('SEARCH_REGION_ID');
  const searchRegion = useObjectById(regions, searchRegionId, first(regions));

  // determine default value. cleanup deprected "SEARCH_CITY"

  useEffect(() => {
    // wait for regions to load

    if (!regions) return;

    // get search city

    const searchCityRawValue = window.localStorage.getItem('SEARCH_CITY');

    if (searchCityRawValue) {
      // if there's a search city, try to use it and clear it from storage

      window.localStorage.removeItem('SEARCH_CITY');

      const searchCity = City.from(searchCityRawValue) as City;
      const regionForSearchCity = unwrap(searchCity, city => {
        const centerLatLng = city.latLngBounds().getCenter();
        const coordinatesArray: CoordinatesArray = [centerLatLng.lat(), centerLatLng.lng()];
        return regions.find(region => region.contains(coordinatesArray));
      });

      // if so, set search region id

      if (regionForSearchCity?.id) {
        setSearchRegionId(regionForSearchCity.id);
      } else if (regions.length) {
        setSearchRegionId(regions[0]!.id!);
      } else {
        // no regions have been set up yet. unlikely.
      }
    } else if (!searchRegionId && regions.length) {
      // else try to use the first available region

      setSearchRegionId(regions[0]!.id!);
    }
  }, [regions, setSearchRegionId, searchRegionId]);

  // callback

  useEffect(() => {
    if (searchRegion) {
      const bounds = searchRegion.latLngBounds();
      onChangeBounds(bounds);
    }
  }, [searchRegion, onChangeBounds]);

  return (
    <>
      {!compact && <small className="text-muted mr-2">Search near</small>}
      <select
        value={searchRegionId || ''}
        onChange={event => setSearchRegionId(event.target.value)}
        className="custom-select custom-select-sm w-auto"
        disabled={isLoading || disabled}>
        {regions &&
          regions.map(region => (
            <option key={region.id} value={region.id}>
              {region.name}
            </option>
          ))}
      </select>
    </>
  );
}
