export default class Order {}

Order.formatId = id => `#${id.substr(0, 3)}-${id.substr(3, 3)}`;

/**
 * Returns the parse value, or `false` if invalid
 */
Order.parseId = rawValue => {
  if (!rawValue) return false;
  const parsed = rawValue.replace('-', '').replace('#', '').trim();
  if (parsed.length !== 6) return false;
  return parsed;
};
