import firebase from 'firebase/app';
import HttpReference from '../platform/HttpReference';

export default class Messages extends HttpReference {
  async create(data) {
    const user = firebase.auth().currentUser;
    if (!user) throw new Error('Not authenticated');
    const token = await user.getIdToken();
    return this.request({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    }).then(response => {
      // check for failure count
      if (response.data.failureCount) throw new Error(response.data.responses[0].error.message);
      return response;
    });
  }
}
