import omit from 'lodash/omit';
import React, {ChangeEvent, useCallback, useMemo} from 'react';
import {useStatus} from 'wave-common';
import {tripTemplateListAll} from 'wave-common/lib/controllers/TripTemplate';
import {useAlert} from '../../contexts/AlertContext';
import FirestoreDataSource from '../../data-sources/FirestoreDataSource';
import Trip from '../../models/scoopm/Trip';
import {TripSource} from '../../models/scoopm/TripSource';
import Alert from '../Alert/Alert';

export default function TemplatesDropdown({
  defaultValue,
  setDefaultValue,
  source,
}: {
  defaultValue: Trip;
  setDefaultValue: (newTrip: Trip) => void;
  source: TripSource;
}) {
  const {setAlert} = useAlert();

  const {
    isPending,
    error,
    value: templates,
  } = useStatus(
    React as never,
    useMemo(() => tripTemplateListAll(FirestoreDataSource), []),
  );

  const onChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      if (!value) {
        return;
      }
      setAlert(
        new Alert(
          'Use template?',
          'Any current changes may be overwritten.',
          'Apply template',
          async () => {
            const template = templates?.find(template => template.id === value);
            const newDefaultValue: Trip = new Trip({
              ...Trip.forManualEntry(source),
              ...defaultValue,
              ...omit(template, 'id', 'name'),
            });
            console.log({template, defaultValue, newDefaultValue});
            setDefaultValue(newDefaultValue);
          },
          'Cancel',
          undefined,
          undefined,
          undefined,
          'USE_TRIP_TEMPLATE',
        ),
      );
    },
    [defaultValue, setDefaultValue, setAlert, source, templates],
  );

  // dont' show if there are no templates

  if (templates?.length === 0) {
    return null;
  }

  return (
    <div className="col-auto">
      <div className="py-1">
        <select onChange={onChange} className="custom-select" disabled={isPending || Boolean(error) || !templates}>
          <option value="">{error ? '-' : 'Use template...'}</option>
          {templates?.map(template => (
            <option key={template.id} value={template.id}>
              {template.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
