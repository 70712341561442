import firebase from 'firebase/app';
import first from 'lodash/first';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import {useMemo} from 'react';
import expect from '../functions/expect';
import {unwrap} from '../functions/unwrap';
import Vendor from '../models/scoopm/Vendor';
import {useSelectedVendorId} from '../stores/localStorageStore';
import useFirestoreListener from './useFirestoreListener';
import useResult from './useResult';
import useUserGroups from './useUserGroups';

/**
 * Observes the current user's groups, and returns any vendor ids from them
 */
export function useUserGroupVendorIds(): {
  isLoading: boolean;
  error: Error | undefined;
  vendorIds: string[] | undefined;
} {
  const {isLoading, error, groups} = useUserGroups();
  const vendorIds = useMemo(
    () =>
      unwrap(groups, groups =>
        groups.filter(([id, data]: any) => data.vendorId).map(([id, data]: any) => data.vendorId),
      ),
    [groups],
  );
  return {
    isLoading,
    error: error ?? undefined,
    vendorIds,
  };
}

/**
 * Observes the current user's groups, and returns any vendors from them
 */
export function useUserGroupVendors(): {
  isLoading: boolean;
  error: Error | undefined;
  vendors: Vendor[] | undefined;
} {
  const {isLoading, error, vendorIds} = useUserGroupVendorIds();
  const promise = useMemo(
    () => unwrap(vendorIds, ids => Promise.all(ids.map(async id => expect(await Vendor.withId(id))))),
    [vendorIds],
  );
  const result = useResult(promise);
  return {
    isLoading: isLoading || result === null,
    error: error || result?.error || undefined,
    vendors: sortBy(result?.value, vendor => vendor?.name),
  };
}

/**
 * Returns the selected or first vendor in this user's group's vendors
 */
export default function useUserGroupVendor() {
  const {isLoading, error, vendorIds} = useUserGroupVendorIds();
  const [selectedVendorId] = useSelectedVendorId();
  const reference = useMemo(
    () => unwrap(selectedVendorId || first(vendorIds), id => firebase.firestore().collection('vendors').doc(id)),
    [vendorIds, selectedVendorId],
  );
  const result = useFirestoreListener(reference, Vendor);
  return {
    isLoading: isLoading || result.isLoading,
    error: error || result.error,
    ...omit(result, 'isLoading', 'error'),
  };
}
