import firebase from 'firebase/app';
import {cleanForFirebase, CreateResult, expect, ReadResult, RealtimeDatabaseDataSource as Interface} from 'wave-common';
import {RealtimeDatabaseDataSourceOptions} from 'wave-common/lib/data-sources/RealtimeDatabaseDataSource';
import logPromise from '../functions/logPromise';

class RealtimeDatabaseDataSource implements Interface {
  static instance = new RealtimeDatabaseDataSource();

  serverTimestamp = firebase.database.ServerValue.TIMESTAMP;

  async create(path: string, data: unknown): Promise<CreateResult> {
    const reference = await logPromise(
      `create object at "${path}"`,
      firebase
        .database()
        .ref(path)
        .push(cleanForFirebase(data as never)),
    );
    return {id: expect(reference.key)};
  }

  async read(path: string, id: string): Promise<ReadResult<unknown>> {
    const snapshot = await logPromise(`read "${path}/${id}"`, firebase.database().ref(path).child(id).once('value'));
    return {value: snapshot.val(), id: expect(snapshot.key)};
  }

  async delete(path: string, id: string): Promise<void> {
    await logPromise(`delete "${path}/${id}"`, firebase.database().ref(path).child(id).remove());
  }

  async list(
    path: string,
    options?: {orderByChild?: string | undefined; equalTo?: string | number | undefined} | undefined,
  ): Promise<ReadResult<unknown>[]> {
    let reference = firebase.database().ref(path) as firebase.database.Query;
    if (options) {
      if (options.orderByChild) {
        reference = reference.orderByChild(options.orderByChild);
      }
      if (options.equalTo) {
        reference = reference.equalTo(options.equalTo);
      }
    }
    const snapshot = await logPromise(`list ${path}`, reference.once('value'));
    if (!snapshot.exists()) {
      // throw new Error(`No values at "${path}"`)
      return [];
    }
    return Object.entries(snapshot.val()).map(([key, value]) => ({
      value,
      id: key,
    }));
  }

  async update(path: string, id: string, values: unknown): Promise<void> {
    await logPromise(
      `update "${path}/${id}"`,
      firebase
        .database()
        .ref(path)
        .child(id)
        .update(cleanForFirebase(values as never)),
    );
  }

  on(
    path: string,
    options?: (RealtimeDatabaseDataSourceOptions & {eventType?: firebase.database.EventType | undefined}) | undefined,
    onSuccess?: ((snapshot: firebase.database.DataSnapshot) => void) | undefined,
    onFailure?: ((error: Error) => void) | undefined,
  ): () => void {
    throw new Error('Function not implemented.');
  }
}

export default RealtimeDatabaseDataSource;
