import {BootstrapActionColor} from '../../styles/BootstrapColor';

type SubscriptionStatus =
  | 'incomplete'
  | 'incomplete_expired'
  | 'trialing'
  | 'active'
  | 'past_due'
  | 'canceled'
  | 'unpaid';
export default SubscriptionStatus;

export function subscriptionStatusTitle(status: SubscriptionStatus) {
  switch (status) {
    case 'incomplete':
      return 'Incomplete';
    case 'incomplete_expired':
      return 'Incomplete/expired';
    case 'trialing':
      return 'Trial';
    case 'active':
      return 'Active';
    case 'past_due':
      return 'Past due';
    case 'canceled':
      return 'Canceled';
    case 'unpaid':
      return 'Unpaid';
  }
}

export function subscriptionStatusBootstrapColor(status: SubscriptionStatus): BootstrapActionColor {
  switch (status) {
    case 'incomplete':
    case 'past_due':
    case 'unpaid':
      return 'warning';

    case 'incomplete_expired':
    case 'canceled':
      return 'danger';

    case 'trialing':
    case 'active':
      return 'success';
  }
}
