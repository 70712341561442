import chunk from 'lodash/chunk';
import React, {useCallback, useState} from 'react';
import ReactDatePicker from 'react-datepicker';
import {Location as WaveCommonLocation} from 'wave-common';
import unwrap from '../../functions/unwrap';
import PositionInList from '../../models/scoopm/PositionInList';
import TripContact from '../../models/scoopm/TripContact';
import TripContent from '../../models/scoopm/TripContent';
import TripLocation from '../../models/scoopm/TripLocation';
import ValidateableInput from '../bootstrap/ValidateableInput';
import Autocomplete from '../GoogleMaps/Autocomplete';
import Input from '../Input';
import SearchNear from '../SearchNear';

export default function Location({
  id,
  positionInList,
  location,
  onChangeLocation,
  deleteLocation,
  contact,
  onChangeContact,
  locationCount,
  allowDeletion,
  validation,
  contactValidation,
  disabled,
}: {
  id?: number;
  positionInList: PositionInList;
  location?: TripLocation;
  onChangeLocation: (location: TripLocation) => void;
  deleteLocation?: () => void;
  contact?: TripContact;
  onChangeContact: (contact: TripContact) => void;
  validation?: Record<string, any>;
  contactValidation?: Record<string, any>;
  locationCount?: number;
  allowDeletion?: boolean;
  disabled?: boolean;
}) {
  const [searchBounds, setSearchBounds] = useState();

  function onLocationChange(searchedLocation: WaveCommonLocation) {
    const newLocation = new TripLocation({...location, ...searchedLocation} as never);
    onChangeLocation(newLocation);
  }

  function onChangeName(event: any) {
    const name = event.target.value;
    const newContact = new TripContact(contact || {});
    newContact.name = name;
    onChangeContact(newContact);
  }

  function onChangePhone(event: any) {
    const phone = event.target.value;
    const newContact = new TripContact(contact || {});
    newContact.phone = phone;
    onChangeContact(newContact);
  }

  function onClickCreateContent() {
    const newLocation = new TripLocation(location as never);
    if (!newLocation.contents) newLocation.contents = [];
    newLocation.contents.push(new TripContent({title: '', quantity: 1}));
    onChangeLocation(newLocation);
  }

  function onClickDeleteContent(i: number) {
    return function () {
      const newLocation = new TripLocation(location as never);
      newLocation.contents.splice(i, 1);
      onChangeLocation(newLocation);
    };
  }

  function onChangeTitle(i: number) {
    return function (event: any) {
      const newLocation = new TripLocation(location as never);

      let title: string = event.target.value;

      // try to parse / split the string for convenience

      const titleParts = title
        .split(/(\d+)/)
        .map(s => s.trim())
        .filter(Boolean);

      if (titleParts.length && titleParts.length > 1) {
        chunk(titleParts, 2).forEach((chunk, j) => {
          newLocation.contents[i + j] = {
            quantity: Number(chunk[0]),
            title: chunk[1],
          };
        });
      } else {
        newLocation.contents[i].title = title;
      }

      onChangeLocation(newLocation);
    };
  }

  function onChangeQuantity(i: number) {
    return function (event: any) {
      const newLocation = new TripLocation(location as never);
      const quantity = Number(event.target.value);
      newLocation.contents[i].quantity = quantity;
      onChangeLocation(newLocation);
    };
  }

  function onChangeInstructions(event: any) {
    const newLocation = new TripLocation(location as never);
    newLocation.instructions = event.target.value;
    onChangeLocation(newLocation);
  }

  function onChangeScheduledTimestamp(date: Date) {
    const newLocation = new TripLocation(location as never);
    newLocation.scheduledTimestampMs = date.valueOf();
    onChangeLocation(newLocation);
  }

  const onChangeCompletionTimestamp = useCallback(
    date => {
      const newLocation = new TripLocation(location as never);
      newLocation.completionTimestampMs = date.valueOf();
      onChangeLocation(newLocation);
    },
    [location, onChangeLocation],
  );

  function deleteScheduledTimestamp() {
    const newLocation = new TripLocation(location as never);
    newLocation.scheduledTimestampMs = null;
    onChangeLocation(newLocation);
  }

  const deleteCompletionTimestamp = useCallback(() => {
    const newLocation = new TripLocation(location as never);
    newLocation.completionTimestampMs = null;
    onChangeLocation(newLocation);
  }, [location, onChangeLocation]);

  return (
    <div className="bg-light rounded p-2 p-md-3 mb-3 mb-md-4">
      <div className="row mb-2 mb-md-3">
        {/* heading */}

        <div className="col">
          <h5>{positionInList.locationName(locationCount)}</h5>
        </div>

        {/* delete button */}

        <div className="col-auto">
          <button
            type="button"
            onClick={deleteLocation}
            className="btn btn-light"
            disabled={!allowDeletion || disabled}>
            <i className="fas fa-minus" />
          </button>
        </div>
      </div>

      <div className="row">
        {/* address, name, phone */}

        <div className="col-md">
          {/* address */}

          <div className="form-group">
            <div className="d-flex align-items-baseline">
              <label htmlFor={`searchBoxInput-${id}`} className="flex-grow-1">
                Address
              </label>
              {/* {positionInList === PositionInList.first && ( */}
              <SearchNear onChangeBounds={setSearchBounds} disabled={disabled} />
              {/* )} */}
            </div>
            <Autocomplete bounds={searchBounds} onChange={onLocationChange}>
              <ValidateableInput
                elementType={Input}
                defaultValue={TripLocation.prototype.title.apply(location)}
                id={`searchBoxInput-${id}`}
                type="text"
                className="form-control"
                placeholder="Search"
                isValid={unwrap(validation, v => v.coordinates)}
                invalidFeedback="Enter a valid address"
                disabled={disabled}
              />
            </Autocomplete>
          </div>

          {/* name, phone */}

          {contact && (
            <div className="form-group">
              <div className="row">
                <div className="col-md mb-3 mb-md-0">
                  <label htmlFor={`nameInput-${id}`}>Name</label>
                  <ValidateableInput
                    type="text"
                    id={`nameInput-${id}`}
                    value={contact.name}
                    onChange={onChangeName}
                    placeholder="Contact name"
                    className="form-control"
                    isValid={unwrap(contactValidation, v => v.name)}
                    invalidFeedback="Enter a valid name"
                    autoComplete="off"
                    disabled={disabled}
                  />
                </div>
                <div className="col-md">
                  <label htmlFor={`phoneInput-${id}`}>Phone</label>
                  <ValidateableInput
                    type="text"
                    id={`phoneInput-${id}`}
                    value={contact.phone || ''}
                    onChange={onChangePhone}
                    placeholder="(xxx) xxx-xxxx"
                    className="form-control"
                    isValid={unwrap(contactValidation, v => v.phone)}
                    invalidFeedback="Enter a valid phone number"
                    autoComplete="off"
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          )}

          {/* scheduled time */}

          <div className="form-group">
            <div>
              <label htmlFor={`datePicker-${id}`}>{positionInList.locationName(locationCount)} time</label>
            </div>
            <div className="row">
              <ReactDatePicker
                id={`datePicker-${id}`}
                selected={unwrap(location?.scheduledTimestampMs, ts => new Date(ts))}
                onChange={onChangeScheduledTimestamp}
                dateFormat="Pp"
                className={['form-control', unwrap(validation, v => v.scheduledTime) ? 'is-valid' : ''].join(' ')}
                wrapperClassName="col"
                showTimeSelect
                timeIntervals={15}
                placeholderText="Not scheduled"
                disabled={disabled}
              />
              {unwrap(location?.scheduledTimestampMs, ts => (
                <div className="col-auto">
                  <button
                    type="button"
                    onClick={deleteScheduledTimestamp}
                    className="btn btn-light ml-1"
                    disabled={disabled}>
                    <i className="fas fa-minus"></i>
                  </button>
                </div>
              ))}
            </div>
            <small className="form-text text-muted">Optional</small>
          </div>

          {/* completion time */}

          {location?.completionTimestampMs && (
            <div className="form-group">
              <div>
                <label htmlFor={`completionInput-${id}`}>Completed at</label>
              </div>
              <div className="row">
                <ReactDatePicker
                  id={`completionInput-${id}`}
                  selected={unwrap(location.completionTimestampMs, ts => new Date(ts))}
                  onChange={onChangeCompletionTimestamp}
                  dateFormat="Pp"
                  className={['form-control', unwrap(validation, v => v.completionTimestampMs) ? 'is-valid' : ''].join(
                    ' ',
                  )}
                  wrapperClassName="col"
                  showTimeSelect
                  placeholderText="Not yet completed"
                  disabled={disabled}
                />
                {unwrap(location.completionTimestampMs, ts => (
                  <div className="col-auto">
                    <button
                      type="button"
                      onClick={deleteCompletionTimestamp}
                      className="btn btn-light ml-1"
                      disabled={disabled}>
                      <i className="fas fa-minus"></i>
                    </button>
                  </div>
                ))}
              </div>
              <small className="form-text text-muted">Optional</small>
            </div>
          )}
        </div>

        {/* second column */}

        <div className="col-md">
          {/* contents */}

          {positionInList === PositionInList.first && (
            <div className="form-group">
              <label htmlFor={`instructionsInput-${id}`}>Contents</label>
              <div>
                {location?.contents &&
                  location.contents.map((content: TripContent, i: number) => (
                    <div key={i} className="row mb-2">
                      <div className="col-md-4 mb-2 mb-md-0">
                        <ValidateableInput
                          type="number"
                          value={String(content.quantity)}
                          onChange={onChangeQuantity(i)}
                          placeholder="Quantity"
                          className="form-control"
                          isValid={unwrap(validation, v => v.contents)}
                          invalidFeedback={null}
                          autoComplete="off"
                          disabled={disabled}
                        />
                      </div>
                      <div className="col-md">
                        <ValidateableInput
                          type="text"
                          value={content.title}
                          onChange={onChangeTitle(i)}
                          placeholder="Describe item"
                          className="form-control"
                          isValid={unwrap(validation, v => v.contents)}
                          invalidFeedback={null}
                          autoComplete="off"
                          disabled={disabled}
                        />
                        {i === 0 && (
                          <small className="form-text">
                            Tip &mdash; you can paste multiple items in the description. They'll be split up
                          </small>
                        )}
                      </div>
                      <div className="col-md-auto d-flex align-items-start">
                        <button
                          type="button"
                          onClick={onClickDeleteContent(i)}
                          className="btn btn-sm btn-light"
                          disabled={disabled}>
                          <i className="fas fa-minus" />
                        </button>
                      </div>
                    </div>
                  ))}
                <button type="button" onClick={onClickCreateContent} className="btn btn-light" disabled={disabled}>
                  <i className="fas fa-plus" /> Add item
                </button>
              </div>
              {unwrap(validation, v => !v.contents) && (
                <small className="text-danger">
                  Check that all contents are typed correctly and have a quantity of at least 1
                </small>
              )}
            </div>
          )}

          {/* instructions */}

          <div className="form-group">
            <label htmlFor={`instructionsInput-${id}`}>Instructions</label>
            <ValidateableInput
              elementType="textarea"
              id={`instructionsInput-${id}`}
              value={location?.instructions || ''}
              onChange={onChangeInstructions}
              rows="3"
              className="form-control"
              placeholder="None"
              isValid={unwrap(validation, v => v.instructions)}
              disabled={disabled}
              optional
            />
          </div>
        </div>
      </div>

      {/* <pre>{JSON.stringify(location, null, '   ')}</pre> */}
    </div>
  );
}
