"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function useCallbackCaller(react, callback, value) {
    react.useEffect(() => {
        if (callback) {
            callback(value);
        }
    }, [value, callback]);
}
exports.default = useCallbackCaller;
