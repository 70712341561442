import first from 'lodash/first';
import React, {ReactNode, useRef} from 'react';
import StandaloneSearchBox, {
  StandaloneSearchBoxProps,
} from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import TripLocation, {tripLocationFromPlace} from 'wave-common/lib/models/TripLocation';

export default function Autocomplete({
  onChange,
  ...props
}: {
  onChange: (location: TripLocation) => void;
  children: ReactNode;
} & Omit<StandaloneSearchBoxProps, 'ref' | 'onPlacesChanged'>) {
  const ref = useRef<StandaloneSearchBox>();
  return (
    <StandaloneSearchBox
      ref={ref as never}
      onPlacesChanged={() => {
        const places = ref.current!.getPlaces();
        const place = JSON.parse(JSON.stringify(first(places)));
        onChange(tripLocationFromPlace(place, undefined));
      }}
      {...props}
    />
  );
}
