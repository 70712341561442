import sortBy from 'lodash/sortBy';
import React from 'react';
import {tripSourceAllKnown, tripSourceDescription} from 'wave-common/lib/models/TripSource';
import unhandledCase from '../../functions/unhandledCase';
import ezCaterLogo from '../../images/ez-cater-logo.png';
import oloLogo from '../../images/olo-logo.png';
import profileAdmin from '../../images/profile_admin.png';
import Enum from '../base/Enum';
import SelectOption from '../SelectOption';

export class TripSource extends Enum {
  static selectOptions() {
    return tripSourceAllKnown.map(source => new SelectOption(source, tripSourceDescription(source))).sort();
  }

  static inOrder() {
    return sortBy(tripSourceAllKnown, source => tripSourceDescription(source).toLowerCase());
  }

  isEditable() {
    switch (this) {
      case TripSource.adminPortal:
      case TripSource.android:
      case TripSource.customerPortal:
      case TripSource.dispatchPortal:
      case TripSource.ezCater:
      case TripSource.iOS:
      case TripSource.slack:
        return true;

      case TripSource.olo:
      case TripSource.dispatchApi:
        return false;

      default:
        return true;
    }
  }

  get title() {
    return tripSourceDescription(this.rawValue);
  }

  Icon() {
    switch (this) {
      case TripSource.adminPortal:
        return withIcon(profileAdmin, 'Admin with headset');
      case TripSource.android:
        return () => '📱';
      case TripSource.customerPortal:
        return () => '👩‍💻';
      case TripSource.dispatchApi:
        return () => '🌊';
      case TripSource.dispatchPortal:
        return () => '👩‍💻';
      case TripSource.ezCater:
        return withIcon(ezCaterLogo, 'ezCater logo');
      case TripSource.iOS:
        return () => '📱';
      case TripSource.olo:
        return withIcon(oloLogo, 'OLO logo');
      case TripSource.slack:
        return () => '✳️';
      default:
        throw unhandledCase(this);
    }
  }
}

function withIcon(src, alt) {
  const style = {
    width: '18px',
    height: '24px',
    backgroundImage: `url(${src})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };
  return function () {
    return <div style={style} />;
  };
}

TripSource.adminPortal = new TripSource('ADMIN_PORTAL');
TripSource.android = new TripSource('ANDROID');
TripSource.customerPortal = new TripSource('CUSTOMER_PORTAL');
TripSource.dispatchApi = new TripSource('DISPATCH_API');
TripSource.dispatchPortal = new TripSource('DISPATCH_PORTAL');
TripSource.ezCater = new TripSource('EZ_CATER');
TripSource.ios = new TripSource('IOS');
TripSource.olo = new TripSource('OLO');
TripSource.slack = new TripSource('SLACK');
