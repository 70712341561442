import React, {MouseEvent} from 'react';
import {Estimate, formatCurrency, tripTypeTitle} from 'wave-common';
import {tripTypeShortDescription} from 'wave-common/lib/models/TripType';
import WhiteText from '../../../components/WhiteText';
import joinClassNames from '../../../functions/joinClassNames';
import {tripTypeImageSource} from '../../../models/scoopm/TripType';

export default function EstimateCard({
  estimate,
  isSelected,
  onClick,
  isLoading,
}: {
  estimate?: Estimate;
  isSelected?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  isLoading?: boolean;
}) {
  return (
    <div
      onClick={estimate && onClick}
      className={joinClassNames(estimate && 'selectable', estimate && (isSelected ? 'is-selected' : 'cursor-pointer'))}>
      <div className={joinClassNames('selectable-inner', !estimate && isLoading && 'loading-animation')}>
        <div className="row align-items-center">
          <div className="col-auto">
            {estimate && <img src={tripTypeImageSource(estimate.tripType)} alt="" width="88" height="35" />}
          </div>
          <div className="col">
            <h5 className="mb-n1">{estimate ? tripTypeTitle(estimate.tripType) : <WhiteText />}</h5>
            <small className="text-muted">
              {estimate ? tripTypeShortDescription(estimate.tripType) : <WhiteText />}
            </small>
          </div>
          <div className="col-auto text-right">
            {estimate?.valueBeforeDiscountD && (
              <small className="text-decoration-line-through text-muted">
                {formatCurrency(estimate.valueBeforeDiscountD)}
              </small>
            )}
            <div className={estimate?.valueBeforeDiscountD ? 'text-primary' : undefined}>
              {estimate ? formatCurrency(estimate.valueD) : <WhiteText />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
