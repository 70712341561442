import React from 'react';
import joinClassNames from '../../../../functions/joinClassNames';

export default function ZoomButton(props: {
  driverId?: string;
  selectedDriverId?: string;
  setSelectedDriverId: (id?: string) => void;
}) {
  return (
    <button
      onClick={event => {
        event.preventDefault();
        props.setSelectedDriverId(props.selectedDriverId === props.driverId ? undefined : props.driverId);
      }}
      className={joinClassNames('btn btn-link', props.driverId === props.selectedDriverId && 'text-primary')}>
      {props.driverId === props.selectedDriverId ? (
        <i className="fas fa-search-minus" />
      ) : (
        <i className="fas fa-search-plus text-prim" />
      )}
    </button>
  );
}
