import Enum from '../base/Enum';

export default class DeviceType extends Enum {
  title() {
    switch (this) {
      case DeviceType.android:
        return 'Android';
      case DeviceType.api:
        return 'API';
      case DeviceType.iOS:
        return 'iOS';
      case DeviceType.web:
        return 'Web';
      default:
        return null;
    }
  }
}

DeviceType.android = new DeviceType('ANDROID');
DeviceType.api = new DeviceType('API');
DeviceType.iOS = new DeviceType('IOS');
DeviceType.web = new DeviceType('WEB');

DeviceType.mobileTypes = [DeviceType.android, DeviceType.iOS];
