import React from 'react';
import {Link} from 'react-router-dom';
import {Driver} from 'wave-common';
import Anchor from '../../../../components/Anchor';
import PropertySwitch from '../../../../components/PropertySwitch';
import {Separator, Subtitle} from '../driver';
import EarningsTotal from './EarningsTotal';

export default function Earnings({driver}: {driver: Driver}) {
  return (
    <>
      <Anchor id="earnings" showHint />

      <Separator />
      <Subtitle>
        INSTANT PAYOUT
        <span className="float-right">
          <EarningsTotal type="INSTANT" driverId={driver.id} />
        </span>
      </Subtitle>

      <div className="mb-2">
        <PropertySwitch title="Auto-calculate" property="autoCalculateInstantEarnings" object={driver} />
      </div>

      <div className="mb-2">
        <PropertySwitch title="Allow instant payout" property="allowPayout" object={driver} />
        <div className="custom-switch-caption">
          <small className="text-muted">
            When enabled, drivers will be able to:
            <ol className="mb-0">
              <li>Cash out</li>
              <li>See accrued instant earnings ("History" screen)</li>
              <li>
                See potential earnings when self-assigning ("New jobs" screen). This applies to deliveries/rideshare
                alike. Even though deliveries are not available for instant payouts. It's a caviat of the old app 🤷‍♂️
              </li>
            </ol>
            <Link to={'/admin/payouts'} className="text-muted text-decoration-underline">
              Global instant payout shutoff &rarr;
            </Link>
          </small>
        </div>
      </div>

      <Separator />
      <Subtitle>
        DELAYED PAYOUT
        <span className="float-right">
          <EarningsTotal type="DELAYED" driverId={driver.id} />
        </span>
      </Subtitle>

      <div className="mb-2">
        <PropertySwitch title="Auto-calculate" property="autoCalculateDelayedEarnings" object={driver} />
      </div>
    </>
  );
}
