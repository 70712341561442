import React, {ReactNode, useEffect, useState} from 'react';

/**
 * Will only render the children after the timeout. Useful to avoid flashing when async activity is fast.
 */
export default function Delay({
  amountMs = 1250,
  childrenBeforeTimeout,
  children,
}: {
  amountMs?: number;
  childrenBeforeTimeout?: ReactNode;
  children: ReactNode;
}) {
  const [didTimeOut, setDidTimeOut] = useState(false);

  useEffect(() => {
    const handle = setTimeout(() => {
      setDidTimeOut(true);
    }, amountMs);
    return () => clearTimeout(handle);
  });

  if (!didTimeOut) {
    return <>{childrenBeforeTimeout}</>;
  }

  return <>{children}</>;
}
