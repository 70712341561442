import React, {useEffect, useState} from 'react';
import unwrap from '../functions/unwrap';
import useLocalStorage from '../hooks/useLocalStorage';
import Collapsable from './Collapsable';
import ViewInFirebase, {ViewInFirebaseLink} from './ViewInFirebase';

export const Pre = ({value}) => (
  <pre
    style={{
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      fontSize: '10pt',
      lineHeight: '13pt',
    }}
    className="mb-0">
    {value}
  </pre>
);

const defaultKeysToHide = ['ssn', 'fcmRegistrationTokens', 'signatureSvgBase64', 'signatureDataBase30'];

export default function RawData({
  data,
  keysToHide = undefined,
  title = 'Raw data',
  defaultIsCollapsed,
  path = null,
  ...props
}) {
  const [filteredData, setFilteredData] = useState();
  const [entries, setEntries] = useState();
  const [isPlainText, setIsPlainText] = useLocalStorage('RAW_DATA_IS_PLAIN_TEXT', 'YES');

  useEffect(() => {
    const filteredData = unwrap(data, data =>
      Object.entries(data).filter(
        ([key, value]) => !defaultKeysToHide.includes(key) && (!keysToHide || !keysToHide.includes(key)),
      ),
    );
    const entries = !filteredData
      ? null
      : filteredData.map(([key, value]) => [
          key,
          typeof value === 'object' ? <Pre value={JSON.stringify(value, null, 3)} /> : String(value),
        ]);
    setFilteredData(filteredData);
    setEntries(entries);
  }, [data, keysToHide]);

  return (
    <Collapsable
      title={title}
      additionalComponents={path && <ViewInFirebaseLink path={path}>View in Firebase</ViewInFirebaseLink>}
      additionalComponentsWhenOpen={
        <div className="col-auto">
          <div className="btn-group">
            <button
              onClick={() => setIsPlainText('YES')}
              className={['btn btn-sm', isPlainText === 'YES' ? 'btn-secondary' : 'btn-light border'].join(' ')}>
              Plain text
            </button>
            <button
              onClick={() => setIsPlainText('NO')}
              className={['btn btn-sm', isPlainText === 'YES' ? 'btn-light border' : 'btn-secondary'].join(' ')}>
              Table
            </button>
          </div>
        </div>
      }
      defaultIsCollapsed={defaultIsCollapsed}
      {...props}>
      {!entries ? (
        <table>
          <tbody>
            <tr>
              <td>No data</td>
            </tr>
          </tbody>
        </table>
      ) : isPlainText === 'YES' ? (
        <pre className="mb-0" style={{fontSize: '10pt'}}>
          {filteredData &&
            JSON.stringify(Object.assign({}, ...filteredData.map(([key, value]) => ({[key]: value}))), null, '   ')}
        </pre>
      ) : (
        <div className="table-responsive m-0">
          <table className="table table-sm table-striped table-bordered table-hover w-auto m-0">
            <tbody>
              {entries.map(([key, value], i) => (
                <tr key={i}>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Collapsable>
  );
}
