import React from 'react';
import Card from '../../../components/Card';
import CreateTripForm from '../../../components/TripForm/CreateTripForm';
import {TripSource} from '../../../models/scoopm/TripSource';

export default function CreateRide() {
  return (
    <Card padded>
      <CreateTripForm source={TripSource.adminPortal} isAdmin />
    </Card>
  );
}
