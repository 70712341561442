import React, {Component} from 'react';
import {checkForProp} from '../../functions/checkForProp';
import joinClassNames from '../../functions/joinClassNames';
import unwrap from '../../functions/unwrap';
import RealtimeDatabase from '../../references/database/RealtimeDatabase';
import './EditableInput.css';

export function RealtimeDatabaseEditableInput({updatePath, ...props}) {
  return (
    <EditableInput
      onChange={({name, value, done}) => {
        RealtimeDatabase.instance
          .child(updatePath)
          .update(value)
          .then(done, error => {
            console.error('Error updating', error);
            return false;
          });
      }}
      {...props}
    />
  );
}

class EditableInput extends Component {
  constructor(props) {
    super(props);

    checkForProp('name', this);
    checkForProp('value', this);
    checkForProp('onChange', this);

    this.state = {
      name: this.props.name,
      value: this.props.value, // value can't be null. if value is null, we get an "uncontrolled input" error
    };

    this.formRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({value: this.props.value});
    }
  }

  onFocus(event) {
    this.setState({isEditing: true});
  }

  onChange(event) {
    console.log(event.target.value);
    this.setState({value: event.target.value});
  }

  onBlur(event) {
    this.save();
  }

  onSubmit(event) {
    event.preventDefault();
    this.save();
  }

  save() {
    // blur input
    // this.inputRef.current.blur();

    // not editing
    this.setState({isEditing: false});

    // ignore if same value
    if (this.state.value === this.props.value) return;

    this.setState({isSaving: true}, () => {
      this.props.onChange({
        name: this.state.name,
        value: this.state.value,
        done: result => {
          let hasError = result === false;
          let value = hasError ? this.props.value : this.state.value; // reset value if there was an error
          if (!this.formRef.current) return; // component is gone
          this.setState({isSaving: false, hasError, value});
        },
      });
    });
  }

  render() {
    const {name, placeholder, disabled, type, small, noInline, className, value, onChange, ...props} = this.props;
    return (
      <form
        ref={this.formRef}
        onSubmit={this.onSubmit.bind(this)}
        onFocus={this.onFocus.bind(this)}
        onBlur={this.onBlur.bind(this)}
        className={'editable' + (noInline ? '' : ' d-inline-block')}>
        <div className={'input-group' + (small ? ' input-group-sm' : '')}>
          <input
            ref={this.inputRef}
            id={name}
            name={name}
            value={unwrap(
              this.state.value,
              value => value,
              () => '',
            )}
            placeholder={placeholder}
            onChange={this.onChange.bind(this)}
            size={this.state.value ? String(this.state.value).length + 2 : placeholder ? placeholder.length + 2 : 8}
            type={type}
            className={joinClassNames(
              'form-control text-dark',
              noInline && 'd-inline w-auto',
              this.state.hasError && 'error',
              className,
            )}
            disabled={disabled || this.state.isSaving}
            {...props}
          />

          {(this.state.isEditing || this.state.isSaving || this.state.hasError) && (
            <div className="input-group-append">
              {this.state.isEditing && (
                <button type="submit" className="btn btn-primary mr-2">
                  <i className="fas fa-save" />
                </button>
              )}

              {this.state.isSaving && (
                <button className="btn btn-primary">
                  <i className="fas fa-circle-notch fa-spin" />
                </button>
              )}

              {this.state.hasError && !this.state.isEditing && (
                <span className="text-danger input-group-text">Error</span>
              )}
            </div>
          )}
        </div>
      </form>
    );
  }
}

export default EditableInput;
