import React, {useEffect, useState} from 'react';
import Map from '../../../components/GoogleMaps/GoogleMap';
import MapContents from '../../../components/GoogleMaps/MapContents';
import ViewInFirebase from '../../../components/ViewInFirebase';
import unwrap from '../../../functions/unwrap';
import usePrevious from '../../../hooks/usePrevious';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';
import ControlDetails from './ControlDetails';

export default function Control({path}) {
  // HOOKS

  const geofences = useRealtimeDatabase({path});
  const previousGeofences = usePrevious(geofences);

  // STATE

  const [circles, setCircles] = useState();
  const [selectedGeofenceId, setSelectedGeofenceId] = useState();
  const [mapContents, setMapContents] = useState();
  const [mapCenter, setMapCenter] = useState();

  // EFFECT: if the path changes, unselect the current geofence

  useEffect(() => {
    setSelectedGeofenceId(null);
  }, [path]);

  // EFFECT: geofences data -> circles

  useEffect(() => {
    if (geofences.data) {
      const circles = Object.values(geofences.data).map((geofence, i) => {
        const id = Object.keys(geofences.data)[i];
        return {
          id,
          center: {
            lat: geofence.centerCoordinate[0],
            lng: geofence.centerCoordinate[1],
          },
          radiusM: geofence.radius,
          editable: id === selectedGeofenceId,
          onRadiusChanged: radiusM => {
            geofences.update({
              [`${id}/radius`]: radiusM,
            });
          },
          onCenterChanged: center => {
            geofences.update({
              [`${id}/centerCoordinate`]: [center.lat(), center.lng()],
            });
          },
          onClick: () => setSelectedGeofenceId(id),
          strokeColor: geofence.canRequestRides ? undefined : '#636363',
          fillColor: geofence.canRequestRides ? undefined : '#636363',
        };
      });
      setCircles(circles);
    } else {
      setCircles(null);
    }
  }, [geofences, selectedGeofenceId]);

  // EFFECT: geofences data -> map contents

  useEffect(() => {
    if (geofences.data) {
      if (geofences.key === previousGeofences.key) return;
      let bounds = new window.google.maps.LatLngBounds();
      Object.values(geofences.data).forEach(geofence => {
        const googleCircle = new window.google.maps.Circle({
          center: {
            lat: geofence.centerCoordinate[0],
            lng: geofence.centerCoordinate[1],
          },
          radius: geofence.radius,
        });
        bounds = bounds.union(googleCircle.getBounds());
      });
      setMapContents(
        new MapContents.Bounds({
          id: 'geofences',
          latLngBounds: bounds,
          name: 'All geofences',
        }),
      );
    } else {
      setMapContents(null);
    }
  }, [geofences, previousGeofences]);

  // ON: click add -> create new geofence

  const onClickAdd = event => {
    event.preventDefault();

    geofences.update({
      [geofences.reference.push().key]: {
        canRequestRides: false,
        centerCoordinate: [mapCenter.lat(), mapCenter.lng()],
        radius: 100000,
      },
    });
  };

  // ON: click delete -> delete

  const onClickDelete = event => {
    event.preventDefault();

    const name = geofences.data[selectedGeofenceId].name
      ? `"${geofences.data[selectedGeofenceId].name}"`
      : 'this unamed';
    if (!window.confirm(`Delete ${name} geofence? You cannot undo this`)) {
      return;
    }
    setSelectedGeofenceId(null);
    geofences.reference.child(selectedGeofenceId).remove();
  };

  // RENDER

  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        <button onClick={onClickAdd} className="btn btn-primary">
          <i className="fas fa-plus mr-1" />
          Add
        </button>
      </div>

      <div style={{height: '500px'}} className="mb-3">
        <Map
          onCenterChanged={setMapCenter}
          circles={circles}
          mapTypeControl={false}
          streetViewControl={false}
          rotateControl={false}
          fullscreenControl={false}
          mapContents={mapContents}
          className="mb-3"
        />
      </div>

      <ControlDetails
        geofenceData={unwrap(geofences.data, data => unwrap(data[selectedGeofenceId]))}
        onUpdate={(key, value) => geofences.update({[`${selectedGeofenceId}/${key}`]: value})}
        onClickDelete={onClickDelete}
      />

      <ViewInFirebase path={`${path}/${selectedGeofenceId || ''}`} />
    </div>
  );
}
