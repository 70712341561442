import Price from '../../../models/stripe/Price';
import StripePage from '../../../models/stripe/StripePage';
import HttpReference from '../../platform/HttpReference';
import StripePrices from './StripePrices';

export default class DeliverySubscriptionPrice extends HttpReference<StripePage<Array<Price>>, void, StripePrices> {
  get() {
    return this.request();
  }
}
