import firebase from 'firebase/app';
import moment from 'moment';
import React, {useMemo, useState} from 'react';
import joinClassNames from '../../../../functions/joinClassNames';
import MomentHelper from '../../../../functions/MomentHelper';
import unwrap from '../../../../functions/unwrap';
import RealtimeDatabase from '../../../../references/database/RealtimeDatabase';

export default function ConfirmButton(props: {
  tripId: string;
  customerId: string;
  driverConfirmationTimestampMs?: number;
}) {
  const [isUpdating, setIsUpdating] = useState(false);

  function toggleTripConfirmation() {
    const {tripId, customerId} = props;
    const update = {
      driverConfirmationTimestampMs: props.driverConfirmationTimestampMs
        ? null
        : firebase.database.ServerValue.TIMESTAMP,
    };
    setIsUpdating(true);
    RealtimeDatabase.instance.trips
      .child(customerId)
      .child(tripId)
      .update(update)
      .catch(error => {
        window.alert(`Error changing confirmation. "${error.message}"`);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  }

  const confirmationDateString: string = useMemo(
    () =>
      unwrap(props.driverConfirmationTimestampMs, ts => MomentHelper.ifValid(moment(ts), m => ` ${m.calendar()}`)) ||
      '',
    [props.driverConfirmationTimestampMs],
  );

  return (
    <button
      type="button"
      onClick={toggleTripConfirmation}
      className={joinClassNames(
        'btn btn-sm',
        props.driverConfirmationTimestampMs ? 'btn-success' : 'btn-outline-success',
      )}
      disabled={isUpdating}>
      {props.driverConfirmationTimestampMs ? `✓ Confirmed${confirmationDateString}` : 'Confirm'}
    </button>
  );
}
