import React from 'react';
import Card from '../../../components/Card';

export default function Dashboard() {
  return (
    <Card padded>
      <h1>Admin Portal</h1>
      <h2 className="lead">a.k.a. Admin Database (AD)</h2>
      <p>
        Select an item from the menu on the left side. If you can't see what you're looking for, ask Tim for access.
      </p>
    </Card>
  );
}
