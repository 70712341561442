import omit from 'lodash/omit';
import moment from 'moment';
import React, {useMemo} from 'react';
import {match} from 'react-router-dom';
import {useStatus} from 'wave-common';
import {tripLogRead} from 'wave-common/lib/controllers/TripLog';
import {momentFormatDateTimeSeconds} from 'wave-common/lib/helpers/MomentHelper';
import Card from '../../../components/Card';
import {DelaySpinner} from '../../../components/Spinner';
import ViewInFirebase from '../../../components/ViewInFirebase';
import FirestoreDataSource from '../../../data-sources/FirestoreDataSource';

export default function TripLog({match}: {match: match<{objectId: string}>}) {
  const {objectId} = match.params;

  const {
    isPending,
    error,
    value: log,
  } = useStatus(
    React as never,
    useMemo(() => tripLogRead(objectId, FirestoreDataSource), [objectId]),
  );

  return (
    <Card padded>
      <div className="row">
        <div className="col">
          <h3>Log {objectId}</h3>
        </div>
        <div className="col-auto">
          <ViewInFirebase firestorePath={`/trip-logs/${objectId}`} />
        </div>
      </div>

      {isPending ? (
        <DelaySpinner />
      ) : error ? (
        error.message
      ) : log ? (
        <div>
          <h5>{momentFormatDateTimeSeconds(moment(log.timestampMs))}</h5>
          <pre>{JSON.stringify(omit(log, 'id', 'object', 'timestampMs'), null, 2)}</pre>
          <hr />
          <pre>{JSON.stringify(log.object, null, 2)}</pre>
        </div>
      ) : (
        'No data'
      )}
    </Card>
  );
}
