export class Pin {
  constructor(title, coords, address, name) {
    this.title = title;
    this.coords = coords;
    this.address = address;
    this.name = name;
  }
}

export class CustomPin {
  constructor(title, coords, iconSrc) {
    this.title = title;
    this.coords = coords;
    this.src = iconSrc;
  }
}
