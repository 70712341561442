import 'bootstrap';
import $ from 'jquery';
import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';
import configuration from 'wave-common/lib/Configuration';
import './App.css';
import AlertModal from './components/Alert/AlertModal';
import {AlertContextProvider} from './contexts/AlertContext';
import {AuthContextProvider} from './contexts/AuthContext';
import {MinimalUiContextProvider} from './contexts/MinimalUiContext';
import {NewTripContextProvider} from './contexts/NewTripContext';
import {ThemeColorContextWrapper} from './contexts/ThemeColorContext';
import TitleContext from './contexts/TitleContext';
import FirestoreDataSource from './data-sources/FirestoreDataSource';
import RealtimeDatabaseDataSource from './data-sources/RealtimeDatabaseDataSource';
import withErrorBoundary from './hocs/withErrorBoundary';
import withTheme from './hocs/withTheme';
import Routes from './routes';
import BootstrapModalContext from './routes/BootstrapModalContext';
import {Provider as LocalStorageStoreProvider} from './stores/localStorageStore';

configuration.database = RealtimeDatabaseDataSource.instance;
configuration.firestore = FirestoreDataSource;

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  require('./styles/bootstrap.scss');
} else if (process.env.REACT_APP_ENVIRONMENT === 'sandbox') {
  require('./styles/bootstrap-sandbox.scss');
} else {
  require('./styles/bootstrap-development.scss');
}

function App() {
  const [modalContent, setModalContent] = useState();
  const [alert, setAlert] = useState();

  const [title, setTitle] = useState();

  // show modal when set

  useEffect(() => {
    if (modalContent) {
      // console.log('Showing modal. Content was was set')
      $('#rootModal').modal('show');
    }
  }, [modalContent]);

  // unset modal when hidden

  useEffect(() => {
    const listener = event => {
      // console.log('Removing modal. Modal was hidden')
      setModalContent(null);
    };
    $('#rootModal').on('hidden.bs.modal', listener);
    return () => $('#rootModal').off('hidden.bs.modal', listener);
  });

  // when setting modal, check if modal needs to be hidden, first

  const hideAndSetModalContent = content => {
    if (!content) {
      // console.log('Hiding modal. Content was removed')
      $('#rootModal').modal('hide');
      $('#rootModal').one('hidden.bs.modal', event => {
        if (!modalContent) {
          // console.log('Removing modal. After modal hidden')
          setModalContent(content);
        }
      });
    } else {
      // console.log('Setting modal')
      setModalContent(content);
    }
  };

  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <TitleContext.Provider value={{title, setTitle}}>
          <AlertContextProvider value={{alert, setAlert}}>
            <BootstrapModalContext.Provider
              value={{
                setModalContent: hideAndSetModalContent,
                hasModalContent: Boolean(modalContent),
              }}>
              <AuthContextProvider>
                <LocalStorageStoreProvider>
                  <ThemeColorContextWrapper>
                    <NewTripContextProvider>
                      <MinimalUiContextProvider>
                        {/* <DropTargetContextProvider> */}
                        <Routes />

                        {modalContent && (
                          <div
                            id="rootModal"
                            className="modal fade"
                            tabIndex="-1"
                            aria-labelledby="rootModalLabel"
                            aria-hidden="true">
                            <div className="modal-dialog modal-xl modal-dialog-centered">
                              <div className="modal-content">{modalContent}</div>
                            </div>
                          </div>
                        )}

                        <AlertModal alert={alert} setAlert={setAlert} />
                        {/* </DropTargetContextProvider> */}
                      </MinimalUiContextProvider>
                    </NewTripContextProvider>
                  </ThemeColorContextWrapper>
                </LocalStorageStoreProvider>
              </AuthContextProvider>
            </BootstrapModalContext.Provider>
          </AlertContextProvider>
        </TitleContext.Provider>
      </QueryParamProvider>
    </BrowserRouter>
  );
}

export default withErrorBoundary(withTheme(App));

// export default function Test() {
//   return <LocalStorageStoreProvider>
//     <SubscriptionCard />
//   </LocalStorageStoreProvider>
// }
