import React, {useEffect, useState} from 'react';
import bg1 from '../../images/home-coursel/bg_1.jpg';
import bg2 from '../../images/home-coursel/bg_2.jpg';
import bg3 from '../../images/home-coursel/bg_3.jpg';
import bg4 from '../../images/home-coursel/bg_4.jpg';
import './background-image-carousel.css';

const images = [bg1, bg2, bg3, bg4];

const INTERVAL_MS = 5000;

export default function BackgroundImageCarousel() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const newIndex = index < images.length - 1 ? index + 1 : 0;
      setIndex(newIndex);
    }, INTERVAL_MS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [index]);

  return images.map((image, i) => (
    <div key={i} style={{backgroundImage: `url(${image})`}} className={'fadeable' + (i === index ? ' faded-in' : '')} />
  ));
}
