import InvoiceFormula from '../additional-content-components/InvoiceFormula';
import Property from '../types/Property';
import PropertyType from '../types/PropertyType';
import Schema from '../types/Schema';

const invoiceFormulaSchema = new Schema(
  'invoice-formula',
  [
    new Property('name', PropertyType.string),
    new Property('minimumD', PropertyType.number, {
      userFacingName: 'Minimum',
      isOptional: true,
    }),
    new Property('items', PropertyType.pricingFormulaItem, {
      isArray: true,
      userFacingName: 'Pricing items',
    }),
  ],
  InvoiceFormula,
);

export default invoiceFormulaSchema;
