import {auth} from 'firebase/app';
import React, {useCallback, useEffect, useState} from 'react';
import {expect, tripShortName, useStatus} from 'wave-common';
import {scrape} from 'wave-common/lib/controllers/scrapers';
import {vendorIdHasScraper, vendorIdLemonade} from 'wave-common/lib/models/Vendor';
import {useAuthContext} from '../../contexts/AuthContext';
import {environmentCurrentWaveCommon, environmentVariable} from '../../Environment';
import joinClassNames from '../../functions/joinClassNames';
import {default as Trip, default as TripClass} from '../../models/scoopm/Trip';
import {TripSource} from '../../models/scoopm/TripSource';
import {useSelectedVendorId} from '../../stores/localStorageStore';
import {FontAwesomeV5} from '../FontAwesome';

export default function DropZone({
  source,
  setTrip,
}: {
  source: TripSource;
  setTrip: React.Dispatch<React.SetStateAction<Partial<TripClass>>>;
}) {
  // state

  const {user, claims} = useAuthContext();
  let [selectedVendorId] = useSelectedVendorId();

  // HACK to make Lemonade the default id for our admins, so that they can use the lemonade scraper

  if (claims?.manageTrips) {
    selectedVendorId = vendorIdLemonade(environmentCurrentWaveCommon);
  }

  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const {
    isPending: scraperIsPending,
    error: scraperError,
    value: trips,
    handlePromise: handleScraperPromise,
  } = useStatus<TripClass[]>(React as never);
  const [hasSetTrip, setHasSetTrip] = useState(false);

  // callbacks

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const onDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    setIsDraggedOver(true);
  }, []);

  const onDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    setIsDraggedOver(false);
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      // finish drag

      setIsDraggedOver(false);

      // create async handler

      const handler = async () => {
        let files: File[];

        // get file

        if (event.dataTransfer.items) {
          files = [];
          for (const item of event.dataTransfer.items) {
            if (item.kind !== 'file') {
              throw new Error('Dropped item is not a file');
            }
            const file = item.getAsFile();
            if (!file) {
              throw new Error('Unable to open file');
            }
            files.push(file);
          }
        } else {
          files = [...event.dataTransfer.files];
        }

        // check file type

        if (files.find(file => file.type !== 'application/pdf')) {
          throw new Error('Incorrect file type. You must use a PDF file');
        }

        // check for only one at a time

        if (files.length !== 1) {
          throw new Error('Only one file should be uploaded at a time');
        }

        // scrape

        try {
          const trip = await scrape(
            expect(selectedVendorId, 'selected vendor id'),
            files[0],
            expect(user, 'user'),
            environmentVariable('SCRAPER_BASE_URL'),
          );
          return [
            new TripClass({
              ...Trip.forManualEntry(source),
              ...trip,
              customerId: auth().currentUser?.uid,
            }),
          ];
        } catch (error) {
          console.error(error);
          throw new Error('Unable to parse file');
        }
      };

      // run handler

      handleScraperPromise(handler());
    },
    [handleScraperPromise, selectedVendorId, user, source],
  );

  const onChangeFileInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const handler = async () => {
        try {
          const file = expect(event.target.files?.item(0), 'file');
          return scrape(
            expect(selectedVendorId, 'selected vendor id'),
            file,
            expect(user, 'user'),
            environmentVariable('SCRAPER_BASE_URL'),
          ).then(trip => [
            new TripClass({
              ...Trip.forManualEntry(source),
              ...trip,
              customerId: auth().currentUser?.uid,
            }),
          ]);
        } catch (error) {
          console.error(error);
          throw new Error('Unable to parse file');
        }
      };

      handleScraperPromise(handler());
    },
    [selectedVendorId, user, handleScraperPromise, source],
  );

  // if only one trip returned, fill out the field automatically. otherwise the user must select which trip they want to use

  useEffect(() => {
    if (trips?.length === 1) {
      setHasSetTrip(true);
      setTrip(trips[0]);
    }
  }, [setTrip, trips]);

  // render

  if (!selectedVendorId) {
    return null;
  }

  if (!vendorIdHasScraper(selectedVendorId, environmentCurrentWaveCommon)) {
    return null;
  }

  if (!user) {
    return null;
  }

  return (
    <div
      className={joinClassNames(
        'position-relative rounded p-5 mb-5',
        isDraggedOver ? 'border-primary bg-primary-light' : 'bg-light',
      )}
      style={{
        border: '2px dashed gray',
      }}>
      {
        <div className="row align-items-center">
          <div className="col-auto">
            <h1 className="mb-0">
              {scraperIsPending ? (
                <FontAwesomeV5 name="circle-notch" className="fa-spin" />
              ) : scraperError ? (
                <FontAwesomeV5 name="xmark-circle" className="text-danger" />
              ) : trips ? (
                trips.length > 1 ? (
                  <FontAwesomeV5 name="question-circle" className="text-muted" />
                ) : (
                  <FontAwesomeV5 name="check-circle" className="text-success" />
                )
              ) : (
                // <span className="fa-stack fa-2x">
                //   <i className="fa-solid fa-square fa-stack-2x" />
                //   <i className="fa-solid fa-file-pdf fa-stack-1x fa-inverse" />
                //   <i className="fa-solid fa-mouse-pointer fa-stack-1x fa-inverse" />
                // </span>
                <>
                  <FontAwesomeV5 name="file-pdf" /> <FontAwesomeV5 name="mouse-pointer" className="fa-xs" />
                </>
              )}
            </h1>
          </div>
          <div className="col-lg-6 mb-2 mb-lg-0">
            {scraperIsPending ? (
              'Processing file...'
            ) : scraperError ? (
              scraperError.message
            ) : trips ? (
              hasSetTrip ? (
                'Review the details, then submit the form to create the order'
              ) : trips.length > 1 ? (
                <div>
                  <p>Select which invoice to use</p>
                  <div className="row">
                    {trips.map(trip => (
                      <div key={trip.id} className="col-auto">
                        <button
                          type="button"
                          onClick={() => {
                            setHasSetTrip(true);
                            setTrip(trip);
                          }}
                          className="btn btn-primary position-relative"
                          style={{
                            zIndex: 1,
                          }}>
                          {/* <FontAwesomeV5 name="input-text" /> */}
                          Use <strong>{trip.orderId ?? tripShortName(trip as never)}</strong>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                `Success. Check the form and submit when ready.`
              )
            ) : (
              'Drag and drop an invoice PDF here. The scraper will read the invoice, and fill out this form'
            )}
          </div>
          <div className="col">
            <div className="custom-file">
              <input
                type="file"
                id="drop-zone-input"
                accept="application/pdf"
                onChange={onChangeFileInput}
                className="custom-file-input"
                disabled={scraperIsPending}
              />
              <label className="custom-file-label" htmlFor="drop-zone-input">
                {hasSetTrip ? 'Chose a different file...' : 'Choose file...'}
              </label>
            </div>
          </div>
        </div>
      }
      <div
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        className="position-absolute"
        style={{top: 0, bottom: 0, left: 0, right: 0, zIndex: isDraggedOver ? 100000 : 0}}
      />
    </div>
  );
}
