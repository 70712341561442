export const DbConstants = {
  status: {
    approved: {identifier: 'approved'},
    pending: {identifier: 'pending'},
    suspended: {identifier: 'suspended'},
    rejected: {identifier: 'rejected'},
  },
  types: {
    scoopM: {identifier: 'scoopM', name: 'ScoopM Regular'},
    scoopXl: {identifier: 'scoopXl', name: 'ScoopXL'},
    nanny: {identifier: 'nanny', name: 'ScoopM Nanny'},
    butler: {identifier: 'butler', name: 'ScoopM Butler'},
    runner: {identifier: 'runner', name: 'ScoopM Runner'},
    taxi: {identifier: 'taxi', name: 'ScoopM Taxi'},
    heli: {identifier: 'heli', name: 'ScoopM Heli'},
  },
};
