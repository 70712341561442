import moment from 'moment';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import unwrap from '../../functions/unwrap';
import DriverSelect from '../DriverSelect';
import DropdownSelect from '../DropdownSelect';
import FilterType from '../FirestoreTable/FilterType';

export default function FilterComponent({filter, onChangeFilter, disabled}) {
  switch (filter.type) {
    case FilterType.boolean:
      return (
        <div className="custom-control custom-switch">
          <input
            id={filter.name + 'Switch'}
            type="checkbox"
            onChange={event => onChangeFilter(filter, event.target.checked)}
            className="custom-control-input"
            checked={filter.value || false}
            disabled={disabled}
          />
          <label htmlFor={filter.name + 'Switch'} className="custom-control-label">
            {filter.value ? 'Yes' : 'No'}
          </label>
        </div>
      );

    case FilterType.singleValue:
      return (
        <select
          id={`${filter.field}Select`}
          value={filter.value || ''}
          onChange={event => onChangeFilter(filter, event.target.value)}
          className="custom-select custom-select"
          disabled={disabled}>
          <option value="">Any</option>
          {filter.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      );

    case FilterType.array:
      return (
        <DropdownSelect
          options={filter.options}
          selectedValues={filter.value}
          onChange={values => onChangeFilter(filter, values.length === 0 ? null : values)}
          buttonClassName="btn w-100 text-left"
          className="d-block"
          disabled={disabled}
        />
      );

    case FilterType.dateRange:
      const isTodayOnly = filter.value === 'TODAY_ONLY';

      const startDate = isTodayOnly ? null : unwrap(filter.value, value => unwrap(value[0], ts => new Date(ts)));
      const startTimestamp = unwrap(startDate, date => date.valueOf());

      const endDate = isTodayOnly ? null : unwrap(filter.value, value => unwrap(value[1], ts => new Date(ts)));
      const endTimestamp = unwrap(endDate, date => date.valueOf());

      const onUpdate = (startTimestamp, endTimestamp) => {
        if (!startTimestamp && !endTimestamp) {
          onChangeFilter(filter, null);
        } else {
          onChangeFilter(filter, [startTimestamp, endTimestamp]);
        }
      };

      return (
        <div>
          <div className="form-row">
            <div className="col">
              <ReactDatePicker
                selected={startDate}
                onChange={date => onUpdate(date.valueOf(), endTimestamp)}
                wrapperClassName="d-block mb-1"
                className="form-control form-control"
                placeholderText="Start date"
                popperPlacement="top-end"
                disabled={disabled || isTodayOnly}
              />
            </div>
            {startDate && (
              <div className="col-auto">
                <button
                  type="button"
                  onClick={event => onUpdate(null, endTimestamp)}
                  className="btn btn-light btn ml-1">
                  <i className="fas fa-minus" />
                </button>
              </div>
            )}
          </div>

          <div className="form-row">
            <div className="col">
              <ReactDatePicker
                selected={endDate}
                onChange={date => onUpdate(startTimestamp, moment(date).endOf('day').valueOf())}
                wrapperClassName="d-block mb-1"
                className="form-control form-control"
                placeholderText="End date"
                popperPlacement="top-end"
                disabled={disabled || isTodayOnly}
              />
            </div>
            {endDate && (
              <div className="col-auto">
                <button
                  type="button"
                  onClick={event => onUpdate(startTimestamp, null)}
                  className="btn btn-light btn ml-1">
                  <i className="fas fa-minus" />
                </button>
              </div>
            )}
          </div>

          <div className="form-row">
            <div className="col">
              <div className="custom-control custom-switch">
                <input
                  id={filter.name + 'TodayOnlySwitch'}
                  type="checkbox"
                  checked={isTodayOnly}
                  onChange={event => onChangeFilter(filter, isTodayOnly ? null : 'TODAY_ONLY')}
                  className="custom-control-input"
                  disabled={disabled}
                />
                <label htmlFor={filter.name + 'TodayOnlySwitch'} className="custom-control-label">
                  Today only
                </label>
              </div>
            </div>
          </div>
        </div>
      );

    case FilterType.driverId:
      return (
        <DriverSelect
          value={filter.value}
          onChange={driverId => onChangeFilter(filter, driverId)}
          defaultText="Any"
          className="custom-select custom-select"
          disabled={disabled}
        />
      );

    default:
      return null;
  }
}
