import firebase from 'firebase/app';
import moment from 'moment';
import React, {useMemo} from 'react';
import Card from '../../../components/Card';
import GoogleMap from '../../../components/GoogleMaps/GoogleMap';
import MapContents from '../../../components/GoogleMaps/MapContents';
import RawData from '../../../components/RawData';
import {ViewInFirebaseLink} from '../../../components/ViewInFirebase';
import useFirestoreListener from '../../../hooks/useFirestoreListener';
import FirestoreBounds from '../../../models/scoopm/FirestoreBounds';
import TripDirectionsSelect from '../rides/TripDirectionsSelect';

export default function Directions({match}) {
  const reference = useMemo(
    () => firebase.firestore().collection('directions').doc(match.params.directionsId),
    [match.params.directionsId],
  );

  const {snapshot, isLoading} = useFirestoreListener(reference);

  const data = snapshot && snapshot.data();

  if (isLoading) return null;
  if (!data) return 'No data';

  return (
    <Card className="h-100" innerClassName="h-100 d-flex flex-column" padded>
      <h4>Directions</h4>

      <div className="form-group">
        <TripDirectionsSelect value={match.params.directionsId} tripId={data.tripId} />
      </div>

      <table className="table table-sm table-bordered table-hover mb-0 w-auto align-self-start">
        <tbody>
          <tr>
            <td>Created</td>
            <td>{moment.unix(data.creationTimestamp.seconds).toLocaleString()}</td>
          </tr>
          <tr>
            <td>Legs</td>
            <td>
              {data.legs
                .map(leg => `${Math.round((leg.durationS / 3600) * 100) / 100} hours, ${leg.distanceM} meters`)
                .join('; ')}
            </td>
          </tr>
          <tr>
            <td>Trip ID</td>
            <td>{data.tripId}</td>
          </tr>
        </tbody>
      </table>

      <div className="my-3">
        <ViewInFirebaseLink path={snapshot && `/directions/${snapshot.id}`} firestore>
          View in Firestore
        </ViewInFirebaseLink>
      </div>

      <div className="flex-grow-1" style={{minHeight: '20rem'}}>
        <GoogleMap
          paths={data.legs.map((leg, i) => ({
            id: 'PATH' + i,
            googlePath: window.google.maps.geometry.encoding.decodePath(leg.encodedPath),
          }))}
          mapContents={
            new MapContents.Bounds({
              id: match.params.directionsId,
              latLngBounds: FirestoreBounds.prototype.gsmLatLngBounds.apply(data.bounds),
            })
          }
          rectangles={[
            {
              bounds: FirestoreBounds.prototype.gsmLatLngBounds.apply(data.bounds),
              options: {
                fillColor: 'transparent',
                strokeColor: '#ff6666',
              },
            },
          ]}
        />
      </div>

      <RawData data={data} defaultIsCollapsed />
    </Card>
  );
}
