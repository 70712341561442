export function forin(obj, callback) {
  let i = 0;
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const element = obj[key];
      let result = callback(element, i);
      if (result === false) break;
      i++;
    }
  }
}
