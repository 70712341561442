import React from 'react';
import download_on_app_store from '../../images/download_on_app_store.svg';

// https://developer.apple.com/app-store/marketing/guidelines/#section-badges

export default function DownloadOnAppStore({driver = false}) {
  return (
    <a
      href={
        driver
          ? 'https://apps.apple.com/au/app/wavem-driver/id1450981584'
          : 'https://apps.apple.com/us/app/wave-formerly-scoopm/id1440492089'
      }
    >
      <img src={download_on_app_store} alt="Download on the App Store" />
    </a>
  );
}
