import firebase from 'firebase/app';
import first from 'lodash/first';
import sortBy from 'lodash/sortBy';
import React, {useEffect, useRef, useState} from 'react';
import Delay from '../../components/Delay';
import Spinner from '../../components/Spinner';
import Constants from '../../Constants';
import unwrap from '../../functions/unwrap';
import usePrevious from '../../hooks/usePrevious';
import Driver from '../../models/scoopm/Driver';
import InvalidFeedback from './InvalidFeedback';

export default function UidInput({property, id, value, onChange, disabled, validationError}) {
  const containerDiv = useRef();

  const previousValue = usePrevious(value);

  const [userEntries, setUserEntries] = useState();
  const [fetchError, setfetchError] = useState();
  const [query, setQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState();
  const [highlightedUserEntry, setHighlightedUserEntry] = useState();
  const [selectedUserEntry, setSelectedUserEntry] = useState();

  // EFFECTS

  // body click listener

  useEffect(() => {
    if (filteredUsers) {
      const listener = event => {
        if (containerDiv.current !== event.target && !window.$(containerDiv.current).has(event.target).length) {
          setFilteredUsers(null);
          setQuery('');
          // event.preventDefault()
          // event.stopPropagation()
        }
      };
      window.document.body.addEventListener('click', listener);
      return () => {
        window.document.body.removeEventListener('click', listener);
      };
    }
  }, [filteredUsers]);

  // fetch users

  useEffect(() => {
    firebase
      .database()
      .ref('users')
      .once('value')
      .then(
        snapshot => {
          let entries = Object.entries(snapshot.val());
          entries = entries
            .filter(([uid, user]) => user.firstName && user.lastName)
            .map(([uid, user]) => [
              uid,
              Object.assign(user, {
                searchString: [user.firstName, user.lastName, uid, user.email, user.cellphone || user.phone]
                  .filter(Boolean)
                  .map(s => String(s).toLowerCase())
                  .join(''),
                fullName:
                  Driver.prototype.fullName.apply(user) +
                  ` ${unwrap(user.email || user.cellphone || user.phone, s => `(${s})`, '')}`,
              }),
            ]);
          setUserEntries(entries);
          unwrap(
            entries.find(([uid, user]) => uid === value),
            setSelectedUserEntry,
          );
          setfetchError(null);
        },
        error => {
          console.error('Error getting users', error);
          setfetchError(error);
        },
      );
  }, [value]);

  // value --> selection

  useEffect(() => {
    if (value !== previousValue) {
      setSelectedUserEntry(userEntries ? unwrap(userEntries.find(([uid, user]) => uid === value)) : null);
    }
  }, [value, previousValue, userEntries]);

  // filter users

  useEffect(() => {
    if (!userEntries) {
      setFilteredUsers(null);
    } else if (!query) {
      setFilteredUsers(null);
    } else {
      const queryLowercase = query.toLowerCase();
      let users = userEntries.filter(([uid, user]) => user.searchString.indexOf(queryLowercase) !== -1);
      users = sortBy(users, ([uid, user]) => user.searchString.indexOf(queryLowercase));
      users = users.slice(0, 5);
      if (users.length) {
        setFilteredUsers(users);
      } else {
        setFilteredUsers(null);
      }
    }
  }, [userEntries, query]);

  // when new filtered users, set highlighted user

  useEffect(() => {
    if (filteredUsers && !highlightedUserEntry) {
      setHighlightedUserEntry(first(filteredUsers));
    }
  }, [filteredUsers, highlightedUserEntry]);

  // when selecting user, clear search results, call callback, clear query

  useEffect(() => {
    if (selectedUserEntry) {
      setFilteredUsers(null);
    }
    setQuery(unwrap(selectedUserEntry, entry => entry[1].fullName) || '');
    onChange && onChange(unwrap(selectedUserEntry, ([uid]) => uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserEntry]);

  // RENDER

  if (fetchError) {
    return 'Error. ' + fetchError.message;
  }

  if (!userEntries) {
    return (
      <Delay>
        <Spinner small />
      </Delay>
    );
  }

  const highlightedUid = unwrap(highlightedUserEntry, entry => entry[0]);
  const selectedUid = unwrap(selectedUserEntry, entry => entry[0]);

  return (
    <div ref={containerDiv} className="position-relative">
      <input
        type="search"
        value={query}
        onChange={event => setQuery(event.target.value)}
        onKeyDown={event => {
          if (event.nativeEvent.code === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
          }
          if (filteredUsers) {
            switch (event.nativeEvent.code) {
              case 'Enter': {
                const highLightedIndex = filteredUsers.indexOf(highlightedUserEntry);
                setSelectedUserEntry(filteredUsers[highLightedIndex]);
                break;
              }
              case 'ArrowDown': {
                const highLightedIndex = filteredUsers.indexOf(highlightedUserEntry);
                const newIndex = highLightedIndex + 1;
                if (newIndex === filteredUsers.length) return;
                setHighlightedUserEntry(filteredUsers[newIndex]);
                break;
              }
              case 'ArrowUp': {
                const highLightedIndex = filteredUsers.indexOf(highlightedUserEntry);
                const newIndex = highLightedIndex - 1;
                if (newIndex === -1) return;
                setHighlightedUserEntry(filteredUsers[newIndex]);
                break;
              }
              default: {
                break;
              }
            }
          }
        }}
        placeholder="Type user's name..."
        className={'form-control' + unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid'), '')}
        disabled={!userEntries || disabled}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
      <InvalidFeedback error={validationError} property={property} />
      {filteredUsers && (
        <div
          style={{zIndex: Constants.zIndex.level1}}
          className="w-100 position-absolute bg-white shadow rounded border">
          {filteredUsers.map(([uid, user]) => (
            <div
              key={uid}
              onClick={event => setSelectedUserEntry([uid, user])}
              className={
                'p-2 cursor-pointer bg-hover-light' +
                (uid === selectedUid ? ' bg-gray-200' : uid === highlightedUid ? ' bg-light' : '')
              }>
              {user.fullName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
