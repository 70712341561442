import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useStatus} from 'wave-common';
import Spinner from '../../components/Spinner';
import useIsAdmin from '../../hooks/useIsAdmin';
import TripStatus from '../../models/scoopm/TripStatus';
import ScoopMApi from '../../references/scoopm-api';

export function ButtonsRow({tripId, model}) {
  const {isAdmin} = useIsAdmin();
  const {isPending: cancelIsPending, handlePromise: handleCancelPromise} = useStatus(React);

  const onClickCancel = useCallback(
    event => {
      event.preventDefault();

      if (!window.confirm('Cancel this ride?')) {
        return;
      }

      handleCancelPromise(
        ScoopMApi.instance.trips
          .child(tripId)
          .cancel()
          .catch(error => {
            window.alert(`Unable to cancel trip. ${error.reason ? error.message : 'Please try again later'}`);
          }),
      );
    },
    [tripId, handleCancelPromise],
  );

  return (
    <div className="row my-4">
      {model && TripStatus.notDone().includes(model.statusType()) && (
        <div className="col-auto">
          <button
            type="button"
            onClick={onClickCancel}
            className="btn btn-outline-danger rounded-pill px-3"
            disabled={cancelIsPending}>
            {cancelIsPending ? (
              <span>
                <Spinner small /> Canceling
              </span>
            ) : (
              'Cancel ride'
            )}
          </button>
        </div>
      )}
      {isAdmin && model && (
        <div className="col-auto">
          <Link to={`/admin/jobs/${model.customerId}/${tripId}`} className="btn btn-link">
            View in Admin Portal
          </Link>
        </div>
      )}
    </div>
  );
}
