import React from 'react';
import Delay from './Delay';

export default function Spinner({className, small = false}: {className?: string; small?: boolean}) {
  return (
    <div
      role="status"
      className={['spinner-border fade-in', small ? 'spinner-border-sm' : '', className || ''].join(' ')}
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export function SpinnerCentered({...props}) {
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <Spinner {...props} />
    </div>
  );
}

export function DelaySpinner({
  amountMs,
  small = false,
  childrenBeforeTimeout,
  className,
}: {
  amountMs?: number;
  small?: boolean;
  childrenBeforeTimeout?: any;
  className?: string;
}) {
  return (
    <Delay amountMs={amountMs} childrenBeforeTimeout={childrenBeforeTimeout}>
      <Spinner small={small} className={className} />
    </Delay>
  );
}
