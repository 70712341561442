import React, { Component } from 'react';
import { LegalText } from './LegalText';

export class TermsAndConditionsPage extends Component {
  render() {
    return <LegalText>{this.theTerms()}</LegalText>;
  }

  theTerms() {
    return (
      <div>
        <p>&nbsp;</p>

        <h1 className="text-center">TERMS AND CONDITIONS</h1>

        <p>&nbsp;</p>

        <p style={{ textAlign: 'center' }}>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>Wave Mobile Technologies</strong>
          </span>
        </p>
        <p style={{ textAlign: 'center' }}>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>Terms And Conditions &amp; Privacy Policy</strong>
          </span>
        </p>
        <p style={{ textAlign: 'center' }}>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'small',
            }}
          >
            <strong>
              <br />
            </strong>
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong className="h3">1. CONTRACTUAL RELATIONSHIP</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            These Terms of Use ("<em>Terms</em>") govern the access or use by you, an individual, from within the United
            States and its territories and possessions of applications, websites, content, products, and services (the "
            <em>Services</em>") made available in the United States and its territories and possessions by Wave Mobile Technologies
            and its subsidiaries and affiliates (collectively, "<em>Wave Mobile Technologies</em>"). PLEASE READ THESE TERMS
            CAREFULLY BEFORE ACCESSING OR USING THE SERVICES. In these Terms, the words "including" and "include" mean
            "including, but not limited to."
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Your access and use of the Services constitutes your agreement to be bound by these Terms, which establishes
            a contractual relationship between you and Wave Mobile Technologies. If you do not agree to these Terms, you may not access or
            use the Services. These Terms expressly supersede prior agreements or arrangements with you. Wave Mobile Technologies may
            immediately terminate these Terms or any Services with respect to you, or generally cease offering or deny
            access to the Services or any portion thereof, at any time for any reason.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Supplemental terms may apply to certain Services, such as policies for a particular event, activity or
            promotion, and such supplemental terms will be disclosed to you in connection with the applicable
            Service(s). Supplemental terms are in addition to, and shall be deemed a part of, the Terms for the purposes
            of the applicable Service(s). Supplemental terms shall prevail over these Terms in the event of a conflict
            with respect to the applicable Services.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies may amend the Terms related to the Services from time to time. Amendments will be effective upon
            Wave Mobile Technologies's posting of such updated Terms at this location or the amended policies or supplemental terms on the
            applicable Service(s). Your continued access or use of the Services after such posting constitutes your
            consent to be bound by the Terms, as amended.
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong className="h3">2. THE SERVICES</strong>
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            The Services constitute a technology platform that enables users of Wave Mobile Technologies's mobile applications or websites
            provided as part of the Services (each, an "<em>Application</em>") to arrange and schedule transportation
            and/or logistics services with third party providers of such services, including independent third party
            transportation providers and third party logistics providers under agreement with Wave Mobile Technologies or certain of
            Wave Mobile Technologies's affiliates ("
            <em>Third Party Providers</em>"). Unless otherwise agreed by Wave Mobile Technologies in a separate written agreement with
            you, the Services are made available solely for your personal, noncommercial use. YOU ACKNOWLEDGE THAT
            Wave Mobile Technologies DOES NOT PROVIDE TRANSPORTATION OR LOGISTICS SERVICES OR FUNCTION AS A TRANSPORTATION CARRIER.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies DOES NOT PROVIDE TRANSPORTATION SERVICES, AND Wave Mobile Technologies IS NOT A TRANSPORTATION CARRIER. IT IS UP TO THE
            DRIVER TO DECIDE WHETHER OR NOT TO OFFER A RIDE TO A RIDER CONTACTED THROUGH THE Wave Mobile Technologies SERVICES, AND IT IS
            UP TO THE RIDER TO DECIDE WHETHER OR NOT TO ACCEPT A RIDE FROM ANY DRIVER CONTACTED THROUGH Wave Mobile Technologies. ANY
            DECISION BY A USER TO OFFER OR ACCEPT SERVICES ONCE SUCH USER IS MATCHED THROUGH Wave Mobile Technologies IS A DECISION MADE
            IN SUCH USER&rsquo;S SOLE DISCRETION. EACH TRANSPORTATION SERVICE PROVIDED BY A DRIVER TO A RIDER SHALL
            CONSTITUTE A SEPARATE AGREEMENT BETWEEN SUCH PERSONS.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>LICENSE</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Subject to your compliance with these Terms, Wave Mobile Technologies grants you a limited, non-exclusive, non-sublicensable,
            revocable, non-transferrable license to: (i) access and use the Applications on your personal device solely
            in connection with your use of the Services; and (ii) access and use any content, information and related
            materials that may be made available through the Services, in each case solely for your personal,
            noncommercial use. Any rights not expressly granted herein are reserved by Wave Mobile Technologies and Wave Mobile Technologies's licensors.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>RESTRICTIONS</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            You may not: (i) remove any copyright, trademark or other proprietary notices from any portion of the
            Services; (ii) reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell,
            resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the
            Services except as expressly permitted by Wave Mobile Technologies; (iii) decompile, reverse engineer or disassemble the
            Services except as may be permitted by applicable law; (iv) link to, mirror or frame any portion of the
            Services; (v) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or
            otherwise data mining any portion of the Services or unduly burdening or hindering the operation and/or
            functionality of any aspect of the Services; or (vi) attempt to gain unauthorized access to or impair any
            aspect of the Services or its related systems or networks.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>THIRD PARTY SERVICES AND CONTENT</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            The Services may be made available or accessed in connection with third party services and content
            (including advertising) that Wave Mobile Technologies does not

 control. You acknowledge that different terms of use and
            privacy policies may apply to your use of such third party services and content. Wave Mobile Technologies does not endorse
            such third party services and content and in no event shall Wave Mobile Technologies be responsible or liable for any products
            or services of such third party providers. Additionally, Apple Inc., Google, Inc., Microsoft Corporation or
            BlackBerry Limited will be a third-party beneficiary to this contract if you access the Services using
            Applications developed for Apple iOS, Android, Microsoft Windows, or Blackberry-powered mobile devices,
            respectively. These third party beneficiaries are not parties to this contract and are not responsible for
            the provision or support of the Services in any manner. Your access to the Services using these devices is
            subject to terms set forth in the applicable third party beneficiary's terms of service.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>OWNERSHIP</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            The Services and all rights therein are and shall remain Wave Mobile Technologies's property or the property of Wave Mobile Technologies's
            licensors. Neither these Terms nor your use of the Services convey or grant to you any rights: (i) in or
            related to the Services except for the limited license granted above; or (ii) to use or reference in any
            manner Wave Mobile Technologies's company names, logos, product and service names, trademarks or services marks or those of
            Wave Mobile Technologies's licensors.
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong className="h3">3. YOUR USE OF THE SERVICES</strong>
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>USER ACCOUNTS</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            In order to use most aspects of the Services, you must register for and maintain an active personal user
            Services account ("
            <em>Account</em>"). Wave Mobile Technologies requires all Users under the age of 18 to obtain parent, or guardian,
            authorization to create an Account and to use Wave Mobile Technologies Services. Account registration requires you to submit
            to Wave Mobile Technologies certain personal information, such as your name, address, mobile phone number and age, as well as
            at least one valid payment method (either a credit card or accepted payment partner). You agree to maintain
            accurate, complete, and up-to-date information in your Account. Your failure to maintain accurate, complete,
            and up-to-date Account information, including having an invalid or expired payment method on file, may
            result in your inability to access and use the Services or Wave Mobile Technologies's termination of this Agreement with you.
            You are responsible for all activity that occurs under your Account, and you agree to maintain the security
            and secrecy of your Account username and password at all times. Unless otherwise permitted by Wave Mobile Technologies in
            writing, you may only possess one Account.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>USER REQUIREMENTS AND CONDUCT</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            You may not assign or otherwise transfer your Account to any other person or entity. You agree to comply
            with all applicable laws when using the Services, and you may only use the Services for lawful purposes (
            <em>e.g.</em>, no transport of unlawful or hazardous materials). You will not in your use of the Services
            cause nuisance, annoyance, inconvenience, or property damage, whether to the Third Party Provider or any
            other party. In certain instances you may be asked to provide proof of identity to access or use the
            Services, and you agree that you may be denied access to or use of the Services if you refuse to provide
            proof of identity.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            In addition to the actions stated in the prior section, with respect to your use of your Account and your
            participation in the Wave Mobile Technologies Services, you agree that you will not (the list of activities is not exclusive):
          </span>
        </p>
        <ol>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                stalk, threaten, or otherwise harass any person, or carry any weapons;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                violate any law, statute, ordinance or regulation;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                interfere with or disrupt the Services or the Wave Mobile Technologies Platform or the servers or networks connected to
                Wave Mobile Technologies services;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                forge headers or otherwise manipulate identifiers in order to disguise the origin of any information
                transmitted through Wave Mobile Technologies;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                &ldquo;frame&rdquo; or &ldquo;mirror&rdquo; any part of Wave Mobile Technologies, without our prior written authorization
                or use meta tags or code or other devices containing any reference to us in order to direct any person
                to any other web site for any purpose;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                rent, lease, lend, sell, redistribute, license or sublicense the Wave Mobile Technologies Platform or access to any
                portion of the Wave Mobile Technologies Platform;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                create liability for us or cause us to become subject to regulation as a transportation carrier or
                provider of taxi service; or
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                cause any third party to engage in the restricted activities above.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            We reserve the right, but we have no obligation, to suspend or deactivate your Account if you do not comply
            with these prohibitions.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>PROMOTIONAL CODES</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies may, in Wave Mobile Technologies's sole discretion, create promotional codes that may be redeemed for Account credit,
            or other features or benefits related to the Services and/or a Third Party Provider's services, subject to
            any additional terms that Wave Mobile Technologies establishes on a per promotional code basis ("<em>Promo Codes</em>"). You
            agree that Promo Codes: (i) must be used for the intended audience and purpose, and in a lawful manner; (ii)
            may not be duplicated, sold or transferred in any manner, or made available to the general public (whether
            posted to a public form or otherwise), unless expressly permitted by Wave

 Mobile Technologies; (iii) may be disabled by Wave Mobile Technologies
            at any time for any reason without liability to Wave Mobile Technologies; (iv) may only be used pursuant to the specific terms
            that Wave Mobile Technologies establishes for such Promo Code; (v) are not valid for cash; and (vi) may expire prior to your
            use. Wave Mobile Technologies reserves the right to withhold or deduct credits or other features or benefits obtained through
            the use of Promo Codes by you or any other user in the event that Wave Mobile Technologies determines or believes that the use
            or redemption of the Promo Code was in error, fraudulent, illegal, or in violation of the applicable Promo
            Code terms or these Terms.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>USER PROVIDED CONTENT</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies may, in Wave Mobile Technologies's sole discretion, permit you from time to time to submit, upload, publish or
            otherwise make available to Wave Mobile Technologies through the Services textual, audio, and/or visual content and
            information, including commentary and feedback related to the Services, initiation of support requests, and
            submission of entries for competitions and promotions ("<em>User Content</em>"). Any User Content provided
            by you remains your property. However, by providing User Content to Wave Mobile Technologies, you grant Wave Mobile Technologies a worldwide,
            perpetual, irrevocable, transferrable, royalty-free license, with the right to sublicense, to use, copy,
            modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in
            any manner such User Content in all formats and distribution channels now known or hereafter devised
            (including in connection with the Services and Wave Mobile Technologies's business and on third-party sites and services),
            without further notice to or consent from you, and without the requirement of payment to you or any other
            person or entity.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            You represent and warrant that: (i) you either are the sole and exclusive owner of all User Content or you
            have all rights, licenses, consents and releases necessary to grant Wave Mobile Technologies the license to the User Content
            as set forth above; and (ii) neither the User Content nor your submission, uploading, publishing or
            otherwise making available of such User Content nor Wave Mobile Technologies's use of the User Content as permitted herein
            will infringe, misappropriate or violate a third party's intellectual property or proprietary rights, or
            rights of publicity or privacy, or result in the violation of any applicable law or regulation.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            You agree to not provide User Content that is defamatory, libelous, hateful, violent, obscene, pornographic,
            unlawful, or otherwise offensive, as determined by Wave Mobile Technologies in its sole discretion, whether or not such
            material may be protected by law. Wave Mobile Technologies may, but shall not be obligated to, review, monitor, or remove User
            Content, at Wave Mobile Technologies's sole discretion and at any time and for any reason, without notice to you.
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong className="h3">4. PAYMENT</strong>
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            You understand that use of the Services may result in charges to you for the services or goods you receive
            from a Third Party Provider ("
            <em>Charges</em>"). After you have received services or goods obtained through your use of the Service,
            Wave Mobile Technologies will facilitate your payment of the applicable Charges on behalf of the Third Party Provider, as such
            Third Party Provider's limited payment collection agent. Payment of the Charges in such manner shall be
            considered the same as payment made directly by you to the Third Party Provider. Charges will be inclusive
            of applicable taxes where required by law. Charges paid by you are final and non-refundable, unless
            otherwise determined by Wave Mobile Technologies.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            All Charges are due immediately and payment will be facilitated by Wave Mobile Technologies using the preferred payment method
            designated in your Account, after which Wave Mobile Technologies will send you a receipt by text, email, or in the user
            account. If your primary Account payment method is determined to be expired, invalid or otherwise not able
            to be charged, you agree that Wave Mobile Technologies may, as the Third Party Provider's limited payment collection agent,
            use a secondary payment method in your Account, if available.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            As between you and Wave Mobile Technologies, Wave Mobile Technologies reserves the right to establish, remove and/or revise Charges for any or
            all services or goods obtained through the use of the Services at any time in Wave Mobile Technologies's sole discretion.
            Further, you acknowledge you will be responsible for Charges incurred under your Account regardless of your
            awareness of such Charges or the amounts thereof. Wave Mobile Technologies may from time to time provide certain users with
            promotional offers and discounts that may result in different amounts charged for the same or similar
            services or goods obtained through the use of the Services, and you agree that such promotional offers and
            discounts, unless also made available to you, shall have no bearing on your use of the Services or the
            Charges applied to you. You may elect to cancel your request for services or goods from a Third Party
            Provider at any time prior to such Third Party Provider's arrival, in which case you may be charged a
            cancellation fee.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            This payment structure is intended to fully compensate the Third Party Provider for the services or goods
            provided. Wave Mobile Technologies does not designate any portion of your payment as a tip or gratuity to the Third Party
            Provider. Any representation by Wave Mobile Technologies (on Wave Mobile Technologies's website, in the Application, or in Wave Mobile Technologies's marketing
            materials) to the effect that tipping is "voluntary," "not required," and/or "included" in the payments you
            make for services or goods provided is not intended to suggest that Wave Mobile Technologies provides any additional amounts,
            beyond those described above, to the Third Party Provider. You understand and agree that, while you are free
            to provide additional payment as a gratuity to any Third Party Provider who provides you with services or
            goods obtained through the Service, you are under no obligation to do so. Gratuities are voluntary. After
            you have received services or goods obtained through the Service, you will have the opportunity to rate your
            experience and leave additional feedback about your Third Party Provider.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>TECHNOLOGY FEE</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies shall assess a per-ride &ldquo;Tech Fee&rdquo; to all transactions for use of Services, as stated in
            receipts. Tech Fee collections are used in offsetting Wave Mobile Technologies&rsquo;s expenses, including but not limited to
            promoting safety, business research, expansion into other markets and geographic locations, in addition to
            other operational costs.
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong className="h3">5. DISCLAIMERS; LIMITATION OF LIABILITY; INDEMNITY; RELEASE</strong>
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>DISCLAIMER</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            THE SERVICES ARE PROVIDED "

AS IS" AND "AS AVAILABLE." Wave Mobile Technologies DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES,
            EXPRESS, IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, Wave Mobile Technologies MAKES NO
            REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, OR
            AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE
            SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. Wave Mobile Technologies DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR
            ABILITY OF THIRD PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES,
            AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT
            PERMITTED UNDER APPLICABLE LAW.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>LIMITATION OF LIABILITY</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES,
            INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR
            OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, EVEN IF Wave Mobile Technologies HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. Wave Mobile Technologies SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR
            RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR
            RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF Wave Mobile Technologies HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES. Wave Mobile Technologies SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND
            Wave Mobile Technologies'S REASONABLE CONTROL. YOU ACKNOWLEDGE THAT THIRD PARTY TRANSPORTATION PROVIDERS PROVIDING
            TRANSPORTATION SERVICES REQUESTED THROUGH SOME REQUEST BRANDS MAY OFFER RIDESHARING OR PEER-TO-PEER
            TRANSPORTATION SERVICES AND MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED. IN NO EVENT SHALL Wave Mobile Technologies'S
            TOTAL LIABILITY TO YOU IN CONNECTION WITH THE SERVICES FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED
            FIVE HUNDRED U.S. DOLLARS (US $500).
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies'S SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE TRANSPORTATION, GOODS, OR LOGISTICS SERVICES
            WITH THIRD PARTY PROVIDERS, BUT YOU AGREE THAT Wave Mobile Technologies HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO
            ANY TRANSPORTATION, GOODS OR LOGISTICS SERVICES PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER THAN AS
            EXPRESSLY SET FORTH IN THESE TERMS.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A
            CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>INDEMNITY</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            You agree to indemnify and hold Wave Mobile Technologies and its officers, directors, employees, and agents harmless from any
            and all claims, demands, losses, liabilities, and expenses (including attorneys' fees), arising out of or in
            connection with: (i) your use of the Services or services or goods obtained through your use of the
            Services; (ii) your breach or violation of any of these Terms; (iii) Wave Mobile Technologies's use of your User Content; or
            (iv) your violation of the rights of any third party, including Third Party Providers.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>RELEASE</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            In the event that you have a dispute with one or more Users, you agree to release Wave Mobile Technologies (including our
            affiliates and each of our respective officers, directors, employees, agents, and suppliers) from claims,
            demands and damages of every kind and nature, known and unknown, suspected and unsuspected, disclosed and
            undisclosed, arising out of or in any way connected to such disputes with other Users or to your use of the
            Wave Mobile Technologies Platform or participation in the Services. Furthermore, you expressly waive any rights you may have
            under California Civil Code Section 1542 (or analogous laws of other states), which says: &ldquo;A general
            release does not extend to claims which the creditor does not know or suspect to exist in his ride at the
            time of executing the release, which, if known by him must have materially affected his settlement with the
            debtor.&rdquo; We reserve the right, but have no obligation, to monitor disputes between you and other
            Users.
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong className="h3">6. DISPUTE RESOLUTION &nbsp;</strong>
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>ARBITRATION</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            You and We agree that any legal disputes or claims arising out of or related to the Agreement (including but
            not limited to the use of Wave Mobile Technologies and/or the Services, or the interpretation, enforceability, revocability,
            or validity of the Agreement, or the arbitrability of any dispute), that cannot be resolved informally shall
            be submitted to binding arbitration in the state in which the Agreement was performed. The arbitration shall
            be conducted by the American Arbitration Association under its Commercial Arbitration Rules, or as otherwise
            mutually agreed by you and we. Any judgment on the award rendered by the arbitrator may be entered in any
            court having jurisdiction thereof. Claims shall be brought within the time required by applicable law. You
            and we agree that any claim, action or proceeding arising out of or related to the Agreement must be brought
            in your individual capacity, and not as a plaintiff or class member in any purported class, collective, or
            representative proceeding. The arbitrator may not consolidate more than one person's claims, and may not
            otherwise preside over any form of a representative, collective, or class proceeding.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            YOU ACKNOWLEDGE AND AGREE THAT YOU AND Wave Mobile Technologies ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
            PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>CHANGES</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Notwithstanding the provisions of the modification-related provisions above, if Wave Mobile Technologies changes this "Dispute
            Resolution" section after the date you first accepted these Terms (or accepted any subsequent changes to
            these Terms), you may reject any such change by providing Wave Mobile Technologies written notice of such rejection by mail or
            hand delivery to: Wave Mobile Technologies, 333 West Trade Street, Charlotte, NC 28202, or by email from the
           

 email address associated with your Account to: legal@wavemobiletechnologies.com, within 30 days of the date such change
            became effective, as indicated in the "Last update" date above. In order to be effective, the notice must
            include your full name and clearly indicate your intent to reject changes to this "Dispute Resolution"
            section. By rejecting changes, you are agreeing that you will arbitrate any Dispute between you and Wave Mobile Technologies
            in accordance with the provisions of this "Dispute Resolution" section as of the date you first accepted
            these Terms (or accepted any subsequent changes to these Terms).
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong className="h3">7. CLAIMS OF COPYRIGHT INFRINGEMENT</strong>
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>&nbsp;</strong>
            <strong>PROPRIETARY RIGHTS AND TRADEMARK LICENSE</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            All intellectual property rights of Wave Mobile Technologies shall be owned by us absolutely and in their entirety. These
            rights include and are not limited to database rights, copyright, design rights (whether registered or
            unregistered), trademarks (whether registered or unregistered) and other similar rights wherever existing in
            the world together with the right to apply for protection of the same. All other trademarks, logos, service
            marks, company or product names set forth by Wave Mobile Technologies are the property of their respective owners. You
            acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information
            (&ldquo;Submissions&rdquo;) provided by you to us are non-confidential and shall become the sole property of
            Wave Mobile Technologies. Wave Mobile Technologies shall own exclusive rights, including all intellectual property rights, and shall be entitled
            to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise,
            without acknowledgment or compensation to you.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies, and other Wave Mobile Technologies logos, designs, graphics, icons, scripts and service names are registered
            trademarks, trademarks or trade dress of Wave Mobile Technologies in the United States and/or other countries (collectively,
            the &ldquo;Wave Mobile Technologies Marks&rdquo;). If you provide Services as a Driver, Wave Mobile Technologies grants to you, during the term
            of this Agreement, and subject to your compliance with the terms and conditions of this Agreement, a
            limited, revocable, non-exclusive license to display and use the Wave Mobile Technologies Marks solely in connection with
            providing the Services through the Wave Mobile Technologies Platform (&ldquo;License&rdquo;). The License is non-transferable
            and non-assignable, and you shall not grant to any third party any right, permission, license or sublicense
            with respect to any of the rights granted hereunder without Wave Mobile Technologies&rsquo;s prior written permission, which
            it may withhold in its sole discretion. The Wave Mobile Technologies Marks may not be used in any manner that is likely to
            cause confusion.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            You acknowledge that Wave Mobile Technologies is the owner and licensor of the Wave Mobile Technologies Marks, and that your use of the Wave Mobile Technologies
            Marks will confer no additional interest in or ownership of the Wave Mobile Technologies Marks in you but rather used on your
            behalf to the benefit of Wave Mobile Technologies. You agree to use the Wave Mobile Technologies Marks strictly in accordance with
            Wave Mobile Technologies&rsquo;s Trademark Usage Guidelines, as may be provided to you and revised from time to time, and to
            immediately cease any use that Wave Mobile Technologies determines to nonconforming or otherwise unacceptable.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            You agree that you will not:
          </span>
        </p>
        <ol>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                create any materials that incorporate the Wave Mobile Technologies Marks or any derivatives of the Wave Mobile Technologies Marks other than
                as expressly approved by Wave Mobile Technologies in writing;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                use the Wave Mobile Technologies Marks in any way that tends to impair their validity as proprietary trademarks, service
                marks, trade names or trade dress, or use the Wave Mobile Technologies Marks other than in accordance with the terms,
                conditions and restrictions herein;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                take any other action that would jeopardize or impair Wave Mobile Technologies&rsquo;s rights as owner of the Wave Mobile Technologies Marks
                or the legality and/or enforceability of the Wave Mobile Technologies Marks, including, without limitation, challenging or
                opposing Wave Mobile Technologies&rsquo;s ownership in the Wave Mobile Technologies Marks;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                apply for trademark registration or renewal of trademark registration of any of the Wave Mobile Technologies Marks, any
                derivative of the Wave Mobile Technologies Marks, any combination of the Wave Mobile Technologies Marks and any other name, or any
                trademark, service mark, trade name, symbol or word which is similar to the Wave Mobile Technologies Marks; or
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                use the Wave Mobile Technologies Marks on or in connection with any product, service or activity that is in violation of
                any law, statute, government regulation or standard.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Violation of any provision of this License may result in immediate termination of the License, in
            Wave Mobile Technologies&rsquo;s sole discretion. If you create any materials bearing the Wave Mobile Technologies Marks (in violation of this
            Agreement or otherwise), you agree that upon their creation Wave Mobile Technologies exclusively owns all right, title and
            interest in and to such materials, including without limitation any modifications to the Wave Mobile Technologies Marks or
            derivative works based on the Wave Mobile Technologies Marks. You further agree to assign any interest or right you may have
            in such materials to Wave Mobile Technologies, and to provide information and execute any documents as reasonably requested by
            Wave Mobile Technologies to enable Wave Mobile Technologies to formalize such assignment.
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong className="h3">8. PRIVACY POLICY</strong>
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies is dedicated to protecting your personal information and informing you about how we use it. This
            privacy policy applies to transactions and activities and data gathered through the Wave Mobile Technologies Platform. Please
            review this privacy policy periodically as Wave Mobile Technologies may revise it without notice. This privacy policy was last
            revised on&nbsp;November 8, 2015. Each time you use your Account, use the Wave Mobile Technologies Services, or provide Wave Mobile Technologies
            with information, by doing so you are accepting the practices described in this privacy policy at that time.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>DATA WE COLLECT FROM YOU</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            In order to operate Wave Mobile Technologies and to provide you with information about products or services that may

 be of
            interest to you, Wave Mobile Technologies may collect &ldquo;personal information&rdquo; (i.e. information that could be used
            to contact you directly (without using the Wave Mobile Technologies services) such as full name, postal address, phone number,
            credit/debit card information, or email address) or &ldquo;demographic information&rdquo; (i.e. information
            that you submit, or that we collect, that is not personal information; this may include, but is not limited
            to, zip code, hometown, gender, username, age/birth date, browsing history information, searching history
            information, and registration history information). Wave Mobile Technologies will also collect the contact information of your
            friends, if you choose to connect your contacts and address book information with Wave Mobile Technologies and your login
            credentials to your social network accounts, such as Facebook and Twitter, if you choose to connect those
            accounts with your Wave Mobile Technologies account. You represent and warrant that you have the authority to provide Wave Mobile Technologies
            with any such contact information. Demographic information is divided into two categories:
          </span>
        </p>
        <ol>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                &ldquo;non-public information&rdquo;, which consists of ride transaction information and one-on-one
                communications between you and other Users of Wave Mobile Technologies; and
              </span>
            </p>
          </li>
          <li>
            <p>
              <span
                style={{
                  fontFamily: 'arial, helvetica, sans-serif',
                  fontSize: 'medium',
                }}
              >
                &ldquo;public information&rdquo;, which consists of all other demographic information.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Please note that nowhere on the Wave Mobile Technologies Platform does Wave Mobile Technologies knowingly collect, keep or maintain personal
            information from children under the age of 18, as Wave Mobile Technologies require that all users represent to us that they
            are at least 18 years old.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>HOW WE USE PERSONAL INFORMATION</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            We use your email address and your other personal information to help us efficiently operate Wave Mobile Technologies, to
            contact you in connection with your transactions and other activities provided by Wave Mobile Technologies (including, but not
            limited to, confirmation emails, or important news that could affect your relationship with Wave Mobile Technologies), to
            forward trip information to you from other Users, to forward trip information from you to other Users, and
            to contact you and others to suggest potential matches. We use your contact information to find and connect
            with your friends (when instructed by you). These types of communications are known as &ldquo;Operational
            Communications.&rdquo; In some cases, Operational Communications may also contain commercial messages, such
            as banner ads and special offers.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            To operate Wave Mobile Technologies, including processing your transactions and supporting your activities on the Wave Mobile Technologies
            Platform, we may share your personal information with our agents, representatives, contractors and service
            providers so they can provide us with support services such as email origination, receipt or support
            services, customer relationship management services, and order fulfillment. We require these entities not to
            use your information for any other purpose.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            By purchasing, or registering or making reservations for, products or services offered or sponsored by third
            parties of Wave Mobile Technologies, or electing to receive communications (such as emails or material by mail) or electing to
            participate in contests, sweepstakes or other programs (such as discount or rewards programs), offered or
            sponsored by third parties on Wave Mobile Technologies, you consent to our providing your personal information to those third
            parties. Those third parties may use your personal information in accordance with their own privacy
            policies. You will need to contact those third parties to instruct them directly regarding your preferences
            for the use of your personal information by them. Additionally, you agree that we may use and disclose all
            such information so submitted to such third parties in the same manner in which we are entitled to use and
            disclose any other information you submit to us.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Any third party with whom we are allowed to share your personal information is authorized to use your
            personal information in accordance with Our contractual arrangements with such third parties and in
            accordance with their own privacy policies, over which we have no control, and you agree that we are not
            responsible or liable for any of their actions or omissions. Those who contact you will need to be
            instructed directly by you regarding your preferences for the use of your personal information by them.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>HOW WE USE DEMOGRAPHIC DATA</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            We may review all demographic Data. We may use public information to enable other users to search your
            profile, to determine whether your trip details fit other user's requirements, and to communicate with you.
            We may use demographic information to tailor Wave Mobile Technologies and communications to your interests. We may also share
            demographic information with advertisers on an anonymous and aggregated basis (i.e., without telling the
            advertisers your identity). One of the reasons we may do this is to increase the likelihood that our
            advertisers' goods and services will appeal to you as a user of Wave Mobile Technologies. Our sharing of demographic
            information with advertisers is anonymous (i.e., we do not tell advertisers which particular Wave Mobile Technologies Users
            are members of which demographic groups), subject to the rest of this privacy policy. When you respond to an
            advertisement, however, we ask you to remember that if that ad that is targeted to a demographic group and
            you decide to give the advertiser your personal information, then the advertiser may be able to identify you
            as being a member of that demographic group.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>HOW TO EDIT YOUR INFORMATION</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies provides you with the ability to access and edit your personal information. To update your personal
            info, click Settings in the Wave Mobile Technologies menu. There you can view, update and correct your account information.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            So that we can protect the integrity of sensitive data, there are certain pieces of information, such as
            your age, that you cannot alter yourself.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Our databases automatically update any personal information you edit in your profile, or that you request we
            edit. Information transmitted through boards, chats, polls or through any other means remains in our
            databases and become the property of Wave Mobile Technologies upon submission. Keep this in mind if you decide to communicate
            personal information through any of these applications.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>INFORMATION RETENTION</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            To preserve the integrity of our databases, standard procedure calls for Wave Mobile Technologies to retain information
            submitted by members for an indefinite length of time. Wave Mobile Technologies understands your submissions as consent to
           

 store all your information in one place for this indefinite length of time, if we so wish. If required by
            law, as is the case to comply with the Children's Online Privacy Protection Act (COPPA), Wave Mobile Technologies will nullify
            member information by erasing it from our database. Wave Mobile Technologies will also respond to written member requests to
            nullify account information. Also, by using Wave Mobile Technologies, you do hereby represent and warrant that you understand
            and agree that all information submitted by you through Wave Mobile Technologies or otherwise to Wave Mobile Technologies becomes the property
            of Wave Mobile Technologies and may be used in the sole discretion of Wave Mobile Technologies in accordance with this Privacy Policy and the
            Terms of Use.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>CHOICE/OPT-OUT</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies provides Users the opportunity to opt-out of receiving communications from Wave Mobile Technologies and our partners at
            the point where we request information about the visitor. Wave Mobile Technologies gives users the option to remove their
            information from our database, to not receive future communications or to no longer receive our service.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>SPECIAL CASES IN WHICH WE SHARE PERSONAL INFORMATION</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Your personal information may be passed on to a third party in the event of a transfer of ownership or
            assets, or a bankruptcy. Wave Mobile Technologies may also disclose personal information when we determine that such
            disclosure is necessary to comply with applicable law, to cooperate with law enforcement or to protect the
            interests or safety of Wave Mobile Technologies or other visitors to Wave Mobile Technologies. Wave Mobile Technologies also may disclose your personal
            information to our subsidiary and parent companies and businesses, and other affiliated legal entities and
            businesses with whom Wave Mobile Technologies is under common corporate control. Whenever personal information is disclosed
            under this paragraph, we may also disclose your demographic information along with it, on a non-anonymous
            basis. All of our parent, subsidiary and affiliated legal entities and businesses that receive your personal
            information or non-anonymous demographic information from Wave Mobile Technologies will comply with the terms of this privacy
            policy with respect to their use and disclosure of such information.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>HOW WE PROTECT INFORMATION</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            We implement security measures designed to protect your information from unauthorized access. Your account
            is protected by your account password and we urge you to take steps to keep your personal information safe
            by not disclosing your password and by logging out of your account after each use. We further protect your
            information from potential security breaches by implementing certain technological security measures
            including encryption, firewalls and secure socket layer technology. However, these measures do not guarantee
            that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and
            secure server software. By using our Service, you acknowledge that you understand and agree to assume these
            risks.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies may contain links to other web sites. Wave Mobile Technologies is not responsible for the privacy practices of other
            web sites. Wave Mobile Technologies encourages Users to be aware when they leave the Wave Mobile Technologies Account to read the privacy
            statements of each and every web site that collects personally identifiable information. This Privacy Policy
            applies solely to information collected by the Wave Mobile Technologies Platform.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>CHANGING OUR PRIVACY POLICY FOR PREVIOUSLY GATHERED INFORMATION</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            If at any point Wave Mobile Technologies decides to use particular personally identifiable information in a manner materially
            different from that stated at the time it was collected, Wave Mobile Technologies will notify Users by way of an email or by
            providing 30 days notice on the Wave Mobile Technologies Platform. We also encourage you to review this privacy policy
            periodically. By using Wave Mobile Technologies, you do hereby represent and warrant that you have read, understand and agree
            to all terms of Agreement. Each time you use Wave Mobile Technologies, you agree to all terms set forth in this Agreement and
            any other policies published by Wave Mobile Technologies on the Wave Mobile Technologies Platform. Please note that Wave Mobile Technologies will continue to have
            the right to change its privacy policy and practices, and how Wave Mobile Technologies uses your personally identifiable
            information, without notice, as described in herein, provided that such changes shall only apply to
            information gathered on or after the date of the change.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>CONTACTING Wave Mobile Technologies</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us by
            sending an email to&nbsp;<em>legal@wavemobiletechnologies.com</em>.
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong className="h3">9. OTHER PROVISIONS</strong>
          </span>
        </p>

        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>CHOICE OF LAW</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            These Terms are governed by and construed in accordance with the laws of the State of North Carolina,
            U.S.A., without giving effect to any conflict of law principles.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>NOTICE</strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            Wave Mobile Technologies may give notice by means of a general notice on the Services, electronic mail to your email address
            in your Account, or by written communication sent by first class mail or pre-paid post to your address in
            your Account. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing
            or posting (if sent by first class mail or pre-paid post) or 12 hours after sending (if sent by email). You
            may give notice to Wave Mobile Technologies, with such notice deemed given when received by Wave Mobile Technologies, at any time by first class
            mail or pre-paid post to Wave Mobile Technologies Technologies, 333 West Trade Street, Charlotte, NC 28202.
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>
              <span style={{ whiteSpace: 'pre' }} />
              GENERAL
            </strong>
          </span>
        </p>
        <p>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <span style={{ whiteSpace: 'pre' }} />
            You may not assign these Terms without Wave Mobile Technologies's prior written approval. Wave Mobile Technologies may assign these Terms
            <span style={{ whiteSpace: 'pre' }} />
            without your consent to: (i) a subsidiary or affiliate; (ii) an acquirer of Wave

 Mobile Technologies 's equity, business or
            <span style={{ whiteSpace: 'pre' }} />
            assets; or
            <span style={{ whiteSpace: 'pre' }} />
            (iii) a successor by merger. Any purported assignment in violation of this section shall be void. No joint
            venture,
            <span style={{ whiteSpace: 'pre' }} />
            partnership, employment, or agency relationship exists between you, Wave Mobile Technologies or any Third
            <span style={{ whiteSpace: 'pre' }} />
            Party Provider
            <span style={{ whiteSpace: 'pre' }} />
            as a result of this Agreement or use of the Services. If any provision of these Terms is held to be invalid
            or
            <span style={{ whiteSpace: 'pre' }} />
            unenforceable, such provision shall be struck and the remaining provisions shall be
            <span style={{ whiteSpace: 'pre' }} />
            enforced to the fullest
            <span style={{ whiteSpace: 'pre' }} />
            extent under law. Wave Mobile Technologies's failure to enforce any right or provision in these Terms shall not constitute a
            waiver
            <span style={{ whiteSpace: 'pre' }} />
            of such right or provision unless acknowledged and agreed to by Wave Mobile Technologies in writing.
          </span>
        </p>
        <p>&nbsp;</p>
        <p style={{ textAlign: 'center' }}>
          <span
            style={{
              fontFamily: 'arial, helvetica, sans-serif',
              fontSize: 'medium',
            }}
          >
            <strong>The Terms &amp; Conditions and Privacy Policy were last updated on JUNE 14, 2024.</strong>
          </span>
        </p>
      </div>
    );
  }
}