import React from 'react';
import unwrap from '../../functions/unwrap';
import InvalidFeedback from './InvalidFeedback';
import numberForString from './numberForString';
import stringForNumber from './stringForNumber';

export default function NumberInput({property, id, value, onChange, disabled, validationError}) {
  console.log({
    property,
    id,
    value,
    onChange,
    disabled,
    validationError,
  });

  return (
    <>
      <input
        id={id}
        name={id}
        type="number"
        value={stringForNumber(value)}
        onChange={event => onChange(numberForString(event.target.value))}
        className={'form-control ' + unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid'), '')}
        autoComplete="off"
        disabled={disabled}
      />
      <InvalidFeedback property={property} error={validationError} />
    </>
  );
}
