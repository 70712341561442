import DmfError from '../DmfError';
import {dmfError, dmflog} from '../functions/dmflog';
import {Auth} from './Auth';
import {Twilio} from './Twilio';
import {UserService} from './UserService';
import firebase from 'firebase/app';

export class DbRefs {
  // Use getters, because Firebase isn't initialised yet

  // GETTERS
  static database() {
    return firebase.database();
  }

  // root
  static get root() {
    return this.database().ref();
  }

  //rides
  static get allRides() {
    return this.database().ref('rides');
  }
  static get allTrips() {
    return this.database().ref('trips');
  }
  static get myRides() {
    return DbRefs.currentUser.child('rides');
  }

  //user
  static get users() {
    return this.database().ref('users');
  }
  static get currentUser() {
    if (UserService.instance.isDriver) {
      return DbRefs.currentDriver;
    } else if (UserService.instance.isCustomer) {
      return DbRefs.currentCustomer;
    } else {
      throw new Error(`No user to get current user reference`);
    }
  }
  static get currentCustomer() {
    let uid = Auth.instance.user.uid;
    return this.database().ref('customers/' + uid);
  }
  static get currentDriver() {
    return this.database().ref('drivers/' + Auth.instance.user.uid);
  }
  static get currentAdmin() {
    return this.database().ref('admins/' + Auth.instance.user.uid);
  }
  static get currentWallet() {
    return DbRefs.wallet(Auth.instance.user.uid);
  }
  //drivers
  static get allDrivers() {
    return this.database().ref(`drivers`);
  }
  // static get activeDrivers() {
  //   return this.database().ref(`active-drivers`);
  // }
  static get driversAvailable() {
    return this.database().ref(`drivers-available`);
  }
  //customers
  static get allCustomers() {
    return this.database().ref(`customers`);
  }
  //runners
  static get allRunners() {
    return this.database().ref('runners');
  }
  static get allSuspendedRunners() {
    return this.database().ref('suspended-runners');
  }
  //applications
  static get allApplications() {
    return this.database().ref('applications');
  }
  static get incompleteApplications() {
    return firebase.database().ref('applications').orderByChild(Db.fields.application.status).equalTo(null);
  }
  static get reachedOutApplications() {
    return firebase
      .database()
      .ref('applications')
      .orderByChild(Db.fields.application.status)
      .equalTo(Db.constants.application.status.reachedOut);
  }
  static get submittedApplications() {
    return firebase
      .database()
      .ref('applications')
      .orderByChild(Db.fields.application.status)
      .equalTo(Db.constants.application.status.pending);
  }
  static get approvedApplications() {
    return firebase
      .database()
      .ref('applications')
      .orderByChild(Db.fields.application.status)
      .equalTo(Db.constants.application.status.approved);
  }

  // geofire
  static get geoFire() {
    return this.database().ref(`geoFire`);
  }

  // queue
  static get queue() {
    return this.database().ref('queue');
  }

  // notes
  static get notes() {
    return this.database().ref('notes');
  }

  // FUNCTIONS
  static customer(withId) {
    return this.database().ref('customers/' + withId);
  }
  static ride(withId) {
    return this.allRides.child(withId);
  }
  static trip(customerId, tripId) {
    return this.allTrips.child(customerId).child(tripId);
  }
  static user(withId) {
    return this.database().ref('users/' + withId);
  }
  static driver(withId) {
    return this.database().ref('drivers/' + withId);
  }
  static suspendedRunner(withId) {
    return this.database().ref('suspended-runners/' + withId);
  }
  static application(withId) {
    return this.database().ref('applications/' + withId);
  }
  static wallet(withId) {
    return this.database().ref('wallets/' + withId);
  }
  static sharedRide(withToken) {
    return this.database().ref(`/sharedRides/${withToken}`);
  }
  static queuedRide(withId) {
    return this.queue.child(withId);
  }
}

export class Db {
  static fields = {
    application: {
      status: 'status',
    },
    customer: {
      currentWebRide: 'currentWebRide',
      firstName: 'firstName',
      lastName: 'lastName',
    },
    driver: {
      identifier: 'driver',
      cellphone: 'cellphone',
      coordinate: 'coordinate',
      color: 'carColor',
      currentRide: 'currentRide',
      currentQueueRide: 'currentQueueRide',
      driverId: 'driverId',
      dwollaCustomerId: 'dwollaCustomerId',
      dwollaPayoutSourceId: 'dwollaPayoutSourceId',
      firstName: 'firstName',
      inPickupModeEnabled: 'inPickupModeEnabled',
      isOnTrip: 'isOnTrip',
      isOnQueueTrip: 'isOnQueueTrip',
      lastName: 'lastName',
      licensePlateNumber: 'licensePlateNumber',
      make: 'make',
      model: 'model',
      notes: 'notes',
      phone: 'phone',
      types: 'types',
      year: 'year',
    },
    ride: {
      details: 'details',
      deadlineType: 'deadlineType',
      deadlineTimestamp: 'deadlineTimestamp',
      estimatedHours: 'estimatedHours',
      estimatedPrice: 'estimatedPrice',
      payments: 'payments',
      driverOffers: 'driverOffers',
      requesterEmail: 'requesterEmail',
      requesterName: 'requesterName',
      requesterPhone: 'requesterPhone',
      summary: 'summary',
      status: 'status',
      type: 'type',
    },
    trip: {
      createdAt: 'createdAt',
      confirmedAt: 'confirmedAt',
      deviceType: 'deviceType',
      completedAt: 'completedAt',
      customer: {
        identifier: 'customer',
        customerId: 'customerId',
        firstName: 'firstName',
        lastName: 'lastName',
        phone: 'phone',
        cellphone: 'cellphone',
      },
      driver: {
        identifier: 'driver',
        driverId: 'driverId',
        firstName: 'firstName',
        lastName: 'lastName',
        phone: 'phone',
        cellphone: 'cellphone',
      },
      fromAddress: 'fromAddress',
      startedAt: 'startedAt',
      toAddress: 'toAddress',
      status: 'status',
      type: 'type',
    },
    types: {
      scoopM: {identifier: 'scoopM', name: 'ScoopM Standard'},
      scoopXl: {identifier: 'scoopXl', name: 'ScoopXL'},
      scoopNanny: {identifier: 'scoopNanny', name: 'ScoopM Nanny'},
      scoopButler: {identifier: 'scoopButler', name: 'ScoopM Butler'},
      scoopHeli: {identifier: 'scoopHeli', name: 'ScoopM Heli'},
      scoopTaxi: {identifier: 'scoopTaxi', name: 'ScoopM Taxi'},
    },
    user: {
      communications: 'communications',
    },
  };
  static constants = {
    ride: {
      deadline: {
        type: {
          now: 'NOW',
          specificTime: 'SPECIFIC_TIME',
        },
      },
      location: {
        pickup: 'PICKUP',
        dropOff: 'DROP_OFF',
      },
      status: {
        initialized: 'INITIALIZED',
        created: 'CREATED',
        paymentError: 'PAYMENT_ERROR',
        paid: 'PAID',
        accepted: 'ACCEPTED',
        started: 'STARTED',
        canceledByDriver: 'CANCELED-BY-DRIVER',
        canceledByRider: 'CANCELED-BY-RIDER',
        completed: 'COMPLETED',
      },
      type: {
        scoopM: 'scoopM',
        scoopXl: 'scoopXl',
        scoopButler: 'scoopButler',
        scoopNanny: 'scoopNanny',
        scoopTaxi: 'scoopTaxi',
        scoopHeli: 'scoopHeli',
      },
      userType: {
        customer: 'CUSTOMER',
        driver: 'DRIVER',
      },
    },
    application: {
      status: {
        reachedOut: 'reached_out',
        pending: 'pending',
        approved: 'approved',
      },
    },
    trip: {
      deviceType: {
        android: 'Android',
      },
      status: {
        created: 'CREATED',
        accepted: 'ACCEPTED',
        started: 'STARTED',
        canceledByDriver: 'CANCELED-BY-DRIVER',
        canceledByRider: 'CANCELED-BY-RIDER',
        completed: 'COMPLETED',
      },
    },
  };

  constructor() {
    this.observers = {};
  }

  /**
   * @returns {Db}
   */
  static get instance() {
    if (typeof window.dmfDb === 'undefined') {
      window.dmfDb = new Db();
    }
    return window.dmfDb;
  }

  getUserWithEmail(emailAddress) {
    return new Promise((resolve, reject) => {
      Auth.instance
        .checkIfAccountExistsFor(emailAddress)
        .then(() => {
          return DbRefs.users.orderByChild('email').equalTo(emailAddress).once('value');
        })
        .then(userSnapshot => {
          let userContainerObject = userSnapshot.val(); //don't know why the data comes back in this form
          let uid = Object.keys(userContainerObject)[0];
          let user = userContainerObject[uid];
          if (user) {
            resolve({user, uid});
          } else {
            reject(new Error('No users match that email address'));
          }
        })
        .catch(error => reject(new DmfError(this, `getting user (email ${emailAddress})`, error)));
    });
  }

  once(databaseReference) {
    return databaseReference.once('value');
  }

  observe(databaseReference, eventType, observerName, success, failure) {
    let observer = this.observers[observerName];
    if (observer) {
      dmflog(`Db.observe: observer for "${observerName}" already exists. Replacing...`);
      observer.reference.off(observer.eventType, observer.successCallback);
    }
    this.observers[observerName] = {
      reference: databaseReference,
      eventType: eventType,
      name: observerName,
      successCallback: success,
    };
    databaseReference.on(eventType, success, error => {
      failure(error);
      throw new DmfError(this, `observing ${observerName}`, error);
    });
  }

  doneObserving() {
    for (const observerName of arguments) {
      let observer = this.observers[observerName];
      if (observer) {
        observer.reference.off(observer.eventType, observer.successCallback);
        delete this.observers[observerName];
      }
    }
  }

  doneObservingRide(id) {
    DbRefs.ride(id).off('value', this.observeRideReference);
  }

  doneObservingRides() {
    DbRefs.rides.off('value', this.observeRidesReference);
  }

  doneObservingUser() {
    DbRefs.currentUser.off('value', this.observeUserReference);
  }

  observeRide(id, success, failure) {
    this.observeRideReference = DbRefs.ride(id).on(
      'value',
      snapshot => {
        success(snapshot);
      },
      error => {
        failure(error);
      },
    );
  }

  requestRide(query) {
    return new Promise((resolve, reject) => {
      query.requester = Auth.instance.user.uid;
      query.dispatcher = Auth.instance.user.uid;
      var rideId; // scope
      this.createRideWith(query)
        .then(id => {
          rideId = id;
          return this.updateCustomerWithNewRide(rideId);
        })
        .then(() => {
          resolve(rideId);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  requestRideAsDispatcher(query) {
    return new Promise((resolve, reject) => {
      query.dispatcher = Auth.instance.user.uid;
      var rideId; // scope
      this.createRideWith(query)
        .then(rideId => {
          if (!query.dispatcher) {
            return true; //continue anyway
          } else {
            // there is an existing account for this user
            return this.updateCustomerWithNewRide(rideId);
          }
        })
        .then(() => {
          //add ride id to dispatcher's node
          let update = {};
          update[rideId] = true;
          return DbRefs.currentUser.child('createdRides').update(update);
        })
        .then(() => {
          resolve(rideId);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  createRideWith(query) {
    return new Promise((resolve, reject) => {
      let newRideRef = DbRefs.allRides.push();
      let newRideId = newRideRef.key;
      query.status = Db.constants.ride.status.created;
      newRideRef
        .update(query)
        .then(() => {
          resolve(newRideId);
        })
        .catch(error => {
          dmflog('Db.requestRide: unable to add ride to /rides:\n' + error.message);
          reject(error);
        });
    });
  }

  updateCustomerWithNewRide(rideId) {
    return DbRefs.currentUser
      .update({currentWebRide: rideId})
      .then(() => {
        let rides = {};
        rides[rideId] = true;
        return DbRefs.currentUser.child('rides').update(rides);
      })
      .catch(error => {
        dmfError(`updating user with new ride`);
        throw error;
      });
  }

  setRideAsPaid(rideId) {
    return this.update(DbRefs.ride(rideId), {
      status: Db.constants.ride.status.paid,
    });
  }

  setRidePaymentAsError(rideId) {
    return this.update(DbRefs.ride(rideId), {
      status: Db.constants.ride.status.paymentError,
    });
  }

  async driverOfferRide(rideId) {
    try {
      return await DbRefs.ride(rideId).child(Db.fields.ride.driverOffers).push(Auth.instance.user.uid);
    } catch (error) {
      throw new DmfError(this, 'updating ride as accepted', error);
    }
  }

  async riderAcceptDriver(driverId, rideId, phone) {
    try {
      await DbRefs.ride(rideId).update({
        driver: driverId,
        status: Db.constants.ride.status.accepted,
        test: 'value',
      });
    } catch (error) {
      throw new DmfError(this, `accepting a driver (${driverId}) for the ride ${rideId}`, error);
    }
    if (!phone) {
      return true; // return true for the promise to work
    }
    try {
      let driverSnap = await DbRefs.driver(driverId).once('value');
      let driver = driverSnap.val();
      return await Twilio.acceptedRide(phone, driver);
    } catch (error) {
      dmfError(error);
      // carry on anyways
    }
  }

  completeRide(rideId) {
    return new Promise((resolve, reject) => {
      DbRefs.ride(rideId)
        .update({status: Db.constants.ride.status.completed})
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
          throw new DmfError(this, 'updating ride as completed', error);
        });
    });
  }

  updateUserName(firstName, lastName) {
    return DbRefs.currentUser.update({
      firstName: firstName,
      lastName: lastName,
    });
  }

  update(ref, data) {
    return ref.update(data);
  }

  suspendRunner(uid) {
    return this.setSuspended(uid, true);
  }

  cancelSuspensionForRunner(uid) {
    return this.setSuspended(uid, false);
  }

  setSuspended(uid, suspended) {
    let update = {};
    update[`suspended-runners/${uid}`] = suspended ? true : null;
    update[`runners/${uid}`] = suspended ? null : true;
    return DbRefs.root.update(update).catch(error => {
      dmfError(`Couldn't ${suspended ? 'suspend' : 'cancel suspension'} for UID ${uid}`);
      return error;
    });
  }

  approveRunner(uid) {
    // change application status
    return DbRefs.application(uid)
      .update({status: Db.constants.application.status.approved})
      .then(() => {
        // add to runners table
        let update = {};
        update[uid] = true;
        return DbRefs.allRunners.update(update);
      })
      .then(() => {
        // set user role to runner
        return Auth.instance.setUserRole(uid, Auth.roleNames.runner);
      });
  }

  savePaymentDetails() {
    return DbRefs.ride();
  }

  createCustomer(uid, firstName, lastName) {
    return DbRefs.allCustomers
      .child(uid)
      .update({firstName, lastName})
      .catch(error => {
        dmfError('Error creating customer: ' + error.message);
        throw error;
      });
  }

  updatePhone(phone) {
    return DbRefs.currentUser.update({phone}).catch(error => {
      dmfError(`Updating phone number`);
    });
  }
}
