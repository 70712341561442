import {createContext, ReactNode, useContext, useEffect} from 'react';

interface Value {
  additionalNavbarContent: ReactNode;
  setAdditionalNavbarContent: React.Dispatch<React.SetStateAction<ReactNode>>;
}

const AdditionalNavbarContentContext = createContext<Value>({
  additionalNavbarContent: null,
  setAdditionalNavbarContent: () => {},
});

export const AdditionalNavbarContentContextProvider = AdditionalNavbarContentContext.Provider;

export function useAdditionalNavbarContent(content: ReactNode) {
  const {setAdditionalNavbarContent} = useContext(AdditionalNavbarContentContext);

  useEffect(() => {
    setAdditionalNavbarContent(content);
    return () => setAdditionalNavbarContent(undefined);
  }, [setAdditionalNavbarContent, content]);
}
