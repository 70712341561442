"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tripTemplateListAll = exports.tripTemplateRead = void 0;
const TripTemplate_1 = require("../models/TripTemplate");
const PATH = 'trip-templates';
async function tripTemplateRead(id, firestore) {
    const result = await firestore.read(PATH, id);
    return (0, TripTemplate_1.tripTemplateDeserialize)(result.value, result.id);
}
exports.tripTemplateRead = tripTemplateRead;
async function tripTemplateListAll(firestore) {
    const results = await firestore.list(PATH);
    return results.map(result => (0, TripTemplate_1.tripTemplateDeserialize)(result.value, result.id));
}
exports.tripTemplateListAll = tripTemplateListAll;
