import firebase from 'firebase/app';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import React, {useEffect, useState} from 'react';
import Table from '../../../components/Table';
import ColumnMetadata from '../../../components/Table/ColumnMetadata';
import DataRow from '../../../components/Table/DataRow';
import SortingDirection from '../../../components/Table/SortingDirection';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';

const metadataColumns = [
  new ColumnMetadata({path: 'firstName', name: 'First name'}),
  new ColumnMetadata({path: 'lastName', name: 'Last name'}),
  new ColumnMetadata({
    path: 'cellphone',
    name: 'Phone',
    get: user => user.cellphone || user.phone,
  }),
  new ColumnMetadata({
    path: 'uid',
    name: 'UID',
    get: (user, userId) => userId,
    width: 350,
  }),
];

export default function SuperAdmins() {
  const admins = useRealtimeDatabase({path: 'admins', addKeyToModel: false});
  const [snapshots, setSnapshots] = useState();
  const [rows, setRows] = useState();
  const [sortingDirection, setSortingDirection] = useLocalStorage(
    'ADMINS_SORTING_DIRECTION',
    SortingDirection.ascending,
  );
  const [sortingColumn, setSortingColumn] = useLocalStorage('ADMINS_SORTING_COLUMN', 'firstName');

  useEffect(() => {
    if (admins.data) {
      Promise.all(
        Object.entries(admins.data)
          .filter(([uid, isAdmin]) => Boolean(isAdmin)) // filter by the boolean value of the node
          .map(([uid]) => firebase.database().ref(`/users/${uid}`).once('value')), // fetch the corresponding user
      )
        .then(setSnapshots)
        .catch(error => {
          console.error('Error getting admins', error);
        });
    }
  }, [admins.data]);

  useEffect(() => {
    if (snapshots) {
      const create = DataRow.creator(metadataColumns, sortingColumn);
      let rows = snapshots.map(snapshot => create(snapshot.key, snapshot.val(), `/admin/users/${snapshot.key}`));
      if (sortingColumn) {
        rows = sortBy(rows, 'sortingValue');
        if (sortingDirection === SortingDirection.descending) {
          rows = reverse(rows);
        }
      }
      setRows(rows);
    }
  }, [snapshots, sortingColumn, sortingDirection]);

  return (
    <div className="h-100 d-flex">
      <Table
        columns={metadataColumns}
        rows={rows}
        sortingDirection={sortingDirection}
        onChangeSortingDirection={setSortingDirection}
        sortingColumn={sortingColumn}
        onChangeSortingColumn={setSortingColumn}
        className="flex-grow-1"
      />
    </div>
  );
}
