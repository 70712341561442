import React from 'react';
import FirebaseTable from '../../../components/FirebaseTable';
import moment from 'moment';

export default function All() {
  return (
    <FirebaseTable
      title="Outgoing API Events"
      path="events-outgoing"
      limitToLast={500}
      observe
      reverse
      link={event => `/admin/events-outgoing/${event.key}`}
      linkTitle={event => JSON.stringify(event, null, '\t')}
      columns={[
        {heading: 'Method', value: event => event.method},
        {heading: 'Path', value: event => event.path},
        {
          heading: 'Time',
          value: event => moment(event.timestampMs).calendar(),
        },
        {
          heading: 'Response',
          value: event => {
            if (event.responseCode >= 200 && event.responseCode < 300) {
              return (
                <span className="text-success">
                  {event.responseCode} {event.responseText}
                </span>
              );
            } else {
              return (
                <span className="text-danger">
                  {event.responseCode} {event.responseText}
                </span>
              );
            }
          },
        },
      ]}
    />
  );
}
