import React, {Component} from 'react';
class Credits extends Component {
  render() {
    return (
      <div className="card card-basic limited-width">
        <h4>Credits</h4>
        <h6>Icons</h6>
        <ul className="list-unstyled">
          <li>Pin by Mani Cheng from the Noun Project (modified)</li>
          <li>Car by vicky anvekar from the Noun Project (modified)</li>
          <li>Motorcycle by Buztas Linggar from the Noun Project</li>
        </ul>
      </div>
    );
  }
}

export default Credits;
