import HttpReference from '../../platform/HttpReference';
import CheckoutSessions from './CheckoutSessions';
import Stripe from './Stripe';

export default class Checkout extends HttpReference<void, CheckoutSessions, Stripe> {
  sessions: CheckoutSessions;

  constructor(path: string, parent: Stripe) {
    super(path, parent);

    this.sessions = new CheckoutSessions('sessions', this);
  }
}
