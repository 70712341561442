import moment from 'moment';
import React, {useContext, useState} from 'react';
import Alert from '../../../components/Alert/Alert';
import Card from '../../../components/Card';
import Delay from '../../../components/Delay';
import FontAwesome from '../../../components/FontAwesome';
import Spinner from '../../../components/Spinner';
import {useAlert} from '../../../contexts/AlertContext';
import {simluateDownloadClickCsv} from '../../../functions/simulateDownloadClick';
import ScoopMApi from '../../../references/scoopm-api';

export default function Gatekeeper() {
  const {setAlert} = useAlert();

  const [isFetching, setIsFetching] = useState(false);

  function downloadCsv(event: React.MouseEvent) {
    setIsFetching(true);

    ScoopMApi.instance.gatekeeper.report
      .get()
      .then(response => {
        const csvString = response.data;
        const filename = moment().startOf('month').subtract(1, 'month').format('[Wave_]YYYYMM[.csv]');
        simluateDownloadClickCsv(csvString, filename as any);
      })
      .catch(error => {
        console.error(error);
        setAlert(new Alert('Error getting report', error.message));
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  return (
    <Card padded>
      <h1>Gatekeeper Dashboard</h1>

      <h6>Charlotte-Douglass International Airport</h6>
      <button type="button" onClick={downloadCsv} className="btn btn-sm btn-primary" disabled={isFetching}>
        {isFetching ? (
          <Delay childrenBeforeTimeout={(<FontAwesome.DownloadSolid />) as any}>
            <Spinner small />
          </Delay>
        ) : (
          <FontAwesome.DownloadSolid />
        )}{' '}
        Download Report (CSV)
      </button>
      <p>
        <small>The report includes all events during the last month</small>
      </p>
    </Card>
  );
}
