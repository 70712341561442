import '@babel/polyfill';
// import * as GoogleMaps from 'google.maps'
import 'bootstrap';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/storage';
import $ from 'jquery';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {environmentFirebaseConfig} from './Environment';
import './index.css';
import * as serviceWorker from './serviceWorker';

console.log('Running in ' + process.env.REACT_APP_ENVIRONMENT);

// define window globals

declare global {
  interface Window {
    $?: typeof $;
    // google?: typeof google;
  }
}

// configure Firebase

firebase.initializeApp(environmentFirebaseConfig);

// moment settings

moment.updateLocale('en', {
  calendar: {
    sameDay: 'LT',
    sameElse: 'MM/DD/YYYY [at] h:mm a',
  },
});

// configure jQuery

window.$ = $;

// render React app

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();
