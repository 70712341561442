import firebase from 'firebase/app';
import Invite from './Invite';

export default class UserGroupInvite extends Invite {
  groupId: string;

  constructor(snapshot?: firebase.firestore.DocumentSnapshot) {
    super(snapshot);

    this.groupId = snapshot?.get('groupId');
  }
}
