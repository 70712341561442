import first from 'lodash/first';
import flatMap from 'lodash/flatMap';
import last from 'lodash/last';
import round from 'lodash/round';
import {useMemo, useState} from 'react';
import Constants from '../Constants';
import unwrap from '../functions/unwrap';
import FirestoreBounds from '../models/scoopm/FirestoreBounds';
import useDeepComparisonEffect, {useDeepComparisonMemo} from './useDeepComparisonEffect';

export default function useTripPaths({trip, directions}) {
  const typedTrip = unwrap(trip, trip => (trip.data ? trip.data : trip));

  const tripLocations = useMemo(() => unwrap(typedTrip, trip => trip.locationsWithDefault()), [typedTrip]);

  const {serverPaths, serverDistancesMi, serverBounds} = useDeepComparisonMemo(() => {
    if (!directions || !directions.length || !tripLocations) {
      return {};
    } else {
      const oldestDoc = first(directions);
      const newestDoc = directions.length > 1 ? last(directions) : null;
      const docs = [oldestDoc, newestDoc].filter(Boolean);

      const serverPaths = flatMap(
        docs.map((doc, i) =>
          doc.data().legs.map((leg, j) => ({
            id: `${doc.id}-leg-${j}`,
            googlePath: window.google.maps.geometry.encoding.decodePath(leg.encodedPath),
            color:
              docs.length > 1 && i === 0
                ? Constants.theme.primaryDarkColorHexString
                : Constants.theme.primaryColorHexString,
          })),
        ),
      );

      const legs = oldestDoc.data().legs;
      const legsForActualTrip = legs.length > tripLocations.length - 1 ? legs.slice(1) : legs;
      const serverDistancesMi = legsForActualTrip.map(leg => round(leg.distanceM * Constants.metersPerMile, 2));

      let serverBounds = unwrap(oldestDoc, doc => FirestoreBounds.prototype.gsmLatLngBounds.apply(doc.data().bounds));

      return {serverPaths, serverDistancesMi, serverBounds};
    }
  }, [tripLocations, directions]);

  const [{clientPaths, clientDistancesMi, clientBounds}, setClientProperties] = useState({});

  const shouldFetchClientSide = useMemo(
    () =>
      Boolean(
        typedTrip && !typedTrip.directions,
        // && TripSt atus.active().includes(typedTrip.statusType())
      ),
    [typedTrip],
  );
  const pickupCoordinates = useMemo(() => unwrap(typedTrip, trip => typedTrip.fromLatLng()), [typedTrip]);
  const dropOffCoordinates = useMemo(() => unwrap(typedTrip, trip => typedTrip.toLatLng()), [typedTrip]);

  useDeepComparisonEffect(() => {
    if (!shouldFetchClientSide || !pickupCoordinates || !dropOffCoordinates || !tripLocations) {
      setClientProperties({});
    } else {
      const origin = pickupCoordinates;
      const destination = dropOffCoordinates;
      const request = {travelMode: 'DRIVING', origin, destination};
      console.log('Getting directions...', JSON.stringify(request));
      new window.google.maps.DirectionsService().route(request, (directions, status) => {
        if (status !== 'OK') {
          console.log('Error getting directions', status);
          return;
        }
        console.log('Got directions', directions);

        let clientPaths = [
          {
            id: 'TRIP_OVERVIEW_PATH',
            googlePath: directions.routes[0].overview_path,
          },
        ];

        const legs = directions.routes[0].legs;
        const legsForActualTrip = legs.length > tripLocations.length - 1 ? legs.slice(1) : legs;
        let clientDistancesMi = legsForActualTrip.map(leg => round(leg.distance.value * Constants.metersPerMile, 2));

        let clientBounds = directions.routes[0].bounds;

        setClientProperties({clientPaths, clientDistancesMi, clientBounds});
      });
    }
  }, [shouldFetchClientSide, pickupCoordinates, dropOffCoordinates, tripLocations]);

  const state = useMemo(
    () => ({
      paths: flatMap([serverPaths, clientPaths].filter(Boolean)), // both
      distancesMi: serverDistancesMi || clientDistancesMi, // client is fallback
      bounds: serverBounds || clientBounds, // client is fallback
    }),
    [clientBounds, clientDistancesMi, clientPaths, serverBounds, serverDistancesMi, serverPaths],
  );

  return state;
}
