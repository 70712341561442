import {CoordinatesArray, CoordinatesObject, LatLngBounds} from 'wave-common';
import FirestoreBounds from '../../models/scoopm/FirestoreBounds';

export function latLng<
  Input extends google.maps.LatLng | google.maps.LatLngLiteral | CoordinatesArray | CoordinatesObject | undefined,
>(input: Input): undefined extends Input ? google.maps.LatLng | undefined : google.maps.LatLng {
  if (!window.google.maps.LatLng) {
    throw new Error("Google Maps hasn't loaded yet");
  } else if (!input) {
    return undefined as never;
  } else if (input instanceof google.maps.LatLng) {
    return input;
  } else if ((input as CoordinatesObject).latitude) {
    return new google.maps.LatLng({
      lat: (input as CoordinatesObject).latitude,
      lng: (input as CoordinatesObject).longitude,
    });
  } else if (Array.isArray(input)) {
    return new google.maps.LatLng({
      lat: input[0],
      lng: input[1],
    });
  } else return new google.maps.LatLng(input as google.maps.LatLngLiteral);
}

export const boundsForLatLngs = (
  latLngArray: google.maps.LatLng[] | google.maps.LatLngLiteral[] | CoordinatesArray[] | CoordinatesObject[],
) => {
  if (!Array.isArray(latLngArray) || latLngArray.length === 0) {
    throw new Error('Must input an array of at least one LatLng object');
  }
  const bounds = new window.google.maps.LatLngBounds();
  latLngArray.forEach(ll => {
    bounds.extend(latLng(ll));
  });
  return bounds;
};

export const boundsforCircle = (anyCenterCoordinates: CoordinatesArray, radiusM: number) => {
  const latLngs = latLngsForCircle(anyCenterCoordinates, radiusM);
  return boundsForLatLngs(latLngs);
};

export const latLngsForCircle = (anyCenterCoordinates: CoordinatesArray, radiusM: number) => {
  const north = window.google.maps.geometry.spherical.computeOffset(latLng(anyCenterCoordinates), radiusM, 0);
  const east = window.google.maps.geometry.spherical.computeOffset(latLng(anyCenterCoordinates), radiusM, 90);
  const south = window.google.maps.geometry.spherical.computeOffset(latLng(anyCenterCoordinates), radiusM, 180);
  const west = window.google.maps.geometry.spherical.computeOffset(latLng(anyCenterCoordinates), radiusM, 270);
  return [north, east, south, west];
};

export function latLngBounds(
  bounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral | FirestoreBounds | LatLngBounds,
): google.maps.LatLngBounds {
  if (bounds instanceof google.maps.LatLngBounds) {
    return bounds;
  } else if (bounds instanceof FirestoreBounds) {
    return new window.google.maps.LatLngBounds(bounds.gsmLatLngBounds());
  } else if ((bounds as LatLngBounds).northeast) {
    return new window.google.maps.LatLngBounds((bounds as LatLngBounds).northeast, (bounds as LatLngBounds).southwest);
  } else {
    const latLngBounds = new google.maps.LatLngBounds();
    latLngBounds.extend({
      lat: (bounds as google.maps.LatLngBoundsLiteral).north,
      lng: (bounds as google.maps.LatLngBoundsLiteral).east,
    });
    latLngBounds.extend({
      lat: (bounds as google.maps.LatLngBoundsLiteral).south,
      lng: (bounds as google.maps.LatLngBoundsLiteral).west,
    });
    return latLngBounds;
  }
}
