import qs from 'querystringify';
import React, {Component} from 'react';
import {GeneralPage} from '../unauth-app/GeneralPage';
import RecoverEmail from './RecoverEmail';
import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';

class FirebaseAuthApp extends Component {
  state = {};

  componentDidMount() {
    // Code from https://firebase.google.com/docs/auth/custom-email-handler

    const parameters = qs.parse(this.props.location.search);

    var mode = parameters['mode'];
    var actionCode = parameters['oobCode'];
    var apiKey = parameters['apiKey'];
    var continueUrl = parameters['continueUrl'];
    var lang = parameters['lang'] || 'en';

    // check for missing params
    if (!mode || !actionCode) {
      mode = 'error';
    } else {
      // check for wrong mode
      if (mode !== 'resetPassword' && mode !== 'recoverEmail' && mode !== 'verifyEmail') {
        mode = 'invalidMode';
      }
    }

    this.setState({mode, actionCode, apiKey, continueUrl, lang});
  }

  render() {
    return (
      <GeneralPage>
        <div className="card limited-width-small">
          <div className="card-body">
            {/*  error */}
            {this.state.mode === 'error' && <pre>Sorry, there was an error</pre>}

            {/*  invalid code */}
            {this.state.mode === 'invalidMode' && <pre>Sorry, there was an error</pre>}

            {/* new password */}
            {this.state.mode === 'resetPassword' && (
              <ResetPassword actionCode={this.state.actionCode} continueUrl={this.state.continueUrl} />
            )}

            {/* recover email */}
            {this.state.mode === 'recoverEmail' && (
              <RecoverEmail actionCode={this.state.actionCode} continueUrl={this.state.continueUrl} />
            )}

            {/* recover email */}
            {this.state.mode === 'verifyEmail' && (
              <VerifyEmail actionCode={this.state.actionCode} continueUrl={this.state.continueUrl} />
            )}
          </div>
        </div>
      </GeneralPage>
    );
  }
}

export default FirebaseAuthApp;
