"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tripTypeDescription = void 0;
const TripType_1 = require("../../models/TripType");
const Enum_1 = require("../Enum");
exports.tripTypeDescription = (0, Enum_1.enumDescription)({
    userFacingTitle: 'service type',
    userFacingValue: TripType_1.tripTypeDriverTitle,
    values: TripType_1.tripTypeAllKnown,
    order: type => TripType_1.tripTypeAllKnown.indexOf(type),
});
