import firebase from 'firebase/app';
import sortBy from 'lodash/sortBy';
import {useEffect, useState} from 'react';
import {latLng} from '../components/GoogleMaps/LatLng+additions';
import Driver from '../models/scoopm/Driver';
import useFilterCities from './useFilterCities';

export default function useApprovedDrivers() {
  const [drivers, setDrivers] = useState<Array<Driver>>();

  const {isLoading, cities} = useFilterCities();

  useEffect(() => {
    if (isLoading) {
      setDrivers(undefined);
    } else {
      firebase
        .database()
        .ref('/drivers')
        .orderByChild('status')
        .equalTo('approved')
        .once('value')
        .then(
          snapshot => {
            if (snapshot.exists()) {
              let drivers: Array<Driver> = Object.entries(snapshot.val()).map(([id, data]) =>
                Object.assign(new Driver(data as any), {id}),
              );

              // filter having required properties

              drivers = drivers.filter(
                driver => driver.firstName && driver.lastName && (driver.phone || driver.cellphone),
              );

              // filter by user-group filter cities

              if (cities) {
                drivers = drivers.filter(driver =>
                  Boolean(
                    driver.addressCoordinatesArray &&
                      cities.find(city => city.contains(latLng(driver.addressCoordinatesArray))),
                  ),
                );
              }

              // sort by name

              drivers = sortBy(drivers, driver => driver.firstName + driver.lastName);

              setDrivers(drivers);
            }
          },
          error => {
            console.error('Error getting drivers', error);
          },
        );
    }
  }, [isLoading, cities]);

  return drivers;
}
