import firebase from 'firebase/app';
import React from 'react';

export default function Message({message}) {
  return (
    <div
      className={
        'rounded mb-2 py-1 px-2 ' +
        (message.senderId === firebase.auth().currentUser.uid
          ? 'bg-primary text-white align-self-end'
          : 'bg-light text-black align-self-start')
      }
    >
      {message.body}
    </div>
  );
}
