import Enum from '../base/Enum';

class TransportType extends Enum {
  static forFuelBodyType(bodyType) {
    switch (bodyType) {
      case 'convertible':
      case 'coupe':
      case 'CUV':
      case 'hatchback':
      case 'minivan':
      case 'pickup':
      case 'roadster':
      case 'sedan':
      case 'specialty':
      case 'SUT':
      case 'SUV':
      case 'wagon':
        return TransportType.car;

      case 'cargo van':
      case 'passenger van':
        return TransportType.van;

      default:
        return null;
    }
  }

  get label() {
    switch (this) {
      case TransportType.walking:
        return 'Walking';
      case TransportType.bicycle:
        return 'Bicycle';
      case TransportType.cycleWithCargo:
        return 'Bicycle or motorcycle with cargo';
      case TransportType.car:
        return 'Car';
      case TransportType.van:
        return 'Van';
      default:
        return '';
    }
  }

  title() {
    return this.label;
  }

  get isAutomobile() {
    return this !== TransportType.walking && this !== TransportType.bicycle;
  }
}

TransportType.walking = new TransportType('WALKING');
TransportType.bicycle = new TransportType('BICYCLE');
TransportType.cycleWithCargo = new TransportType('CYCLE_WITH_CARGO');
TransportType.car = new TransportType('CAR');
TransportType.van = new TransportType('VAN');

export default TransportType;
