"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const deserializeString_1 = require("./deserializeString");
function stringEnumDeserialize(types, typeName, allowUnkownTypes) {
    return function (value) {
        const string = (0, deserializeString_1.deserializeString)(value);
        if (allowUnkownTypes) {
            return string;
        }
        if (!types.includes(string)) {
            throw new Error(`Invalid ${typeName}`);
        }
        return string;
    };
}
exports.default = stringEnumDeserialize;
