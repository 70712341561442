import firebase from 'firebase/app';
import React, {useContext, useEffect, useState} from 'react';

const AuthContext = React.createContext({} as Value);

export function AuthContextProvider({children}: {children: any}) {
  const [value, setValue] = useState<Value>({
    user: undefined,
    uid: undefined,
    token: undefined,
    claims: undefined,
    error: undefined,
  });

  useEffect(() => {
    let counter = 0; // used to stop the ID token result if auth state changes while the result is being fetched

    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      counter++;

      if (user) {
        setValue({
          user,
          uid: user.uid,
          token: undefined,
          claims: undefined,
          error: undefined,
        });

        const counterBefore = counter;
        user.getIdTokenResult(true).then(
          result => {
            if (counterBefore !== counter) return;
            setValue({
              user,
              uid: user.uid,
              token: result.token,
              claims: result.claims || null,
              error: null,
            });
          },
          error => {
            if (counterBefore !== counter) return;
            setValue({
              user,
              uid: user.uid,
              token: null,
              claims: null,
              error: error,
            });
          },
        );
      } else {
        setValue({
          user,
          uid: null,
          token: null,
          claims: null,
          error: null,
        });
      }
    });

    return unsubscribe;
  }, []);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

interface Value {
  user: firebase.User | undefined | null;
  uid: string | undefined | null;
  token: string | undefined | null;
  claims: Record<string, boolean> | undefined | null;
  error: Error | undefined | null;
}

export function useAuthContext() {
  return useContext(AuthContext);
}
