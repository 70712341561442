import React from 'react';
import unwrap from '../../functions/unwrap';
import PropertyType from '../types/PropertyType';
import LocationMap from './LocationMap';

export default function LocationDisplay({schema, property, value}) {
  return (
    <div className="bg-light rounded mb-2 mb-md-3">
      <div className="p-2 p-md-3">
        <p>{PropertyType.format(property.type, value)}</p>
        <h6>Instructions</h6>
        {unwrap(value.instructions, instructions => (
          <p style={{whiteSpace: 'break-spaces'}}>{instructions}</p>
        ))}
      </div>
      <LocationMap location={value} />
    </div>
  );
}
