export default class Chat {
  /**
   * @param {ChatType} chatType
   * @param {Array<Message>} messages
   */
  constructor(chatType, messages) {
    this.chatType = chatType.rawValue;
    this.messages = messages;
  }
}
