"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anyPropertyDescriptionValue = void 0;
function anyPropertyDescriptionValue(description, model, propertyKey) {
    const getValue = description.getValue;
    if (getValue) {
        return getValue(model);
    }
    else {
        return model[propertyKey];
    }
}
exports.anyPropertyDescriptionValue = anyPropertyDescriptionValue;
