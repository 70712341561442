import moment from 'moment-timezone';
import React from 'react';
import unwrap from '../../functions/unwrap';
import Location from './Location';

export default class TripLocation extends Location {
  constructor({
    contactUserType,
    instructions,
    contents,
    arrivalTimestampMs,
    scheduledTimestampMs,
    completionTimestampMs,
    requestedTimestampMs,
    estimatedArrivalTimestampMs,
    waitTimeS,
    imageIds,
    files,
    ...otherProperties
  }) {
    super({...otherProperties});

    this.contactUserType = contactUserType || null;
    this.instructions = instructions || null;
    this.contents = contents || null;
    this.arrivalTimestampMs = arrivalTimestampMs || null;
    this.scheduledTimestampMs = scheduledTimestampMs || null;
    this.completionTimestampMs = completionTimestampMs || null;
    this.requestedTimestampMs = requestedTimestampMs || null;
    this.estimatedArrivalTimestampMs = estimatedArrivalTimestampMs || null;
    this.waitTimeS = waitTimeS || null;
    this.imageIds = imageIds || null;
    this.files = files || null;
  }

  formattedComponent() {
    const ts = this.scheduledTimestampMs || this.estimatedArrivalTimestampMs;
    if (!ts) return null;
    const prefix = this.scheduledTimestampMs ? 'Scheduled ' : 'Estimated arrival ';
    if (this.timeZoneIdentifier && this.timeZoneIdentifier !== moment.tz.guess()) {
      return (
        <>
          {prefix}
          {moment(ts).calendar()}{' '}
          <span className="font-weight-normal">({moment(ts).tz(this.timeZoneIdentifier).format('LT z')})</span>
        </>
      );
    } else {
      return prefix + moment(ts).calendar();
    }
  }

  setPlace(place) {
    this.address = place.formatted_address;
    this.coordinates = [place.geometry.location.lat(), place.geometry.location.lng()];
    this.googlePlaceId = place.place_id;
    this.name = place.formatted_address.startsWith(place.name) ? null : place.name;
    this.state = unwrap(
      place.address_components.find(component => component.types.includes('administrative_area_level_1')),
      c => c.short_name,
    );
    return this;
  }

  isOverdue() {
    if (this.completionTimestampMs) {
      return false;
    }
    return unwrap(this.scheduledTimestampMs || this.estimatedArrivalTimestampMs, timestamp =>
      moment(timestamp).isBefore(moment()),
    );
  }
}
