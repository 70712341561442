import HttpReference from '../platform/HttpReference';

export default class UserGroupInvite extends HttpReference {
  accept() {
    return this.request({
      method: 'PATCH',
      data: {
        action: 'ACCEPT',
      },
    });
  }
}
