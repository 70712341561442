import firebase from 'firebase/app';
import uniq from 'lodash/uniq';
import pluralize from 'pluralize';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {tripIsDone, tripUpdate} from 'wave-common';
import Alert from '../../../components/Alert/Alert';
import DropdownSelect from '../../../components/DropdownSelect';
import FontAwesome from '../../../components/FontAwesome';
import StandardSpacer from '../../../components/Spacer';
import Table from '../../../components/Table';
import ColumnMetadata from '../../../components/Table/ColumnMetadata';
import DataRow from '../../../components/Table/DataRow';
import SelectionAction from '../../../components/Table/SelectionAction';
import {useAlert} from '../../../contexts/AlertContext';
import RealtimeDatabaseDataSource from '../../../data-sources/RealtimeDatabaseDataSource';
import {environmentVariable} from '../../../Environment';
import logPromise from '../../../functions/logPromise';
import {simluateDownloadClickCsv} from '../../../functions/simulateDownloadClick';
import unwrap from '../../../functions/unwrap';
import useLocalStorage from '../../../hooks/useLocalStorage';
import Trip from '../../../models/scoopm/Trip';
import SelectOption from '../../../models/SelectOption';
import ScoopMApi from '../../../references/scoopm-api';
import Delivery from '../../../references/scoopm-api/Delivery';
import CSVHelper from '../../../services/CSVHelper';
import CurrentRoutesContext from '../../router/CurrentRoutesContext';
import {useAdditionalMenuSections} from './AdditionalMenuSectionsContext';
import MenuSection from './MenuSection';
import withJobsData from './withJobsData';

function JobsTable(props: {trips?: Trip[]; error?: Error; timezone?: string}) {
  // general hooks

  const {setAlert} = useAlert();
  const {currentRoute} = useContext(CurrentRoutesContext);

  // table

  const [rows, setRows] = useState<Array<DataRow>>();

  // columns

  const columnMetadataAll = useMemo<ColumnMetadata[]>(() => ColumnMetadata.all(), []);

  const [columnOptionsString, setColumnOptionsString] = useLocalStorage(
    'JOBS_TABLE_AVAILABLE_COLUMNS',
    useMemo(() => columnMetadataAll.map(_ => _.path).join(',') as any, [columnMetadataAll]),
  );

  const columnOptionsStrings: string[] = useMemo(
    () => unwrap(columnOptionsString, string => string.split(','), []),
    [columnOptionsString],
  );

  // keep ordered/available columns "JOBS_TABLE_AVAILABLE_COLUMNS" up-to-date with latest ColumnMetadata.all

  useEffect(() => {
    const allPaths = columnMetadataAll.map(metadata => metadata.path);
    const pathNotInLocalStorage = allPaths.find(path => !columnOptionsStrings.includes(path));
    if (pathNotInLocalStorage) {
      const newColumnOptionsStrings = uniq(columnOptionsStrings.concat(...allPaths));
      setColumnOptionsString(newColumnOptionsStrings.join(','));
    }
  }, [columnOptionsStrings, setColumnOptionsString, columnMetadataAll]);

  const columnOptionsMetadatas = useMemo(() => {
    return columnOptionsStrings
      .map(string => columnMetadataAll.find(_ => _.path === string))
      .filter(Boolean) as ColumnMetadata[];
  }, [columnOptionsStrings, columnMetadataAll]);

  const columnOptions = useMemo(
    () => columnOptionsMetadatas.map(metadata => new SelectOption(metadata.path, metadata.name)),
    [columnOptionsMetadatas],
  );

  const [visibleColumnsString, setVisibleColumnsString] = useLocalStorage(
    'JOBS_TABLE_COLUMNS',
    useMemo(
      () =>
        ColumnMetadata.jobsTableDefault()
          .map(cm => cm.path)
          .join(',') as any,
      [],
    ),
  );
  const visibleColumnsStrings = useMemo(
    () => unwrap(visibleColumnsString, string => string.split(','), []),
    [visibleColumnsString],
  );
  const visibleColumnMetadatas = useMemo(
    () => columnOptionsMetadatas.filter(metadata => visibleColumnsStrings.includes(metadata.path)),
    [visibleColumnsStrings, columnOptionsMetadatas],
  );

  // selection

  const selectionActions = useMemo(() => {
    function onDismiss(error?: Error) {
      if (error) {
        const errorAlert = new Alert<any, Error>('Ugh, an error', error.message);
        setAlert(errorAlert);
      }
    }

    return [
      // accept action

      new SelectionAction('Accept...', selection => {
        const trips = selection.map(dataRow => new Trip(dataRow.rawValue));

        // check for trips that aren't eligible for ezCater acceptance

        const tripsForAcceptance = trips.filter(trip => trip.canBeAcceptedForEzCater());
        const acceptableCount = tripsForAcceptance.length;

        if (trips.length !== acceptableCount) {
          const warningAlert = new Alert<any, Error>(
            `Out of ${trips.length} selected ${pluralize('job', trips.length)}, ${acceptableCount} ${
              acceptableCount === 1 ? 'is' : 'are'
            } eligible for acceptance`,
            acceptableCount
              ? 'Would you like to continue with only those which can be accepted?' +
                additionalEzCaterMessage(acceptableCount)
              : undefined,
            acceptableCount ? `Accept eligible ${pluralize('job', acceptableCount)}` : undefined,
            acceptableCount ? acceptDeliveries : undefined,
            acceptableCount ? 'Cancel' : undefined,
            undefined,
            onDismiss,
          );
          setAlert(warningAlert);
        }

        // confirm
        else {
          const confirmationAlert = new Alert<any, Error>(
            `Accept ${acceptableCount} selected ${pluralize('job', acceptableCount)}?`,
            additionalEzCaterMessage(acceptableCount),
            'Accept',
            acceptDeliveries,
            'Cancel',
            undefined,
            onDismiss,
          );
          setAlert(confirmationAlert);
        }

        function acceptDeliveries() {
          return logPromise(
            'Accept jobs',
            (async () => {
              for (const trip of tripsForAcceptance) {
                const {ezCaterPaddingM} = trip;
                if (!ezCaterPaddingM)
                  throw new Error(`${trip.title2()} doesn't have padding specified. Please do so before accepting it.`);
                try {
                  await ScoopMApi.instance.dispatch.ezCater.deliveries
                    .child(trip.ezCaterDeliveryId)
                    .accept.patch(ezCaterPaddingM);
                } catch (error: any) {
                  throw new Error(`Unable to accept ${trip.orderId}: ${error.message}`);
                }
              }
            })(),
          );
        }
      }),

      // (force) cancel

      new SelectionAction('Cancel...', selection => {
        // warn if jobs are done
        const doneJobsCount = selection.filter(row => tripIsDone(row.rawValue)).length;
        if (doneJobsCount) {
          setAlert(new Alert("Can't cancel", 'Some of the selected jobs are already done'));
          return;
        }
        // warn if some jobs are ezCater (only for prod though)
        if (environmentVariable('ENVIRONMENT') === 'production') {
          const ezJobsCount = selection.filter(row => row.rawValue.source === 'EZ_CATER').length;
          if (ezJobsCount) {
            setAlert(
              new Alert("Can't cancel", 'Some of the selected jobs are ezCater jobs, and must be rejected instead'),
            );
            return;
          }
        }
        // confirmation alert
        setAlert(
          new Alert(
            `Cancel ${selection.length} ${pluralize('job', selection.length)}?`,
            'You might not see the change immediately. The table refreshes every 30 seconds.',
            undefined,
            async () => {
              await Promise.all(
                selection.map(async dataRow =>
                  tripUpdate(
                    dataRow.rawValue.customerId,
                    dataRow.id,
                    {
                      status: 'CANCELED-BY-RIDER',
                      canceledAt: firebase.database.ServerValue.TIMESTAMP,
                    },
                    RealtimeDatabaseDataSource.instance,
                  ),
                ),
              );
            },
            'Cancel',
          ),
        );
      }),

      // reject action

      new SelectionAction('Reject...', selection => {
        const trips = selection.map(dataRow => new Trip(dataRow.rawValue));

        // check for trips that aren't eligible for ezCater rejection

        const tripsForRejection = trips.filter(trip => trip.canBeAcceptedForEzCater());
        const rejectableCount = tripsForRejection.length;

        if (trips.length !== rejectableCount) {
          const warningAlert = new Alert<any, Error>(
            `Out of ${trips.length} selected ${pluralize('job', trips.length)}, ${rejectableCount} ${
              rejectableCount === 1 ? 'is' : 'are'
            } eligible for rejection`,
            rejectableCount
              ? 'Would you like to continue with only those which can be rejected?' +
                additionalEzCaterMessage(rejectableCount)
              : undefined,
            rejectableCount ? `Reject eligible ${pluralize('job', rejectableCount)}` : undefined,
            rejectableCount ? rejectDeliveries : undefined,
            rejectableCount ? 'Cancel' : undefined,
            undefined,
            onDismiss,
          );
          setAlert(warningAlert);
        }

        // confirm
        else {
          const confirmationAlert = new Alert<any, Error>(
            `Reject ${rejectableCount} selected ${pluralize('job', rejectableCount)}?`,
            additionalEzCaterMessage(rejectableCount),
            'Reject',
            rejectDeliveries,
            'Cancel',
            undefined,
            onDismiss,
          );
          setAlert(confirmationAlert);
        }

        function rejectDeliveries() {
          return logPromise(
            'Reject jobs',
            (async () => {
              for (const trip of tripsForRejection) {
                try {
                  await (
                    ScoopMApi.instance.dispatch.ezCater.deliveries.child(trip.ezCaterDeliveryId) as Delivery
                  ).reject.patch();
                } catch (error: any) {
                  throw new Error(`Unable to reject ${trip.orderId}: ${error.message}`);
                }
              }
            })(),
          );
        }
      }),

      // mark as paid

      new SelectionAction('Mark as paid...', selection => {
        setAlert(
          new Alert(
            `Mark ${selection.length} ${pluralize('job', selection.length)} as paid?`,
            'You might not see the change immediately. The table refreshes every 30 seconds.',
            undefined,
            async () => {
              await Promise.all(
                selection.map(async dataRow =>
                  tripUpdate(
                    dataRow.rawValue.customerId,
                    dataRow.id,
                    {
                      cashedOutAt: firebase.database.ServerValue.TIMESTAMP,
                    },
                    RealtimeDatabaseDataSource.instance,
                  ),
                ),
              );
            },
            'Cancel',
          ),
        );
      }),

      // mark as paid

      new SelectionAction('Mark as test...', selection => {
        setAlert(
          new Alert(
            `Mark ${selection.length} ${pluralize('job', selection.length)} as test?`,
            'This will hide the job from regular view. You might not see the change immediately. The table refreshes every 30 seconds.',
            undefined,
            async () => {
              await Promise.all(
                selection.map(async dataRow =>
                  tripUpdate(
                    dataRow.rawValue.customerId,
                    dataRow.id,
                    {
                      isTest: true,
                    },
                    RealtimeDatabaseDataSource.instance,
                  ),
                ),
              );
            },
            'Cancel',
          ),
        );
      }),
    ].filter(Boolean);
  }, [setAlert]);

  // create rows

  useEffect(() => {
    setRows(
      unwrap(props.trips, (trips: Trip[]) => {
        const sortingColumn = null;
        const creator = DataRow.creator(visibleColumnMetadatas, sortingColumn, props.timezone);
        return trips.map((trip, index) => creator(trip.id, trip, `/admin/jobs/${trip.customerId}/${trip.id}`, index));
      }) || undefined,
    );
  }, [props.trips, props.timezone, visibleColumnMetadatas]);

  // on click download

  const onClickDownload = useCallback(
    event => {
      unwrap(rows, rows => {
        unwrap(currentRoute, route => {
          const string = CSVHelper.stringFromTableRows(visibleColumnMetadatas, rows);
          simluateDownloadClickCsv(string, undefined, 'jobs', route.name);
        });
      });
    },
    [visibleColumnMetadatas, rows, currentRoute],
  );

  // additional menu sections

  useAdditionalMenuSections(
    useMemo(
      () => (
        <>
          <MenuSection title="Show/hide columns">
            <DropdownSelect
              options={columnOptions}
              selectedValues={visibleColumnsStrings}
              onChange={(strings: string[]) => setVisibleColumnsString(strings.join(','))}
              onChangeOrder={(options: SelectOption[]) => {
                console.log('options', options);
                setColumnOptionsString(options.map(option => option.value).join(','));
              }}
              disabled={false}
              className="d-block"
              width="15rem"
              buttonClassName="btn-sm w-100"
              onlyShowSelectionCount
              orderable
            />
          </MenuSection>
          <StandardSpacer />
          <MenuSection title="CSV Export">
            <button type="button" onClick={onClickDownload} className="btn btn-sm btn-primary d-block w-100">
              <FontAwesome.DownloadSolid /> Download
            </button>
          </MenuSection>
        </>
      ),
      [columnOptions, visibleColumnsStrings, setVisibleColumnsString, onClickDownload, setColumnOptionsString],
    ),
  );

  // render

  return (
    <div className="h-100 d-flex flex-column bg-gray-200">
      {unwrap(props.error, error => (
        <div className="alert alert-warning m-2 m-md-3 text-wrap overflow-scroll">
          <FontAwesome.InfoCircle /> {error.message}
        </div>
      ))}
      <Table
        columns={visibleColumnMetadatas}
        rows={rows}
        className={'flex-grow-1' as any}
        selectionActions={selectionActions as any}
        allowSelection
      />
    </div>
  );
}

function additionalEzCaterMessage(tripsCount: number) {
  if (tripsCount > 20) return ' This may take a minute, plase be patient.';
  else return '';
}

// export function withJobsTable(defaultValues?: {
//     defaultStartDate?: Date,
//     defaultEndDate?: Date,
//     defaultSource?: TripSource,
//     defaultType?: TripType,
//     defaultVendorId?: string,
//     defaultStatusGroup?: StatusGroup
// }) {
//     return function () {
//         return (
//             <JobsTable
//                 defaultStartDate={defaultValues?.defaultStartDate}
//                 defaultEndDate={defaultValues?.defaultEndDate}
//                 defaultSource={defaultValues?.defaultSource}
//                 defaultType={defaultValues?.defaultType}
//                 defaultVendorId={defaultValues?.defaultVendorId}
//                 defaultStatusGroup={defaultValues?.defaultStatusGroup}
//             />
//         )
//     }
// }

export default withJobsData(JobsTable);
