import times from 'lodash/times';
import React, {useMemo} from 'react';
import {formatCurrency, pricingFormulatInputTitle, TripType, tripTypeTitle, unwrap, useStatus} from 'wave-common';
import {pricingFormulaRead} from 'wave-common/lib/controllers/PricingFormula';
import {tripTypeDetailRead} from 'wave-common/lib/controllers/TripTypeDetail';
import Delay from '../../components/Delay';
import LoadingAnimation from '../../components/LoadingAnimation';
import WhiteText from '../../components/WhiteText';
import FirestoreDataSource from '../../data-sources/FirestoreDataSource';
import RealtimeDatabaseDataSource from '../../data-sources/RealtimeDatabaseDataSource';
import ContainerLayout from '../../layouts/ContainerLayout';

export default function Pricing() {
  return (
    <ContainerLayout>
      <h1 className="mb-4">Pricing</h1>
      <InvoiceFormula tripType="scoopM" />
      <InvoiceFormula tripType="scoopXl" />
      <InvoiceFormula tripType="SUV" />
      <InvoiceFormula tripType="LUX" />
      <div className="mb-5">
        <small className="text-muted">All prices are subject to change.</small>
      </div>
    </ContainerLayout>
  );
}

function InvoiceFormula({tripType}: {tripType: TripType}) {
  // trip type detail

  const {
    isIdle: tripTypeDetailIsIdle,
    isPending: tripTypeDetailIsPending,
    error: tripTypeDetailError,
    value: tripTypeDetail,
  } = useStatus(
    React as never,
    useMemo(() => tripTypeDetailRead(tripType, RealtimeDatabaseDataSource.instance), [tripType]),
  );

  // invoice formula

  const {
    isIdle: invoiceFormulaIsIdle,
    isPending: invoiceFormulaIsPending,
    error: invoiceFormulaError,
    value: invoiceFormula,
  } = useStatus(
    React as never,
    useMemo(
      () =>
        unwrap(tripTypeDetail?.invoiceFormulaId, invoiceFormulaId =>
          pricingFormulaRead('invoice', invoiceFormulaId, FirestoreDataSource),
        ),
      [tripTypeDetail?.invoiceFormulaId],
    ),
  );

  // render

  if (tripTypeDetailError || invoiceFormulaError) {
    return null;
  }

  return (
    <div className="mb-5">
      <table className="table mb-5">
        <thead>
          <tr>
            <th colSpan={2}>{tripTypeTitle(tripType)}</th>
          </tr>
        </thead>
        <tbody>
          {tripTypeDetailIsPending || tripTypeDetailIsIdle || invoiceFormulaIsPending || invoiceFormulaIsIdle ? (
            times(4).map(i => (
              <tr key={i}>
                <td colSpan={2}>
                  <Delay childrenBeforeTimeout={<WhiteText />}>
                    <LoadingAnimation />
                  </Delay>
                </td>
              </tr>
            ))
          ) : (
            <>
              {invoiceFormula?.items.map((item, i) => (
                <tr key={i}>
                  <td>{item.input ? pricingFormulatInputTitle(item.input) : item.name}</td>
                  <td>{formatCurrency(item.amount)}</td>
                </tr>
              ))}
              {invoiceFormula?.minimumD && (
                <tr>
                  <td>Minimum</td>
                  <td>{formatCurrency(invoiceFormula?.minimumD)}</td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}
