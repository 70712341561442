import {AxiosResponse} from 'axios';
import React, {useMemo} from 'react';
import joinClassNames from '../../functions/joinClassNames';
import {unwrap} from '../../functions/unwrap';
import useResult from '../../hooks/useResult';
import StripeObject from '../../models/stripe/StripeObject';
import StripePage from '../../models/stripe/StripePage';
import PropertyInputProps from './PropertyInputProps';

export default function StripeObjectInput<StripeModel extends StripeObject>(
  props: PropertyInputProps & {
    getPromise: () => Promise<AxiosResponse<StripePage<Array<StripeModel>>>>;
    format: (stripeModel: StripeModel) => string;
  },
) {
  const {getPromise} = props;

  const promise = useMemo(getPromise, [getPromise]);
  const result = useResult(promise);

  return (
    <select
      id={'stripe-object-input-' + props.id}
      value={props.value || ''}
      onChange={event => props.onChange(event.target.value || null)}
      className={joinClassNames(
        'form-control',
        unwrap(props.validationError, error => (error ? ' is-invalid' : ' is-valid')),
      )}
      disabled={props.disabled}>
      {result === null && <option value="">Loading...</option>}

      {result?.error && <option value="">Unable to load options</option>}

      {result?.value && <option value="">{props.property.isOptional ? 'None' : 'Select...'}</option>}

      {result?.value?.data?.data.map(datum => (
        <option key={datum.id} value={datum.id}>
          {props.format(datum)}
        </option>
      ))}
    </select>
  );
}
