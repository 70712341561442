import React from 'react';
import AdjustingTextArea from '../../components/AdjustingTextArea';
import unwrap from '../../functions/unwrap';
import Property from '../types/Property';
import InvalidFeedback from './InvalidFeedback';

interface InputProps {
  property: Property;
  id: string;
  value: any;
  onChange: (value: string | null) => void;
  disabled: boolean;
  validationError: Error | undefined;
}

export default function StringLongInput(props: InputProps) {
  return (
    <>
      <AdjustingTextArea
        id={props.id}
        name={props.id}
        value={props.value || ''}
        onChange={event => props.onChange(event.target.value || null)}
        className={'form-control ' + unwrap(props.validationError, error => (error ? ' is-invalid' : ' is-valid'), '')}
        disabled={props.disabled}
      />
      <InvalidFeedback error={props.validationError} />
    </>
  );
}
