import {useEffect, useRef} from 'react';

const useAsyncAffect = (getPromise, handleResult, handleError = null, dependencies) => {
  const mostRecentPromise = useRef();

  useEffect(() => {
    const newPromise = getPromise();

    mostRecentPromise.current = newPromise;

    console.log('Running async effect...');

    newPromise.then(
      result => {
        if (newPromise !== mostRecentPromise.current) {
          console.info('Ignoring promise; not most recent');
          return;
        }
        console.log('Ran async effect', result);
        handleResult(result);
      },
      error => {
        console.error('Error running async effect', error);
        handleError && handleError(error);
      },
    );
  }, dependencies);
};

export default useAsyncAffect;
