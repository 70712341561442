import firebase from 'firebase/app';
import {useEffect, useState} from 'react';
import log from '../functions/log';
import Type from '../functions/Type';
import unwrap from '../functions/unwrap';
import FirestoreModel from '../models/base/FirestoreModel';

/**
 * Used for getting Firestore references.
 * The property `isLoading` is `true` by default.
 * You can disable this hook by passing a falsy `reference`.
 */
export default function useFirestoreGet<Model extends FirestoreModel>(
  reference?: firebase.firestore.DocumentReference,
  modelType?: Type<Model>,
) {
  const [state, setState] = useState<State<Model>>({
    isLoading: true,
    reference,
  });

  useEffect(() => {
    if (reference) {
      setState({isLoading: true, reference});

      const path = reference.path || 'query';
      log.i(`Getting "${path}"...`);
      reference.get().then(
        snapshot => {
          log.i(`Got "${path}"`);
          const model = createModel(modelType, snapshot);
          setState({isLoading: false, snapshot, model, reference});
        },
        error => {
          log.e(`Error getting "${path}"`, error);
          setState({isLoading: false, error, reference});
        },
      );
    } else {
      setState(oldState => {
        log.i(`Unsetting ${oldState.reference ? oldState.reference.path || 'query' : 'reference'}`);
        return {isLoading: false};
      });
    }
  }, [reference, modelType]);

  return state;
}

interface State<Model> {
  isLoading: boolean;
  snapshot?: firebase.firestore.DocumentSnapshot;
  model?: Model;
  error?: Error;
  reference?: firebase.firestore.DocumentReference;
}

export function createModel<Model extends FirestoreModel>(
  modelType: Type<Model> | undefined,
  snapshot: firebase.firestore.DocumentSnapshot,
): Model | undefined {
  return (
    unwrap(modelType, type => {
      if (!snapshot.exists) {
        return undefined;
      } else if (type.prototype instanceof FirestoreModel) {
        return new type(snapshot);
      } else {
        const data = snapshot.data();
        const model = new type(data);
        model.id = snapshot.id;
        return model;
      }
    }) ?? undefined
  );
}
