import firebase from 'firebase/app';
import Constants from '../../Constants';
import unwrap from '../../functions/unwrap';
import Driver from '../../models/scoopm/Driver';
import TripContact from '../../models/scoopm/TripContact';
import TripDriver from '../../models/scoopm/TripDriver';
import TripStatus from '../../models/scoopm/TripStatus';
import UserType from '../../models/scoopm/UserType';
import Firestore from '../firestore/Firestore';
import DatabaseReference from '../platform/DatabaseReference';
import Config from './Config';
import Devices from './Devices';
import Drivers from './Drivers';
import DriversGeoFire from './DriversGeoFire';
import Trips from './Trips';
import TripsAll from './TripsAll';

class RealtimeDatabase extends DatabaseReference {
  static _instance = null;

  /**
   * @returns {RealtimeDatabase} The shared instance of this class
   */
  static get instance() {
    if (this._instance == null) this._instance = new RealtimeDatabase();
    return this._instance;
  }

  constructor(path, parent) {
    super(path, parent);

    this.config = new Config('config', this);
    this.devices = new Devices('devices', this);
    this.drivers = new Drivers('drivers', this);
    this.driversGeoFire = new DriversGeoFire('drivers-geo-fire', this);
    this.driversGeoFireActive = new DriversGeoFire('drivers-geo-fire-active', this);
    this.trips = new Trips('trips', this);
    this.tripsAll = new TripsAll('trips-all', this);
  }

  async batchUpdate_queueDriver(tripId, tripData, driverId, driverData) {
    const {customerId} = tripData;
    const values = {};

    // update trip driver

    values[`/trips/${customerId}/${tripId}/driverId`] = driverId;
    values[`/trips/${customerId}/${tripId}/driver`] = TripDriver.fromDriver(driverData);

    // update trip driver contact

    if (tripData.contacts) {
      const index = tripData.contacts.findIndex(contact => contact.userType === UserType.driver.rawValue);

      if (index !== -1) {
        values[`/trips/${customerId}/${tripId}/contacts/${index}`] = new TripContact({
          name: Driver.prototype.fullName.apply(driverData),
          phone: driverData.safePhoneNumber(),
          uid: driverId,
          userType: UserType.driver.rawValue,
        });
      }
    }

    return this.update(values);
  }

  async batchUpdate_dispatchTrip(tripId, tripData, driverId, driverData) {
    const {customerId} = tripData;
    const values = {};

    // update trip status

    values[`/trips/${customerId}/${tripId}/status`] = TripStatus.accepted.rawValue;
    values[`/trips/${customerId}/${tripId}/confirmedAt`] = firebase.database.ServerValue.TIMESTAMP;

    // update trip driver

    values[`/trips/${customerId}/${tripId}/driverId`] = driverId;
    values[`/trips/${customerId}/${tripId}/driver`] = TripDriver.fromDriver(driverData);

    // update trip driver contact

    if (tripData.contacts) {
      const index = tripData.contacts.findIndex(contact => contact.userType === UserType.driver.rawValue);

      if (index !== -1) {
        values[`/trips/${customerId}/${tripId}/contacts/${index}`] = new TripContact({
          name: Driver.prototype.fullName.apply(driverData),
          phone: driverData.safePhoneNumber(),
          uid: driverId,
          userType: UserType.driver.rawValue,
        });
      }
    }

    // update customer

    values[`/customers/${customerId}/currentRide`] = tripId;

    // update driver

    values[`/drivers/${driverId}/currentTrip/currentCustomerId`] = customerId;
    values[`/drivers/${driverId}/currentTrip/currentTripId`] = tripId;

    return this.update(values);
  }

  async batchUpdate_assignNewDriver(tripId, tripData, driverId = null, driverData = null) {
    const {customerId} = tripData;
    const values = {};

    // update trip driver

    const safePhoneNumber = unwrap(driverData, data => Driver.prototype.safePhoneNumber.apply(data));

    values[`/trips/${customerId}/${tripId}/driverId`] = driverId;
    values[`/trips/${customerId}/${tripId}/driver`] = driverId ? TripDriver.fromDriver(driverData) : null;

    // update trip driver contact

    if (tripData.contacts) {
      const index = tripData.contacts.findIndex(contact => contact.userType === UserType.driver.rawValue);

      if (index !== -1) {
        if (driverId) {
          values[`/trips/${customerId}/${tripId}/contacts/${index}`] = new TripContact({
            name: Driver.prototype.fullName.apply(driverData),
            phone: safePhoneNumber,
            uid: driverId,
            userType: UserType.driver.rawValue,
          });
        } else {
          values[`/trips/${customerId}/${tripId}/contacts`] = tripData.contacts.splice(index, 1);
        }
      }
    }

    // update old driver

    if (tripData.driverId) {
      values[`/drivers/${tripData.driverId}/currentTrip`] = null;
    }

    // update new driver

    if (driverId) {
      values[`/drivers/${driverId}/currentTrip/currentCustomerId`] = customerId;
      values[`/drivers/${driverId}/currentTrip/currentTripId`] = tripId;
    }

    return this.update(values);
  }
}

export default RealtimeDatabase;
