import React, {Component} from 'react';
import {checkForProp} from '../../functions/checkForProp';
import EditableInput from './EditableInput';

class EditableSelect extends Component {
  constructor(props) {
    super(props);

    checkForProp('name', this);
    checkForProp('value', this);
    checkForProp('options', this);
    checkForProp('onChange', this);

    let allOptions = [...this.props.options];
    if (props.allowOther) {
      allOptions.unshift({value: '__OTHER', text: 'Other...'});
    }
    allOptions.unshift({value: '', text: 'Please select'});

    this.state = {
      name: this.props.name,
      value: this.props.value || '', // value can't be null. if value is null, we get an "uncontrolled input" error
      allOptions,
    };
  }

  onChange(event) {
    this.setState({value: event.target.value});
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.value !== prevState.value) {
      this.save({
        name: this.state.name,
        value: this.state.value,
        done: result => {
          let hasError = result === false;
          let value = hasError ? this.props.value : this.state.value; // reset value if there was an error
          this.setState({isSaving: false, hasError, value});
        },
      });
    }
  }

  save({name, value, done}) {
    this.setState({value}, () => {
      // check if they selected "Other..."
      if (value === '__OTHER') {
        return; // wait until they type something meaningful
      }

      // check if not changed
      if (this.state.value === this.props.value) {
        return;
      }

      this.setState({isSaving: true}, () => {
        this.props.onChange({name, value, done});
      });
    });
  }

  didClickShowOptions(event) {
    this.setState({value: ''});
  }

  render() {
    let optionIsInOptions = this.props.options.findIndex(option => option.value === this.state.value);
    if (
      this.props.allowOther &&
      optionIsInOptions === -1 && // option is not in options
      this.state.value !== ''
    ) {
      return (
        <div>
          <EditableInput
            name={this.props.name}
            value={this.state.value === '__OTHER' ? '' : this.state.value}
            onChange={this.save.bind(this)}
            placeholder={'Type value here...'}
            className="show-border mr-2"
          />
          <button onMouseDown={this.didClickShowOptions.bind(this)} className="btn btn-secondary">
            <i className="fas fa-undo" /> <i className="fas fa-list-ul" />
          </button>
        </div>
      );
    }

    return (
      <div className="d-inline">
        <select
          value={this.state.value}
          onChange={this.onChange.bind(this)}
          className={
            this.props.className +
            ' custom-select d-inline w-auto' +
            (this.state.hasError ? ' error' : '') +
            (this.state.isValid === null ? '' : this.state.isValid ? ' valid' : ' invalid')
          }
          {...this.state.props}>
          {this.state.allOptions.map(({value, text}) => (
            <option value={value} key={value}>
              {text}
            </option>
          ))}
        </select>

        {this.state.isSaving && <i className="fas fa-circle-notch fa-spin" />}

        {this.state.hasError && !this.state.isEditing && <span className="text-danger">Error</span>}
      </div>
    );
  }
}

export default EditableSelect;
