import React, {useEffect, useMemo, useRef, useState} from 'react';
import joinClassNames from '../functions/joinClassNames';
import unwrap from '../functions/unwrap';

export default function AdjustingTextArea(props: TextAreaProps) {
  const ref = useRef<HTMLTextAreaElement>(null);

  const [scrollHeight, setScrollHeight] = useState<number>();

  useEffect(() => {
    const padding = lineHeightPx * 0.5;
    const initialHeight = ref.current!.scrollHeight + padding;
    setScrollHeight(initialHeight);
  }, []);

  const combinedProps = useMemo<TextAreaProps>(
    () => ({
      ...props,
      onInput: (event: any) => {
        const {scrollHeight, clientHeight} = event.target; // get value from synthetic event
        if (scrollHeight > clientHeight) {
          setScrollHeight(
            oldValue => Math.max(oldValue ?? 0, scrollHeight), // never gets smaller
          );
        }
      },
      className: joinClassNames(props.className, 'hidden-border align-self-center'),
      style: {
        ...props.style,
        height: unwrap(scrollHeight, height => `${height}px`) ?? undefined,
        overflowY: 'hidden',
      },
    }),
    [props, scrollHeight],
  );

  return <textarea ref={ref} {...combinedProps} />;
}

type TextAreaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

const lineHeightPx = 24;
