import {filterBoolean} from 'wave-common';
import {maybe} from '../../../../functions/maybe';
import driverFlagsApproved from './driverFlagsApproved';

const headings = [
  {
    heading: 'Name',
    value: driver => {
      const names = [driver.lastName, driver.firstName].filter(Boolean);
      if (!names.length) return 'Not entered';
      else return names.join(', ');
    },
  },
  {heading: 'Phone', value: driver => driver.phone},
  {heading: 'Email', value: driver => driver.email},
  {
    heading: 'Car',
    value: driver => `${driver.year || ''} ${driver.make || ''} ${driver.model || ''}`,
  },
  {
    heading: 'State/region',
    value: driver => maybe(driver, 'address', 'region') || driver.state,
    sortByDefault: true,
  },
  {
    heading: 'Application source',
    value: driver => (driver.fountainApplicantId ? 'Fountain' : 'driver.scoopm.com'),
    sortByDefault: true,
  },
  {
    heading: '',
    value: driver =>
      filterBoolean(
        driverFlagsApproved.map(flag =>
          flag.searchOnly ? undefined : driver[flag.property] ? flag.shortName ?? flag.name : undefined,
        ),
      ).join(', '),
  },
];

export default headings;
