import moment from 'moment';
import React from 'react';
import Driver from '../../../models/scoopm/Driver';
import DriversTable from './DriversTable';
import headings from './misc/headings';

export default function Applicants() {
  return (
    <DriversTable
      title="Submitted applications"
      orderByChild="status"
      equalTo="pending"
      columns={[
        ...headings,
        {
          heading: 'Submitted',
          value: (driver: Driver) =>
            driver.submissionTime && moment(driver.submissionTime * 1000).format('MM/DD/YYYY, hh:mma'),
          key: (driver: Driver) => driver.submissionTime,
        },
      ]}
    />
  );
}
