import React, {useContext} from 'react';
import Alert from '../components/Alert/Alert';

interface AlertContextInterface<ConfirmResult, CancelResult> {
  alert?: Alert<ConfirmResult, CancelResult>;
  setAlert: (alert?: Alert<ConfirmResult, CancelResult>) => void;
}

const AlertContext = React.createContext<AlertContextInterface<any, any>>({} as AlertContextInterface<any, any>);

export const AlertContextProvider = AlertContext.Provider;

export function useAlert() {
  const state = useContext(AlertContext);
  return {
    setAlert: state.setAlert,
  };
}
