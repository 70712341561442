import csvFromArrayOfArrays from '../functions/csvFromArrayOfArrays';

export default class CSVHelper {
  static stringFromTableRows(columns: any[], rows: any[]) {
    const csvRows = [];

    // add heading row

    const headingRow: any[] = [];
    columns.forEach(column => {
      // add column heading

      const heading = column.name;
      headingRow.push(heading);

      // add additional column heading if present

      if (column.additionalCsvColumn) {
        const additionalHeading = column.additionalCsvColumn.name;
        headingRow.push(additionalHeading);
      }
    });
    csvRows.push(headingRow);

    // add regular rows

    rows.forEach(row => {
      // add row

      const csvRow: any[] = [];
      row.columns.forEach((column: any) => {
        // add value

        const value = column.columnMetadata.formatCsv
          ? column.columnMetadata.formatCsv(column.rawValue)
          : column.formattedValue || column.rawValue;
        csvRow.push(value);

        // add additional value if present

        if (column.columnMetadata.additionalCsvColumn) {
          const additionalValue = column.columnMetadata.additionalCsvColumn.format(column.rawValue);
          csvRow.push(additionalValue);
        }
      });
      csvRows.push(csvRow);
    });

    // download csv file

    return csvFromArrayOfArrays(csvRows);
  }
}
