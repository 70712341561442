import React from 'react';
import {FontAwesomeV5} from '../../components/FontAwesome';

export default function OtherServices() {
  return (
    <div className="container py-5">
      <h3 className="mb-4">
        <FontAwesomeV5 name="truck-medical" className="text-primary" /> Medical Transport/Delivery
      </h3>

      <h3 className="mb-4 text-right">
        <FontAwesomeV5 name="children" className="text-primary" /> Kids/Youth Rides
      </h3>

      <h3 className="mb-1">
        <FontAwesomeV5 name="bell-concierge" className="text-primary" /> Custom Solutions
      </h3>
      <p className="text-muted mb-4">Private events &middot; Concierge</p>

      <h3 className="text-right">
        <FontAwesomeV5 name="bowl-food" className="text-primary" /> Catering Delivery / Food Delivery
      </h3>
    </div>
  );
}
