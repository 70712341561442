"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deviceTypeTitle = exports.deviceTypeDeserialize = exports.deviceTypeNotificationCapable = exports.deviceTypeAll = void 0;
exports.deviceTypeAll = ['ANDROID', 'API', 'IOS', 'WEB'];
exports.deviceTypeNotificationCapable = ['ANDROID', 'IOS', 'WEB'];
function deviceTypeDeserialize(value) {
    if (!exports.deviceTypeAll.includes(value)) {
        throw new Error(`Invalid device type "${value}"`);
    }
    return value;
}
exports.deviceTypeDeserialize = deviceTypeDeserialize;
function deviceTypeTitle(type) {
    switch (type) {
        case 'ANDROID':
            return 'Android';
        case 'API':
            return 'API';
        case 'IOS':
            return 'iOS';
        case 'WEB':
            return 'Web';
    }
}
exports.deviceTypeTitle = deviceTypeTitle;
