import Enum from '../base/Enum';

export default class VehicleColor extends Enum {
  static beige = new VehicleColor('BEIGE');
  static black = new VehicleColor('BLACK');
  static blue = new VehicleColor('BLUE');
  static brown = new VehicleColor('BROWN');
  static burgundy = new VehicleColor('BURGUNDY');
  static charcoal = new VehicleColor('CHARCOAL');
  static gold = new VehicleColor('GOLD');
  static gray = new VehicleColor('GRAY');
  static green = new VehicleColor('GREEN');
  static offWhite = new VehicleColor('OFF_WHITE');
  static orange = new VehicleColor('ORANGE');
  static pink = new VehicleColor('PINK');
  static purple = new VehicleColor('PURPLE');
  static red = new VehicleColor('RED');
  static silver = new VehicleColor('SILVER');
  static tan = new VehicleColor('TAN');
  static turquoise = new VehicleColor('TURQUOISE');
  static white = new VehicleColor('WHITE');
  static yellow = new VehicleColor('YELLO');

  name() {
    switch (this) {
      case VehicleColor.beige:
        return 'Beige';
      case VehicleColor.black:
        return 'Black';
      case VehicleColor.blue:
        return 'Blue';
      case VehicleColor.brown:
        return 'Brown';
      case VehicleColor.burgundy:
        return 'Burgundy';
      case VehicleColor.charcoal:
        return 'Charcoal';
      case VehicleColor.gold:
        return 'Gold';
      case VehicleColor.gray:
        return 'Gray';
      case VehicleColor.green:
        return 'Green';
      case VehicleColor.offWhite:
        return 'Off white';
      case VehicleColor.orange:
        return 'Orange';
      case VehicleColor.pink:
        return 'Pink';
      case VehicleColor.purple:
        return 'Purple';
      case VehicleColor.red:
        return 'Red';
      case VehicleColor.silver:
        return 'Silver';
      case VehicleColor.tan:
        return 'Tan';
      case VehicleColor.turquoise:
        return 'Turquoise';
      case VehicleColor.white:
        return 'White';
      case VehicleColor.yellow:
        return 'Yello';
    }
  }
}
