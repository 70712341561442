"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.driverAvailabilityRadiusKm = exports.unitedStates = exports.minimumBookingWindowH = void 0;
exports.minimumBookingWindowH = 4;
exports.unitedStates = {
    northeast: {
        lat: 49.3,
        lng: -66.51,
    },
    southwest: {
        lat: 24.14,
        lng: -125.31,
    },
};
exports.driverAvailabilityRadiusKm = 161; // km = 100 mi
