import times from 'lodash/times';
import React, {useEffect, useState} from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import PricingFormula from '../../models/scoopm/PricingFormula';

export default function InvoiceFormula({schema, model}) {
  const [isEditing, setIsEditing] = useState(false);

  const [charges, setCharges] = useLocalStorage('INVOICE_FORMULA_CHARGES', '5,10,15');
  const [miles, setMiles] = useLocalStorage('INVOICE_FORMULA_MILES', '5,10,15');
  const [minutes, setMinutes] = useLocalStorage('INVOICE_FORMULA_MINUTES', '5,10,15');
  const [tips, setTips] = useLocalStorage('INVOICE_FORMULA_TIPS', '5,10,15');
  const [revenues, setRevenues] = useLocalStorage('INVOICE_FORMULA_CHARGES', '5,10,15');

  const [table, setTable] = useState([]);

  useEffect(() => {
    if (model) {
      let table = [];

      charges.split(',').forEach(charge => {
        miles.split(',').forEach(mile => {
          minutes.split(',').forEach(minute => {
            tips.split(',').forEach(tip => {
              revenues.split(',').forEach(revenue => {
                table.push([
                  charge,
                  mile,
                  minute,
                  tip,
                  revenue,
                  PricingFormula.prototype.calculate.apply(model, [charge, mile, minute, tip, revenue].map(Number)),
                ]);
              });
            });
          });
        });
      });

      setTable(table);
    }
  }, [model, charges, miles, minutes, tips, revenues]);

  return (
    <div>
      <div className="row no-gutters bg-gray-200 rounded-top p-2 p-md-3">
        <div className="col">
          <h3 className="mb-0">Examples</h3>
        </div>
        <div className="col-auto">
          <button onClick={event => setIsEditing(!isEditing)} className="btn btn-secondary">
            {isEditing ? 'Done' : 'Edit'}
          </button>
        </div>
      </div>

      <table className="table table-hover">
        <thead className="thead-light">
          <tr>
            <TableHeading label="Charge" value={charges} setter={setCharges} isEditing={isEditing} />
            <TableHeading label="Miles" value={miles} setter={setMiles} isEditing={isEditing} />
            <TableHeading label="Minutes" value={minutes} setter={setMinutes} isEditing={isEditing} />
            <TableHeading label="Tip" value={tips} setter={setTips} isEditing={isEditing} />
            <TableHeading label="Revenue" value={revenues} setter={setRevenues} isEditing={isEditing} />
            <th scope="col">Output</th>
          </tr>
        </thead>
        <tbody>
          {table.map((row, i) => (
            <tr key={i}>
              {row.map((cell, j) =>
                j === 5 ? (
                  <th key={j} scope="row">
                    {cell}
                  </th>
                ) : (
                  <td key={j}>{cell}</td>
                ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function TableHeading({label, value, setter, isEditing}) {
  return (
    <th scope="col">
      {label}
      {isEditing ? (
        <Inputs value={value} setter={setter} />
      ) : (
        <span className="font-weight-normal"> ({value.replaceAll(',', ', ')})</span>
      )}
    </th>
  );
}

function Inputs({value, setter}) {
  function onChange(value, setter, index) {
    return function (event) {
      const newValue = [...value.split(',')];
      newValue.splice(index, 1, event.target.value);
      setter(newValue.join(','));
    };
  }

  return (
    <div>
      {times(3, i => (
        <input
          key={i}
          type="number"
          value={value.split(',')[i]}
          onChange={onChange(value, setter, i)}
          className="form-control mt-1"
        />
      ))}
    </div>
  );
}
