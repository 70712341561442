import React, {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import Delay from '../../components/Delay';
import Spinner from '../../components/Spinner';
import {useAuthContext} from '../../contexts/AuthContext';
import {useIsMinimalUi} from '../../contexts/MinimalUiContext';
import Footer from './Footer';
import Header from './Header';
import './main.css';

export default function MainLayout({children}) {
  const hasShownMainContent = useRef(false);

  const location = useLocation();
  const {claims} = useAuthContext();
  const isMinimalUi = useIsMinimalUi();

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, location.hash]);

  if (claims === undefined && !hasShownMainContent.current) {
    return (
      <Delay>
        <Spinner />
      </Delay>
    );
  }

  hasShownMainContent.current = true;

  return (
    <div className="min-vh-100 d-flex flex-column">
      {/* <div className="min-vh-100 d-flex flex-column roboto"> */}
      <div className="min-vh-100 d-flex flex-column">
        <Header />
        <div className="flex-grow-1 bg-white">{children}</div>
      </div>
      {!isMinimalUi && <Footer />}
    </div>
  );
}

/* {isDraggedOver && (
  <div className="position-absolute bg-dark d-flex" style={{top: 0, bottom: 0, left: 0, right: 0}}>
    <div className="flex-grow-1 m-3 rounded border bg-white shadow d-flex align-items-center justify-content-center">
      Drop an invoice here to create a new order
    </div>
  </div>
)} */
