import pluralize from 'pluralize';
import React, {useState} from 'react';
import {RouteChildrenProps} from 'react-router-dom';
import Card from '../../../components/Card';
import unwrap from '../../../functions/unwrap';
import TripStatus from '../../../models/scoopm/TripStatus';
import UserTripsTable from './UserTripsTable';

export function CustomerJobs({match}: RouteChildrenProps<{id: string}>) {
  const uid = match?.params.id;
  if (!uid) return null;
  return (
    <div>
      <Section
        title="Underway"
        uid={uid}
        statuses={[TripStatus.created, TripStatus.accepted, TripStatus.started]}
        userType="CUSTOMER"
      />
      <Section title="Up next" description="Queue" uid={uid} statuses={[TripStatus.scheduled]} userType="CUSTOMER" />
      <Section
        title="Complete"
        description="History"
        uid={uid}
        statuses={TripStatus.done()}
        classname="mb-5"
        descending
        userType="CUSTOMER"
      />
    </div>
  );
}

export function DriverJobs({match}: RouteChildrenProps<{id: string}>) {
  const uid = match?.params.id;
  if (!uid) return null;

  return (
    <div>
      <Section
        title="Underway"
        uid={uid}
        statuses={[TripStatus.created, TripStatus.accepted, TripStatus.started]}
        userType="DRIVER"
      />
      <Section title="Up next" description="Queue" uid={uid} statuses={[TripStatus.scheduled]} userType="DRIVER" />
      <Section
        title="Complete"
        description="History"
        uid={uid}
        statuses={TripStatus.done()}
        classname="mb-5"
        descending
        userType="DRIVER"
      />
    </div>
  );
}

function Section({
  title,
  description,
  uid,
  statuses,
  descending,
  classname,
  userType,
}: {
  title: string;
  description?: string;
  uid: string;
  statuses: Array<TripStatus>;
  descending?: boolean;
  classname?: string;
  userType: 'CUSTOMER' | 'DRIVER';
}) {
  const [limit, setLimit] = useState(25);

  const [count, setCount] = useState<number | null>();

  return (
    <Card padded innerPadded={false} innerClassName="bg-gray-200 rounded border" className={classname}>
      <div className="p-2 p-md-3">
        <h4 className="mb-0">{title}</h4>

        {/* {description && <small className="mb-2 text-muted">{description}</small>} */}

        {unwrap(count, it => (
          <div className="d-flex align-items-center text-muted">
            {it} {pluralize('job', it)}{' '}
            {it === limit && (
              <button onClick={event => setLimit(limit + 25)} className="btn btn-link">
                Load more
              </button>
            )}
          </div>
        ))}
      </div>

      <div className="bg-white rounded">
        <UserTripsTable
          uid={uid}
          statuses={statuses}
          descending={descending}
          limit={limit}
          onChangeCount={setCount}
          userType={userType}
        />
      </div>
    </Card>
  );
}
