"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.couponRead = void 0;
const Coupon_1 = require("../models/Coupon");
const PATH = 'coupons';
async function couponRead(couponCode, database) {
    const result = await database.read(PATH, couponCode.toLowerCase());
    return (0, Coupon_1.couponDeserialize)(result.value, result.id);
}
exports.couponRead = couponRead;
