import sortBy from 'lodash/sortBy';

export default class Enum {
  constructor(public rawValue: string) {}

  toString() {
    return this.rawValue;
  }

  toJSON() {
    return this.rawValue;
  }

  static get allCases() {
    return sortBy(
      Object.values(this).filter(value => value instanceof this),
      caze =>
        caze.order ? caze.order() : typeof caze.title === 'function' ? caze.title() : caze.title || caze.rawValue,
    );
  }

  static from<E extends Enum>(rawValue: any): E | null {
    // iterate static properties
    for (const key in this) {
      if (this.hasOwnProperty(key)) {
        const property = (this as {[key: string]: any})[key];

        //  if Enum case
        if (property instanceof Enum) {
          // if enum case raw value equals 'rawValue'
          if (property.rawValue === rawValue) {
            return property as E;
          }
        }
      }
    }

    // no match
    return null;
  }
}
