import firebase from 'firebase/app';
import ColumnMetadata from '../../components/Table/ColumnMetadata';
import SortingDirection from '../../components/Table/SortingDirection';
import CustomClaim from './CustomClaim';
import {TripSource} from './TripSource';
import TripStatus from './TripStatus';

export default class FirestoreQuery {
  constructor({name, path, visibleColumns, sortingColumn, sortingDirection, filterValues, uid, claims}) {
    this.name = name;
    this.path = path;
    this.visibleColumns = visibleColumns;
    this.sortingColumn = sortingColumn;
    this.sortingDirection = sortingDirection;
    this.filterValues = filterValues;
    this.uid = uid;
    this.timestamp = firebase.firestore.FieldValue.serverTimestamp();
  }
}

FirestoreQuery.generic = claims => {
  const queryId = firebase.firestore().collection('queries').doc().id;
  return [
    queryId,
    new FirestoreQuery({
      claims,
      name: null,
      path: queryId,
      visibleColumns: ColumnMetadata.default().map(column => column.path),
      filterValues: {
        type: 'delivery',
        status: TripStatus.notDone(),
      },
      uid: firebase.auth().currentUser.uid,
    }),
  ];
};

FirestoreQuery.defaultQueries = claims => {
  const queries = [
    [
      firebase.firestore().collection('queries').doc().id,
      new FirestoreQuery({
        claims,
        name: 'Drop-off today',
        path: 'drop-off-today',
        visibleColumns: ColumnMetadata.default().map(column => column.path),
        sortingColumn: 'dropOffTimestampMs',
        sortingDirection: SortingDirection.ascending,
        filterValues: {
          dropOffTimestampMs: 'TODAY_ONLY',
          status: TripStatus.notDone(),
        },
        uid: firebase.auth().currentUser.uid,
      }),
    ],
    [
      firebase.firestore().collection('queries').doc().id,
      new FirestoreQuery({
        claims,
        name: 'Scheduled',
        path: 'scheduled',
        visibleColumns: ColumnMetadata.default().map(column => column.path),
        sortingColumn: 'dropOffTimestampMs',
        sortingDirection: SortingDirection.ascending,
        filterValues: {
          isScheduled: true,
          status: TripStatus.notDone(),
        },
        uid: firebase.auth().currentUser.uid,
      }),
    ],
    [
      firebase.firestore().collection('queries').doc().id,
      new FirestoreQuery({
        claims,
        name: 'Newest',
        path: 'newest',
        visibleColumns: ColumnMetadata.default().map(column => column.path),
        filterValues: {
          status: TripStatus.notDone(),
        },
        sortingColumn: 'createdAt',
        sortingDirection: SortingDirection.descending,
        uid: firebase.auth().currentUser.uid,
      }),
    ],
  ];

  // if (!claims || !claims.floridaOnly) { // if not florida-only
  //     queries.push(
  //         [
  //             firebase.firestore().collection('queries').doc().id,
  //             new FirestoreQuery({
  //                 claims,
  //                 name: 'ezCater',
  //                 path: 'ezcater',
  //                 visibleColumns: ColumnMetadata.default().map(column => column.path),
  //                 sortingColumn: 'dropOffTimestampMs',
  //                 sortingDirection: SortingDirection.ascending,
  //                 filterValues: {
  //                     source: 'EZ_CATER',
  //                     status: TripStatus.notDone()
  //                 },
  //                 uid: firebase.auth().currentUser.uid
  //             })
  //         ],
  //         [
  //             firebase.firestore().collection('queries').doc().id,
  //             new FirestoreQuery({
  //                 claims,
  //                 name: 'OLO',
  //                 path: 'olo',
  //                 visibleColumns: ColumnMetadata.default().map(column => column.path),
  //                 sortingColumn: 'dropOffTimestampMs',
  //                 sortingDirection: SortingDirection.ascending,
  //                 filterValues: {
  //                     source: 'OLO',
  //                     status: TripStatus.notDone()
  //                 },
  //                 uid: firebase.auth().currentUser.uid
  //             })
  //         ],
  //         [
  //             firebase.firestore().collection('queries').doc().id,
  //             new FirestoreQuery({
  //                 claims,
  //                 name: 'Wavebytes',
  //                 path: 'wavebytes',
  //                 visibleColumns: ColumnMetadata.default().map(column => column.path),
  //                 sortingColumn: 'dropOffTimestampMs',
  //                 sortingDirection: SortingDirection.ascending,
  //                 filterValues: {
  //                     source: TripSource.dispatchApi.rawValue,
  //                     status: TripStatus.notDone()
  //                 },
  //                 uid: firebase.auth().currentUser.uid
  //             })
  //         ],
  //     )
  // }

  return queries;
};
