import React from 'react';
import {Redirect} from 'react-router-dom';
import Delay from '../../../components/Delay';
import FullSizeContent from '../../../components/FullSizeContent';
import Spinner from '../../../components/Spinner';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';

export default function RideWithoutCustomerId({match}) {
  const customerId = useRealtimeDatabase({
    path: `/trips-all/${match.params.tripId}/customerId`,
  });

  if (!customerId.data) return null;

  if (customerId.isLoading)
    return (
      <FullSizeContent>
        <Delay>
          <Spinner />
        </Delay>
      </FullSizeContent>
    );

  if (customerId.error) return 'Error';

  return <Redirect to={`/admin/jobs/${customerId.data}/${match.params.tripId}`} />;
}
