import HttpReference from '../../platform/HttpReference';
import Stripe from './Stripe';

export default class Payouts extends HttpReference<any, void, Stripe> {
  list() {
    return this
      .request
      // { params: { account: 'acct_1Iyl3PPNVa3KB4zq' } } as any
      ();
  }
}
