import findIndex from 'lodash/findIndex';
import round from 'lodash/round';
import {useEffect, useRef, useState} from 'react';
import {CoordinatesArray} from 'wave-common';
import unwrap from '../functions/unwrap';
import {useDeepComparisonMemo} from './useDeepComparisonEffect';
import useThrottledState from './useThrottledState';

export class Item<T> {
  key: string;
  coordinatesArray: CoordinatesArray;
  distanceKm: number;
  value?: T;

  static from<T>(item: Item<T>) {
    return new Item<T>(item.key, item.coordinatesArray, item.distanceKm, item.value);
  }

  constructor(key: string, coordinatesArray: CoordinatesArray, distanceKm: number, value?: T) {
    this.key = key;
    this.coordinatesArray = coordinatesArray;
    this.distanceKm = distanceKm;
    this.value = value;
  }
}

export default function useGeoFireQuery<T>(args: {
  realtimeDatabaseReference?: any;
  centerCoordinatesArray?: CoordinatesArray;
  radiusKm?: number;
}) {
  const {realtimeDatabaseReference, centerCoordinatesArray, radiusKm} = args;

  const delayMs = useRef(50);

  const radiusToUse = unwrap(radiusKm, radius => round(radius, 2)); // round raduis to nearest  meter, to avoid unneeded renders

  const [items, setItems] = useState<Item<T>[] | undefined>(undefined);
  const {state: throttledItems, setState: setThrottledItems} = useThrottledState<Item<T>[] | undefined>(
    undefined,
    delayMs.current,
  );
  useEffect(() => {
    if (items) {
      setThrottledItems(items);
    }
  }, [items, setThrottledItems]);
  const [queryIsReady, setQueryIsReady] = useState(false);

  // arguments --> query

  const query: any = useDeepComparisonMemo(() => {
    if (!realtimeDatabaseReference || !centerCoordinatesArray || !radiusToUse) {
      console.log('Setting query to null');
      return null;
    } else {
      console.log('Setting new query', realtimeDatabaseReference.toJSON(), centerCoordinatesArray, radiusToUse);
      const query = new (window as any).GeoFire(realtimeDatabaseReference).query({
        center: centerCoordinatesArray,
        radius: radiusToUse,
      });
      return query;
    }
  }, [realtimeDatabaseReference, centerCoordinatesArray, radiusToUse]);

  // query --> result

  useEffect(() => {
    if (!query) {
      setItems(undefined);
    } else {
      let initialKeys: string[] = [];

      const enteredRegistration = query.on(
        'key_entered',
        (key: string, coordinatesArray: CoordinatesArray, distanceKm: number) => {
          // console.log('key_entered', key, coordinatesArray, distanceKm);
          initialKeys.push(key);
          const item = new Item<T>(key, coordinatesArray, distanceKm, undefined);
          setItems(oldItems => {
            const newItems = [...(oldItems ?? [])];
            const index = findIndex(newItems, newItem => newItem.key === key);
            if (index === -1) newItems.push(item);
            else newItems[index] = item;
            return newItems;
          });
        },
      );

      const movedRegistration = query.on(
        'key_moved',
        (key: string, coordinatesArray: CoordinatesArray, distanceKm: number) => {
          // console.log('key_moved', key, coordinatesArray, distanceKm);
          setItems(oldItems => {
            const newItems = [...(oldItems ?? [])];
            const index = findIndex(newItems, item => item.key === key);
            if (index !== -1) newItems[index] = new Item(key, coordinatesArray, distanceKm, newItems[index].value);
            return newItems;
          });
        },
      );

      const exitedRegistration = query.on(
        'key_exited',
        (key: string, coordinatesArray: CoordinatesArray, distanceKm: number) => {
          // console.log('key_exited', key, coordinatesArray, distanceKm);
          setItems(oldItems => {
            const newItems = [...(oldItems ?? [])];
            const index = findIndex(newItems, item => item.key === key);
            newItems.splice(index, 1);
            return newItems;
          });
        },
      );

      const onReadyRegistration = query.on('ready', () => {
        console.log('Query ready');
        delayMs.current = 1500;
        setItems(currentItems => {
          let newItems: Item<T>[] = [...(currentItems ?? [])];
          newItems = newItems.filter(item => initialKeys.includes(item.key)); // set result to be only items in the initial load. not items from previous queries
          return newItems;
        });
        setQueryIsReady(true);
      });

      return () => {
        enteredRegistration.cancel();
        movedRegistration.cancel();
        exitedRegistration.cancel();
        onReadyRegistration.cancel();
      };
    }
  }, [query]);

  // useEffect(() => {
  //     console.log('Query items', throttledItems)
  // }, [throttledItems])

  if (!queryIsReady) {
    return undefined;
  }

  return throttledItems;
}
