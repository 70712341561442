import Enum from '../base/Enum';

export default class DispatchCancellationReason extends Enum {
  get title() {
    switch (this) {
      case DispatchCancellationReason.pickupNotReady:
        return 'Pickup not ready';
      case DispatchCancellationReason.destinationUnreachable:
        return 'Destination unreachable';
      case DispatchCancellationReason.packageContents:
        return 'Package contents';
      case DispatchCancellationReason.packageDamage:
        return 'Package damage';
      case DispatchCancellationReason.incompatibleDeliveryMode:
        return 'Incompatible delivery mode';
      case DispatchCancellationReason.driverFailure:
        return 'Driver failure';
      case DispatchCancellationReason.serviceOverComitted:
        return 'Service overcomitted';
      case DispatchCancellationReason.other:
        return 'Other';
      default:
        return null;
    }
  }
}

DispatchCancellationReason.pickupNotReady = new DispatchCancellationReason('PICKUP_NOT_READY');
DispatchCancellationReason.destinationUnreachable = new DispatchCancellationReason('DESTINATION_UNREACHABLE');
DispatchCancellationReason.packageContents = new DispatchCancellationReason('PACKAGE_CONTENTS');
DispatchCancellationReason.packageDamage = new DispatchCancellationReason('PACKAGE_DAMAGE');
DispatchCancellationReason.incompatibleDeliveryMode = new DispatchCancellationReason('INCOMPATIBLE_DELIVERY_MODE');
DispatchCancellationReason.driverFailure = new DispatchCancellationReason('DRIVER_FAILURE');
DispatchCancellationReason.serviceOverComitted = new DispatchCancellationReason('SERVICE_OVER_COMMITTED');
DispatchCancellationReason.other = new DispatchCancellationReason('OTHER');
