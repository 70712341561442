import React, {ReactNode, useState} from 'react';
import SaveableInput from '../SaveableInput';

export default function ViewOrSaveableInput<R>(props: {
  value: string;
  canEdit: boolean;
  save: (value: string) => Promise<R>;
  label: ReactNode;
  additionalContent?: ReactNode;
}) {
  const [isEditing, setIsEditing] = useState(false);

  if (!isEditing) {
    return (
      <div className="row no-gutters align-items-center">
        <div className="col">{props.label}</div>
        {props.additionalContent && <div className="col-auto">{props.additionalContent}</div>}
        {props.canEdit && (
          <div className="col-auto">
            <button type="button" onClick={event => setIsEditing(true)} className="btn btn-sm btn-light ml-1">
              Edit
            </button>
          </div>
        )}
      </div>
    );
  } else {
    return <SaveableInput initialValue={props.value} save={props.save} onComplete={() => setIsEditing(false)} />;
  }
}
