import firebase from 'firebase/app';
import FirestoreModel from '../base/FirestoreModel';

export default class Vendor extends FirestoreModel {
  static path = 'vendors';

  desiredPriceId?: string;
  name: string;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
  invoiceColumns?: string[];

  constructor(snapshot?: firebase.firestore.DocumentSnapshot) {
    super(snapshot);

    if (snapshot) {
      this.desiredPriceId = snapshot.get('desiredPriceId');
      this.name = snapshot.get('name');
      this.stripeCustomerId = snapshot.get('stripeCustomerId');
      this.stripeSubscriptionId = snapshot.get('stripeSubscriptionId');
      this.invoiceColumns = snapshot.get('invoiceColumns');
    } else {
      this.name = 'Unnamed';
    }
  }

  static async withName(name: string) {
    if (!name) return null;
    let snapshot = await firebase.firestore().collection('vendors').where('name', '==', name).get();
    if (snapshot.empty)
      snapshot = await firebase.firestore().collection('vendors').where('nameMatches', 'array-contains', name).get();
    if (snapshot.empty) return null;
    return snapshot.docs[0].data();
  }
}
