import last from 'lodash/last';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import joinClassNames from '../../functions/joinClassNames';
import unwrap from '../../functions/unwrap';
import useActiveTrips from '../../hooks/useActiveTrips';
import useHistoryTrips from '../../hooks/useHistoryTrips';
import useScheduledTrips from '../../hooks/useScheduledTrips';
import ContainerLayout from '../../layouts/ContainerLayout';
import TripLocation from '../../models/scoopm/TripLocation';
import TripType from '../../models/scoopm/TripType';

export default function Trips() {
  const activeTrips = useActiveTrips();
  const scheduledTrips = useScheduledTrips();
  const historyTrips = useHistoryTrips(5);

  const noTripsYet = Boolean(
    activeTrips.models &&
      !activeTrips.models.length &&
      scheduledTrips.models &&
      !scheduledTrips.models.length &&
      historyTrips.models &&
      !historyTrips.models.length,
  );

  return (
    <ContainerLayout>
      {noTripsYet && <NoRidesYet />}

      <Section
        title="Ongoing"
        trips={useMemo(() => unwrap(activeTrips.models, models => sortOldest(models)), [activeTrips.models])}
        additionalContent={!noTripsYet && <BookButton />}
      />

      <hr className="my-2" />

      <Section
        title="Up next"
        trips={useMemo(() => unwrap(scheduledTrips.models, models => sortOldest(models)), [scheduledTrips.models])}
      />

      <hr className="my-2" />

      <Section
        title="History"
        trips={useMemo(() => unwrap(historyTrips.models, models => sortNewest(models)), [historyTrips.models])}
        additionalContent={<Link to="/jobs/history">View all</Link>}
      />
    </ContainerLayout>
  );
}

function NoRidesYet() {
  return (
    <div className="jumbotron bg-blue text-white">
      <h2 className="mb-3">You haven't booked a ride yet</h2>
      {/* <p className="lead mb-4">
        Ride with our quality service. You can schedule a pickup, or book a driver for a number of hours.
      </p> */}
      <BookButton large />
    </div>
  );
}

function sortOldest(trips) {
  return sortBy(
    trips,
    trip => trip.mostRelevantTimestampMs(),
    trip => trip.createdAt || 0,
  );
}

function sortNewest(trips) {
  return reverse(sortOldest(trips));
}

function BookButton({large = false}) {
  return (
    <Link to="/jobs/create" className={joinClassNames('btn', large ? 'bg-white btn-lg' : 'btn-primary')}>
      Book now
    </Link>
  );
}

function Section({title, additionalContent, trips}) {
  return (
    <div>
      <div className="row mb-4">
        <div className="col">
          {title && <h3 className="mb-0">{title}</h3>}
          {/* <h6 className="text-muted">
                        {trips && (
                            `${trips.length}`
                        )}
                    </h6> */}
        </div>
        {additionalContent && <div className="col-auto d-flex align-items-center">{additionalContent}</div>}
      </div>
      <div
        className="d-flex flex-nowrap mb-4"
        style={{
          height: '10.5rem',
          overflowX: 'auto',
          WebkitOverflowScrolling: 'touch',
        }}>
        {unwrap(trips, trips => trips.map(trip => <JobCard key={trip.id} trip={trip} className="mr-2 mr-md-3" />))}
      </div>
    </div>
  );
}

export function JobCard({trip, className}) {
  const locations = trip.locationsWithDefault();
  return (
    <Link to={`/jobs/${trip.customerId}/${trip.id}`} className="d-flex text-decoration-none">
      <div
        className={joinClassNames('d-flex flex-column bg-light p-2 p-md-3', className)}
        style={{width: '18rem', flex: '0 0 auto', borderRadius: '1rem'}}>
        <span className="text-gray-500">
          {unwrap(TripType.from(trip.type), type => type.customerFacingTitle(), 'Trip')}
        </span>
        <h6 className="flex-grow-1">{TripLocation.prototype.simplestTitle.apply(last(locations))}</h6>
        <span className="text-gray-700">{trip.statusTitle()}</span>
      </div>
    </Link>
  );
}
