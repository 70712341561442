import moment from 'moment';
import {unwrap} from 'wave-common';

export function simluateDownloadClickCsv(
  csvString: string,
  fileName?: string,
  dataTypeName?: string,
  bucketName?: string,
) {
  simulateDownloadClick(
    `data:text/csv;charset=utf-8,${encodeURIComponent(csvString)}`,
    fileName ||
      unwrap(dataTypeName, dataTypeName =>
        unwrap(bucketName, bucketName => moment().format(`[${dataTypeName}_${bucketName}]_YYYY-MM-DD_hh-mm-A`)),
      ) ||
      'Export.csv',
  );
}

export function simulateDownloadClick(href: string, filename: string) {
  var a = window.document.createElement('a');
  a.setAttribute('href', href);
  a.setAttribute('download', filename);
  a.style.display = 'none';
  window.document.body.appendChild(a);
  a.click();
  window.document.body.removeChild(a);
}
