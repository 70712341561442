import $ from 'jquery';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {unwrap} from 'wave-common';
import joinClassNames from '../../functions/joinClassNames';
import useStatus from '../../hooks/useStatus';
import ValidateableInput from '../bootstrap/ValidateableInput';
import Delay from '../Delay';
import FontAwesome from '../FontAwesome';
import Input from '../Input';
import Spinner from '../Spinner';
import Alert from './Alert';

export default function AlertModal<ConfirmResult, CancelResult>({
  alert,
  setAlert,
}: {
  alert?: Alert<ConfirmResult, CancelResult>;
  setAlert: React.Dispatch<React.SetStateAction<undefined>>;
}) {
  // state

  const {status, error, setStatus, handlePromise} = useStatus();
  const [inputText, setInputText] = useState('');

  // effect: modal handling

  useEffect(() => {
    const handler = (event: JQuery.TriggeredEvent<HTMLElement, undefined, HTMLElement, HTMLElement>) => {
      setAlert(undefined);
      if (alert?.onDismiss) {
        alert.onDismiss(error);
      }
    };
    const alertModal = $('#alertModal');
    alertModal.on('hidden.bs.modal', handler);
    return () => {
      alertModal.off('hidden.bs.modal', handler);
    };
  }, [alert, setAlert, error]);

  // callback: dismiss

  const dismiss = useCallback((error?: Error) => {
    ($('#alertModal') as any).modal('hide');
  }, []);

  // callback: on click confirm

  const onClickConfirm = useCallback(() => {
    if (alert?.confirmAction) {
      handlePromise(
        alert.confirmAction(inputText).then(result => {
          dismiss();
        }),
      );
    } else {
      dismiss();
    }
  }, [dismiss, handlePromise, alert, inputText]);

  // callback: on click cancel

  const onClickCancel = useCallback(() => {
    if (alert?.cancelAction) {
      handlePromise(
        alert.cancelAction().then(result => {
          dismiss();
        }),
      );
    } else {
      dismiss();
    }
  }, [alert, handlePromise, dismiss]);

  // effect: show/hide when alert changes, reset pending state

  useEffect(() => {
    ($('#alertModal') as any).modal(alert ? 'show' : 'hide');

    setStatus('IDLE');

    if (alert) {
      const eventType = 'keyup';
      const listener = (event: KeyboardEvent) => {
        if (event.code === 'Enter') {
          // console.log('PRESSED ENTER', new Date());
          onClickConfirm();
        }
      };
      window.addEventListener(eventType, listener);
      return () => {
        window.removeEventListener(eventType, listener);
      };
    }
  }, [alert, setStatus, onClickConfirm]);

  // memo: don't ask again

  const [doNotAskAgainFullKey, doNotAskAgain] = useMemo(
    () =>
      unwrap(
        alert?.dontAskAgainKey,
        dontAskAgainKey => {
          const doNotAskAgainFullKey = `DO_NOT_ASK_${dontAskAgainKey}`;
          return [doNotAskAgainFullKey, window.localStorage.getItem(doNotAskAgainFullKey) as 'YES' | 'NO' | null];
        },
        () => [],
      ),
    [alert?.dontAskAgainKey],
  );

  // effect: if "don't ask again", simulate confirmation click

  useEffect(() => {
    if (doNotAskAgain === 'YES') {
      onClickConfirm();
    }
  }, [doNotAskAgain, onClickConfirm]);

  // render

  if (doNotAskAgain === 'YES') {
    return null;
  }

  if (!alert) {
    return null;
  }

  const confirmText = alert?.confirmText ?? 'OK';

  const shouldShowCancelButtons = alert?.cancelText || alert?.cancelAction;

  return (
    <div id="alertModal" className="modal fade" tabIndex={-1}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header mb-n3">
            <h5 className="modal-title">{alert?.title}</h5>
            {/* {shouldShowCancelButtons && (
              <button
                type="button"
                onClick={onClickCancel}
                className="close"
                aria-label="Close"
                disabled={status === "PENDING"}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )} */}
          </div>
          {alert?.body && (
            <div className="modal-body">
              {alert.body}
              {alert.prompt && (
                <ValidateableInput
                  elementType={Input}
                  placeholder={alert.prompt}
                  value={inputText}
                  onChange={(event: any) => setInputText(event.target.value)}
                  className="form-control mt-2"
                  validation={error?.message}
                />
              )}
            </div>
          )}
          <div className="modal-footer align-items-stretch">
            {doNotAskAgainFullKey && (
              <div className="form-check flex-grow-1">
                <input
                  id="DO_NOT_ASK_CHECKBOX"
                  type="checkbox"
                  onChange={event => {
                    window.localStorage.setItem(doNotAskAgainFullKey, event.target.checked ? 'YES' : 'NO');
                  }}
                  className="form-check-input"
                />
                <label htmlFor="DO_NOT_ASK_CHECKBOX" className="form-check-label">
                  Don't ask again
                </label>
              </div>
            )}

            {shouldShowCancelButtons && (
              <button
                type="button"
                onClick={onClickCancel}
                className="btn btn-outline-secondary"
                disabled={status === 'PENDING'}>
                {alert.cancelText ?? 'Cancel'}
                {/* {' '}
                                <span className="badge badge-light border ml-1">ESC</span> */}
              </button>
            )}

            <button
              type="button"
              onClick={onClickConfirm}
              className={joinClassNames(
                'btn',
                status === 'FAILURE' && !alert.prompt ? 'btn-outline-danger' : 'btn-primary',
              )}
              disabled={status === 'PENDING'}>
              {status === 'PENDING' ? (
                <Delay
                  amountMs={5000}
                  childrenBeforeTimeout={
                    (
                      <Delay childrenBeforeTimeout={confirmText as any}>
                        <Spinner small /> {confirmText}
                      </Delay>
                    ) as any
                  }>
                  <Spinner small /> Please be patient
                </Delay>
              ) : (
                confirmText
              )}
            </button>

            {error && !alert.prompt && (
              <span className="text-danger">
                <FontAwesome.InfoCircle className="mr-2" />
                {error.message}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
