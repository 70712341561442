import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';
import Delay from '../../../components/Delay';
import Spinner from '../../../components/Spinner';
import unwrap from '../../../functions/unwrap';
import useTripEzCaterEvents from '../../../hooks/useTripEzCaterEvents';

export default function TripEvents({tripData}) {
  //TODO: add link, e.g. https://console.firebase.google.com/u/0/project/towmasters-2f8b3/functions/logs?search=&functionFilter=v1(us-central1)&severity=DEBUG&selectedDate=Mon%20Jan%2024%202022%2009:30:00%20GMT-0500%20(EST)

  const events = useTripEzCaterEvents(tripData);

  if (events.isLoading) {
    return (
      <Delay>
        <Spinner />
      </Delay>
    );
  }

  const eventEntries = unwrap(events.data, data => Object.entries(data));

  if (!eventEntries) {
    return null;
  }

  if (!eventEntries.length) {
    return <>None</>;
  }

  return (
    <pre className="mb-0">
      {eventEntries.map(([eventId, event]) => (
        <div key={eventId}>
          {moment(event.timestampMs).calendar()} &mdash;{' '}
          <strong>{event.requestHeaders && event.requestHeaders['x-ezcater-event']}</strong> &mdash;{' '}
          {event.responseCode} {unwrap(event.responseBody, body => body.message || JSON.stringify(body))}{' '}
          {unwrap(event.error, e => (
            <strong>{e.message}</strong>
          ))}{' '}
          <Link to={`/admin/events/${eventId}`} className="btn btn-light btn-sm ml-2 my-1">
            Details
          </Link>
        </div>
      ))}
    </pre>
  );
}
