import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import Card from '../../components/Card';
import {DelaySpinner} from '../../components/Spinner';
import ViewInFirebase from '../../components/ViewInFirebase';
import useRealtimeDatabase from '../../hooks/useRealtimeDatabase';
import CenteredLayout from '../../layouts/CenteredLayout';
import FirestoreModelForm from './FirestoreModelForm';
import SchemaDisplay from './SchemaDisplay';

export default function RealtimeDatabaseModelDisplay({schema, id}) {
  const history = useHistory();
  const path = `${schema.collection()}/${id}`;
  console.log('path', path);
  const model = useRealtimeDatabase({path});
  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const [isDeleting, setIsDeleting] = useState(false);

  // METHODS

  const deleteModel = event => {
    if (!window.confirm('Delete this item?')) {
      return;
    }
    console.log(`Deleting model...`);
    setIsDeleting(true);
    model.remove().then(
      () => {
        setIsDeleting(false);
        console.log(`Deleted model`);
        history.push(`/admin/${schema.collection()}`);
      },
      error => {
        setIsDeleting(false);
        console.error(`Error deleting model`, error);
      },
    );
  };

  // RENDER

  if (model.error) {
    return <div className="alert alert-warning">Error. {model.error.message}</div>;
  }

  if (isEditing) {
    return (
      <FirestoreModelForm
        schema={schema}
        id={id}
        model={model.data}
        onSave={toggleIsEditing}
        onCancel={toggleIsEditing}
      />
    );
  }

  if (!model.data && !isDeleting) {
    return 'This item does not exist';
  }

  if (model.isLoading) {
    return (
      <CenteredLayout>
        <DelaySpinner />
      </CenteredLayout>
    );
  }

  return (
    <Card padded>
      <SchemaDisplay schema={schema} model={model.data} />

      <div className="d-flex justify-content-end">
        <ViewInFirebase path={`${schema.collection()}/${id}`} padded={false} className="mr-2" />

        <button onClick={deleteModel} className="btn btn-outline-danger mr-2" disabled={isDeleting}>
          Delete...
        </button>

        <button onClick={toggleIsEditing} className="btn btn-outline-primary" disabled={isDeleting}>
          Edit
        </button>
      </div>
    </Card>
  );
}
