"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function useIntervalMemo(react, intervalMs, factory, deps, label) {
    const [state, setState] = react.useState(factory());
    react.useEffect(() => {
        // console.log('START')
        setState(factory());
        const handle = setInterval(() => {
            // console.log(`Tick: ${label}`)
            setState(factory());
        }, intervalMs);
        return () => {
            clearInterval(handle);
        };
    }, [intervalMs, deps]);
    return state;
}
exports.default = useIntervalMemo;
