import firebase from 'firebase/app';
import {useMemo} from 'react';
import FirestoreModel from '../models/base/FirestoreModel';
import Schema from '../schemas/types/Schema';
import FirestoreQueryState from './FirestoreQueryState';
import useFirestoreQueryGet from './useFirestoreQueryGet';

export default function useFirestoreSchemaGet<Model extends FirestoreModel>(
  schema: Schema<Model>,
): FirestoreQueryState<Model> {
  const query = useMemo(() => firebase.firestore().collection(schema.collection()), [schema]);
  return useFirestoreQueryGet(query, schema.Model);
}
