import Enum from '../../models/base/Enum';

class MapType extends Enum {}

MapType.hybrid = new MapType('hybrid');
MapType.roadmap = new MapType('roadmap');
MapType.satellite = new MapType('satellite');
MapType.terrain = new MapType('terrain');

export default MapType;
