"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pricingFormulaTypeTitle = exports.pricingFormulaTypeTripPropertyName = exports.pricingFormulaTypePropertyName = exports.pricingFormulaTypeOverridePropertyName = exports.pricingFormulaTypeCollection = void 0;
function pricingFormulaTypeCollection(type) {
    switch (type) {
        case 'invoice':
            return 'invoice-formulas';
        case 'payout':
            return 'payout-formulas';
    }
}
exports.pricingFormulaTypeCollection = pricingFormulaTypeCollection;
function pricingFormulaTypeOverridePropertyName(type) {
    switch (type) {
        case 'invoice':
            return 'didOverrideEstimate';
        case 'payout':
            return 'didOverrideDriverEarnings';
    }
}
exports.pricingFormulaTypeOverridePropertyName = pricingFormulaTypeOverridePropertyName;
function pricingFormulaTypePropertyName(type) {
    switch (type) {
        case 'invoice':
            return 'invoiceFormulaId';
        case 'payout':
            return 'payoutFormulaId';
    }
}
exports.pricingFormulaTypePropertyName = pricingFormulaTypePropertyName;
function pricingFormulaTypeTripPropertyName(type) {
    switch (type) {
        case 'invoice':
            return 'estimate';
        case 'payout':
            return 'driverEarningsD';
    }
}
exports.pricingFormulaTypeTripPropertyName = pricingFormulaTypeTripPropertyName;
function pricingFormulaTypeTitle(type, tripType) {
    switch (type) {
        case 'invoice':
            switch (tripType) {
                case 'delivery':
                    return 'Delivery fee';
                default:
                    return 'Fee';
            }
        case 'payout':
            return 'Driver rate';
    }
}
exports.pricingFormulaTypeTitle = pricingFormulaTypeTitle;
