import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import CustomClaim from '../../models/scoopm/CustomClaim';
import PropertyType from './PropertyType';

export default class Property<T = any> {
  name: string;
  type: PropertyType;

  isOptional: boolean;
  isArray: boolean;
  tableColumnWidth?: number;
  userFacingName: string;
  customClaims?: CustomClaim[];
  readonly?: boolean;
  defaultValue?: () => T;
  description?: string;
  path: string | undefined;
  useIdAsName: ((id: string) => string) | undefined;

  constructor(
    name: string,
    type: PropertyType,
    options?: {
      isOptional?: boolean;
      isArray?: boolean;
      tableColumnWidth?: number;
      userFacingName?: string;
      customClaims?: CustomClaim[];
      readonly?: boolean;
      defaultValue?: () => T;
      description?: string;
      path?: string;
      useIdAsName?: (id: string) => string;
    },
  ) {
    this.name = name;
    this.type = type;

    this.isOptional = options?.isOptional || false;
    this.isArray = options?.isArray || false;
    this.tableColumnWidth = options?.tableColumnWidth;
    this.userFacingName = options?.userFacingName || capitalize(lowerCase(name.replace('Id', '')));
    this.customClaims = options?.customClaims;
    this.readonly = options?.readonly;
    this.defaultValue = options?.defaultValue;
    this.description = options?.description;
    this.path = options?.path;
    this.useIdAsName = options?.useIdAsName;
  }

  /**
   * Returns true if valid, or throws an error if not. The error message can be displayed to the user
   * @param {*} value The model value of the property
   */
  validate(value: unknown): boolean {
    // spacer?

    if (this.type === PropertyType.spacer) {
      return true;
    }

    // no value?

    if (!value) {
      if (this.isOptional) {
        return true;
      } else {
        throw new Error('Required');
      }
    }

    // validate

    return PropertyType.validate(this.type, value);
  }
}
