import React, {useEffect, useState} from 'react';
import TabContainer from '../../../components/TabContainer';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';
import Control from './Control';
import ServiceAreaPanel from './ServiceAreaPanel';

export default function Geofences({history, match}) {
  const [tabs, setTabs] = useState();

  const apiUsers = useRealtimeDatabase({path: '/api-users', isArray: true});

  useEffect(() => {
    if (!apiUsers.data) {
      setTabs(null);
    } else {
      const tabs = {
        asap: {title: 'ASAP Rides', render: () => <Control path="control" />},
        scheduled: {
          title: 'Scheduled rides',
          render: () => <Control path="controls-scheduled-rides" />,
        },
      };
      Object.assign(
        tabs,
        ...apiUsers.data.map(apiUser => ({
          [apiUser.key]: {
            title: apiUser.label,
            render: () => <ServiceAreaPanel apiUserName={apiUser.key} />,
          },
        })),
      );
      setTabs(tabs);
    }
  }, [apiUsers.data]);

  return (
    <div className="p-2 p-md-3 bg-light">
      <TabContainer
        tabs={tabs}
        selected={match.params.geofenceId || 'asap'}
        onSelect={id => history.push(`/admin/geofences/${id}`)}
      />
    </div>
  );
}
