import snakeCase from 'lodash/snakeCase';
import React, {ReactElement} from 'react';
import FontAwesome from '../../../../components/FontAwesome';
import joinClassNames from '../../../../functions/joinClassNames';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useScreenIsSmall from '../../../../hooks/useScreenIsSmall';

export default function SectionCard({
  title,
  alternateTitle,
  children,
}: {
  title: string;
  alternateTitle?: string;
  children: ReactElement;
}) {
  const screenIsSmall = useScreenIsSmall();

  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    `DRIVER_SECTION_${snakeCase(title).toUpperCase()}_IS_COLLAPSED`,
    false,
  );

  return (
    <div className="rounded bg-white mb-2 mb-md-3">
      <div
        onClick={screenIsSmall ? () => setIsCollapsed(!isCollapsed) : undefined}
        className={joinClassNames('d-flex align-items-center p-2 p-md-3 ', screenIsSmall && 'cursor-pointer')}>
        <h5 className="mb-0 flex-grow-1">{title}</h5>

        {isCollapsed && alternateTitle && (
          <div className="text-muted text-right ellipsis" style={{width: '16rem'}}>
            {alternateTitle}
          </div>
        )}

        <button onClick={() => setIsCollapsed(!isCollapsed)} className="btn text-muted">
          {isCollapsed ? <FontAwesome.Plus /> : <FontAwesome.Minus />}
        </button>
      </div>

      {!isCollapsed && <div className="px-2 pb-2 px-md-3 pb-md-3">{children}</div>}
    </div>
  );
}
