import React, {useMemo} from 'react';
import {ArrayParam, useQueryParam} from 'use-query-params';
import FirebaseTable from '../../../components/FirebaseTable';
import useStatesAndRegions from '../../../hooks/useStatesAndRegions';
import Driver from '../../../models/scoopm/Driver';
import SelectOption from '../../../models/SelectOption';
import csvKeys from './misc/csvKeys';
import driverFlags from './misc/driverFlags';
import getAdditionalCsvData from './misc/getAdditionalCsvData';
import headings from './misc/headings';

export default function DriversTable(props: {
  title: string;
  orderByChild?: string;
  equalTo?: any;
  columns?: any[];
  flags?: any[];
  filter?: (driver: Driver) => boolean;
}) {
  const {filter} = props;

  const {
    regionOptions,
    regionValue,
    regionObject,
    state,
    setRegion,
    isLoading: regionsAreLoading,
  } = useStatesAndRegions(undefined, 'DRIVERS_TABLE_REGION');
  const [selectedFlagStrings] = useQueryParam('flags', ArrayParam);
  const flagsIncludesTesting = useMemo(
    () => selectedFlagStrings && selectedFlagStrings.includes('isTestingAccount'),
    [selectedFlagStrings],
  );

  const filterInternal = useMemo(
    () => (driver: Driver) => {
      // filter for test drivers

      if (!flagsIncludesTesting) {
        if (driver.isTestingAccount) {
          return false;
        }
      }

      // filter by region

      if (regionObject) {
        if (driver.addressCoordinatesArray) {
          return regionObject.contains(driver.addressCoordinatesArray);
        } else {
          return false;
        }
      }

      // filter by state

      if (state) {
        if (driver.address && driver.address.region) {
          return driver.address.region === state;
        } else {
          return false;
        }
      }

      // apply filter

      if (filter) {
        return filter(driver);
      }

      return true;
    },
    [filter, regionObject, state, flagsIncludesTesting],
  );

  return (
    <FirebaseTable
      title={props.title}
      path="drivers"
      orderByChild={props.orderByChild}
      equalTo={props.equalTo}
      link={(driver: any) => `/admin/drivers/${driver.key}`}
      searchTerm={((driver: Driver) => `${driver.firstName} ${driver.lastName} ${driver.email}`) as any}
      columns={props.columns ?? headings}
      flags={props.flags ?? (driverFlags as any)}
      cellClassName={((driver: Driver) => (driver.isTestingAccount ? 'testing-account-background' : '')) as any}
      csvKeys={csvKeys as any}
      getAdditionalCsvData={getAdditionalCsvData as any}
      clientSideFilter={filterInternal as any}
      additionalBarComponents={
        (
          <select
            value={regionValue ?? ''}
            onChange={event => setRegion((event.target.value || undefined) as any)}
            className="custom-select border-0">
            <option value="">All regions</option>
            <option value="" disabled>
              -
            </option>
            {SelectOption.render(regionOptions)}
          </select>
        ) as any
      }
      isLoading={regionsAreLoading as any} // prevents flickering
      linkTitle={undefined}
      getAdditionalData={undefined}
      additionalMenuComponents={undefined}
      model={undefined}
      limitToFirst={undefined}
    />
  );
}
