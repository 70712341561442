import React from 'react';
import {Link} from 'react-router-dom';
import Card from '../../../components/Card';

export default function Reports() {
  return (
    <div className="p-4">
      <h3>Reports</h3>
      <div className="d-flex">
        <Card className="mr-2 mr-md-3">
          <h5>ATL Rideshare CSV</h5>
          <p>Used for government reporting</p>
          <Link to="/admin/drivers/reports/atl-rideshare-csv" className="btn btn-outline-primary w-100">
            View
          </Link>
        </Card>
        <Card>
          <h5>Drivers/mileage</h5>
          <p>Drivers by state, including mileage</p>
          <Link to="/admin/drivers/reports/drivers-mileage" className="btn btn-outline-primary w-100">
            View
          </Link>
        </Card>
      </div>
    </div>
  );
}
