"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
async function tryOrUndefined(promise) {
    try {
        return await promise;
    }
    catch (_a) {
        return undefined;
    }
}
exports.default = tryOrUndefined;
