import Constants from '../../Constants';
import Customer from './Customer';

export default class TripCustomer {
  constructor({firstName, lastName, cellphone} = {}) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.cellphone = cellphone;
  }
}

TripCustomer.fromCustomer = customer =>
  new TripCustomer({
    firstName: customer.firstName,
    lastName: customer.lastName,
    cellphone: Customer.prototype.safePhoneNumber.apply(customer),
  });

TripCustomer.adminPortal = () =>
  new TripCustomer({
    firstName: 'Admin',
    lastName: 'Portal',
    cellphone: Constants.information.supportPhoneE164,
  });
