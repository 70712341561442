import firebase from 'firebase/app';
import $ from 'jquery';
import moment from 'moment';
import React, {MouseEvent, useEffect, useRef, useState} from 'react';
import unwrap from '../../../functions/unwrap';
import DispatchCancellationReason from '../../../models/scoopm/DispatchCancellationReason';
import Trip from '../../../models/scoopm/Trip';
import {TripSource} from '../../../models/scoopm/TripSource';
import TripStatus from '../../../models/scoopm/TripStatus';
import TripType from '../../../models/scoopm/TripType';
import RealtimeDatabase from '../../../references/database/RealtimeDatabase';
import ScoopMApi from '../../../references/scoopm-api';

export default function CancelTripComponent({
  tripId,
  trip,
  className,
}: {
  tripId: string;
  trip: Trip;
  className?: string;
}) {
  // REFS

  const dropdown = useRef();

  // STATE

  const [isShowingMenu, setIsShowingMenu] = useState(false);
  const [cancellationReason, setCancellationReason] = useState<DispatchCancellationReason | undefined>(undefined);
  const [comments, setComments] = useState(null);
  const [isCancelling, setIsCancelling] = useState(false);
  const [ezDeliveryIsWithin1Hour, setEzDeliveryIsWithin1Hour] = useState(false);

  // EFFECT: body.click --> hide menu

  useEffect(() => {
    // on click outside of menu
    (window as any).$('body').on('click', (event: MouseEvent) => {
      if (dropdown.current === event.target || (window as any).$(dropdown.current).has(event.target).length > 0) {
        return;
      }
      setIsShowingMenu(false);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (ezDeliveryIsWithin1Hour) {
        ($('[data-toggle="tooltip"]') as any).tooltip();
      } else {
        ($('[data-toggle="tooltip"]') as any).tooltip('dispose');
      }
    }, 100);
  }, [ezDeliveryIsWithin1Hour]);

  // EFFECT: trip, isCancelling --> can cancel

  useEffect(() => {
    setEzDeliveryIsWithin1Hour(
      Boolean(
        trip &&
          trip.source === TripSource.ezCater.rawValue &&
          trip.dropOffTimestampMs &&
          moment(trip.dropOffTimestampMs).isBefore(moment().add(1, 'hour')) &&
          trip.statusType() !== TripStatus.created, // can still cancel if never accepted
      ),
    );
  }, [trip]);

  // VIEW METHODS

  const onClickDropdownButton = (event: MouseEvent) => {
    setIsShowingMenu(!isShowingMenu);
  };

  const onSelectChange = (event: any) => {
    setCancellationReason(DispatchCancellationReason.from(event.target.value) as DispatchCancellationReason);
  };

  const onTextareaChange = (event: any) => {
    setComments(event.target.value.trim() || null);
  };

  const onClickConfirm = (event: MouseEvent) => {
    event.preventDefault();

    setIsCancelling(true);

    // cancel ez delivery if relevant

    const promise = unwrap(
      trip.ezCaterDeliveryId,
      id => ScoopMApi.instance.dispatch.ezCater.deliveries.child(id).reject.patch(),
      Promise.resolve(),
    );

    promise
      .then(() => {
        // cancel trip

        let updates = {
          [`/trips/${trip.customerId}/${tripId}/status`]: TripStatus.canceledByRider.rawValue,
          [`/trips/${trip.customerId}/${tripId}/canceledAt`]: firebase.database.ServerValue.TIMESTAMP,
          [`/trips/${trip.customerId}/${tripId}/dispatchCancellationReason`]: cancellationReason
            ? cancellationReason.rawValue
            : null,
          [`/trips/${trip.customerId}/${tripId}/cancellationComments`]: comments || null,
          [`/customers/${trip.customerId}/currentRide`]: null,
        };

        if (trip.driverId) {
          updates[`/drivers/${trip.driverId}/currentTrip`] = null;
        }

        return RealtimeDatabase.instance.update(updates).catch(error => {
          // error cancelling

          console.error('Error cancelling trip', error);
          alert(`Error cancelling trip. ${error.message}`);
        });
      })
      .catch((error: any) => {
        // error rejecting

        alert(`Error rejecting delivery. ${error.message}`);
      })
      .finally(() => {
        // stop pending indication

        setIsCancelling(false);
      });
  };

  // RENDER

  if (!trip || trip.isDone()) {
    return null;
  }

  const cancelButton = (
    <button
      type="button"
      className={'btn btn-outline-danger dropdown-toggle ' + (className || '')}
      onClick={onClickDropdownButton}
      style={ezDeliveryIsWithin1Hour ? {pointerEvents: 'none'} : undefined}
      disabled={ezDeliveryIsWithin1Hour}>
      {trip.source === TripSource.ezCater.rawValue ? 'Reject' : 'Cancel'}
    </button>
  );

  return (
    <div ref={dropdown as any} className="dropdown ml-2">
      {/* wrapper for tooltip */}

      {ezDeliveryIsWithin1Hour ? (
        <span data-toggle="tooltip" title="Can't cancel ez order within 1 hour of drop-off" tabIndex={0}>
          {cancelButton}
        </span>
      ) : (
        cancelButton
      )}

      <div
        className={'dropdown-menu dropdown-menu-right shadow' + (isShowingMenu ? ' show' : '')}
        style={{minWidth: '20rem'}}>
        <div className="px-3 py-2">
          {trip.isDelivery && (
            <div className="mb-3">
              <label htmlFor="cancellationReasonSelect">
                {trip.source === TripSource.ezCater.rawValue ? 'Rejection' : 'Cancellation'} reason
              </label>

              <select
                id="cancellationReasonSelect"
                onChange={onSelectChange}
                value={cancellationReason ? cancellationReason.rawValue : ''}
                className="form-control">
                <option value="">Select</option>

                {DispatchCancellationReason.allCases.map((reason, i) => (
                  <option key={i} value={reason.rawValue}>
                    {reason.title}
                  </option>
                ))}
              </select>
            </div>
          )}

          {trip.isDelivery && (
            <div className="mb-3">
              <label htmlFor="commentsTextarea">Comments (optional)</label>
              <textarea id="commentsTextarea" rows={4} onChange={onTextareaChange} className="form-control"></textarea>
            </div>
          )}

          <div className="text-right">
            <button
              onClick={onClickConfirm}
              className="btn btn-danger"
              disabled={
                (trip.type === TripType.delivery.rawValue ? cancellationReason === null : false) || isCancelling
              }>
              Confirm {isCancelling && <i className="fa fa-cog fa-spin ml-1" />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
