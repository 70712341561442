import React, {Component} from 'react';
import Constants from '../../Constants';
import {checkForProp} from '../../functions/checkForProp';
import {dmflog} from '../../functions/dmflog';
import {forin} from '../../functions/forin';

import {loop} from '../../functions/loop';

import myLocationImage from '../../images/myLocation.png';
import {getDriverOverlay} from './DriverOverlay';
import './ScoopMap.css';
import {ScoopMarker} from './ScoopMarker';

export class ScoopMap extends Component {
  static get charlotte() {
    return new window.google.maps.LatLng(35.2270869, -80.84312669999997);
  }
  static defaultZoom = 16;

  constructor(props) {
    super(props);

    checkForProp(`markers`, this, true);
    checkForProp(`routeMarkers`, this, true);
    checkForProp(`onClickMarker`, this, true);

    checkForProp(`customerLocation`, this, true);
    checkForProp(`driverLocation`, this, true);

    this.state = {
      isTrackingLocation: true,
    };

    this.markers = {
      general: {},
      route: {},
    };

    this.infoWindows = {
      general: {},
      route: {},
    };
  }

  componentDidMount() {
    this.initMap();
  }

  initMap() {
    // map
    this.map = new window.google.maps.Map(window.document.getElementById(`scoopmap`), {
      center: ScoopMap.charlotte,
      zoom: ScoopMap.defaultZoom,
      // disable controls
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
    });
    this.map.addListener(`drag`, this.onDragMap.bind(this));
    this.map.addListener(`zoom_changed`, this.mapZoomDidChange.bind(this));

    // directions
    this.directionsService = new window.google.maps.DirectionsService();
    this.directionsRenderer = new window.google.maps.DirectionsRenderer({
      map: this.map,
      suppressMarkers: true,
      preserveViewport: true,
    });
    window.directionsRenderer = this.directionsRenderer;
  }

  onDragMap() {
    this.setState({isTrackingLocation: false});
  }

  mapZoomDidChange() {
    if (this.state.shouldPadZoom) {
      // only do once
      this.setState({shouldPadZoom: false}, this.extendBoundsForTopPadding);
    }
  }

  extendBoundsForTopPadding() {
    dmflog(this, `extendBoundsForTopPadding`);

    // get padding coordinates
    let coords = this.getPaddingPointCoords();

    // extend map
    this.bounds.extend(coords);
    this.map.fitBounds(this.bounds);
  }

  getPaddingPointCoords() {
    //https://developers.google.com/maps/documentation/javascript/coordinates
    dmflog(this, `getPaddingPointCoords`);

    // get current NE coords
    let currentNeLatLng = this.bounds.getNorthEast();
    dmflog(this, `NE coords`, currentNeLatLng.lat(), currentNeLatLng.lng());

    // convert to Point
    dmflog('this.map', this.map);
    dmflog('this.map.getProjection()', this.map.getProjection());

    let currentNePoint = this.map.getProjection().fromLatLngToPoint(currentNeLatLng);

    // get offset in Points
    let pixelOffset = this.props.topPadding;
    pixelOffset += 300; // padding for info windows
    var scale = Math.pow(2, this.map.getZoom());
    let pointOffset = pixelOffset / scale;
    dmflog(this, `zoom`, this.map.getZoom());
    dmflog(this, `pointOffset`, pointOffset, `px offset`, pointOffset * scale);

    // create new NE Point
    let newNePoint = new window.google.maps.Point(currentNePoint.x, currentNePoint.y - pointOffset);

    // convert new NE Point back to coords
    let newNeLatLng = this.map.getProjection().fromPointToLatLng(newNePoint);
    dmflog(this, `new NE coords`, newNeLatLng.lat(), newNeLatLng.lng());

    // return
    return newNeLatLng;
  }

  componentDidUpdate(prevProps, prevState) {
    // position
    if (this.props.position !== prevProps.position) {
      this.didUpdatePosition();
    }

    // markers
    if (this.props.markers !== prevProps.markers) {
      this.didUpdateMarkers(prevProps.markers, this.props.markers);
    }

    // route markers
    if (this.props.routeMarkers !== prevProps.routeMarkers) {
      this.didUpdateRouteMarkers();
    }

    // customer location
    if (this.props.customerLocation !== prevProps.customerLocation) {
      this.didUpdatecustomerLocation();
    }

    // driver location
    if (this.props.driverLocation !== prevProps.driverLocation) {
      this.didUpdateDriverLocation();
    }

    // top padding
    if (this.props.topPadding !== prevProps.topPadding) {
      this.didUpdateTopPadding();
    }

    // track my location
    if (this.state.isTrackingLocation !== prevState.isTrackingLocation) {
      this.didUpdateIsTrackingLocation();
    }

    // track my location
    if (this.props.trackMyLocation !== prevProps.trackMyLocation) {
      this.didUpdateTrackMyLocation();
    }
  }

  didUpdatePosition() {
    if (this.props.trackMyLocation && this.state.isTrackingLocation) {
      this.centerOnMyLocation();
    }
  }

  didUpdateMarkers(oldMarkers, newMarkers) {
    dmflog(this, 'didUpdateMarkers', this.props.markers);

    let markersToDraw = [];
    let markersToDelete = [];

    // any markers?
    let i = 0;
    forin(newMarkers, () => i++);
    if (i === 0) {
      dmflog(this, 'No markers');
      this.map.panTo(ScoopMap.charlotte);
      return;
    }

    forin(newMarkers, newMarker => {
      if (!oldMarkers || newMarker !== oldMarkers[newMarker.id]) {
        // the marker has changed or been newly created
        markersToDraw.push(newMarker);
      }
    });

    // loop through old markers
    forin(oldMarkers, oldMarker => {
      if (!newMarkers[oldMarker.id]) {
        // the old marker's isn't present in the new markers
        markersToDelete.push(oldMarker);
      }
    });

    // remove old markers
    markersToDelete.forEach(markerToDelete => {
      this.removeScoopMarker(markerToDelete, 'general');
    });

    // draw new markers
    // new bounds
    this.bounds = new window.google.maps.LatLngBounds();

    // new markers
    forin(markersToDraw, marker => {
      this.drawMarker(marker, 'general');
      this.bounds.extend(marker.coords);
    });

    // pan to bounds
    this.map.fitBounds(this.bounds);
  }

  removeScoopMarker(scoopMarker, markerType = 'general') {
    this.removeMarker(this.markers[markerType][scoopMarker.id]);
    this.removeInfoWindow(this.infoWindows[markerType][scoopMarker.id]);
  }

  removeMarker(googleMarker) {
    googleMarker.setMap(null);
    googleMarker = null;
  }

  removeInfoWindow(infoWindow) {
    if (infoWindow) {
      infoWindow.setMap(null);
      infoWindow = null;
    }
  }

  drawMarker(scoopMarker, markerType = 'general') {
    // delete marker before re-drawing
    if (this.markers[markerType][scoopMarker.id]) {
      this.removeScoopMarker(scoopMarker, markerType);
    }

    let options = {
      position: scoopMarker.coords,
      map: this.map,
    };
    if (scoopMarker.iconSrc) options.icon = scoopMarker.iconSrc;
    let googleMarker = new window.google.maps.Marker(options);
    // add to array
    this.markers[markerType][scoopMarker.id] = googleMarker;
    // }

    // info window
    // if (!this.infoWindows[scoopMarker.id]) {
    //   // only create once
    // content
    let infoWindow = scoopMarker.infoWindow;
    let googleInfoWindow;
    if (infoWindow) {
      let content = '';
      if (infoWindow.title) content += `<h1 class="mb-1 lead">${infoWindow.title}</h1>`;
      if (infoWindow.subtitle) content += `<h4 class="mb-1">${infoWindow.subtitle}</h4>`;
      if (infoWindow.details) content += `<p class="mb-1">${infoWindow.details}</p>`;
      googleInfoWindow = new window.google.maps.InfoWindow({content});
      if (infoWindow.openByDefault) {
        googleInfoWindow.open(this.map, this.markers[markerType][scoopMarker.id]);
      }
      // add to array
      this.infoWindows[markerType][scoopMarker.id] = googleInfoWindow;
    }

    // click listener
    this.markers[markerType][scoopMarker.id].addListener(`click`, () => {
      // open info window
      if (infoWindow) {
        googleInfoWindow.open(this.map, this.markers[markerType][scoopMarker.id]);
      }

      // on click
      this.onClickMarker(scoopMarker);
    });
  }

  didUpdatecustomerLocation() {
    let customerLocationMarker = new ScoopMarker({
      id: 'customerLocation',
      coords: this.props.customerLocation,
      iconSrc: myLocationImage,
      infoWindow: {
        title: 'My location',
        openByDefault: false,
      },
    });
    this.drawMarker(customerLocationMarker);
  }

  didUpdateDriverLocation() {
    // check for no driver location
    if (!this.props.driverLocation) {
      if (this.driverOverlay) {
        this.driverOverlay.setMap(null);
        this.driverOverlay = null;
      }
      return;
    }

    // check if no overlay
    if (!this.driverOverlay) {
      this.driverOverlay = getDriverOverlay(this.map, this.props.driverLocation, this.props.driverHeading);
    } else {
      // update overlay
      this.driverOverlay.update(this.props.driverLocation, this.props.driverHeading);
    }

    // let customerLocationMarker = new ScoopMarker({
    //   id: "driverLocation",
    //   coords: this.props.driverLocation,
    //   iconSrc: driverLocationImage,
    //   infoWindow: {
    //     title: "Driver location",
    //     openByDefault: false,
    //   },
    // });
    // this.drawMarker(customerLocationMarker);
  }

  didUpdateRouteMarkers() {
    // check for no route markers
    if (this.props.routeMarkers.length < 2) {
      dmflog(this, `Not drawing route. Need at least 2 markers. Deleting any existing route.`, this.props.routeMarkers);

      // remove markers
      forin(this.markers.route, marker => {
        this.removeMarker(marker);
      });

      // remove info windows
      forin(this.infoWindows.route, infoWindow => {
        this.removeInfoWindow(infoWindow);
      });

      // remove directions
      this.directionsRenderer.setDirections({routes: []});
      return;
    }

    // draw markers
    // new bounds
    this.bounds = new window.google.maps.LatLngBounds();

    // draw
    this.props.routeMarkers.forEach(marker => {
      this.drawMarker(marker, 'route');
      this.bounds.extend(marker.coords);
    });

    // request
    let originMarker = this.props.routeMarkers[0];
    let destinationMarker = this.props.routeMarkers[this.props.routeMarkers.length - 1];
    let origin = originMarker.coords;
    let destination = destinationMarker.coords;
    let directionsRequest = {travelMode: `DRIVING`, origin, destination};

    // waypoints
    if (this.props.routeMarkers.length > 2) {
      let waypointMarkers = this.props.routeMarkers.slice(1, this.props.routeMarkers.length - 1);
      let waypoints = [];
      loop(waypointMarkers, marker => {
        waypoints.push(marker.coords);
      });
      directionsRequest.waypoints = waypoints;
    }

    // get route
    this.directionsService.route(directionsRequest, (result, status) => {
      if (status === `OK`) {
        // display route
        this.directionsRenderer.setDirections(result);

        // send miles to parent
        if (this.props.onChangeDistance) {
          let meters = result.routes[0].legs[0].distance.value;
          let miles = meters * Constants.metersPerMile;
          this.props.onChangeDistance(miles);
        }

        // will adjust zoom after map fires "zoom changed" event
        this.setState({shouldPadZoom: true});

        // pan to bounds
        this.map.fitBounds(this.bounds);
      }
    });
  }

  onClickMarker(scoopMarker) {
    if (this.props.onClickMarker) {
      this.props.onClickMarker(scoopMarker);
    }
  }

  didUpdateTopPadding() {
    if (this.props.trackMyLocation && this.state.isTrackingLocation) {
      if (this.bounds) {
        this.map.setZoom(30); // so that it can zoom out / fit bounds again
        this.extendBoundsForTopPadding();
      }
    }
  }

  didUpdateTrackMyLocation() {
    this.centerOnMyLocation();
  }

  didUpdateIsTrackingLocation() {
    this.centerOnMyLocation();
  }

  centerOnMyLocation() {
    if (!this.props.trackMyLocation || !this.state.isTrackingLocation) return;

    let center = this.props.position
      ? new window.google.maps.LatLng(this.props.position.coords.latitude, this.props.position.coords.longitude)
      : ScoopMap.charlotte;
    let zoom = this.props.zoom || ScoopMap.defaultZoom;

    this.map.setCenter(center);
    this.map.setZoom(zoom);
  }

  onClickReCenter(e) {
    this.setState({isTrackingLocation: true});
  }

  render() {
    return (
      <div className={this.props.containerClassName} style={this.props.containerStyle}>
        {/* map */}
        <div id="scoopmap" className={`google-map-2 h-100 w-100 ` + (this.props.className || '')} />

        {/* re-center button */}
        {!this.state.isTrackingLocation && (
          <div className="track-my-location">
            <button onClick={this.onClickReCenter.bind(this)} className="btn bg-white text-black">
              <i className="fa fa-dot-circle" /> Re-center
            </button>
          </div>
        )}
      </div>
    );
  }
}
