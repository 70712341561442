import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {FixedSizeList} from 'react-window';
import unwrap from '../../functions/unwrap';
import {useLocalStorageArray} from '../../hooks/useLocalStorage';
import ColumnMetadata from './ColumnMetadata';
import DataRow from './DataRow';
import HeadComponent from './HeadComponent';
import RowComponent from './RowComponent';
import SelectionAction from './SelectionAction';
import SortingDirection from './SortingDirection';

export default function Table({
  columns,
  rows,
  sortingDirection,
  onChangeSortingDirection,
  sortingColumn,
  onChangeSortingColumn,
  className,
  height,
  onChangeSelection,
  allowSelection = false,
  selectionActions,
}: {
  columns: ColumnMetadata[];
  rows: DataRow[] | undefined;
  sortingDirection?: SortingDirection;
  onChangeSortingDirection?: (newDirection: SortingDirection | undefined) => void;
  sortingColumn?: string;
  onChangeSortingColumn?: (newSortingColumn: string | undefined) => void;
  className?: string;
  height?: number;
  onChangeSelection?: (selection: string[]) => void;
  allowSelection?: boolean;
  selectionActions?: SelectionAction[];
}) {
  const tableDivRef = useRef();

  const [tableHeight, setTableHeight] = useState(500); // fallback height of 500

  const columnsWidth = useMemo(() => columns.reduce((total, column) => total + column.width, 0), [columns]);

  const [selectedRowIds, setSelectedRowIds] = useLocalStorageArray('JOBS_TABLE_SELECTED_ROW_IDS');

  const selectedRows = useMemo(
    () => unwrap(rows, rows => selectedRowIds.map(id => rows.find(row => row.id === id)).filter(Boolean), []),
    [selectedRowIds, rows],
  );

  const rowsWithSelectionData = useMemo(() => {
    if (rows && allowSelection) {
      return rows.map(row => {
        const _allowSelection = allowSelection;
        const _isSelected = selectedRowIds.includes(row.id);
        const _onChangeSelection = (isSelected: boolean) => {
          if (isSelected) {
            setSelectedRowIds([...selectedRowIds, row.id]);
          } else {
            const newIds = [...selectedRowIds];
            const index = findIndex(newIds, id => id === row.id);
            newIds.splice(index, 1);
            setSelectedRowIds(newIds);
          }
        };
        return Object.assign(row, {
          _allowSelection,
          _isSelected,
          _onChangeSelection,
        });
      });
    } else {
      return rows;
    }
  }, [rows, allowSelection, selectedRowIds, setSelectedRowIds]);

  // EFFECT: cancel selection when rows change

  useEffect(() => {
    if (rows && selectedRowIds.length) {
      const remainingSelectedIds = selectedRowIds.filter(selectedId => find(rows, row => row.id === selectedId));
      if (remainingSelectedIds.length !== selectedRowIds.length) {
        setSelectedRowIds(remainingSelectedIds);
      }
    }
  }, [rows, selectedRowIds, setSelectedRowIds]);

  // EFFECT: call on change handler when selection changes

  useEffect(() => {
    if (onChangeSelection) {
      onChangeSelection(selectedRowIds);
    }
  }, [selectedRowIds, onChangeSelection]);

  // EFFECT: observe height of table div

  useEffect(() => {
    if (height) return;
    const div = tableDivRef.current;
    const observer = new window.ResizeObserver(() => {
      const h = (div as any).offsetHeight - 42;
      setTableHeight(h);
    });
    observer.observe(div as any);
    return () => {
      observer.unobserve(div as any);
    };
  }, [height]);

  // method: on click column heading

  const onClickColumnHeading = unwrap(onChangeSortingColumn, onChangeSortingColumn =>
    unwrap(onChangeSortingDirection, onChangeSortingDirection => (column: ColumnMetadata) => (event: any) => {
      if (sortingColumn !== column.path) {
        onChangeSortingColumn(column.path);
        onChangeSortingDirection(SortingDirection.ascending);
      } else if (sortingDirection === SortingDirection.ascending) {
        onChangeSortingDirection(SortingDirection.descending);
      } else {
        onChangeSortingColumn(undefined);
        onChangeSortingDirection(undefined);
      }
    }),
  );

  // method: set selected rows

  function setSelectedRows(rows: DataRow[]) {
    const ids = rows.map(row => row.id);
    setSelectedRowIds(ids);
  }

  // render

  return (
    <div
      ref={tableDivRef as any}
      className={
        'd-flex flex-column bg-white overflow-scroll border-left border-bottom border-right ' + (className || '')
      }>
      <div className="d-flex border-bottom" style={{width: columnsWidth}}>
        <HeadComponent
          columns={columns}
          sortingColumn={sortingColumn}
          onClickColumnHeading={onClickColumnHeading}
          sortingDirection={sortingDirection}
          allowSelection={allowSelection}
          selection={selectedRows}
          setSelection={setSelectedRows}
          selectionActions={selectionActions}
          rows={rowsWithSelectionData}
        />
      </div>
      <div className="flex-grow-1" style={{width: columnsWidth}}>
        {!rowsWithSelectionData ? null : rowsWithSelectionData.length === 0 ? (
          <div className="p-2 border-bottom border-right">Nothing to show</div>
        ) : (
          <FixedSizeList
            width={columnsWidth}
            height={height || tableHeight}
            itemCount={rowsWithSelectionData.length}
            itemKey={(index, data) => data[index].id}
            itemSize={32}
            itemData={rowsWithSelectionData}>
            {RowComponent}
          </FixedSizeList>
        )}
      </div>
    </div>
  );
}
