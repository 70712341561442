import firebase from 'firebase/app';
import {useEffect, useState} from 'react';
import {useAuthContext} from '../contexts/AuthContext';
import UserType from '../models/scoopm/UserType';

interface State {
  user?: firebase.User | null;
  type?: UserType | null;
  data?: object | null;
  update?: ((values: Object) => Promise<any>) | null;
}

export default function useUserData() {
  const [state, setState] = useState<State>({
    user: undefined,
    type: undefined,
    data: undefined,
    update: undefined,
  });

  const {user} = useAuthContext();

  useEffect(() => {
    const uid = user && user.uid;
    if (uid) {
      firebase
        .database()
        .ref(`/admins/${uid}`)
        .once('value')
        .then(snapshot => {
          const isAdmin = snapshot.val() === true;
          const driverReference = firebase.database().ref(`/drivers/${uid}`);
          return driverReference.once('value').then(snapshot => {
            if (snapshot.exists()) {
              setState({
                user,
                type: isAdmin ? UserType.admin : UserType.driver,
                data: snapshot.val(),
                update: values => driverReference.update(values),
              });
            } else {
              const customerReference = firebase.database().ref(`/customers/${uid}`);
              return customerReference.once('value').then(snapshot => {
                if (snapshot.exists()) {
                  setState({
                    user,
                    type: isAdmin ? UserType.admin : UserType.customer,
                    data: snapshot.val(),
                    update: values => customerReference.update(values),
                  });
                } else {
                  setState({
                    user,
                    type: isAdmin ? UserType.admin : null,
                    data: null,
                    update: null,
                  });
                }
              });
            }
          });
        })
        .catch(error => {
          console.error('Error determining user type', error);
          setState({
            user,
            type: null,
            data: null,
            update: null,
          });
        });
    } else {
      setState({
        user,
        type: null,
        data: null,
        update: null,
      });
    }
  }, [user]);

  return state;
}
