// error
export function dmfError(...somethings) {
  // if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  console.error(`Error`, ...somethings);
  // }
}

// log
export function dmflog(obj, ...somethings) {
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    if (obj) {
      console.log(`${obj.constructor.name}:`, ...somethings);
    } else {
      console.log(...somethings);
    }
  }
}
