import React from 'react';
import Anchor from '../../components/Anchor';
import first from '../../images/home/first.png';
import second from '../../images/home/second.png';

export default function AboutApp() {
  return (
    <>
      <Anchor id="about" />

      <div className="container pt-4">
        <div className="row py-5">
          <div className="col-lg mb-4 mb-lg-0">
            <h5 className="mb-3">
              <i className="fas fa-user-shield mr-2 text-primary" />
              Quality and safety
            </h5>
            <ul className="pl-3 mb-0 text-muted">
              <li>No creepy drivers!</li>
              <li>Thorough background checks</li>
              <li>Driver interviews</li>
              <li>DashCam stream (rideshare)</li>
              <li>Emergency panic button</li>
              <li>20+ safety features in-app</li>
            </ul>
          </div>
          <div className="col-lg mb-4 mb-lg-0">
            <h5 className="mb-3">
              <i className="fas fa-dollar-sign mr-2 text-primary" />
              Higher driver pay - better benefits!
            </h5>
            <ul className="pl-3 text-muted">
              <li>Drivers make 10-15% more than the competition – All the Time</li>
              <li>
                Future offerings
                <ul style={{marginLeft: '-1.25rem'}}>
                  <li>BETTER insurance options for drivers</li>
                  <li>Auto Repair/Maintenance Plans</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-lg mb-4 mb-lg-0">
            <h5 className="mb-3">
              <i className="fas fa-hand-holding-heart mr-2 text-primary" />
              Human touch
            </h5>
            <ul className="pl-3 text-muted">
              <li>Give us a call any time!</li>
              <li>High quality client, restaurant, and intermediary satisfaction scores</li>
              <li>Help us make your ride awesome every time!</li>
              <li>Team based food delivery</li>
              <li>Food delivery like we made it ourselves</li>
              <li>
                Assigned Chief Driver Officers
                <ul style={{marginLeft: '-1.25rem'}}>
                  <li>Enhanced client and restaurant communications</li>
                  <li>Issues resolution</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-light">
        <div className="container py-4">
          <div className="row py-5">
            <div className="col-lg text-center">
              <img src={first} alt="Screenshots" style={{width: '100%', maxWidth: 505, maxHeight: 538}} />
            </div>
            <div className="col-lg">
              <h4 className="text-primary mb-1">Safer rideshare and high-quality food delivery</h4>
              <h1 className="display-4">
                Safer rides,
                <br />
                better experiences
              </h1>
              <p className="text-muted">
                Wave is a rideshare and food delivery that puts quality and safety first. We value both the rider and
                the driver. We employ superior technology and process to ensure the safest, most convenient rideshare
                experience possible.
              </p>
              <p className="text-muted">
                As a rider, you can rest assured that your driver has been thoroughly screened and verified. Every
                driver is required to do a thorough background screen.
              </p>
              <p className="text-muted">
                As a driver, you can expect to be paid more, treated more equitably, and covered more comprehensively.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="row py-3">
          <div className="col-lg d-flex flex-column justify-content-center">
            <h1 className="display-4">How is Wave Rideshare different?</h1>
            <p className="text-muted">
              Existing rideshare companies have very little concern for the driver or the rider. They are only concerned
              with making money off of the transaction and cutting upside for drivers. We are here to change that
              concept and deliver a best-of-service experience in rideshare. Our goals are to:
            </p>
            <ul>
              <li>Take great care of our drivers and partners. No games</li>
              <li>Eliminate all forms of crime, harassment, and inappropriate behavior during and after the ride</li>
              <li>Increase accountability at all phases of the trip (check out our video below)</li>
              <li>Adequately cover all parties in the event of an accident</li>
              <li>Deliver a top-notch rideshare experience, end to end!</li>
              <li>
                Client centric food delivery with enhanced restaurant communication, catering delivery is our specialty
              </li>
              <li>Guarantee a safe experience, especially for minors and women</li>
            </ul>
          </div>
          <div className="col-lg-auto text-center">
            <img src={second} alt="Screenshot" width={310} height={672} className="screenshot" />
          </div>
        </div>
      </div>
    </>
  );
}
