import firebase from 'firebase/app';
import React, {useMemo} from 'react';
import {unwrap, useStatus} from 'wave-common';
import FirebaseImageStore from '../services/FirebaseImageStore';
import SquareImage from './SquareImage';

export default function FirebaseStorageSquareImage({
  path,
  thumbnailPath,
  fallbackUrl,
  isImage,
  disableClick,
  roundedXl,
}: {
  path?: string | null;
  thumbnailPath?: string | null;
  fallbackUrl?: string | null;
  isImage?: boolean | null;
  disableClick?: boolean;
  roundedXl?: boolean;
}) {
  const {
    isPending: urlIsPending,
    error: urlError,
    value: url,
  } = useStatus<string>(
    React as never,
    useMemo(() => unwrap(path, path => FirebaseImageStore.instance.fetchUrl(path)), [path]),
  );

  const {
    isPending: thumbnailUrlIsPending,
    error: thumbnailUrlError,
    value: thumbnailUrl,
  } = useStatus<string>(
    React as never,
    useMemo(
      () => unwrap(thumbnailPath, thumbnailPath => FirebaseImageStore.instance.fetchUrl(thumbnailPath)),
      [thumbnailPath],
    ),
  );

  const {
    isPending: metadataIsPending,
    error: metadataErro,
    value: isImageInternal,
  } = useStatus<boolean>(
    React as never,
    useMemo(
      () =>
        unwrap(path, path =>
          firebase
            .storage()
            .ref()
            .child(path)
            .getMetadata()
            .then(metadata => metadata && /image\/.+/.test(metadata.contentType)),
        ),

      [path],
    ),
  );

  return (
    <SquareImage
      url={thumbnailUrl ?? url ?? (urlError ? fallbackUrl : undefined)}
      fullsizeUrl={url ?? undefined}
      isImage={isImage || isImageInternal}
      disableClick={disableClick}
      roundedXl={roundedXl}
    />
  );
}
