import React from 'react';
import ContainerLayout from '../../layouts/ContainerLayout';

export default function OfficialRidesharePartner() {
  return (
    <ContainerLayout>
      <h4 className="text-center mt-3 mb-4">Official Rideshare Partner</h4>

      <div className="row">
        <div className="col-sm-4 d-flex justify-content-center align-items-center offset-sm-2">
          {/* 1070x581 */}
          <img
            src="/images/panthers.png"
            alt=""
            style={{objectFit: 'contain'}}
            width="268"
            height="145"
            className="mb-2"
          />
        </div>

        <div className="col-sm-4 d-flex justify-content-center align-items-center">
          {/* 875x840 */}
          <img src="/images/hornets.png" alt="" width="175" height="168" className="mb-2" />
        </div>
      </div>

      {/* <div className="d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center mr-5 ml-n2">
         
          <h6>Carolina Panthers</h6>
        </div>
        <div className="d-flex flex-column align-items-center">
          
          {/* <h6>Charlotte Hornets</h6> */}
      {/* </div> */}
      {/* </div>  */}
    </ContainerLayout>
  );
}
