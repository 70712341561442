import React, {forwardRef, HTMLProps, KeyboardEvent} from 'react';
import joinClassNames from '../functions/joinClassNames';

const Input = forwardRef<
  HTMLInputElement,
  Omit<HTMLProps<HTMLInputElement>, 'onKeyPress' | 'autoComplete' | 'autoCorrect' | 'autoCapitalize' | 'spellCheck'>
>(({className, ...props}, ref) => (
  <input
    ref={ref}
    onKeyPress={preventReturn}
    autoComplete="off"
    autoCorrect="off"
    autoCapitalize="off"
    spellCheck="false"
    className={joinClassNames('ellipsis', className)}
    {...props}
  />
));

export default Input;

function preventReturn(event: KeyboardEvent<HTMLInputElement>) {
  if (event.which === 13) {
    event.preventDefault();
    event.stopPropagation();
  }
}
