import HttpReference from '../../platform/HttpReference';
import firebase from 'firebase/app';
import RealtimeDatabase from '../../database/RealtimeDatabase';

export default class AccountLinks extends HttpReference {
  async create(account) {
    const user = firebase.auth().currentUser;
    if (!user) throw new Error('Unauthenticated');

    return RealtimeDatabase.instance.drivers
      .child(user.uid)
      .once()
      .then(([driver]) => {
        if (!driver.stripeAccountId) {
          const error = new Error('That is not a driver account');
          error.notADriver = true;
          throw error;
        }

        return user.getIdToken().then(token =>
          this.request({
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              account: driver.stripeAccountId,
            },
          }),
        );
      });
  }
}
