import firebase, {firestore} from 'firebase/app';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import isNumber from 'lodash/isNumber';
import last from 'lodash/last';
import round from 'lodash/round';
import sample from 'lodash/sample';
import moment from 'moment';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDeepComparisonMemo} from 'wave-common';
import Constants from '../../Constants';
import {useAuthContext} from '../../contexts/AuthContext';
import formatCurrency from '../../functions/formatCurrency';
import {formatPhoneE164} from '../../functions/formatPhone';
import joinClassNames from '../../functions/joinClassNames';
import unwrap from '../../functions/unwrap';
import ClaimsGuard from '../../guards/ClaimsGuard';
import useUserData from '../../hooks/useUserData';
import useUserGroupVendor from '../../hooks/useUserGroupVendor';
import Color from '../../models/scoopm/Color';
import CustomClaim from '../../models/scoopm/CustomClaim';
import Estimate from '../../models/scoopm/Estimate';
import PositionInList from '../../models/scoopm/PositionInList';
import Trip from '../../models/scoopm/Trip';
import TripContact from '../../models/scoopm/TripContact';
import TripContent from '../../models/scoopm/TripContent';
import TripCustomer from '../../models/scoopm/TripCustomer';
import TripDriver from '../../models/scoopm/TripDriver';
import TripLocation from '../../models/scoopm/TripLocation';
import {TripSource} from '../../models/scoopm/TripSource';
import TripStatus from '../../models/scoopm/TripStatus';
import TripType from '../../models/scoopm/TripType';
import Vendor from '../../models/scoopm/Vendor';
import RealtimeDatabase from '../../references/database/RealtimeDatabase';
import ScoopMApi from '../../references/scoopm-api';
import DirectionsService from '../../services/Directions';
import ValidateableInput from '../bootstrap/ValidateableInput';
import CustomerSelect from '../CustomerSelect';
import FirestoreSelect from '../FirestoreSelect';
import Spinner from '../Spinner';
import DropZone from './DropZone';
import Location from './Location';
import updateContactProperties from './updateContactProperties';

const phoneNumberUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export default function TripForm({
  defaultValue = null,
  submitButton = 'Submit',
  isAdmin,
  source = TripSource.adminPortal,
  onSubmit,
  isPending,
  isUpdate,
}: {
  defaultValue: Trip | null;
  submitButton: string;
  isAdmin?: boolean;
  source?: TripSource;
  onSubmit: (trip: Trip) => void;
  isPending?: boolean;
  isUpdate?: boolean;
}) {
  const {uid, claims} = useAuthContext();
  const {data: userData} = useUserData();

  const [trip, setTrip] = useState<Partial<Trip>>(defaultValue ? new Trip(defaultValue) : Trip.forManualEntry(source));

  // if default value changes (e.g. trip template usage) then set the trip

  useDeepComparisonMemo(
    React as never,
    () => setTrip(defaultValue ? new Trip(defaultValue) : Trip.forManualEntry(source)),
    [defaultValue, source],
  );

  const [validation, setValidation] = useState<Record<string, any>>();

  const [customerError, setCustomerError] = useState<Error | null>();
  const [estimateError, setEstimateError] = useState<Error | null>(null);

  const [isGettingDirections, setIsGettingDirections] = useState(false);

  const userGroupVendor = useUserGroupVendor();

  // EFFECT: add vendor id, if relevant

  useEffect(() => {
    if (userGroupVendor.snapshot && !isUpdate && !trip.vendorId) {
      setTrip(oldTrip => {
        const newTrip = new Trip(oldTrip);
        newTrip.vendorId = userGroupVendor.snapshot.id;
        newTrip.vendorName = userGroupVendor.snapshot.get('name');
        return newTrip;
      });
    }
  }, [userGroupVendor.snapshot, isUpdate, trip.vendorId]);

  // EFFECT: if not admin, set customer/customerId manually
  // they must have first and last name, and phone. This is required by the users page, when you try to enable the `manualEntry` custom claim

  useEffect(() => {
    if (isAdmin) {
      setCustomerError(null);
    } else if (!isUpdate) {
      console.info('Fetching customer details...');
      const {uid} = firebase.auth().currentUser!;
      RealtimeDatabase.instance
        .child('users')
        .child(uid)
        .once()
        .then(
          ([user]: any) => {
            console.info('Fetched customer details', uid, user);
            setTrip(oldTrip => {
              const newTrip = new Trip(oldTrip);
              newTrip.customerId = uid;
              newTrip.customer = TripCustomer.fromCustomer(user);
              return newTrip;
            });
            setCustomerError(null);
          },
          (error: Error) => {
            console.error(error);
            setCustomerError(error);
          },
        );
    }
  }, [isUpdate, isAdmin]);

  // EFFECT: if delivery, set customer/customerId automatically

  useEffect(() => {
    if (!isUpdate && isAdmin && trip.type === TripType.delivery.rawValue) {
      console.log('Setting customer to "ADMIN PORTAL"');
      setTrip(oldTrip => {
        const newTrip = new Trip(oldTrip);
        newTrip.customerId = process.env.REACT_APP_ADMIN_PORTAL_CUSTOMER_ID;
        newTrip.customer = TripCustomer.adminPortal();
        return newTrip;
      });
    }
  }, [isUpdate, isAdmin, trip.type]);

  // EFFECT: trip type changes --> set/unset trip colors

  useEffect(() => {
    setTrip(oldTrip => {
      const newTrip = new Trip(oldTrip);
      // console.log(newTrip)
      if ((TripType.from(newTrip.type) as TripType).hasTripColors()) {
        newTrip.firstColor = sample(Color.allCases);
        newTrip.secondColor = sample(Color.allCases.filter(color => color !== newTrip.firstColor));
      } else {
        newTrip.firstColor = null;
        newTrip.secondColor = null;
      }
      return newTrip;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip.type]);

  // EFFECT: trip customer changes --> change single-location trip contact

  useEffect(() => {
    if (trip.customer && trip.locations.length === 1) {
      setTrip(oldTrip => {
        const newTrip = new Trip(oldTrip);
        newTrip.contacts[0] = TripContact.fromTripCustomer(trip.customer, trip.customerId);
        return newTrip;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip.customer, trip.locations]);

  // EFFECT: trip locations change, set things like "fromAddress"

  useEffect(() => {
    setTrip(oldTrip => {
      const newTrip = new Trip(oldTrip);

      const firstLocation: TripLocation | undefined = first(newTrip.locations);
      newTrip.fromAddress = firstLocation?.address;
      newTrip.fromCoordinate = firstLocation?.coordinates;
      newTrip.fromPlaceName = firstLocation?.name;
      newTrip.fromPlaceId = firstLocation?.googlePlaceId;
      newTrip.scheduleTimestamp = firstLocation?.scheduledTimestampMs || null;

      const lastLocation: TripLocation | undefined = last(newTrip.locations);
      if (lastLocation) {
        newTrip.toAddress = lastLocation?.address;
        newTrip.toCoordinate = lastLocation?.coordinates;
        newTrip.toPlaceName = lastLocation?.name;
        newTrip.toPlaceId = lastLocation?.googlePlaceId;
        newTrip.dropOffTimestampMs = lastLocation?.scheduledTimestampMs || null;
      }

      newTrip.state = firstLocation?.state || unwrap(lastLocation, l => l.state);

      return newTrip;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(trip.locations)]);

  // EFFECT: calculate miles and minutes

  useEffect(() => {
    if (!trip.locations || trip.locations.filter((l: TripLocation) => l.coordinates).length <= 1) {
      setTrip(oldTrip =>
        Object.assign(new Trip(oldTrip), {
          estimatedMiles: null,
          estimatedMinutes: null,
        }),
      );
    } else {
      setIsGettingDirections(true);
      DirectionsService.getDirectionsForTrip('N/A', trip)
        .then(
          ([id, directions]) => {
            const miles = directions.routes[0].legs.reduce(
              (total: any, leg: any) => total + leg.distance.value * Constants.metersPerMile,
              0,
            );
            const minutes = directions.routes[0].legs.reduce(
              (total: any, leg: any) => total + leg.duration.value / 60,
              0,
            );
            setTrip(oldTrip =>
              Object.assign(new Trip(oldTrip), {
                estimatedMiles: miles,
                estimatedMinutes: minutes,
              }),
            );
          },
          error => {
            setTrip(oldTrip =>
              Object.assign(new Trip(oldTrip), {
                estimatedMiles: null,
                estimatedMinutes: null,
              }),
            );
          },
        )
        .finally(() => {
          setIsGettingDirections(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(trip.locations)]);

  // EFFECT: calculate estimate

  useEffect(() => {
    if (!isUpdate) {
      if (trip.estimatedMiles && trip.estimatedMinutes) {
        ScoopMApi.instance.pricing.estimate.create(trip as Trip).then(
          estimate => {
            setTrip(oldTrip => Object.assign(new Trip(oldTrip), {estimate: estimate.data.valueD}));
            setEstimateError(null);
          },
          error => {
            setTrip(oldTrip => Object.assign(new Trip(oldTrip), {estimate: null}));
            setEstimateError(error);
          },
        );
      } else {
        setTrip(oldTrip => Object.assign(new Trip(oldTrip), {estimate: null}));
        setEstimateError(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, trip.estimatedMiles, trip.estimatedMinutes, trip.orderValue, trip.type]);

  // METHODS

  function onClickAddLocation(event: any) {
    event.preventDefault();

    setTrip(oldTrip => {
      const newTrip = new Trip(oldTrip);

      // add or insert new location

      const newLocation = new TripLocation({} as never);
      if (newTrip.locations.length === 1) {
        newTrip.locations.push(newLocation);
      } else {
        newTrip.locations.splice(newTrip.locations.length - 1, 0, newLocation);
      }

      return updateContactProperties(newTrip);
    });
  }

  function onChangeLocation(i: number) {
    return function (location: TripLocation) {
      setTrip(oldTrip => {
        const newTrip = new Trip(oldTrip);
        newTrip.locations[i] = location;
        newTrip.state = (first(newTrip.locations) as TripLocation).state;
        return updateContactProperties(newTrip);
      });
    };
  }

  function deleteLocation(i: number) {
    return function () {
      setTrip(oldTrip => {
        const newTrip = new Trip(oldTrip);
        newTrip.locations.splice(i, 1);
        return updateContactProperties(newTrip);
      });
    };
  }

  function onChangeContact(locationId: number) {
    return function (contact: TripContact) {
      setTrip(oldTrip => {
        const newTrip = new Trip(oldTrip);
        const index = findIndex(
          newTrip.contacts,
          (contact: TripContact) => contact.userType === newTrip.locations[locationId].contactUserType,
        );
        if (index > -1) {
          newTrip.contacts[index] = contact;
        }
        return newTrip;
      });
    };
  }

  const onSubmitForm = useCallback(
    event => {
      event.preventDefault();

      // validate data

      console.log('Validating trip...');

      const newValidation = Object.assign({}, validation || {locations: [], contacts: []});

      // validate top-level properties

      newValidation.customerError = Boolean(customerError);
      newValidation.type = true;
      newValidation.customer = Boolean(trip.customerId);
      newValidation.orderValue = !trip.orderValue || (isFinite(Number(trip.orderValue)) && Number(trip.orderValue) > 0);
      newValidation.tip = !trip.tip || (!isNaN(trip.tip) && trip.tip > 0);
      newValidation.orderId = true;
      newValidation.vendorId = true;

      // validate locations

      trip.locations.forEach((location: TripLocation, i: number) => {
        newValidation.locations[i] = {
          coordinates: Boolean(location.coordinates), // coordinates required
          instructions: true, // optional
          completionTimestampMs: true, // optional
          completedAt: true, // optional
          contents:
            !location.contents ||
            location.contents.filter(
              (
                content: TripContent, // either no contents, or all contents are valid
              ) =>
                !content.title ||
                !(typeof content.title === 'string') ||
                !isNumber(content.quantity) ||
                !(content.quantity > 0), // truthy string title + contents must be non-negative number
            ).length === 0,
        };
      });

      // validate contacts

      const isValidPhone = (string: string) => {
        try {
          const phoneNumber = phoneNumberUtil.parse(string, 'US');
          return phoneNumberUtil.isValidNumber(phoneNumber);
        } catch (error) {
          console.error(error);
          return false;
        }
      };

      // if editing existing trip without contacts, add them

      if (!trip.contacts) trip.contacts = trip.contactsWithDefault!();

      trip.contacts.forEach((contact: TripContact, i: number) => {
        newValidation.contacts[i] = {
          name: Boolean(contact.name),
          phone: contact.phone ? isValidPhone(contact.phone) : false,
        };
      });

      newValidation.didPass =
        !newValidation.customerError &&
        newValidation.type &&
        newValidation.customer &&
        newValidation.orderValue &&
        newValidation.orderId &&
        newValidation.vendorId &&
        !find(newValidation.locations, location => !location.coordinates || !location.contents) &&
        !find(newValidation.contacts, contact => !contact.name || !contact.phone);

      setValidation(newValidation);

      // passed?

      if (!newValidation.didPass) {
        console.warn('Did not pass validation', newValidation, trip);
        return;
      }

      // format

      // phone numbers

      console.log('Formatting phone numbers');

      trip.contacts.forEach((contact: TripContact) => {
        contact.phone = formatPhoneE164(contact.phone);
      });

      // ez cater order id

      trip.orderId = trip.orderId || null; // e.g., ignore if empty string

      // driver / driver id

      if (claims && claims[CustomClaim.selfAssign]) {
        trip.driverId = uid;
        trip.driver = TripDriver.fromPojo(userData);
      }

      // tip

      let tipNumber: number | null = trip.tip === '' ? NaN : Number(trip.tip);
      tipNumber = isNaN(tipNumber) ? null : tipNumber;
      trip.tip = tipNumber;

      // completion timestamps

      if (!isUpdate) {
        let confirmedAt = null;
        let pickupCompletionTimestampMs = first<TripLocation>(trip.locations)?.completionTimestampMs;
        let dropOffCompletionTimestampMs = unwrap(
          last<TripLocation>(trip.locations),
          location => location.completionTimestampMs,
        );
        const hasCompletionTimestamp = Boolean(pickupCompletionTimestampMs || dropOffCompletionTimestampMs);
        if (hasCompletionTimestamp) {
          pickupCompletionTimestampMs =
            pickupCompletionTimestampMs || moment(dropOffCompletionTimestampMs).subtract(1, 'hour').valueOf();
          dropOffCompletionTimestampMs =
            dropOffCompletionTimestampMs || moment(pickupCompletionTimestampMs).add(1, 'hour').valueOf();
          confirmedAt = moment(pickupCompletionTimestampMs).subtract(1, 'hour').valueOf();
        }
        trip.locations[0].completionTimestampMs = pickupCompletionTimestampMs;
        if (trip.locations.length)
          last<TripLocation>(trip.locations)!.completionTimestampMs = dropOffCompletionTimestampMs;
        trip.confirmedAt = confirmedAt;
        trip.startedAt = pickupCompletionTimestampMs;
        trip.completedAt = dropOffCompletionTimestampMs;

        trip.status = hasCompletionTimestamp ? TripStatus.completed.rawValue : TripStatus.scheduled.rawValue; // ?
        // : find(trip.locations, location => location.scheduledTimestampMs)
        //     ? TripStatus.scheduled.rawValue
        //     : TripStatus.created.rawValue
      }

      trip.orderValue = trip.orderValue ? Number(trip.orderValue) : null;

      // callback
      console.log('onSubmit(trip)', trip);
      onSubmit && onSubmit(trip as Trip);
    },
    [claims, customerError, userData, onSubmit, uid, trip, validation, isUpdate],
  );

  const addCompletionTimestamp = useCallback(add => {
    setTrip(oldTrip => {
      const newTrip = new Trip(oldTrip);
      if (add) {
        const dropOffMoment = moment().subtract(1, 'day').startOf('hour');
        first<TripLocation>(newTrip.locations)!.completionTimestampMs = dropOffMoment
          .clone()
          .subtract(1, 'hour')
          .valueOf();
        if (newTrip.locations.length) {
          last<TripLocation>(newTrip.locations)!.completionTimestampMs = dropOffMoment.valueOf();
        }
      } else {
        for (const location of newTrip.locations) {
          location.completionTimestampMs = null;
        }
      }
      return newTrip;
    });
  }, []);

  const jobHasCompletionTimestampMs = useMemo(
    () => Boolean(find(trip.locations, _ => _.completionTimestampMs)),
    [trip],
  );

  return (
    <form
      onSubmit={onSubmitForm}
      className="needs-validation"
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      noValidate>
      {customerError && <p className="text-danger">There was an error. Refresh the page or try again later</p>}

      {/* PDF drop zone */}

      <DropZone setTrip={setTrip} source={source} />

      {/* top-level properties */}

      <div className="row">
        {isAdmin && (
          <>
            {/* type */}

            {!isUpdate && (
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="typeSelect">Type</label>
                  <ValidateableInput
                    elementType="select"
                    id="typeSelect"
                    value={trip.type}
                    onChange={({target: {value}}: any) =>
                      setTrip(oldTrip => Object.assign(new Trip(oldTrip), {type: value}))
                    }
                    className="custom-select"
                    isValid={unwrap(validation, validation => validation.type)}
                    disabled={isPending}>
                    {TripType.allCases.map(type => (
                      <option key={type.rawValue} value={type.rawValue}>
                        {type.title}
                      </option>
                    ))}
                  </ValidateableInput>
                </div>
              </div>
            )}

            {/* customer */}

            {!isUpdate && trip.type !== TripType.delivery.rawValue && (
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="customerSearch">Rider</label>
                  <ValidateableInput
                    elementType={CustomerSelect}
                    id="customerSearch"
                    value={trip.customerId}
                    onChange={(customerId: string, customer: TripCustomer | undefined) =>
                      setTrip(oldTrip =>
                        Object.assign(new Trip(oldTrip), {
                          customer: unwrap(customer, c => TripCustomer.fromCustomer(c)),
                          customerId,
                        }),
                      )
                    }
                    className="custom-select"
                    isValid={unwrap(validation, validation => validation.customer)}
                    disabled={isPending}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {/* order value */}

        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="orderValueInput">Order value</label>
            <ValidateableInput
              id="orderValueInput"
              value={trip.orderValue || ''}
              onChange={(event: any) =>
                setTrip(
                  Object.assign(new Trip(trip), {
                    orderValue: event.target.value,
                  }),
                )
              }
              className="form-control"
              placeholder="None"
              isValid={unwrap(validation, v => v.orderValue)}
              invalidFeedback="Must be a valid price"
              autoComplete="off"
              disabled={isPending}
              optional
            />
          </div>
        </div>

        {/* tip */}

        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="tipInput">Tip</label>
            <ValidateableInput
              id="tipInput"
              value={trip.tip || ''}
              onChange={(event: any) => setTrip(Object.assign(new Trip(trip), {tip: event.target.value}))}
              className="form-control"
              placeholder="None"
              isValid={unwrap(validation, v => v.tip)}
              invalidFeedback="Must be a valid amount"
              autoComplete="off"
              disabled={isPending}
              optional
            />
          </div>
        </div>

        {/* order id */}

        {(isAdmin || (claims && claims[CustomClaim.selfAssign])) && (
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="orderIdInput">Order ID</label>
              <ValidateableInput
                id="orderIdInput"
                value={trip.orderId || ''}
                onChange={(event: any) =>
                  setTrip(
                    Object.assign(new Trip(trip), {
                      orderId: event.target.value,
                    }),
                  )
                }
                className="form-control"
                placeholder="None"
                isValid={unwrap(validation, v => v.orderId)}
                invalidFeedback="Must be at least 6 characters"
                autoComplete="off"
                disabled={isPending}
                optional
              />
            </div>
          </div>
        )}

        {isAdmin && (
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="vendorIdInput">Vendor</label>
              <ValidateableInput
                id="vendorIdInput"
                elementType={FirestoreSelect}
                collection="vendors"
                getTextForDoc={(doc: firestore.DocumentSnapshot) => doc.get('name')}
                selectedId={trip.vendorId}
                onChange={(vendorId: string, vendor: Vendor) =>
                  setTrip(
                    Object.assign(new Trip(trip), {
                      vendorId,
                      vendorName: unwrap(vendor, _ => _.name),
                    }),
                  )
                }
                className="custom-select"
                placeholder="None"
                isValid={unwrap(validation, v => v.vendorId)}
                invalidFeedback="Must be at least 6 characters"
                autoComplete="off"
                disabled={isPending}
                optional
              />
            </div>
          </div>
        )}

        {!isUpdate && (
          <div className="col-md-6">
            <div className="form-group">
              <label>Status</label>

              <div>
                <div className="btn-group">
                  <button
                    type="button"
                    onClick={event => {
                      event.preventDefault();
                      addCompletionTimestamp(false);
                    }}
                    className={joinClassNames(
                      'btn',
                      jobHasCompletionTimestampMs ? 'btn-outline-secondary' : 'btn-primary',
                    )}>
                    Scheduled
                  </button>
                  <button
                    type="button"
                    onClick={event => {
                      event.preventDefault();
                      addCompletionTimestamp(true);
                    }}
                    className={joinClassNames(
                      'btn',
                      jobHasCompletionTimestampMs ? 'btn-primary' : 'btn-outline-secondary',
                    )}>
                    Completed
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* locations */}

      {(trip.locations || []).map((location: TripLocation, i: number, array: TripLocation[]) => (
        <Location
          key={i}
          id={i}
          locationCount={array.length}
          positionInList={PositionInList.for(i, array)}
          location={location}
          onChangeLocation={onChangeLocation(i)}
          deleteLocation={deleteLocation(i)}
          contact={unwrap(trip.contacts, contacts =>
            find(contacts, contact => contact.userType === location.contactUserType),
          )}
          onChangeContact={onChangeContact(i)}
          validation={unwrap(validation, v => v.locations[i])}
          contactValidation={unwrap(validation, v =>
            unwrap(
              findIndex(trip.contacts as TripContact[], contact => contact.userType === location.contactUserType),
              index => v.contacts[index],
            ),
          )}
          allowDeletion={trip.customerId && trip.locations.length > 1}
          disabled={isPending}
        />
      ))}
      <div className="form-group">
        <button type="button" onClick={onClickAddLocation} className="btn btn-light" disabled={isPending}>
          <i className="fas fa-plus" /> Add stop
        </button>
      </div>

      {/* estimate */}

      {!isUpdate && claims && !claims[CustomClaim.selfAssign] && (
        <div className="row">
          <div className="col">
            <h4>{Estimate.name(trip.type)}</h4>
            <p>
              {unwrap(
                trip.estimate,
                formatCurrency,
                unwrap(
                  estimateError,
                  _ => 'Error creating estimate',
                  `To see ${Estimate.name(trip.type).toLocaleLowerCase()}, enter details above`,
                ),
              )}
            </p>
            <p>{unwrap(trip.estimatedMiles, miles => `${round(miles, 1)} miles`)}</p>
            <p>{unwrap(trip.estimatedMinutes, minutes => `${round(minutes, 1)} minutes`)}</p>
          </div>
        </div>
      )}

      {/* submit button  */}

      <div className="row">
        <div className="col"></div>
        <div className="col-auto">
          <div className="text-right">
            {unwrap(validation, v => !v.didPass) && (
              <span className="text-danger mr-3">Unable to {submitButton.toLowerCase()}. See errors above</span>
            )}
            <button type="submit" className="btn btn-primary" disabled={isGettingDirections || isPending}>
              {isPending ? <Spinner small /> : submitButton}
            </button>
          </div>
        </div>
      </div>

      <ClaimsGuard claim={CustomClaim.manageApi}>
        <pre>{JSON.stringify(trip, null, '  ')}</pre>
      </ClaimsGuard>
    </form>
  );
}
