import React, {useEffect, useState} from 'react';
import Delay from '../../components/Delay';
import Spinner from '../../components/Spinner';
import unwrap from '../../functions/unwrap';
import PropertyType from '../types/PropertyType';
import InvalidFeedback from './InvalidFeedback';

export default function ObjectInput({property, id, value, onChange, disabled, validationError}) {
  const [objectEntries, setObjectEntries] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    console.log('Fetching object entries...');
    PropertyType.fetchObjectEntries(property).then(
      entries => {
        console.log('Fetched object entries', entries);
        setObjectEntries(entries);
        setError(null);
      },
      error => {
        console.error('Error fetching object entries', error);
        setError(error);
      },
    );
  }, [property]);

  if (error) {
    return <div className="form-group">Error. {error.message}</div>;
  }

  if (objectEntries === undefined) {
    return (
      <div className="form-group">
        <Delay>
          <Spinner small />
        </Delay>
      </div>
    );
  }

  if (!objectEntries) {
    return <div className="form-group">Nothing to choose from</div>;
  }

  return (
    <>
      <select
        id={id}
        name={id}
        value={value || ''}
        onChange={event => onChange(event.target.value || null)}
        className={'custom-select ' + unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid'), '')}
        disabled={disabled}>
        <option value="">None</option>
        {objectEntries.map(([id, name]) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
      <InvalidFeedback error={validationError} property={property} />
    </>
  );
}
