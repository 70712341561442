import firebase from 'firebase/app';
import unwrap from '../functions/unwrap';

/**
 * Local store of URLs to prevent flickering
 *
 * this.urls[<path>] can be `undefined`, `null` if loading, an `Error` if failed, or a string URL value
 */
export default class FirebaseImageStore {
  constructor() {
    this.callbacks = {};
    this.urls = {};

    setInterval(() => {
      // every 60 seconds, check if needing a reset
      if (Object.entries(this.urls).length > 200) {
        this.reset();
      }
    }, 60000);
  }

  urlNullOrFallback(path, fallbackUrl) {
    return unwrap(
      path,
      path => unwrap(this.urls[path], url => (url instanceof Error ? fallbackUrl : url)),
      fallbackUrl,
    );
  }

  async fetchUrl(path) {
    if (this.urls[path] instanceof Error) {
      // if failed

      throw this.urls[path]; // throw error
    } else if (this.urls[path]) {
      // if value

      return this.urls[path]; // return value
    } else if (this.urls[path] === null) {
      // if loading

      // create a promise which can be resolved or rejected later

      let resolve = null;
      let reject = null;

      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });

      if (!this.callbacks[path]) this.callbacks[path] = [];
      this.callbacks[path].push({resolve, reject});

      return await promise;
    } else {
      // fetch

      try {
        this.urls[path] = null;
        const url = await firebase.storage().ref(path).getDownloadURL();
        console.log('Got URL', path, url);
        this.urls[path] = url;

        // resolve any promises from before

        unwrap(this.callbacks[path], callbacks => callbacks.forEach(({resolve}) => resolve(url)));

        return url;
      } catch (firebaseError) {
        const error = new Error(firebaseError.message);

        this.urls[path] = error;

        // reject any promises from before

        unwrap(this.callbacks[path], callbacks => callbacks.forEach(({reject}) => reject(error)));
        throw error;
      }
    }
  }
}

FirebaseImageStore.instance = new FirebaseImageStore();
