import first from 'lodash/first';
import last from 'lodash/last';
import {latLngLiteralFromCoordinatesArray} from 'wave-common/lib/models/CoordinatesArray';
import Location from 'wave-common/lib/models/Location';

// const DirectionsController = {
//   read: async (request: google.maps.DirectionsRequest) => new google.maps.DirectionsService().route(request),

//   readForInputs(inputs: DirectionsInput[]): Promise<google.maps.DirectionsResult> {
//     if (inputs.length < 2) {
//       throw new Error('Directions need at least two directions inputs');
//     }
//     const origin = expect(first(inputs));
//     const destination = expect(last(inputs));
//     const waypoints = inputs.length > 2 ? inputs.slice(1, -1) : undefined;
//     return this.read({origin, destination, waypoints});
//   },

// //   readForTrip(trip: Trip, driverCoordinates: CoordinatesArray | undefined): Promise<google.maps.DirectionsResult> {
// //     const inputs: DirectionsInput[] = tripLocationsWithDefault(trip).map(locationDirectionsInput);
// //     if (driverCoordinates) {
// //       inputs.splice(0, 0, driverCoordinates);
// //     }
// //     return this.readForInputs(inputs);
// //   },

//   readForTripLocations(locations: TripLocation[]): Promise<google.maps.DirectionsResult> {
//     return this.readForInputs(locations.map(locationDirectionsInput));
//   },
// };

// export default DirectionsController;

export function directionsReadForLocations(locations: Location[]) {
  if (locations.length < 2) {
    throw new Error('Must include at least 2 locations');
  }
  return new google.maps.DirectionsService().route({
    origin: latLngLiteralFromCoordinatesArray(first(locations)!.coordinates),
    destination: latLngLiteralFromCoordinatesArray(last(locations)!.coordinates),
    waypoints:
      locations.length > 2
        ? locations.slice(1, -1).map(location => ({
            location: new google.maps.LatLng(latLngLiteralFromCoordinatesArray(location.coordinates)),
          }))
        : [],
    travelMode: google.maps.TravelMode.DRIVING,
  });
}
