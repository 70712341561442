import firebase from 'firebase/app';
import first from 'lodash/first';
import omit from 'lodash/omit';
import {useMemo} from 'react';
import unwrap from '../functions/unwrap';
import UserGroup from '../models/scoopm/UserGroup';
import {useSelectedVendorId} from '../stores/localStorageStore';
import useFirestoreQueryListener from './useFirestoreQueryListener';

export default function useUserGroups(uid = unwrap(firebase.auth().currentUser, user => user.uid)) {
  const reference = useMemo(
    () => unwrap(uid, uid => firebase.firestore().collection('user-groups').where('members', 'array-contains', uid)),
    [uid],
  );

  const {isLoading, snapshot, error} = useFirestoreQueryListener(reference);

  const groups = useMemo(
    () => unwrap(snapshot, snapshot => snapshot.docs.map(doc => [doc.id, doc.data()])),
    [snapshot],
  );

  return {isLoading, groups, error};
}

export function useUserGroupForSelectedVendor() {
  const [selectedVendorId] = useSelectedVendorId();
  const reference = useMemo(
    () => unwrap(selectedVendorId, id => firebase.firestore().collection('user-groups').where('vendorId', '==', id)),
    [selectedVendorId],
  );
  const result = useFirestoreQueryListener(reference, UserGroup);
  return Object.assign(omit(result, 'models'), {group: first(result.models)});
}
