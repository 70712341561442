import firebase from 'firebase/app';
import querystringify from 'querystringify';
import React, {useMemo, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import unwrap from '../../functions/unwrap';
import AuthLayout from './AuthLayout';

export default function ForgotPassword() {
  const location = useLocation();
  const [email, setEmail] = useState();
  const [validation, setValidation] = useState();
  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [didSucceed, setDidSucceed] = useState(false);

  const signInLink = useMemo(
    () => unwrap(querystringify.parse(location.search).from, from => `/sign-in?from=${from}`, '/sign-in'),
    [location.search],
  );

  const sendPasswordResetEmail = event => {
    event.preventDefault();

    const validation = {
      email: Boolean(email),
    };
    setValidation(validation);

    if (!validation.email) {
      return;
    }

    setIsSending(true);
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setDidSucceed(true);
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/invalid-email':
            return setErrorMessage("That doesn't appear to be a valid email address");
          case 'auth/user-not-found':
            return setErrorMessage("That doesn't seem to be an email address you used before");
          default:
            return setErrorMessage("Sorry, we couldn't send the email. Please contact us if the problem persists.");
        }
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  return (
    <AuthLayout>
      <div className="mb-3">
        <Link to={signInLink}>&larr; Sign in</Link>
      </div>

      {errorMessage ? (
        <div className="alert alert-danger">{errorMessage}</div>
      ) : didSucceed ? (
        <div className="alert alert-primary">A password reset link has been sent to {email}</div>
      ) : (
        <p>To reset your password, enter your account email address</p>
      )}

      <form onSubmit={sendPasswordResetEmail}>
        <div className="mb-3">
          <input
            type="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            className={'form-control' + (validation && !validation.email ? ' is-invalid' : '')}
            placeholder="Email address"
            disabled={isSending}
          />
          <small className="invalid-feedback">Required</small>
        </div>
        <input type="submit" value="Send password reset" className="btn btn-primary" disabled={isSending} />
      </form>
    </AuthLayout>
  );
}
