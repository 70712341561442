import React from 'react';
import FirebaseTable from '../../../components/FirebaseTable';
import moment from 'moment';
import formatCurrency from '../../../functions/formatCurrency';

export default function All() {
  return (
    <FirebaseTable
      title="All Delivery Quotes"
      path="quotes"
      limitToLast={300}
      observe
      reverse
      link={quote => `/admin/quotes/${quote.key}`}
      columns={[
        {
          heading: 'Dispatch',
          value: quote => quote.apiUsername.toUpperCase(),
        },
        {
          heading: 'Available',
          value: quote =>
            quote.isAvailable ? <span className="text-success">Yes</span> : <span className="text-danger">No</span>,
        },
        {
          heading: 'Pickup',
          value: quote => quote.estimatedPickupTimestampMs && moment(quote.estimatedPickupTimestampMs).calendar(),
        },
        {
          heading: 'Drop-off',
          value: quote => quote.estimatedDropoffTimestampMs && moment(quote.estimatedDropoffTimestampMs).calendar(),
        },
        {
          heading: 'Fee',
          value: quote => quote.fee && formatCurrency(quote.fee),
        },
        {
          heading: 'Expires',
          value: quote => quote.expirationTimestampMs && moment(quote.expirationTimestampMs).calendar(),
        },
        {
          heading: 'Created',
          value: quote => moment(quote.creationTimestampMs).calendar(),
        },
        // { heading: 'Path', value: quote => quote.path },
        // { heading: 'Time', value: quote => moment(quote.timestampMs).calendar() },
        // {
        //     heading: 'Response',
        //     value: quote => {
        //         if (quote.responseCode >= 200 && quote.responseCode < 300) {
        //             return <span className="text-success">{quote.responseCode}</span>
        //         } else {
        //             return <span className="text-danger">{quote.responseCode}</span>
        //         }
        //     }
        // }
      ]}
    />
  );
}

let m = {
  apiUsername: 'olo',
  creationTimestampMs: 1594391140409,
  estimatedDropoffTimestampMs: 1594394562700,
  estimatedPickupTimestampMs: 1594392667700,
  expirationTimestampMs: 1594392940407,
  fee: 37.23,
  isAccepted: true,
  isAvailable: true,
  miles: 9.263398868,
  minutes: 26.583333333333332,
  tripId: '-MBt6WZzWgSx9fqE780u',
  key: '-MBt6WRoUCda7XQ9G2Nc',
};
