import {ReactNode} from 'react';
import {MarkerProps} from 'react-google-maps';
import {CoordinatesArray, LatLng, TripLocation} from 'wave-common';
import unwrap from '../../functions/unwrap';
import PositionInList from '../../models/scoopm/PositionInList';
import Trip from '../../models/scoopm/Trip';
import {latLng} from './LatLng+additions';

export type GoogleLatLng = google.maps.LatLng;

export type MarkerInterface = Omit<MarkerProps, 'icon'> & {
  id: string;
  clusterName?: string;
  // label?: string | {text: string; className: string};
  infoWindowContent?: string;
  infoWindowComponent?: ReactNode;
  position: CoordinatesArray | LatLng | GoogleLatLng;
  icon: MarkerProps['icon'] | false;
  onClick?: () => void;
  zIndex?: number;
  rotation?: number;
  animate?: boolean;
};

export default class Marker {
  id: string;
  clusterName?: string;
  label?: string | {text?: string; className?: string};
  infoWindowContent?: string | undefined;
  position: google.maps.LatLng;
  icon: MarkerProps['icon'] | false;
  onClick?: (() => void) | undefined;
  zIndex?: number | undefined;
  rotation?: number | undefined;

  constructor({
    id,
    clusterName,
    label,
    infoWindowContent,
    position,
    icon,
    onClick,
    zIndex,
    rotation,
    ...props
  }: MarkerInterface) {
    this.id = id;
    this.clusterName = clusterName;
    this.label = label;
    this.infoWindowContent = infoWindowContent;
    this.position = latLng(position);
    this.icon = icon;
    this.onClick = onClick;
    this.zIndex = zIndex || 0;
    this.rotation = rotation;
    Object.assign(this, props);
  }

  /**
   *
   * @param {Trip} trip
   * @returns
   */
  static markersForTrip(trip: Trip, onClick?: (trip: Trip) => void, showOnlyPickupExceptForTripId?: string) {
    const tripTitle = trip.simplestTitle();
    return unwrap(trip.locationsWithDefault(), (locations: TripLocation[]) => {
      let locationsToUse = locations;
      if (trip.id && showOnlyPickupExceptForTripId && trip.id === showOnlyPickupExceptForTripId) {
        locationsToUse = [locationsToUse[0]];
      }
      return locationsToUse.map((location, locationIndex) => {
        const position = PositionInList.for(locationIndex, locationsToUse);
        return new Marker({
          id: trip.id + locationIndex,
          clusterName: 'TRIPS',
          label: {text: tripTitle, className: 'text-shadow-white'},
          position: latLng(location.coordinates),
          icon: {
            // url: position.mapIconUrl(),
            path: position.mapIconPath(),
            labelOrigin: new (window as any).google.maps.Point(15, 40),
            fillColor: 'black',
            fillOpacity: 1,
            strokeColor: '#FFFFFF77',
            // strokeWeight: 0.1,
          },
          onClick: unwrap(onClick, callback => () => (callback as any)(trip)) as any,
        });
      });
    });
  }
}
