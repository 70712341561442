import React from 'react';
import unwrap from '../../functions/unwrap';
import PropertyType from '../types/PropertyType';
import InvalidFeedback from './InvalidFeedback';

export default function BooleanInput({property, id, value, onChange, disabled, validationError}) {
  return (
    <>
      <div className="custom-control custom-switch">
        <input
          id={id}
          name={id}
          type="checkbox"
          checked={value || false}
          onChange={event => onChange(event.target.checked)}
          className={
            'custom-control-input' + unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid'), '')
          }
          disabled={disabled}
        />
        <label className="custom-control-label" htmlFor={id}>
          {PropertyType.format(property.type, value)}
        </label>
        <InvalidFeedback property={property} error={validationError} />
      </div>
    </>
  );
}
