"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.driverRatingListForDriverId = void 0;
const DriverRating_1 = require("../models/DriverRating");
const PATH = 'driver-ratings';
async function driverRatingListForDriverId(driverId, firestore) {
    const results = await firestore.list(PATH, {
        orderBy: 'timestamp',
        orderByDirection: 'desc',
        where: [['driverId', '==', driverId]],
    });
    return results.map(result => (0, DriverRating_1.driverRatingDeserialize)(result.value, result.id));
}
exports.driverRatingListForDriverId = driverRatingListForDriverId;
