"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zendriveTripDescription = void 0;
const ModelDescription_1 = require("../type-descriptions/ModelDescription");
const Number_1 = require("../type-descriptions/Number");
const String_1 = require("../type-descriptions/String");
const InsurancePlan_1 = require("./InsurancePlan");
const Period_1 = require("./zendrive/Period");
exports.zendriveTripDescription = (0, ModelDescription_1.modelDescription)({
    properties: {
        id: (0, String_1.stringDescription)(),
        driverId: (0, String_1.stringDescription)(),
        startTimestampSec: (0, Number_1.numberDescription)({ minimumValue: 0 }),
        endTimestampSec: Object.assign(Object.assign({}, (0, Number_1.numberDescription)({ minimumValue: 0 })), { isOptional: true }),
        period: Period_1.periodDescription,
        insurancePlan: Object.assign(Object.assign({}, InsurancePlan_1.insurancePlanDescription), { isOptional: true }),
    },
});
