import DmfError from '../DmfError';
import {dmfError} from '../functions/dmflog';
import {Db} from './Db';
import {UserService} from './UserService';
import firebase from 'firebase/app';

export class Auth {
  static roles = {
    none: 0,
    driver: 1,
    dispatcher: 2,
    admin: 3,
  };
  static roleNames = {
    none: 'none',
    user: 'user',
    driver: 'driver',
    dispatcher: 'dispatcher',
    admin: 'admin',
  };

  static accessRules = [
    {path: '/', publicAccess: true},
    {path: '/sign-in', publicAccess: true},
    {path: '/about', publicAccess: true},
    {path: '/terms-and-conditions', publicAccess: true},
    {path: '/contractor-agreement', publicAccess: true},
    {path: '/shareMyRide', publicAccess: true},
    {path: '/privacy-policy', publicAccess: true},
    {path: '/auth', publicAccess: true},
    {path: '/rides/all', minRole: Auth.roles.driver},
    {path: '/tickets/all', minRole: Auth.roles.admin},
    {path: '/drivers', minRole: Auth.roles.admin},
    {path: '/applications', minRole: Auth.roles.admin},
    {path: '/users', minRole: Auth.roles.admin},
    // {
    //   path: "/admin/ez-dispatch", minRole: Auth.roles.admin, exceptions: [
    //     "wBJne2a5pLO6NNRmVGpjv3UU8KV2",
    //     "E53A2IYpOsf7VJngCAsKIaJL6YN2"
    //   ]
    // },
    {path: '/admin', minRole: Auth.roles.admin},
  ];

  constructor() {
    this.credential = false;
  }

  get user() {
    return firebase.auth().currentUser || false;
  }

  checkIfAccountExistsFor(emailAddress) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .fetchSignInMethodsForEmail(emailAddress)
        .then(signInMethods => {
          if (signInMethods.length > 0) {
            resolve();
          } else {
            reject(new Error('No sign-in methods for the given email address'));
          }
        })
        .catch(error => reject(new DmfError(this, `checkIfAccountExistsFor(${emailAddress})`, error)));
    });
  }

  /**
   * @returns {Auth}
   */
  static get instance() {
    //init
    if (typeof window.dmfAuth === 'undefined') {
      window.dmfAuth = new Auth();
    }
    //return
    return window.dmfAuth;
  }

  reAuthenticate(email, password) {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    let promise = this.user.reauthenticateAndRetrieveDataWithCredential(credential).catch(error => {
      dmfError('Auth.reAuthenticate: couldnt reauthenticate', error);
    });
    return promise;
  }

  sendPasswordResetEmail(email) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve();
        })
        .catch(error => {
          dmfError('Error sending password reset email:\n' + error.message);
          reject(error);
        });
    });
  }

  signIn(email, password) {
    return (
      this.signOut()
        // in case they're an anonymous user
        .then(() => firebase.auth().signInWithEmailAndPassword(email, password))
        .catch(error => {
          dmfError(`Auth.signIn`, error.message, error.code, error);
          throw error;
        })
    );
  }

  signOut() {
    return firebase
      .auth()
      .signOut()
      .catch(error => {
        dmfError(`Auth.signOut()`, error.message, error.code, error);
        throw error;
      });
  }

  signUp(email, password) {
    return new Promise((resolve, reject) => {
      let isAnonymous = firebase.auth().currentUser.isAnonymous;
      if (isAnonymous) {
        // upgrade to full account
        let credential = firebase.auth().EmailAuthProvider.credential(email, password);
        return firebase
          .auth()
          .currentUser.linkAndRetrieveDataWithCredential(credential)
          .catch(error => {
            dmfError(`Error upgrading anonymous account.\n${error.message}\n${error.code}\n${error}`);
            throw error;
          });
      } else {
        // create new account
        return firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .catch(error => {
            dmfError(`Error creating user.\n${error.message}\n${error.code}\n${error}`);
            throw error;
          });
      }
    }).then(() => {
      return Db.instance.createCustomer();
    });
  }

  updateEmail(email) {
    return new Promise((resolve, reject) => {
      this.user
        .updateEmail(email)
        .then(() => {
          resolve();
        })
        .catch(error => {
          dmfError('Auth.updateEmail: couldnt update email:\n' + error.message);
          reject(error);
        });
    });
  }

  updatePassword(password) {
    return new Promise((resolve, reject) => {
      this.user
        .updatePassword(password)
        .then(() => {
          resolve();
        })
        .catch(error => {
          dmfError('Auth.updatePassword: couldnt update password:\n' + error.message);
          dmfError('Error code: ' + error.code);
          reject(error);
        });
    });
  }

  userCanAccess(pathname) {
    let rule = this.ruleFor(pathname);
    // dmfError("\n\nChecking rule", rule, "for path", pathname);

    if (!Auth.instance.user && !rule.publicAccess) {
      return false;
    }

    if (!rule) {
      //   dmfError("No rule");
      return true;
    }

    if (!rule.minRole) {
      //   dmfError("No min role");
      return true;
    }

    if (UserService.instance.role >= rule.minRole) {
      //   dmfError("User allowed");
      return true;
    }

    //   dmfError("User not allowed");
    return false;
  }

  ruleFor(pathname) {
    // iterate rules
    return (
      Auth.accessRules.find(rule => {
        // if path begins with rule name
        if (pathname.indexOf(rule.path) === 0) {
          // return rule
          return rule;
        } else {
          return false;
        }
        // or false
      }) || false
    );
  }

  setUserRole(uid, role) {
    let setUserRole = firebase.functions().httpsCallable('setUserRole');
    return setUserRole({uid, role});
  }

  getUserRole(forUid) {
    let getUserRole = firebase.functions().httpsCallable('getUserRole');
    return getUserRole({uid: forUid});
  }

  getUserRecord(forUid) {
    let getUserRecord = firebase.functions().httpsCallable('getUserRecord');
    return getUserRecord({uid: forUid});
  }
}
