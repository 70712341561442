const {default: HttpReference} = require('../platform/HttpReference');

export default class DispatchAcceptQuote extends HttpReference {
  post(username, password, quote) {
    return this.request({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${Buffer.from(`${username}:${password}`).toString('base64')}`,
      },
      data: quote,
    });
  }
}
