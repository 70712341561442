import Schema from '../types/Schema';
import Property from '../types/Property';
import PropertyType from '../types/PropertyType';

const tripSourceDetailschema = new Schema('trip-source-detail', [
  new Property('source', PropertyType.tripSource),
  new Property('invoiceFormulaId', PropertyType.firestoreId, {
    isOptional: true,
  }),
  new Property('payoutFormulaId', PropertyType.firestoreId, {
    isOptional: true,
  }),
]);

export default tripSourceDetailschema;
