import firebase from 'firebase/app';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import ReactDatePicker from 'react-datepicker';
import {Link} from 'react-router-dom';
import Card from '../../../components/Card';
import DriverSelect from '../../../components/DriverSelect';
import {ViewInFirebaseLink} from '../../../components/ViewInFirebase';
import csvFromArrayOfArrays from '../../../functions/csvFromArrayOfArrays';
import {simluateDownloadClickCsv} from '../../../functions/simulateDownloadClick';
import unwrap from '../../../functions/unwrap';
import useLocalStorage from '../../../hooks/useLocalStorage';
import Driver from '../../../models/scoopm/Driver';

const columns = [
  {name: 'Period', text: (tripId, trip) => trip.period},
  {name: 'Plan', text: (tripId, trip) => trip.insurancePlan},
  {name: 'Start', text: (tripId, trip) => trip.startTimestampFormatted},
  {name: 'Finish', text: (tripId, trip) => trip.endTimestampFormatted},
  {name: 'Duration', text: (tripId, trip) => trip.durationFormatted},
  {
    name: 'Driver',
    text: (tripId, trip) => trip.driverName,
    render: (tripId, trip, text) => (
      <td className="py-0 align-middle">
        <Link to={`/admin/drivers/${trip.driverId}`} className="text-decoration-underline">
          {text}
        </Link>
      </td>
    ),
  },
  {
    name: 'DB',
    text: (tripId, trip) => 'View',
    render: (tripId, trip, text) => (
      <td className="py-0 align-middle">
        <ViewInFirebaseLink path={`/zendrive-trips/${tripId}`} className="text-decoration-underline">
          {text}
        </ViewInFirebaseLink>
      </td>
    ),
  },
];

export default function Zendrive() {
  // STATE

  const [startTimestamp, setStartTimestamp] = useLocalStorage(
    'ZENDRIVE_START_DATE',
    moment().startOf('month').valueOf(),
  );
  const [endTimestamp, setEndTimestamp] = useLocalStorage('ZENDRIVE_END_DATE', moment().endOf('month').valueOf());
  const [driverId, setDriverId] = useLocalStorage('ZENDRIVE_DRIVER_ID');
  const [includeP1, setIncludeP1] = useLocalStorage('ZENDRIVE_INCLUDE_P1', true);

  const [unfilteredEntries, setUnfilteredEntries] = useState(); // only refresh this when neccessary. otherwise sort existing snapshot
  const [entries, setEntries] = useState();

  const [driversObject, setDriversObject] = useState();

  // EFFECT: dates --> unfiltered entries

  useEffect(() => {
    if (!startTimestamp || !endTimestamp) return;

    console.log('Getting Zendrive trips...');
    setUnfilteredEntries(null);

    const startTimestampSec = startTimestamp / 1000;
    const endTimestampSec = endTimestamp / 1000;
    const queryEndTimestampSec = moment(endTimestamp).add(1, 'week').valueOf() / 1000;

    firebase
      .database()
      .ref('zendrive-trips')
      .orderByChild('startTimestampSec')
      .startAt(startTimestampSec)
      .endAt(queryEndTimestampSec) // since we're sorting by start time, we'll add some padding + filter data later
      .once('value')
      .then(
        snapshot => {
          const unfilteredEntries = Object.entries(snapshot.val() || {}).filter(
            ([tripId, trip]) =>
              trip.startTimestampSec >= startTimestampSec &&
              (trip.endTimestampSec ? trip.endTimestampSec <= endTimestampSec : true),
          );
          console.log(`Got ${unfilteredEntries.length} Zendrive trips`, unfilteredEntries);
          setUnfilteredEntries(unfilteredEntries);
        },
        error => {
          console.error('Unable to get data', error);
          window.alert(`Unable to get data. ${error.message}`);
        },
      );
  }, [startTimestamp, endTimestamp]);

  // EFFECT: unfiltered entries, sorting criteria --> entries

  useEffect(() => {
    if (unfilteredEntries) {
      const entries = unfilteredEntries
        .filter(
          ([tripId, trip]) =>
            (!driverId ? true : trip.driverId === driverId) && (includeP1 ? true : trip.period !== 'P1'),
        )
        .map(([tripId, trip]) => {
          const startMoment = moment(trip.startTimestampSec * 1000);
          const endMoment = trip.endTimestampSec ? moment(trip.endTimestampSec * 1000) : null;
          trip.startTimestampFormatted = startMoment.format('M/D/YYYY h:mm a');
          trip.endTimestampFormatted = endMoment ? endMoment.format('M/D/YYYY h:mm a') : null;
          trip.durationFormatted = endMoment
            ? capitalize(moment.duration(endMoment.diff(startMoment)).humanize())
            : null;
          trip.driverName = unwrap(driversObject, obj =>
            unwrap(obj[trip.driverId], driver => Driver.prototype.casualName.apply(driver)),
          );
          return [tripId, trip];
        });
      console.log(`Filtered ${entries.length} Zendrive trips`, entries);
      setEntries(entries);
    } else {
      setEntries(null);
    }
  }, [unfilteredEntries, driverId, includeP1, driversObject]);

  const fileName = prefix =>
    `${prefix}_${moment(startTimestamp).format('YYYY_MM_DD')}_to_${moment(endTimestamp).format('YYYY_MM_DD')}${unwrap(
      driverId,
      id => `_${Driver.prototype.casualName.apply(driversObject[id]).replaceAll(' ', '_').replaceAll('.', '')}`,
      '',
    )}${includeP1 ? '' : '_without_P1'}.csv`;

  // METHOD: download

  const onClickDownload = event => {
    simluateDownloadClickCsv(
      csvFromArrayOfArrays([
        columns.map(column => column.name),
        ...entries.map(([tripId, trip]) => columns.map(column => column.text(tripId, trip))),
      ]),
      fileName('Zendrive'),
    );
  };

  // METHOD: download zendrive

  const onClickDownloadReport = event => {
    simluateDownloadClickCsv(
      csvFromArrayOfArrays([
        ['driver_id', 'period', 'start_timestamp', 'end_timestamp'],
        ...entries.map(([tripId, trip]) => [
          trip.driverId,
          trip.period,
          Math.round(trip.startTimestampSec),
          unwrap(trip.endTimestampSec, ts => Math.round(ts)),
        ]),
      ]),
      fileName('Zendrive_Report'),
    );
  };

  return (
    <div className="m-0 p-2 p-md-3">
      <div className="row">
        <div className="col-lg-4">
          <Card>
            <div className="form-group">
              <h5>Search settings</h5>
            </div>

            <div className="form-group">
              <label htmlFor="startDateInput">Start date</label>
              <ReactDatePicker
                id="startDateInput"
                selected={new Date(startTimestamp)}
                onChange={date => setStartTimestamp(date.valueOf())}
                wrapperClassName="d-block"
                className="form-control"
                autoComplete="off"
              />
              <small className="invalid-feedback">Test</small>
            </div>

            <div className="form-group">
              <label htmlFor="endDateInput">End date</label>
              <ReactDatePicker
                id="endDateInput"
                selected={new Date(endTimestamp)}
                onChange={date => setEndTimestamp(moment(date).endOf('day').valueOf())}
                wrapperClassName="d-block"
                className="form-control"
                autoComplete="off"
              />
            </div>

            <div className="form-group">
              <label htmlFor="driverSelect">Driver</label>
              <DriverSelect
                value={driverId}
                onChange={setDriverId}
                onGetDrivers={driverEntries =>
                  setDriversObject(
                    Object.assign(
                      {},
                      ...driverEntries.map(([driverId, driver]) => ({
                        [driverId]: driver,
                      })),
                    ),
                  )
                }
                className="custom-select"
                defaultText="Any"
              />
            </div>

            <div className="form-group">
              <label htmlFor="includeP1Checkbox">Exclude P1 events</label>
              <div className="custom-control custom-switch">
                <input
                  id="includeP1Checkbox"
                  type="checkbox"
                  checked={!includeP1}
                  onChange={event => setIncludeP1(!event.target.checked)}
                  className="custom-control-input"
                />
                <label htmlFor="includeP1Checkbox" className="custom-control-label">
                  {includeP1 ? 'No' : 'Yes'}
                </label>
              </div>
            </div>

            <hr />

            <div className="form-group">
              <h5>CSV</h5>
            </div>

            <div className="form-group">
              <button onClick={onClickDownload} className="btn btn-success d-block" disabled={!entries}>
                Download CSV
              </button>
            </div>

            <div className="form-group">
              <button onClick={onClickDownloadReport} className="btn btn-success d-block" disabled={!entries}>
                Download Zendrive CSV
              </button>
            </div>
          </Card>
        </div>

        <div className="col-lg-8">
          <Card innerPadded={false} innerClassName="bg-gray-200" white={false}>
            <div className="row p-2 p-md-3">
              <div className="col">
                {entries === undefined
                  ? 'Data will show when dates are selected'
                  : entries === null
                  ? 'Loading...'
                  : `${entries.length} results, oldest to newest`}
              </div>
              <div className="col-auto">
                <ViewInFirebaseLink path="/zendrive-trips">View in Firebase</ViewInFirebaseLink>
              </div>
            </div>
            {entries && (
              <table className="table table-hover bg-white rounded-bottom">
                <thead className="thead-light">
                  <tr>
                    {columns.map((column, i) => (
                      <th key={i}>{column.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {entries.map(([tripId, trip]) => (
                    <tr key={tripId}>
                      {columns.map((column, i) =>
                        column.render ? (
                          <React.Fragment key={tripId + i}>
                            {column.render(tripId, trip, column.text(tripId, trip))}
                          </React.Fragment>
                        ) : (
                          <td key={tripId + i}>{column.text(tripId, trip)}</td>
                        ),
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}
