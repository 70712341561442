import firebase from 'firebase/app';
import sortBy from 'lodash/sortBy';
import React, {useEffect, useState} from 'react';
import {formatPhone} from '../functions/formatPhone';
import unwrap from '../functions/unwrap';
import useRealtimeDatabase from '../hooks/useRealtimeDatabase';
import Customer from '../models/scoopm/Customer';

export default function CustomerSelect({value, onChange, ...props}) {
  // STATE

  const customers = useRealtimeDatabase({path: '/customers'});
  const [filteredCustomers, setFilteredCustomers] = useState();

  // EFFECT: customers --> filter to have first and last name

  useEffect(() => {
    setFilteredCustomers(
      unwrap(customers.data, data => {
        const customers = Object.entries(data);
        const filtered = customers.filter(
          ([customerId, customer]) => customer.firstName && customer.lastName && (customer.phone || customer.cellphone),
        );
        const sorted = sortBy(filtered, entry => entry[1].lastName + entry[1].firstName);
        return sorted;
      }),
    );
  }, [customers.data]);

  // EFFECT: fetch customer. if present, call `onChange` with our own UID

  useEffect(() => {
    firebase
      .database()
      .ref(`/customers/${firebase.auth().currentUser.uid}`)
      .once('value')
      .then(
        snapshot => {
          if (snapshot.val()) {
            if (filteredCustomers && filteredCustomers.find(([customerId]) => customerId === snapshot.key)) {
              onChange(snapshot.key);
            }
          }
        },
        error => {
          console.error('Error checking if current user is a customer.', error.message);
        },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCustomers]);

  // RENDER

  return (
    <select
      type="text"
      value={value || ''}
      onChange={event => onChange(event.target.value, customers.data[event.target.value])}
      {...props}>
      <option value="">None</option>
      {filteredCustomers &&
        filteredCustomers.map(([customerId, customer]) => (
          <option key={customerId} value={customerId}>
            {customer.firstName} {customer.lastName}{' '}
            {unwrap(Customer.prototype.safePhoneNumber.apply(customer), phone => formatPhone(phone))}
          </option>
        ))}
    </select>
  );
}
