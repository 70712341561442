import React, {ReactChild} from 'react';
import {formatCurrency, unwrap} from 'wave-common';
import WhiteText from '../../../../components/WhiteText';
import joinClassNames from '../../../../functions/joinClassNames';

export default function Row({
  title,
  amountD,
  emphasis,
}: {
  title?: string | JSX.Element;
  amountD?: number | ReactChild;
  emphasis?: boolean;
}) {
  return (
    <div className={joinClassNames('row pb-1', emphasis && 'font-weight-bold')}>
      <div className="col">{title ?? <WhiteText />}</div>
      <div className="col-auto">
        {unwrap(
          amountD,
          amount => (typeof amount === 'number' ? <>{formatCurrency(amount)}</> : amount),
          () => (
            <WhiteText />
          ),
        )}
      </div>
    </div>
  );
}
