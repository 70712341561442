import React, {Component} from 'react';

export class LegalText extends Component {
  render() {
    return (
      <div className="row justify-content-center">
        <div className="col-sm-10 col-lg-8 limited-width mt-5 mb-5 p-5 bg-white">
          <div className="pt-4">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
