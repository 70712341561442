import React, {useCallback, useEffect, useState} from 'react';
import {FixedSizeList} from 'react-window';
import unwrap from '../../../functions/unwrap';
import DriverRow from './DriverRow';

export default function DriversList(props: {
  containerDiv: React.RefObject<HTMLDivElement>;
  filteredDrivers?: any[];
  selectedDriverId?: string;
  onChangeSelectedDriverId?: (id: string) => void;
  driverIdForQuery?: string;
  onChangeDriverIdForQuery?: (id: string) => void;
}) {
  const [listHeight, setListHeight] = useState(500); // fallback of 500

  // containerDiv --> list height
  useEffect(() => {
    const observer = new window.ResizeObserver(() => {
      setListHeight(props.containerDiv.current!.offsetHeight);
    });
    observer.observe(props.containerDiv.current!);
    const div = props.containerDiv.current!; // capture object reference
    return () => observer.unobserve(div);
  }, [props.containerDiv]);

  const getRow = useCallback(
    childProps => (
      <DriverRow
        selectedDriverId={props.selectedDriverId}
        onChangeSelectedDriverId={props.onChangeSelectedDriverId}
        driverIdForQuery={props.driverIdForQuery}
        onChangeDriverIdForQuery={props.onChangeDriverIdForQuery}
        {...childProps}
      />
    ),
    [props.selectedDriverId, props.onChangeSelectedDriverId, props.driverIdForQuery, props.onChangeDriverIdForQuery],
  );

  return unwrap(props.filteredDrivers, drivers =>
    drivers.length ? (
      <FixedSizeList
        width={300}
        height={listHeight}
        itemCount={drivers.length}
        itemData={drivers}
        itemKey={(index, data) => data[index][0]}
        itemSize={76}>
        {getRow}
      </FixedSizeList>
    ) : (
      <div className="p-2 text-center text-muted">No results</div>
    ),
  );
}
