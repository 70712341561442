export default class FirestoreBounds {
  constructor(rawData) {
    this.northeast = rawData.northeast;
    this.southwest = rawData.southwest;
  }

  gsmLatLngBounds() {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend({
      lat: this.southwest.latitude,
      lng: this.southwest.longitude,
    });
    bounds.extend({
      lat: this.northeast.latitude,
      lng: this.northeast.longitude,
    });
    return bounds;
  }
}
