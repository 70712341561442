import React, {Component} from 'react';
import firebase from 'firebase/app';

class VerifyEmail extends Component {
  state = {
    pending: true,
  };

  componentDidMount() {
    this.verifyEmail();
  }

  verifyEmail() {
    // apply action code
    firebase
      .auth()
      .applyActionCode(this.props.actionCode)
      .then(resp => {
        this.setState({pending: false});
      })
      .catch(error => {
        console.log(error);

        // try sending verificaiton again

        // check if signed in
        let user = firebase.auth().currentUser;
        if (!user) {
          this.setState({
            pending: false,
            error: 'The link you used is invalid or expired',
          });
          return;
        }

        // send verification
        user
          .sendEmailVerification()
          .then(() => {
            this.setState({
              pending: false,
              error: `The link you used is invalid or expired. A new email verification link has been emailed to "${user.email}"`,
            });
          })
          .catch(error => {
            console.log(error);
            this.setState({
              pending: false,
              error: 'The link you used is invalid or expired',
            });
          });
      });
  }

  render() {
    if (this.state.pending) return <h3>Confirming email...</h3>;

    if (this.state.error) return <h3>{this.state.error}</h3>;

    return (
      <div>
        <h3>Your email has been confirmed</h3>
        <a href={this.props.continueUrl || '/'} className="btn btn-primary float-right">
          {this.props.continueUrl ? 'Continue' : 'Home'}
        </a>
      </div>
    );
  }
}

export default VerifyEmail;
