import cloneDeep from 'lodash/cloneDeep';
import React, {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useState} from 'react';
import {EmptyTrip, expect} from 'wave-common';

const NewTripContext = createContext<State>(undefined as never);

type State = [trip: EmptyTrip | undefined, setTrip: Dispatch<SetStateAction<EmptyTrip | undefined>>];

export function NewTripContextProvider({children}: {children: ReactNode}) {
  const state = useState<EmptyTrip>();
  return <NewTripContext.Provider value={state}>{children}</NewTripContext.Provider>;
}

type SafeSetter = (newTrip: EmptyTrip | undefined | ((newTrip: EmptyTrip) => void)) => void;

export function useNewTripContext(): [EmptyTrip | undefined, SafeSetter] {
  const [newTrip, setNewTrip] = useContext(NewTripContext);

  const setNewTripSafe = useCallback(
    (newValueOrCallback: EmptyTrip | undefined | ((newTrip: EmptyTrip) => void)) => {
      if (newValueOrCallback instanceof Function) {
        setNewTrip(oldTrip => {
          let newTrip = expect(cloneDeep(oldTrip), 'old trip');
          newValueOrCallback(newTrip);
          return newTrip;
        });
      } else {
        return setNewTrip(newValueOrCallback);
      }
    },
    [setNewTrip],
  );

  return [newTrip, setNewTripSafe];
}
