import {distanceBetween} from 'geofire-common';
import {CoordinatesArray} from 'wave-common';
import {boundsforCircle} from '../../components/GoogleMaps/LatLng+additions';
import FirestoreModel from '../base/FirestoreModel';
import GeoCircle from './GeoCircle';

export default class Region extends FirestoreModel {
  static path = 'regions';

  name: string;
  circle: GeoCircle;

  constructor(snapshot?: firebase.firestore.DocumentSnapshot) {
    super(snapshot);

    this.name = snapshot?.get('name');
    this.circle = snapshot?.get('circle');
  }

  contains(coordinates: CoordinatesArray) {
    const distanceFromCenterToCoordinatesKm = distanceBetween(this.circle.center, coordinates);
    const distanceFromCenterToCoordinatesM = distanceFromCenterToCoordinatesKm * 1000;
    return distanceFromCenterToCoordinatesM < this.circle.radiusM;
  }

  latLngBounds() {
    return boundsforCircle(this.circle.center, this.circle.radiusM);
  }
}
