import React, {useCallback} from 'react';
import {FailureStatus, PendingStatus, SuccessStatus, useStatus} from 'wave-common';

export default function useGeolocation() {
  const {isPending, error, value, setStatus, status} = useStatus<GeolocationPosition, GeolocationPositionError>(
    React as never,
  );

  const startObserving = useCallback(() => {
    // check for geolocation API
    // if not available, you should never present geolocation as an option to the user

    if (!('geolocation' in navigator)) {
      setStatus(oldStatus => new FailureStatus(new Error('Geolocation API not available'), oldStatus.value));
      console.error('GeoLocation API not available');
      return;
    }

    // observe location

    setStatus(oldStatus => new PendingStatus(oldStatus.value));
    const handle = navigator.geolocation.watchPosition(
      position => {
        console.log('Observed position', position);
        setStatus(new SuccessStatus(position));
      },

      error => {
        console.error('Error observing position', error);
        setStatus(oldStatus =>
          oldStatus.value ? new SuccessStatus(oldStatus.value) : new FailureStatus(error, oldStatus.value),
        );
      },

      {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 10000,
        // timeout: 45000,
      },
    );

    // unsubscribe

    return () => {
      navigator.geolocation.clearWatch(handle);
    };
  }, [setStatus]);

  return {
    startObserving,
    requestPermission: startObserving,
    isPending,
    error,
    value,
    status,
  };
}
