import formatCurrency from '../../functions/formatCurrency';
import unwrap from '../../functions/unwrap';

/**
 * Represents information about a discount coupon code
 */
class Coupon {
  get discountPercentage() {
    return unwrap(this.discountFraction, fraction => fraction * 100 + '%');
  }

  // descriptions

  get discountAmountDescription() {
    return unwrap(this.discountAmount, amount => formatCurrency(-amount / 100));
  }

  get discountFractionDescription() {
    return unwrap(this.discountPercentage, percentage => percentage + ' OFF');
  }

  get flatRateDescription() {
    return unwrap(this.flatRate, flatRate => formatCurrency(flatRate / 100) + ' FLAT RATE');
  }

  get descriptions() {
    return [this.discountAmountDescription, this.discountFractionDescription, this.flatRateDescription].filter(
      description => description !== null,
    );
  }

  // constructor

  constructor({discountAmount, discountFraction, expirationTimestampMilliseconds, flatRate} = {}) {
    if (discountAmount) this.discountAmount = discountAmount;
    if (discountFraction) this.discountFraction = discountFraction;
    if (expirationTimestampMilliseconds) this.expirationTimestampMilliseconds = expirationTimestampMilliseconds;
    if (flatRate) this.flatRate = flatRate;
  }
}

export default Coupon;
