import firebase from 'firebase/app';
import $ from 'jquery';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import FontAwesome from '../../../components/FontAwesome';
import {latLng} from '../../../components/GoogleMaps/LatLng+additions';
import Spinner from '../../../components/Spinner';
import joinClassNames from '../../../functions/joinClassNames';
import unwrap from '../../../functions/unwrap';
import useCallbackCaller from '../../../hooks/useCallbackCaller';
import useFilterCities from '../../../hooks/useFilterCities';
import useInterval from '../../../hooks/useInterval';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';
import Driver from '../../../models/scoopm/Driver';
import DriversList from './DriversList';

export default function DriversPanel({
  onChangeAllDrivers,
  onChangeDriverEntries,

  selectedDriverId,
  onChangeSelectedDriverId,

  driverIdForQuery,
  onChangeDriverIdForQuery,

  additionalContent,
}) {
  const containerDiv = useRef();

  const {isLoading: citiesAreLoading, cities} = useFilterCities();

  const [filterActiveOnly, setFilterActiveOnly] = useLocalStorage('FILTER_ACTIVE_ONLY', false);

  const [query, setQuery] = useLocalStorage('DRIVERS_PANEL_QUERY');

  const [isLoading, setIsLoading] = useState(true);
  const [drivers, setDrivers] = useState();
  useInterval(
    useCallback(() => {
      setIsLoading(true);
      fetchDrivers()
        .then(setDrivers, console.log)
        .finally(() => setIsLoading(false));
    }, []),
    30_000,
  );
  const {data: selectedDriverCoordinates, isLoading: selectedDriverCoordinatesAreLoading} = useRealtimeDatabase({
    path: unwrap(selectedDriverId, id => `/drivers-geo-fire-active/${id}/l`),
  });
  const {data: selectedDriverBearing, isLoading: selectedDriverBearingIsLoading} = useRealtimeDatabase({
    path: unwrap(selectedDriverId, id => `/drivers/${id}/bearing`),
  });
  const [allDrivers, setAllDrivers] = useState();
  const filteredDriverEntries = useMemo(
    () =>
      unwrap(allDrivers, drivers => {
        let filteredDrivers = drivers;
        if (filterActiveOnly) {
          // filter: active only
          filteredDrivers = filteredDrivers.filter(driver => Boolean(driver.inPickupModeEnabled));
        }
        if (query) {
          // filter: query
          const cleanQuery = query.trim().toLowerCase();
          filteredDrivers = filteredDrivers.filter(driver => driver.quickQuery().includes(cleanQuery));
        }
        const entries = filteredDrivers.map(driver => [driver.id, driver]);
        return entries;
      }),
    [allDrivers, filterActiveOnly, query],
  );

  // callbacks

  useCallbackCaller(onChangeAllDrivers, allDrivers);
  useCallbackCaller(onChangeDriverEntries, filteredDriverEntries);

  // effect: add live coordinates to selected driver

  useEffect(() => {
    if (selectedDriverCoordinatesAreLoading || selectedDriverBearingIsLoading) return;
    if (selectedDriverId && selectedDriverCoordinates) {
      setDrivers(latestDrivers =>
        unwrap(latestDrivers, latestDrivers => {
          const newDrivers = latestDrivers.map(driver => new Driver(driver));
          const index = findIndex(newDrivers, {id: selectedDriverId});
          if (index !== -1) {
            if (selectedDriverCoordinates) newDrivers[index].coordinate = selectedDriverCoordinates;
            if (selectedDriverBearing) newDrivers[index].bearing = selectedDriverBearing;
          }
          return newDrivers;
        }),
      );
    }
  }, [
    selectedDriverId,
    selectedDriverCoordinates,
    selectedDriverBearing,
    selectedDriverCoordinatesAreLoading,
    selectedDriverBearingIsLoading,
  ]);

  // effect: parse/filter drivers

  useEffect(() => {
    let filteredDrivers = undefined;

    if (drivers) {
      // wait for cities to load

      if (!citiesAreLoading) {
        filteredDrivers = drivers;

        // filter: not tester account

        filteredDrivers = filteredDrivers.filter(driver => !driver.isTestingAccount);

        // filter: cities

        if (cities && cities.length) {
          filteredDrivers = filteredDrivers.filter(driver => {
            if (!driver.coordinate) return false;
            const driverLatLng = latLng(driver.coordinate);
            return cities.find(city => city.contains(driverLatLng));
          });
        }
      }
    }

    // sort by name

    filteredDrivers = sortBy(filteredDrivers, driver => driver.nickNameWithLastName().toLowerCase());

    setAllDrivers(filteredDrivers);
  }, [cities, citiesAreLoading, filterActiveOnly, query, drivers]);

  // effect: set up tooltips when driver entries change. This must happen after the button elements exist in the DOM

  useEffect(() => {
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 100);
  }, [selectedDriverId, filteredDriverEntries]);

  // effect: hide tooltip when active only changes

  useEffect(() => {
    setTimeout(() => {
      $('#activeDriversFilterButton').tooltip('dispose');
    }, 100);
  }, [filterActiveOnly]);

  // render

  return (
    <div className="h-100 d-flex flex-column" style={{width: '300px'}}>
      <div className="row d-flex align-items-center border-bottom mx-0 px-2" style={{height: '76px'}}>
        <div className="col">
          <input
            type="search"
            value={query || ''}
            onChange={event => setQuery(event.target.value)}
            className="form-control"
            placeholder="Search"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
        </div>

        <div className="col-auto">
          <button
            id="activeDriversFilterButton"
            type="button"
            onClick={event => setFilterActiveOnly(!filterActiveOnly)}
            className={joinClassNames('btn', filterActiveOnly && 'btn-primary')}
            data-toggle="tooltip"
            title={`Show ${filterActiveOnly ? 'all' : 'active only'}`}>
            {isLoading ? <Spinner small /> : <FontAwesome.LightBulb />}
          </button>
        </div>

        {additionalContent}
      </div>

      <div ref={containerDiv} className="flex-grow-1">
        <DriversList
          containerDiv={containerDiv}
          filteredDrivers={filteredDriverEntries}
          selectedDriverId={selectedDriverId}
          onChangeSelectedDriverId={onChangeSelectedDriverId}
          driverIdForQuery={driverIdForQuery}
          onChangeDriverIdForQuery={onChangeDriverIdForQuery}
        />
      </div>
    </div>
  );
}

async function fetchDrivers() {
  // fetch approved drivers

  console.log('Fetching drivers...');
  const snapshot = await firebase.database().ref('drivers').orderByChild('status').equalTo('approved').once('value');
  let drivers = unwrap(snapshot.val(), drivers =>
    Object.entries(drivers).map(([id, driver]) => Object.assign(new Driver(driver), {id})),
  );

  // fetch coordinates for each driver

  drivers = await Promise.all(
    drivers.map(async driver => {
      if (!driver.coordinate) {
        // console.log(`Fetching coordinates... ${driver.id}`)
        const snapshot = await firebase
          .database()
          .ref('drivers-geo-fire-active')
          .child(driver.id)
          .child('l')
          .once('value');
        const coordinate = snapshot.val();
        driver.coordinate = coordinate;
      }
      return driver;
    }),
  );

  return drivers;
}
