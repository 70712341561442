import moment from 'moment-timezone';
import React, {useCallback, useContext, useMemo} from 'react';
import {Link} from 'react-router-dom';
import FirebaseStorageCircleImage from '../../../components/FirebaseStorageCircleImage';
import FontAwesome from '../../../components/FontAwesome';
import joinClassNames from '../../../functions/joinClassNames';
import MomentHelper from '../../../functions/MomentHelper';
import unwrap from '../../../functions/unwrap';
import Trip from '../../../models/scoopm/Trip';
import TripStatus from '../../../models/scoopm/TripStatus';
import UserType from '../../../models/scoopm/UserType';
import BootstrapModalContext from '../../BootstrapModalContext';
import Ride from '../rides/Ride';

export default function TripRow({
  style,
  index,
  data,
  selectedTripId,
  onChangeSelectedTripId,
  timezone,
  onChangeHighlightedTripId,
  highlightedTripId,
}) {
  const [tripId, trip] = data[index];
  const isSelected = tripId === selectedTripId;

  const currentLocation = useMemo(() => Trip.prototype.currentLocationWithDefault.apply(trip), [trip]);

  const {setModalContent} = useContext(BootstrapModalContext);

  const onClick = useCallback(
    event => {
      onChangeSelectedTripId(isSelected ? null : tripId);
    },
    [onChangeSelectedTripId, tripId, isSelected],
  );

  const showTripModal = (customerId, tripId) => event => {
    setModalContent(
      <>
        <div className="modal-header">
          <h5 className="modal-title" id="rootModalLabel">
            Details
          </h5>
          <Link
            to={`/admin/jobs/${customerId}/${tripId}`}
            className="my-auto ml-auto mr-4"
            onClick={event => {
              setModalContent(null);
            }}>
            Go to full details
          </Link>
          <button type="button" className="close ml-0" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {customerId && tripId && <Ride match={{params: {customerId, tripId}}} minimal />}
        </div>
      </>,
    );
  };

  return (
    <div
      style={style}
      className={joinClassNames(
        'd-flex flex-row align-items-center border-bottom',
        isSelected ? 'bg-gray-200' : highlightedTripId === tripId ? 'bg-gray-100' : null,
      )}
      onMouseEnter={event => onChangeHighlightedTripId && onChangeHighlightedTripId(tripId)}>
      <div onClick={onClick} className="p-2 d-flex align-items-center cursor-pointer">
        <FirebaseStorageCircleImage
          path={trip.driverProfilePath}
          fallbackUrl={UserType.driver.imgSrc}
          diameterRem={2.5}
        />
      </div>
      <div
        onClick={onClick}
        className="pr-2 py-2 flex-grow-1 d-flex flex-column justify-items-center cursor-pointer truncated-flex">
        <span className="truncated-flex">{trip.title2()}</span>

        <small className="d-block truncated-flex">
          {unwrap(trip.firstScheduledTimestampMs, timestamp => moment(timestamp).tz(timezone).format('MM/DD/YYYY'))}

          {' - '}

          {unwrap(TripStatus.from(trip.status), status => status.title(trip))}
        </small>

        <small className="d-block truncated-flex">
          {unwrap(Trip.prototype.locationsWithDefault.apply(trip), locations =>
            join(
              locations.map((location, i) => {
                let formattedTime = location.scheduledTimestampMs
                  ? moment(location.scheduledTimestampMs)
                      .tz(timezone)
                      .format(i === 0 ? 'hh:mma z' : 'hh:mma')
                  : 'ASAP';

                if (location.scheduledTimestampMs && (i === currentLocation || (i === 0 && currentLocation === -1))) {
                  const diff = moment(location.scheduledTimestampMs).diff(moment());
                  const duration = moment.duration(diff);
                  return (
                    <span key={i}>
                      {formattedTime}{' '}
                      {unwrap(
                        MomentHelper.durationString(duration),
                        durationString =>
                          durationString && (
                            <span className={diff <= 0 ? 'text-danger' : ''}>
                              ({durationString.replace('-', '')} {duration < 0 ? 'late' : 'left'})
                            </span>
                          ),
                      )}
                    </span>
                  );
                } else {
                  return formattedTime;
                }
              }),
              key => <FontAwesome.ArrowRightSolid key={key} className="text-gray-600 mx-1" />,
            ),
          )}
          {/* {Trip.prototype.schedulingStatusComponent.apply(trip) || <>&nbsp;</>} */}
        </small>
      </div>
      <button type="button" onClick={showTripModal(trip.customerId, tripId)} className="btn m-2">
        <span className="fa-stack fa-xs text-secondary">
          <i className="fa-regular fa-circle fa-stack-2x"></i>
          <i className="fa-solid fa-info fa-stack-1x"></i>
        </span>
      </button>
    </div>
  );
}

function join(components, getSeparator) {
  const newComponents = [];
  components.forEach((component, i) => {
    if (i !== 0) newComponents.push(getSeparator('SEPARATOR' + i));
    newComponents.push(component);
  });
  return newComponents;
}
