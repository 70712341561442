import {useCallback, useState} from 'react';

/**
 * Used for marking the validity of form data.
 *
 * The record should contain an entry for each property in the form.
 *
 * The value is true (meaning valid) or a string (describing why the field is not valid)
 *
 */
type Validation<Keys extends keyof any> = Record<Keys, true | string>;

export default Validation;

/**
 * When the validation is equal to `undefined`, no validation has been performed.
 *
 * When the validation has a value, a validation has been performed, and each property represents the status of that field in the form
 *
 */
export function useValidation<Key extends keyof any, V = Validation<Key>>() {
  const [validation, setValidation] = useState<Partial<V>>();

  const setAndCheckValidation = useCallback(
    <AllowPartial extends boolean = false, NewValidation = AllowPartial extends true ? Partial<V> : V>(
      validationOrCallback: NewValidation | ((oldValidation: Partial<V> | undefined) => NewValidation),
    ) => {
      setValidation(
        oldValidation =>
          ({
            ...oldValidation,
            ...(validationOrCallback instanceof Function ? validationOrCallback(oldValidation) : validationOrCallback),
          } as never),
      );
      const values = Object.values(validationOrCallback as never);
      const shouldContinue = !values.find(value => typeof value === 'string');
      console.log('setAndCheckValidation', {validation: validationOrCallback, shouldContinue});
      return shouldContinue;
    },
    [],
  );

  return {validation, setAndCheckValidation};
}
