"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tripLogListForObjectId = exports.tripLogRead = exports.tripLogCreate = exports.PATH = void 0;
const TripLog_1 = require("./../models/TripLog");
exports.PATH = 'trip-logs';
function tripLogCreate(log, firestore) {
    return firestore.create(exports.PATH, log);
}
exports.tripLogCreate = tripLogCreate;
async function tripLogRead(id, firestore) {
    const result = await firestore.read(exports.PATH, id);
    return (0, TripLog_1.tripLogDeserialize)(result.value, result.id);
}
exports.tripLogRead = tripLogRead;
async function tripLogListForObjectId(id, firestore) {
    const results = await firestore.list(exports.PATH, {
        where: [['objectId', '==', id]],
    });
    return results.map(result => (0, TripLog_1.tripLogDeserialize)(result.value, result.id));
}
exports.tripLogListForObjectId = tripLogListForObjectId;
