import HttpReference from '../platform/HttpReference';
import Token from './Token';

class Streaming extends HttpReference {
  constructor(path, parent) {
    super(path, parent);

    this.token = new Token('token', this);
  }
}

export default Streaming;
