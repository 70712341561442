import Enum from '../base/Enum';

class ControlledSubstance extends Enum {
  get label() {
    switch (this) {
      case ControlledSubstance.alcohol:
        return 'Alcohol';
      case ControlledSubstance.tobacco:
        return 'Tobacco';
      case ControlledSubstance.marijuana:
        return 'Marijuana';
    }
  }
}

ControlledSubstance.alcohol = new ControlledSubstance('ALCOHOL');
ControlledSubstance.tobacco = new ControlledSubstance('TOBACCO');
ControlledSubstance.marijuana = new ControlledSubstance('MARIJUANA');

export default ControlledSubstance;
