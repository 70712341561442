import React, {useContext, useEffect, useMemo, useState} from 'react';
import Alert from '../../components/Alert/Alert';
import FontAwesome from '../../components/FontAwesome';
import {useAlert} from '../../contexts/AlertContext';
import useResult from '../../hooks/useResult';
import useStatus, {StatusButton} from '../../hooks/useStatus';
import VendorApplicationModel from '../../models/scoopm/VendorApplication';
import Price, {priceDescription} from '../../models/stripe/Price';
import {AxiosResponseWithModel} from '../../references/platform/HttpReference';
import ScoopMApi from '../../references/scoopm-api';
import {AdditionalContentComponentProps} from '../types/Schema';

export default function VendorApplication(props: AdditionalContentComponentProps<VendorApplicationModel>) {
  const {setAlert} = useAlert();
  const {status, handlePromise} = useStatus<AxiosResponseWithModel<VendorApplicationModel>>();

  function onClick() {
    let result = {value: ''};
    setAlert(
      new Alert(
        'Select price',
        <PriceSelection result={result} />,
        undefined,
        async () => {
          if (!result.value) {
            throw new Error('Please select a price before continuing');
          }
          await handlePromise(ScoopMApi.instance.vendorApplications.child(props.id).approve(result.value));
        },
        'Cancel',
      ),
    );
  }

  return (
    <div className="p-2 p-md-3">
      <h4>Status</h4>

      {props.model?.vendorId ? (
        <span className="text-success">
          <FontAwesome.CheckSolid /> Approved
        </span>
      ) : (
        <StatusButton status={status} onClick={onClick}>
          Approve
        </StatusButton>
      )}
      <p>
        <small>
          {props.model?.vendorId
            ? 'This application has been approved, and a corresponding Vendor has been created'
            : 'Approving this application will create a corresponding "vendor". It will also create a "user group" if the "Submitted by" field is set'}
        </small>
      </p>
    </div>
  );
}

function PriceSelection(props: {result: {value: string}}) {
  // the "result" object is a way to pass the state back to the alert

  const [priceId, setPriceId] = useState('');

  const promise = useMemo(() => ScoopMApi.instance.stripe.prices.deliverySubscription.get(), []);
  const result = useResult(promise);

  useEffect(() => {
    props.result.value = priceId;
  }, [props.result, priceId]);

  return (
    <div>
      <p>Select the starting price for the vendor's subscription. This can be changed later</p>
      <select value={priceId} onChange={event => setPriceId(event.target.value)} className="form-control">
        {result === null && <option value="">Loading...</option>}

        {result?.error && <option value="">Unable to load prices</option>}

        {result?.value && <option value="">Select</option>}
        {result?.value?.data?.data.map((price: Price) => (
          <option key={price.id} value={price.id}>
            {priceDescription(price)}
          </option>
        ))}
      </select>
    </div>
  );
}
