import StripePage from '../../../models/stripe/StripePage';
import SubscriptionModel from '../../../models/stripe/Subscription';
import HttpReference from '../../platform/HttpReference';
import Stripe from './Stripe';
import Subscription from './Subscription';

export default class Subscriptions extends HttpReference<StripePage<Array<SubscriptionModel>>, Subscription, Stripe> {
  get childReferenceType() {
    return Subscription;
  }
}
