import omit from 'lodash/omit';
import pick from 'lodash/pick';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {TripLocation} from 'wave-common';
import Trip, {
  tripLocationsWithDefault,
  TRIP_LOCATIONS_WITH_DEFAULT_PICKED_PROPERTIES,
} from 'wave-common/lib/models/Trip';
import ClassBasedTrip from '../../../models/scoopm/Trip';
import {TripSource} from '../../../models/scoopm/TripSource';

const PROPERTIES_USED = [
  'contacts',
  'orderValue',
  'orderId',
  'tip',
  'type',
  'vendorId',
  'vendorName',
  ...TRIP_LOCATIONS_WITH_DEFAULT_PICKED_PROPERTIES,
] as const;

export default function DuplicateTripLink({
  trip,
  className = 'btn btn-outline-secondary',
  children = 'Duplicate',
}: {
  trip: Pick<Trip, typeof PROPERTIES_USED[number]> | undefined;
  className?: string;
  children?: React.ReactNode;
}) {
  // url

  const url = useMemo(() => {
    if (!trip) {
      return undefined;
    }

    // pick properties from the current trip

    const partialTrip: Partial<Trip> = pick(trip, ...PROPERTIES_USED);

    // remove un-needed stuff from locations

    partialTrip.locations = tripLocationsWithDefault(trip).map((location: TripLocation) =>
      omit(
        location,
        'requestedTimestampMs',
        'estimatedArrivalTimestampMs',
        'arrivalTimestampMs',
        'completionTimestampMs',
      ),
    );

    // get a "standard" manual entry trip

    const tripForManualEntry = ClassBasedTrip.forManualEntry(TripSource.adminPortal);

    // combine the two

    const combinedTrip = {...tripForManualEntry, ...partialTrip};

    // encode the combined trip

    const jobJson = encodeURIComponent(JSON.stringify(combinedTrip));

    return `/admin/jobs/create?job=${jobJson}`;
  }, [trip]);

  // render

  return (
    <Link to={url ?? '#select-job-first'} className={className}>
      {children}
    </Link>
  );
}
