import first from 'lodash/first';
import React, {useCallback, useEffect, useState} from 'react';
import {FormControl} from 'react-bootstrap';
import {useStatus} from 'wave-common';
import {
  fountainApplicantTokenCreate,
  fountainApplicantTokenListForApplicantId,
} from 'wave-common/lib/controllers/FountainApplicantToken';
import Card from '../../../components/Card';
import CopyButton from '../../../components/CopyButton';
import FontAwesome from '../../../components/FontAwesome';
import {DelaySpinner} from '../../../components/Spinner';
import FirestoreDataSource from '../../../data-sources/FirestoreDataSource';
import {environmentVariable} from '../../../Environment';

export default function GetSignUpLink() {
  // state

  const [fountainUrl, setFountainUrl] = useState<string>('');
  const {isPending, error, value: url, handlePromise} = useStatus<string>(React as any, undefined, true);

  // callback: on submit

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      async function hanlder() {
        // get applicant id from url

        const string = fountainUrl.trim();
        let url: URL;
        try {
          url = new URL(string);
        } catch (error) {
          throw new Error('Invalid URL');
        }
        const applicantId = url.searchParams.get('applicantId');
        if (!applicantId) {
          throw new Error("That URL doesn't contain the applicant ID");
        }

        // fetch tokens

        const tokens = await fountainApplicantTokenListForApplicantId(applicantId, FirestoreDataSource).catch(() => {
          throw new Error('Unable to get link');
        });
        let tokenId = first(tokens)?.id;

        // if no tokens, create one now

        if (!tokens.length) {
          // create a token

          const result = await fountainApplicantTokenCreate({applicantId: applicantId}, FirestoreDataSource);
          tokenId = result.id;
        }

        // create url

        return `${environmentVariable('URL')}/driver-sign-up?token=${tokenId}`;
      }

      handlePromise(hanlder());
    },
    [fountainUrl, handlePromise],
  );

  // effect: select input after it loads

  useEffect(() => {
    setTimeout(() => {
      (document.getElementById('fountain-url-input') as HTMLInputElement).select();
    }, 100);
  }, []);

  // render

  const getLinkContent = <>Get link</>;

  return (
    <Card padded>
      <div className="row">
        {/* column for everything but image */}

        <div className="col">
          <h3>Sign-up link</h3>

          <p>
            When a Fountain applicant is approved, the system emails them a secure sign-up link, to set up their Wave
            driver account. If they can't find the email, use this page to get it.
          </p>

          <ol>
            <li>
              Go to{' '}
              <a
                href="https://app.fountain.com/wave-mobile-technologies/applicants?page=1&per_page=20"
                target="_blank"
                rel="noreferrer"
                className="text-decoration-underline">
                Fountain <FontAwesome.ExternalLinkAlt />
              </a>
              , search for the applicant, and open their details (see screenshot)
            </li>
            <li>Copy the URL of that page</li>
            <li>Paste it here</li>
          </ol>

          {/* form */}

          <form onSubmit={onSubmit}>
            <div className="form-row">
              <div className="col">
                <FormControl
                  id="fountain-url-input"
                  value={fountainUrl}
                  onChange={event => setFountainUrl(event.target.value)}
                  placeholder="Fountain URL"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                />
              </div>
              <div className="col-auto">
                <button className="btn btn-primary" disabled={!fountainUrl || isPending}>
                  {isPending ? <DelaySpinner childrenBeforeTimeout={getLinkContent} small /> : getLinkContent}
                </button>
              </div>
            </div>
          </form>

          {/* results */}

          {error ? (
            <div className="alert alert-danger mb-0 mt-2 mt-md-3">
              <div className="row align-items-center">
                <div className="col-auto">
                  <FontAwesome.ExclamationCircle />
                </div>
                <div className="col-auto" dangerouslySetInnerHTML={{__html: error.message}} />
              </div>
            </div>
          ) : (
            url && (
              <div className="alert alert-primary mb-0 mt-2 mt-md-3">
                <strong>Here's the link</strong>
                <hr />
                <div className="row align-items-center">
                  <div className="col">{url}</div>
                  <div className="col-auto">
                    <CopyButton text={url} className="btn btn-primary ml-2" />
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        {/* image column */}

        <div className="col-lg-6">
          <img
            src="/images/fountain-applicant-details-screenshot.jpg"
            alt="Screenshot of Fountain applicant details page"
            className="mw-100"
          />
        </div>
      </div>
    </Card>
  );
}
