import moment from 'moment';
import React from 'react';
import {TripLocation} from 'wave-common';
import FontAwesome from '../../components/FontAwesome';
import joinClassNames from '../../functions/joinClassNames';
import unwrap from '../../functions/unwrap';
import MapPin3FillBlue from '../../images/MapPin3FillBlue';
import MapPin3FillPink from '../../images/MapPin3FillPink';
import PositionInList from '../../models/scoopm/PositionInList';

export function LocationCard({
  location,
  locationsCount,
  positionInList,
}: {
  location: TripLocation;
  locationsCount: number;
  positionInList: PositionInList;
}) {
  return (
    <>
      <div className="row">
        <div className="col-auto d-flex flex-column align-items-center py-1">
          {positionInList === PositionInList.last ? <MapPin3FillPink /> : <MapPin3FillBlue />}
          {positionInList !== PositionInList.last && (
            <div style={{width: 1, height: '100%', borderRight: '1px dashed #AAAAAA'}} className="mt-2 flex-grow" />
          )}
        </div>

        <div className={joinClassNames('col', positionInList !== PositionInList.last && 'mb-4')}>
          {/* position in list */}

          <small className="text-gray-600">
            {unwrap(positionInList, position => (
              <span>
                {position.locationName(locationsCount)}
                {unwrap(location, location =>
                  unwrap(
                    location.scheduledTimestampMs,
                    timestamp => moment(timestamp).calendar(),
                    // <span> &middot; {moment(timestamp).calendar()}</span>
                  ),
                )}
              </span>
            ))}
          </small>

          {/* name */}

          <h6 className="mb-0">{location ? location.name || location.address || 'GPS Coordinates' : ' '}</h6>

          {/* address */}

          {location && location.name && location.address && !location.address.startsWith(location.name) && (
            <div className="mt-1 mb-0 text-muted">{location.address}</div>
          )}

          {/* wait time */}

          {location && location.waitTimeS && (
            <div className="mt-2">
              <FontAwesome.Clock /> {moment.duration(location.waitTimeS, 'seconds').humanize()}
            </div>
          )}

          {/* instructions */}

          {location && unwrap(location.instructions, instructions => <p className="mt-2 mb-0">{instructions}</p>)}
        </div>
      </div>
    </>
  );
}
