import React, {ReactNode} from 'react';

export default function MenuSection(props: {title: string; children: ReactNode}) {
  return (
    <div>
      <div className="mb-1">{props.title}</div>
      {props.children}
    </div>
  );
}
