import HttpReference from '../../platform/HttpReference';
import AccountLinks from './AccountLinks';
import BillingPortal from './BillingPortal';
import Checkout from './Checkout';
import Customers from './Customers';
import PaymentIntents from './PaymentIntents';
import Payouts from './Payouts';
import Products from './Products';
import StripePrices from './StripePrices';
import Subscriptions from './Subscriptions';

export default class Stripe extends HttpReference {
  constructor(path, parent) {
    super(path, parent);

    this.accountLinks = new AccountLinks('account-links', this);
    this.billingPortal = new BillingPortal('billing-portal', this);
    this.checkout = new Checkout('checkout', this);
    this.customers = new Customers('customers', this);
    this.paymentIntents = new PaymentIntents('payment-intents', this);
    this.payouts = new Payouts('payouts', this);
    this.prices = new StripePrices('prices', this);
    this.products = new Products('products', this);
    this.subscriptions = new Subscriptions('subscriptions', this);
  }
}
