import sortBy from 'lodash/sortBy';
import React from 'react';
import Destination from 'wave-common/lib/models/Destination';
import {destinationCategoryAll, destinationCategoryTitle} from 'wave-common/lib/models/DestinationCategory';
import Place from 'wave-common/lib/models/Place';
import {zoneDescription} from 'wave-common/lib/models/Zone';
import DropdownSelect from '../../components/DropdownSelect';
import joinClassNames from '../../functions/joinClassNames';
import SelectOption from '../../models/SelectOption';
import PropertyInputProps from './PropertyInputProps';

export default function DestinationInput({
  id,
  model,
  value: destination,
  onChange,
  validationError,
}: PropertyInputProps<Destination, Place>) {
  return (
    <div className={joinClassNames(validationError && 'rounded border border-danger')}>
      <div className="row">
        <div className="col">
          <label htmlFor={id + 'nameInput'}>Name</label>
          <input
            id={id + 'nameInput'}
            type="text"
            placeholder="Name"
            className="form-control"
            value={destination?.name ?? ''}
            onChange={event => onChange({...destination, name: event.target.value})}
          />
        </div>
        <div className="col-auto">
          <label className="d-block">Zones</label>
          <DropdownSelect
            id={id}
            options={sortBy(model.zones, 'name').map(zone => new SelectOption(zone.id, zoneDescription(zone)))}
            selectedValues={destination?.zoneIds ?? []}
            onChange={zoneIds => onChange({...destination, zoneIds})}
            onlyShowSelectionCount
          />
        </div>
        <div className="col-auto">
          <label className="d-block">Category</label>
          <select
            value={destination?.category ?? ''}
            onChange={event => onChange({...destination, category: event.target.value})}
            className="custom-select">
            <option value="">None</option>
            {destinationCategoryAll.map(category => (
              <option key={category} value={category}>
                {destinationCategoryTitle(category)}
              </option>
            ))}
          </select>
        </div>
        {/* {validationError && <div className="text-danger">Invalid</div>} */}
      </div>
    </div>
  );
}
