import firebase from 'firebase/app';
import React, {useMemo} from 'react';
import Card from '../../../components/Card';
import ViewInFirebase from '../../../components/ViewInFirebase';
import unwrap from '../../../functions/unwrap';
import useFirestoreGet from '../../../hooks/useFirestoreGet';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';
import TripType from '../../../models/scoopm/TripType';

export default function TripTypes() {
  // state

  const tripTypeDetails = useRealtimeDatabase({path: '/trip-type-details'});

  const invoiceFormulasReference = useMemo(() => firebase.firestore().collection('invoice-formulas'), []);
  const invoiceFormulas = useFirestoreGet(invoiceFormulasReference);

  const payoutFormulasReference = useMemo(() => firebase.firestore().collection('payout-formulas'), []);
  const payoutFormulas = useFirestoreGet(payoutFormulasReference);

  // callbacks

  const onChangeInvoiceFormulaId = type => event => {
    event.preventDefault();

    tripTypeDetails.reference
      .child(type.rawValue)
      .update({invoiceFormulaId: event.target.value || null})
      .catch(error => {
        window.alert(`There was an error. ${error.message}`);
      });
  };

  const onChangePayoutFormulaId = type => event => {
    event.preventDefault();

    tripTypeDetails.reference
      .child(type.rawValue)
      .update({payoutFormulaId: event.target.value || null})
      .catch(error => {
        window.alert(`There was an error. ${error.message}`);
      });
  };

  // render

  if (tripTypeDetails.isLoading) {
    return null;
  }

  if (tripTypeDetails.error) {
    return 'Error';
  }

  if (!tripTypeDetails.data) {
    return 'Unable to read data';
  }

  return (
    <>
      <Card padded>
        <h3>Trip types</h3>

        {TripType.allCases.map(type => {
          const details = tripTypeDetails.data[type.rawValue];

          return (
            <div key={type.rawValue}>
              <hr className="mt-0" />

              <div className="row">
                <div className="col">
                  <h5>{type.titleWithEmoji}</h5>
                </div>

                <div className="col">
                  <div className="form-group">
                    <label htmlFor={type.rawValue + 'InvoiceFormulaSelect'}>Invoice formula</label>
                    <select
                      id={type.rawValue + 'InvoiceFormulaSelect'}
                      value={unwrap(details, details => details.invoiceFormulaId)}
                      onChange={onChangeInvoiceFormulaId(type)}
                      className="custom-select"
                      disabled={!invoiceFormulas.snapshot}>
                      <option value="">---</option>
                      {unwrap(invoiceFormulas.snapshot, snapshot =>
                        snapshot.docs.map(doc => (
                          <option key={doc.id} value={doc.id}>
                            {doc.get('name')}
                          </option>
                        )),
                      )}
                    </select>
                  </div>
                </div>

                <div className="col">
                  <div className="form-group">
                    <label htmlFor={type.rawValue + 'PayoutFormulaSelect'}>Payout formula</label>
                    <select
                      id={type.rawValue + 'PayoutFormulaSelect'}
                      value={unwrap(details, details => details.payoutFormulaId)}
                      onChange={onChangePayoutFormulaId(type)}
                      className="custom-select"
                      disabled={!payoutFormulas.snapshot}>
                      <option value="">---</option>
                      {unwrap(payoutFormulas.snapshot, snapshot =>
                        snapshot.docs.map(doc => (
                          <option key={doc.id} value={doc.id}>
                            {doc.get('name')}
                          </option>
                        )),
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Card>

      <ViewInFirebase path={'/trip-type-details'} />
    </>
  );
}
