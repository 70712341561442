import React from 'react';
import ReactDatePicker from 'react-datepicker';
import joinClassNames from '../../functions/joinClassNames';
import unwrap from '../../functions/unwrap';
import Property from '../types/Property';

export default function TimestampInput(props: {
  property: Property;
  id: string;
  value: any;
  onChange: (newValue: any) => void;
  disabled: boolean;
  validationError?: Error;
}) {
  return (
    <ReactDatePicker
      id={`datePicker-${props.id}`}
      selected={unwrap(props.value, ts => new Date(ts))}
      onChange={date => props.onChange(date?.valueOf())}
      dateFormat="Pp"
      className={joinClassNames(
        'form-control',
        unwrap(props.validationError, error => (error ? ' is-invalid' : ' is-valid')),
      )}
      showTimeSelect
      placeholderText={undefined}
      disabled={props.disabled}
    />
  );
}
