import firebase from 'firebase/app';
import React, {useEffect, useState} from 'react';
import EditableInput from '../../../components/Forms/EditableInput';
import Map from '../../../components/GoogleMaps/GoogleMap';
import MapContents from '../../../components/GoogleMaps/MapContents';
import ViewInFirebase from '../../../components/ViewInFirebase';
import unwrap from '../../../functions/unwrap';
import usePrevious from '../../../hooks/usePrevious';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';

const postalCodesStringToArray = string => {
  const array = string
    .split(',')
    .map(code => code.trim())
    .filter(Boolean);
  const uniqueValues = [...new Set(array)];
  return uniqueValues;
};

const ServiceAreaPanel = ({apiUserName}) => {
  const [mapCenter, setMapCenter] = useState();

  const serviceArea = useRealtimeDatabase({
    path: `/service-areas/${apiUserName}`,
  });
  const previousServiceArea = usePrevious(serviceArea);

  const [isEnabled, setIsEnabled] = useState();
  const [isAvailableIsPending, setIsAvailableIsPending] = useState(false);

  const [postalCodesString, setPostalCodesString] = useState('');
  const [postalCodesHaveChanged, setPostalCodesHaveChanged] = useState(false);
  const [postalCodesAreValid, setPostalCodesAreValid] = useState(true);
  const [postalCodesIsPending, setPostalCodesIsPending] = useState(false);

  const [circles, setCircles] = useState(null);
  const [selectedCircleIndex, setSelectedCircleIndex] = useState(null);

  const [mapContents, setMapContents] = useState();

  // service area -> postal codes, isEnabled

  useEffect(() => {
    setPostalCodesString(
      unwrap(serviceArea.data, data => unwrap(data.postalCodes, postalCodes => postalCodes.join(', '))) || '',
    );
    setIsEnabled(serviceArea.data ? serviceArea.data.isEnabled : undefined);
  }, [serviceArea.data]);

  // service area, api username -> map contents

  useEffect(() => {
    if (serviceArea.key !== previousServiceArea.key) {
      if (serviceArea.data && serviceArea.data.circles) {
        // if (serviceArea.key === previousServiceArea.key) return
        let bounds = new window.google.maps.LatLngBounds();
        Object.values(serviceArea.data.circles).forEach(circle => {
          const googleCircle = new window.google.maps.Circle({
            center: {
              lat: circle.center.latitude,
              lng: circle.center.longitude,
            },
            radius: circle.radiusKm * 1000,
          });
          bounds = bounds.union(googleCircle.getBounds());
        });
        setMapContents(
          new MapContents.Bounds({
            id: 'service-areas',
            latLngBounds: bounds,
            name: 'All geofences',
          }),
        );
      } else {
        setMapContents(null);
      }
    }
  }, [serviceArea, previousServiceArea]);

  // service area data, selected circle index -> circles

  useEffect(() => {
    if (serviceArea.data) {
      const circles = unwrap(serviceArea.data.circles, circles =>
        circles.map((circle, i) => ({
          id: `${apiUserName}-${circles.length}-${i}`, // this is a way to keep ID's consistent (for map bounds updates / zooming) except when items are removed or added
          center: {lat: circle.center.latitude, lng: circle.center.longitude},
          radiusM: circle.radiusKm * 1000,
          editable: i === selectedCircleIndex,
          onRadiusChanged: radiusM => {
            // update db
            const radiusKm = radiusM / 1000;
            console.log(`Changing radius of circle #${i} to ${radiusKm}km...`);
            firebase
              .database()
              .ref('service-areas')
              .child(apiUserName)
              .child('circles')
              .child(i)
              .child('radiusKm')
              .set(radiusKm)
              .then(
                () => {
                  console.info(`Changed radius of circle #${i}`);
                },
                error => {
                  console.error(`Error changing radius of circle #${i}`, error);
                },
              );
          },
          onCenterChanged: center => {
            console.log(`Changing center of circle #${i} to ${center}...`);
            firebase
              .database()
              .ref('service-areas')
              .child(apiUserName)
              .child('circles')
              .child(i)
              .child('center')
              .set({
                latitude: center.lat(),
                longitude: center.lng(),
              })
              .then(
                () => {
                  console.info(`Changed center of circle #${i}`);
                },
                error => {
                  console.error(`Error changing center of circle #${i}`, error);
                },
              );
          },
          onClick: () => setSelectedCircleIndex(i),
          strokeColor: serviceArea.data.isEnabled ? undefined : '#636363',
          fillColor: serviceArea.data.isEnabled ? undefined : '#636363',
        })),
      );
      setCircles(circles);
    } else {
      setCircles(null);
    }
  }, [serviceArea.data, selectedCircleIndex, apiUserName]);

  // postal codes -> validate

  useEffect(() => {
    if (postalCodesString === '') return;

    const invalidCode = postalCodesStringToArray(postalCodesString).find(code => !code.match(/^[a-z0-9 ]+$/i));

    if (invalidCode) {
      setPostalCodesAreValid(false);
    } else {
      setPostalCodesAreValid(true);
    }
  }, [postalCodesString]);

  // on change: service area switch

  const onServiceAreaSwitchChange = event => {
    const isEnabled = event.target.checked;

    setIsEnabled(isEnabled);

    console.log('Setting isEnabled...');
    setIsAvailableIsPending(true);

    firebase
      .database()
      .ref('service-areas')
      .child(apiUserName)
      .update({isEnabled})
      .then(
        () => {
          console.log('Set isEnabled');
          setIsAvailableIsPending(false);
        },
        error => {
          console.error('Error setting isEnabled', error);
          setIsAvailableIsPending(false);
          setIsEnabled(!isEnabled); // reset state
        },
      );
  };

  // ON: change postal codes textarea

  const onPostalCodesTextareaChange = event => {
    event.preventDefault();

    setPostalCodesHaveChanged(true);
    setPostalCodesString(event.target.value);
  };

  // ON: submit postal codes form

  const onSubmitPostalCodesForm = event => {
    event.preventDefault();

    if (!postalCodesAreValid) return;

    console.log('Setting postal codes...');
    setPostalCodesIsPending(true);

    firebase
      .database()
      .ref('service-areas')
      .child(apiUserName)
      .update({
        postalCodes: postalCodesStringToArray(postalCodesString),
      })
      .then(
        () => {
          console.log('Set postal codes');
          setPostalCodesIsPending(false);
          setPostalCodesHaveChanged(false);
        },
        error => {
          console.error('Error setting postal codes', error);
          setPostalCodesIsPending(false);
        },
      );
  };

  // ON: click "add circle"

  const onClickAddCircle = event => {
    event.preventDefault();

    const circle = {
      center: {
        latitude: mapCenter.lat(),
        longitude: mapCenter.lng(),
      },
      radiusKm: 10,
    };

    const index = circles ? circles.length : 0;

    console.log(`Adding circle #${index}...`);
    firebase
      .database()
      .ref('service-areas')
      .child(apiUserName)
      .child('circles')
      .child(index)
      .set(circle)
      .then(
        () => {
          console.info(`Changed center of circle #${index}`);
        },
        error => {
          console.error(`Error changing center of circle #${index}`, error);
        },
      );
  };

  // ON: click "remove circle"

  const onClickRemoveCircle = event => {
    event.preventDefault();

    if (!window.confirm(`Delete this circle?`)) {
      return;
    }

    const newCircles = [...serviceArea.data.circles];
    newCircles.splice(selectedCircleIndex, 1).filter(Boolean);

    setSelectedCircleIndex(null);

    console.log(`Removing circle #${selectedCircleIndex}...`);
    firebase
      .database()
      .ref('service-areas')
      .child(apiUserName)
      .child('circles')
      .set(newCircles)
      .then(
        () => {
          console.info(`Removed center of circle #${selectedCircleIndex}`);
        },
        error => {
          console.error(`Error removing center of circle #${selectedCircleIndex}`, error);
        },
      );
  };

  // ON: change editable input

  const onChangeEditableInput =
    path =>
    ({done, name, value}) => {
      const numberValue = Number(value);
      if (isNaN(numberValue) || numberValue < 0) {
        done(false);
        return;
      }
      serviceArea.update({[path]: numberValue}).then(done, () => done(false));
    };

  // render

  const circle = unwrap(serviceArea.data, data => unwrap(data.circles, circles => circles[selectedCircleIndex]));
  const coordinatesValue = unwrap(
    circle,
    circle =>
      `(${Math.round(circle.center.latitude * 10000) / 10000}, ${Math.round(circle.center.longitude * 10000) / 10000})`,
  );
  const radiusValue = unwrap(
    circle,
    circle => `${Intl.NumberFormat('en-US', {style: 'decimal'}).format(Math.round(circle.radiusKm))}km`,
  );

  return (
    <>
      <h5>General</h5>
      <div className="d-flex align-items-center mb-2 mb-md-3">
        <div className="custom-control custom-switch">
          <input
            id={serviceArea.key + 'ServiceAreaSwitch'}
            type="checkbox"
            onChange={onServiceAreaSwitchChange}
            className="custom-control-input"
            checked={Boolean(isEnabled)}
            disabled={serviceArea.isLoading || serviceArea.error || isAvailableIsPending}
          />
          <label
            id={serviceArea.key + 'ServiceAreaSwitch'}
            htmlFor={serviceArea.key + 'ServiceAreaSwitch'}
            className="custom-control-label">
            {isEnabled ? 'Allow' : "Don't allow"} deliveries for all areas below
          </label>
        </div>
      </div>

      <label htmlFor="postalEstimatedDriverCount">Estimated number of drivers, for any order outside the circles</label>
      <EditableInput
        id="postalEstimatedDriverCount"
        name="postalEstimatedDriverCount"
        value={unwrap(serviceArea.data, data => data.estimatedDriverCount)}
        onChange={onChangeEditableInput(`estimatedDriverCount`)}
        disabled={serviceArea.isLoading}
        type="number"
        noInline
      />

      <hr />

      <div className="row mb-3 align-items-center">
        <div className="col">
          <h5>Geographic areas</h5>
        </div>
        <div className="col-auto">
          <button onClick={onClickAddCircle} className="btn btn-primary">
            <i className="fas fa-plus mr-1" />
            Add
          </button>
        </div>
      </div>
      <div style={{height: '500px'}} className="border rounded">
        <Map
          onCenterChanged={setMapCenter}
          circles={circles}
          mapContents={mapContents}
          mapTypeControl={false}
          streetViewControl={false}
          rotateControl={false}
          fullscreenControl={false}
        />
      </div>
      <div className="p-2 p-md-3 bg-gray-200">
        <div className="row mb-2">
          <div className="col">
            <div className={selectedCircleIndex !== null ? '' : ' text-muted'}>
              Coordinates: {coordinatesValue}
              <br />
              Radius: {radiusValue}
            </div>
          </div>
          <div className="col-auto">
            <button onClick={onClickRemoveCircle} className={'btn btn-danger'} disabled={selectedCircleIndex === null}>
              <i className="fas fa-trash" /> Delete
            </button>
          </div>
        </div>
        <label htmlFor="estimatedDriverCount">Estimated number of drivers{!circle && ' (no circle selected)'}</label>
        <EditableInput
          id="estimatedDriverCount"
          name="estimatedDriverCount"
          value={unwrap(circle, circle => circle.estimatedDriverCount)}
          onChange={onChangeEditableInput(`circles/${selectedCircleIndex}/estimatedDriverCount`)}
          disabled={!circle}
          type="number"
          noInline
        />
      </div>
      {/* <div>
                    <label htmlFor="estimatedTimeToPickupS">Estimated travel time to pickup in <strong>minutes</strong></label>
                    <EditableInput
                        id="estimatedTimeToPickupS"
                        name="estimatedTimeToPickupS"
                        value={unwrap(circle, circle => unwrap(circle.estimatedTimeToPickupS, seconds => seconds / 60))}
                        onChange={({ done, value }) => onChangeEditableInput(`circles/${selectedCircleIndex}/estimatedTimeToPickupS`)({ done, value: value * 60 })}
                        disabled={!circle}
                        type="number"
                        noInline
                    />
                </div> */}

      <hr />

      <h5>Postal codes</h5>
      <form onSubmit={onSubmitPostalCodesForm}>
        <div className="mb-2">
          <textarea
            rows="5"
            value={postalCodesString}
            onChange={onPostalCodesTextareaChange}
            className="form-control"
            placeholder="Type here..."
            disabled={serviceArea.isLoading || postalCodesIsPending}
          />
        </div>
        <div className="row d-flex justify-content-between">
          <div className="col">
            <small className="text-muted">Separate items with a comma. Spaces have no effect</small>
          </div>
          <div className="col-auto">{!postalCodesAreValid && <span className="text-danger">Invalid data</span>}</div>
          <div className="col-auto">
            <button
              className="btn btn-secondary"
              disabled={!postalCodesHaveChanged || !postalCodesAreValid || postalCodesIsPending}>
              Save
            </button>
          </div>
        </div>
      </form>
      {/* <div>
                    <label htmlFor="postalEstimatedTimeToPickupS">Estimated travel time to pickup in <strong>minutes</strong>, for any order outside the circles</label>
                    <EditableInput
                        id="postalEstimatedTimeToPickupS"
                        name="postalEstimatedTimeToPickupS"
                        value={unwrap(serviceArea.data, data => unwrap(data.estimatedTimeToPickupS, seconds => seconds / 60))}
                        onChange={({ done, value }) => onChangeEditableInput('estimatedTimeToPickupS')({ done, value: value * 60 })}
                        disabled={serviceArea.isLoading}
                        type="number"
                        noInline
                    />
                </div> */}

      <hr />

      <ViewInFirebase path={`/service-areas/${apiUserName}`} />
    </>
  );
};

export default ServiceAreaPanel;
