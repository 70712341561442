import first from 'lodash/first';
import React, {useEffect} from 'react';
import {useUserGroupVendors} from '../../hooks/useUserGroupVendor';
import {useSelectedVendorId} from '../../stores/localStorageStore';

export default function VendorSwitcher({
  fullWidth = false,
  showAlways = false,
}: {
  fullWidth?: boolean;
  showAlways?: boolean;
}) {
  const {isLoading, error, vendors} = useUserGroupVendors();
  const [selectedVendorId, setSelectedVendorId] = useSelectedVendorId();

  useEffect(() => {
    if (!selectedVendorId) {
      const id = first(vendors)?.id;
      if (id) {
        setSelectedVendorId(id);
      }
    }
  }, [selectedVendorId, vendors, setSelectedVendorId]);

  if (isLoading || error || !vendors) {
    return null;
  }

  if (!showAlways && vendors.length < 2) {
    return null;
  } else
    return (
      <div className="nav-item">
        <select
          value={selectedVendorId ?? ''}
          onChange={event => setSelectedVendorId(event.target.value)}
          className="custom-select"
          style={fullWidth ? undefined : {width: '10rem'}}>
          <option value="" disabled>
            - My Teams -
          </option>
          {vendors.map(vendor => (
            <option key={vendor.id} value={vendor.id}>
              {vendor.name}
            </option>
          ))}
        </select>
      </div>
    );
}
