export var CommunicationType = {
  phoneCall: {rawValue: 'PHONE_CALL', description: 'phone call'},
  email: {rawValue: 'EMAIL', description: 'email'},
};

export var ReverseCommunicationType = {
  PHONE_CALL: 'phoneCall',
  EMAIL: 'email',
};

export class Communication {
  /**
   * Constructor
   * @param {Communication.type} type Type
   */
  constructor(type) {
    this.type = type.rawValue;
    this.timestamp = Math.round(new Date().valueOf() / 1000);
  }
}
