import React, {useEffect, useState} from 'react';
import EditableInput from '../../../components/Forms/EditableInput';
import unwrap from '../../../functions/unwrap';

export default function ControlDetails({geofenceData, onUpdate, onClickDelete}) {
  const [coordinatesString, setCoordinatesString] = useState();
  const [radiusString, setRadiusString] = useState();

  useEffect(() => {
    setCoordinatesString(
      unwrap(
        geofenceData,
        geofenceData =>
          `(${Math.round(geofenceData.centerCoordinate[0] * 10000) / 10000}, ${
            Math.round(geofenceData.centerCoordinate[1] * 10000) / 10000
          })`,
      ),
    );
    setRadiusString(
      unwrap(
        geofenceData,
        geofenceData =>
          `${Intl.NumberFormat('en-US', {style: 'decimal'}).format(Math.round(geofenceData.radius) / 1000)}km`,
      ),
    );
  }, [geofenceData]);

  const onChangeEditableInput =
    key =>
    ({name, value, done}) => {
      onUpdate(key, value).then(done, () => done(false));
    };

  const onChangeSwitch = event => {
    const {checked} = event.target;
    onUpdate('canRequestRides', checked);
  };

  return (
    <div className="bg-light rounded p-2 p-md-3">
      <div className="row mb-2">
        <div className="col">
          {geofenceData ? (
            <p>
              <span>Coordinates: {coordinatesString}</span>
              <br />
              <span>Radius: {radiusString}</span>
            </p>
          ) : (
            <h5 className="mb-0">Click a geofence to select it</h5>
          )}
        </div>
        <div className="col-auto">
          <button onClick={onClickDelete} className="btn btn-danger" disabled={!geofenceData}>
            <i className="fas fa-trash" /> Delete
          </button>
        </div>
      </div>

      <div className="row mb-2 d-flex align-items-center">
        <div className="col-6 col-md-3">Name (just for convenience)</div>
        <div className="col">
          <EditableInput
            name="geofenceName"
            value={unwrap(geofenceData, geofenceData => geofenceData.name)}
            onChange={onChangeEditableInput('name')}
            placeholder="None"
            disabled={!geofenceData}
            noInline
          />
        </div>
      </div>

      <div className="row mb-2 d-flex align-items-center">
        <div className="col-6 col-md-3">Allow rides</div>
        <div className="col d-flex align-items-center">
          <div className="custom-control custom-switch">
            <input
              id="allowRidesCheckboxInput"
              type="checkbox"
              checked={Boolean(unwrap(geofenceData, geofenceData => geofenceData.canRequestRides))}
              onChange={onChangeSwitch}
              className="custom-control-input"
              disabled={!geofenceData}
            />
            <label className="custom-control-label" htmlFor="allowRidesCheckboxInput">
              {Boolean(unwrap(geofenceData, geofenceData => geofenceData.canRequestRides)) ? 'Enabled' : 'Disabled'}
            </label>
          </div>
        </div>
      </div>

      <div className="row mb-2 d-flex align-items-center">
        <div className="col-6 col-md-3">Custom alert title</div>
        <div className="col">
          <EditableInput
            name="geofenceTitle"
            value={unwrap(geofenceData, geofenceData => geofenceData.title)}
            onChange={onChangeEditableInput('title')}
            placeholder="Using default"
            disabled={!geofenceData}
            noInline
          />
        </div>
      </div>

      <div className="row mb-2 d-flex align-items-center">
        <div className="col-6 col-md-3">Custom alert message</div>
        <div className="col">
          <EditableInput
            name="geofenceMessage"
            value={unwrap(geofenceData, geofenceData => geofenceData.message)}
            onChange={onChangeEditableInput('message')}
            placeholder="Using default"
            disabled={!geofenceData}
            noInline
          />
        </div>
      </div>

      <div className="row mb-2 d-flex align-items-center">
        <div className="col-6 col-md-3">Custom alert image URL</div>
        <div className="col">
          <EditableInput
            name="geofenceImageURL"
            value={unwrap(geofenceData, geofenceData => geofenceData.imageUrl)}
            onChange={onChangeEditableInput('imageUrl')}
            placeholder="Using default"
            disabled={!geofenceData}
            noInline
          />
        </div>
      </div>
    </div>
  );
}
