import React from 'react';

export function SmallSpacer() {
  return <div className="mb-1 mb-md-2" />;
}

export default function StandardSpacer() {
  return <div className="mb-2 mb-md-3" />;
}

export function MediumSpacer() {
  return <div className="mb-3 mb-md-4">&nbsp;</div>;
}

export function LargeSpacer() {
  return <div className="mb-4 mb-md-5">&nbsp;</div>;
}

export function HorizontalSpacerSmall() {
  return <div className="my-1">&nbsp;</div>;
}
