import firebase from 'firebase/app';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import partition from 'lodash/partition';
import moment from 'moment';
import React, {useEffect, useMemo} from 'react';
import {Link, match} from 'react-router-dom';
import {
  baseUserFullName,
  deviceListForUserId,
  filterBoolean,
  Join,
  unwrap,
  User as WaveCommonUser,
  useStatus,
} from 'wave-common';
import Device from 'wave-common/lib/models/Device';
import Alert from '../../../components/Alert/Alert';
import Card from '../../../components/Card';
import DeviceCard, {MiniDeviceDetails} from '../../../components/DeviceCard';
import FirebaseStorageCircleImage from '../../../components/FirebaseStorageCircleImage';
import FontAwesome, {FontAwesomeV5} from '../../../components/FontAwesome';
import RawData from '../../../components/RawData';
import {SeparatorSm} from '../../../components/Separators';
import {DelaySpinner} from '../../../components/Spinner';
import {useAlert} from '../../../contexts/AlertContext';
import {useAuthContext} from '../../../contexts/AuthContext';
import {useTitle} from '../../../contexts/TitleContext';
import RealtimeDatabaseDataSource from '../../../data-sources/RealtimeDatabaseDataSource';
import joinClassNames from '../../../functions/joinClassNames';
import ClaimsGuard from '../../../guards/ClaimsGuard';
import useFirestoreQueryGet from '../../../hooks/useFirestoreQueryGet';
import {useLocalStorageBoolean} from '../../../hooks/useLocalStorage';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';
import CustomClaim from '../../../models/scoopm/CustomClaim';
import Customer from '../../../models/scoopm/Customer';
import Driver from '../../../models/scoopm/Driver';
import ScoopMApi from '../../../references/scoopm-api';
import Jobs from './Jobs';

export default function User({match}: {match: match<{uid: string}>}) {
  // context

  const {setAlert} = useAlert();
  const {claims: myClaims} = useAuthContext();

  // user data

  const user = useRealtimeDatabase<WaveCommonUser>({path: `/users/${match.params.uid}`});
  useTitle(unwrap(user.data, baseUserFullName));
  const customer = useRealtimeDatabase({
    path: `/customers/${match.params.uid}`,
  });
  const driver = useRealtimeDatabase({path: `/drivers/${match.params.uid}`});
  const admin = useRealtimeDatabase({
    path: `/admins/${match.params.uid}`,
    Model: Boolean,
  });

  // state

  const invoiceFormulasReference = useMemo(() => firebase.firestore().collection('invoice-formulas'), []);
  const invoiceFormulas = useFirestoreQueryGet(invoiceFormulasReference);

  const payoutFormulasReference = useMemo(() => firebase.firestore().collection('payout-formulas'), []);
  const payoutFormulas = useFirestoreQueryGet(payoutFormulasReference);

  const {
    isPending: claimsArePending,
    error: claimsError,
    value: claims,
    handlePromise: handleClaimsPromise,
  } = useStatus<Record<string, boolean>>(
    React as any,
    useMemo(
      () =>
        ScoopMApi.instance.users
          .child(match.params.uid)
          .claims.get()
          .then((response: any) => response.data),
      [match.params.uid],
    ),
  );

  const {
    isPending: emailIsPending,
    error: emailError,
    value: email,
  } = useStatus<string>(
    React as any,
    useMemo(
      () =>
        ScoopMApi.instance.users
          .child(match.params.uid)
          .email.get()
          .then((response: any) => response.data.value),
      [match.params.uid],
    ),
  );

  const {isPending: userUpdateIsPending, handlePromise: handleUserUpdatePromise} = useStatus(React as any);
  const isWaitingForUserUpdate = useMemo(
    () => userUpdateIsPending || !isEqual(user.data, driver.data ? driver.data : customer.data),
    [userUpdateIsPending, user.data, customer.data, driver.data],
  );

  // callbacj: on change claim

  const onChangeClaim = (name: string) => (event: any) => {
    const {checked} = event.target;
    const newClaims = {...(claims || {}), [name]: checked};
    handleClaimsPromise(ScoopMApi.instance.users.child(match.params.uid).claims.set(newClaims));
  };

  // callback: on change isAdmin

  const onChangeIsAdmin = (event: any) => {
    admin.set(event.target.checked).catch(error => {
      console.error('Error changing super admin status', error);
    });
  };

  // callback: update

  const update = (value: any) => {
    handleUserUpdatePromise(
      (driver.data ? driver.update(value) : customer.update(value)).catch(error => {
        setAlert(new Alert('Unable to perform update'));
        console.error(error);
        throw error;
      }),
    );
  };

  // devices

  const {
    handlePromise: handleDevicesPromise,
    isPending: devicesArePending,
    error: devicesError,
    value: devices,
  } = useStatus<Device<number>[]>(React as any);

  useEffect(() => {
    handleDevicesPromise(deviceListForUserId(match.params.uid, RealtimeDatabaseDataSource.instance));
  }, [match.params.uid, handleDevicesPromise]);

  const [activeDevices, inactiveDevices] = useMemo(
    () =>
      unwrap(
        devices,
        devices => {
          const aMonthAgo = moment().subtract(1, 'months').valueOf();
          return partition(devices, device => device.lastUsedTimestampMs && device.lastUsedTimestampMs > aMonthAgo);
        },
        () => [undefined, undefined],
      ),
    [devices],
  );

  // render

  if (user.isLoading) return <DelaySpinner />;

  if (!user.data) return 'No data';

  return (
    <Card padded>
      <div className="row">
        {/* basic information */}

        <div className={'col-md-12 mb-2 mb-md-3'}>
          <div className="row align-items-center">
            {/* profile pictures */}

            <div className="col-auto">
              <FirebaseStorageCircleImage path={`/profiles/${match.params.uid}`} diameterRem={6} modal />
            </div>

            <div className="col">
              {/* name */}

              <div className="mb-2">
                {/* user type */}

                <h6 className="text-muted mb-0">
                  {capitalize(filterBoolean([customer.data && 'rider', driver.data && 'driver']).join(' + '))}
                </h6>

                <h3 className="mb-0">
                  {/* name */}

                  {Driver.prototype.fullNameWithNickname.apply(user.data)}

                  {/* email */}

                  {emailError ? (
                    ' (error getting email)'
                  ) : (
                    <>
                      {' '}
                      / <a href={`mailto:${email}`}>{email}</a>
                    </>
                  )}
                </h3>
              </div>

              <div className="d-flex align-items-center">
                {/* rating */}

                {(user.data as any)?.stars && (
                  <span className="mr-2">
                    <FontAwesomeV5 name="star" className="text-warning" /> {(user.data as any).stars}
                  </span>
                )}

                {/* link buttons */}

                {Boolean(driver.data) && (
                  <Link to={`/admin/drivers/${driver.key}`} className="btn btn-sm btn-light mr-2">
                    <FontAwesome.CarSolid /> Driver profile
                  </Link>
                )}
              </div>

              {/* user types */}

              {customer.data && driver.data && (
                <div className="alert alert-danger mt-2 mb-0">
                  <FontAwesome.ExclamationCircle /> This account is set up as both a rider and a driver, which can cause
                  issues
                </div>
              )}
            </div>
          </div>
        </div>

        {/* pricing formulas */}

        <ClaimsGuard claim={CustomClaim.manageFinancial}>
          <Section
            title={
              <>Pricing formula overrides {userUpdateIsPending && <DelaySpinner className="float-right" small />}</>
            }
            storageKey="FORMULAS">
            <div className="form-group">
              <div className="mb-1">Invoice formula</div>
              <select
                id="sourceSelect"
                value={unwrap(user.data, data => data.invoiceFormulaId) || ''}
                onChange={event => update({invoiceFormulaId: event.target.value || null})}
                className="custom-select"
                disabled={isWaitingForUserUpdate}>
                <option value="">None</option>
                {unwrap(invoiceFormulas.snapshot, snapshot =>
                  snapshot.docs.map(doc => (
                    <option key={doc.id} value={doc.id}>
                      {doc.get('name')}
                    </option>
                  )),
                )}
              </select>
              <small className="mb-2">
                <FontAwesome.InfoCircle /> If this user creates jobs (such as through the dispatch portal), this formula
                will be used to charge them
              </small>
            </div>
            <div className="mb-1">Payout formula</div>
            <select
              id="sourceSelect"
              value={unwrap(user.data, data => data.payoutFormulaId) || ''}
              onChange={event => update({payoutFormulaId: event.target.value || null})}
              className="custom-select"
              disabled={isWaitingForUserUpdate}>
              <option value="">None</option>
              {unwrap(payoutFormulas.snapshot, snapshot =>
                snapshot.docs.map(doc => (
                  <option key={doc.id} value={doc.id}>
                    {doc.get('name')}
                  </option>
                )),
              )}
            </select>
            <small className="mb-0">
              <FontAwesome.InfoCircle /> Overrides any other formlas used to calculate the driver's rate
            </small>
          </Section>
        </ClaimsGuard>

        {/* claims / "permissions" */}

        <Section
          title={<>Permissions {claimsArePending && <i className="fas fa-circle-notch fa-spin" />}</>}
          storageKey="PERMISSIONS">
          {/* error message */}
          {claimsError && (
            <div className="alert alert-warning">
              <i className="fas fa-exclamation-circle"></i> Unable to get or set the {"user's"} permissions.{' '}
              {claimsError.message}
            </div>
          )}
          {/* super admin switch */}
          {myClaims && myClaims[CustomClaim.manageApi] && (
            <div className="custom-control custom-switch mb-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id="isAdminSwitch"
                checked={admin.data === true}
                onChange={onChangeIsAdmin}
                disabled={admin.isLoading}
              />
              <label className="custom-control-label" htmlFor="isAdminSwitch">
                Super admin <i className="fas fa-bolt " />
              </label>
            </div>
          )}

          {/* other claims */}

          {!Customer.prototype.isValid.apply(user.data) && (
            <div>(To edit these, the user must have a phone number and name)</div>
          )}

          <Join react={React as any} separator={SeparatorSm}>
            {CustomClaim.allCases().map(claimType => (
              <div key={claimType} className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={claimType + 'Input'}
                  checked={Boolean(claims && claims[claimType])}
                  onChange={onChangeClaim(claimType)}
                  disabled={!claims || Boolean(claimsError) || !Customer.prototype.isValid.apply(user.data)}
                />
                <label className="custom-control-label" htmlFor={claimType + 'Input'}>
                  {CustomClaim.getComponent(claimType)}
                </label>
              </div>
            ))}
          </Join>
        </Section>

        {/* requested jobs */}

        <Section title="Requested jobs" storageKey="REQUESTED_JOBS">
          <Jobs uid={match.params.uid} />
        </Section>

        {/* devices */}

        <Section
          title={
            <>
              Apps{' '}
              {Boolean(activeDevices?.length) && (
                <span className="text-muted float-right">{activeDevices!.length}</span>
              )}
            </>
          }
          storageKey="DEVICES">
          {devicesArePending ? (
            <DelaySpinner />
          ) : devicesError ? (
            <span className="text-muted">Unable to load devices</span>
          ) : activeDevices?.length ? (
            <div className="row">
              {activeDevices.map(device => (
                <div key={device.id} className="col-auto mb-2 mb-md-3">
                  <DeviceCard key={device.id} device={device} />
                </div>
              ))}
            </div>
          ) : (
            <span className="text-muted">None</span>
          )}
          {Boolean(inactiveDevices?.length) && (
            <>
              <h5 className="mt-2 mt-md-3">
                Inactive/legacy apps
                {Boolean(inactiveDevices?.length) && (
                  <span className="text-muted float-right">{inactiveDevices?.length}</span>
                )}
              </h5>
              <Join react={React as any} separator={SeparatorSm}>
                {inactiveDevices?.map(device => <MiniDeviceDetails key={device.id} device={device} />) ?? []}
              </Join>
            </>
          )}
        </Section>

        {/* raw data */}

        <ClaimsGuard claim={CustomClaim.manageApi}>
          <Section title="Raw data" storageKey="RAW_DATA">
            <ClaimsGuard claim={CustomClaim.manageApi}>
              <RawData
                title="User data"
                data={user.data}
                path={`/users/${match.params.uid}` as any}
                className="bg-white rounded mb-2 mb-md-3"
                defaultIsCollapsed
              />
              <RawData
                title="Customer data"
                data={customer.data}
                path={`/customers/${match.params.uid}` as any}
                className="bg-white rounded mb-2 mb-md-3"
                defaultIsCollapsed
              />
              <RawData
                title="Driver data"
                data={driver.data}
                path={`/drivers/${match.params.uid}` as any}
                className="bg-white rounded"
                defaultIsCollapsed
              />
            </ClaimsGuard>
          </Section>
        </ClaimsGuard>
      </div>
    </Card>
  );
}

function Section({title, storageKey, children}: {title?: any; storageKey: string; children?: any}) {
  const [isExpanded, setIsExpanded] = useLocalStorageBoolean(`USER_PROFILE_${storageKey}_IS_EXPANDED`, true);
  return (
    <div className={'col-md-6 mb-2 mb-md-3'}>
      <div className={'p-2 p-md-3 rounded-xl bg-light'}>
        <div className={joinClassNames('row align-items-center', isExpanded && 'mb-3')}>
          <div className="col">{title && <h5 className="mb-0">{title}</h5>}</div>
          <div className="col-auto">
            <button type="button" onClick={() => setIsExpanded(!isExpanded)} className="btn">
              <FontAwesomeV5 name={isExpanded ? 'minus' : 'plus'} />
            </button>
          </div>
        </div>
        {isExpanded && children}
      </div>
    </div>
  );
}
