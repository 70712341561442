import React, {Component} from 'react';
import InvalidCode from './InvalidCode';
import PasswordWasReset from './PasswordWasReset';
import firebase from 'firebase/app';
import Delay from '../components/Delay';
import Spinner from '../components/Spinner';

class ResetPassword extends Component {
  state = {
    loading: true,
  };

  passwordRef = React.createRef();

  componentDidMount() {
    this.verifyResetCode();
  }

  verifyResetCode() {
    firebase
      .auth()
      .verifyPasswordResetCode(this.props.actionCode)
      .then(email => {
        this.setState({loading: false, email});
      })
      .catch(error => {
        // Invalid or expired action code. Ask user to try to reset the password again
        console.error(error);
        this.setState({loading: false, error: 'invalidCode'});
      });
  }

  onSubmit(e) {
    e.preventDefault();

    // get password
    let password = this.passwordRef.current.value;

    // validate
    if (!password) {
      this.setState({error: 'Enter a strong password'});
      return;
    }

    // confirm new password
    firebase
      .auth()
      .confirmPasswordReset(this.props.actionCode, password)
      .then(response => {
        // sign in
        firebase
          .auth()
          .signInWithEmailAndPassword(this.state.email, password)
          .then(() => this.setState({success: true}))
          .catch(error => {
            console.log(error);
            this.setState({success: true});
          });
      })
      .catch(error => {
        console.log(error);
        if (error.code === 'auth/weak-password') {
          this.setState({
            error: 'Password not strong enough. The password must be at least 6 characters',
          });
        } else {
          this.setState({
            error: 'There was an error saving your new password',
          });
        }
      });
  }

  render() {
    if (this.state.loading)
      return (
        <Delay>
          <Spinner />
        </Delay>
      );

    if (this.state.success) return <PasswordWasReset continueUrl={this.props.continueUrl} />;

    if (this.state.error === 'invalidCode') return <InvalidCode />;

    return (
      <div>
        <h3>Reset your password</h3>
        <p>
          For <strong>{this.state.email}</strong>
        </p>

        <form onSubmit={this.onSubmit.bind(this)}>
          <div className="form-group mb-3">
            <input
              ref={this.passwordRef}
              id="password"
              name="password"
              type="password"
              placeholder="Enter new password"
              className={'form-control' + (this.state.error ? ' is-invalid' : '')}
            />
            {this.state.error && <div className="invalid-feedback">{this.state.error}</div>}
          </div>
          <div className="form-group">
            <input type="submit" value="Save" className="btn btn-primary float-right" />
          </div>
        </form>
      </div>
    );
  }
}

export default ResetPassword;
