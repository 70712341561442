import React from 'react';
import ColumnComponent from './ColumnComponent';
import {selectionColumnWidth} from './HeadComponent';

export default function RowComponent({index, style, data}) {
  const row = data[index];

  return (
    <div style={style}>
      <div className="firestore-row border-bottom border-right">
        {/* selection column */}

        {row._allowSelection && (
          <div className="firestore-column" style={{width: selectionColumnWidth}}>
            <div className="custom-control custom-checkbox">
              <input
                id={`SELECTION_CHECKBOX_${index}`}
                type="checkbox"
                checked={row._isSelected}
                onChange={event => row._onChangeSelection(!row._isSelected)}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor={`SELECTION_CHECKBOX_${index}`}>
                {' '}
              </label>
            </div>
          </div>
        )}

        {row.columns.map((column, i) => (
          <ColumnComponent key={i} column={column} href={row.href} data={row.rawValue} />
        ))}
      </div>
    </div>
  );
}
