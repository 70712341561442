import React from 'react';

export default function FourOFour() {
  return (
    <div className="d-flex justify-content-center">
      <div className="p-5 d-inline">
        <h3 className="text-muted">Not found</h3>
      </div>
    </div>
  );
}
