import {decode, encode} from '@googlemaps/polyline-codec';
import cloneDeep from 'lodash/cloneDeep';
import flatMap from 'lodash/flatMap';
import last from 'lodash/last';
import pluralize from 'pluralize';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Polygon as PolygonComponent} from 'react-google-maps';
import {exists, expect} from 'wave-common';
import {latLngLiteralFromCoordinatesArray} from 'wave-common/lib/models/CoordinatesArray';
import Polygon from 'wave-common/lib/models/Polygon';
import FontAwesome from '../../components/FontAwesome';
import GoogleMap from '../../components/GoogleMaps/GoogleMap';
import {boundsForLatLngs} from '../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../components/GoogleMaps/MapContents';
import {GoogleLatLng} from '../../components/GoogleMaps/Marker';
import {polygonOptions, polygonOptionsPrimary} from './PolygonsDisplay';
import {ArrayPropertyInputProps} from './PropertyInputProps';

export default function PolygonsInput({
  value: polygons,
  model,
  onChange,
  validationError,
}: ArrayPropertyInputProps<Polygon>) {
  // refs

  const mapRef = useRef<any>();
  const dragStartLatLngRef = useRef<GoogleLatLng>();

  // state

  const [mapContents, setMapContents] = useState<MapContents>();
  const [selectedPolygonIndex, setSelectedPolygonIndex] = useState<number | undefined>(
    polygons?.length ? 0 : undefined,
  );
  const [history, setHistory] = useState<(Polygon[] | undefined)[]>([polygons as Polygon[] | undefined]);
  const [historyIndex, setHistoryIndex] = useState<number>();

  // effect: set map contents

  useEffect(() => {
    if (mapContents) {
      // only set once
      return;
    }
    if (polygons) {
      setMapContents(
        new MapContents.Bounds({
          id: 'polygons',
          name: pluralize('Polygon', polygons.length),
          latLngBounds: boundsForLatLngs(flatMap(polygons.map(polygon => decode(polygon.coordinatesEncoded)))),
        }),
      );
    } else if (model?.location?.coordinates) {
      setMapContents(
        new MapContents.Coordinates({
          id: 'location_center',
          name: 'Location',
          latLng: latLngLiteralFromCoordinatesArray(model.location.coordinates),
          zoom: 14,
        }),
      );
    } else {
      // setMapContents(MapContents.Bounds.unitedStates());
    }
  }, [mapContents, polygons, model?.location?.coordinates]);

  // callback: call change listener and reset history

  const callChangeListenerAndResetHistory = useCallback(
    (newPolygons: Polygon[]) => {
      let newHistory = cloneDeep(history);
      if (historyIndex !== undefined) {
        newHistory = newHistory.slice(0, historyIndex + 1);
        setHistoryIndex(undefined);
      }
      newHistory.push(newPolygons);
      setHistory(newHistory);
      onChange(newPolygons.length === 0 ? undefined : newPolygons);
    },
    [history, historyIndex, onChange],
  );

  // callback: change history index

  const changeHistoryIndex = useCallback(
    (index: number) => {
      setHistoryIndex(index);
      onChange(history[index]);
    },
    [onChange, history],
  );

  // memos

  // polygons based off of the history index

  const polygonsFromHistory = useMemo(
    () => (historyIndex !== undefined ? history[historyIndex] : last(history)),
    [history, historyIndex],
  );

  // render

  return (
    <>
      {validationError && <div className="alert alert-danger">{validationError.message}</div>}
      <div className="position-relative">
        <div
          className="position-absolute"
          style={{
            top: 10,
            right: 10,
            zIndex: 1000,
          }}>
          {selectedPolygonIndex !== undefined && polygonsFromHistory && polygonsFromHistory[selectedPolygonIndex] && (
            <button
              type="button"
              onClick={() => {
                const newPolygons = cloneDeep(expect(polygonsFromHistory));
                newPolygons.splice(selectedPolygonIndex, 1);
                setSelectedPolygonIndex(undefined);
                callChangeListenerAndResetHistory(newPolygons);
              }}
              className="btn btn-outline-light text-dark bg-white shadow">
              <FontAwesome.Trash />
              <span className="d-none d-xl-inline"> Delete</span>
            </button>
          )}
          {history && history.length > 1 && (
            <div className="btn-group shadow ml-2 ml-xl-3">
              <button
                type="button"
                onClick={() => changeHistoryIndex(historyIndex === undefined ? history.length - 2 : historyIndex - 1)}
                className="btn btn-outline-light text-dark bg-white"
                disabled={history.length < 2 || historyIndex === 0}>
                <FontAwesome.RotateLeft />
                <span className="d-none d-xl-inline"> Undo</span>
              </button>
              <button
                type="button"
                onClick={() => changeHistoryIndex(expect(historyIndex) + 1)}
                className="btn btn-outline-light text-dark bg-white"
                disabled={!exists(historyIndex) || historyIndex === history.length - 1}>
                <FontAwesome.RotateRight />
                <span className="d-none d-xl-inline"> Redo</span>
              </button>
            </div>
          )}
        </div>
        <div style={{height: '22rem'}}>
          <GoogleMap
            onMap={(map: any) => (mapRef.current = map)}
            onClick={({latLng}: {latLng: GoogleLatLng}) => {
              const newPolygons = polygonsFromHistory ? cloneDeep(polygonsFromHistory) : [];
              if (selectedPolygonIndex !== undefined) {
                const newCoordinates = decode(newPolygons[selectedPolygonIndex].coordinatesEncoded);
                newCoordinates?.push([latLng.lat(), latLng.lng()]);
                newPolygons[selectedPolygonIndex].coordinatesEncoded = encode(newCoordinates);
              } else {
                newPolygons.push({coordinatesEncoded: encode([[latLng.lat(), latLng.lng()]])});
                setSelectedPolygonIndex(newPolygons.length - 1);
              }
              callChangeListenerAndResetHistory(newPolygons);
            }}
            mapContents={mapContents}
            fullscreenControl={false}
            streetViewControl={false}
            useDefaultMapStyles
            rounded>
            {polygonsFromHistory?.map((polygon, index) => {
              const polygonLatLngs = decode(polygon.coordinatesEncoded).map(latLngLiteralFromCoordinatesArray);
              return (
                <PolygonComponent
                  key={index}
                  path={polygonLatLngs}
                  options={index === selectedPolygonIndex ? polygonOptionsPrimary : polygonOptions}
                  onClick={() => setSelectedPolygonIndex(selectedPolygonIndex === index ? undefined : index)}
                  onDragStart={({latLng}) => {
                    dragStartLatLngRef.current = latLng;
                    if (selectedPolygonIndex === index) {
                      setSelectedPolygonIndex(index);
                    }
                  }}
                  onDragEnd={({latLng}) => {
                    const dLatitude = latLng.lat() - (dragStartLatLngRef?.current?.lat() ?? 0);
                    const dLongitude = latLng.lng() - (dragStartLatLngRef?.current?.lng() ?? 0);
                    dragStartLatLngRef.current = undefined;
                    const newPolygons = cloneDeep(polygonsFromHistory);
                    newPolygons[index].coordinatesEncoded = encode(
                      decode(newPolygons[index].coordinatesEncoded).map(([latitude, longitude]) => [
                        latitude + dLatitude,
                        longitude + dLongitude,
                      ]),
                    );
                    callChangeListenerAndResetHistory(newPolygons);
                  }}
                  // editable
                  draggable
                />
              );
            })}
          </GoogleMap>
        </div>
      </div>
    </>
  );
}
