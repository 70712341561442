import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';
import Delay from '../../../components/Delay';
import Spinner from '../../../components/Spinner';
import unwrap from '../../../functions/unwrap';
import useTripEzCaterEventsOutgoing from '../../../hooks/useTripEzCaterEventsOutgoing';
import EventOutgoing from '../../../models/scoopm/EventOutgoing';

export default function TripEzCaterEventsOutgoing({tripData}) {
  const eventsOutgoing = useTripEzCaterEventsOutgoing(tripData);

  if (eventsOutgoing.isLoading) {
    return (
      <Delay>
        <Spinner />
      </Delay>
    );
  }

  const eventsEntries = unwrap(eventsOutgoing.data, data => Object.entries(data));

  if (!eventsEntries) {
    return null;
  }

  if (!eventsEntries.length) {
    return <>None</>;
  }

  return (
    <pre className="mb-0">
      {eventsEntries.map(([eventId, event]) => (
        <div key={eventId}>
          {moment(event.timestampMs).format('YYYY/MM/DD hh:mm:ss A')}
          &nbsp;&mdash;&nbsp;
          <strong>{EventOutgoing.mutationName(event.requestBody)}</strong> &mdash; {event.responseCode}{' '}
          {unwrap(event.responseBody, body => body.message || JSON.stringify(body))}{' '}
          {unwrap(event.error, e => (
            <strong>{e.message}</strong>
          ))}
          <Link to={`/admin/events-outgoing/${eventId}`} className="btn btn-light btn-sm ml-2 my-1">
            Details
          </Link>
        </div>
      ))}
    </pre>
  );
}
