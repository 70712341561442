import unhandledCase from '../../functions/unhandledCase';
import Enum from '../base/Enum';
import Workspace from './Workspace';

export default class Channel extends Enum {
  name() {
    switch (this) {
      case Channel.api:
        return '#api';
      case Channel.apiSandbox:
        return '#api-sandbox';
      case Channel.apiDevelopment:
        return '#api-development';
      case Channel.deliveriesTesting:
        return '#deliveries-testing';
      case Channel.deliveriesTestingComplete:
        return '#deliveries-testing-complete';
      case Channel.deliveriesWaveM:
        return '#deliveries-wavem';
      case Channel.deliveriesWaveMComplete:
        return '#deliveries-wavem-complete';
      case Channel.notifications:
        return '#notifications';
      case Channel.notificationsComplete:
        return '#notifications-complete';
      case Channel.willys_daily_schedule:
        return '#willys_daily_schedule';
      case Channel.willys_store1:
        return '#willys_store1';
      case Channel.willys_store2:
        return '#willys_store2';
      case Channel.willys_store3:
        return '#willys_store3';
      case Channel.willys_store4:
        return '#willys_store4';
      case Channel.willys_store5:
        return '#willys_store5';
      case Channel.willys_store7:
        return '#willys_store7';
      case Channel.willys_store14:
        return '#willys_store14';
      case Channel.willys_store15:
        return '#willys_store15';
      case Channel.willys_store16:
        return '#willys_store16';
      case Channel.willys_store17:
        return '#willys_store17';
      case Channel.willys_store18:
        return '#willys_store18';
      case Channel.willys_store20:
        return '#willys_store20';
      case Channel.willys_store25:
        return '#willys_store25';
      case Channel.willys_store26:
        return '#willys_store26';
      case Channel.willys_store27:
        return '#willys_store27';
      case Channel.willys_store28:
        return '#willys_store28';
      case Channel.willys_store29:
        return '#willys_store29';
      case Channel.willys_store30:
        return '#willys_store30';
      case Channel.willys_store31:
        return '#willys_store31';
      case Channel.willys_store32:
        return '#willys_store32';
      case Channel.willys_store33:
        return '#willys_store33';
      case Channel.willys_store34:
        return '#willys_store34';
      case Channel.willys_store36:
        return '#willys_store36';
      case Channel.willysTesting:
        return '#willys-testing';
      default:
        throw new Error(`No title set for channel case "${this}"`);
    }
  }

  title() {
    return `${this.name()} (${this.workspace().title()})`;
  }

  workspace() {
    switch (this) {
      case Channel.api:
      case Channel.apiSandbox:
      case Channel.apiDevelopment:
      case Channel.deliveriesTesting:
      case Channel.deliveriesTestingComplete:
        return Workspace.ibgsoftware;

      case Channel.deliveriesWaveM:
      case Channel.deliveriesWaveMComplete:
      case Channel.willys_daily_schedule:
      case Channel.willys_store1:
      case Channel.willys_store2:
      case Channel.willys_store3:
      case Channel.willys_store4:
      case Channel.willys_store5:
      case Channel.willys_store7:
      case Channel.willys_store14:
      case Channel.willys_store15:
      case Channel.willys_store16:
      case Channel.willys_store17:
      case Channel.willys_store18:
      case Channel.willys_store20:
      case Channel.willys_store25:
      case Channel.willys_store26:
      case Channel.willys_store27:
      case Channel.willys_store28:
      case Channel.willys_store29:
      case Channel.willys_store30:
      case Channel.willys_store31:
      case Channel.willys_store32:
      case Channel.willys_store33:
      case Channel.willys_store34:
      case Channel.willys_store36:
      case Channel.willysTesting:
        return Workspace.wave_ops;

      case Channel.notifications:
      case Channel.notificationsComplete:
        return Workspace.dAndCEnterprise;

      default:
        throw unhandledCase(this);
    }
  }
}

// ibgsoftware

Channel.api = new Channel('C024JMREA4X');
Channel.apiSandbox = new Channel('C024ZR1T1L3');
Channel.apiDevelopment = new Channel('C02525P0Y2J');
Channel.deliveriesTesting = new Channel('C019V1KVD4G');
Channel.deliveriesTestingComplete = new Channel('C027Q2Y25T3');

// wave_ops

Channel.deliveriesWaveM = new Channel('G01JPQ421M3');
Channel.deliveriesWaveMComplete = new Channel('C027Q2PQXT3');
Channel.willys_daily_schedule = new Channel('C020VJDEYN9');
Channel.willys_store1 = new Channel('C02112H1NJG');
Channel.willys_store2 = new Channel('C021NHJ8YSV');
Channel.willys_store3 = new Channel('C020RQ36ECD');
Channel.willys_store4 = new Channel('C0206AJER9V');
Channel.willys_store5 = new Channel('C01JPRV0TRT');
Channel.willys_store7 = new Channel('C021JHYANG1');
Channel.willys_store14 = new Channel('C020Q7763T8');
Channel.willys_store15 = new Channel('C01JZ413QHJ');
Channel.willys_store16 = new Channel('C01JYSNE56V');
Channel.willys_store17 = new Channel('C023B5ZSSVB');
Channel.willys_store18 = new Channel('C01JL8380JE');
Channel.willys_store20 = new Channel('C021KCLFAGK');
Channel.willys_store25 = new Channel('C01JPS8LLQ5');
Channel.willys_store26 = new Channel('C0220RNUL3Z');
Channel.willys_store27 = new Channel('C022KL6Q5PU');
Channel.willys_store28 = new Channel('C01JDEQH3K8');
Channel.willys_store29 = new Channel('C01JH5MD4CV');
Channel.willys_store30 = new Channel('C01JZ3WJZB6');
Channel.willys_store31 = new Channel('C021YRF7DQA');
Channel.willys_store32 = new Channel('G01Q4Q7M4G5');
Channel.willys_store33 = new Channel('C01JSUPBZD0');
Channel.willys_store34 = new Channel('C01JH5KGN6R');
Channel.willys_store36 = new Channel('C02166LQJ1H');
Channel.willysTesting = new Channel('C026KRCNPNK');

// D&C Enterprise

Channel.notifications = new Channel('C027U7WTY8Y');
Channel.notificationsComplete = new Channel('C027Q17NSL9');
