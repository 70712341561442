import React, {useMemo} from 'react';
import {unwrap, useStatus} from 'wave-common';
import {vehicleTrimRead} from 'wave-common/lib/controllers/VehicleTrim';
import {DelaySpinner} from '../../components/Spinner';
import FirestoreDataSource from '../../data-sources/FirestoreDataSource';

export default function VehicleTrimDisplay({value}: {value: string}) {
  const {
    isPending,
    error,
    value: trim,
  } = useStatus(
    React as any,
    useMemo(() => unwrap(value, value => vehicleTrimRead(value, FirestoreDataSource)), [value]),
  );

  return isPending ? <DelaySpinner small /> : error ? 'Error' : trim?.name ?? 'None';
}
