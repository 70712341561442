import Enum from '../base/Enum';

class StreamMemberType extends Enum {
  static rider = new StreamMemberType('RIDER');
  static driver = new StreamMemberType('DRIVER');
  static admin = new StreamMemberType('ADMIN');
  static guest = new StreamMemberType('GUEST');
}

export default StreamMemberType;
