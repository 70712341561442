import AgoraRTC from 'agora-rtc-sdk';
import React, {useEffect, useState} from 'react';
import Constants from '../../Constants';
import Enum from '../../models/base/Enum';
import ScoopMApi from '../../references/scoopm-api';
import './Agora.css';

class State extends Enum {
  static settingUp = new State('SETTING_UP');
  static error = new State('ERROR');
  static noStreams = new State('NO_STREAMS');
  static playing = new State('PLAYING');
  static paused = new State('PAUSED');
}

const Agora = ({tripIdOrDriverId}) => {
  if (!tripIdOrDriverId) throw new Error('"tripIdOrDriverId" is a required prop of Agora');

  const [state, setState] = useState(State.settingUp);
  const [stream, setStream] = useState(null);

  useEffect(() => {
    // initialize agora client

    console.log('Initializing Agora client');

    const client = AgoraRTC.createClient({mode: 'rtc', codec: 'h264'});

    client.init(
      Constants.publicKeys.appID,
      () => {
        console.info('Initialized Agora client');

        // get token

        let _uid = null;

        ScoopMApi.instance.streaming.token.create(tripIdOrDriverId).then(
          response => {
            const {token, channelName, uid, accountName} = response.data;

            // join channel

            console.log('Joining channel...');

            client.join(
              token,
              channelName,
              uid || accountName,
              uid => {
                _uid = uid;

                console.info('Joined channel', uid);

                setState(State.noStreams);
              },
              errorMessage => {
                console.error('Error joining channel', errorMessage);

                setState(State.error);
              },
            );
          },
          error => {
            setState(State.error);
          },
        );

        // wait for stream to be added

        client.on('stream-added', event => {
          const {stream} = event;

          console.warn('Stream added', stream.getId());

          // is own id?
          if (stream.getId() === _uid) return;

          // subscribe

          client.subscribe(stream, error => {
            console.error('Error subscribing to stream', error);

            setState(State.error);
          });
        });

        // wait for stream to be subscribed

        client.on('stream-subscribed', event => {
          const {stream} = event;

          console.warn('Stream subscribed', stream.getId());

          setStream(stream);

          setState(State.paused);
        });

        // observe stream-removed -- doesn't seem to do anything

        client.on('stream-removed', event => {
          const {stream} = event;

          console.warn('Stream removed', stream.getId());

          stream.stop('streamDiv');

          setStream(null);

          setState(State.noStreams);
        });

        // observe peer leaving

        client.on('peer-leave', event => {
          const {stream} = event;

          console.warn('Peer left', event);

          stream.stop('streamDiv');

          setStream(null);

          setState(State.noStreams);
        });
      },
      errorMessage => {
        console.error('Error initializing Agora client', errorMessage);

        setState(State.error);
      },
    );

    // cleanup

    return () => {
      // leave channel

      console.info('Leaving channel');

      client.leave(
        () => {
          console.info('Left channel');
        },
        errorMessage => {
          console.error('Error leaving channel', errorMessage);
        },
      );
    };
  }, []);

  const onClickOverlay = event => {
    console.log(stream);

    if (state === State.paused) {
      stream.play('streamDiv', {fit: 'contain'});

      setState(State.playing);
    } else if (state === State.playing) {
      stream.stop('streamDiv');

      setState(State.paused);
    } else {
      // do nothing
    }
  };

  return (
    <div className="agora-container">
      <div id="streamDiv" className="agora-stream-div"></div>
      <div
        className={
          'agora-overlay' +
          (state === State.playing ? ' agora-overlay-playing' : '') +
          (state === State.noStreams ? ' agora-overlay-no-streams' : '')
        }
        onClick={onClickOverlay}
      >
        {state === State.settingUp ? (
          <i className="fas fa-circle-notch fa-spin fa-2x" />
        ) : state === State.noStreams ? (
          'Dashcam is not streaming'
        ) : state === State.paused ? (
          <i className="fas fa-play-circle fa-5x" />
        ) : state === State.playing ? (
          <i className="fas fa-pause-circle fa-5x" />
        ) : state === State.error ? (
          <span>
            <i className="fas fa-exclamation-circle mr-2" />
            There was an error connecting to the video stream
          </span>
        ) : (
          <span>
            <i className="fas fa-exclamation-circle mr-2" />
            Something went wrong
          </span>
        )}
      </div>
    </div>
  );
};

export default Agora;
