import last from 'lodash/last';
import {useCallback, useEffect, useRef, useState} from 'react';

export default function useThrottledState<T>(
  initialValue: T,
  delayMs: number,
): {
  state: T;
  setState: React.Dispatch<React.SetStateAction<T>>;
} {
  const isTimingOut = useRef<boolean>(false);
  const stateStack = useRef<React.SetStateAction<T>[]>([]);

  const [throttledState, setThrottledState] = useState<T>(initialValue);

  const startTimer = useCallback(() => {
    isTimingOut.current = true;
    const handle = setTimeout(() => {
      isTimingOut.current = false;
      if (stateStack.current.length) {
        const state = last(stateStack.current)!;
        stateStack.current = [];
        // console.log('Throttled state: Found more updates')
        setThrottledState(state);
        startTimer();
      }
    }, delayMs);
    return () => clearTimeout(handle);
  }, [delayMs]);

  const setThrottledStateWithCheck: React.Dispatch<React.SetStateAction<T>> = useCallback(
    (state: React.SetStateAction<T> | T) => {
      if (isTimingOut.current) {
        stateStack.current.push(state);
      } else {
        // console.log('Throttled state: Setting state without timeout')
        setThrottledState(state);
        return startTimer();
      }
    },
    [startTimer],
  );

  // useEffect(() => {
  //     console.log('Throttled state:', throttledState)
  // }, [throttledState])

  return {state: throttledState, setState: setThrottledStateWithCheck};
}

// export default function useThrottledState<T>(state: T, delayMs: number) {

//     const callback = useRef<() => void>()
//     const latestState = useRef<T>()

//     const [throttledState, setThrottledState] = useState<T>(state)

//     useEffect(() => {
//         latestState.current = state
//         if (callback.current) {
//             // wait
//         } else {
//             callback.current = () => {
//                 setThrottledState(latestState.current as T)
//                 callback.current = undefined
//             }
//             setTimeout(callback.current, delayMs)
//         }
//     }, [state, delayMs])

//     return throttledState

// }
