import firebase from 'firebase/app';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, {useEffect, useMemo, useState} from 'react';
import FormControl from 'react-bootstrap/FormControl';
import {Link, useHistory} from 'react-router-dom';
import ValidateableInput from '../../components/bootstrap/ValidateableInput';
import logPromise from '../../functions/logPromise';
import unwrap from '../../functions/unwrap';
import useFirestoreGet from '../../hooks/useFirestoreGet';
import useQuery from '../../hooks/useQuery';
import logo from '../../images/logo_words_trans_sm.png';
import ErrorReason from '../../models/scoopm/ErrorReason';
import VehicleColor from '../../models/scoopm/VehicleColor';
import ScoopMApi from '../../references/scoopm-api';
import AuthLayout from './AuthLayout';

function userFacingError(message) {
  const error = new Error(message);
  error.userFacingMessage = message;
  return error;
}

export default function LinkFountainApplication() {
  // -------------------------------- STATE

  const history = useHistory();

  // token

  const urlSearchParams = useQuery();
  const token = urlSearchParams ? urlSearchParams.get('token') : undefined;

  // year

  const yearsReference = useMemo(() => firebase.firestore().collection('vehicle-years'), []);
  const years = useFirestoreGet(yearsReference);
  const sortedYearsSnapshots = useMemo(
    () => unwrap(years.snapshot, snapshot => orderBy(snapshot.docs, doc => doc.get('value'), 'desc')),
    [years],
  );
  const [year, setYear] = useState('');

  // make

  const makesReference = useMemo(
    () => year && firebase.firestore().collection('vehicle-makes').where('years', 'array-contains', year),
    [year],
  );
  const makes = useFirestoreGet(makesReference);
  const sortedMakesSnapshots = useMemo(
    () => unwrap(makes.snapshot, snapshot => sortBy(snapshot.docs, doc => doc.get('name'))),
    [makes],
  );
  const [make, setMake] = useState('');

  // model

  const modelsReference = useMemo(
    () =>
      year &&
      make &&
      firebase.firestore().collection('vehicle-models').where('year', '==', year).where('make', '==', make),
    [year, make],
  );
  const models = useFirestoreGet(modelsReference);
  const sortedModelsSnapshots = useMemo(
    () => unwrap(models.snapshot, snapshot => sortBy(snapshot.docs, doc => doc.get('name'))),
    [models],
  );
  const [model, setModel] = useState('');

  // trim

  const trimsReference = useMemo(
    () =>
      year &&
      make &&
      model &&
      firebase
        .firestore()
        .collection('vehicle-trims')
        .where('year', '==', year)
        .where('make', '==', make)
        .where('model', '==', model),
    [year, make, model],
  );
  const trims = useFirestoreGet(trimsReference);
  const sortedTrimsSnapshots = useMemo(
    () => unwrap(trims.snapshot, snapshot => sortBy(snapshot.docs, doc => doc.get('name'))),
    [trims],
  );
  const [trim, setTrim] = useState('');

  // others

  const [color, setColor] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validation, setValidation] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const currentUser = firebase.auth().currentUser;

  // -------------------------------- EFFECTS

  // when makes list changes, see if the selected make is still valid

  useEffect(() => {
    if (sortedMakesSnapshots && !sortedMakesSnapshots.find(snapshot => snapshot.get('name') === make)) {
      setMake('');
    }
  }, [sortedMakesSnapshots, make]);

  // when models list changes, see if the selected model is still valid

  useEffect(() => {
    if (sortedModelsSnapshots && !sortedModelsSnapshots.find(snapshot => snapshot.get('name') === model)) {
      setModel('');
    }
  }, [sortedModelsSnapshots, model]);

  // when trims list changes, see if the selected trim is still valid

  useEffect(() => {
    if (sortedTrimsSnapshots && !sortedTrimsSnapshots.find(snapshot => snapshot.get('name') === trim)) {
      setTrim('');
    }
  }, [sortedTrimsSnapshots, trim]);

  // -------------------------------- METHODS

  const submitForm = event => {
    event.preventDefault();

    const validation = {
      year: Boolean(year),
      make: Boolean(make),
      model: Boolean(model),
      trim: true,
      color: Boolean(color),
      licensePlate: Boolean(licensePlate),
      email: currentUser || Boolean(email),
      password: currentUser || Boolean(password),
    };

    setValidation(validation);

    if (Object.values(validation).includes(false)) {
      console.info('Validation failed', validation);
      return;
    }
    console.log('Validation succeeded');

    // create or use existing account

    setIsCreating(true);
    const promise = currentUser
      ? createApplicant()
      : logPromise('Create user', firebase.auth().createUserWithEmailAndPassword(email, password)).then(
          credential => {
            return createApplicant();
          },
          createUserError => {
            switch (createUserError.code) {
              case 'auth/invalid-email':
                throw userFacingError('Invalid email address');
              case 'auth/weak-password':
                throw userFacingError(
                  'Password is too weak. Make sure to use uppercase and lowercase letters, plus special characters',
                );
              case 'auth/email-already-in-use':
                return logPromise('Sign in', firebase.auth().signInWithEmailAndPassword(email, password)).then(
                  credential => {
                    return createApplicant();
                  },
                  signInError => {
                    switch (signInError.code) {
                      case 'auth/too-many-requests':
                        throw userFacingError('Too many attempts. Please wait a while and try again later');
                      case 'auth/user-disabled':
                        throw userFacingError('Your user account has been disabled');
                      case 'auth/wrong-password':
                        throw userFacingError('Incorrect email or password');
                      default:
                        throw signInError;
                    }
                  },
                );
              default:
                throw createUserError;
            }
          },
        );
    promise.catch(anyError => {
      console.error(anyError);
      setErrorMessage(
        anyError.userFacingMessage ||
          (anyError.reason ? anyError.message : null) ||
          'There was an error setting up your account',
      );
      setIsCreating(false);
    });
  };

  const createApplicant = () => {
    const trimId = trim ? trims.snapshot.docs.find(doc => doc.get('name') === trim).id : trims.snapshot.docs[0].id; // default to the first trim

    return ScoopMApi.instance.drivers.applicant.create(token, trimId, color, licensePlate).then(
      response => {
        console.log(response.data);
        setTimeout(() => {
          history.push('/profile');
        }, 500);
      },
      error => {
        switch (error.reason) {
          case ErrorReason.driverAlreadyCreated:
            throw userFacingError('Your driver account is already set up');
          case ErrorReason.emailDoesNotMatch:
            throw userFacingError('That email address does not match the one you used in your application');
          default:
            throw error;
        }
      },
    );
  };

  // -------------------------------- RENDER

  if (!token) {
    return (
      <div className="container py-4">
        <div className="row">
          <div className="col-sm">
            <div className="alert alert-warning">
              <h5 className="mb-0">Invalid link</h5>
              <p className="mb-0">
                If you need help, <a href="mailto:support@waveride.co">contact support</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (years.error) {
    return (
      <AuthLayout>
        <div className="alert alert-info">
          <h5>Unable to continue</h5>
          <p className="mb-0">Please try again later, or contact support</p>
        </div>
      </AuthLayout>
    );
  }

  return (
    <div className="h-100 d-flex flex-column container py-4">
      <div className="text-center mb-3 mb-md-4">
        <Link to="/">
          <img src={logo} alt="Wave" width="125" height="138" />
        </Link>
      </div>

      <h2>Set up your account</h2>

      <p className="lead">
        Complete this form to create your Wave driver account. If you already have an email and password for Wave, use
        them here.
      </p>

      <form onSubmit={submitForm} disabled={isCreating}>
        <div className="bg-light p-2 p-md-3 mb-2 mb-md-3 rounded">
          <div className="row">
            <Section title="Vehicle details">
              <div className="mb-2">
                {/* <FormLabel htmlFor="yearInput">Year</FormLabel> */}
                <ValidateableInput
                  elementType={FormControl}
                  as="select"
                  className="custom-select"
                  isValid={unwrap(validation, validation => validation.year)}
                  id="yearInput"
                  value={year}
                  onChange={event => setYear(event.target.value ? Number(event.target.value) : '')}
                  disabled={!years.snapshot || isCreating}>
                  {years.isLoading ? (
                    <option value="">Loading...</option>
                  ) : (
                    unwrap(sortedYearsSnapshots, snapshots => (
                      <>
                        <option value="">Year</option>
                        {snapshots.map(doc => (
                          <option key={doc.id} value={doc.get('value')}>
                            {doc.get('value')}
                          </option>
                        ))}
                      </>
                    ))
                  )}
                </ValidateableInput>
              </div>

              <div className="mb-2">
                {/* <FormLabel htmlFor="makeInput">Make</FormLabel> */}
                <ValidateableInput
                  elementType={FormControl}
                  as="select"
                  className="custom-select"
                  isValid={unwrap(validation, validation => validation.make)}
                  id="makeInput"
                  value={make}
                  onChange={event => setMake(event.target.value)}
                  disabled={!makes.snapshot || !year || isCreating}>
                  {makes.isLoading ? (
                    <option value="">Loading...</option>
                  ) : (
                    unwrap(sortedMakesSnapshots, snapshots => (
                      <>
                        <option value="">Make</option>
                        {snapshots.map(doc => (
                          <option key={doc.id} value={doc.get('name')}>
                            {doc.get('name')}
                          </option>
                        ))}
                      </>
                    ))
                  )}
                </ValidateableInput>
              </div>

              <div className="mb-2">
                {/* <FormLabel htmlFor="modelInput">Model</FormLabel> */}
                <ValidateableInput
                  elementType={FormControl}
                  as="select"
                  className="custom-select"
                  isValid={unwrap(validation, validation => validation.model)}
                  id="modelInput"
                  value={model}
                  onChange={event => setModel(event.target.value)}
                  disabled={!models.snapshot || !make || isCreating}>
                  {models.isLoading ? (
                    <option value="">Loading...</option>
                  ) : (
                    unwrap(sortedModelsSnapshots, snapshots => (
                      <>
                        <option value="">Model</option>
                        {snapshots.map(doc => (
                          <option key={doc.id} value={doc.get('name')}>
                            {doc.get('name')}
                          </option>
                        ))}
                      </>
                    ))
                  )}
                </ValidateableInput>
              </div>

              <div className="mb-2">
                {/* <FormLabel htmlFor="trimInput">Trim</FormLabel> */}
                <ValidateableInput
                  id="trimInput"
                  elementType={FormControl}
                  as="select"
                  value={trim}
                  onChange={event => setTrim(event.target.value)}
                  isValid={unwrap(validation, validation => validation.trim)}
                  className="custom-select"
                  disabled={!trims.snapshot || !model || isCreating}>
                  {trims.isLoading ? (
                    <option value="">Loading...</option>
                  ) : (
                    unwrap(sortedTrimsSnapshots, snapshots => (
                      <>
                        <option value="">Trim (optional)</option>
                        {snapshots.map(doc => (
                          <option key={doc.id} value={doc.get('name')}>
                            {doc.get('name')}
                          </option>
                        ))}
                      </>
                    ))
                  )}
                </ValidateableInput>
              </div>

              <div className="mb-2">
                {/* <FormLabel htmlFor="colorInput">Color</FormLabel> */}
                <ValidateableInput
                  elementType={FormControl}
                  as="select"
                  className="custom-select"
                  isValid={unwrap(validation, validation => validation.color)}
                  id="colorInput"
                  value={color}
                  onChange={event => setColor(event.target.value)}
                  disabled={!model || isCreating}>
                  <option value="">Color</option>
                  {VehicleColor.allCases.map(color => (
                    <option key={color.rawValue} value={color.rawValue}>
                      {color.name()}
                    </option>
                  ))}
                </ValidateableInput>
              </div>

              <div className="mb-2">
                <ValidateableInput
                  value={licensePlate}
                  onChange={event => setLicensePlate(event.target.value)}
                  isValid={unwrap(validation, validation => validation.licensePlate)}
                  className="form-control"
                  placeholder="License plate"
                  disabled={!color || isCreating}
                />
              </div>
            </Section>

            <Section title="Email and password">
              {currentUser && (
                <div className="mb-2 d-flex align-items-center text-muted">
                  <i className="fa fa-check mr-1" /> You are signed in as {currentUser.email}.{' '}
                  <button
                    type="button"
                    onClick={event => firebase.auth().signOut().catch(console.error)}
                    className="btn btn-link text-muted">
                    Sign out
                  </button>
                </div>
              )}

              <div className="mb-2">
                {/* <FormLabel htmlFor="emailInput">Email</FormLabel> */}
                <ValidateableInput
                  id="emailInput"
                  elementType={FormControl}
                  type="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  isValid={unwrap(validation, validation => validation.email)}
                  autoComplete="email"
                  placeholder="Email"
                  disabled={currentUser || isCreating}
                />
              </div>

              <div className="mb-2">
                {/* <FormLabel htmlFor="passwordInput">Password</FormLabel> */}
                <ValidateableInput
                  elementType={FormControl}
                  isValid={unwrap(validation, validation => validation.password)}
                  id="passwordInput"
                  type="password"
                  autoComplete="new-password"
                  placeholder="Password"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  disabled={currentUser || isCreating}
                />
              </div>
            </Section>
          </div>
        </div>

        {errorMessage && (
          <div className="alert alert-warning mb-2 mb-md-3">
            <i className="fas fa-exclamation-circle" /> {errorMessage}
          </div>
        )}

        <div>
          <button type="submit" className="btn btn-lg btn-primary" disabled={isCreating}>
            {isCreating ? (
              <>
                <i className="fas fa-circle-notch fa-spin" /> Creating account...
              </>
            ) : (
              'Create account'
            )}
          </button>
        </div>
      </form>

      <div className="flex-grow-1 d-flex justify-content-center align-items-end mt-3 text-gray-500">
        <small className="font-weight-light">&copy; {moment().year()} Wave Mobile Technologies, Inc.</small>
      </div>
    </div>
  );
}

function Section({title, children}) {
  return (
    <div className="col-sm mb-2 mb-sm-0">
      <h5 className="mb-3 text-gray-700">{title}</h5>
      {children}
    </div>
  );
}
