"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Removes `undefined` values by parsing to a JSON string and then back to a plain JavaScript object
 * Also removes any "key" or "id" properties
 * @param {Object} object
 */
function cleanForFirebase(object) {
    const pojo = JSON.parse(JSON.stringify(object));
    if (pojo.id)
        pojo.id = null;
    if (pojo.key)
        pojo.key = null;
    return pojo;
}
exports.default = cleanForFirebase;
