import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeV5} from './FontAwesome';

export default function PrivacyNotice({children}: {children: ReactNode}) {
  return (
    <small className="form-text text-gray-500">
      <FontAwesomeV5 name="hand" /> {children}.{' '}
      <Link
        to="/privacy-policy"
        className="text-gray-500 text-decoration-underline"
        target="_blank"
        rel="noopener noreferrer">
        Privacy policy
      </Link>
    </small>
  );
}
