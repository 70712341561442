import {Badge} from '@material-ui/core';
import React from 'react';
import joinClassNames from '../../functions/joinClassNames';
import DropdownMenu from '../DropdownMenu';
import FontAwesome from '../FontAwesome';
import SelectionAction from './SelectionAction';
import SelectionActionButton from './SelectionActionButton';
import SortingDirection from './SortingDirection';

const columnClassname = 'firestore-head-column d-flex align-items-center px-2 border-right bg-gray-200';

export const selectionColumnWidth = 60;

export default function HeadComponent({
  columns,
  sortingColumn,
  onClickColumnHeading,
  sortingDirection,
  allowSelection,
  selection,
  selectionActions,
  setSelection,
  rows,
}) {
  return (
    <>
      {/* selection */}

      {allowSelection && (
        <div className={columnClassname} style={{width: selectionColumnWidth}}>
          {selectionActions && (
            <DropdownMenu
              text={
                <Badge
                  badgeContent={selection.length}
                  anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                  color="primary">
                  <FontAwesome.List />
                </Badge>
              }
              className="align-self-stretch flex-grow-1 d-flex py-1"
              buttonClassName="btn btn-sm py-0 bg-white"
              anyWidth
              dismissAlways>
              <div className="d-flex flex-column">
                <SelectionActionButton
                  action={new SelectionAction('Select all', selection => setSelection([...rows]))}
                  selection={selection}
                  className="rounded-top"
                  disabled={!rows || !rows.length || selection.length === rows.length}
                />
                <SelectionActionButton
                  action={new SelectionAction('Deselect all', selection => setSelection([]))}
                  selection={selection}
                  disabled={!selection.length}
                />
                <hr className="my-0" />
                {selectionActions.map((action, i) => (
                  <SelectionActionButton
                    key={i}
                    action={action}
                    selection={selection}
                    className={i === selectionActions.length - 1 ? 'rounded-bottom' : undefined}
                    disabled={selection.length === 0}
                  />
                ))}
              </div>
            </DropdownMenu>
          )}
        </div>
      )}

      {/* headings */}

      {columns.map((column, i) => {
        const noSorting = column.disableSort || !onClickColumnHeading;
        const isSelected = column.path === sortingColumn;
        return (
          <div
            key={column.path + i}
            onClick={noSorting ? null : onClickColumnHeading(column)}
            className={joinClassNames(columnClassname, isSelected && 'bg-gray-300', noSorting && 'cursor-pointer')}
            style={{width: column.width}}>
            <div className="d-flex justify-content-between align-items-center">
              {column.name}
              {isSelected && (
                <i
                  className={`fas ${
                    sortingDirection === SortingDirection.ascending ? 'fa-angle-down' : 'fa-angle-up'
                  } ml-1`}
                />
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}
