import React, {useMemo} from 'react';
import {unwrap, useStatus} from 'wave-common';
import {vehicleModelListForYear_Make} from 'wave-common/lib/controllers/VehicleModel';
import FirestoreDataSource from '../../data-sources/FirestoreDataSource';
import joinClassNames from '../../functions/joinClassNames';
import InvalidFeedback from './InvalidFeedback';
import PropertyInputProps from './PropertyInputProps';

export default function VehicleModelInput({
  id,
  property,
  value,
  model,
  onChange,
  validationError,
  disabled,
}: PropertyInputProps) {
  const {
    isPending,
    error,
    value: models,
  } = useStatus(
    React as any,
    useMemo(
      () =>
        unwrap(model.year, year =>
          unwrap(model.make, make => vehicleModelListForYear_Make(year, make, FirestoreDataSource)),
        ),
      [model.year, model.make],
    ),
  );

  return (
    <>
      <select
        name={id}
        id={id}
        value={value || ''}
        onChange={event => onChange(event.target.value)}
        className={joinClassNames(
          'custom-select',
          unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid')),
        )}
        disabled={Boolean(isPending || error || !model.make || disabled)}>
        <option value="">{!model.make ? 'Select make' : property.isOptional ? 'None' : 'Select...'}</option>
        {models?.map(model => (
          <option key={model.id} value={model.name}>
            {model.name}
          </option>
        ))}
      </select>
      <InvalidFeedback error={validationError} />
    </>
  );
}
