import moment from 'moment';
import React, {useMemo, useState} from 'react';
import FirebaseTable from '../../../components/FirebaseTable';
import unwrap from '../../../functions/unwrap';

type Event = any;

export default function EzCater() {
  const [orderId, setOrderId] = useState('');

  const orderIdParsed = useMemo(() => orderId.trim().replaceAll(/ |-|#/g, ''), [orderId]);

  return (
    <FirebaseTable
      title="API Events"
      path="events"
      limitToLast={500}
      orderByChild={orderIdParsed ? 'requestBody/order/orderNumber' : 'path'}
      equalTo={orderIdParsed ? orderIdParsed : '/v1/dispatch/ezcater'}
      reverse
      link={(event: {key: string}) => `/admin/events/${event.key}`}
      linkTitle={((event: object) => JSON.stringify(event, null, '\t')) as never}
      additionalBarComponents={
        (
          <input
            value={orderId}
            onChange={(event: Event) => setOrderId(event.target.value)}
            className="form-control"
            placeholder="Order ID"
          />
        ) as never
      }
      columns={[
        {
          heading: 'Event',
          value: (event: Event) => event.requestHeaders['x-ezcater-event'],
        },
        {
          heading: 'Delivery ID',
          value: (event: Event) => unwrap(event.requestBody, body => body.id),
        },
        {heading: 'Method', value: (event: Event) => event.method},
        {heading: 'Path', value: (event: Event) => event.path},
        {
          heading: 'Time',
          value: (event: Event) => moment(event.timestampMs).calendar(),
        },
        {
          heading: 'Duration (seconds)',
          value: (event: Event) =>
            unwrap(event.responseDurationS, duration =>
              duration < 5 ? (
                duration
              ) : duration < 15 ? (
                <span className="text-warning">{duration}</span>
              ) : (
                <span className="text-danger">{duration}</span>
              ),
            ),
        },
        {
          heading: 'Response',
          value: (event: Event) => {
            if (event.responseCode >= 200 && event.responseCode < 300) {
              return (
                <span className="text-success">
                  {event.responseCode} {event.responseText}
                </span>
              );
            } else {
              return (
                <span className="text-danger">
                  {event.responseCode} {event.responseText}
                </span>
              );
            }
          },
        },
      ]}
    />
  );
}
