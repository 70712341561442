import RealtimeDatabaseModel from '../base/RealtimeDatabaseModel';

export default class User extends RealtimeDatabaseModel {
  constructor(
    public firstName: string,
    public lastName: string,
    public nickName?: string,
    public phone?: string,
    public cellphone?: string,
    public email?: string,
  ) {
    super();
  }

  phoneSafe() {
    return this.cellphone ?? this.phone;
  }

  fullName() {
    const parts = [this.firstName, this.lastName].filter(Boolean);
    if (!parts.length) return undefined;
    return parts.join(' ');
  }

  casualName() {
    return [this.nickName || this.firstName, this.lastName].filter(Boolean).join(' ');
  }

  formalName() {
    return this.fullName();
  }
}
