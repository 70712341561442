import HttpReference from '../platform/HttpReference';
import OloQuotes from './OloQuotes';

export default class Olo extends HttpReference {
  constructor(path, parent) {
    super(path, parent);

    this.quotes = new OloQuotes('quotes', this);
  }
}
