"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emptyTripRideshareNew = exports.emptyTripNew = void 0;
const Color_1 = require("./Color");
function emptyTripNew() {
    return {
        locations: [undefined, undefined],
    };
}
exports.emptyTripNew = emptyTripNew;
function emptyTripRideshareNew({ deviceType, source, }, database) {
    const colors = [...Color_1.colorAll];
    const firstIndex = Math.floor(Math.random() * colors.length);
    const firstColor = colors[firstIndex];
    const remainingColors = colors.splice(firstIndex, 1);
    const secondColor = remainingColors[Math.floor(Math.random() * remainingColors.length)];
    return Object.assign({ createdAt: database.serverTimestamp, deviceType,
        // estimate: null, // set by server
        firstColor,
        secondColor, status: 'CREATED', source }, emptyTripNew());
}
exports.emptyTripRideshareNew = emptyTripRideshareNew;
