import React from 'react';
import {GridChildComponentProps} from 'react-window';
import Cell from './Cell';
import CellOfAction from './CellOfAction';
import CellOfCheckbox from './CellOfCheckbox';
import CellOfHeading from './CellOfHeading';
import CellOfMarker from './CellOfMarker';
import CellOfProperty from './CellOfProperty';
import CellOfToggler from './CellOfToggler';

export default function CellSwitch({columnIndex, rowIndex, data: rows, style}: GridChildComponentProps<Cell[][]>) {
  const cell = rows[rowIndex][columnIndex];
  switch (cell.type) {
    case 'CHECKBOX':
      return <CellOfCheckbox cell={cell} style={style} />;
    case 'HEADING':
      return <CellOfHeading cell={cell} style={style} />;
    case 'MARKER':
      return <CellOfMarker cell={cell} style={style} />;
    case 'PROPERTY':
      return <CellOfProperty cell={cell} style={style} />;
    case 'TOGGLER':
      return <CellOfToggler cell={cell} style={style} />;
    case 'ACTION':
      return <CellOfAction cell={cell} style={style} />;
    case 'SPACER':
    case 'EMPTY':
      return <div style={style} onMouseEnter={cell.onMouseEnter} />;
    default:
      return <div style={style} />;
  }
}
