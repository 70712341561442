"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function join(elements, separator) {
    const joined = new Array();
    elements.forEach((element, i) => {
        joined.push(element);
        if (i < elements.length - 1) {
            joined.push(separator(element, i));
        }
    });
    return joined;
}
exports.default = join;
