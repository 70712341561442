"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coordinatesObjectFromLatLng = exports.coordinatesObjectCoordinatesArray = void 0;
function coordinatesObjectCoordinatesArray(array) {
    return {
        latitude: array[0],
        longitude: array[1],
    };
}
exports.coordinatesObjectCoordinatesArray = coordinatesObjectCoordinatesArray;
function coordinatesObjectFromLatLng(latLng) {
    return {
        latitude: latLng.lat,
        longitude: latLng.lng,
    };
}
exports.coordinatesObjectFromLatLng = coordinatesObjectFromLatLng;
