import React from 'react';
import DriversTable from './DriversTable';

export default function FountainDrivers() {
  return (
    <DriversTable
      title="Fountain applicants"
      orderByChild="status"
      equalTo="approved"
      filter={driver => (driver as any).fountainApplicantId}
    />
  );
}
