import React from 'react';
import Anchor from '../../components/Anchor';
import fifth from '../../images/home/fifth.png';

export default function HowItWorks() {
  return (
    <div className="bg-light" style={{overflow: 'hidden'}}>
      <Anchor id="how-it-works" />

      <div className="container position-relative" style={{paddingTop: '12rem', paddingBottom: '12rem'}}>
        <img
          src={fifth}
          alt="Screenshot"
          style={{top: 0, right: '-42rem'}}
          className="position-absolute d-none d-lg-inline"
        />

        <h1 className="display-4 mb-5">How it works</h1>
        <ol>
          <li>
            <h4>Download app</h4>
            <p className="text-muted">Go get the app from the Google Play store or the App Store</p>
          </li>
          <li>
            <h4>Register</h4>
            <p className="text-muted">Register and add payment methods</p>
          </li>
          <li>
            <h4>Call your ride</h4>
            <p className="text-muted">Call your ride, and enjoy!</p>
          </li>
        </ol>
      </div>
    </div>
  );
}
