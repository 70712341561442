import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';
import {iconMapPin3FillBorder, iconMapPin3FillBorderPink} from '../components/GoogleMaps/helpers';
import {latLng} from '../components/GoogleMaps/LatLng+additions';
import MapContents from '../components/GoogleMaps/MapContents';
import Marker from '../components/GoogleMaps/Marker';
import Path from '../components/GoogleMaps/Path';
import DirectionsService from '../services/Directions';
import usePrevious from './usePrevious';

export default function useMapPropsForTripLocations(pickupLocation, dropOffLocation) {
  const [state, setState] = useState(new State());

  const previousPickupLocaiton = usePrevious(pickupLocation);
  const previousDropOffLocation = usePrevious(dropOffLocation);

  useEffect(() => {
    // ignore if no change

    if (isEqual(pickupLocation, previousPickupLocaiton) && isEqual(dropOffLocation, previousDropOffLocation)) {
      return;
    }

    if (pickupLocation) {
      if (dropOffLocation) {
        setState(new State());
        DirectionsService.getDirections(pickupLocation.coordinates, null, dropOffLocation.coordinates).then(
          directions => {
            const route = directions.routes[0];
            const leg = route.legs[0];
            setState(
              new State(
                new MapContents.Bounds({
                  id: 'FULL_ROUTE',
                  latLngBounds: route.bounds,
                }),
                [
                  new Marker({
                    id: 'PICKUP',
                    position: leg.start_location,
                    icon: iconMapPin3FillBorder(),
                  }),
                  new Marker({
                    id: 'DROP_OFF',
                    position: leg.end_location,
                    icon: iconMapPin3FillBorderPink(),
                  }),
                ],
                [Path.forGoogleMapsDirections('id', directions)],
                leg,
              ),
            );
          },
          error => {
            console.error(error);
          },
        );
      } else {
        setState(
          new State(
            new MapContents.Coordinates({
              id: 'PICKUP_TEMP',
              latLng: latLng(pickupLocation.coordinates),
              zoom: 14,
            }),
            [
              new Marker({
                id: 'PICKUP_TEMP',
                icon: false,
                position: pickupLocation.coordinates,
              }),
            ],
            null,
            null,
          ),
        );
      }
    } else {
      setState(new State());
    }
  }, [pickupLocation, previousPickupLocaiton, dropOffLocation, previousDropOffLocation]);

  return state;
}

class State {
  constructor(mapContents = MapContents.Bounds.unitedStates(), markers, paths, leg) {
    this.mapContents = mapContents;
    this.markers = markers;
    this.paths = paths;
    this.leg = leg;
  }
}
