import React from 'react';
import unwrap from '../../../functions/unwrap';
import SelectOption from '../../../models/SelectOption';
import NavbarColumn from './NavbarColumn';
import NavbarHeading from './NavbarHeading';

export default function NavbarSelect(props: {
  id: string;
  text: string;
  options?: Array<SelectOption>;
  value?: string | null;
  onChange: (value: string | undefined) => void;
  noEmptyValue?: boolean;
}) {
  return (
    <NavbarColumn>
      <NavbarHeading text={props.text} />
      <select
        value={props.value ?? ''}
        onChange={event => props.onChange(event.target.value || undefined)}
        className="custom-select custom-select-sm border-0"
        // style={{ borderColor: 'rgba(255, 255, 255, 0.4)', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
      >
        {!props.noEmptyValue && (
          <>
            <option value="">All</option>
            <option value="" disabled>
              -
            </option>
          </>
        )}
        {unwrap(props.options, options =>
          options.map(option => (
            <option key={option.value} value={option.value} disabled={!option.enabled}>
              {option.text ?? option.value}
            </option>
          )),
        )}
      </select>
    </NavbarColumn>
  );
}
