"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cancellationReasonTitle = exports.cancellationReasonDeserialize = exports.cancellationReasonAll = void 0;
exports.cancellationReasonAll = [
    'CHANGE_OF_PLANS',
    'CLEANLINESS',
    'DRIVER_LATE',
    'DRIVER_MANNERS',
    'EASE_OF_USE',
    'NO_DRIVERS_FOUND',
    'PRICE',
];
function cancellationReasonDeserialize(value) {
    if (!exports.cancellationReasonAll.includes(value)) {
        throw new Error(`Invalid cancellation reason "${value}"`);
    }
    return value;
}
exports.cancellationReasonDeserialize = cancellationReasonDeserialize;
function cancellationReasonTitle(reason) {
    switch (reason) {
        case 'CHANGE_OF_PLANS':
            return 'Change of plans';
        case 'CLEANLINESS':
            return 'Vehicle cleanliness';
        case 'DRIVER_LATE':
            return 'Driver was late';
        case 'DRIVER_MANNERS':
            return "Driver's manners";
        case 'EASE_OF_USE':
            return 'App/service difficult to use';
        case 'NO_DRIVERS_FOUND':
            return 'No drivers available nearby';
        case 'PRICE':
            return 'Too expensive';
    }
}
exports.cancellationReasonTitle = cancellationReasonTitle;
