import unwrap from '../functions/unwrap';
import Driver from '../models/scoopm/Driver';
import useRealtimeDatabase from './useRealtimeDatabase';

export default function useDriver(id: string | undefined) {
  return useRealtimeDatabase({
    path: unwrap(id, id => `/drivers/${id}`),
    Model: Driver,
  });
}
