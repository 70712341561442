import round from 'lodash/round';
import React, {useMemo} from 'react';
import GoogleMap from '../../components/GoogleMaps/GoogleMap';
import {boundsforCircle, latLng} from '../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../components/GoogleMaps/MapContents';
import {ScoopMap} from '../../components/Maps/ScoopMap';
import unwrap from '../../functions/unwrap';

export default function GeoCircleInput({id, property, value, onChange, validationError, disabled}) {
  const circle = useMemo(
    () => ({
      center: unwrap(value, value => unwrap(value.center, latLng)) || ScoopMap.charlotte,
      radiusM: unwrap(value, value => value.radiusM) || 10_000,
      editable: true,
      onRadiusChanged: radiusM => onChange(Object.assign({}, value, {radiusM})),
      onCenterChanged: center => onChange(Object.assign({}, value, {center: [center.lat(), center.lng()]})),
    }),
    [value, onChange],
  );

  const mapContents = useMemo(
    () =>
      new MapContents.Bounds({
        id: 'CIRCLE',
        name: 'Circle',
        latLngBounds: boundsforCircle(circle.center, circle.radiusM),
      }),
    [circle],
  );

  return (
    <div className="position-relative">
      {validationError && <div className="text-danger">{validationError.message}</div>}
      Radius:{' '}
      {unwrap(value, value => unwrap(value.radiusM, radius => `${round(radius / 1000, 1)} kilometers`)) || 'Not set'}
      <div style={{height: '350px'}} className={'mt-2   ' + (validationError ? ' border border-danger' : '')}>
        <GoogleMap
          mapContents={mapContents}
          circles={[circle]}
          fullscreenControl={false}
          streetViewControl={false}
          rounded
        />
      </div>
    </div>
  );
}
