import omit from 'lodash/omit';
import React, {Dispatch, useCallback, useEffect} from 'react';
import {Provider as ReactReduxProvider, ProviderProps, useDispatch, useSelector} from 'react-redux';
import {Action, AnyAction, applyMiddleware, createStore, Reducer} from 'redux';
import {useUserGroupVendorIds} from '../hooks/useUserGroupVendor';

// state

interface State {
  selectedVendorId: string | undefined;
}

function defaultState(): State {
  return {
    selectedVendorId: window.localStorage.getItem('SELECTED_VENDOR_ID') ?? undefined,
  };
}

// actions

interface SetSelectedVendorId extends Action<'selectedVendorId/set'> {
  id: string | undefined;
}

function setSelectedVendorId(id: string | undefined): SetSelectedVendorId {
  return {
    type: 'selectedVendorId/set',
    id,
  };
}

type Actions = SetSelectedVendorId;

// reducer

const reducer: Reducer<State, Actions> = (state: State | undefined = defaultState(), action: Actions) => {
  switch (action.type) {
    case 'selectedVendorId/set':
      return {selectedVendorId: action.id};
    default:
      return state;
  }
};

// middleware

const saveToLocalStorage = (api: any) => (next: Dispatch<AnyAction>) => (action: Actions) => {
  switch (action.type) {
    case 'selectedVendorId/set': {
      if (action.id) {
        window.localStorage.setItem('SELECTED_VENDOR_ID', action.id);
      } else {
        window.localStorage.removeItem('SELECTED_VENDOR_ID');
      }
    }
  }
  return next(action);
};

// store

const localStorageStore = createStore(reducer, applyMiddleware(saveToLocalStorage));

export function Provider(props: ProviderProps<Actions>) {
  return <ReactReduxProvider store={localStorageStore} {...omit(props, 'store')} />;
}

// hooks

export function useSelectedVendorId(): [string | undefined, (id: string) => void] {
  const selection = useSelector((state: State) => state.selectedVendorId);
  const dispatch = useDispatch<Dispatch<SetSelectedVendorId>>();
  const setter = useCallback(
    (id: string | undefined) => {
      dispatch(setSelectedVendorId(id));
    },
    [dispatch],
  );

  // check if vendor id belongs to the user's groups
  const {isLoading, error, vendorIds} = useUserGroupVendorIds();
  useEffect(() => {
    if (selection && vendorIds && !vendorIds.includes(selection)) {
      setter(undefined);
    }
  }, [selection, vendorIds, setter]);

  return [selection, setter];
}

// export function useLocalStorage<T extends Values>(
//     key: Keys,
//     serializer: Serializer<T>,
//     defaultValue?: T,
// ) {

//     const selection = ReactRedux.useSelector<State>(state => state[key])
//     const dispatch = ReactRedux.useDispatch<Dispatch<Actions>>()

//     useEffect(() => {
//         const value = window.localStorage.getItem(key)
//         const deserializedValue = serializer.deserialize(value)
//         if (value) {
//             dispatch({ type: 'set', key, value: deserializedValue })
//         }
//     }, [key, dispatch, options])

//     const setValue = useCallback((value: T) => {
//         const serializedValue = (options?.serialize ?? String)(value)
//         dispatch({ type: 'set', key, value: serializedValue })
//     }, [key, dispatch, options])

//     return [selection, setValue]

// }

// interface Serializer<T> {
//     serialize: (value: T) => string
//     deserialize: (string: string | null) => T | null
// }

// class StringSerializer implements Serializer<string> {
//     serialize(value: string) { return value }
//     deserialize(string: string | null) { return string }
// }
