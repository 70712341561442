import React from 'react';
import CenteredLayout from '../../layouts/CenteredLayout';

export default function Return() {
  return (
    <CenteredLayout className="bg-white">
      <h5 className="text-muted">
        <i className="fas fa-check mr-2" /> You may now close this page
      </h5>
    </CenteredLayout>
  );
}
