import React from 'react';
import BORDER_RADIUS from './BORDER_RADIUS';
import {CellOfMarker as CellOfMarkerType} from './Cell';
import CellProps from './CellProps';

export default function CellOfMarker({cell, style}: CellProps<CellOfMarkerType>) {
  return (
    <div
      onMouseEnter={cell.onMouseEnter}
      style={{
        backgroundColor: cell.color,
        borderTopLeftRadius: cell.roundedTopLeft ? BORDER_RADIUS : 0,
        borderBottomLeftRadius: cell.roundedBottomLeft ? BORDER_RADIUS : 0,
        ...style,
      }}
    />
  );
}
