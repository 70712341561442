import unhandledCase from '../../functions/unhandledCase';
import Enum from '../base/Enum';

export default class PricingFormulaInput extends Enum {
  get title() {
    switch (this) {
      case PricingFormulaInput.charge:
        return 'Order value';
      case PricingFormulaInput.miles:
        return 'Miles';
      case PricingFormulaInput.minutes:
        return 'Minutes';
      case PricingFormulaInput.tip:
        return 'Tip';
      case PricingFormulaInput.revenue:
        return 'Revenue';
      default:
        return null;
    }
  }
}

PricingFormulaInput.charge = new PricingFormulaInput('CHARGE');
PricingFormulaInput.miles = new PricingFormulaInput('MILES');
PricingFormulaInput.minutes = new PricingFormulaInput('MINUTES');
PricingFormulaInput.tip = new PricingFormulaInput('TIP');
PricingFormulaInput.revenue = new PricingFormulaInput('REVENUE');

PricingFormulaInput.value = (input, charge, miles, minutes, tip, revenue) => {
  switch (PricingFormulaInput.from(input)) {
    case PricingFormulaInput.charge:
      return charge;
    case PricingFormulaInput.miles:
      return miles;
    case PricingFormulaInput.minutes:
      return minutes;
    case PricingFormulaInput.tip:
      return tip;
    case PricingFormulaInput.revenue:
      return revenue;
    default:
      throw unhandledCase(input);
  }
};
