import firebase from 'firebase/app';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import React, {useMemo, useState} from 'react';
import {Redirect} from 'react-router-dom';
import Card from '../../components/Card';
import cleanForFirebase from '../../functions/cleanForFirebase';
import SchemaForm from './SchemaForm';

export default function FirestoreModelForm({
  schema,
  id = undefined,
  model = null,
  onSave = null,
  onCancel = null,
  shouldUpdate = false,
  hadQueryValues = false,
}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const originalValue = useMemo(() => cloneDeep(model), []);

  const [idUponCompletion, setIdUponCompletion] = useState();

  const collection = useMemo(() => schema.collection(), [schema]);

  const onSaveInternal = newModel => {
    let modelToUpload = {...newModel};

    if (!hadQueryValues) {
      if (originalValue) {
        for (const key in modelToUpload) {
          if (Object.hasOwnProperty.call(modelToUpload, key)) {
            if (isEqual(modelToUpload[key], originalValue[key])) {
              delete modelToUpload[key];
            }
          }
        }
      }
    }

    modelToUpload = cleanForFirebase(modelToUpload);

    // console.log({
    //     originalValue,
    //     newModel,
    //     modelToUpload
    // })

    console.log(`${shouldUpdate ? 'Updating' : 'SEtting'} "${collection}"...`, modelToUpload);

    const reference = id
      ? firebase.firestore().collection(collection).doc(id)
      : firebase.firestore().collection(collection).doc();

    const promise = shouldUpdate ? reference.update(modelToUpload) : reference.set(modelToUpload);

    return promise.then(
      () => {
        console.info(`Set "${collection}"`);

        if (onSave) {
          onSave();
        } else {
          setIdUponCompletion(reference.id);
        }
      },
      error => {
        console.error(`Error setting "${collection}"`, error);

        throw new Error('Error saving data');
      },
    );
  };

  if (idUponCompletion) {
    return <Redirect to={`/admin/${collection}/${idUponCompletion}`} />;
  }

  return (
    <Card padded>
      <SchemaForm schema={schema} onSave={onSaveInternal} defaultValue={model} onCancel={onCancel} />
    </Card>
  );
}
