import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React from 'react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function withStripe(WrappedComponent) {
  return function HigherOrderComponent({...props}) {
    return (
      <Elements stripe={stripePromise}>
        <WrappedComponent {...props} />
      </Elements>
    );
  };
}
