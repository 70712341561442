import React, {useCallback, useState} from 'react';
import {Link} from 'react-router-dom';
import FontAwesome from '../../../../components/FontAwesome';
import formatIntl from '../../../../functions/formatIntl';
import joinClassNames from '../../../../functions/joinClassNames';
import unwrap from '../../../../functions/unwrap';
import {Item} from '../../../../hooks/useGeoFireQuery';
import Driver from '../../../../models/scoopm/Driver';
import Trip from '../../../../models/scoopm/Trip';
import TripType from '../../../../models/scoopm/TripType';
import RealtimeDatabase from '../../../../references/database/RealtimeDatabase';
import DriverAssignGuard from './DriverAssignGuard';
import ZoomButton from './ZoomButton';

export default function DriversList<T>(props: {
  items?: Item<Driver>[];
  trip: Trip;
  searchRadiusKm?: number;
  selectedDriverId?: string;
  setSelectedDriverId: (id?: string) => void;
  showNearbyDrivers: boolean;
  setShowNearbyDrivers: (show: boolean) => void;
  isLoading: boolean;
}) {
  const [highlightedDriverId, setHighlightedDriverId] = useState();

  const queue = useCallback(
    (driver: Driver) => (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (!window.confirm(`Assign to ${driver.firstName} ${driver.lastName}?`)) return;
      RealtimeDatabase.instance.batchUpdate_queueDriver(props.trip.id, props.trip, driver.id, driver).catch(error => {
        window.alert(`There was an error assigning the trip. "${error.message}"`);
      });
    },
    [props.trip],
  );

  const assignNewDriver = useCallback(
    (driver: Driver) => (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (!window.confirm(`Re-assign to ${driver.firstName} ${driver.lastName}?`)) return;
      RealtimeDatabase.instance
        .batchUpdate_assignNewDriver(props.trip.id, props.trip, driver.id, driver as any)
        .catch(error => {
          window.alert(`There was an error re-assigning the trip. "${error.message}"`);
        });
    },
    [props.trip],
  );

  return (
    <div className="bg-white mb-5">
      <div className="p-2 p-md-3 bg-gray-200 border-bottom">
        <div className="row d-flex align-items-baseline">
          <div className="col">
            <h5 className="mb-0">Nearby drivers</h5>
            <span className="text-muted">
              {[
                unwrap(props.trip.typeType() as TripType | null, type => `Approved for ${type.title.toLowerCase()}`),
                props.searchRadiusKm &&
                  formatIntl(props.searchRadiusKm * 0.62137, {
                    style: 'decimal',
                    maximumSignificantDigits: 3,
                  } as any) + 'mi radius',
              ]
                .filter(Boolean)
                .join(', ')}
            </span>
          </div>
          <div className="col-auto">
            <button onClick={event => props.setShowNearbyDrivers(!props.showNearbyDrivers)} className="btn btn-link">
              {props.showNearbyDrivers ? <FontAwesome.EyeSolid /> : <FontAwesome.EyeSlashSolid />}
            </button>
            <div className="badge badge-secondary h6">
              {props.isLoading ? <i className="fas fa-circle-notch fa-spin" /> : String(props.items?.length ?? 0)}
            </div>
          </div>
        </div>
      </div>
      <div onMouseLeave={event => setHighlightedDriverId(undefined)}>
        {unwrap(props.items, items =>
          items.map((item, i) => {
            const {value: driver} = item;
            if (!driver) return null;
            return (
              <div
                key={i}
                onMouseEnter={event => setHighlightedDriverId(driver.id)}
                onClick={event =>
                  props.setSelectedDriverId(props.selectedDriverId === driver.id ? undefined : driver.id)
                }
                className={joinClassNames(
                  'd-flex flex-column border-bottom p-2 pl-md-3 cursor-pointer',
                  driver.id === props.selectedDriverId
                    ? 'bg-gray-100'
                    : driver.id === highlightedDriverId
                    ? 'bg-light'
                    : 'bg-white',
                )}>
                <div className="d-flex w-100 justify-content-between align-items-center mb-2">
                  <Link to={`/admin/drivers/${driver.id}`}>{driver.fullName()}</Link>
                  <ZoomButton
                    driverId={driver.id}
                    selectedDriverId={props.selectedDriverId}
                    setSelectedDriverId={props.setSelectedDriverId}
                  />
                </div>
                <div className="d-flex w-100 justify-content-between align-items-center">
                  {/* <span className="text-muted">{item.coordinatesArray}</span> */}
                  {formatIntl(
                    (window as any).google.maps.geometry.spherical.computeDistanceBetween(
                      new (window as any).google.maps.LatLng(...item.coordinatesArray),
                      props.trip.fromLatLng(),
                    ) * 0.00062137,
                    {
                      style: 'decimal',
                      maximumFractionDigits: 1,
                    } as any,
                  ) + 'mi'}
                  <DriverAssignGuard trip={props.trip}>
                    <div>
                      <button
                        onClick={props.trip.driverId ? assignNewDriver(driver) : queue(driver)}
                        className="btn btn-sm btn-outline-secondary">
                        Assign…
                      </button>
                    </div>
                  </DriverAssignGuard>
                </div>
              </div>
            );
          }),
        )}
      </div>
    </div>
  );
}
