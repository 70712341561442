import {latLng} from '../../components/GoogleMaps/LatLng+additions';
import Enum from '../base/Enum';

export default class City extends Enum {
  constructor(rawValue, boundsLiteral) {
    super(rawValue);
    this.boundsLiteral = boundsLiteral;
  }

  latLngBounds() {
    let bounds = new window.google.maps.LatLngBounds();
    bounds.extend(this.boundsLiteral.ne);
    bounds.extend(this.boundsLiteral.sw);
    return bounds;
  }

  title() {
    switch (this) {
      case City.atlanta:
        return 'Atlanta';
      case City.charlotte:
        return 'Charlotte';
      case City.losAngeles:
        return 'LA';
      case City.orlando:
        return 'Orlando';
      default:
        throw new Error('Unkown City');
    }
  }

  cornerLatLngs() {
    return [
      latLng(this.boundsLiteral.ne),
      latLng([this.boundsLiteral.ne.lat, this.boundsLiteral.sw.lng]),
      latLng(this.boundsLiteral.sw),
      latLng([this.boundsLiteral.sw.lat, this.boundsLiteral.ne.lng]),
    ];
  }

  contains(anyCoordinates) {
    const paths = City.prototype.cornerLatLngs.apply(this);
    const polygon = new window.google.maps.Polygon({paths});
    return window.google.maps.geometry.poly.containsLocation(latLng(anyCoordinates), polygon);
  }
}

City.atlanta = new City('ATLANTA', {
  ne: {lat: 34.3489, lng: -83.7167},
  sw: {lat: 33.1173, lng: -84.9091},
});
City.charlotte = new City('CHARLOTTE', {
  ne: {lat: 35.8905, lng: -79.9476},
  sw: {lat: 34.5697, lng: -81.7309},
});
City.losAngeles = new City('LOS_ANGELES', {
  ne: {lat: 34.545951, lng: -117.18763},
  sw: {lat: 33.526509, lng: -119.293612},
});
City.orlando = new City('ORLANDO', {
  ne: {lat: 28.836073, lng: -81.06837},
  sw: {lat: 27.846727, lng: -82.063372},
});
