import DataColumn from './DataColumn';

export default class DataRow {
  constructor(id, rawValue, href, columns, sortingValue, searchValue) {
    this.id = id;
    this.rawValue = rawValue;
    this.href = href;
    this.columns = columns;
    this.sortingValue = sortingValue;
    this.searchValue = searchValue;
  }
}

DataRow.creator = (columnMetadatas, sortingColumn, timezone) => (id, data, href, index) => {
  let sortingValue = null;

  const cols = columnMetadatas.map(column => {
    const rawValue = column.get ? column.get(data, id, index) : data[column.path];

    const formattedValue = column.format ? column.format(rawValue, data, timezone) : null;

    if (column.path === sortingColumn) {
      sortingValue = column.sortByFormattedValue ? formattedValue : rawValue;
      if (typeof sortingValue === 'string') sortingValue = sortingValue.toLowerCase();
    }

    return new DataColumn(rawValue, formattedValue, column.Component, column.width, column);
  });

  const searchValue = cols.map(col => String(col.formattedValue || col.rawValue).toLowerCase()).join('');

  return new DataRow(id, data, href, cols, sortingValue, searchValue);
};
