import unwrap from '../functions/unwrap';
import firebase from 'firebase/app';
import useEmails from './useEmails';

export default function useCurrentUserEmail() {
  const uid = unwrap(firebase.auth().currentUser, user => user.uid);

  const result = useEmails(unwrap(uid, uid => [uid]));

  return unwrap(result, result => result[uid].email, result);
}
