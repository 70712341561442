import firebase from 'firebase/app';
import React from 'react';
import Type from '../../functions/Type';
import Property from './Property';

export default class Schema<Model> {
  /**
   *
   * @param {String} name The schema's name, in snake case, singular. E.g., "trip-type"
   * @param {Array<Property>} properties
   */
  constructor(
    public name: string,
    public properties: Property[],
    public AdditionalContentComponent?:
      | React.Component<AdditionalContentComponentProps<Model>>
      | React.FunctionComponent<AdditionalContentComponentProps<Model>>,
    public Model?: Type<Model>,
    public queries?: Query[],
  ) {}

  collection() {
    return this.name + 's';
  }

  userFacingName() {
    return this.name.replaceAll('-', ' ');
  }
}

export interface AdditionalContentComponentProps<Model = undefined> {
  id: string;
  schema: Schema<Model>;
  model?: Model;
}

export interface Query {
  name: string;
  get: (reference: firebase.firestore.CollectionReference) => firebase.firestore.Query;
}
