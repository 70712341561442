import React from 'react';
import ScoopMApi from '../../references/scoopm-api';
import ViewOrSaveableInput from './ViewOrSaveableInput';

export default function PaddingRow({defaultValue, deliveryId}) {
  return (
    <ViewOrSaveableInput
      value={defaultValue}
      canEdit={defaultValue}
      label={defaultValue + ' min'}
      placeholder="Enter amount in minutes"
      save={async value => {
        const paddingMNumber = Number(value.trim());

        if (isNaN(paddingMNumber)) throw new Error('Invalid number');

        return ScoopMApi.instance.dispatch.ezCater.deliveries.child(deliveryId).updatePadding.put(paddingMNumber);
      }}
    />
  );
}
