"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.latLngFromCoordinatesObject = void 0;
function latLngFromCoordinatesObject(coordinatesObject) {
    return {
        lat: coordinatesObject.latitude,
        lng: coordinatesObject.longitude,
    };
}
exports.latLngFromCoordinatesObject = latLngFromCoordinatesObject;
