import React from 'react';
import firebase from 'firebase/app';
import moment from 'moment';
import Property from '../types/Property';
import PropertyType from '../types/PropertyType';
import Schema, {AdditionalContentComponentProps} from '../types/Schema';
import Card from '../../components/Card';
import {unwrap} from '../../functions/unwrap';
import pluralize from 'pluralize';

const panicEventSchema = new Schema(
  'panic-event',
  [
    new Property('creationTimestamp', PropertyType.firestoreTimestamp, {
      readonly: true,
      userFacingName: 'Created',
    }),
    new Property('resolutionTimestamp', PropertyType.firestoreTimestamp, {
      isOptional: true,
      userFacingName: 'Resolved',
    }),
    new Property('creatorId', PropertyType.uid, {readonly: true}),
    // liveCoordinates?: CoordinatesArray
    new Property('tripId', PropertyType.realtimeDatabaseId, {
      readonly: true,
      isOptional: true,
      userFacingName: 'Associated job',
    }),
    new Property('comments', PropertyType.stringLong, {isOptional: true}),
  ],
  AdditionalContentComponent,
);
export default panicEventSchema;

function AdditionalContentComponent({
  model,
}: AdditionalContentComponentProps<{
  resolutionTimestamp?: firebase.firestore.Timestamp;
  creationTimestamp: firebase.firestore.Timestamp;
}>) {
  return (
    <Card innerPadded>
      {unwrap(
        model?.resolutionTimestamp,
        timestamp => {
          const duration = moment.duration(timestamp.seconds - model!.creationTimestamp.seconds, 'seconds');
          const days = duration.days();
          const hours = duration.hours();
          const minutes = duration.minutes();
          return [
            'Open for',
            days,
            days ? pluralize('day', days) : false,
            hours,
            hours ? pluralize('hour', hours) : false,
            minutes,
            minutes ? pluralize('minute', minutes) : false,
          ]
            .filter(Boolean)
            .join(' ');
        },
        () => 'This event is not resolved',
      )}
    </Card>
  );
}
