import TripType from './TripType';

export default class Estimate {
  static name(tripType) {
    return tripType === TripType.delivery.rawValue ? 'Delivery charge' : 'Price';
  }

  constructor({valueD, valueBeforeDiscountD = null, valueBeforeMinimumD}) {
    this.valueD = valueD;
    this.valueBeforeDiscountD = valueBeforeDiscountD;
    this.valueBeforeMinimumD = valueBeforeMinimumD;
  }
}
