import Reference from './Reference';

class GeoFireReference extends Reference {
  constructor(path, parent, realtimeDatabaseReference) {
    super(path, parent);

    this.geoFire = new window.GeoFire(realtimeDatabaseReference);
  }

  /**
   * Retrieves all items found within a certain radius from a center point
   * @param {Array<Number>} centerCoordinatesArray An array containing the latitude and longitude of the center of the search
   * @param {Number} radiusKm The radius in kilometers from the center in which to perform the search
   */
  async once(centerCoordinatesArray, radiusKm) {
    const items = [];

    const query = this.geoFire.query({
      center: centerCoordinatesArray,
      radius: radiusKm,
    });

    // observe key_entered

    console.log(
      `Getting items at ${this.fullPath} within ${radiusKm}km of [${centerCoordinatesArray[0]}, ${centerCoordinatesArray[1]}]...`,
    );

    query.on('key_entered', (key, coordinatesArray, distanceKm) => {
      items.push({key, coordinatesArray, distanceKm});
    });

    // wait for initial data load, then cancel query

    await new Promise(resolve => {
      query.on('ready', () => {
        query.cancel();
        resolve();
      });
    });

    console.log(`Got ${items.length} items`, items);

    return items;
  }
}

export default GeoFireReference;
