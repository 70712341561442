import capitalize from 'lodash/capitalize';

export default class EventOutgoing {}

EventOutgoing.mutationNameRaw = requestData => requestData.query.replace(/mutation +/, '').replace(/\(.+/, '');

EventOutgoing.mutationName = requestData =>
  capitalize(
    EventOutgoing.mutationNameRaw(requestData)
      .split(/(?=[A-Z])/)
      .join(' '),
  );
