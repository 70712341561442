import React from 'react';
import FirebaseTable from '../../../components/FirebaseTable';
import Coupon from '../../../models/scoopm/Coupon';
import moment from 'moment';
import unwrap from '../../../functions/unwrap';

export default function All({history}) {
  const onClickNew = event => {
    event.preventDefault();

    const rawCode = window.prompt('Enter coupon code');

    if (!rawCode) return;

    const parsedCode = rawCode.trim().toLowerCase();

    history.push(`/admin/coupon-codes/${parsedCode}`);
  };

  return (
    <FirebaseTable
      title="Coupon codes"
      path="coupons"
      model={Coupon}
      searchTerm={JSON.stringify}
      link={coupon => `/admin/coupon-codes/${coupon.key}`}
      columns={[
        {
          heading: 'Code',
          value: coupon => coupon.key.toUpperCase(),
        },
        {
          heading: 'Conditions',
          value: coupon =>
            coupon.descriptions.map((description, i) => (
              <div key={i} className="alert alert-warning d-inline p-1 mr-1">
                {description}
              </div>
            )),
        },
        {
          heading: 'Expiration',
          value: coupon =>
            unwrap(
              coupon.expirationTimestampMilliseconds,
              ms => moment(ms).calendar(),
              () => 'Never',
            ),
        },
      ]}
      additionalBarComponents={
        <button onClick={onClickNew} className="btn btn-primary">
          <i className="fas fa-plus mr-1"></i>New
        </button>
      }
    />
  );
}
