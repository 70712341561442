import {EventEmitter} from 'events';
import {dmfError} from '../functions/dmflog';
import {Auth} from './Auth';
import {DbRefs} from './Db';
import firebase from 'firebase/app';

export class UserService extends EventEmitter {
  constructor() {
    super();

    firebase.auth().onAuthStateChanged(user => {
      // Emit user
      //   dmflog(this, `User ${user ? `is` : `is not`} authenticated`);
      this.emit(`authUser`, user);

      // Then handle firebase nodes
      if (user) {
        this.getCustomer();
        this.getDriver();
        this.getAdmin();
      } else {
        this.offUser();
        if (this.customerCallback) this.customerRef.off(`value`, this.customerCallback);
        if (this.driverCallback) this.driverRef.off(`value`, this.driverCallback);
        if (this.adminCallback) this.adminRef.off(`value`, this.adminCallback);
      }
    });
  }

  /**
   * @returns {UserService}
   */
  static get instance() {
    //init
    if (typeof window.scoopUserService === 'undefined') {
      window.scoopUserService = new UserService();
    }
    //return
    return window.scoopUserService;
  }

  get role() {
    if (this.isAdmin) {
      return Auth.roles.admin;
    } else if (this.isDriver) {
      return Auth.roles.driver;
    } else {
      return Auth.roles.none;
    }
  }

  getCustomer() {
    //dmflog(this, `Getting customer`);
    this.customerRef = DbRefs.currentCustomer;
    this.customerCallback = this.customerRef.on(
      `value`,
      snapshot => {
        let customer = snapshot.val();
        if (customer) {
          this.onCustomer(customer);
        } else {
          this.onCustomer(false);
        }
      },
      error => {
        if (error.code === 'PERMISSION_DENIED') {
          this.onCustomer(false);
        } else {
          dmfError(error.code);
        }
      },
    );
  }

  getDriver() {
    //dmflog(this, `Getting driver`);
    this.driverRef = DbRefs.currentDriver;
    this.driverCallback = this.driverRef.on(
      `value`,
      snapshot => {
        let driver = snapshot.val();
        if (driver) {
          this.onDriver(driver);
        } else {
          this.onDriver(false);
        }
      },
      error => {
        if (error.code === 'PERMISSION_DENIED') {
          this.onDriver(false);
        } else {
          dmfError(error.code);
        }
      },
    );
  }

  getAdmin() {
    //dmflog(this, `Getting admin`);
    this.adminRef = DbRefs.currentAdmin;
    this.adminCallback = this.adminRef.on(
      `value`,
      snapshot => {
        let admin = snapshot.val();
        if (admin) {
          this.onAdmin(true);
        } else {
          this.onAdmin(false);
        }
      },
      error => {
        if (error.code === 'PERMISSION_DENIED') {
          this.onAdmin(false);
        } else {
          dmfError(error.code);
        }
      },
    );
  }

  onCustomer(customer) {
    //dmflog(this, `Got customer:`, customer);
    this.customer = customer;
    this.isCustomer = customer ? true : false;
    this.attemptOnUser();
  }

  onDriver(driver) {
    //dmflog(this, `Got driver:`, driver);
    this.driver = driver;
    this.isDriver = driver ? true : false;
    this.attemptOnUser();
  }

  onAdmin(admin) {
    //dmflog(this, `Got admin:`, admin);
    this.admin = admin;
    this.isAdmin = admin ? true : false;
    this.attemptOnUser();
  }

  attemptOnUser() {
    if (typeof this.customer === `undefined`) return;
    if (typeof this.driver === `undefined`) return;
    if (typeof this.admin === `undefined`) return;
    this.onUser();
  }

  onUser() {
    if (!this.isCustomer && !this.isDriver) {
      // no nodes set up for this account
      this.emit(`noUserNodeSetUp`);
      return;
    }

    this.user = this.isDriver ? this.driver : this.customer;
    this.emit(`fbUser`, this.user);
    if (this.isCustomer) this.emit(`fbCustomer`, this.customer);
    if (this.isDriver) this.emit(`fbDriver`, this.driver);
    if (this.isAdmin) this.emit(`fbAdmin`, this.admin);
  }

  offUser() {
    this.user = undefined;

    this.customer = undefined;
    this.driver = undefined;
    this.admin = undefined;

    this.isCustomer = undefined;
    this.isDriver = undefined;
    this.isAdmin = undefined;

    this.emit(`fbUser`, this.user);
  }
}
