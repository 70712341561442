import omit from 'lodash/omit';
import React, {useCallback} from 'react';
import {priceDescription} from '../../models/stripe/Price';
import ScoopMApi from '../../references/scoopm-api';
import PropertyInputProps from './PropertyInputProps';
import StripeObjectInput from './StripeObjectInput';

const productId = process.env.REACT_APP_STRIPE_DELIVERY_SERVICES_PRODUCT_ID!;

export default function StripePriceInput(props: PropertyInputProps) {
  const getPromise = useCallback(() => ScoopMApi.instance.stripe.prices.list(productId), []);
  return (
    <StripeObjectInput getPromise={getPromise} format={priceDescription} {...omit(props, 'getPromise', 'format')} />
  );
}
