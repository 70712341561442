import React from 'react';
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker';
import joinClassNames from '../../../functions/joinClassNames';

/**
 * This allows the input *and* the container to have the `is-invalid` class. So the input will go red, and the `.invalid-feedback` will show up
 */
export default function DatePicker({className, ...props}: ReactDatePickerProps) {
  return (
    <ReactDatePicker
      className={joinClassNames('form-control form-control-lg', className)}
      wrapperClassName={joinClassNames('d-block', className)}
      {...props}
    />
  );
}
