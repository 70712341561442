import React, {useMemo} from 'react';
import {Route, Switch} from 'react-router-dom';
import unwrap from '../../functions/unwrap';
import ClaimsGuard from '../../guards/ClaimsGuard';
import FourOFour from '../admin/FourOFour';

export default function RouteComponent({route}) {
  return (
    <Switch>
      <Route path={'/' + route.absolutePath} exact={Boolean(route.exact)}>
        {renderChildOfRoot(route)}
      </Route>
    </Switch>
  );
}

function renderChildOfRoot(route) {
  return function ({...props}) {
    return (
      <OptionalClaimsGuard route={route} {...props}>
        {unwrap(route.children, children => (
          <Switch>
            {children.map(child => {
              return (
                <Route key={child.absolutePath} path={'/' + child.absolutePath} exact={Boolean(child.exact)}>
                  <RouteComponent route={child} />
                </Route>
              );
            })}
            <Route render={FourOFour} />
          </Switch>
        ))}
      </OptionalClaimsGuard>
    );
  };
}

function OptionalClaimsGuard({route, children, ...props}) {
  const hasRequiredClaims = useMemo(() => route.requiredClaims, [route.requiredClaims]);
  if (hasRequiredClaims) {
    return (
      <ClaimsGuard claims={route.requiredClaims} showMessage>
        <ContainerWithSwitch route={route} {...props}>
          {children}
        </ContainerWithSwitch>
      </ClaimsGuard>
    );
  } else {
    return (
      <ContainerWithSwitch route={route} {...props}>
        {children}
      </ContainerWithSwitch>
    );
  }
}

function ContainerWithSwitch({route, children, ...props}) {
  const hasComponent = useMemo(() => route.Component, [route.Component]);
  if (hasComponent) {
    return <route.Component {...props}>{children}</route.Component>;
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
}
