import {useMemo, useState} from 'react';
import logPromise from '../functions/logPromise';
import useIsMountedRef from './useIsMountedRef';

/**
 * Result will be `null` if loading, and `undefined` if there is no promise.
 */
export default function useResult<T = any>(promise: Promise<T> | null | undefined): Result<T> | null | undefined {
  const isMountedRef = useIsMountedRef();

  const [result, setResult] = useState<Result<T> | null | undefined>(null);

  // we catch the results of the promise inside a `useMemo` hook, since this will run immediately and prevent the promise from failing without an error handler

  useMemo(() => {
    if (promise) {
      setResult(null);
      logPromise('Get result', promise).then(
        result => {
          if (!isMountedRef.current) return;
          setResult(new Result(result, null));
        },
        error => {
          if (!isMountedRef.current) return;
          setResult(new Result<T>(null, error));
        },
      );
    } else {
      setResult(undefined);
    }
  }, [promise, isMountedRef]);

  return result;
}

export class Result<T> {
  value: T | null;
  error: Error | null;

  constructor(value: T | null, error: Error | null) {
    this.value = value;
    this.error = error;
  }
}
