import $ from 'jquery';
import React, {ReactNode, useEffect, useRef, useState} from 'react';
import joinClassNames from '../functions/joinClassNames';
import FontAwesome from './FontAwesome';

export default function DropdownMenu(props: {
  text?: string;
  disabled?: boolean;
  buttonClassName?: string;
  anyWidth?: boolean;
  className?: string;
  right?: boolean;
  children?: ReactNode;
  dismissAlways?: boolean;
}) {
  const dropdown = useRef();

  const [isShowingMenu, setIsShowingMenu] = useState(false);

  // on click outside of menu

  useEffect(() => {
    const listener = (event: any) => {
      if (props.dismissAlways) {
        setIsShowingMenu(false);
      } else {
        const dropdownWasClicked = dropdown.current === event.target;
        const dropdownContainsClickedElement = $(dropdown.current as any).has(event.target).length > 0;
        if (dropdownWasClicked || dropdownContainsClickedElement) {
          return;
        }
        setIsShowingMenu(false);
      }
    };
    $('body').on('click', listener);
    return () => {
      $('body').off('click', listener);
    };
  }, [props.dismissAlways]);

  const onClickDropdownButton = (event: React.MouseEvent) => {
    setIsShowingMenu(!isShowingMenu);
  };

  return (
    <div ref={dropdown as any} className={joinClassNames('dropdown', props.className)}>
      <button
        type="button"
        className={'btn dropdown-toggle ' + (props.buttonClassName || 'btn-outline-primary')}
        // data-toggle="dropdown"
        disabled={props.disabled}
        onClick={onClickDropdownButton}>
        {props.text || <FontAwesome.Cog />}
      </button>
      <div
        style={
          props.anyWidth
            ? undefined
            : {
                minWidth: '18rem',
                maxWidth: '20rem',
              }
        }
        className={
          'dropdown-menu shadow p-0' +
          (isShowingMenu ? ' show' : '') +
          (props.right ? ' dropdown-menu-right' : ' dropdown-menu-left')
        }>
        {props.children}
      </div>
    </div>
  );
}
