import React, {useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {unwrap, useStatus} from 'wave-common';
import {vehicleTrimRead} from 'wave-common/lib/controllers/VehicleTrim';
import {transportTypeHasFuelImage} from 'wave-common/lib/models/TransportType';
import Vehicle from 'wave-common/lib/models/Vehicle';
import FirestoreDataSource from '../data-sources/FirestoreDataSource';
import joinClassNames from '../functions/joinClassNames';
import useFuelImageUrl from '../hooks/useFuelImageUrl';
import FontAwesome, {FontAwesomeForTransportType} from './FontAwesome';
import WhiteText from './WhiteText';

export default function VechicleCard({
  vehicle,
  isActive,
  onSelect,
}: {
  vehicle: Omit<Vehicle, 'id'> & {id?: string};
  isActive: boolean;
  onSelect?: (vehicle: Omit<Vehicle, 'id'> & {id?: string}) => void;
}) {
  const fuelUrl = useFuelImageUrl(vehicle);

  // hover status

  const [isHovered, setIsHovered] = useState(false);

  // trim

  const {isPending, value: trim} = useStatus(
    React as any,
    useMemo(() => unwrap(vehicle.trimId, trimId => vehicleTrimRead(trimId, FirestoreDataSource)), [vehicle.trimId]),
  );

  // render

  if (isPending) {
    return null;
  }

  return (
    <div
      onClick={() => unwrap(onSelect, callback => callback(vehicle))}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={joinClassNames(
        'rounded d-flex flex-column border',
        isActive ? 'border-success' : isHovered && 'border-gray-500',
        onSelect && 'cursor-pointer',
      )}
      style={{
        width: '11rem',
      }}>
      <div className="flex-grow-1">
        {vehicle.transportType && !transportTypeHasFuelImage(vehicle.transportType) ? (
          <div className="w-100 d-flex align-items-center justify-content-center" style={{height: '8rem'}}>
            <h4>
              <FontAwesomeForTransportType type={vehicle.transportType} />
            </h4>
          </div>
        ) : (
          <img
            src={fuelUrl}
            alt=""
            className="w-100 mb-2 rounded d-block"
            style={{
              height: '6rem',
              objectFit: 'cover',
            }}
          />
        )}
        <div className="px-2 px-md-3">
          <h4 className="mb-1 border rounded p-1 text-center">{vehicle.licensePlateNumber}</h4>
          <p className="mb-0 text-muted">
            {vehicle.year} {vehicle.make} {vehicle.model}
          </p>
          <p className="mb-2 text-muted">
            {trim?.name}
            {vehicle.id && (
              <>
                {' '}
                <Link
                  to={`/admin/vehicles/${vehicle.id}`}
                  onClick={event => {
                    // event.preventDefault();
                    event.stopPropagation();
                  }}
                  className="float-right text-gray-400">
                  Edit
                </Link>
              </>
            )}
          </p>
        </div>
      </div>
      <div className="align-self-stretch text-center">
        <div
          className={joinClassNames(
            'rounded-bottom p-1',
            isActive ? 'bg-success text-white' : isHovered ? 'bg-gray-300' : undefined,
          )}>
          {isActive ? (
            <>
              <FontAwesome.CheckSolid /> Active
            </>
          ) : isHovered ? (
            'Make active'
          ) : (
            <WhiteText />
          )}
        </div>
      </div>
    </div>
  );
}
