import kebabCase from 'lodash/kebabCase';
import React, {useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import Delay from '../../components/Delay';
import Spinner from '../../components/Spinner';
import PropertyType from '../types/PropertyType';
import {None} from './PropertyDisplay';

export default function ObjectDisplay({schema, property, value}) {
  const [name, setName] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    setName(undefined);
    setError(null);
    console.log('Fetching object name...');
    PropertyType.fetchObjectName(property, value).then(
      name => {
        console.log('Fetched object name', name);
        setName(name);
      },
      error => {
        console.error('Error fetching object name', error);
        setError(error);
      },
    );
  }, [property, value]);

  const url = useMemo(() => `/admin/${kebabCase(property.name.replace('Id', ''))}s/${value}`, [property.name, value]);
  console.log({value, name});
  if (error) {
    return `Error. ${error.message}`;
  }

  if (name === undefined) {
    return (
      <Delay>
        <Spinner small />
      </Delay>
    );
  }

  if (value === null || value === undefined) {
    return <None />;
  }

  return (
    <Link to={url} className="text-decoration-underline">
      {name || 'View'}
    </Link>
  );
}
