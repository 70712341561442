import Enum from '../base/Enum';

export default class PlaceCountEstimate extends Enum {
  static small = new PlaceCountEstimate('SMALL');
  static medium = new PlaceCountEstimate('MEDIUM');
  static large = new PlaceCountEstimate('LARGE');

  title() {
    switch (this) {
      case PlaceCountEstimate.small:
        return '1-5';
      case PlaceCountEstimate.medium:
        return '5-15';
      case PlaceCountEstimate.large:
        return '15+';
    }
  }

  order() {
    switch (this) {
      case PlaceCountEstimate.small:
        return 0;
      case PlaceCountEstimate.medium:
        return 1;
      case PlaceCountEstimate.large:
        return 2;
    }
  }
}
