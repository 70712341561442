import RealtimeDatabase from '../database/RealtimeDatabase';

const {default: HttpReference} = require('../platform/HttpReference');

export default class OloAcceptQuote extends HttpReference {
  async post(delivery) {
    const [oloClientId] = await RealtimeDatabase.instance.config.child('keys').oloClientId.once();

    return await this.request({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${oloClientId}`,
      },
      data: delivery,
    });
  }
}
