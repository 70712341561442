import React, {useState} from 'react';
import FontAwesome from './FontAwesome';

export default function Collapsable({
  title,
  additionalComponents,
  additionalComponentsWhenOpen,
  defaultIsCollapsed,
  children,
  ...props
}: {
  title: any;
  additionalComponents?: any;
  additionalComponentsWhenOpen?: any;
  defaultIsCollapsed?: boolean;
  children?: any;
}) {
  const [isCollapsed, setIsCollapsed] = useState(Boolean(defaultIsCollapsed));

  const onClickCollapseButton = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div {...props}>
      <div className="row d-flex align-items-center p-2 p-md-3">
        <div className="col">
          <h5 className="mb-0">{title}</h5>
        </div>

        {additionalComponents}

        {!isCollapsed && additionalComponentsWhenOpen}

        <div className="col-auto">
          <button onClick={onClickCollapseButton} className="btn btn-sm">
            {isCollapsed ? <FontAwesome.Plus /> : <FontAwesome.Minus />}
          </button>
        </div>
      </div>

      {!isCollapsed && <div className="px-2 px-md-3 pb-2 pb-md-3">{children}</div>}
    </div>
  );
}
