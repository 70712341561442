import firebase from 'firebase/app';
import flatMap from 'lodash/flatMap';
import {useMemo} from 'react';
import unwrap from '../functions/unwrap';
import City from '../models/scoopm/City';
import useUserGroups from './useUserGroups';

export default function useFilterCities(uid: string | undefined = firebase.auth().currentUser?.uid) {
  const {isLoading, groups} = useUserGroups(uid);

  const cities = useMemo(() => {
    const filterCitiesRawValues = unwrap(groups, (entries: any) =>
      flatMap(entries.map(([id, group]: any) => group.filterCities)),
    ) as Array<string>;

    let filterCities = unwrap(
      filterCitiesRawValues,
      rawValues => rawValues.map((rawValue: string) => City.from(rawValue)) as Array<City>,
    );

    filterCities = filterCities?.filter(Boolean) ?? null;

    if (filterCities?.length === 0) {
      filterCities = null;
    }

    return filterCities;
  }, [groups]);

  return {isLoading, cities};
}
