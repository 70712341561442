import {Badge} from '@material-ui/core';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParam, withDefault} from 'use-query-params';
import Constants from '../Constants';
import {useAdditionalNavbarContent} from '../contexts/AdditionalNavbarContentContext';
import arrayOfCount from '../functions/arrayOfCount';
import csvFromKeysAndObjects from '../functions/csvFromKeysAndObjects';
import joinClassNames from '../functions/joinClassNames';
import {simluateDownloadClickCsv} from '../functions/simulateDownloadClick';
import unwrap from '../functions/unwrap';
import useAsyncAffect from '../hooks/useAsyncEffect';
import useRealtimeDatabase from '../hooks/useRealtimeDatabase';
import CurrentRoutesContext from '../routes/router/CurrentRoutesContext';
import Delay from './Delay';
import DropdownMenu from './DropdownMenu';
import FontAwesome from './FontAwesome';
import Spinner, {DelaySpinner} from './Spinner';
import WhiteText from './WhiteText';

export default function FirebaseTable({
  columns,
  link,
  linkTitle = undefined,
  searchTerm = undefined,
  flags = undefined,
  csvKeys = undefined,
  getAdditionalData = undefined,
  getAdditionalCsvData = undefined,
  cellClassName = undefined,
  additionalBarComponents = undefined,
  additionalMenuComponents = undefined,
  model = undefined,
  limitToFirst = undefined,
  clientSideFilter = undefined,
  isLoading = undefined,
  ...props
}) {
  const {currentRoute} = useContext(CurrentRoutesContext);

  // const [internalLimitToFirst, setInternalLimitToFirst] = useState(null)

  const result = useRealtimeDatabase({
    Model: model,
    isArray: true,
    limitToFirst: /*internalLimitToFirst ||*/ limitToFirst,
    ...props,
  });

  const [selectedFlagStrings, setSelectedFlagStrings] = useQueryParam('flags', ArrayParam);
  const [sortingColumnHeading, setSortingColumnHeading] = useQueryParam(
    'sortinColumn',
    withDefault(
      StringParam,
      unwrap(
        columns.find(column => column.sortByDefault === true),
        column => column.heading,
      ),
    ),
  );
  const [reverseSort, setReverseSort] = useQueryParam('reverseSort', withDefault(BooleanParam, false));
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0));
  const [pageSize, setPageSize] = useQueryParam(
    'pageSize',
    withDefault(
      NumberParam,
      Number(window.localStorage.getItem(Constants.localStorageKeys.firebaseTablePageSize) || 20),
    ),
  );
  const [pageData, setPageData] = useState();

  const [query, setQuery] = useQueryParam('query', StringParam);

  const [isFetchingAdditionalCsvData, setIsFetchingAdditionalCsvData] = useState(false);

  // page size -> local storage

  useEffect(() => {
    window.localStorage.setItem(Constants.localStorageKeys.firebaseTablePageSize, pageSize);
  }, [pageSize]);

  // sorted data

  const sortedData = useMemo(() => {
    if (isLoading) {
      return null;
    } else if (!result.data) {
      return null;
    } else {
      let sortedData = Object.assign([], result.data);

      // client side filter

      if (clientSideFilter) {
        sortedData = sortedData.filter(clientSideFilter);
      }

      // search

      if (searchTerm && query) {
        const queryLowercase = query.toLowerCase();
        sortedData = sortedData.filter(item => {
          const searchTermLowercase = String(searchTerm(item)).toLowerCase();
          return searchTermLowercase.includes(queryLowercase);
        });
      }

      // flags

      if (selectedFlagStrings && selectedFlagStrings.length) {
        // filter items
        sortedData = sortedData.filter(item => !selectedFlagStrings.find(flagString => !item[flagString]));
      }

      // sort

      if (sortingColumnHeading) {
        console.log('Sorting by ' + sortingColumnHeading);

        const sortingColumn = columns.find(c => c.heading === sortingColumnHeading);

        sortedData.sort((a, b) => {
          const keyFunctionA = sortingColumn.key || sortingColumn.value;
          const valueA = String(keyFunctionA(reverseSort ? b : a)).toLowerCase();
          const keyFunctionB = sortingColumn.key || sortingColumn.value;
          const valueB = String(keyFunctionB(reverseSort ? a : b)).toLowerCase();
          if (valueA < valueB) {
            return -1;
          } else if (valueA > valueB) {
            return 1;
          } else {
            return 0;
          }
        });
      }

      // console.log('result.data, sortedData', result.data[0].key, sortedData[0].key);

      return sortedData;
    }
  }, [
    result.data,
    sortingColumnHeading,
    reverseSort,
    query,
    selectedFlagStrings,
    columns,
    searchTerm,
    clientSideFilter,
    isLoading,
  ]);

  // page count

  const pageCount = useMemo(() => {
    if (!sortedData) {
      return 0;
    } else {
      return Math.ceil(sortedData.length / pageSize);
    }
  }, [sortedData, pageSize]);

  // page

  useEffect(() => {
    if (pageCount > 0 && page >= pageCount) {
      setPage(pageCount - 1);
    }
  }, [pageCount, page, setPage]);

  // start

  const start = useMemo(() => pageSize * page, [pageSize, page]);

  // end

  const end = useMemo(() => start + pageSize, [start, pageSize]);

  // page data

  useAsyncAffect(
    async () => {
      if (!sortedData) {
        return null;
      } else {
        const pageData = sortedData.slice(start, end);
        if (!getAdditionalData) {
          return pageData;
        } else {
          console.log('Getting additional data...', pageData);
          try {
            const additionalData = getAdditionalData(pageData);
            console.log('Got additional data', additionalData);
            return additionalData;
          } catch (error) {
            console.error('Error getting additional data', error);
            throw error;
          }
        }
      }
    },
    pageData => {
      console.log('Setting page data...', pageData);
      setPageData(pageData);
    },
    error => {
      setPageData(null);
    },
    [sortedData, start, end],
  );

  // view methods

  // on click heading
  const onClickHeading = column => event => {
    event.preventDefault();
    if (column.heading !== sortingColumnHeading) {
      setSortingColumnHeading(column.heading);
      setReverseSort(false);
    } else {
      setReverseSort(!reverseSort);
    }
  };

  useAdditionalNavbarContent(
    useMemo(
      () => (
        <>
          {/* item count */}
          <Column>
            {sortedData ? (limitToFirst || props.limitToLast ? 'Showing ' : 'Total ') + (sortedData || []).length : ''}
          </Column>

          {/* more columns? */}

          {(limitToFirst || props.limitToLast) && (
            <Column>
              <button
                className="m-2 btn btn-light"
                onClick={event => {
                  event.preventDefault();
                  result.loadMore();
                }}
                disabled={result.isLoading || !result.moreDataMayExist}>
                {result.isLoading ? (
                  <Delay>
                    <Spinner small />
                  </Delay>
                ) : result.moreDataMayExist ? (
                  'Load more'
                ) : (
                  'No more items'
                )}
              </button>
            </Column>
          )}

          {/* additional bar components */}

          {additionalBarComponents && <Column>{additionalBarComponents}</Column>}

          {/* filters */}

          {(flags || csvKeys || additionalMenuComponents) && (
            <Column className="order-md-last mr-md-2">
              <Badge
                color="secondary"
                badgeContent={selectedFlagStrings ? selectedFlagStrings.length : 0}
                className="w-100">
                <DropdownMenu disabled={result.isLoading} className="w-100" buttonClassName="bg-white w-100" right>
                  {/* flags */}

                  {flags && (
                    <div id="filtersContainer" className="p-2 p-md-3">
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <h6 className="mb-0">Filters</h6>
                        {selectedFlagStrings && selectedFlagStrings.length && (
                          <a
                            onClick={event => {
                              event.preventDefault();
                              setSelectedFlagStrings([]);
                              window.$('#filtersContainer input:checkbox').prop('checked', false);
                            }}
                            href="#reset-filters">
                            Reset
                          </a>
                        )}
                      </div>

                      {flags.map((flag, i) => {
                        const checked = Boolean(
                          selectedFlagStrings && selectedFlagStrings.indexOf(flag.property) !== -1,
                        );

                        const id = `filter-${flag.property}`;

                        return (
                          <div key={i} className="custom-control custom-checkbox mt-1">
                            <input
                              id={id}
                              type="checkbox"
                              checked={checked}
                              onChange={event => {
                                const newSelectedFlagStrings = selectedFlagStrings ? [...selectedFlagStrings] : [];

                                if (event.target.checked) {
                                  newSelectedFlagStrings.push(flag.property);
                                } else {
                                  const index = newSelectedFlagStrings.indexOf(flag.property);
                                  if (index === -1) return;
                                  newSelectedFlagStrings.splice(index, 1);
                                }

                                setSelectedFlagStrings(newSelectedFlagStrings);
                              }}
                              className="custom-control-input"
                            />
                            <label htmlFor={id} className="custom-control-label">
                              {flag.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {((flags && additionalMenuComponents) || (flags && csvKeys)) && <hr className="m-0" />}

                  {additionalMenuComponents && <div className="p-2 p-md-3">{additionalMenuComponents}</div>}

                  {additionalMenuComponents && csvKeys && <hr className="m-0" />}

                  {csvKeys && (
                    <div className="d-flex flex-row justify-content-between align-items-center p-2 p-md-3">
                      <h6 className="mb-0">CSV Export</h6>
                      <button
                        onClick={event => {
                          const filename = unwrap(currentRoute, route => route.name + '.csv') || 'CSV Export.csv';
                          if (getAdditionalCsvData) {
                            setIsFetchingAdditionalCsvData(true);
                            getAdditionalCsvData([...sortedData])
                              .then(newData => {
                                simluateDownloadClickCsv(csvFromKeysAndObjects(csvKeys, newData), filename);
                              })
                              .catch(error => {
                                console.error('Error getting additional CSV data', error);
                                if (
                                  window.confirm(
                                    'There was an error getting all the data for the CSV. Would you like to download a partial CSV?',
                                  )
                                ) {
                                  simluateDownloadClickCsv(csvFromKeysAndObjects(csvKeys, sortedData), filename);
                                }
                              })
                              .finally(() => {
                                setIsFetchingAdditionalCsvData(false);
                              });
                          } else {
                            simluateDownloadClickCsv(csvFromKeysAndObjects(csvKeys, sortedData), filename);
                          }
                        }}
                        className="btn btn-primary ml-2"
                        disabled={isFetchingAdditionalCsvData}>
                        {isFetchingAdditionalCsvData ? (
                          <Spinner small />
                        ) : (
                          <>
                            <FontAwesome.DownloadSolid /> Download
                          </>
                        )}
                      </button>
                    </div>
                  )}
                </DropdownMenu>
              </Badge>
            </Column>
          )}

          {/* search */}

          {searchTerm && (
            <Column colClassName="col-12 col-md-auto">
              <input
                type="search"
                placeholder="Search"
                defaultValue={query}
                onChange={e => {
                  e.preventDefault();
                  setQuery(e.target.value);
                }}
                className="form-control w-100 border-0"
                disabled={result.isLoading}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
              />
            </Column>
          )}
        </>
      ),
      [
        additionalBarComponents,
        additionalMenuComponents,
        csvKeys,
        flags,
        isFetchingAdditionalCsvData,
        limitToFirst,
        currentRoute,
        getAdditionalCsvData,
        setSelectedFlagStrings,
        props.limitToLast,
        query,
        result,
        searchTerm,
        selectedFlagStrings,
        setQuery,
        sortedData,
      ],
    ),
  );

  return (
    <div className="flex-grow-1 bg-white d-flex flex-column">
      <div className="table-responsive mt-0">
        <table className="table table-wrap table-sm table-sm-text table-hover mb-0">
          {/* table header */}

          <thead className="thead-light">
            <tr>
              {columns.map((column, i) => (
                <th
                  key={i}
                  onClick={column.disableSort || onClickHeading(column)}
                  // className={(column.heading === sortingColumnHeading ? "bg-light text-primary" )}
                  className={column.disableSort ? null : 'cursor-pointer'}>
                  {column.headingComponent || column.heading}
                  {column.heading === sortingColumnHeading && (
                    <span className="float-right">
                      {reverseSort ? <i className="fas fa-angle-up"></i> : <i className="fas fa-angle-down"></i>}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>

          {/* table body */}

          <tbody>
            {isLoading || result.isLoading || (result.data && !pageData) ? ( // loading or pasing data
              <tr>
                <td colSpan={columns.length}>
                  <DelaySpinner small /> &nbsp;
                </td>
              </tr>
            ) : pageData && pageData.length > 0 ? ( // data to show
              pageData.map((item, i) => (
                <tr key={i}>
                  {columns.map((column, i) => {
                    const value = column.valueComponent ? column.valueComponent(item) : column.value(item);
                    const className = cellClassName ? cellClassName(item) : '';
                    const title = linkTitle ? linkTitle(item) : column.value(item);

                    if (link) {
                      return (
                        <td key={i} className={'p-0 ' + className}>
                          <Link to={link(item)} title={title} className="d-block p-1 text-decoration-none text-dark">
                            {value || <WhiteText />}
                          </Link>
                        </td>
                      );
                    } else {
                      return (
                        <td key={i} className={'p-1 ' + className} title={title}>
                          {value || <WhiteText />}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))
            ) : (
              // no data to show
              <tr>
                <td colSpan={columns.length}>No data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <form className="form-inline border-bottom bg-gray-200">
        <div className="d-flex w-100 justify-content-between m-2">
          {/* page size */}
          <div>
            <span className="text-muted mr-1">Page size</span>
            <select
              value={pageSize}
              onChange={e => setPageSize(Number(e.target.value))}
              className="form-control mr-1"
              disabled={!sortedData}>
              {[10, 15, 20, 50, 100].map(i => (
                <option key={i}>{i}</option>
              ))}
            </select>
          </div>

          <div>
            {/* page */}
            <span className="text-muted mr-1">Page</span>
            <select
              value={page + 1}
              onChange={e => setPage(Number(e.target.value) - 1)}
              className="form-control mr-1"
              disabled={!sortedData}>
              {arrayOfCount(pageCount, i => (
                <option key={i}>{i}</option>
              ))}
            </select>
            <span className="text-muted mr-4">of {pageCount}</span>

            {/* prev/next */}
            <button
              onClick={e => {
                e.preventDefault();
                setPage(page - 1);
              }}
              className="btn btn-link"
              disabled={result.isLoading || !pageData || page === 0}>
              <i className="fas fa-chevron-left" />
            </button>
            <button
              onClick={e => {
                e.preventDefault();
                setPage(page + 1);
              }}
              className="btn btn-link"
              disabled={result.isLoading || !pageData || page === pageCount - 1}>
              <i className="fas fa-chevron-right" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function Column({colClassName = 'col-4 col-md-auto', className, children}) {
  return (
    <div className={joinClassNames(colClassName, 'd-flex align-items-center px-1 mb-2 mb-md-0', className)}>
      {children}
    </div>
  );
}
