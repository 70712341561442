import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from 'react';

type Value = [isMinimal: boolean, setIsMinimal: Dispatch<SetStateAction<boolean>>];

const MinimalUiContext = createContext<Value>(undefined as never);

export function MinimalUiContextProvider({children}: {children: ReactNode}) {
  const state = useState(false);
  return <MinimalUiContext.Provider value={state}>{children}</MinimalUiContext.Provider>;
}

export function useMinimalUi() {
  const [, setIsMinimal] = useContext(MinimalUiContext);

  useEffect(() => {
    setIsMinimal(true);
    return () => {
      setIsMinimal(false);
    };
  }, [setIsMinimal]);
}

export function useIsMinimalUi() {
  return useContext(MinimalUiContext)[0];
}
