import DataRow from './DataRow';

export default class SelectionAction {
  text: string;
  onClick: (selection: DataRow[]) => void;

  constructor(text: string, onClick: (selection: DataRow[]) => void) {
    this.text = text;
    this.onClick = onClick;
  }
}
