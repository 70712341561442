import CustomClaim from '../../models/scoopm/CustomClaim';
import Property from '../types/Property';
import PropertyType from '../types/PropertyType';
import Schema from '../types/Schema';

const vendorSchema = new Schema('vendor', [
  new Property('name', PropertyType.string),
  new Property('nameMatches', PropertyType.string, {
    isArray: true,
    isOptional: true,
    tableColumnWidth: 500,
    userFacingName: 'Alternate names',
  }),
  new Property('invoiceFormulaId', PropertyType.firestoreId, {
    isOptional: true,
  }),
  new Property('payoutFormulaId', PropertyType.firestoreId, {
    isOptional: true,
  }),
  new Property('stripeCustomerId', PropertyType.stripeCustomerId, {
    readonly: true,
    isOptional: true,
    customClaims: [CustomClaim.manageLogistics],
  }),
  new Property('stripeSubscriptionId', PropertyType.stripeSubscriptionId, {
    readonly: true,
    isOptional: true,
    customClaims: [CustomClaim.manageLogistics],
  }),
  new Property('desiredPriceId', PropertyType.stripePriceId, {
    isOptional: true,
    customClaims: [CustomClaim.manageApi],
    description: 'This will be auto-selected for the vendor when they first set up their payment',
  }),
  new Property('vendorApplicationId', PropertyType.firestoreId, {
    readonly: true,
    isOptional: true,
  }),
  new Property('invoiceColumns', PropertyType.jobsTableColumn, {
    isArray: true,
    isOptional: true,
  }),
]);

export default vendorSchema;
