import firebase from 'firebase/app';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import first from 'lodash/first';
import last from 'lodash/last';
import round from 'lodash/round';
import moment from 'moment';
import React from 'react';
import {boundsForLatLngs} from '../../components/GoogleMaps/LatLng+additions';
import Constants from '../../Constants';
import MomentHelper from '../../functions/MomentHelper';
import unwrap from '../../functions/unwrap';
import Order from '../ezCater/Order';
import Color from './Color';
import DeviceType from './DeviceType copy';
import PositionInList from './PositionInList';
import TripContact from './TripContact';
import TripContent from './TripContent';
import TripDriver from './TripDriver';
import TripLocation from './TripLocation';
import {TripSource} from './TripSource';
import TripStatus from './TripStatus';
import TripType from './TripType';
import UserType from './UserType';

export default class Trip {
  __searchValue;
  searchValue() {
    if (!this.__searchValue) this.__searchValue = JSON.stringify(this).toLocaleLowerCase();
    return this.__searchValue;
  }

  canceledAt;
  cancellationComments;
  cancellationReason;
  cashedOutAt;
  completedAt;
  couponCode;
  contacts;
  createdAt;
  createdBy;
  customer;
  didOverrideDriverEarnings;
  didOverrideEstimate;
  dispatchCancellationReason;
  directions;
  driverEarningsD;
  dropOffTimestampMs;
  estimate;
  estimatedMiles;
  estimatedMinutes;
  firstColor;
  fromAddress;
  fromCoordinate;
  fromPlaceId;
  fromPlaceName;
  locations;
  orderValue;
  paymentIntentId;
  pickupWaitTimeS;
  receipts;
  requiresResponse;
  secondColor;
  scheduleTimestamp;
  source;
  status;
  state;
  tip;
  toAddress;
  toCoordinate;
  toPlaceId;
  toPlaceName;
  vendorId;
  vendorName;

  static Key = {
    confirmedAt: 'confirmedAt',
    driverId: 'driverId',
    driver: 'driver',
    status: 'status',
  };

  mostRelevantTimestampMs() {
    return (
      this.canceledAt ||
      this.completedAt ||
      Trip.prototype.nextLocation.apply(this)?.scheduledTimestampMs ||
      this.createdAt ||
      0
    );
  }

  nextLocation() {
    if (Trip.prototype.isDone.apply(this)) {
      return null;
    }
    const locations = Trip.prototype.locationsWithDefault.apply(this);
    return first(locations, location => location.completionTimestampMs === null);
  }

  canBeAcceptedForEzCater() {
    if (!this.ezCaterDeliveryId) return false;
    if (!this.ezCaterPaddingM) return false;
    if (isNaN(this.ezCaterPaddingM)) return false;
    if (this.sourceType() !== TripSource.ezCater) return false;
    if (this.statusType() !== TripStatus.created && !this.requiresResponse) return false;
    return true;
  }

  currentLocationWithDefault() {
    if (Trip.prototype.isSingleLocation.apply(this)) {
      if (Trip.prototype.isDone.apply(this)) {
        return null;
      } else {
        return 0;
      }
    } else {
      return unwrap(this.currentLocation, null, () => {
        switch (this.status) {
          case TripStatus.created.rawValue:
            return -1;
          case TripStatus.scheduled.rawValue:
            return -1;
          case TripStatus.accepted.rawValue:
            return 0;
          case TripStatus.started.rawValue:
            return 1;
          default:
            return null;
        }
      });
    }
  }

  isAtCurrentLocation() {
    return Boolean(
      unwrap(
        Trip.prototype.currentLocationWithDefault.apply(this),
        currentLocation =>
          currentLocation >= 0 &&
          unwrap(Trip.prototype.locationsWithDefault.apply(this), locations =>
            unwrap(locations[currentLocation], location => location.arrivalTimestampMs),
          ),
      ),
    );
  }

  contactsWithDefault() {
    if (this.contacts) {
      // already have contacts array

      const contacts = [...this.contacts];

      // add driver if needed

      if (!contacts.find(contact => contact.userType === UserType.driver.rawValue)) {
        if (this.driver) {
          contacts.push(
            new TripContact({
              name: `${this.driver.firstName} ${this.driver.lastName}`,
              phone: this.driver.phone,
              uid: this.driverId,
              userType: UserType.driver.rawValue,
            }),
          );
        }
      }

      return contacts;
    } else {
      // create new array

      const contacts = [];

      if (this.customerId && this.customer) {
        contacts.push(
          new TripContact({
            name: `${this.customer.firstName} ${this.customer.lastName}`,
            phone: this.customer.cellphone,
            uid: this.customerId,
            userType: UserType.customer.rawValue,
          }),
        );
      }

      if (this.driverId && this.driver) {
        contacts.push(
          new TripContact({
            name: `${this.driver.firstName} ${this.driver.lastName}`,
            phone: this.driver.phone,
            uid: this.driverId,
            userType: UserType.driver.rawValue,
          }),
        );
      }

      return contacts;
    }
  }

  ezCaterOrderIdFormatted() {
    return unwrap(this.ezCaterOrderId, Order.formatId);
  }

  get universalContacts() {
    return Trip.prototype.contactsWithDefault.apply(this).filter(contact => {
      const locations = Trip.prototype.locationsWithDefault.apply(this);
      return !locations || !locations.find(location => location.contactUserType === contact.userType);
    });
  }

  contact(userType) {
    const contacts = Trip.prototype.contactsWithDefault.apply(this);
    const contact = unwrap(contacts, contacts => find(contacts, contact => contact.userType === userType.rawValue));
    return contact;
  }

  isSingleLocation() {
    if (this.fromCoordinate && this.toCoordinate) {
      return this.fromCoordinate === this.toCoordinate;
    } else {
      return unwrap(Trip.prototype.locationsWithDefault.apply(this), locations => locations.length === 1, false);
    }
  }

  fromLatLng() {
    return unwrap(Trip.prototype.locationsWithDefault.apply(this), locations =>
      unwrap(
        first(locations),
        location => new window.google.maps.LatLng(location.coordinates[0], location.coordinates[1]),
      ),
    );
  }

  pickupComponent() {
    return this.fromPlaceName ? (
      <span>
        {this.fromPlaceName} <span className="text-gray-500">{this.fromAddress}</span>
      </span>
    ) : (
      <span>{this.fromAddress}</span>
    );
  }

  dropOffComponent() {
    return this.toPlaceName ? (
      <span>
        {this.toPlaceName} <span className="text-gray-500">{this.toAddress}</span>
      </span>
    ) : (
      <span>{this.toAddress}</span>
    );
  }

  get isCancelled() {
    return this.status === TripStatus.canceledByDriver.rawValue || this.status === TripStatus.canceledByRider.rawValue;
  }

  get isDelivery() {
    if (!this.type) return null;
    return this.type === TripType.delivery.rawValue;
  }

  isDone() {
    return (
      this.isCancelled ||
      this.status === TripStatus.completed.rawValue ||
      this.status === TripStatus.tripTimedOut.rawValue
    );
  }

  isScheduledWithDefault() {
    return unwrap(this.isScheduled, Boolean, () =>
      unwrap(Trip.prototype.earliestScheduledLocation.apply(this), Boolean, () =>
        Boolean(this.scheduleTimestamp || this.dropOffTimestampMs),
      ),
    );
  }

  locationsWithDefault() {
    if (this.locations) {
      const locations = cloneDeep(this.locations);

      const indexOfNullLocaiton = locations.indexOf(null);
      if (indexOfNullLocaiton > -1) locations[indexOfNullLocaiton] = new TripLocation({});

      // populate timestamps thay may not have been set

      if (locations[0]) {
        unwrap(this.scheduleTimestamp, t => (locations[0].scheduledTimestampMs = t));
        unwrap(this.startedAt, t => (locations[0].completionTimestampMs = t));
        unwrap(this.requestedPickupTimestampMs, t => (locations[0].requestedTimestampMs = t));
        unwrap(this.estimatedPickupTimestampMs, t => (locations[0].estimatedArrivalTimestampMs = t));
        unwrap(this.pickupWaitTimeS, t => (locations[0].waitTimeS = t));
      }

      if (locations[1]) {
        unwrap(this.dropOffTimestampMs, t => (locations[1].scheduledTimestampMs = t));
        unwrap(this.completedAt, t => (locations[1].completionTimestampMs = t));
        unwrap(this.requestedDropOffTimestampMs, t => (locations[1].requestedTimestampMs = t));
        unwrap(this.estimatedDropOffTimestampMs, t => (locations[1].estimatedArrivalTimestampMs = t));
        // locations[0].waitTimeS = null
      }

      return locations;
    } else if (this.fromCoordinate === this.toCoordinate) {
      return [
        new TripLocation({
          contactUserType: UserType.customer.rawValue,
          instructions: this.description,
          contents: null,
          address: this.fromAddress,
          coordinates: this.fromCoordinate,
          googlePlaceId: this.fromPlaceId,
          name: this.fromPlaceName,
          scheduledTimestampMs: this.scheduleTimestamp,
          completionTimestampMs: this.startedAt,
          requestedTimestampMs: this.requestedPickupTimestampMs,
          estimatedArrivalTimestampMs: this.estimatedPickupTimestampMs,
          waitTimeS: this.pickupWaitTimeS,
        }),
      ];
    } else {
      return [
        new TripLocation({
          contactUserType: UserType.customer.rawValue,
          instructions: this.description,
          contents: null,
          address: this.fromAddress,
          coordinates: this.fromCoordinate,
          googlePlaceId: this.fromPlaceId,
          name: this.fromPlaceName,
          scheduledTimestampMs: this.scheduleTimestamp,
          completionTimestampMs: this.startedAt,
          requestedTimestampMs: this.requestedPickupTimestampMs,
          estimatedArrivalTimestampMs: this.estimatedPickupTimestampMs,
          waitTimeS: this.pickupWaitTimeS,
        }),
        new TripLocation({
          contactUserType: null,
          instructions: null,
          contents: null,
          address: this.toAddress,
          coordinates: this.toCoordinate,
          googlePlaceId: this.toPlaceId,
          name: this.toPlaceName,
          scheduledTimestampMs: this.dropOffTimestampMs,
          completionTimestampMs: this.completedAt,
          requestedTimestampMs: this.requestedDropOffTimestampMs,
          estimatedArrivalTimestampMs: this.estimatedDropOffTimestampMs,
        }),
      ];
    }
  }

  remainingLocations() {
    const currentLocation = Trip.prototype.currentLocationWithDefault.apply(this);
    const locations = Trip.prototype.locationsWithDefault.apply(this);
    if (currentLocation < 0) return locations;
    else return locations.slice(currentLocation);
  }

  title() {
    return [
      Trip.prototype.isScheduledWithDefault.apply(this) ? 'Scheduled' : 'ASAP',
      unwrap(TripType.from(this.type), type => type.title),
    ].join(' ');
  }

  customerFacingTitle() {
    return [
      Trip.prototype.isScheduledWithDefault.apply(this) ? 'Scheduled' : null,
      unwrap(TripType.from(this.type), type => type.customerFacingTitle()),
    ]
      .filter(Boolean)
      .join(' ');
  }

  title2() {
    return [
      unwrap(TripSource.from(this.source), source => source.title),
      unwrap(TripType.from(this.type), type => type.title),
      unwrap(this.orderId, null, () => Trip.prototype.ezCaterOrderIdFormatted.apply(this)),
    ]
      .filter(Boolean)
      .join(' ');
  }

  simplestTitle() {
    return (
      this.orderId ||
      Trip.prototype.ezCaterOrderIdFormatted.apply(this) ||
      this.vendorName ||
      unwrap(Trip.prototype.sourceType(), source => source.title) ||
      unwrap(TripType.from(this.type), t => t.title)
    );
  }

  statusTitle() {
    const status = TripStatus.from(this.status);
    switch (status) {
      case TripStatus.created:
      case TripStatus.scheduled:
      case TripStatus.accepted:
      case TripStatus.started:
        const locations = this.locationsWithDefault();
        for (let i = 0; i < locations.length; i++) {
          const location = locations[i];

          if (location.scheduledTimestampMs) {
            const positionInList = PositionInList.for(i, locations);
            return `${positionInList.locationName(locations.length)} ${moment(
              location.scheduledTimestampMs,
            ).calendar()}`;
          }
        }
        return status.customerFacingTitle();

      case TripStatus.completed:
        return `Completed ${moment(this.completedAt).calendar()}`;
      case TripStatus.canceledByRider:
      case TripStatus.canceledByDriver:
        return `Canceled ${moment(this.canceledAt).calendar()}`;
      case TripStatus.tripTimedOut:
        return `No driver found ${moment(this.canceledAt).calendar()}`;

      default:
        return 'Trip';
    }
  }

  toLatLng() {
    return unwrap(Trip.prototype.locationsWithDefault.apply(this), locations =>
      unwrap(last(locations), location => new window.google.maps.LatLng(...location.coordinates)),
    );
  }

  get pickupDescription() {
    if (this.fromPlaceName && this.fromAddress) {
      return `${this.fromPlaceName}, ${this.fromAddress}`;
    } else if (this.fromAddress) {
      return this.fromAddress;
    } else {
      return 'Point on map';
    }
  }

  get dropOffDescription() {
    if (this.toPlaceName && this.toAddress) {
      return `${this.toPlaceName}, ${this.toAddress}`;
    } else if (this.toAddress) {
      return this.toAddress;
    } else {
      return 'Point on map';
    }
  }

  time() {
    return this.canceledAt
      ? `✗ ${moment(this.canceledAt).calendar()}`
      : this.completedAt
      ? `✓ ${moment(this.completedAt).calendar()}`
      : this.startedAt
      ? `Picked up ${moment(this.startedAt).calendar()}`
      : this.confirmedAt
      ? `Dispatched ${moment(this.confirmedAt).calendar()}`
      : this.scheduleTimestamp
      ? this.dropOffTimestampMs && this.requestedDropOffTimestampMs
        ? `Drop off ${moment(this.dropOffTimestampMs).calendar()}`
        : `Pick up ${moment(this.scheduleTimestamp).calendar()}`
      : `Created ${moment(this.createdAt).calendar()}`;
  }

  /**
   * @returns {Boolean} Whether or not the trip can be advanced using the "swipe" button. Note that a trip can't be started if a driver hasn't yet been assigned.
   */
  isAdvanceable() {
    switch (Trip.prototype.statusType.apply(this)) {
      case TripStatus.created:
      case TripStatus.scheduled:
        return Boolean(this.driverId);

      case TripStatus.accepted:
      case TripStatus.started:
        return true;

      default:
        return false;
    }
  }

  /**
   * @returns {Boolean} Whether or not the trip can be reversed using the "reverse swipe" button
   */
  isReversable() {
    switch (Trip.prototype.statusType.apply(this)) {
      case TripStatus.accepted:
      case TripStatus.started:
      case TripStatus.completed:
        return true;
      default:
        return false;
    }
  }

  isActive() {
    return [TripStatus.accepted, TripStatus.started].includes(Trip.prototype.statusType.apply(this));
  }

  /**
   * @returns {TripStatus}
   */
  statusType() {
    return TripStatus.from(this.status);
  }

  sourceType() {
    return TripSource.from(this.source);
  }

  typeType() {
    return TripType.from(this.type);
  }

  /**
   * E.g., "Pickup due in 4hr 2m" or "ASAP"
   */
  schedulingStatusString() {
    const [location, positionInList, locationsLength] = Trip.prototype.nextScheduledLocation.apply(this);

    if (location === undefined) return 'ASAP';

    if (location === null) return null;

    const locationName = unwrap(positionInList, position => position.locationName(locationsLength));

    const diff = moment(location.scheduledTimestampMs).diff(moment());
    if (diff <= 0) return `${locationName} ${moment(location.scheduledTimestampMs).calendar()}`;

    const duration = moment.duration(diff);
    const durationString = [
      unwrap(duration.years(), v => (v === 0 ? false : `${v}yr`)),
      unwrap(duration.months(), v => (v === 0 ? false : `${v}mon`)),
      unwrap(duration.days(), v => (v === 0 ? false : `${v}d`)),
      unwrap(duration.hours(), v => (v === 0 ? false : `${v}hr`)),
      unwrap(duration.minutes(), v => (v === 0 ? false : `${v}m`)),
    ]
      .filter(Boolean)
      .join(' ');

    return `${locationName} in ${durationString}`;
  }

  /**
   * E.g., "Pickup due in 4hr 2m" or "ASAP"
   */
  schedulingStatusComponent() {
    const [location, positionInList, locationsLength] = Trip.prototype.nextScheduledLocation.apply(this);

    if (location === undefined) return 'ASAP';

    if (location === null) return null;

    const locationName = unwrap(positionInList, position => position.locationName(locationsLength));

    const diff = moment(location.scheduledTimestampMs).diff(moment());
    if (diff <= 0)
      return (
        <span className="text-danger">
          {/* <i className="fas fa-exclamation-triangle" />  */}
          {locationName} {moment(location.scheduledTimestampMs).calendar()}
        </span>
      );

    const duration = moment.duration(diff);
    const durationString = MomentHelper.durationString(duration);

    return `${locationName} in ${durationString}`;
  }

  nextScheduledLocation() {
    const currentLocation = Trip.prototype.currentLocationWithDefault.apply(this);
    if (currentLocation === null) return [null];
    const locations = Trip.prototype.locationsWithDefault.apply(this);
    for (let i = currentLocation < 0 ? 0 : currentLocation; i < locations.length; i++) {
      const location = locations[i];
      if (location.scheduledTimestampMs) {
        return [location, PositionInList.for(i, locations), locations.length];
      }
    }
    return [undefined];
  }

  isOverdue() {
    return unwrap(
      Trip.prototype.nextScheduledLocation.apply(this)[0],
      l => TripLocation.prototype.isOverdue.apply(l),
      false,
    );
  }

  latLngBounds() {
    const locations = Trip.prototype.locationsWithDefault.apply(this);
    const coordinates = locations.map(_ => _.coordinates);
    return boundsForLatLngs(coordinates);
  }

  quickQuery() {
    return (
      Trip.prototype.title2.apply(this) +
      Trip.prototype.schedulingStatusString.apply(this) +
      unwrap(TripStatus.from(this.status), status => status.title, '')
    ).toLowerCase();
  }

  earliestScheduledLocation() {
    return unwrap(Trip.prototype.locationsWithDefault.apply(this), locations =>
      find(locations, location => Boolean(location && location.scheduledTimestampMs)),
    );
  }

  pickupLocation() {
    return unwrap(Trip.prototype.locationsWithDefault.apply(this), first);
  }

  dropOffLocation() {
    return unwrap(Trip.prototype.locationsWithDefault.apply(this), last);
  }

  earliestTimestamp() {
    return unwrap(Trip.prototype.earliestScheduledLocation.apply(this), location => location.scheduledTimestampMs);
  }

  constructor(properties) {
    if (properties) {
      Object.assign(this, properties);
      this.id = properties.id;
      this.ezCaterDeliveryId = properties.ezCaterDeliveryId;
      this.ezCaterPaddingM = properties.ezCaterPaddingM;
      this.customerId = properties.customerId;
      this.driverId = properties.driverId;
      this.driverConfirmationTimestampMs = properties.driverConfirmationTimestampMs;
      this.confirmedAt = properties.confirmedAt;
      this.driver = unwrap(properties.driver, td => TripDriver.fromDriver(td));
      this.orderId = properties.orderId;
      this.type = properties.type;
      this.startedAt = properties.startedAt;
    }
  }
}

/**
 *
 * @param {TripSource} source
 * @returns Trip
 */
Trip.forManualEntry = source =>
  new Trip({
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    contacts: [
      new TripContact({
        name: '',
        phone: '',
        userType: UserType.restaurant.rawValue,
      }),
      new TripContact({
        name: '',
        phone: '',
        userType: UserType.orderer.rawValue,
      }),
    ],
    customer: null,
    customerId: null,
    deviceType: DeviceType.web.rawValue,
    estimate: 0,
    firstColor: null,
    locations: [
      new TripLocation({contactUserType: UserType.restaurant.rawValue}),
      new TripLocation({contactUserType: UserType.orderer.rawValue}),
    ],
    secondColor: null,
    status: TripStatus.scheduled.rawValue,
    source: source.rawValue,
    type: TripType.delivery.rawValue,
  });

Trip.dummyForManualEntry = () =>
  new Trip({
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    contacts: [
      new TripContact({
        name: "Billy Bob's Restuarant",
        phone: '7047047704',
        userType: UserType.restaurant,
      }),
      new TripContact({
        name: 'Tim Burton',
        phone: '5677047704',
        userType: UserType.orderer,
      }),
    ],
    customer: {
      firstName: 'Tim',
      lastName: 'Hawkins',
      cellphone: '+17047047704',
    },
    customerId: 'yEHhidWcuSdT0heQ7gSAxbh8Re02',
    deviceType: DeviceType.web,
    estimate: 0,
    firstColor: null,
    locations: [
      new TripLocation({
        contactUserType: UserType.restaurant,
        contents: [
          new TripContent({title: 'Avocado sandwhich', quantity: 1}),
          new TripContent({title: 'Fries', quantity: 2}),
        ],
        instructions: 'Entry is in the back of the building',
      }),
      new TripLocation({
        contactUserType: UserType.orderer,
        scheduledTimestampMs: moment().add('2', 'days').valueOf(),
      }),
    ],
    secondColor: null,
    status: TripStatus.scheduled,
    type: TripType.delivery,
  });

Trip.premiumRideshare = (locations, waitTimeS, directionsLeg, tripType, couponCode) => {
  if (!locations || !locations[0]) {
    // if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    //     return new Trip({ "createdAt": { ".sv": "timestamp" }, "deviceType": "WEB", "estimate": null, "estimatedMiles": 8, "estimatedMinutes": 16, "firstColor": "#FFFFFF", "locations": [{ "address": "400 E M.L.K. Jr Blvd, Charlotte, NC 28202, USA", "coordinates": [35.22113660000001, -80.8439538], "googlePlaceId": "ChIJyWIVfyigVogR6c37kTbkXa4", "name": "NASCAR Hall of Fame", "geohash": null, "contactUserType": UserType.customer, "instructions": null, "contents": null, "arrivalTimestampMs": null, "scheduledTimestampMs": null, "completionTimestampMs": null, "requestedTimestampMs": null, "estimatedArrivalTimestampMs": null, "waitTimeS": null, "imageIds": null, "files": null }, { "address": "5501 Josh Birmingham Pkwy, Charlotte, NC 28208, USA", "coordinates": [35.2144026, -80.9473146], "googlePlaceId": "ChIJidRKt32YVogRN6fEPG9Kuho", "name": "Charlotte Douglas International Airport", "geohash": null, "instructions": null, "contents": null, "arrivalTimestampMs": null, "scheduledTimestampMs": null, "completionTimestampMs": null, "requestedTimestampMs": null, "estimatedArrivalTimestampMs": null, "waitTimeS": null, "imageIds": null, "files": null }], "secondColor": "#000000", "status": TripStatus.scheduled, "type": TripType.suv, "couponCode": null })
    // } else {
    return null;
    // }
  }

  if (waitTimeS) {
    locations[0].waitTimeS = waitTimeS;
  }

  const colors = Color.allCases;
  const firstIndex = Math.floor(Math.random() * colors.length);
  const firstColor = colors[firstIndex];
  const remainingColors = colors.splice(firstIndex, 1);
  const secondColor = remainingColors[Math.floor(Math.random() * remainingColors.length)];

  const estimatedMiles = unwrap(directionsLeg, leg => round(leg.distance.value * Constants.metersPerMile, 2));
  const estimatedMinutes = unwrap(directionsLeg, leg => round(leg.duration.value / 60, 2));

  return new Trip({
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    deviceType: DeviceType.web,
    estimate: null, // set by server
    estimatedMiles,
    estimatedMinutes,
    firstColor,
    locations,
    secondColor,
    status: TripStatus.scheduled,
    type: tripType,
    couponCode,
  });
};
