import driverLocationImage from '../../images/driver.png';

// Have to put this inside a function.
// The Google Maps API's are only loaded after the page loads
export function getDriverOverlay(map, coords, heading) {
  DriverOverlay.prototype = window.google.maps.OverlayView.prototype;

  function DriverOverlay(map, coords, heading) {
    // dmflog(
    //   `DriverOverlay(map, coords, heading)`,
    //   map,
    //   coords.toString(),
    //   heading
    // );
    this.setMap(map);
    this.coords = coords;
    this.heading = heading;
  }

  DriverOverlay.prototype.update = function (coords, heading) {
    // dmflog(`update(coords, heading)`, coords, heading);
    this.coords = coords;
    this.heading = heading;
  };

  DriverOverlay.prototype.onAdd = function () {
    // dmflog(`DriverOverlay.onAdd`, this);
    this.image = document.createElement('img');
    this.image.src = driverLocationImage;
    this.image.style.position = 'absolute';
    this.getPanes().overlayLayer.appendChild(this.image);
  };

  DriverOverlay.prototype.draw = function () {
    // dmflog(`DriverOverlay.draw`);
    var pixels = this.getProjection().fromLatLngToDivPixel(this.coords);
    this.image.style.left = pixels.x + 'px';
    this.image.style.top = pixels.y + 'px';
    let degrees = this.heading || 0;
    this.image.style.transform = `rotate(${degrees}deg)`;
  };

  DriverOverlay.prototype.onRemove = function () {
    // dmflog(`DriverOverlay.onRemove`);
    this.image.parentNode.removeChild(this.image);
    this.image = null;
  };

  return new DriverOverlay(map, coords, heading);
}
