import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {CoordinatesArray} from 'wave-common';
import Constants from '../../../../Constants';
import unwrap from '../../../../functions/unwrap';
import useGeoFireDatabaseJoin from '../../../../hooks/useGeoFireDatabaseJoin';
import {Item} from '../../../../hooks/useGeoFireQuery';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useRealtimeDatabase from '../../../../hooks/useRealtimeDatabase';
import useThrottledState from '../../../../hooks/useThrottledState';
import Driver from '../../../../models/scoopm/Driver';
import Trip from '../../../../models/scoopm/Trip';
import RealtimeDatabase from '../../../../references/database/RealtimeDatabase';
import DriverCard from './DriverCard';
import DriversList from './DriversList';
import Map from './Map';

export interface GeoProperties {
  centerCoordinatesArray?: CoordinatesArray;
  radiusKm?: number;
}

export default function DispatchPanel(props: {
  trip: Trip;
  onChangeDistancesMi: (distances: number[]) => void;
  onChangeGeoProperties?: (properties: GeoProperties) => void;
}) {
  const {onChangeGeoProperties} = props;

  const {state: geoProperties, setState: setGeoProperties} = useThrottledState<GeoProperties>({}, 2_000);
  useEffect(() => {
    onChangeGeoProperties && onChangeGeoProperties(geoProperties);
  }, [geoProperties, onChangeGeoProperties]);
  const [selectedDriverId, setSelectedDriverId] = useState<string>();
  const [showNearbyDrivers, setShowNearbyDrivers] = useLocalStorage('SHOW_NEARBY_DRIVERS', true as any);

  // drivers state

  const realtimeDatabaseReference = useMemo(
    () => (props.trip.isDone() ? undefined : RealtimeDatabase.instance.driversGeoFireActive.query),
    [props.trip],
  );
  const {isLoading, items: rawItems} = useGeoFireDatabaseJoin<Driver>({
    realtimeDatabaseReference,
    centerCoordinatesArray: geoProperties.centerCoordinatesArray,
    radiusKm: geoProperties.radiusKm,
    getJoinReference: useCallback(key => RealtimeDatabase.instance.drivers.child(key), []),
  });
  const driverIdForBearing = selectedDriverId ?? props.trip.driverId;
  const {isLoading: bearingIsLoading, data: bearingData} = useRealtimeDatabase({
    path: unwrap(driverIdForBearing, id => `/drivers/${id}/bearing`),
  }); // realtime updates of selected or trip driver bearings
  const parsedItems = useMemo(
    () =>
      unwrap(rawItems, items => {
        let filteredItems = items.filter(item => item.value?.types && item.value.types[props.trip.type] === true);
        if (!bearingIsLoading && bearingData) {
          const selectedDriverItemIndex = findIndex(filteredItems, item => item.key === driverIdForBearing);
          if (selectedDriverItemIndex !== -1) {
            filteredItems[selectedDriverItemIndex] = Item.from(filteredItems[selectedDriverItemIndex]);
            filteredItems[selectedDriverItemIndex].value!.bearing = bearingData;
          }
        }
        const sortedItems = sortBy(filteredItems, item =>
          (window as any).google.maps.geometry.spherical.computeDistanceBetween(
            new (window as any).google.maps.LatLng(...item.coordinatesArray),
            props.trip.fromLatLng(),
          ),
        );
        const maxItems = sortedItems.slice(0, 50);
        return maxItems;
      }) || undefined,
    [rawItems, props.trip, driverIdForBearing, bearingIsLoading, bearingData],
  );
  const parsedItemsMinusTripDriver = useMemo(
    () => unwrap(parsedItems, items => items.filter(item => item.key !== props.trip.driverId)) || undefined,
    [parsedItems, props.trip.driverId],
  );

  // render

  return (
    <div className={'row no-gutters h-100'}>
      <div
        className={'col col-sm-6 col-md-4 bg-gray-200 h-100 pb-5 overflow-scroll rounded-left'}
        style={{
          zIndex: Constants.zIndex.level1,
          boxShadow: '0.5rem 0rem 0.5rem -0.5rem rgba(0,0,0,0.2)',
          maxWidth: '400px',
        }}>
        <DriverCard trip={props.trip} selectedDriverId={selectedDriverId} setSelectedDriverId={setSelectedDriverId} />
        {realtimeDatabaseReference && (
          <DriversList
            items={parsedItemsMinusTripDriver}
            trip={props.trip}
            searchRadiusKm={geoProperties.radiusKm}
            isLoading={isLoading}
            selectedDriverId={selectedDriverId}
            setSelectedDriverId={setSelectedDriverId}
            showNearbyDrivers={showNearbyDrivers}
            setShowNearbyDrivers={setShowNearbyDrivers}
          />
        )}
      </div>
      <div className="col border border-left-0 rounded-right">
        <Map
          items={parsedItems}
          trip={props.trip}
          selectedDriverId={selectedDriverId}
          setSelectedDriverId={setSelectedDriverId}
          geoProperties={geoProperties}
          setGeoProperties={setGeoProperties}
          onChangeDistancesMi={props.onChangeDistancesMi}
          showNearbyDrivers={showNearbyDrivers}
        />
      </div>
    </div>
  );
}
