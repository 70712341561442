import HttpReference from '../platform/HttpReference';
import Quotes from './DispatchQuotes';
import EzCater from './EzCater';

export default class Dispatch extends HttpReference {
  constructor(path, parent) {
    super(path, parent);

    this.ezCater = new EzCater('ezcater', this);
    this.quotes = new Quotes('quotes', this);
  }
}
