import React, {ReactNode} from 'react';
import {environmentFirebaseConfig} from '../Environment';
import ClaimsGuard from '../guards/ClaimsGuard';
import CustomClaim from '../models/scoopm/CustomClaim';

export function ViewInFirebaseLink({
  path,
  className,
  children,
  firestore = false,
  ...props
}: {
  path: string;
  className?: string;
  children?: ReactNode;
  firestore?: boolean;
}) {
  return (
    <ClaimsGuard claim={CustomClaim.manageApi}>
      <a
        href={
          firestore
            ? `https://console.firebase.google.com/u/0/project/${
                environmentFirebaseConfig.projectId
              }/firestore/data/${encodeURIComponent(path)}`
            : `https://console.firebase.google.com/u/0/project/${environmentFirebaseConfig.projectId}/database/${environmentFirebaseConfig.projectId}/data${path}`
        }
        className={className}
        {...props}>
        {children}
      </a>
    </ClaimsGuard>
  );
}

export default function ViewInFirebase({
  path,
  firestorePath,
  padded = true,
  className,
}: {
  path?: string;
  firestorePath?: string;
  padded?: boolean;
  className?: string;
}) {
  return (
    <ClaimsGuard claim={CustomClaim.manageApi}>
      <div className={'text-muted ' + (padded ? 'p-1 p-md-2 ' : '') + (className || '')}>
        {path && (
          <ViewInFirebaseLink path={path} className="btn btn-link">
            View in Realtime Database
          </ViewInFirebaseLink>
        )}
        {firestorePath && (
          <ViewInFirebaseLink path={firestorePath} className="btn btn-link" firestore>
            View in Firestore
          </ViewInFirebaseLink>
        )}
      </div>
    </ClaimsGuard>
  );
}
