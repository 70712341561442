"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zendriveTripsList = void 0;
const ZendriveTrip_1 = require("../models/ZendriveTrip");
const ValidationResult_1 = require("../type-descriptions/ValidationResult");
const PATH = 'zendrive-trips';
async function zendriveTripsList(start, end, database) {
    const options = {
        orderByChild: 'startTimestampSec',
        startAt: Math.floor(start.valueOf() / 1000),
        endAt: Math.floor(end.valueOf() / 1000),
    };
    console.log(options);
    const results = await database.list(PATH, options);
    return results.map(result => {
        try {
            return (0, ValidationResult_1.validationResultCheck)(ZendriveTrip_1.zendriveTripDescription.validate(Object.assign({ id: result.id }, result.value)));
        }
        catch (error) {
            throw new Error(`Error validating Zendrive trip ${result.id}: ${error.message}`);
        }
    });
}
exports.zendriveTripsList = zendriveTripsList;
