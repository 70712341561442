import React, {FunctionComponent} from 'react';
import {join} from '../functions/join';

export default function Join<T>(props: {array: T[] | undefined; separator: FunctionComponent}) {
  return (
    <>
      {join(props.array, index => (
        <props.separator key={index} />
      ))}
    </>
  );
}
