import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {
  pricingFormulaInputVerb,
  pricingFormulaInputVisibleToAdmins,
  pricingFormulatInputTitle,
} from 'wave-common/lib/models/PricingFormulaInput';
import FontAwesome from '../../components/FontAwesome';
import unwrap from '../../functions/unwrap';
import InvalidFeedback from './InvalidFeedback';
import numberForString from './numberForString';
import stringForNumber from './stringForNumber';

export default function PricingFormulaItemInput({index, property, id, value, onChange, disabled, validationError}) {
  const update = callback => {
    const newValue = unwrap(value, value => Object.assign({}, value), {});
    callback(newValue);
    onChange(newValue);
  };

  return (
    <div className="row mx-0 mb-1 bg-white rounded py-2 py-md-3 px-1">
      <div className="col-auto">
        <div className="row bg-gray">
          {/* name */}

          <div className="col-auto">
            <input
              type="text"
              value={unwrap(value, value => value.name) || ''}
              onChange={event => {
                event.preventDefault();
                update(pricingFormulaItem => (pricingFormulaItem.name = event.target.value || null));
              }}
              placeholder="Name"
              className={
                'form-control form-control d-inline w-auto' +
                unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid'), '')
              }
              disabled={disabled}
            />
            <InvalidFeedback property={property} error={validationError} />
          </div>

          <div className="col-auto">
            <div className="d-flex align-items-center">
              {/* input */}

              <select
                value={unwrap(value, value => value.input) || ''}
                onChange={event => {
                  event.preventDefault();
                  update(pricingFormulaItem => (pricingFormulaItem.input = event.target.value || null));
                }}
                className={
                  'custom-select custom-select d-inline w-auto' +
                  unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid'), '')
                }
                disabled={disabled}>
                <option value="">Flat fee</option>
                {pricingFormulaInputVisibleToAdmins.map(input => (
                  <option key={input} value={input}>
                    {pricingFormulatInputTitle(input)}
                  </option>
                ))}
              </select>

              {/* amount */}

              <div className="ml-2 input-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">{unwrap(value, value => value.input) ? '×' : '$'}</span>
                </div>
                <input
                  type="text"
                  value={stringForNumber(unwrap(value, value => value.amount))}
                  onChange={event => {
                    event.preventDefault();
                    update(pricingFormulaItem => (pricingFormulaItem.amount = numberForString(event.target.value)));
                  }}
                  placeholder="Amount"
                  className={
                    'form-control form-control' +
                    unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid'), '')
                  }
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>

        {/* conditions */}

        <div className="row mt-2 mt-md-3">
          <div className="col">
            {unwrap(value, value =>
              unwrap(value.conditions, conditions =>
                conditions.map((condition, i) => (
                  <div key={i} className="row align-items-center mx-0 p-1 mb-2 rounded border">
                    <div className="col-auto">Only when</div>
                    {/* input */}

                    <div className="col-auto">
                      <select
                        value={condition.input}
                        onChange={event => {
                          event.preventDefault();
                          update(
                            pricingFormulaItem => (pricingFormulaItem.conditions[i].input = event.target.value || null),
                          );
                        }}
                        className={
                          'custom-select custom-select-sm d-inline w-auto mr-2' +
                          unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid'), '')
                        }
                        disabled={disabled}>
                        {pricingFormulaInputVisibleToAdmins.map(input => (
                          <option key={input} value={input}>
                            {pricingFormulatInputTitle(input)}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* min/max */}

                    <div className="col-auto">{pricingFormulaInputVerb(condition.input)} between</div>

                    <div className="col-auto">
                      <input
                        type="number"
                        value={stringForNumber(condition.minimumInclusive)}
                        onChange={event =>
                          update(pricingFormulaItem => {
                            pricingFormulaItem.conditions[i].minimumInclusive = numberForString(event.target.value);
                          })
                        }
                        placeholder="0"
                        className="form-control form-control-sm"
                        style={{width: '5rem'}}
                      />
                    </div>

                    <div className="col-auto d-flex align-items-center">and</div>

                    <div className="col-auto">
                      <input
                        type="number"
                        value={stringForNumber(condition.maximumExclusive)}
                        onChange={event =>
                          update(pricingFormulaItem => {
                            pricingFormulaItem.conditions[i].maximumExclusive = numberForString(event.target.value);
                          })
                        }
                        placeholder="Infinity"
                        className="form-control form-control-sm"
                        style={{width: '5rem'}}
                      />

                      {/* <Slider
                                        domain={sliderDomain}
                                        values={[(condition.minimumInclusive || sliderDomain[0]), (condition.maximumExclusive || sliderDomain[1])]}
                                        step={sliderStep}
                                        onUpdate={([mininum, maximum]) => update(pricingFormulaItem => {
                                            pricingFormulaItem.conditions[i].minimumInclusive = mininum
                                            pricingFormulaItem.conditions[i].maximumExclusive = maximum
                                        })}
                                        disabled={disabled}
                                    /> */}
                    </div>

                    {/* apply to value? */}

                    <div className="col-auto d-flex align-items-center">
                      <div className="custom-control custom-switch">
                        <input
                          id={id + 'applyRangeToInputValue' + i}
                          type="checkbox"
                          checked={Boolean(condition.applyRangeToInputValue)}
                          onChange={event =>
                            update(
                              pricingFormulaItem =>
                                (pricingFormulaItem.conditions[i].applyRangeToInputValue = event.target.checked),
                            )
                          }
                          className={
                            'custom-control-input' +
                            unwrap(validationError, error => (error ? ' is-invalid' : ' is-valid'), '')
                          }
                        />
                        <label htmlFor={id + 'applyRangeToInputValue' + i} className="custom-control-label">
                          Only within range{' '}
                          {condition.applyRangeToInputValue &&
                            condition.maximumExclusive &&
                            `(of ${
                              (condition.maximumExclusive || Number.MAX_SAFE_INTEGER) -
                              (condition.minimumInclusive || 0)
                            })`}
                        </label>
                      </div>
                    </div>

                    {/* delete */}

                    <div className="col-auto">
                      <OverlayTrigger overlay={<Tooltip>Remove this condition</Tooltip>}>
                        <button
                          onClick={event => {
                            event.preventDefault();
                            update(pricingFormulaItem => {
                              pricingFormulaItem.conditions.splice(i, 1);
                              if (!pricingFormulaItem.conditions.length) pricingFormulaItem.conditions = null;
                            });
                          }}
                          className="btn btn-sm"
                          title="Delete this condition"
                          disabled={disabled}>
                          <i className="fas fa-trash-alt" />
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                )),
              ),
            )}

            {/* add condition */}

            <div className="text-muted">
              <OverlayTrigger overlay={<Tooltip>Add condition</Tooltip>}>
                <button
                  onClick={event => {
                    event.preventDefault();
                    update(
                      pricingFormulaItem =>
                        (pricingFormulaItem.conditions = [
                          ...(unwrap(value, v => v.conditions) || []),
                          {input: pricingFormulaInputVisibleToAdmins[0]},
                        ]),
                    );
                  }}
                  className="btn btn-sm btn-light"
                  disabled={disabled}>
                  <i className="fas fa-plus" /> Add condition
                </button>
              </OverlayTrigger>
            </div>

            {unwrap(value, value =>
              unwrap(value.conditions, conditions => (
                <div className="text-muted mt-2">
                  <small>
                    <FontAwesome.InfoCircle /> This pricing item will only be applied when all of the conditions are
                    true
                  </small>
                </div>
              )),
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
