import {firestore} from 'firebase/app';
import {Location} from 'history';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {formatCurrency, tripRead, tripShortName, unwrap, useStatus} from 'wave-common';
import {momentFormatDateTimeSeconds} from 'wave-common/lib/helpers/MomentHelper';
import {driverEarningsDLogDeserialize} from 'wave-common/lib/models/DriverEarningsDLog';
import {authTypeName} from 'wave-common/lib/models/firebase/AuthType';
import Card from '../../../components/Card';
import {DelaySpinner} from '../../../components/Spinner';
import RealtimeDatabaseDataSource from '../../../data-sources/RealtimeDatabaseDataSource';
import useFirestoreQueryListener from '../../../hooks/useFirestoreQueryListener';
import CenteredLayout from '../../../layouts/CenteredLayout';

export default function DriverEarningsDLogs({location}: {location: Location}) {
  const {tripId, limit} = useMemo<{tripId?: string; limit?: number}>(
    () =>
      unwrap(
        location.search || undefined,
        search => {
          const params = new URLSearchParams(search);
          return {
            tripId: params.get('tripId') ?? undefined,
            limit: unwrap(params.get('limit'), Number),
          };
        },
        () => ({}),
      ),
    [location.search],
  );

  const {isLoading, error, snapshot} = useFirestoreQueryListener(
    useMemo(
      () =>
        tripId
          ? firestore().collection('driver-earnings-d-logs').where('tripId', '==', tripId)
          : firestore()
              .collection('driver-earnings-d-logs')
              .orderBy('eventTimestampMs', 'desc')
              .limit(limit ?? 10),
      [tripId, limit],
    ),
  );

  const logs = useMemo(
    () =>
      unwrap(snapshot, snapshot =>
        reverse(
          sortBy(
            snapshot.docs.map(doc => driverEarningsDLogDeserialize(doc.data(), doc.id)),
            'eventTimestampMs',
          ),
        ),
      ),
    [snapshot],
  );

  return (
    <Card padded innerPadded={false}>
      {isLoading ? (
        <div className="p-2 p-md-3">
          <CenteredLayout>
            <DelaySpinner />
          </CenteredLayout>
        </div>
      ) : error ? (
        <div className="p-2 p-md-3">Error: ${error.message}</div>
      ) : logs?.length ?? 0 > 1 ? (
        <>
          <div className="p-2 p-md-3">
            <h4 className="mb-0">Driver rate history</h4>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Amount</th>
                <th>Changed by</th>
                <th>Date/time</th>
                {!tripId && <th>Job</th>}
              </tr>
            </thead>
            <tbody>
              {logs?.map(log => (
                <tr key={log.id}>
                  <td>{formatCurrency(log.value)}</td>
                  <td>
                    {log.uid ? (
                      <Link to={`/admin/users/${log.uid}`}>{log.userCasualName ?? log.authType}</Link>
                    ) : (
                      log.userCasualName ?? authTypeName(log.authType)
                    )}
                  </td>
                  <td>{momentFormatDateTimeSeconds(moment(log.eventTimestampMs))}</td>
                  {!tripId && (
                    <td>
                      <Link to={`/admin/trips/${log.tripId}`}>
                        <TripTitle tripId={log.tripId} /> &rarr;
                      </Link>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div className="p-2 p-md-3">No data</div>
      )}
    </Card>
  );
}

function TripTitle({tripId}: {tripId: string}) {
  const {isPending, value: trip} = useStatus(
    React as any,
    useMemo(() => tripRead(undefined, tripId, RealtimeDatabaseDataSource.instance), [tripId]),
  );
  return <>{isPending ? <DelaySpinner small /> : trip ? tripShortName(trip) : 'View trip'} </>;
}
