import React from 'react';
import {JobCard} from '.';
import useHistoryTrips from '../../hooks/useHistoryTrips';
import ContainerLayout from '../../layouts/ContainerLayout';

export default function History() {
  const {models} = useHistoryTrips();

  return (
    <ContainerLayout>
      <h1>History</h1>

      {models && models.map(model => <JobCard key={model.id} trip={model} className="w-100 mb-2 mb-md-3" />)}
    </ContainerLayout>
  );
}
