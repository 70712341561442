import moment from 'moment';
import React from 'react';
import Card from '../../../components/Card';
import Delay from '../../../components/Delay';
import RawData from '../../../components/RawData';
import Spinner from '../../../components/Spinner';
import ViewInFirebaseLink from '../../../components/ViewInFirebase';
import useRealtimeDatabase from '../../../hooks/useRealtimeDatabase';

export default function Event({match}) {
  const event = useRealtimeDatabase({
    path: `/events/${match.params.eventId}`,
  });

  if (event.isLoading)
    return (
      <Delay>
        <Spinner />
      </Delay>
    );

  if (!event.data) return 'No data';

  return (
    <>
      <div className="p-2 p-md-3 bg-light">
        <h3>Event details</h3>

        <Card className="mb-3">
          <p>{event.data.path}</p>
          <p>{moment(event.data.timestampMs).calendar()}</p>
        </Card>

        {event.data.error && (
          <Card className="mb-3">
            <h5 className="text-danger">
              <i className="fas fa-exclamation-triangle mr-2" />
              Error
            </h5>

            <h6>{event.data.error.message}</h6>

            <table className="table table-sm table-striped table-borderless border-bottom table-hover mb-0">
              <tbody>
                {Object.values(event.data.error.stackTrace).map((trace, i) => (
                  <tr key={i}>
                    <td>{i}</td>
                    <td>{trace}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        )}

        {event.data.requestBody && (
          <Card className="mb-3" innerPadded={false}>
            <RawData title="Request body" data={event.data.requestBody} />
          </Card>
        )}

        {event.data.responseBody && (
          <Card className="mb-3" innerPadded={false}>
            <RawData title="Response body" data={event.data.responseBody} />
          </Card>
        )}

        <Card innerPadded={false}>
          <RawData title="Other data" data={event.data} keysToHide={['requestBody', 'responseBody', 'error']} />
        </Card>
      </div>

      <ViewInFirebaseLink path={`/events/${event.key}`} />
    </>
  );
}
