import firebase from 'firebase/app';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import Card from '../../../components/Card';
import Code from '../../../components/Code';
import CopyButton from '../../../components/CopyButton';
import {useAuthContext} from '../../../contexts/AuthContext';
import {formatPhoneE164} from '../../../functions/formatPhone';
import useCurrentUserEmail from '../../../hooks/useCurrentUserEmail';
import computeFirestoreIndexes from './firestore-indexes/computeFirestoreIndexes';
import Scripts from './Scripts';
import UpdateVehiclesDatabaseButton from './UpdateVehiclesDatabaseButton';
// const libphonenumber = require("libphonenumber-js")

export default function ApiDashboard() {
  // return <FixBrokenTrips />;

  const {uid, token, error} = useAuthContext();
  const email = useCurrentUserEmail();
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState();

  useEffect(() => {
    console.clear();
    window.firebase = firebase;
    // window.lodash = lodash
    window.formatPhoneE164 = formatPhoneE164;
    window.moment = moment;
    // window.libphonenumber = libphonenumber
    return () => {
      delete window.firebase;
      delete window.lodash;
      delete window.formatPhoneE164;
      // delete window.libphonenumber
    };
  }, []);

  // useEffect(() => {
  // console.log('Updating drivers.............')

  // Promise.resolve()
  //     .then(async () => {
  //         // get drivers

  //         const snapshot = await firebase
  //             .database()
  //             .ref('drivers')
  //             .orderByChild('autoCalculateDelayedEarnings')
  //             .equalTo(true)
  //             .once('value')
  //         const value = snapshot.val()
  //         if (!value) throw new Error('No drivers')
  //         const driverIds = Object.keys(value)
  //         console.log(`${driverIds.length} drivers`)

  //         // iterate drivers

  //         for (const driverId of driverIds) {
  //             console.log('driverId', driverId)

  //             // update driver

  //             const values = {
  //                 allowPayout: true,
  //                 autoCalculateInstantEarnings: null,
  //                 autoCalculateDelayedEarnings: true,
  //             }
  //             console.log('update', { driverId }, values)
  //             await firebase.database().ref('drivers').child(driverId).update(values)

  //             // wait

  //             await new Promise(resolve => {
  //                 setTimeout(() => {
  //                     resolve()
  //                 }, 250)
  //             })
  //         }
  //     })
  //     .then(console.log, console.error)
  // }, [])

  // state: code eval

  const evalCode = useCallback(
    event => {
      event.preventDefault();

      try {
        eval(code);
        setCodeError(null);
      } catch (error) {
        setCodeError(error);
      }
    },
    [code],
  );

  // RENDER

  if (error) {
    return error.message;
  } else if (!token) {
    return 'Bad state';
  } else {
    return (
      <Card padded>
        <div className="row mb-3">
          <div className="col">
            <h3>Maintenance functions</h3>
            <div className="row">
              <div className="col-auto">
                <UpdateVehiclesDatabaseButton />
              </div>
              {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                <div className="col-auto">
                  <button type="button" onClick={event => computeFirestoreIndexes()} className="btn btn-secondary">
                    Download Firestore Indexes
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h3>Email</h3>
          </div>
          <div className="col-auto">
            <CopyButton text={email} className="btn btn-sm btn-light" />
          </div>
        </div>
        <Code>{email}</Code>

        <div className="row">
          <div className="col">
            <h3>UID</h3>
          </div>
          <div className="col-auto">
            <CopyButton text={uid} className="btn btn-sm btn-light" />
          </div>
        </div>
        <Code>{uid}</Code>

        <div className="row">
          <div className="col">
            <h3>Token</h3>
          </div>
          <div className="col-auto">
            <CopyButton text={token} className="btn btn-sm btn-light" />
          </div>
        </div>
        <Code>{token}</Code>

        <h3>Eval</h3>
        <div className="mb-2">
          <textarea value={code} onChange={event => setCode(event.target.value)} rows="10" className="form-control" />
        </div>
        <div className="mb-2">
          <button onClick={evalCode} className="btn btn-primary">
            Run
          </button>
        </div>
        {codeError && (
          <div className="alert alert-warning">
            <pre>{codeError.stack}</pre>
          </div>
        )}

        {process.env.REACT_APP_ENVIRONMENT !== 'production' && <Scripts />}
      </Card>
    );
  }
}
