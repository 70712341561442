import Enum from '../base/Enum';

export default class Color extends Enum {
  name() {
    switch (this) {
      case Color.red:
        return 'red';
      case Color.orange:
        return 'orange';
      case Color.yellow:
        return 'yellow';
      case Color.green:
        return 'green';
      case Color.blue:
        return 'blue';
      case Color.purple:
        return 'purple';
      case Color.black:
        return 'black';
      case Color.white:
        return 'white';
      default:
        throw new Error('Unkown Color');
    }
  }
}

Color.red = new Color('#FF0900');
Color.orange = new Color('#FF7F00');
Color.yellow = new Color('#FFEF00');
Color.green = new Color('#00F11D');
Color.blue = new Color('#0079FF');
Color.purple = new Color('#A800FF');
Color.black = new Color('#000000');
Color.white = new Color('#FFFFFF');
