import firebase from 'firebase/app';

/**
 * Removes `undefined` values by parsing to a JSON string and then back to a plain JavaScript object
 * @param {Object} object
 */
export default function cleanForFirebase(object) {
  if (object && typeof object === 'object') {
    let cleanedObject = Array.isArray(object) ? [] : {};
    Object.entries(object).forEach(([key, value]) => {
      let newValue;
      if (value instanceof firebase.firestore.FieldValue) {
        newValue = value;
      } else if (value instanceof firebase.firestore.Timestamp) {
        newValue = value;
      } else if (Array.isArray(value)) {
        newValue = value.map(cleanForFirebase);
      } else if (typeof value === 'object') {
        newValue = JSON.parse(JSON.stringify(value));
      } else if (value === undefined) {
        newValue = null;
      } else {
        newValue = value;
      }
      if (Array.isArray(object)) {
        cleanedObject.push(object);
      } else {
        cleanedObject[key] = newValue;
      }
    });
    return cleanedObject;
  } else {
    return JSON.parse(JSON.stringify(object));
  }
}
