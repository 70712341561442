import firebase from 'firebase/app';
import sortBy from 'lodash/sortBy';
import React, {useMemo} from 'react';
import {deserializeArray, tripDeserialize, tripTypeRideshare, unwrap} from 'wave-common';
import Card from '../../../components/Card';
import Delay from '../../../components/Delay';
import Spinner from '../../../components/Spinner';
import Table from '../../../components/Table';
import ColumnMetadata from '../../../components/Table/ColumnMetadata';
import DataRow from '../../../components/Table/DataRow';
import {useAdditionalNavbarContent} from '../../../contexts/AdditionalNavbarContentContext';
import useFirestoreQueryListener from '../../../hooks/useFirestoreQueryListener';
import useJobsTableTimezone from '../../../hooks/useJobsTableTimezone';
import useLocalStorage from '../../../hooks/useLocalStorage';
import TripType from '../../../models/scoopm/TripType';
import StatusGroup from './StatusGroup';

export default function withRideshareJobs(statusGroup: StatusGroup) {
  return function RideshareJobs() {
    // state

    const [visibleColumnsString] = useLocalStorage(
      'JOBS_TABLE_COLUMNS',
      useMemo(
        () =>
          ColumnMetadata.jobsTableDefault()
            .map(cm => cm.path)
            .join(',') as any,
        [],
      ),
    );

    const metadatas = useMemo(() => {
      const visibleColumnsStrings = unwrap(visibleColumnsString, string => string.split(','));
      return ColumnMetadata.all().filter(metadata => visibleColumnsStrings.includes(metadata.path));
    }, [visibleColumnsString]);

    const [timezone] = useJobsTableTimezone();

    // listen for snapshots

    const {isLoading: scoopMIsLoading, error: scoopMError, snapshot: scoopMSnapshot} = useListener(TripType.scoopM);
    const {isLoading: scoopXlIsLoading, error: scoopXlError, snapshot: scoopXlSnapshot} = useListener(TripType.scoopXl);
    const {isLoading: butlerIsLoading, error: butlerError, snapshot: butlerSnapshot} = useListener(TripType.butler);
    const {isLoading: nannyIsLoading, error: nannyError, snapshot: nannySnapshot} = useListener(TripType.nanny);
    const {isLoading: luxIsLoading, error: luxError, snapshot: luxSnapshot} = useListener(TripType.lux);
    const {isLoading: suvIsLoading, error: suvError, snapshot: suvSnapshot} = useListener(TripType.suv);
    const {isLoading: poshIsLoading, error: poshError, snapshot: poshSnapshot} = useListener(TripType.posh);

    // create rows

    const rows = useMemo(() => {
      // merge docs

      const docs = [
        ...(scoopMSnapshot?.docs ?? []),
        ...(scoopXlSnapshot?.docs ?? []),
        ...(butlerSnapshot?.docs ?? []),
        ...(nannySnapshot?.docs ?? []),
        ...(luxSnapshot?.docs ?? []),
        ...(suvSnapshot?.docs ?? []),
        ...(poshSnapshot?.docs ?? []),
      ];

      if (docs.length) {
        // deserialize

        const trips = deserializeArray((doc: any) => tripDeserialize(doc.data(), doc.id))(docs);

        // filter/sort

        const filteredTrips = sortBy(
          trips.filter(trip => trip && tripTypeRideshare.includes(trip.type as never)),
          trip =>
            trip.scheduleTimestamp ??
            trip.dropOffTimestampMs ??
            trip.locations?.find(l => l.scheduledTimestampMs) ??
            trip.createdAt ??
            0,
          trip => trip.createdAt ?? 0,
        );

        // create rows

        const creator = DataRow.creator(metadatas, null, timezone);
        return filteredTrips.map((trip, index) =>
          creator(trip.id, trip, `/admin/jobs/${trip.customerId}/${trip.id}`, index),
        );
      } else {
        return [];
      }
    }, [
      scoopMSnapshot?.docs,
      scoopXlSnapshot?.docs,
      butlerSnapshot?.docs,
      nannySnapshot?.docs,
      luxSnapshot?.docs,
      suvSnapshot?.docs,
      poshSnapshot?.docs,
      metadatas,
      timezone,
    ]);

    // bar

    const isLoading =
      scoopMIsLoading &&
      scoopXlIsLoading &&
      butlerIsLoading &&
      nannyIsLoading &&
      luxIsLoading &&
      suvIsLoading &&
      poshIsLoading;

    useAdditionalNavbarContent(
      useMemo(
        () =>
          isLoading && (
            <Delay>
              <div className="grow d-flex align-items-center px-3">
                <Spinner />
              </div>
            </Delay>
          ),
        [isLoading],
      ),
    );

    // render

    const error = scoopMError || scoopXlError || butlerError || nannyError || luxError || suvError || poshError;

    if (error) {
      return (
        <Card padded>
          <pre style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{error.message}</pre>
        </Card>
      );
    }

    return (
      <div className="h-100 d-flex flex-column">
        <Table columns={metadatas} rows={rows} className={'flex-grow-1' as any} />
      </div>
    );
  };

  // listener hook function

  function useListener(type: TripType) {
    return useFirestoreQueryListener(
      useMemo(
        () =>
          firebase
            .firestore()
            .collection('trips')
            .orderBy('createdAt', 'desc')
            .where(
              'status',
              'in',
              StatusGroup.statuses(statusGroup).map(s => s.rawValue),
            )
            .where('type', '==', type.rawValue),
        [type.rawValue],
      ),
    );
  }
}
