import {firestore} from 'firebase/app';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {useQueryParam} from 'use-query-params';
import {tripShortName, unwrap} from 'wave-common';
import {PATH} from 'wave-common/lib/controllers/TripLog';
import {momentFormatDateTimeSeconds} from 'wave-common/lib/helpers/MomentHelper';
import {authTypeName} from 'wave-common/lib/models/firebase/AuthType';
import {objectLogDeserialize} from 'wave-common/lib/models/ObjectLog';
import Card from '../../../components/Card';
import {DelaySpinner} from '../../../components/Spinner';
import useFirestoreQueryListener from '../../../hooks/useFirestoreQueryListener';

const withObjectLogs =
  // <T extends unknown>


    (
      collection: string,
      // deserialize: (value: unknown, id: unknown) => ObjectLog<T>,
      objectPath: string,
      objectTitle?: (object: any) => string,
    ) =>
    () => {
      // useTitle('Job logs');

      const [objectId] = useQueryParam<string | undefined>('objectId');
      const [limit] = useQueryParam('limit', {decode: string => unwrap(string, Number), encode: String});

      const {isLoading, error, snapshot} = useFirestoreQueryListener(
        useMemo(
          () =>
            objectId
              ? firestore().collection(collection).where('objectId', '==', objectId)
              : firestore()
                  .collection(collection)
                  .orderBy('timestampMs', 'desc')
                  .limit(limit ?? 500),
          [objectId, limit],
        ),
      );

      const logs = useMemo(
        () =>
          unwrap(snapshot, snapshot =>
            reverse(
              sortBy(
                snapshot.docs.map(doc => objectLogDeserialize(doc.data(), doc.id)),
                'timestampMs',
              ),
            ),
          ),
        [snapshot],
      );

      return (
        <Card padded innerPadded={!logs?.length}>
          {isLoading ? (
            <DelaySpinner />
          ) : error ? (
            `Error: ${error.message}`
          ) : logs?.length ?? 0 > 1 ? (
            <table className="table">
              <thead>
                <tr className="table-borderless">
                  <th> </th>
                  <th>Value</th>
                  <th>Changed by</th>
                  <th>Object ID</th>
                  {objectTitle && <th>Object title</th>}
                  <th>Date/time</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {logs?.map((log, i) => (
                  <tr key={log.id}>
                    <td>{i + 1}</td>
                    <td className="ellipsis" style={{maxWidth: 200}}>
                      <Link to={`/admin/${collection}/${log.id}`}>
                        {unwrap(log.object, object => JSON.stringify(object))}
                      </Link>
                    </td>
                    <td>
                      {log.modifierId ? (
                        <Link to={`/admin/users/${log.modifierId}`}>
                          {log.modifierCasualName ?? log.modifierAuthType}
                        </Link>
                      ) : (
                        log.modifierCasualName ?? authTypeName(log.modifierAuthType)
                      )}
                    </td>
                    <td>
                      <Link to={`/admin/${objectPath}/${log.objectId}`}>{log.objectId}</Link>
                    </td>
                    {objectTitle && (
                      <td>
                        {log.object && (
                          <Link to={`/admin/${objectPath}/${log.objectId}`}>{objectTitle(log.object)}</Link>
                        )}
                      </td>
                    )}
                    <td>{momentFormatDateTimeSeconds(moment(log.timestampMs))}</td>
                    <td>
                      <Link to={`/admin/${collection}/${log.id}`}>Details &rarr;</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            'No data'
          )}
        </Card>
      );
    };

export const TripLogs = withObjectLogs(PATH, 'jobs', tripShortName);
