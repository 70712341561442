import HttpReference from '../platform/HttpReference';
import Estimate from './Estimate';

export default class Pricing extends HttpReference {
  constructor(path, parent) {
    super(path, parent);

    this.estimate = new Estimate('estimate', this);
  }
}
