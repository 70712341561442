import React from 'react';
import Anchor from '../../components/Anchor';
import DownloadOnAppStore from '../../components/app-download-buttons/DownloadOnAppStore';
import GetItOnGooglePlay from '../../components/app-download-buttons/GetItOnGooglePlay';
import third from '../../images/home/third.png';

export default function Features() {
  return (
    <>
      <Anchor id="features" />

      <div className="bg-light py-4">
        <div className="container py-5">
          <h1 className="text-center display-4 mb-5">Unique rideshare features</h1>

          <div className="row">
            <div className="col-lg text-right">
              <div className="row mb-4 mb-lg-5">
                <div className="col">
                  <h6>The panic button</h6>
                  <p className="text-muted">
                    If there is ever an emergency during the ride – press and release the panic button. We will handle
                    the rest!
                  </p>
                </div>
                <div className="col-auto">
                  <i className="fas fa-exclamation white-dot" />
                </div>
              </div>
              <div className="row mb-4 mb-lg-5">
                <div className="col">
                  <h6>Video surveillance</h6>
                  <p className="text-muted">In-App Video Surveillance. Saved to the Cloud</p>
                </div>
                <div className="col-auto">
                  <i className="fas fa-video white-dot" />
                </div>
              </div>
              <div className="row mb-4 mb-lg-5">
                <div className="col">
                  <h6>Driver likes</h6>
                  <p className="text-muted">Like or Dislike your driver? Simple yet powerful!</p>
                </div>
                <div className="col-auto">
                  <i className="fas fa-thumbs-up white-dot" />
                </div>
              </div>
            </div>

            <div className="col-lg mb-5 text-center">
              <img src={third} alt="Screenshot" width={310} height={672} className="screenshot" />
            </div>

            <div className="col-lg">
              <div className="row mb-4 mb-lg-5">
                <div className="col-auto">
                  <i className="fas fa-phone white-dot" />
                </div>
                <div className="col">
                  <h6>Call us any time!</h6>
                  <p className="text-muted">No hassle, pick up the phone and we’ll be there!</p>
                </div>
              </div>
              <div className="row mb-4 mb-lg-5">
                <div className="col-auto">
                  <i className="fas fa-user-shield white-dot" />
                </div>
                <div className="col">
                  <h6>Full rider coverage</h6>
                  <p className="text-muted">Rest assured that in an accident, you are well covered!</p>
                </div>
              </div>
              <div className="row mb-4 mb-lg-5">
                <div className="col-auto">
                  <i className="fas fa-heart white-dot" />
                </div>
                <div className="col">
                  <h6>Pick your driver</h6>
                  <p className="text-muted">
                    You are the valued customer. Pick who you want to drive you to your destination!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primary-to-success text-white p-5">
        <div className="container p-4">
          <div className="row">
            <div className="col">
              <h4 style={{opacity: 0.5}} className="font-weight-bold">
                Available on the App Store and Google Play
              </h4>
              <h1 className="font-weight-light display-4">Download the Wave app now</h1>
            </div>
            <div className="col-auto align-self-center">
              <GetItOnGooglePlay />

              <div className="d-inline-block mb-3">
                <DownloadOnAppStore />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
