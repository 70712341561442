import React from 'react';
import {formatPhone} from '../functions/formatPhone';
import unwrap from '../functions/unwrap';
import useApprovedDrivers from '../hooks/useApprovedDrivers';
import Driver from '../models/scoopm/Driver';

export default function DriverSelect({value, onChange, defaultText, onGetDrivers = undefined, className, ...props}) {
  const drivers = useApprovedDrivers();

  return (
    <select
      type="text"
      value={value || ''}
      onChange={event =>
        onChange(event.target.value || null, unwrap(drivers.find(driver => driver.id === event.target.value)))
      }
      className={'mw-100 ' + (className || '')}
      {...props}>
      <option value="">{defaultText || 'None'}</option>
      {drivers &&
        drivers.map(driver => (
          <option key={driver.id} value={driver.id}>
            {driver.firstName} {driver.lastName}
            {unwrap(
              driver.email,
              email => ` (${email})`,
              () => unwrap(Driver.prototype.safePhoneNumber.apply(driver), phone => ` ${formatPhone(phone)}`),
            )}
          </option>
        ))}
    </select>
  );
}
