"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableWidthPixels = void 0;
const pixels = {
    SM: 150,
    MD: 250,
    LG: 300,
};
function tableWidthPixels(width) {
    return pixels[width];
}
exports.tableWidthPixels = tableWidthPixels;
