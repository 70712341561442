import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import {useMemo} from 'react';
import unwrap from '../functions/unwrap';
import Region from '../models/scoopm/Region';
import SelectOption from '../models/SelectOption';
import {statesOptionsLimited} from '../Regions';
import regionSchema from '../schemas/wavem/Region';
import useFirestoreSchemaGet from './useFirestoreSchemaGet';
import useLocalStorage from './useLocalStorage';

export default function useStatesAndRegions(limit?: number, localStorageKey?: string) {
  const {models: regions, isLoading} = useFirestoreSchemaGet<Region>(regionSchema);

  const regionOptions = useMemo<SelectOption[]>(
    () =>
      unwrap(
        regions,
        regions => [
          ...sortBy(regions, 'name').map(
            region =>
              new SelectOption(region.id ?? '', region.name + (limit ? ` (out of the ${limit} jobs queried)` : '')),
          ),
          new SelectOption('', '-', false),
          ...statesOptionsLimited,
        ],
        statesOptionsLimited,
      ) || [],
    [regions, limit],
  );

  const [region, setRegion] = useLocalStorage(localStorageKey ?? 'JOBS_TABLE_REGION');

  const regionObject = useMemo(
    () => unwrap(region, r => (r.length === 2 ? null : find(regions, r => r.id === region))),
    [region, regions],
  );

  const state = useMemo(() => unwrap(region, r => (r.length === 2 ? region : null)), [region]);

  return {
    regionOptions,
    regionValue: region,
    setRegion,
    regionObject,
    state,
    isLoading,
  };
}
