import pluralize from 'pluralize';
import React, {useMemo} from 'react';
import {tripIsActive, unwrap, useStatus} from 'wave-common';
import {driverRead} from 'wave-common/lib/controllers/Driver';
import Trip from 'wave-common/lib/models/Trip';
import {vehicleFromDriver} from 'wave-common/lib/models/Vehicle';
import RealtimeDatabaseDataSource from '../../data-sources/RealtimeDatabaseDataSource';
import useFuelImageUrl from '../../hooks/useFuelImageUrl';

import {Card} from './Card';

export function VehicleRow({trip}: {trip: Trip | undefined}) {
  const {value: driver} = useStatus(
    React as never,
    useMemo(
      () => unwrap(trip?.driverId, driverId => driverRead(driverId, RealtimeDatabaseDataSource.instance)),
      [trip?.driverId],
    ),
  );

  const fuelUrl = useFuelImageUrl(
    useMemo(
      () =>
        unwrap(driver, driver => {
          try {
            return vehicleFromDriver(driver);
          } catch (error) {
            return undefined;
          }
        }),
      [driver],
    ),
  );

  if (!trip?.driver || !driver?.licensePlateNumber) {
    return null;
  }

  if (!tripIsActive(trip)) {
    return null;
  }

  return (
    <div className="row mb-3">
      <div className="col-md-6">
        <Card className="border">
          <div className="row">
            <div className="col">
              {/* title */}

              <span>Look for</span>

              {/* name */}

              <h4 className="mb-0 font-weight-bold">{driver.licensePlateNumber}</h4>

              {/* make/model */}

              <div className="text-gray-600">
                {driver.make} {driver.model}
              </div>

              {/* car color / seats */}

              <div className="text-gray-600">
                {driver.carColor}{' '}
                {driver.carSeats && (
                  <span>
                    &middot; {driver.carSeats} car {pluralize('seat', driver.carSeats)}
                  </span>
                )}
              </div>
            </div>

            {/* profile picture */}

            <div className="col-auto">
              <img
                src={fuelUrl}
                alt=""
                className="rounded-xl border"
                style={{
                  objectFit: 'cover',
                  width: '130px',
                  height: '100%',
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
