export class Api {
  static isInProductionMode = process.env.REACT_APP_ENVIRONMENT === 'production';

  static scoopBackendLive = false || Api.isInProductionMode;
  static dwollaLive = false || Api.isInProductionMode;

  static get ScoopMDriver() {
    return process.env.REACT_APP_SLIM_SERVER_URL;
  }

  static get ScoopMPayment() {
    return Api.ScoopMDriver + '/pay';
  }

  static getDwollaUrl = () =>
    Api.dwollaLive ? 'https://dashboard.dwolla.com' : 'https://dashboard-sandbox.dwolla.com';

  static getDwollaCustomerUrl = id => `${Api.getDwollaUrl()}/customers/${id}/details`;

  static ScoopMEmail = 'https://driver.scoopm.com/email';

  static get PayPal() {
    return {
      sandbox: 'AVQ-B39KnLWs6SgkPfORmSm3QScKFXsrHDdhF-CRAasHFapvo6cIkAMk6l0dDzh58T1NsCcQaFsqEH0k',
      production: 'ARKRthJFuCydGblenq0FKJe3VHsObxHQLU58K7WsM6_TyGFxK8YRzM9_ZdgowwBvqkX5J8dwVklGyH2_',
    };
  }
  static get PayPalEnvironment() {
    return Api.isInProductionMode ? 'production' : 'sandbox';
  }

  static TwilioRoot = process.env.REACT_APP_SLIM_SERVER_URL + '/texts';

  static TwilioServer = {
    acceptedRide: Api.TwilioRoot + '/acceptedRide',
    customerRideCreated: Api.TwilioRoot + '/queue/customer/ride-created',
    customerDriverDispatched: Api.TwilioRoot + '/queue/customer/driver-dispatched',
    driverDispatched: Api.TwilioRoot + '/queue/driver/dispatched',
    customerRideCompleted: Api.TwilioRoot + '/queue/customer/ride-completed',
    driverRideCompleted: Api.TwilioRoot + '/queue/driver/ride-completed',
  };
}
