import {useEffect, useMemo, useState} from 'react';
import usePrevious from './usePrevious';

// TYPES

type Result_Base<Type extends string> = {type: Type};

export type FailureResult = Result_Base<'FAILURE'> & {error: Error};
export type SuccessResult<T> = Result_Base<'SUCCESS'> & {value: T};

export type Result<T> = FailureResult | SuccessResult<T>;

// HOOK

export default function useResult<T>(promise: Promise<T>) {
  const [result, setResult] = useState<Result<T>>();

  const previousPromise = usePrevious(promise);
  const isLoading = useMemo(() => promise !== previousPromise, [promise, previousPromise]);

  useEffect(() => {
    promise.then(
      value => setResult({type: 'SUCCESS', value}),
      error => setResult({type: 'FAILURE', error}),
    );
  }, [promise]);

  return {
    isLoading,
    result,
    error: (result as FailureResult)?.error as Error | undefined,
    value: (result as SuccessResult<T>)?.value as T | undefined,
  };
}
