import flatMap from 'lodash/flatMap';
import {useMemo} from 'react';
import Marker from '../components/GoogleMaps/Marker';
import Trip from '../models/scoopm/Trip';

export default function useMarkersForTrips(
  trips?: Trip[],
  onClick?: (trip: Trip) => void,
  showOnlyPickupExceptForTripId?: string,
) {
  return useMemo(() => {
    if (!trips || !trips.length) {
      return undefined;
    } else {
      return flatMap(trips, trip => Marker.markersForTrip(trip, onClick, showOnlyPickupExceptForTripId));
    }
  }, [trips, onClick, showOnlyPickupExceptForTripId]);
}
