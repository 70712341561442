"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tripSourceDescription = void 0;
const TripSource_1 = require("../../models/TripSource");
const Enum_1 = require("../Enum");
exports.tripSourceDescription = (0, Enum_1.enumDescription)({
    userFacingTitle: 'trip source',
    userFacingValue: TripSource_1.tripSourceDescription,
    values: TripSource_1.tripSourceAllKnown,
    order: source => TripSource_1.tripSourceAllKnownInOrder.indexOf(source),
});
