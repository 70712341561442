import driverFlags from './driverFlags';

const driverFlagsApproved = [
  {
    property: 'isActivated',
    name: 'Active (can accept rides)',
    shortName: 'Active',
    searchOnly: false,
  },
  ...driverFlags,
];

export default driverFlagsApproved;
