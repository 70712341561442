import ScoopMApi from '.';
import HttpReference from '../platform/HttpReference';
import Report from './GatekeeperReport';

export default class Gatekeeper extends HttpReference<void, Report, ScoopMApi> {
  report: Report;

  constructor(path: string, parent: ScoopMApi) {
    super(path, parent);

    this.report = new Report('report', this);
  }
}
