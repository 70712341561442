import pluralize from 'pluralize';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {CoordinatesArray, expect, filterBoolean, unwrap} from 'wave-common';
import {latLngLiteralFromCoordinatesArray} from 'wave-common/lib/models/CoordinatesArray';
import Zone from 'wave-common/lib/models/Zone';
import FontAwesome from '../../components/FontAwesome';
import GoogleMap from '../../components/GoogleMaps/GoogleMap';
import {iconMapPin3FillBorder, labelWhite} from '../../components/GoogleMaps/helpers';
import {boundsForLatLngs, latLng} from '../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../components/GoogleMaps/MapContents';
import {GoogleLatLng} from '../../components/GoogleMaps/Marker';
import {ArrayPropertyInputProps} from './PropertyInputProps';

export default function ZonesInput({
  index,
  property,
  id,
  value,
  model,
  onChange,
  validationError,
  disabled,
}: ArrayPropertyInputProps<Partial<Zone>>) {
  const nonNullZones = useMemo(() => unwrap(value, filterBoolean), [value]);

  // refs

  const mapRef = useRef<any>();

  // callback: on change property

  const onChangeProperty = useCallback(
    (property: string, propertyValue: any, index: number) => {
      const newZones = [...nonNullZones!];
      newZones.splice(index, 1, {
        ...nonNullZones![index],
        [property]: propertyValue,
      });
      onChange(newZones);
    },
    [nonNullZones, onChange],
  );

  // map contents

  const [mapContents, setMapContents] = useState<MapContents>();

  useEffect(() => {
    if (mapContents) {
      // only set once
      return;
    }
    const coordinates = filterBoolean(nonNullZones?.map(zone => zone.coordinates) ?? []);
    if (coordinates.length > 1) {
      setMapContents(
        new MapContents.Bounds({
          id: 'zones',
          name: pluralize('Zone', nonNullZones!.length),
          latLngBounds: boundsForLatLngs(coordinates.map(latLngLiteralFromCoordinatesArray)),
        }),
      );
    } else if (coordinates.length === 1) {
      setMapContents(
        new MapContents.Coordinates({
          id: 'zone',
          name: 'Zone',
          latLng: latLng(coordinates[0]),
          zoom: 14,
        }),
      );
    } else if (model?.location?.coordinates) {
      setMapContents(
        new MapContents.Coordinates({
          id: 'location_center',
          name: 'Location',
          latLng: latLng(model.location.coordinates as CoordinatesArray),
          zoom: 14,
        }),
      );
    } else {
      // return MapContents.Bounds.unitedStates();
    }
  }, [mapContents, nonNullZones, model?.location?.coordinates]);

  // markers

  const markers = useMemo(
    () =>
      nonNullZones?.map((zone, index) => ({
        id: expect(zone.id),
        position: latLngLiteralFromCoordinatesArray(expect(zone.coordinates)),
        label: labelWhite(zone.name ?? 'Unnamed zone'),
        icon: iconMapPin3FillBorder(),
        infoWindowComponent: (
          <Info
            id={id}
            zone={zone}
            onChange={onChangeProperty}
            onDelete={() => {
              const newZones = [...nonNullZones!];
              newZones.splice(index, 1);
              onChange(newZones);
            }}
            index={index}
          />
        ),
        draggable: true,
        onDragEnd: ({latLng}: {latLng: GoogleLatLng}) =>
          onChangeProperty('coordinates', [latLng.lat(), latLng.lng()], index),
      })),
    [id, nonNullZones, onChangeProperty, onChange],
  );

  // render

  return (
    <>
      {validationError && <div className="alert alert-danger">{validationError.message}</div>}
      <div className="position-relative">
        <div
          className="position-absolute"
          style={{
            top: 10,
            right: 10,
            zIndex: 1000,
          }}>
          <button
            type="button"
            onClick={() => {
              onChange([
                ...(nonNullZones ?? []),
                {
                  id: uuidv4(),
                  coordinates: [mapRef.current.getCenter().lat(), mapRef.current.getCenter().lng()],
                  allowsPickup: false,
                  allowsDropOff: false,
                },
              ]);
            }}
            className="btn bg-white font-weight-bold shadow">
            <FontAwesome.Plus /> Add zone
          </button>
        </div>
        <div style={{height: '22rem'}}>
          <GoogleMap
            onMap={(map: any) => (mapRef.current = map)}
            mapContents={mapContents}
            markers={markers}
            fullscreenControl={false}
            streetViewControl={false}
            useDefaultMapStyles
            rounded
          />
        </div>
      </div>
    </>
  );
}

function Info({
  id,
  zone,
  onChange,
  onDelete,
  index,
}: {
  id: string;
  zone: Partial<Zone>;
  onChange: (property: string, value: string | boolean, index: number) => void;
  onDelete: () => void;
  index: number;
}) {
  return (
    <div className="p-1" style={{fontSize: '1rem', fontWeight: 'normal', lineHeight: '1.5'}}>
      <div className="mb-2">
        <input
          type="text"
          placeholder="Name"
          className="form-control"
          value={zone.name ?? ''}
          onChange={event => onChange('name', event.target.value, index)}
        />
      </div>
      <div className="mb-2">
        <Switch
          title="Allow pickup"
          id={id + 'allowPickup'}
          checked={zone.allowsPickup ?? false}
          onChange={checked => onChange('allowsPickup', checked, index)}
        />
      </div>
      <div className="mb-2">
        <Switch
          title="Allow drop-off"
          id={id + 'allowDropOff'}
          checked={zone.allowsDropOff ?? false}
          onChange={checked => onChange('allowsDropOff', checked, index)}
        />
      </div>
      <button type="button" onClick={onDelete} className="btn btn-outline-danger w-100">
        <FontAwesome.Trash /> Remove zone
      </button>
    </div>
  );
}

function Switch({
  title,
  id,
  checked,
  onChange,
}: {
  title: string;
  id: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) {
  return (
    <div className="custom-control custom-switch">
      <input
        id={id}
        name={id}
        type="checkbox"
        checked={checked}
        onChange={event => onChange(event.target.checked)}
        className="custom-control-input"
      />
      <label className="custom-control-label" htmlFor={id}>
        {title}
      </label>
    </div>
  );
}
