import React from 'react';
import GoogleMap from '../../components/GoogleMaps/GoogleMap';
import useMapPropsForTripLocations from '../../hooks/useMapPropsForTripLocations';

export function MapColumn({locations}) {
  const {mapContents, markers, paths} = useMapPropsForTripLocations(...(locations || []));

  return (
    <div className="col-md mt-3 mt-md-0" style={{minHeight: '28rem'}}>
      <GoogleMap
        mapContents={mapContents}
        markers={markers}
        paths={paths}
        mapTypeControl={false}
        streetViewControl={false}
        rotateControl={false}
        fullscreenControl={false}
        rounded
      />
    </div>
  );
}
