import React from 'react';
import PositionInList from '../../models/scoopm/PositionInList';
import {Card} from './Card';
import {LocationCard} from './LocationCard';

export function LocationsColumn({locations, driverId, driver}) {
  return (
    <div className="col-md-6">
      <Card className="border">
        {locations ? (
          locations.map((location, i) => (
            <div key={i}>
              <LocationCard
                location={location}
                locationsCount={locations.length}
                positionInList={PositionInList.for(i, locations)}
              />
            </div>
          ))
        ) : (
          <>
            <LocationCard />

            <LocationCard />
          </>
        )}
      </Card>
    </div>
  );
}
