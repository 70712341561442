import {firestore} from 'firebase/app';
import moment from 'moment';
import {tripDeserialize, TripOfNumber} from 'wave-common/lib/models/Trip';
import {SubscribeFunction} from '../../../../hooks/useListenerStatus';

export default function subscribeForDate(date: Date): SubscribeFunction<TripOfNumber[]> {
  return (_, onSuccess, onFailure) =>
    firestore()
      .collection('trips')
      .where('firstScheduledTimestampMs', '>=', moment(date).startOf('day').valueOf())
      .where('firstScheduledTimestampMs', '<=', moment(date).endOf('day').valueOf())
      // .where('source','==','EZ_CATER')
      .onSnapshot(
        snapshot => onSuccess(snapshot.docs.map(doc => tripDeserialize(doc.data(), doc.id) as TripOfNumber)),
        onFailure,
      );
}
