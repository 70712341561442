import React, {useEffect, useState} from 'react';
import GoogleMap from '../../../../components/GoogleMaps/GoogleMap';
import {latLng} from '../../../../components/GoogleMaps/LatLng+additions';
import MapContents from '../../../../components/GoogleMaps/MapContents';
import Marker from '../../../../components/GoogleMaps/Marker';
import unwrap from '../../../../functions/unwrap';
import useRealtimeDatabase from '../../../../hooks/useRealtimeDatabase';
import Driver from '../../../../models/scoopm/Driver';
import DriverGeoFire from '../../../../models/scoopm/DriverGeoFire';
import DriverGeoFireActive from '../../../../models/scoopm/DriverGeoFireActive';
import RealtimeDatabase from '../../../../references/database/RealtimeDatabase';

const locationSources = {
  driversGeoFire: 'Idle',
  driver: 'Active (old)',
  driversGeoFireActive: 'Active (new)',
};

export default function DriverAvailability({data, driverId}) {
  // STATE

  const [isUpdatingAvailability, setIsUpdatingAvailability] = useState(false);
  const [isShowingLocation, setIsShowingLocation] = useState(false);
  const [locationSource, setLocationSource] = useState(locationSources.driver);
  const [pathToObserve, setPathToObserve] = useState();
  const location = useRealtimeDatabase({path: pathToObserve});

  const [mapContents, setMapContents] = useState();
  const [markers, setMarkers] = useState();

  // EFFECT: location source --> path to observe

  useEffect(() => {
    switch (locationSource) {
      case locationSources.driver:
        setPathToObserve(`/drivers/${driverId}/coordinate`);
        break;
      case locationSources.driversGeoFire:
        setPathToObserve(`/drivers-geo-fire/delivery/${driverId}/l`);
        break;
      case locationSources.driversGeoFireActive:
        setPathToObserve(`/drivers-geo-fire-active/${driverId}/l`);
        break;
      default:
        return;
    }
  }, [driverId, locationSource]);

  // EFFECT: location data --> map contents

  useEffect(() => {
    setMarkers(unwrap(location.data, data => [new Marker({id: 'first', position: data})]));
    setMapContents(unwrap(location.data, data => new MapContents.Coordinates({latLng: latLng(data), zoom: 11})));
  }, [location.data]);

  // METHOD: on change active status

  function onChange(event) {
    if (!driverId) return console.error('No driver id to use');

    setIsUpdatingAvailability(true);

    // update `inPickupModeEnabled`

    const inPickupModeEnabled = event.target.checked;
    RealtimeDatabase.instance.drivers
      .child(driverId)
      .update({inPickupModeEnabled})
      .catch(error => {
        window.alert(`Unable to change driver availability. ${error.message}`);
      })
      .finally(() => {
        setIsUpdatingAvailability(false);
      });

    // if disabling, clear geofire/coordinates nodes

    if (!inPickupModeEnabled) {
      Promise.all([
        Driver.update(driverId, {coordinate: null}),
        DriverGeoFire.remove(driverId),
        DriverGeoFireActive.remove(driverId),
      ]).catch(error => {
        window.alert(`Unable to clear driver location from system. ${error.message}`);
      });
    }
  }

  // RENDER

  return (
    <div>
      {/* switch */}

      <div className="custom-control custom-switch mb-2 mb-md-3">
        <input
          id="inPickupModeEnabledSwitchInput"
          type="checkbox"
          onChange={onChange}
          className="custom-control-input"
          checked={unwrap(data, d => d.inPickupModeEnabled)}
          disabled={!data || isUpdatingAvailability}
        />
        <label htmlFor="inPickupModeEnabledSwitchInput" className="custom-control-label">
          {unwrap(data, d => (d.inPickupModeEnabled ? 'Available' : 'Unavailable'), 'Loading...')}
        </label>
      </div>

      <div className="row d-flex align-items-center mx-0 mb-2">
        <div className="col">
          <a href="#show-location" onClick={event => setIsShowingLocation(!isShowingLocation)}>
            {isShowingLocation ? 'Hide' : 'Show'} location
          </a>
        </div>
        {isShowingLocation && (
          <div className="btn-group">
            {Object.values(locationSources).map((source, i) => (
              <button
                key={i}
                type="button"
                onClick={event => setLocationSource(source)}
                className={'btn btn-sm' + (locationSource === source ? ' btn-secondary' : ' btn-light')}>
                {source}
              </button>
            ))}
          </div>
        )}
      </div>
      {isShowingLocation && (
        <div style={{height: '15rem'}}>
          <GoogleMap
            markers={markers}
            mapContents={mapContents}
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              zoomControl: false,
            }}
          />
        </div>
      )}
    </div>
  );
}
