import round from 'lodash/round';
import React, {useMemo} from 'react';
import {
  PricingFormulaItem as Model,
  pricingFormulatInputValue,
  // tripUpdate,
  unwrap,
} from 'wave-common';
import {
  pricingFormulaInputAmountFormatted,
  pricingFormulaInputValueFormatted,
  pricingFormulatInputTitle,
} from 'wave-common/lib/models/PricingFormulaInput';
import {pricingFormulaItemValueWithinRange} from 'wave-common/lib/models/PricingFormulaItem';
import Row from './Row';

export default function PricingFormulaItem({
  item,
  charge,
  miles,
  minutes,
  tip,
  revenue,
}: {
  item: Model;
  charge: number;
  miles: number;
  minutes: number;
  tip: number;
  revenue: number;
}) {
  const inputValue = useMemo(() => {
    if (item.input) {
      return pricingFormulatInputValue(item.input, charge, miles, minutes, tip, revenue);
    } else {
      return undefined;
    }
  }, [item.input, charge, miles, minutes, tip, revenue]);

  const inputValueWithinRange: number | undefined = useMemo(
    () => unwrap(inputValue, value => pricingFormulaItemValueWithinRange(item, value)),
    [inputValue, item],
  );

  const title = useMemo(() => {
    if (inputValue) {
      return `${item.name ?? pricingFormulatInputTitle(item.input!)}: ${pricingFormulaInputValueFormatted(
        item.input!,
        inputValueWithinRange ?? inputValue,
      )} ${
        inputValueWithinRange ? `out of ${pricingFormulaInputValueFormatted(item.input!, inputValue)} ` : ''
      }✕ ${pricingFormulaInputAmountFormatted(item.input!, item.amount)}`;
    } else {
      return (
        <>
          {item.name} {item.name ? `(flat amount)` : 'Flat amount'}
        </>
      );
    }
  }, [item, inputValue, inputValueWithinRange]);

  const amountD = useMemo(
    () => round(inputValue ? (inputValueWithinRange ?? inputValue) * item.amount : item.amount, 2),
    [item.amount, inputValue, inputValueWithinRange],
  );

  return <Row title={title} amountD={amountD} />;
}
