import Fountain from '.';
import FunnelsPage from '../../../models/fountain/FunnelsPage';
import HttpReference from '../../platform/HttpReference';

export default class Funnels extends HttpReference<any, void, Fountain> {
  async headers(): Promise<Record<string, string> | null> {
    return null;
  }

  list(page = 0) {
    console.log(`Getting page ${page} of funnels...`);
    return this.request<FunnelsPage>({
      method: 'get',
      params: {
        page,
      },
    });
  }
}
