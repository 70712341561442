import isInteger from 'lodash/isInteger';
import sortBy from 'lodash/sortBy';
import times from 'lodash/times';
import moment from 'moment';
import pluralize from 'pluralize';
import React, {useMemo} from 'react';
import {match} from 'react-router-dom';
import {baseUserFullName, difference, Driver, expect, unwrap, useStatus} from 'wave-common';
import {driverRatingListForDriverId} from 'wave-common/lib/controllers/DriverRating';
import {tripListForFeedbackAndDriverId} from 'wave-common/lib/controllers/Trip';
import {integerTimestampFromFirstoreTimestamp} from 'wave-common/lib/models/integerTimestamp';
import {FontAwesomeV5} from '../../../components/FontAwesome';
import {DelaySpinner} from '../../../components/Spinner';
import {useTitle} from '../../../contexts/TitleContext';
import FirestoreDataSource from '../../../data-sources/FirestoreDataSource';
import ClaimsGuard from '../../../guards/ClaimsGuard';
import useDriver from '../../../hooks/useDriver';
import CenteredLayout from '../../../layouts/CenteredLayout';
import CustomClaim from '../../../models/scoopm/CustomClaim';

export default function Feedback({match}: {match: match<{id: string}>}) {
  // driver

  const {isLoading: driverIsLoading, error: driverError, data: driver} = useDriver(match.params.id);

  // page title

  useTitle(useMemo(() => unwrap(driver, driver => `Feedback for ${baseUserFullName(driver)}`), [driver]));

  // trips

  const {
    isPending: tripsArePending,
    error: tripsError,
    value: trips,
  } = useStatus(
    React as never,
    useMemo(() => tripListForFeedbackAndDriverId(match.params.id, FirestoreDataSource), [match.params.id]),
  );

  // ratings

  const {
    isPending: ratingsArePending,
    error: ratingsError,
    value: ratings,
  } = useStatus(
    React as never,
    useMemo(() => driverRatingListForDriverId(match.params.id, FirestoreDataSource), [match.params.id]),
  );

  // responses

  const responses = useMemo(() => {
    // wait for trips and ratings

    if (!trips || !ratings) {
      return undefined;
    }

    // set up `remainingRatings` which will be iterated over after iterating the trips, in the event that the rating doesn't match up with a trip

    const remainingRatings = [...ratings];
    const responses: Response[] = [];

    // iterate trips

    for (const trip of trips) {
      // find rating within 60 seconds of trip feedback

      const matchingRatingIndex = remainingRatings.findIndex(
        rating =>
          difference(integerTimestampFromFirstoreTimestamp(rating.timestamp), trip.riderFeedbackTimestampMs!) < 60000,
      );

      // add `Response`

      responses.push({
        id: trip.id,
        comments: trip.comments,
        stars: remainingRatings[matchingRatingIndex]?.stars,
        timestamp: expect(trip.riderFeedbackTimestampMs, 'riderFeedbackTimestampMs'),
      });

      // remove rating from `remainingRatings`, if found

      if (matchingRatingIndex !== -1) {
        remainingRatings.splice(matchingRatingIndex, 1);
      }
    }

    // iterate remaining ratings that didn't match a trip. add them to the responses array

    for (const rating of remainingRatings) {
      responses.push({
        id: rating.id,
        comments: undefined,
        stars: rating.stars,
        timestamp: integerTimestampFromFirstoreTimestamp(rating.timestamp),
      });
    }

    // sort responses by timestamp, descending

    return sortBy(responses, response => -response.timestamp);
  }, [ratings, trips]);

  // wait for data to load

  if (driverIsLoading || tripsArePending || ratingsArePending) {
    return (
      <CenteredLayout>
        <DelaySpinner />
      </CenteredLayout>
    );
  }

  // check for errors

  if (driverError) {
    return 'Unable to load driver';
  }

  if (tripsError) {
    return 'Unable to load comment data';
  }

  if (ratingsError) {
    return 'Unable to load ratings';
  }

  // return content

  return (
    driver &&
    ratings &&
    responses && (
      <>
        <div className="row m-2">
          {/* <h1>Feedback</h1> */}
          {/* <div>{baseUserFullName(driver)}</div> */}
          <div className="col-12 col-xl-6">
            <div className="bg-white rounded p-2 p-md-3 mb-2 mb-md-3">
              <div className="form-group">
                <h5 className="mb-1">Average rating</h5>
                <Stars count={(driver as Driver).stars} /> {(driver as Driver).stars}{' '}
                {pluralize('star', (driver as Driver).stars)}
              </div>
              <div className="form-group">
                <h5 className="mb-1">Total ratings</h5>
                {ratings.length}
              </div>
              <div>
                <h5 className="mb-1">Number of responses</h5>
                {responses.length}
              </div>
              <ClaimsGuard claim={CustomClaim.manageApi}>
                <div className="mt-4">
                  <h5>Developer details</h5>
                  <div>Trip count: {trips?.length}</div>
                  <div>Ratings count: {ratings.length}</div>
                  <div>Driver ratingCount property: {(driver as Driver).ratingCount}</div>
                </div>
              </ClaimsGuard>
            </div>
          </div>
        </div>
        {/* <h5 className="ml-3">Responses</h5> */}
        <div className="row m-2">
          {responses?.map(response => (
            <TripComments key={response.id} response={response} />
          ))}
        </div>
      </>
    )
  );
}

interface Response {
  id: string;
  comments?: string;
  stars?: number;
  timestamp: number;
}

export function TripComments({response}: {response: Response}) {
  return (
    <>
      <div className="col-12 col-xl-6">
        <div className="bg-white rounded p-2 p-md-3 mb-2 mb-md-3">
          <div className="row">
            <div className="col-auto d-flex flex-column align-items-center">
              <div>
                <Stars count={response.stars} />
              </div>
              <small className={response.stars ? 'text-muted' : 'text-gray-500'}>
                {response.stars ? `${response.stars} ${pluralize('star', response.stars)}` : 'No rating'}
              </small>
            </div>
            <div className="col">
              <span className="float-right text-muted">
                {moment(response.timestamp).format('dddd, MMMM Do YYYY [at] h:mm a')}
              </span>
              <span>{response.comments ?? <span className="text-gray-500">No comments</span>}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col" />
    </>
  );
}

export function Stars({count}: {count?: number}) {
  return (
    <>
      {times(5).map(i => {
        return (
          <FontAwesomeV5
            key={i}
            name={count !== undefined && i === Math.floor(count) && !isInteger(count) ? 'star-half-alt' : 'star'}
            type={count === undefined ? 'solid' : i < count ? 'solid' : 'regular'}
            className={count === undefined ? 'text-gray-200' : i < count ? 'text-warning' : 'text-gray-500'}
          />
        );
      })}
    </>
  );
}
