import {tripSourceAllKnown, tripSourceDescription} from 'wave-common/lib/models/TripSource';
import TripStatus from '../../models/scoopm/TripStatus';
import TripType from '../../models/scoopm/TripType';
import {StatesOptions} from '../../Regions';
import FilterType from './FilterType';

export default class FilterMetadata {
  constructor({name, field, type, options = null, value}) {
    this.name = name;
    this.field = field;
    this.type = type;
    this.options = options;
    this.value = value;
  }
}

FilterMetadata.forTrips = groups => {
  const filters = [
    FilterMetadata.type,
    FilterMetadata.source,
    FilterMetadata.status,
    FilterMetadata.dropOffDateRange,
    FilterMetadata.driver,
    FilterMetadata.isScheduled,
    FilterMetadata.state(),
  ];
  if (groups && !groups.find(([id, group]) => group.restrictCustomerFilter)) {
    filters.push(FilterMetadata.customer);
  }
  return filters;
};

FilterMetadata.customer = new FilterMetadata({
  name: 'Customer',
  field: 'customerId',
  type: FilterType.singleValue,
  options: [
    {
      value: '3XABIEybLBOhsCnHJozngtzmZi12',
      name: 'Jack Parrish (sunflour@sunflourbakingcompany.com)',
    },
    {
      value: '6LMuQi14XbdBJeTdofffHl5L8Zz2',
      name: 'Jack Parrish (dilworth@sunflourbakingcompany.com)',
    },
    {
      value: 'Jz1dV6wRQHZwpsu7cY3TYkRvac23',
      name: 'Jack Parrish (sunflour@sunflowerbakingcompany.com)',
    },
    {
      value: 'pSEWd5IYkaSpgcxiI2DkZ1Kmniw2',
      name: 'Jack Parrish (ballantyne@sunflourbakingcompany.com)',
    },
    {
      value: 'pquCNqWOvxYuogekeQxfxb5QNnb2',
      name: 'Jack Parrish (elizabeth@sunflourbakingcompany.com)',
    },
    {
      value: 'thsDrwIbXGbqFIN0NMU6aGfWlvO2',
      name: 'Jack Parrish (harrisburg@sunflourbakingcompany.com)',
    },
  ],
});

FilterMetadata.type = new FilterMetadata({
  name: 'Type',
  field: 'type',
  type: FilterType.singleValue,
  options: TripType.allCases.map(type => ({
    value: type.rawValue,
    name: type.title,
  })),
});

FilterMetadata.source = new FilterMetadata({
  name: 'Source',
  field: 'source',
  type: FilterType.singleValue,
  options: tripSourceAllKnown.map(source => ({
    value: source,
    name: tripSourceDescription(source),
  })),
});

FilterMetadata.status = new FilterMetadata({
  name: 'Status',
  field: 'status',
  type: FilterType.array,
  options: TripStatus.inOrder().map(type => ({
    value: type.rawValue,
    name: type.title(),
  })),
});

FilterMetadata.dropOffDateRange = new FilterMetadata({
  name: 'Drop-off range',
  field: 'dropOffTimestampMs',
  type: FilterType.dateRange,
});

FilterMetadata.driver = new FilterMetadata({
  name: 'Driver',
  field: 'driverId',
  type: FilterType.driverId,
});

FilterMetadata.isScheduled = new FilterMetadata({
  name: 'Scheduled only',
  field: 'isScheduled',
  type: FilterType.boolean,
});

FilterMetadata.state = () =>
  new FilterMetadata({
    name: 'State',
    field: 'state',
    type: FilterType.singleValue,
    options: StatesOptions.map(({value, text}) => ({value, name: text})),
  });
