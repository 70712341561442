"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chatRecipientTypeDeserialize = exports.chatRecipientTypeAll = void 0;
exports.chatRecipientTypeAll = ['CUSTOMER', 'DRIVER'];
function chatRecipientTypeDeserialize(value) {
    if (!exports.chatRecipientTypeAll.includes(value)) {
        throw new Error(`Invalid chat recipient type "${value}"`);
    }
    return value;
}
exports.chatRecipientTypeDeserialize = chatRecipientTypeDeserialize;
