import find from 'lodash/find';
import first from 'lodash/first';
import last from 'lodash/last';
import moment from 'moment-timezone';
import React from 'react';
import {tripLocationsWithDefault} from 'wave-common';
import {useAlert} from '../../contexts/AlertContext';
import formatCurrency from '../../functions/formatCurrency';
import {formatPhone} from '../../functions/formatPhone';
import unwrap from '../../functions/unwrap';
import Enum from '../../models/base/Enum';
import Order from '../../models/ezCater/Order';
import Driver from '../../models/scoopm/Driver';
import Trip from '../../models/scoopm/Trip';
import {TripSource} from '../../models/scoopm/TripSource';
import TripStatus from '../../models/scoopm/TripStatus';
import TripType from '../../models/scoopm/TripType';
import UserType from '../../models/scoopm/UserType';
import Alert from '../Alert/Alert';

export default class ColumnMetadata extends Enum {
  static get allCases() {
    return ColumnMetadata.all();
  }

  static from(path) {
    return ColumnMetadata.all().find(metadata => metadata.path === path) || null;
  }

  constructor({path, name, get, format, Component, width, additionalCsvColumn, formatCsv, disableSort}) {
    super(path);

    this.path = path;
    this.name = name;
    this.get = get;
    this.format = format;
    this.Component = Component;
    this.width = width || 150;
    this.additionalCsvColumn = additionalCsvColumn;
    this.formatCsv = formatCsv;
    this.title = name;
    this.disableSort = disableSort;
  }
}

ColumnMetadata.all = () => [
  ColumnMetadata.index,
  ColumnMetadata.type,
  ColumnMetadata.createdAt,
  ColumnMetadata.status,
  ColumnMetadata.driverConfirmation,
  ColumnMetadata.source,
  ColumnMetadata.fromPlaceName,
  ColumnMetadata.pickup,
  ColumnMetadata.dropOff,
  ColumnMetadata.vendorName,
  ColumnMetadata.createdBy,
  ColumnMetadata.orderId,
  ColumnMetadata.state,
  ColumnMetadata.date,
  ColumnMetadata.pickupTime,
  ColumnMetadata.dropOffTime,
  ColumnMetadata.itemCount,
  ColumnMetadata.padding,
  ColumnMetadata.driver,
  ColumnMetadata.customerName,
  ColumnMetadata.customerPhone,
  ColumnMetadata.customerAddress,
  ColumnMetadata.orderTotal,
  ColumnMetadata.tip,
  ColumnMetadata.estimate,
  ColumnMetadata.didOverrideEstimate,
  ColumnMetadata.estimatedMiles,
  ColumnMetadata.driverEarningsD,
  ColumnMetadata.didOverrideDriverEarnings,
  ColumnMetadata.cashedOutAt,
  ColumnMetadata.ezDeliveryId,
  ColumnMetadata.customerId,
];

ColumnMetadata.default = () => [
  ColumnMetadata.status,
  ColumnMetadata.driverConfirmation,
  ColumnMetadata.fromPlaceName,
  // ColumnMetadata.dropOff,
  ColumnMetadata.vendorName,
  ColumnMetadata.orderId,
  ColumnMetadata.state,
  ColumnMetadata.date,
  ColumnMetadata.pickupTime,
  ColumnMetadata.dropOffTime,
  ColumnMetadata.itemCount,
  ColumnMetadata.padding,
  ColumnMetadata.driver,
  ColumnMetadata.customerName,
  ColumnMetadata.customerPhone,
  ColumnMetadata.customerAddress,
  ColumnMetadata.orderTotal,
  ColumnMetadata.tip,
  ColumnMetadata.estimate,
  ColumnMetadata.didOverrideEstimate,
  ColumnMetadata.estimatedMiles,
  ColumnMetadata.driverEarningsD,
  ColumnMetadata.didOverrideDriverEarnings,
  ColumnMetadata.cashedOutAt,
];

ColumnMetadata.jobsTableDefault = () => [ColumnMetadata.index, ...ColumnMetadata.default()];

const widths = {
  date: 200,
  dateTime: 210,
};

ColumnMetadata.acceptOrder = new ColumnMetadata({
  path: 'ACCEPT_BUTTON',
  name: 'Accept',
  Component: ({data}) => {
    const {setAlert} = useAlert();
    return (
      data.status === 'CREATED' && (
        <div>
          <button
            onClick={event => {
              event.preventDefault();
              setAlert(
                new Alert('Accept order?', undefined, 'Accept', undefined, 'Reject', undefined, () => {
                  window.alert('Done');
                }),
              );
            }}
            className="btn btn-sm btn-outline-primary">
            Accept
          </button>
        </div>
      )
    );
  },
});

ColumnMetadata.index = new ColumnMetadata({
  name: '#',
  path: 'index',
  get: (data, id, index) => index + 1,
  Component: TextMuted,
  width: 42,
});

ColumnMetadata.type = new ColumnMetadata({
  name: 'Type',
  path: 'type',
  format: rawValue => unwrap(TripType.from(rawValue), type => type.title),
});

ColumnMetadata.createdAt = new ColumnMetadata({
  name: 'Added',
  path: 'createdAt',
  format: formatDateTime,
  width: widths.dateTime,
});

ColumnMetadata.status = new ColumnMetadata({
  name: 'Status',
  path: 'status',
  format: (rawValue, trip) => unwrap(TripStatus.from(rawValue), status => status.title(trip)),
  width: 175,
});

ColumnMetadata.driverConfirmation = new ColumnMetadata({
  name: 'Confirmed',
  path: 'driverConfirmationTimestampMs',
  format: formatDateTime,
  width: widths.dateTime,
});

ColumnMetadata.source = new ColumnMetadata({
  name: 'Source',
  path: 'source',
  format: rawValue => unwrap(TripSource.from(rawValue), source => source.title),
  width: 150,
});

ColumnMetadata.fromPlaceName = new ColumnMetadata({
  name: 'Store location',
  path: 'fromPlaceName',
  width: 250,
});

ColumnMetadata.pickup = new ColumnMetadata({
  name: 'Pickup address',
  path: 'pickup',
  get: data =>
    unwrap(tripLocationsWithDefault(data), locations =>
      unwrap(
        first(locations),
        location => location.address,
        // locationDescription(location, ' ')
      ),
    ),
  width: 250,
});

ColumnMetadata.dropOff = new ColumnMetadata({
  name: 'Drop-off address',
  path: 'dropOff',
  get: data =>
    unwrap(tripLocationsWithDefault(data), locations =>
      unwrap(
        last(locations),
        location => location.address,
        // locationDescription(location, ' ')
      ),
    ),
  width: 250,
});

ColumnMetadata.vendorId = new ColumnMetadata({
  name: 'Vendor',
  path: 'vendorId',
  width: 200,
});

ColumnMetadata.vendorName = new ColumnMetadata({
  name: 'Vendor',
  path: 'vendorName',
  width: 200,
});

ColumnMetadata.createdBy = new ColumnMetadata({
  name: 'Created by',
  path: 'createdBy',
  width: 150,
});

ColumnMetadata.orderId = new ColumnMetadata({
  name: 'Order/invoice',
  path: 'orderId',
  get: data => data.orderId || unwrap(data.ezCaterOrderId, Order.formatId),
  width: 150,
});

ColumnMetadata.state = new ColumnMetadata({
  name: 'State',
  path: 'state',
  get: data =>
    unwrap(data.state, null, () =>
      unwrap(
        find(data.locations, location => location.state),
        location => location.state,
      ),
    ),
  width: 100,
});

ColumnMetadata.date = new ColumnMetadata({
  name: 'Date',
  path: 'firstScheduledTimestampMs',
  get: data => unwrap(Trip.prototype.earliestScheduledLocation.apply(data), loc => loc.scheduledTimestampMs),
  format: formatDate,
  Component: TimestampComponent,
  width: widths.date,
});

ColumnMetadata.pickupTime = new ColumnMetadata({
  name: 'Pickup time',
  path: 'scheduleTimestamp',
  get: data =>
    unwrap(data, data =>
      unwrap(
        Trip.prototype.pickupLocation.apply(data),
        location => location.scheduledTimestampMs || location.estimatedArrivalTimestampMs,
      ),
    ),
  format: formatTime,
  formatCsv: formatTimeWithoutTimezone,
  Component: TimestampComponent,
});

ColumnMetadata.dropOffTime = new ColumnMetadata({
  name: 'Drop-off time',
  path: 'dropOffTimestampMs',
  get: data =>
    unwrap(data, data =>
      unwrap(
        Trip.prototype.dropOffLocation.apply(data),
        location => location.scheduledTimestampMs || location.estimatedArrivalTimestampMs,
      ),
    ),
  format: formatTime,
  formatCsv: formatTimeWithoutTimezone,
  Component: TimestampComponent,
});

ColumnMetadata.itemCount = new ColumnMetadata({
  name: 'Item count',
  path: 'locations/0/contents.length',
  disableSort: true,
  get: data => {
    const locations = tripLocationsWithDefault(data);
    return locations[0]?.contents?.length;
  },
  width: 100,
});

ColumnMetadata.padding = new ColumnMetadata({
  name: 'Padding',
  path: 'ezCaterPaddingM',
  width: 100,
});

ColumnMetadata.driver = new ColumnMetadata({
  name: 'Assigned driver',
  path: 'driver',
  format: driver => unwrap(driver, driver => Driver.prototype.casualName.apply(driver)),
  sortByFormattedValue: true,
  width: 150,
});

ColumnMetadata.customerName = new ColumnMetadata({
  name: 'Customer name',
  path: 'customer.name',
  get: data =>
    unwrap(Trip.prototype.contact.apply(data, [UserType.orderer]), contact => contact.name) ||
    unwrap(data.customer, customer => Driver.prototype.fullName.apply(customer)),
  width: 225,
});

ColumnMetadata.customerPhone = new ColumnMetadata({
  name: 'Customer phone',
  path: 'customer.phone',
  get: data =>
    unwrap(
      unwrap(Trip.prototype.contact.apply(data, [UserType.orderer]), contact => contact.phone) ||
        (data.customer ? data.customer.phone || data.customer.cellphone : undefined),
      formatPhone,
    ),
  width: 150,
});

ColumnMetadata.customerAddress = new ColumnMetadata({
  name: 'Customer address',
  path: 'customer.address',
  get: data =>
    unwrap(Trip.prototype.locationsWithDefault.apply(data), locations =>
      unwrap(last(locations), location => location.address),
    ),
  width: 250,
});

ColumnMetadata.orderTotal = new ColumnMetadata({
  name: 'Order total',
  path: 'orderValue',
  format: unwrapAndFormatCurrency,
  width: 100,
});

ColumnMetadata.tip = new ColumnMetadata({
  name: 'Tip',
  path: 'tip',
  format: unwrapAndFormatCurrency,
  width: 100,
});

ColumnMetadata.estimate = new ColumnMetadata({
  name: 'Fee',
  path: 'estimate',
  format: unwrapAndFormatCurrency,
  width: 150,
});

ColumnMetadata.estimatedMiles = new ColumnMetadata({
  name: 'Miles',
  path: 'estimatedMiles',
  format: mi => unwrap(mi, mi => Math.round(mi * 10) / 10),
  width: 100,
});

ColumnMetadata.driverEarningsD = new ColumnMetadata({
  name: 'Driver rate',
  path: 'driverEarningsD',
  format: unwrapAndFormatCurrency,
  width: 100,
});

ColumnMetadata.didOverrideDriverEarnings = new ColumnMetadata({
  name: 'Driver rate override',
  path: 'didOverrideDriverEarnings',
  format: value => (value ? 'YES' : ''),
  width: 200,
});

ColumnMetadata.didOverrideEstimate = new ColumnMetadata({
  name: 'Fee override',
  path: 'didOverrideEstimate',
  format: value => (value ? 'YES' : ''),
  width: 200,
});

ColumnMetadata.cashedOutAt = new ColumnMetadata({
  name: 'Payout date',
  path: 'cashedOutAt',
  get: data => (data.cashedOutAt === -1 ? undefined : data.cashedOutAt),
  format: formatTime,
  formatCsv: formatTimeWithoutTimezone,
});

ColumnMetadata.ezDeliveryId = new ColumnMetadata({
  name: 'ezCater Delivery ID',
  path: 'ezCaterDeliveryId',
  width: 350,
});

ColumnMetadata.customerId = new ColumnMetadata({
  name: 'Customer ID',
  path: 'customerId',
  width: 350,
});

function TimestampComponent({rawValue, formattedValue, data}) {
  return Trip.prototype.isOverdue.apply(data) ? <span className="text-danger">{formattedValue}</span> : formattedValue;
}

function unwrapAndFormatCurrency(number) {
  return unwrap(number, formatCurrency);
}

function TextMuted({rawValue}) {
  return <div className="text-gray-400">{rawValue}</div>;
}

function formatDate(timestamp = null, data, timezone) {
  return unwrap(timestamp, ts => createMoment(ts, timezone).format('M/D/YYYY dddd'));
}

function formatTime(timestamp = null, data, timezone) {
  return unwrap(timestamp, ts => createMoment(ts, timezone).format('h:mm A z'));
}

function formatTimeWithoutTimezone(timestamp = null, data, timezone) {
  return unwrap(timestamp, ts => createMoment(ts, timezone).format('h:mm A'));
}

function formatDateTime(timestamp = null, data, timezone) {
  return unwrap(timestamp, ts => createMoment(ts, timezone).format('M/D/YYYY h:mm A z'));
}

function createMoment(timestamp, timezone = 'America/New_York') {
  return moment(timestamp).tz(timezone);
}
