import firebase from 'firebase/app';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Card from '../../../components/Card';
import cleanForFirebase from '../../../functions/cleanForFirebase';
import FirestoreQuery from '../../../models/scoopm/FirestoreQuery';

export default function AddBucket() {
  const history = useHistory();
  const [error, setError] = useState();

  useEffect(() => {
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then(result => {
        const [queryId, query] = FirestoreQuery.generic(result.claims);

        return firebase
          .firestore()
          .collection('queries')
          .doc(queryId)
          .set(cleanForFirebase(query))
          .then(() => {
            history.push(`/admin/jobs/${queryId}`);
          });
      })
      .catch(error => {
        console.error('Error creating bucket', error);
        setError(error);
      });
  }, [history]);

  return <Card padded>{error ? `Error. ${error.message}` : 'Adding...'}</Card>;
}
