import unwrap from '../../functions/unwrap';
import RealtimeDatabaseModel from '../base/RealtimeDatabaseModel';
import Driver from './Driver';

export default class TripDriver {
  constructor({firstName, lastName, phone, proxyPhone}) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.proxyPhone = proxyPhone;
  }
}

TripDriver.fromPojo = pojo => RealtimeDatabaseModel.fromPojo(TripDriver, pojo);

TripDriver.fromDriver = driver =>
  unwrap(
    driver,
    driver =>
      new TripDriver({
        firstName: driver.firstName,
        lastName: driver.lastName,
        phone: Driver.prototype.safePhoneNumber.apply(driver),
      }),
  );
