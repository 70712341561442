import Fountain from '.';
import Model from '../../../models/fountain/Applicant';
import HttpReference from '../../platform/HttpReference';
import Applicant from './Applicant';

export default class Applicants extends HttpReference<any, Applicant, Fountain> {
  get childReferenceType() {
    return Applicant;
  }

  async headers(): Promise<Record<string, string> | null> {
    return null;
  }

  list({email}: {email: string}) {
    return this.request<Model[]>({
      method: 'get',
      params: {
        email,
      },
    });
  }
}
