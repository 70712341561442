import firebase from 'firebase/app';
import pluralize from 'pluralize';
import React, {useCallback, useMemo, useState} from 'react';
import Delay from '../../components/Delay';
import FirestoreTable from '../../components/FirestoreTable';
import FilterMetadata from '../../components/FirestoreTable/FilterMetadata';
import FilterType from '../../components/FirestoreTable/FilterType';
import Spinner from '../../components/Spinner';
import ColumnMetadata from '../../components/Table/ColumnMetadata';
import SortingDirection from '../../components/Table/SortingDirection';
import unwrap from '../../functions/unwrap';
import useLocalStorage from '../../hooks/useLocalStorage';
import useUserGroupVendor from '../../hooks/useUserGroupVendor';

export default function Table({id, filters}) {
  const [rows, setRows] = useState();
  const [isFetching, setIsFetching] = useState(false);

  const [sortingColumn, setSortingColumn] = useLocalStorage(id + '_SORTING_COLUMN', ColumnMetadata.createdAt.path);
  const [sortingDirectionRawValue, setSortingDirectionRawValue] = useLocalStorage(
    id + '_SORTING_DIRECTION',
    SortingDirection.descending.rawValue,
  );
  const sortingDirection = SortingDirection.from(sortingDirectionRawValue);
  const [search, setSearch] = useState();

  const columns = useMemo(
    () => [
      ColumnMetadata.status,
      ColumnMetadata.fromPlaceName,
      ColumnMetadata.orderId,
      ColumnMetadata.date,
      ColumnMetadata.pickupTime,
      ColumnMetadata.dropOffTime,
      ColumnMetadata.itemCount,
      ColumnMetadata.orderTotal,
      ColumnMetadata.tip,
      ColumnMetadata.driver,
      ColumnMetadata.createdAt,
    ],
    [],
  );

  const {isLoading: vendorIsLoading, error: vendorError, model: vendor} = useUserGroupVendor();

  const allFilters = useMemo(
    () =>
      vendorIsLoading
        ? undefined
        : [
            vendor
              ? new FilterMetadata({
                  name: 'Vendor',
                  field: 'vendorId',
                  type: FilterType.singleValue,
                  value: vendor.id,
                })
              : new FilterMetadata({
                  name: 'Customer ID',
                  field: 'customerId',
                  type: FilterType.singleValue,
                  value: unwrap(firebase.auth().currentUser, user => user.uid),
                }),
            ...filters,
          ],
    [vendor, filters, vendorIsLoading],
  );

  const getLink = useCallback((id, data) => `/dispatch/trips/${data.customerId}/${id}`, []);

  return (
    <>
      <div className="row pb-2 pb-md-3">
        <div className="col d-flex align-items-center">
          {vendorError && 'Unable to show all jobs from team. '}
          {isFetching ? (
            <Delay amountMs={500}>
              <Spinner small />
            </Delay>
          ) : rows ? (
            `${rows.length} ${pluralize('item', rows.length)}`
          ) : null}
        </div>
        <div className="col-auto d-flex align-items-center">
          <input
            type="search"
            value={search || ''}
            onChange={event => setSearch(event.target.value)}
            placeholder="Search"
            className="form-control m-1"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
        </div>
      </div>

      {allFilters && (
        <FirestoreTable
          collection="trips"
          columns={columns}
          sortingColumn={sortingColumn}
          onChangeSortingColumn={setSortingColumn}
          sortingDirection={sortingDirection}
          onChangeSortingDirection={sd => setSortingDirectionRawValue(unwrap(sd, sd => sd.rawValue))}
          filters={allFilters}
          orderBy={orderBy}
          limit={300}
          height={700}
          link={getLink}
          onChangeRows={setRows}
          onChangeIsFetching={setIsFetching}
          search={search}
        />
      )}
    </>
  );
}

const orderBy = ['createdAt', 'desc'];
