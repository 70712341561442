import React, {HTMLInputTypeAttribute} from 'react';
import unwrap from '../../functions/unwrap';
import Property from '../types/Property';
import InvalidFeedback from './InvalidFeedback';

interface InputProps {
  property: Property;
  id: string;
  type: HTMLInputTypeAttribute;
  value: any;
  onChange: (value: string | null) => void;
  autoComplete: AutoComplete;
  disabled: boolean;
  validationError: Error | undefined;
}

function Input(props: InputProps) {
  return (
    <>
      <input
        id={props.id}
        name={props.id}
        type={props.type}
        value={props.value || ''}
        onChange={event => props.onChange(event.target.value || null)}
        className={'form-control ' + unwrap(props.validationError, error => (error ? ' is-invalid' : ' is-valid'), '')}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
      />
      <InvalidFeedback error={props.validationError} />
    </>
  );
}

type AutoComplete = 'off' | 'name' | 'tel';

function withStringInput(type: HTMLInputTypeAttribute, autoComplete: AutoComplete) {
  return function (props: Omit<InputProps, 'type' | 'autoComplete'>) {
    return <Input type={type} autoComplete={autoComplete} {...props} />;
  };
}

export default withStringInput('text', 'off');
export const NameInput = withStringInput('text', 'name');
export const PhoneInput = withStringInput('tel', 'tel');
