import Trip, {TripOfNumber} from 'wave-common/lib/models/Trip';
import {tableWidthPixels} from 'wave-common/lib/type-descriptions/TableWidth';
import {DescriptionForColumn} from '.';

export interface AnyCell<
  Type extends 'EMPTY' | 'HEADING' | 'MARKER' | 'PROPERTY' | 'ACTION' | 'TOGGLER' | 'SPACER' | 'CHECKBOX',
> {
  type: Type;
  onMouseEnter: () => void;
}

//

export interface CellOfEmpty extends AnyCell<'EMPTY'> {}

export interface CellOfHeading extends AnyCell<'HEADING'> {
  groupUserFacingValue: string;
  count: number;
}

export interface CellOfMarker extends AnyCell<'MARKER'> {
  color: string;
  roundedTopLeft: boolean;
  roundedBottomLeft: boolean;
}

export interface CellOfProperty<Key extends keyof TripOfNumber> extends AnyCell<'PROPERTY'> {
  description: DescriptionForColumn<TripOfNumber[Key]>;
  model: TripOfNumber;
  lastProperty: boolean;
  onSelect: () => void;
  isHighlighted: boolean;
}

export interface CellOfAction extends AnyCell<'ACTION'> {
  roundedTopRight: boolean;
  roundedBottomRight: boolean;
  lastAction: boolean;
  model: Trip;
  isHighlighted: boolean;
}

export interface CellOfToggler extends AnyCell<'TOGGLER'> {
  isExpanded: boolean;
  count: number;
  toggle: () => void;
}

export interface CellOfSpacer extends AnyCell<'SPACER'> {}

export interface CellOfCheckbox extends AnyCell<'CHECKBOX'> {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  isHighlighted: boolean;
  trip: TripOfNumber;
}

//

type Cell =
  | CellOfEmpty
  | CellOfHeading
  | CellOfMarker
  | CellOfProperty<any>
  | CellOfAction
  | CellOfToggler
  | CellOfSpacer
  | CellOfCheckbox;

export default Cell;

//

export function cellWidth(cell: Cell): number {
  switch (cell.type) {
    case 'EMPTY':
      return 0;
    case 'HEADING':
    case 'TOGGLER':
      return 400;
    case 'SPACER':
    case 'MARKER':
      return 12;
    case 'PROPERTY':
      return tableWidthPixels((cell as CellOfProperty<any>).description.tableWidth ?? 'MD');
    case 'ACTION':
    case 'CHECKBOX':
      return 48;
  }
}
