"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.geolocationPositionErrorCodeCustomerDescription = void 0;
function geolocationPositionErrorCodeCustomerDescription(code) {
    switch (code) {
        case 1:
            return 'Location permission denied';
        case 2:
            return 'Unable to acquire GPS position';
        case 3:
            return 'GPS slow';
    }
}
exports.geolocationPositionErrorCodeCustomerDescription = geolocationPositionErrorCodeCustomerDescription;
