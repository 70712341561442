import firebase from 'firebase/app';
import {GeoFire} from 'geofire';
import {distanceBetween, geohashQueryBounds} from 'geofire-common';
import flatMap from 'lodash/flatMap';
import sortBy from 'lodash/sortBy';

export default class GeoFireHelper {}

GeoFireHelper.once = async (centerCoordinatesArray, radiusM, path) => {
  // set up query

  const geoFire = new GeoFire(firebase.database().ref(path));
  const query = geoFire.query({
    center: centerCoordinatesArray,
    radius: radiusM,
  });

  // listen for results

  const results = [];
  query.on('key_entered', (key, coordinatesArray, distanceKm) => {
    results.push({key, coordinatesArray, distanceKm});
  });

  // wait until "ready"

  await new Promise(resolve => {
    query.on('ready', () => {
      query.cancel();
      resolve();
    });
  });

  return results;
};

GeoFireHelper.query = async (collection, fieldName, coordinatesArrayFieldName, centerCoordinatesArray, radiusM) => {
  // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
  // a separate query for each pair. There can be up to 9 pairs of bounds
  // depending on overlap, but in most cases there are 4.

  const bounds = geohashQueryBounds(centerCoordinatesArray, radiusM);
  const querySnapshots = await Promise.all(
    bounds.map(([start, end]) =>
      firebase.firestore().collection(collection).orderBy(fieldName).startAt(start).endAt(end).get(),
    ),
  );

  // filter out false positives (due to geohashes being imprecise)

  const filteredItems = querySnapshots.map(snapshot =>
    snapshot.docs
      .map(doc => {
        const coordinatesArray = doc.get(coordinatesArrayFieldName);

        const distanceM = distanceBetween(coordinatesArray, centerCoordinatesArray) * 1000;

        if (distanceM > radiusM) {
          return null;
        }

        return [distanceM, doc];
      })
      .filter(Boolean),
  );

  const flatItems = flatMap(filteredItems);

  const sortedItems = sortBy(flatItems, ([distanceM, doc]) => distanceM);

  const snapshots = sortedItems.map(([distanceM, doc]) => doc);

  return snapshots;
};
