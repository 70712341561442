import firebase from 'firebase/app';
import logPromise from '../../functions/logPromise';

export default class FirestoreModel {
  static path: string | null = null;

  id?: string;

  constructor(snapshot?: firebase.firestore.DocumentSnapshot) {
    this.id = snapshot?.id;
  }

  static getPath(): string {
    if (!this.path) throw new Error('You need to set the static path property on the model you are trying to use');
    return this.path;
  }

  static reference() {
    return firebase.firestore().collection(this.getPath());
  }

  static async withId<T extends typeof FirestoreModel>(this: T, id: string): Promise<InstanceType<T> | null> {
    const path = this.getPath();
    const snapshot = await logPromise(`Get ${path} with id ${id}`, firebase.firestore().collection(path).doc(id).get());
    if (!snapshot.exists) return null;
    return new this(snapshot) as InstanceType<T>;
  }
}
