"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.driversListNearCoordinates = exports.driverLocationDetailsRemove = exports.driverUpdate = exports.driverRead = void 0;
const Driver_1 = require("../models/Driver");
const ValidationResult_1 = require("../type-descriptions/ValidationResult");
const geofire_1 = require("../util/geofire");
// CRUD operations
const PATH = 'drivers';
async function driverRead(driverId, database) {
    const result = await database.read(PATH, driverId);
    return (0, ValidationResult_1.validationResultCheck)(Driver_1.driverDescription.validate(Object.assign({ id: result.id }, result.value)));
}
exports.driverRead = driverRead;
function driverUpdate(driverId, values, database) {
    return database.update(PATH, driverId, values);
}
exports.driverUpdate = driverUpdate;
/// `drivers` - Coordinates are only needed while in transit. Update always anyway. Used by rider/admin apps to view the driver's location.
/// `drivers-geo-fire-active` - Driver should be present while active, whether on trip or not. Used by admin for dispatch, for example.
/// `drivers-geo-fire` - Driver should be present only when active and idle. Used by invite flow. Legacy. Will get invites from riders if on this node.
function driverLocationDetailsRemove(id, database) {
    return database.update(PATH, id, {
        bearing: null,
        coordinate: null,
        speed: null,
    });
}
exports.driverLocationDetailsRemove = driverLocationDetailsRemove;
function driversListNearCoordinates(coordinates, radiusM, database) {
    return (0, geofire_1.geofireDatabaseQuery)(PATH, 'addressGeohash', data => { var _a; return (_a = data.addressCoordinatesArray) !== null && _a !== void 0 ? _a : [0, 0]; }, coordinates, radiusM, database, Driver_1.driverDescription, value => (value === null || value === void 0 ? void 0 : value.status) === 'approved');
}
exports.driversListNearCoordinates = driversListNearCoordinates;
